import { useQuery } from "@apollo/client";
import Errors from "components/Errors";
import Spinner from "components/Spinner";
import { useLocation, useParams, Navigate } from "react-router-dom";
import { FETCH_PRODUCTION_PLAN } from "./graphql";
import ProductionPlanForm from "./ProductionPlanForm";

const PlanDetail = () => {
  const { id } = useParams();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const latest = params.get("latest");

  const { loading, error, data } = useQuery(FETCH_PRODUCTION_PLAN, {
    variables: { id },
    fetchPolicy: "network-only",
  });

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const originalPlan = data.productionPlan;

  if (latest && originalPlan.latestPlanId !== id) {
    return <Navigate to={`/order-scheduler/plans/${originalPlan.latestPlanId}`} />;
  }

  return <ProductionPlanForm originalPlan={originalPlan} disabled={originalPlan.state !== "DRAFT"} />;
};

export default PlanDetail;
