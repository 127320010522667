import { useQuery, gql } from '@apollo/client';
import Errors from 'components/Errors';
import OdooPickingTypeSelector from 'components/OdooPickingTypeSelector';
import SearchBar from 'components/SearchBar';
import Spinner from 'components/Spinner';
import { useModals } from 'ModalProvider';
import { useEffect, useState } from 'react';
import OdooTransferDetail from './Detail';

const FETCH_ODOO_STOCK_PICKINGS = gql`
   query FETCH_ODOO_STOCK_PICKINGS(
      $pickingTypeId: Int
      $limit: Int
      $offset: Int
      $q: String
   ) {
      odooStockPickings(
         pickingTypeId: $pickingTypeId
         limit: $limit
         offset: $offset
         q: $q
      )
   }
`;

const OdooTransfers = () => {
   const [limit, setLimit] = useState(80);
   const [offset, setOffset] = useState(0);
   const [pickingTypeId, setPickingTypeId] = useState(
      localStorage.getItem('odooTransfer:filter:pickingTypeId') || '0'
   );
   const [searchText, setSearchText] = useState('');

   useEffect(() => {
      localStorage.setItem('odooTransfer:filter:pickingTypeId', pickingTypeId);
   }, [pickingTypeId]);

   const { loading, error, data } = useQuery(FETCH_ODOO_STOCK_PICKINGS, {
      variables: {
         pickingTypeId: pickingTypeId !== '0' ? parseInt(pickingTypeId) : null,
         limit,
         offset,
         q: searchText,
      },
   });

   useEffect(() => {
      localStorage.setItem('odooTransfer:filter:pickingTypeId', pickingTypeId);
   }, [pickingTypeId]);

   const { present } = useModals();

   return (
      <div className="overflow-auto flex flex-col flex-1">
         <div className="p-4 flex items-center space-x-6">
            <div className="flex-1">
               <SearchBar
                  placeholder="Search by Reference"
                  value={searchText}
                  onChange={setSearchText}
               />
            </div>
            <div className="flex items-center space-x-3">
               <label htmlFor="">Picking Type:</label>
               <OdooPickingTypeSelector
                  value={pickingTypeId}
                  initialValue={pickingTypeId}
                  onSelect={setPickingTypeId}
               />
            </div>
         </div>

         <div className="relative flex-1">
            <Content
               loading={loading}
               error={error}
               data={data}
               setSelectedPicking={(picking) =>
                  present({
                     title: picking.name,
                     maxWidth: 'max-w-6xl',
                     children: <OdooTransferDetail pickingId={picking.id} />,
                  })
               }
            />
         </div>
      </div>
   );
};

const Content = ({ loading, error, data, setSelectedPicking }) => {
   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;

   const odooStockPickings = JSON.parse(data.odooStockPickings);

   return (
      <table className="mt-2">
         <thead>
            <tr className="text-left">
               <th className="sticky px-6 py-3 top-0 bg-gray-200 bg-opacity-80 dark:bg-gray-800 backdrop-filter backdrop-blur-xl dark:bg-opacity-50">
                  Reference
               </th>
               <th className="sticky px-6 py-3 top-0 bg-gray-200 bg-opacity-80 dark:bg-gray-800 backdrop-filter backdrop-blur-xl dark:bg-opacity-50">
                  Destination Location
               </th>
               <th className="sticky px-6 py-3 top-0 bg-gray-200 bg-opacity-80 dark:bg-gray-800 backdrop-filter backdrop-blur-xl dark:bg-opacity-50">
                  Schedule Date
               </th>
               <th className="sticky px-6 py-3 top-0 bg-gray-200 bg-opacity-80 dark:bg-gray-800 backdrop-filter backdrop-blur-xl dark:bg-opacity-50">
                  Source Document
               </th>
               <th className="sticky px-6 py-3 top-0 bg-gray-200 bg-opacity-80 dark:bg-gray-800 backdrop-filter backdrop-blur-xl dark:bg-opacity-50 text-right">
                  Status
               </th>
            </tr>
         </thead>
         <tbody>
            {odooStockPickings.map((i) => (
               <tr
                  key={i.id}
                  className="border-b dark:border-gray-800 hover:text-blue-600 hover:dark:text-blue-700 cursor-pointer"
                  onClick={() => setSelectedPicking(i)}
               >
                  <td className="px-6">{i.name}</td>
                  <td className="px-6">{i.location_dest_id[1]}</td>
                  <td className="px-6">{i.scheduled_date}</td>
                  <td className="px-6">{i.origin}</td>
                  <td className="px-6 text-right">{i.state}</td>
               </tr>
            ))}
         </tbody>
      </table>
   );
};

export default OdooTransfers;
