import React from 'react';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import Spinner from '../../components/Spinner';
import Errors from '../../components/Errors';
import { Button } from '../../components/base.js';
import moment from 'moment';

const FETCH_TRIPS = gql`
   query FETCH_TRIPS {
      trips: allTrips {
         id
         when
         tips
         report
      }
   }
`;

function TripList() {
   const { loading, error, data } = useQuery(FETCH_TRIPS);
   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;
   return (
      <div className="grid grid-cols-3 gap-6 p-6">
         {data.trips.map((trip) => (
            <div
               key={trip.id}
               className="rounded-2xl bg-white dark:bg-gray-800"
            >
               <div className="p-6">
                  <h2 className="mb-4">
                     <Button
                        title={moment(trip.when).format('YYYY-MM-DD')}
                        link={`/csr/trips/${trip.id}`}
                     />
                  </h2>
                  <div className="whitespace-pre-wrap leading-normal">
                     {trip.report}
                  </div>
                  <div className="mt-6">
                     <Button
                        title="Read more.."
                        link={`/csr/trips/${trip.id}`}
                     />
                  </div>
               </div>
            </div>
         ))}
      </div>
   );
}

export default TripList;
