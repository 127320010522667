import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import Product from "components/Product";
import SearchBar from "components/SearchBar";
import { searchByProp } from "utils/search";

const FETCH_PRODUCTS_FOR_SALE = gql`
  query FETCH_PRODUCTS_FOR_SALE {
    allProducts(productType: "normal", isSalable: true, isActive: true) {
      id
      name
      number
    }
  }
`;

function ProductSelector({ onSelectProduct }) {
  const [searchText, setSearchText] = useState("");
  const { loading, error, data } = useQuery(FETCH_PRODUCTS_FOR_SALE);

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  return (
    <div className="flex flex-col flex-1">
      <SearchBar bgColor="dark:bg-gray-900" autoFocus type="text" placeholder="Search by name or number" value={searchText} onChange={setSearchText} />
      <div className="flex-1 mt-4">
        {data.allProducts
          ? data.allProducts
              .filter((i) => searchByProp(i, ["name", "number"], searchText))
              .map((product) => (
                <Product
                  className="flex-1 flex justify-between border-b dark:border-gray-700 hover:bg-blue-200 dark:hover:bg-gray-700 cursor-pointer py-2"
                  key={product.id}
                  name={product.name}
                  number={product.number}
                  onClick={() => onSelectProduct(product)}
                />
              ))
          : null}
      </div>
    </div>
  );
}

export default ProductSelector;
