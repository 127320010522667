import { useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import Spinner, { InlineSpinner } from 'components/Spinner';
import Errors from 'components/Errors';
import { Button } from 'components/base';
import Image from 'components/Image';
import { FETCH_ALL_VIDEOS } from './graphql';
import Page from 'components/Page';
import Modal from 'components/Modal';
import VideoForm from './VideoForm';
import { Alert } from 'components/Toast';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const CREATE_VIDEO = gql`
   mutation CREATE_VIDEO($name: String) {
      createVideo(name: $name) {
         video {
            id
            name
            description
            ordering
            sources {
               id
               file
               filename
               thumbSrc
               meta
               status
            }
         }
      }
   }
`;

const RERODER_VIDEOS = gql`
   mutation RERODER_VIDEOS($ordering: [VideoInput]) {
      reorderVideos(ordering: $ordering) {
         videos {
            id
            ordering
         }
      }
   }
`;

function VideoList() {
   const { loading, error, data } = useQuery(FETCH_ALL_VIDEOS);
   const [createVideo, createVideoRes] = useMutation(CREATE_VIDEO, {
      onCompleted() {
         Alert('success', 'Video Added.');
      },
      onError(error) {
         Alert('error', error.message);
      },
      awaitRefetchQueries: true,
      refetchQueries: ['FETCH_ALL_VIDEOS'],
   });
   const [reorderVideos, reorderVideosRes] = useMutation(RERODER_VIDEOS, {
      onCompleted() {
         Alert('success', 'Ordering updated');
      },
      onError(error) {
         Alert('error', error.message);
      },
   });

   const [selectedVideoId, setSelectedVideoId] = useState(null);

   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;

   const reorder = (list, startIndex, endIndex) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);

      return result;
   };

   const sortedVideos = [...data.allVideos].sort(
      (a, b) => a.ordering - b.ordering
   );
   const videos = [
      ...sortedVideos.filter((i) => i.active),
      ...sortedVideos.filter((i) => !i.active),
   ];

   function onDragEnd(result) {
      if (!result.destination) return;
      if (result.destination.index === result.source.index) return;
      const ordering = reorder(
         videos,
         result.source.index,
         result.destination.index
      ).map((i, index) => ({ id: i.id, ordering: index + 1 }));
      reorderVideos({ variables: { ordering } });
   }

   return (
      <Page
         title="Videos"
         rightButtons={
            <div>
               <Button
                  title="+ Create Video"
                  loading={createVideoRes.loading}
                  disabled={createVideoRes.loading}
                  onClick={() => {
                     const name = window.prompt('Video Name');
                     if (name) createVideo({ variables: { name } });
                  }}
               />
            </div>
         }
      >
         <div className="p-6 relative">
            {reorderVideosRes.loading ? (
               <div className="fixed inset-0 z-10 flex justify-center items-center md:left-56 bg-gray-50 bg-opacity-50 dark:bg-gray-800 dark:bg-opacity-80">
                  <InlineSpinner text="Saving..." />
               </div>
            ) : null}
            <DragDropContext onDragEnd={onDragEnd}>
               <Droppable droppableId="droppable">
                  {(provided) => (
                     <div className="space-y-6" ref={provided.innerRef}>
                        {videos.map((video, index) => (
                           <Draggable
                              key={video.id}
                              draggableId={video.id}
                              index={index}
                           >
                              {(provided) => (
                                 <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className={
                                       video.active ? '' : ' opacity-40'
                                    }
                                 >
                                    <VideoRow
                                       video={video}
                                       onClick={() =>
                                          setSelectedVideoId(video.id)
                                       }
                                    />
                                 </div>
                              )}
                           </Draggable>
                        ))}
                        {provided.placeholder}
                     </div>
                  )}
               </Droppable>
            </DragDropContext>
         </div>

         <Modal
            maxWidth="max-w-6xl"
            show={selectedVideoId !== null}
            center={false}
            title="Video"
            onHide={() => setSelectedVideoId(null)}
         >
            {selectedVideoId ? (
               <VideoForm
                  video={data.allVideos.find((i) => i.id === selectedVideoId)}
               />
            ) : null}
         </Modal>
      </Page>
   );
}

const VideoRow = ({ video, onClick }) => {
   const thumb =
      video.sources.length > 0 ? video.sources.map((i) => i.thumbSrc)[0] : null;
   return (
      <div
         className="py-6 flex bg-white dark:bg-gray-800 rounded-2xl duration-200 hover:scale-101 bounce transform-gpu cursor-pointer"
         onClick={onClick}
      >
         {thumb ? (
            <Image className="ml-6" size="w-32 h-24" src={thumb} />
         ) : null}
         <div className="ml-6 flex-1 space-y-2">
            <h4 className="">
               #{video.id} {video.name}
            </h4>
            {video.description ? (
               <div className="whitespace-pre-wrap text-grey-darkest">
                  {video.description}
               </div>
            ) : null}
            {video.active ? null : (
               <div>
                  <label htmlFor="">Deactived</label>
               </div>
            )}
         </div>
      </div>
   );
};

export default VideoList;
