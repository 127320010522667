import { gql } from '@apollo/client';

export const FETCH_PRODUCTS_VALIDATION = gql`
   query FETCH_PRODUCTS_VALIDATION(
      $productType: String!
      $stockCategoryId: ID
   ) {
      validateProducts(
         productType: $productType
         stockCategoryId: $stockCategoryId
      )
   }
`;
