import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import Spinner from 'components/Spinner';
import Errors from 'components/Errors';
import { CheckableShipmentStatus } from 'components/ShipmentStatus';
import OrderStatus from './OrderStatus';
import OrderErrors from './OrderErrors';
import ErrorsOverview from './ErrorsOverview';
import ErrorsCauseDelay from './ErrorsCauseDelay';
import ProductionDays from './ProductionDays';
import StatusDays from './StatusDays';
import DateRangePicker from '../../analytics/DateRangePicker';
import useDateRange from 'hooks/useDateRange';

const openStatusList = [
   'waiting for deposit payment',
   'in production',
   'delivered to port',
   'shipping',
   'on hold',
];

const shipmentStatusList = [
   ...openStatusList,
   'arrived at destination',
   'canceled',
];

const FETCH_SHIPMENT_LIST_FOR_OVERVIEW = gql`
   query FETCH_SHIPMENT_LIST_FOR_OVERVIEW(
      $selectedStatus: [String!]
      $start: DateTime
      $end: DateTime
   ) {
      shipments: shipmentList(
         latestStatusIn: $selectedStatus
         start: $start
         end: $end
      ) {
         id
         invoiceNumber
         latestStatus
         customer {
            id
            name
         }
         shipmentStatus {
            id
            status
            created
            errors {
               id
               error {
                  id
                  code
                  description
               }
               delayDays
               shipmentErrorProducts {
                  id
                  shipmentInvoiceRow {
                     id
                     product {
                        id
                        name
                     }
                     qty
                  }
               }
               created
            }
         }
         created
         deletedAt
         trackLeadtime
      }
   }
`;

function ShipmentOverview() {
   const [{ start, end }, { setStart, setEnd }] = useDateRange(6);
   const [selectedStatus, setSelectedStatus] = useState(openStatusList);

   const { loading, error, data } = useQuery(FETCH_SHIPMENT_LIST_FOR_OVERVIEW, {
      variables: { start, end, selectedStatus },
   });

   const shipments = data
      ? data.shipments.filter((s) => s.deletedAt === null && s.trackLeadtime)
      : [];

   return (
      <div>
         <div className="m-4 lg:m-6 rounded-2xl bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700">
            <DateRangePicker
               className="p-4"
               start={start}
               end={end}
               setStart={setStart}
               setEnd={setEnd}
            />
            <div className="m-4 lg:m-6 lg:flex">
               {shipmentStatusList.map((status, index) => (
                  <div key={index} className="mr-4">
                     <CheckableShipmentStatus
                        className="text-xs mt-2 lg:mt-0"
                        status={status}
                        check={selectedStatus.includes(status)}
                        onSelect={(check) => {
                           if (check) {
                              setSelectedStatus([...selectedStatus, status]);
                           } else {
                              setSelectedStatus(
                                 selectedStatus.filter((s) => s !== status)
                              );
                           }
                        }}
                     />
                  </div>
               ))}
            </div>
         </div>
         {loading ? (
            <Spinner />
         ) : error ? (
            <Errors error={error} />
         ) : (
            <div className="relative">
               <div className="m-4 lg:m-6 relative flex-1 lg:flex mt-0">
                  <div className="flex-1">
                     <OrderStatus
                        shipments={shipments}
                        selectedStatus={selectedStatus}
                     />
                  </div>
                  <div className="flex-1 mt-4 lg:mt-0 lg:ml-6">
                     <StatusDays
                        shipmentStatusList={shipmentStatusList}
                        shipments={shipments}
                     />
                  </div>
               </div>
               <div className="m-4 lg:m-6 mt-0">
                  <OrderErrors shipments={shipments} />
               </div>
               <div className="relative flex-1 lg:flex  m-4 lg:m-6 mt-0">
                  <div className="flex-1">
                     <ErrorsOverview shipments={shipments} />
                  </div>
                  <div className="flex-1 mt-4 lg:mt-0 lg:ml-6">
                     <ErrorsCauseDelay shipments={shipments} />
                  </div>
               </div>
               <div className="m-4 lg:m-6 mt-0">
                  <ProductionDays shipments={shipments} />
               </div>
            </div>
         )}
      </div>
   );
}

export default ShipmentOverview;
