import React from 'react';
import { IoMdCheckmark } from 'react-icons/io';
import { Button } from '../../../../components/base';

function ErrorProductsSelector({ shipmentInvoices, value, onSelect }) {
   const allShipmentInvoiceRows = shipmentInvoices.flatMap(
      (i) => i.shipmentInvoiceRows
   );
   const hasSelectedAll = value.length === allShipmentInvoiceRows.length;
   return (
      <div>
         {allShipmentInvoiceRows.map((row) => {
            const selected = value.indexOf(row.id) > -1;
            return (
               <div
                  className="mr-2 mb-2 py-2 border-b border-gray-100 cursor-pointer hover:bg-gray-200 rounded-lg animate-ease-5 flex-1 flex items-center"
                  key={row.id}
                  onClick={() => {
                     if (selected) {
                        onSelect(value.filter((i) => i !== row.id));
                     } else {
                        onSelect([...value, row.id]);
                     }
                  }}
               >
                  <div
                     className={`w-10 px-4 text-2xl
                          ${selected ? 'text-green-500' : 'text-gray-400'}`}
                  >
                     <IoMdCheckmark />
                  </div>
                  <div
                     className={`ml-4 animate-ease-2 font-bold text-xs
                  ${selected ? 'text-green-500' : 'text-gray-700'}`}
                  >
                     {row.product.name}
                  </div>
               </div>
            );
         })}
         {allShipmentInvoiceRows.length > 1 && (
            <div className="px-4">
               <Button
                  title={hasSelectedAll ? 'Clear Selection' : 'Select All'}
                  onClick={() => {
                     if (hasSelectedAll) {
                        onSelect([]);
                     } else {
                        onSelect(allShipmentInvoiceRows.map((i) => i.id));
                     }
                  }}
                  size="sm"
               ></Button>
            </div>
         )}
      </div>
   );
}

export default ErrorProductsSelector;
