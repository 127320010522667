import { gql, useQuery } from '@apollo/client';
import { Select } from './Form';

const FETCH_ODOO_TAX_LIST = gql`
   query FETCH_ODOO_TAX_LIST {
      odooTaxList
   }
`;

const OdooTaxSelector = ({ value, onSelect, ...rest }) => {
   const { loading, error, data } = useQuery(FETCH_ODOO_TAX_LIST);

   const odooTaxList = data
      ? JSON.parse(data.odooTaxList).sort((a, b) =>
           a.display_name.toLowerCase() > b.display_name.toLowerCase() ? 1 : -1
        )
      : [];

   return (
      <Select
         value={value || '0'}
         onChange={(e) => onSelect(parseInt(e.target.value))}
         {...rest}
      >
         {loading ? (
            <option value="0" disabled>
               loading...
            </option>
         ) : error ? (
            <option value="0" disabled>
               {error.message}
            </option>
         ) : (
            <>
               <option value="0" disabled>
                  --- Select ---
               </option>
               {odooTaxList.map((i, index) => (
                  <option key={index} value={i.id}>
                     {i.display_name}
                  </option>
               ))}
            </>
         )}
      </Select>
   );
};

export default OdooTaxSelector;
