import { gql, useMutation, useQuery } from '@apollo/client';
import CharlesButton from 'components/charles/base';
import { Alert } from 'components/Toast';

const DELETE_SUB_SUPPLIER_INVOICE = gql`
   mutation ($id: ID!) {
      deleteSubSupplierInvoice(id: $id) {
         result
         order {
            id
            invoices {
               id
            }
            invoiceTotal
         }
      }
   }
`;

const DeleteInvoiceView = ({ invoice, onDeleted, hide }) => {
   const [deleteInvoice, deleteInvoiceRes] = useMutation(
      DELETE_SUB_SUPPLIER_INVOICE,
      {
         variables: { id: invoice.id },
         onCompleted() {
            onDeleted();
            Alert('success', 'Invoice Deleted.');
         },
         onError(error) {
            Alert('error', error.message);
         },
      }
   );

   return (
      <div>
         <div className="text-sm">
            {invoice.paymentLines.length > 0 ? (
               <div>
                  <p>
                     There are payments for this invoice, you can not delete
                     this invoice unless you delete all the payments for this
                     invoice.
                  </p>
                  <hr />
                  <hr />
                  <div className="flex space-x-6">
                     <CharlesButton onClick={hide}>OK</CharlesButton>
                  </div>
               </div>
            ) : (
               <div>
                  <p>Are you sure to delete this invoice?</p>
                  <hr />
                  <div className="flex space-x-6">
                     <CharlesButton
                        danger
                        onClick={() => deleteInvoice()}
                        loading={deleteInvoiceRes.loading}
                     >
                        Yes, Delete Invoice
                     </CharlesButton>
                     <CharlesButton onClick={hide}>
                        No, Take me back
                     </CharlesButton>
                  </div>
               </div>
            )}
         </div>
      </div>
   );
};

export default DeleteInvoiceView;
