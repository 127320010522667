import { gql, useMutation, useQuery } from "@apollo/client";
import { parseError } from "apollo";
import Errors from "components/Errors";
import { Input } from "components/Form";
import Spinner from "components/Spinner";
import { Alert } from "components/Toast";
import CharlesButton from "components/charles/base";
import { CREATE_SHIPMENT, CREATE_SHIPMENT_INVOICE_FROM_ODOO } from "pages/shipment/graphql";
import { useEffect, useState } from "react";

const FETCH_ODOO_SO_DETAIL = gql`
  query FETCH_ODOO_SO_DETAIL($ids: [Int]!) {
    odooSoDetail(ids: $ids) {
      id
      so: name
      po: clientOrderRef
      partnerId
      partnerName
      address
      deliveryAddress
      paymentTerm
      requestCargoReadyDate: xDoMinDate
      shippedBy: xDeliverymethod
      state
      orderLines {
        id
        name
        productId
        qty: productUomQty
      }
    }
  }
`;

const FETCH_INTERNAL_TRANSFER_DETAIL = gql`
  query FETCH_INTERNAL_TRANSFER_DETAIL($ids: [Int]!) {
    odooInternalTransferDetail(ids: $ids) {
      id
      name
      partnerId
      partnerName
      orderLines: moves {
        id
        name
        productId
        qty
      }
    }
  }
`;

const ConfirmOdooObjectView = ({ item, type, customer, shipmentId, complete }) => {
  console.log("item", item);
  const query = item.type === "so" ? FETCH_ODOO_SO_DETAIL : FETCH_INTERNAL_TRANSFER_DETAIL;
  const { loading, error, data } = useQuery(query, { variables: { ids: [item.id] } });

  const [createShipment, createShipmentRes] = useMutation(CREATE_SHIPMENT, {
    onCompleted: (data) => complete(data.createShipment.shipment),
    onError: (error) => Alert("error", parseError(error)),
  });

  const [createShipmentInvoice, createShipmentInvoiceRes] = useMutation(CREATE_SHIPMENT_INVOICE_FROM_ODOO, {
    onCompleted: (data) => complete(data.createShipmentInvoiceFromOdoo.shipmentInvoice),
    onError: (error) => Alert("error", parseError(error)),
  });

  const [state, setState] = useState({
    number: "",
    po: "",
    orderLines: [],
  });

  function confirmHandler() {
    if (type === "CREATE_SHIPMENT") {
      createShipment({
        variables: {
          customerId: customer.id,
          invoiceInput: {
            odooModelId: item.id,
            odooModelType: item.type,
            so: state.number,
            pos: [state.po],
            rows: state.orderLines.filter((i) => i.selected).map((i) => ({ productOdooId: i.productId, qty: i.qty, po: state.po })),
          },
        },
      });
    }

    if (type === "CREATE_SHIPMENT_INVOICE") {
      createShipmentInvoice({
        variables: {
          shipmentId,
          invoiceInput: {
            odooModelId: item.id,
            odooModelType: item.type,
            so: state.number,
            pos: [state.po],
            rows: state.orderLines.filter((i) => i.selected).map((i) => ({ productOdooId: i.productId, qty: i.qty, po: state.po })),
          },
        },
      });
    }
  }

  useEffect(() => {
    if (data) {
      const odooObject = item.type === "so" ? data.odooSoDetail[0] : data.odooInternalTransferDetail[0];
      console.log("odooObject", odooObject);
      setState({
        orderLines: odooObject.orderLines
          .filter((i) => i.productId !== null)
          .map((i) => ({
            id: i.id,
            name: i.name,
            productId: i.productId,
            qty: i.qty,
            selected: i.productId && i.qty > 0,
          })),
        number: item.type === "so" ? odooObject.so.replace("SO", "") : odooObject.name,
        po: odooObject.po !== "false" ? odooObject.po : "",
      });
    }
  }, [data]);

  function toggleSelect() {
    const hasSelected = state.orderLines.some((i) => i.selected);
    setState((state) => ({
      ...state,
      orderLines: state.orderLines.map((i) => ({ ...i, selected: !hasSelected })),
    }));
  }

  if (loading) return <Spinner text="Loading Odoo Data..." />;
  if (error) return <Errors error={error} />;

  console.log("state", state);

  return (
    <div className="text-sm">
      <div className="flex space-x-8">
        <div>
          <label className="pr-2">So Number: </label>
          <Input value={state.number || ""} onChange={(e) => setState({ ...state, number: e.target.value })} />
        </div>
        <div>
          <label className="pr-2">Customer PO Ref: </label>
          <Input value={state.po || ""} onChange={(e) => setState({ ...state, po: e.target.value })} />
        </div>
      </div>

      <div className="-mx-2 mt-4">
        <table className="text-sm">
          <tbody>
            {state.orderLines.map((orderLine) => (
              <tr key={orderLine.id} className="">
                <td className="w-4 py-1">
                  <input
                    id={`orderLine-${orderLine.id}`}
                    className="cursor-pointer translate-y-px"
                    type="checkbox"
                    checked={orderLine.selected}
                    onChange={() => {
                      setState((state) => ({
                        ...state,
                        orderLines: state.orderLines.map((i) => (i.id === orderLine.id ? { ...i, selected: !orderLine.selected } : i)),
                      }));
                    }}
                  />
                </td>
                <td className="">
                  <label className="font-normal cursor-pointer opacity-100 py-1" htmlFor={`orderLine-${orderLine.id}`}>
                    #{orderLine.productId} {orderLine.name}
                  </label>
                </td>
                <td className="py-1">
                  <div className="flex justify-end">
                    <Input
                      className="text-right"
                      type="number"
                      value={orderLine.qty}
                      onChange={(e) => {
                        const qty = parseInt(e.target.value);
                        setState((state) => ({
                          ...state,
                          orderLines: state.orderLines.map((i) => (i.id === orderLine.id ? { ...i, qty } : i)),
                        }));
                      }}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="mt-2">
        <CharlesButton onClick={toggleSelect}>Toggle Select</CharlesButton>
      </div>

      <hr />

      <div className="flex justify-end">
        <CharlesButton primary onClick={confirmHandler} loading={createShipmentRes.loading || createShipmentInvoiceRes.loading}>
          Confirm
        </CharlesButton>
      </div>
    </div>
  );
};

export default ConfirmOdooObjectView;
