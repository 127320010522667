import { NavigationLink } from "components/base";
import Page from "components/Page";
import { Navigate, Route, Routes } from "react-router-dom";
import LumpSum from "./LumpSum";
import Products from "./Products";

const ProductionAnalytics = () => {
  return (
    <Page title="Production Analytics">
      <Routes>
        <Route path="products" element={<Products />} />
        <Route path="*" element={<Navigate to="products" />} />
      </Routes>
    </Page>
  );
};

export default ProductionAnalytics;
