import { gql } from "@apollo/client";

export const FETCH_PRODUCT_IMAGES = gql`
  query FETCH_PRODUCT_IMAGES($id: ID!) {
    product(id: $id) {
      id
      images: computedImages {
        id
        thumb: url(size: "300x300")
      }
    }
  }
`;

export const QC_REPORT_FRAGMENT = gql`
  fragment qcReport on QCReportType {
    id
    name
    factory {
      id
      name
    }
    invoice {
      id
      number
      customer {
        id
        name
      }
    }
    product {
      id
      name
      number
      productLine {
        id
        name
      }
      images: computedImages {
        id
        url(size: "300x300")
      }
      barcodeInnerboxEan13: innerBoxBarcodeEan
      barcodeInnerboxUpc: innerBoxBarcodeUpc
      barcodeOuterboxEan13: innerCartonBarcodeEan
      barcodeOuterboxUpc: innerCartonBarcodeUpc
      barcodeCarton1: outerCartonBarcodeUpc
      barcodeCarton2: outerCartonBarcodeEan
    }
    qty
    checkQty
    barcodeInnerboxEan13
    barcodeInnerboxUpc
    barcodeOuterboxEan13
    barcodeOuterboxUpc
    barcodeCarton
    status
    qualityCheck
    packageCheck
    logoCheck
    metaImages: images {
      id
      src
      meta {
        id
        name
      }
    }
    qualityIssues {
      id
      defectCode {
        id
        code
        description
      }
      defectType
      qty
      images {
        id
        src
      }
      factory {
        id
        name
      }
      productLine {
        id
        name
      }
    }
    printings: padPrintings {
      id
      isValid
      images {
        id
        src
      }
    }
    sharedWithCustomerAt
  }
`;

export const FETCH_QC_REPORTS_LIST = gql`
  query FETCH_QC_REPORTS_LIST($limit: Int, $offset: Int, $q: String, $status: String) {
    qcReports(options: { limit: $limit, offset: $offset, q: $q }, status: $status) {
      total
      results {
        id
        name
        factory {
          id
          name
        }
        invoice {
          id
          number
          customer {
            id
            name
          }
        }
        product {
          id
          name
          number
          productLine {
            id
            name
          }
        }
        qty
        productImages
        status
        user {
          id
          email
        }
        createdAt
        updatedAt
        deletedAt
        sharedWithCustomerAt
      }
    }
  }
`;

export const FETCH_QC_REPORTS = gql`
  query FETCH_QC_REPORTS($limit: Int, $offset: Int, $q: String) {
    allQcReports(limit: $limit, offset: $offset, q: $q) {
      id
      factory {
        id
        name
      }
      invoice {
        id
        number
      }
      product {
        id
        name
        number
        productLine {
          id
          name
        }
      }
      qty
      productImages
      status
      user {
        id
        email
      }
      createdAt
      updatedAt
      deletedAt
      sharedWithCustomerAt
    }
  }
`;

export const FETCH_QC_REPORT = gql`
  query FETCH_QC_REPORT($id: ID!) {
    report: qcReport(id: $id) {
      ...qcReport
    }
  }
  ${QC_REPORT_FRAGMENT}
`;

export const FETCH_QC_PREVIEW = gql`
  query FETCH_QC_PREVIEW($id: ID!) {
    report: qcReport(id: $id) {
      id
      qty
      invoice {
        id
        number
        odooModelType
        customer {
          id
          name
        }
      }
      html
      factory {
        id
        name
      }
      product {
        id
        name
        number
        productLine {
          id
          name
        }
      }
      sharedWithCustomerAt
    }
  }
`;

export const SAVE_SIMPLE_REPORT = gql`
  mutation SAVE_SIMPLE_REPORT(
    $id: ID!
    $barcodeInnerboxEan13: String
    $barcodeInnerboxUpc: String
    $barcodeOuterboxEan13: String
    $barcodeOuterboxUpc: String
    $barcodeCarton: String
    $packageCheck: String
    $logoCheck: String
    $shareWithCustomer: Boolean
    $sendNotification: Boolean
  ) {
    saveSimpleQcReport(
      id: $id
      barcodeInnerboxEan13: $barcodeInnerboxEan13
      barcodeInnerboxUpc: $barcodeInnerboxUpc
      barcodeOuterboxEan13: $barcodeOuterboxEan13
      barcodeOuterboxUpc: $barcodeOuterboxUpc
      barcodeCarton: $barcodeCarton
      packageCheck: $packageCheck
      logoCheck: $logoCheck
      shareWithCustomer: $shareWithCustomer
      sendNotification: $sendNotification
    ) {
      qcReport {
        ...qcReport
      }
    }
  }
  ${QC_REPORT_FRAGMENT}
`;

export const FETCH_PRODUCTS = gql`
  {
    factories: allFactories {
      id
      name
      status
    }
    products: allProductlines {
      id
      name
      mainImage
    }
  }
`;

export const FETCH_DEFECT_CODES = gql`
  query FETCH_DEFECT_CODES {
    allDefectCodes {
      id
      code
      description
    }
  }
`;

export const DELETE_QC_REPORT = gql`
  mutation DELETE_QC_REPORT($id: ID!) {
    deleteQcReport(id: $id) {
      qcReport {
        id
        deletedAt
      }
    }
  }
`;

export const FETCH_BASE_INFO = gql`
  query FETCH_BASE_INFO {
    allInvoices {
      id
      number
      status
      odooModelType
    }
    factories: allFactories(isActive: true) {
      id
      name: nickName
    }
  }
`;

export const CREATE_QC_FINAL_REPORT = gql`
  mutation CREATE_QC_FINAL_REPORT($factoryId: ID!, $invoiceId: ID!, $productId: ID!, $qty: Int!, $checkQty: Int) {
    createSimpleQcReport(factoryId: $factoryId, invoiceId: $invoiceId, productId: $productId, qty: $qty, checkQty: $checkQty) {
      qcReport {
        id
      }
    }
  }
`;

export const SAVE_REPORT_FACTORY = gql`
  mutation SAVE_REPORT_FACTORY($id: ID!, $factoryId: ID!) {
    saveSimpleQcReport(id: $id, factoryId: $factoryId) {
      qcReport {
        ...qcReport
      }
    }
  }
  ${QC_REPORT_FRAGMENT}
`;

export const DELETE_QC_ISSUE = gql`
  mutation DELETE_QC_ISSUE($id: ID!) {
    deleteQcIssue(id: $id) {
      qcReport {
        ...qcReport
      }
    }
  }
  ${QC_REPORT_FRAGMENT}
`;
