import {
   ResponsiveContainer,
   Tooltip,
   Legend,
   PieChart,
   Pie,
   Cell,
} from 'recharts';
import { randomColors } from 'utils/colors';

const DefectCodesChart = ({ data }) => {
   const colors = randomColors(data.length);

   return (
      <ResponsiveContainer width="100%" aspect={3 / 2}>
         <PieChart>
            <Pie data={data} label dataKey="value">
               {data.map((_, index) => (
                  <Cell key={index} fill={colors[index]} />
               ))}
            </Pie>
            <Legend />
            <Tooltip />
         </PieChart>
      </ResponsiveContainer>
   );
};

export default DefectCodesChart;
