const { useQuery, gql } = require('@apollo/client');

const FETCH_ODOO_DELIVERY_METHODS = gql`
   query FETCH_ODOO_DELIVERY_METHODS {
      odooDeliveryMethods
   }
`;

const useOdooDeliveryMethods = () => {
   const { loading, error, data } = useQuery(FETCH_ODOO_DELIVERY_METHODS);
   const odooDeliveryMethods = data
      ? JSON.parse(data.odooDeliveryMethods)
      : null;
   return { loading, error, odooDeliveryMethods };
};

export default useOdooDeliveryMethods;
