import { gql, useQuery } from "@apollo/client";
import Errors from "components/Errors";
import Spinner from "components/Spinner";
import EffectedProductsSyncTable from "./EffectedProductsSyncTable";

const FETCH_EFFECTED_PRODUCT_COST = gql`
  query FETCH_EFFECTED_PRODUCT_COST($ids: [ID], $filters: String, $fields: [String]) {
    products(ids: $ids) {
      results {
        id
        odooId
        number
        name
        cost
      }
    }
    supplierInfo: odooQuery(model: "product.supplierinfo", method: "search_read", filters: $filters, fields: $fields)
  }
`;

const EffectedProductsView = ({ products }) => {
  const filters = JSON.stringify([["product_id", "in", products.filter((i) => i.odooId).map((i) => i.odooId)]]);
  const fields = ["product_id", "price", "currency_id"];
  const { loading, error, data } = useQuery(FETCH_EFFECTED_PRODUCT_COST, {
    variables: { ids: products.map((i) => i.id), filters, fields },
    fetchPolicy: "network-only",
  });

  if (loading) return <Spinner text="Checking effected product cost..." />;
  if (error) return <Errors error={error} />;

  const supplierInfo = JSON.parse(data.supplierInfo);
  const computedProducts = data.products.results.map((i) => {
    return { ...i, supplierInfo: supplierInfo.filter((s) => s.product_id && s.product_id[0] === i.odooId) };
  });

  return (
    <div>
      <div>
        You have updated a component, the parent product cost might be effected. Below are the products with this component and the latest cost. You might need
        to sync to Odoo if needed.
      </div>
      <EffectedProductsSyncTable products={computedProducts} />
    </div>
  );
};

export default EffectedProductsView;
