import { gql, useMutation } from "@apollo/client";
import { parseError } from "apollo";
import { Alert } from "components/Toast";
import CharlesButton from "components/charles/base";
import { BsExclamationCircleFill } from "react-icons/bs";

const CONFIRM_PRODUCT_LIST = gql`
  mutation CONFIRM_PRODUCT_LIST($id: ID!) {
    updateProductList(id: $id, state: "CONFIRMED") {
      productList {
        id
        state
      }
    }
  }
`;

const ConfirmProductListView = ({ productList, hide }) => {
  const [confirm, confirmRes] = useMutation(CONFIRM_PRODUCT_LIST, {
    variables: { id: productList.id },
    onCompleted() {
      Alert("success", "Product list confirmed.");
      hide();
    },
    onError(error) {
      Alert("error", parseError(error));
    },
  });

  return (
    <div>
      <div className="flex space-x-6">
        <div>
          <h4>Are you sure to confirm this product list: {productList.name}?</h4>
          <div className="mt-2  opacity-70">
            By confirming a product list, you are confirming that all the infomartion in this list are correct, the state of the product list will be changed
            from DRAFT to CONFIRMED. Please make sure you have checked all the information before confirming.
          </div>
        </div>
        <div className="text-orange-600">
          <BsExclamationCircleFill size={56} />
        </div>
      </div>

      <hr />

      <div className="flex space-x-6">
        <CharlesButton onClick={confirm} loading={confirmRes.loading}>
          Yes Confirm Now
        </CharlesButton>
        <CharlesButton onClick={hide}>Cancel</CharlesButton>
      </div>
    </div>
  );
};

export default ConfirmProductListView;
