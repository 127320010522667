import { gql } from "@apollo/client";

export const FETCH_PRODUCT_LIST_FOR_DOC_CPC = gql`
  query FETCH_PRODUCT_LIST_FOR_DOC_CPC($id: ID!) {
    productList(id: $id) {
      id
      name
      number
      sampleImages {
        id
        url
      }
      testReports {
        id
        standard {
          id
          name
        }
        fileList
        createdAt
      }
      docData
      cpcData
    }
  }
`;

export const CONFIRM_PRODUCT_LIST_DOCUMENT = gql`
  mutation CONFIRM_PRODUCT_LIST_DOCUMENT($id: ID!, $docData: String, $cpcData: String) {
    updateProductList(id: $id, docData: $docData, cpcData: $cpcData) {
      productList {
        id
        docData
        cpcData
      }
    }
  }
`;
