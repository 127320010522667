import Page from "components/Page";
import SearchBar from "components/SearchBar";
import { useState } from "react";
import { useQuery, gql } from "@apollo/client";
import { useDebounce } from "use-debounce";
import Errors from "components/Errors";
import { InlineSpinner } from "components/Spinner";

const GOOGLE_SEARCH = gql`
  query GOOGLE_SEARCH($q: String!) {
    googleSearch(q: $q)
  }
`;

const Google = () => {
  const [searchText, setSearchText] = useState("");
  const [debouncedSearchText] = useDebounce(searchText, 500);

  const { loading, error, data } = useQuery(GOOGLE_SEARCH, {
    variables: { q: debouncedSearchText },
  });

  return (
    <Page title="Google Search" subtitle="In case we can not access google for some time.">
      <div className="p-6">
        <div>
          <SearchBar value={searchText} onChange={setSearchText} placeholder="Search in Google.." />
        </div>

        <div className="mt-4 relative">
          {loading ? (
            <InlineSpinner className="p-20" />
          ) : error ? (
            <Errors error={error} />
          ) : data ? (
            <GoogleSearchedItems result={data.googleSearch} />
          ) : (
            <div>??</div>
          )}
        </div>
      </div>
    </Page>
  );
};

const GoogleSearchedItems = ({ result }) => {
  if (!result) return null;

  const data = JSON.parse(result);

  const items = data.items.filter((i) => i.pagemap);

  console.log("items", items);

  return (
    <div className="grid grid-cols-4 gap-6">
      {items.map((i, index) => (
        <div key={index} className={`card px-6 space-y-2 py-6 ${index > 0 ? "border-t" : ""}`}>
          <h4>
            <a href={i.link} target="_blank" rel="noreferer">
              {i.title}
            </a>
          </h4>
          <div>{i.snippet}</div>
          {i.pagemap.cse_image ? (
            <div className="flex space-x-4">
              {i.pagemap.cse_image.map((x, imageIndex) => (
                <div key={imageIndex}>
                  <img className="" src={x.src} alt={i.title} />
                </div>
              ))}
            </div>
          ) : null}
        </div>
      ))}
    </div>
  );
};

export default Google;
