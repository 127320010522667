import { gql } from "@apollo/client";

export const FETCH_PRESIGNED_URL = gql`
  query FETCH_PRESIGNED_URL($filename: String!) {
    qiniuPresignedUrl(filename: $filename) {
      token
      key
      url
    }
  }
`;

const UPLOAD_URL = "https://up-z2.qiniup.com";

export function uploadFile(qiniuPresignedUrl, file) {
  return new Promise((resolve, reject) => {
    let xhr = new XMLHttpRequest();
    xhr.open("POST", UPLOAD_URL, true);
    let formData = new FormData();
    formData.append("key", qiniuPresignedUrl.key);
    formData.append("token", qiniuPresignedUrl.token);
    formData.append("file", file);
    xhr.upload.addEventListener(
      "progress",
      function (evt) {
        if (evt.lengthComputable) {
          console.log("process", Math.round((evt.loaded * 100) / evt.total));
        }
      },
      false
    );
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200 && xhr.responseText !== "") {
        resolve(qiniuPresignedUrl.url);
      } else if (xhr.readyState === 4) {
        // status not 200, but request completed
        reject(new Error("Upload failed with status " + xhr.status));
      }
    };
    xhr.send(formData);
  });
}
