// ! Notice: the permission name is used in the backend as group name, so don't change it unless you know what you are doing
export const INTERNAL = "Internal";
export const QC = "qc";

export const SUPER_USER = "super-user";

export const AMAZON_EDITOR = "amazon-editor";

export const PRODUCT_EDITOR = "product-editor";

export const PRODUCT_LIST_EDITOR = "product-list-editor";

export const PRICE_EDITOR = "price-editor";
export const PRICE_VIEWER = "price-viewer";

export const MANAGE_EDITOR_PARTIAL = "manage-editor-partial";

export const ODOO_MARGIN_EDITOR = "odoo-margin-editor";

// special permissions, more fine-grained
export const OS_CONFIRM_TIME = "os-confirm-time";
export const OS_CONFIRM_COST = "os-confirm-cost";
