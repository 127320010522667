import React from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import CustomerList from "./CustomerList";
import CreateCustomer from "./CreateCustomer";
import CustomerDetail from "./Detail";
import Products from "./Products";

const CustomerRoutes = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route index element={<CustomerList />} />
        <Route path="add" element={<CreateCustomer />} />
        <Route path=":id" element={<CustomerDetail />} />
        <Route path=":id/products" element={<Products />} />
      </Route>
    </Routes>
  );
};

export default CustomerRoutes;
