import { useMutation } from "@apollo/client";
import CharlesButton from "components/charles/base";
import { Input } from "components/Form";
import { Alert } from "components/Toast";
import { useState } from "react";
import { CREATE_PRODUCTION_PLAN } from "./graphql";

const CreateAdditionPlanView = ({ originalPlan, complete }) => {
  const additionalPlansCount = originalPlan.children.length;
  const [name, setName] = useState(`Additional Plan ${additionalPlansCount + 1} for ${originalPlan.name}`);

  const [createAdditionalPlan, { loading }] = useMutation(CREATE_PRODUCTION_PLAN, {
    variables: {
      parentId: originalPlan.id,
      name,
      planType: "PRODUCT",
      orderType: originalPlan.orderType,
      startDate: new Date(),
      autoSendToOdoo: false,
    },
    onCompleted(res) {
      Alert("success", "Plan Created");
      complete(res.createProductionPlan.productionPlan);
    },
    onError(error) {
      Alert("error", error.message);
    },
    refetchQueries: ["FETCH_PREV_PLANS"],
  });

  return (
    <div>
      <div>
        <div className="flex items-center space-x-3">
          <label htmlFor="">Plan Name: </label>
          <Input className="flex-1" value={name} onChange={(e) => setName(e.target.value)} />
        </div>

        <div className="text-xs opacity-70 mt-2">
          Additional Plan is just a normal production plan, except has some extra property helps to connect prev orders.
        </div>
      </div>

      <div className="mt-8">
        <CharlesButton primary loading={loading} onClick={createAdditionalPlan}>
          Create
        </CharlesButton>
      </div>
    </div>
  );
};

export default CreateAdditionPlanView;
