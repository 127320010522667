import { gql, useMutation, useQuery } from "@apollo/client";
import CharlesButton from "components/charles/base";
import Errors from "components/Errors";
import SearchBar from "components/SearchBar";
import Spinner from "components/Spinner";
import Status from "components/Status";
import { Alert } from "components/Toast";
import moment from "moment";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { searchByProp } from "utils/search";

const FETCH_SUB_SUPPLIER_INVOICES = gql`
  query FETCH_SUB_SUPPLIER_INVOICES {
    subSupplierInvoices(prefetchDetail: true) {
      total
      results {
        id
        number
        invoiceDate
        dueDate
        total
        amountPaid
        balance
        paymentState
        currency
        order {
          id
          odooPoName
          productionSupplier {
            id
            name
          }
        }
        paymentLines {
          id
          amount
        }
      }
    }
  }
`;

export const CREATE_INVOICE = gql`
  mutation {
    createSubSupplierInvoice {
      invoice {
        id
      }
    }
  }
`;

const SubSupplierInvoices = () => {
  const { loading, error, data } = useQuery(FETCH_SUB_SUPPLIER_INVOICES, {
    fetchPolicy: "network-only",
  });
  const [createInvoice, createInvoiceRes] = useMutation(CREATE_INVOICE, {
    onCompleted(res) {
      navigate(`/sub-supplier/invoices/${res.createSubSupplierInvoice.invoice.id}`);
      Alert("success", "Invoice Created.");
    },
    onError(error) {
      Alert("error", error.message);
    },
  });
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const invoices = data.subSupplierInvoices.results
    .map((i) => {
      const odooPoName = i.order ? i.order.odooPoName : "-";
      const supplierName = i.order && i.order.productionSupplier ? i.order.productionSupplier.name : "-";
      const diffDays = moment.duration(moment(i.dueDate).diff(moment())).asDays();
      return { ...i, diffDays, odooPoName, supplierName };
    })
    .filter((i) => searchByProp(i, ["odooPoName", "supplierName"], searchText));

  return (
    <div className="p-6">
      <div className="flex items-center space-x-8">
        <CharlesButton onClick={createInvoice} loading={createInvoiceRes.loading}>
          + Create Invoice
        </CharlesButton>
        <div className="flex-1">
          <SearchBar placeholder="Search by PO number or production supplier name." value={searchText} onChange={setSearchText} />
        </div>
      </div>
      <div className="card px-6 py-4 mt-4">
        <div className="-mx-2">
          <table>
            <thead>
              <tr>
                <th>Invoice</th>
                <th>Production Supplier</th>
                <th>Order</th>
                <th className="text-right">Total</th>
                <th className="text-right">Paid</th>
                <th className="text-right">Balance</th>
                <th className="text-right">Invoice Date</th>
                <th className="text-right">Due Date</th>
                <th className="text-right">Payment State</th>
              </tr>
            </thead>
            <tbody>
              {invoices.map((invoice) => (
                <tr key={invoice.id} className={`border-y border-gray-100 dark:border-gray-700`}>
                  <td>
                    <Link to={`/sub-supplier/invoices/${invoice.id}`}>
                      INV
                      {invoice.number ? invoice.number : "#" + invoice.id}
                    </Link>
                  </td>
                  <td>{invoice.order && invoice.order.productionSupplier ? invoice.order.productionSupplier.name : "-"}</td>
                  <td>{invoice.order ? invoice.order.odooPoName : "-"}</td>
                  <td className="text-right">
                    {invoice.currency} {invoice.total.toFixed(2)}
                  </td>
                  <td className="text-right">
                    {invoice.currency} {invoice.amountPaid.toFixed(2)}
                  </td>
                  <td className="text-right">
                    {invoice.currency} {invoice.balance.toFixed(2)}
                  </td>
                  <td className="text-right">{invoice.invoiceDate ? moment(invoice.invoiceDate).format("YYYY-MM-DD") : "-"}</td>

                  <td className="text-right">{invoice.dueDate ? moment(invoice.dueDate).format("YYYY-MM-DD") : "-"}</td>
                  <td className="text-right">
                    {invoice.paymentState === "FULLY_PAID" ? null : <DueStateStatus diffDays={invoice.diffDays} hasPaid={invoice.hasPaid} />}
                    <Status status={invoice.paymentState} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const DueStateStatus = ({ diffDays, hasPaid }) => {
  if (hasPaid) return null;

  if (diffDays < 0) return <Status status="OVER_DUE" />;
  if (diffDays < 7) return <Status status="PREPARE_PAYMENT" />;
  return null;
};

export default SubSupplierInvoices;
