import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import Spinner from '../../components/Spinner';
import Errors from '../../components/Errors';
import PageContainer from '../../components/PageContainer.js';
import ProductSelector from 'pages/manage/products/All/ProductSelector';

const FETCH_PROJECTS = gql`
   query FETCH_PROJECTS {
      allProjects {
         id
         name
         versions {
            id
            version
            releasedAt
         }
      }
   }
`;

const Projects = () => {
   const { loading, error, data } = useQuery(FETCH_PROJECTS);
   if (loading) return <Spinner text={null} />;
   if (error) return <Errors error={error} />;
   return (
      <PageContainer title="Projects">
         <ProductSelector />
         <div className="p-4 lg:p-6">
            <h4>test</h4>
            {data.allProjects.map((project) => (
               <Project project={project} key={project.id} />
            ))}
         </div>
      </PageContainer>
   );
};

const Project = ({ project }) => {
   const navigate = useNavigate();
   return (
      <div
         className="bg-white p-6 rounded-xl mb-6 hover:bg-blue-200 cursor-pointer animate-ease-3"
         onClick={() => navigate(`/projects/${project.id}`)}
      >
         <h4>{project.name}</h4>
         <div>Some other content will display here. </div>
      </div>
   );
};

export default Projects;
