const { useQuery, gql } = require("@apollo/client");

const FETCH_ODOO_SUPPLIERS = gql`
  query FETCH_ODOO_SUPPLIERS {
    odooSuppliers
  }
`;

const useOdooSuppliers = () => {
  const { loading, error, data } = useQuery(FETCH_ODOO_SUPPLIERS);
  const odooSuppliers = data ? JSON.parse(data.odooSuppliers) : null;
  return { loading, error, odooSuppliers };
};

export const FETCH_PRODUCT_ODOO_PRICE = gql`
  query FETCH_PRODUCT_ODOO_PRICE($productId: Int!) {
    odooSupplierInfo(productId: $productId) {
      id
      partnerName
      minQty
      currency
      price
      productId
      writeDate
      sequence
    }
  }
`;

export const useOdooSupplierInfo = (productId) => {
  const { loading, error, data } = useQuery(FETCH_PRODUCT_ODOO_PRICE, {
    variables: { productId },
  });

  return { loading, error, data };
};

export default useOdooSuppliers;
