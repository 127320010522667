import { gql } from "@apollo/client";

export const FETCH_ASSESSMENTS = gql`
  query FETCH_ASSESSMENTS {
    assessments {
      edges {
        node {
          id
          category {
            id
            name
          }
          supplier {
            id
            name
          }
          createdAt
          createdBy {
            id
            email
          }
          dueDate
          status
          baseInfo
          totalScore {
            score
            scoreText
          }
        }
      }
      pageInfo {
        startCursor
        endCursor
      }
    }
  }
`;

export const FETCH_ASSESSMENT = gql`
  query FETCH_ASSESSMENT($id: ID!) {
    assessment(id: $id) {
      id
      dueDate
      supplier {
        id
        name
      }
      baseInfo
      lowestWagesPerMonth
      lowestWagesPerHour

      totalScore {
        score
        scoreText
      }

      category {
        id
        name
        children {
          edges {
            node {
              id
              name
              requiredSupplier
              children {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }

      questions {
        edges {
          node {
            id
            question {
              id
              category {
                id
                requiredSupplier
              }
              confirmRequired
              checkDocsRequired
              checkEnvironmentRequired
              checkWithWorkersRequired
              checkWithOwnerRequired
              standardDescription: standardRuleDocstring
              question
              answerType
              numberListCount
              choices {
                id
                choice
                content
              }
            }
            answerText
            answerNumbers
            answerChoices
            uploadedFiles
            score
            requiredRectification
            rectificationNote
            rectificationCompleted
            rectifications {
              edges {
                node {
                  id
                  content
                  files
                }
              }
            }
            followUpNextTime
          }
        }
      }
      status
    }
  }
`;

export const CREATE_ASSESSMENT = gql`
  mutation CREATE_ASSESSMENT($supplierId: ID!) {
    createAssessment(supplierId: $supplierId) {
      assessment {
        id
      }
    }
  }
`;

export const UPDATE_ASSESSMENT_STATUS = gql`
  mutation UPDATE_ASSESSMENT_STATUS($id: ID!, $status: String!) {
    updateAssessmentStatus(id: $id, status: $status) {
      assessment {
        id
        status
      }
    }
  }
`;

export const SAVE_ASSESSMENT = gql`
  mutation SAVE_ASSESSMENT($id: ID!, $dueDate: Date, $extraInfo: AssessmentExtraInfoInput) {
    saveAssessment(id: $id, dueDate: $dueDate, extraInfo: $extraInfo) {
      assessment {
        id
        dueDate
        lowestWagesPerHour
        lowestWagesPerMonth
        questions {
          edges {
            node {
              id
              score
            }
          }
        }
        totalScore {
          score
          scoreText
        }
      }
    }
  }
`;

export const SAVE_ASSESSMENT_QUESTION = gql`
  mutation SAVE_ASSESSMENT_QUESTION($id: ID!, $answerText: String, $answerNumbers: [Float], $answerChoices: [String], $uploadedFiles: [String]) {
    saveAssessmentQuestion(id: $id, answerText: $answerText, answerNumbers: $answerNumbers, answerChoices: $answerChoices, uploadedFiles: $uploadedFiles) {
      assessmentQuestion {
        id
        answerText
        answerNumbers
        answerChoices
        uploadedFiles
        score
        requiredRectification
        assessment {
          id
          totalScore {
            score
            scoreText
          }
        }
      }
    }
  }
`;

export const UPDATE_ASSESSMENT_QUESTION = gql`
  mutation UPDATE_ASSESSMENT_QUESTION(
    $id: ID!
    $requiredRectification: Boolean
    $rectificationCompleted: Boolean
    $rectificationNote: String
    $followUpNextTime: Boolean
  ) {
    updateAssessmentQuestion(
      id: $id
      requiredRectification: $requiredRectification
      rectificationNote: $rectificationNote
      rectificationCompleted: $rectificationCompleted
      followUpNextTime: $followUpNextTime
    ) {
      assessmentQuestion {
        id
        requiredRectification
        rectificationNote
        rectificationCompleted
        followUpNextTime
      }
    }
  }
`;

export const CONFIRM_ASSESSMENT_QUESTION = gql`
  mutation ConfirmAssessmentQuestion($id: ID!, $score: String!) {
    confirmAssessmentQuestion(id: $id, score: $score) {
      assessmentQuestion {
        id
        score
        requiredRectification
        assessment {
          id
          totalScore {
            score
            scoreText
          }
        }
      }
    }
  }
`;
