import { gql, useMutation } from '@apollo/client';
import CharlesButton from 'components/charles/base';
import { Alert } from 'components/Toast';

const SYNC_PRICE_LIST_TO_ODOO = gql`
   mutation SYNC_PRICE_LIST_TO_ODOO($id: ID!) {
      syncPriceListToOdoo(id: $id) {
         result
      }
   }
`;

const SyncPriceListView = ({ priceList, hide }) => {
   const [sync, syncRes] = useMutation(SYNC_PRICE_LIST_TO_ODOO, {
      variables: { id: priceList.id },
      onCompleted() {
         Alert('success', 'Sync Success.');
         hide();
      },
      onError(error) {
         Alert('error', error.message);
      },
      refetchQueries: ['FETCH_ODOO_PRICE_LIST'],
      awaitRefetchQueries: true,
   });

   return (
      <div>
         <div>Are you sure to sync this price list to Odoo?</div>

         <hr />

         <div className="flex space-x-8">
            <CharlesButton loading={syncRes.loading} onClick={sync}>
               Yes, Sync Now.
            </CharlesButton>
            <CharlesButton danger onClick={hide}>
               No.
            </CharlesButton>
         </div>
      </div>
   );
};

export default SyncPriceListView;
