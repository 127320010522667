import { gql, useMutation } from '@apollo/client';
import CharlesButton from 'components/charles/base';
import { Input } from 'components/Form';
import { Alert } from 'components/Toast';
import { useState } from 'react';
import { FETCH_PRICE_LISTS } from '.';

const DUPLICATE_PRICE_LIST = gql`
   mutation ($sourcePriceListId: ID!, $name: String!) {
      duplicatePriceList(sourcePriceListId: $sourcePriceListId, name: $name) {
         priceList {
            id
         }
      }
   }
`;

const DuplicatePriceListView = ({
   sourcePriceListId,
   initialName = '',
   complete,
}) => {
   const [name, setName] = useState(initialName);
   const [duplicate, duplicateRes] = useMutation(DUPLICATE_PRICE_LIST, {
      variables: { sourcePriceListId, name },
      onCompleted(res) {
         Alert('success', 'Price List Duplicated.');
         complete(res.duplicatePriceList.priceList);
      },
      onError(error) {
         Alert('error', error.message);
      },
      refetchQueries: [{ query: FETCH_PRICE_LISTS }],
   });

   return (
      <div className="text-base">
         <div className="flex items-center">
            <label className="pr-2">Name: </label>
            <Input
               className="w-full"
               value={name}
               onChange={(e) => setName(e.target.value)}
            />
         </div>

         <hr />

         <div>
            <CharlesButton
               primary
               onClick={duplicate}
               loading={duplicateRes.loading}
            >
               Save
            </CharlesButton>
         </div>
      </div>
   );
};
export default DuplicatePriceListView;
