import React from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import Suppliers from './suppliers';
import Products from './products';
import Page from 'components/Page';
import { NavigationLink } from 'components/base';
import ProductionNodes from './productionNodes';
import SupplierDetail from './suppliers/Detail';

function ProductionRoutes() {
   return (
      <Routes>
         <Route element={<Layout />}>
            <Route path="suppliers" element={<Suppliers />} />
            <Route path="suppliers/:id" element={<SupplierDetail />} />
            <Route path="products" element={<Products />} />
            <Route path="production-nodes" element={<ProductionNodes />} />
            <Route path="*" element={<Navigate to="suppliers" />} />
         </Route>
      </Routes>
   );
}

const Layout = () => (
   <Page
      className="h-screen"
      leftButtons={
         <div className="flex space-x-4">
            <NavigationLink to="/production/suppliers" title="Suppliers" />
            <NavigationLink to="/production/products" title="Products" />
            <NavigationLink
               to="/production/production-nodes"
               title="Production Nodes"
            />
         </div>
      }
   >
      <Outlet />
   </Page>
);

export default ProductionRoutes;
