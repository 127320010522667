import React, { useContext } from "react";
import { useQuery, gql } from "@apollo/client";
import { InlineSpinner } from "components/Spinner";
import Errors from "components/Errors";
import { ProductRow } from "./QCProduct";
import MissingOdooIdRow from "./MissingOdooIdRow";
import { Button } from "components/base";
import usePing from "hooks/usePing";
import { Alert } from "components/Toast";
import { AppContext } from "App";

const FETCH_INVOICE_PRODUCTS = gql`
  query FETCH_INVOICE_PRODUCTS($id: ID!) {
    singleInvoice(id: $id) {
      id
      number
      odooModelId
      odooModelType
      odooItemLines {
        id
        productId
        name
        qty
        product {
          id
          name
          number
          images: computedImages {
            id
            url(size: "300x300")
          }
          productLine {
            id
            name
            mainImage
          }
        }
      }
    }
  }
`;

const InvoiceOdooProductSelector = ({ invoiceId, onSelectProduct }) => {
  const { user } = useContext(AppContext);
  const { loading, error, data } = useQuery(FETCH_INVOICE_PRODUCTS, {
    variables: { id: invoiceId },
  });
  const [ping, pingRes] = usePing({
    onCompleted: (res) => Alert("success", res.pingForHelp.result),
    onError: (error) => Alert("error", error.message),
  });

  if (loading)
    return (
      <div className="relative p-20">
        <InlineSpinner text="Loading Products..." />
      </div>
    );
  if (error) return <Errors error={error} />;

  let rows = data.singleInvoice.odooItemLines;

  function reportHandler() {
    const userInput = window.prompt("Tell me which product you could not find. 请告诉我们您无法找到的产品。");

    if (userInput) {
      const receipients = process.env.NODE_ENV === "development" ? ["tech@waboba.com"] : ["sharon@waboba.com", "alex.c@waboba.com", "tech@waboba.com"];
      const message = `
            QC Report - could not find product.
            SO Number: ${data.singleInvoice.number}
            odooModelId: ${data.singleInvoice.odooModelId}
            odooModelType: ${data.singleInvoice.odooModelType}
            ----------
            ${userInput}
            ----------
            by ${user.email}
            `;
      ping({ variables: { message, receipients } });
    }
  }

  return (
    <div>
      {rows.length === 0 ? (
        <div className="py-4 text-gray-500">No products for this invoice yet.</div>
      ) : (
        rows.map((row, index) => (
          <div
            key={index}
            onClick={() => {
              console.log("row", row);
              if (row.product) onSelectProduct(row.product, row.qty);
            }}
          >
            {row.product ? (
              <ProductRow product={row.product} qty={row.qty} odooProductId={row.productId} />
            ) : (
              <MissingOdooIdRow row={row} soInfo={data.singleInvoice.number} />
            )}
          </div>
        ))
      )}

      <div className="mt-8 lg:flex lg:space-x-2">
        <div>Could not find the product? 无法找到产品?</div>
        <Button
          className="mt-2 lg:mt-0"
          title="Click to report Waboba. 点击告诉我们。"
          onClick={reportHandler}
          loading={pingRes.loading}
          disabled={pingRes.loading}
        />
      </div>
    </div>
  );
};

export default InvoiceOdooProductSelector;
