import { useState } from 'react';
import FactorySelector from 'components/FactorySelector';
import { DatePicker, Input } from 'components/Form';
import { useQuery, gql, useMutation } from '@apollo/client';
import { InlineSpinner } from 'components/Spinner';
import Errors from 'components/Errors';
import Image from 'components/Image';
import { byName } from 'utils/sort';
import { Button } from 'components/base';
import { Alert } from 'components/Toast';
import { formatDate } from 'react-day-picker/moment';

const FETCH_ALL_PRODUCT_LINES = gql`
   query FETCH_ALL_PRODUCT_LINES {
      allProductlines(activeForWis: true) {
         id
         name
         mainImage(size: "80x80")
      }
   }
`;

const CREATE_QC_INLINE_REPORT = gql`
   mutation CREATE_QC_INLINE_REPORT(
      $factoryId: ID!
      $productLineId: ID!
      $date: Date!
      $finishedQty: Int!
      $batchQty: Int!
      $checkQty: Int!
   ) {
      createQcInlineReport(
         factoryId: $factoryId
         productLineId: $productLineId
         date: $date
         finishedQty: $finishedQty
         batchQty: $batchQty
         checkQty: $checkQty
      ) {
         report {
            id
            productLine {
               id
               name
            }
            factory {
               id
               name
            }
            date
            finishedQty
            batchQty
         }
      }
   }
`;

const InlineReportCreateForm = ({ onCreated }) => {
   const { loading, error, data } = useQuery(FETCH_ALL_PRODUCT_LINES);
   const [createInlineReport, createInlineReportRes] = useMutation(
      CREATE_QC_INLINE_REPORT,
      {
         onCompleted(res) {
            Alert('success', 'Created.');
            onCreated(res.createQcInlineReport.report.id);
         },
         onError(error) {
            Alert('error', error.message);
         },
         refetchQueries: ['FETCH_QC_INLINE_REPORTS'],
      }
   );
   const [factoryId, setFactoryId] = useState(null);
   const [date, setDate] = useState(new Date());
   const [productLine, setProductLine] = useState(null);
   const [batchQty, setBatchQty] = useState(0);
   const [finishedQty, setFinishQty] = useState(0);
   const [checkQty, setCheckQty] = useState(0);

   if (loading)
      return (
         <div className="py-8">
            <InlineSpinner />
         </div>
      );
   if (error) return <Errors error={error.message} />;

   const lines = [...data.allProductlines].sort(byName);

   let message = null;
   if (batchQty < 1) {
      message = '请输入本批次生产数量';
   } else if (finishedQty < 1) {
      message = '请输入已完成数量';
   } else if (checkQty < 1) {
      message = '请输入检查数量';
   } else if (finishedQty > batchQty) {
      message = '完成数量不能够超过批次数量';
   } else if (checkQty > finishedQty) {
      message = '检查数量不能够超过完成数量';
   }

   return (
      <div className="">
         <div className="rounded-2xl p-6 bg-white dark:bg-gray-900 space-y-4">
            <div className="space-y-2">
               <div>
                  <label htmlFor="">Factory 工厂:</label>
               </div>
               <FactorySelector value={factoryId} onChange={setFactoryId} />
            </div>

            <div className="space-y-2">
               <label htmlFor="">Date 日期:</label>
               <DatePicker value={date} onDayChange={setDate} />
            </div>

            <div className="space-y-2">
               <label>Product 产品:</label>

               {productLine ? (
                  <div className="flex items-center space-x-6">
                     <div className="flex items-center space-x-2 py-1 cursor-pointer">
                        <Image src={productLine.mainImage} size="w-12 h-12" />
                        <div>{productLine.name}</div>
                     </div>
                     <Button
                        title="Select"
                        onClick={() => setProductLine(null)}
                     />
                  </div>
               ) : (
                  <div className="">
                     {lines.map((i) => (
                        <div
                           key={i.id}
                           className="flex items-center space-x-2 border-b dark:border-gray-700 py-1 cursor-pointer"
                           onClick={() => setProductLine(i)}
                        >
                           <Image src={i.mainImage} size="w-12 h-12" />
                           <div>{i.name}</div>
                        </div>
                     ))}
                  </div>
               )}
            </div>

            <div className="space-y-2">
               <div>
                  <label htmlFor="">Batch Qty 生产数量:</label>
               </div>
               <Input
                  type="number"
                  className="text-center"
                  value={batchQty || 0}
                  onChange={(e) => setBatchQty(parseInt(e.target.value))}
               />
            </div>

            <div className="space-y-2">
               <div>
                  <label htmlFor="">Finished Qty 完成数量:</label>
               </div>
               <Input
                  type="number"
                  className="text-center"
                  value={finishedQty || 0}
                  onChange={(e) => setFinishQty(parseInt(e.target.value))}
               />
            </div>

            <div className="space-y-2">
               <div>
                  <label htmlFor="">Inspection Qty 检查数量:</label>
               </div>
               <Input
                  type="number"
                  className="text-center"
                  value={checkQty || 0}
                  onChange={(e) => setCheckQty(parseInt(e.target.value))}
               />
            </div>

            {message ? (
               <div className=" text-pink-600 font-semibold">{message}</div>
            ) : null}
            <hr />

            <div>
               <Button
                  bold
                  size="xl"
                  title="Next"
                  next
                  loading={createInlineReportRes.loading}
                  disabled={
                     createInlineReportRes.loading ||
                     factoryId === null ||
                     productLine === null ||
                     batchQty === 0 ||
                     message != null
                  }
                  onClick={() =>
                     createInlineReport({
                        variables: {
                           factoryId,
                           productLineId: productLine ? productLine.id : null,
                           date: formatDate(date, 'YYYY-MM-DD'),
                           finishedQty,
                           batchQty,
                           checkQty,
                        },
                     })
                  }
               />
            </div>
         </div>
      </div>
   );
};

export default InlineReportCreateForm;
