import { gql, useMutation } from "@apollo/client";
import CharlesButton from "components/charles/base";
import { Alert } from "components/Toast";

const REJECT_PRODUCTION_PLAN = gql`
  mutation($planId: ID!) {
    rejectProductionPlan(planId: $planId) {
      productionPlan {
        id
        state
        requestConfirmAt
        confirmTimeAt
        confirmCostAt
        readyDate
      }
    }
  }
`;

const RejectPlanView = ({ onCompleted, plan }) => {
  const [rejectPlan, rejectPlanRes] = useMutation(REJECT_PRODUCTION_PLAN, {
    onCompleted() {
      Alert("success", "Updated.");
      if (onCompleted) onCompleted();
    },
    onError(error) {
      Alert("error", error.message);
    },
    refetchQueries: ["FETCH_PREV_PLANS"],
  });

  return (
    <div className="text-sm">
      <div className="space-y-2">
        <p>Are you sure to reject this plan? </p>
        <p>
          Rejecting a plan will release the stock and production time, this plan will be set to DRAFT status and will be compute again base on the latest stock
          and production status.
        </p>
      </div>

      <hr />

      <div className="flex space-x-6">
        <CharlesButton
          className="text-lg"
          loading={rejectPlanRes.loading}
          onClick={() =>
            rejectPlan({
              variables: {
                planId: plan.id,
                cancelLaterPlans: false,
              },
            })
          }
        >
          Confirm Reject
        </CharlesButton>
      </div>
    </div>
  );
};

export default RejectPlanView;
