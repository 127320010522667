export function searchByProp(item = {}, props = [], searchText = "") {
  if (searchText === null) return false;

  const text = searchText.toLowerCase();
  if (text === "") return true;

  const toSearch = text.split(",").filter((i) => i !== "");

  const founded = toSearch.some((text) => {
    const x = text.replace(/[^a-zA-Z0-9]+/g, "");
    for (let i = 0; i < props.length; i++) {
      const p = props[i];
      if (
        item[p] &&
        String(item[p]) &&
        String(item[p])
          .toLowerCase()
          .replace(/\s/g, "")
          .replace(/[^a-zA-Z0-9]+/g, "")
          .indexOf(x) > -1
      )
        return true;
    }
    return false;
  });

  return founded;
}
