import { gql, useQuery } from '@apollo/client';
import { InlineSpinner } from 'components/Spinner';
import Errors from 'components/Errors';
import { Tag } from 'components/base';

const FETCH_ODOO_PRODUCT_ATTRIBUTE_VALUES = gql`
   query FETCH_ODOO_PRODUCT_ATTRIBUTE_VALUES($id: Int!) {
      odooProductTemplate(id: $id)
   }
`;

const OdooProductAttributeValues = ({ id }) => {
   const { loading, error, data } = useQuery(
      FETCH_ODOO_PRODUCT_ATTRIBUTE_VALUES,
      {
         variables: { id },
      }
   );

   if (loading) return <InlineSpinner />;
   if (error) return <Errors error={error} />;

   const productTemplate = JSON.parse(data.odooProductTemplate);

   return (
      <div className="flex items-center space-x-4">
         <label className="font-bold">Current Variants: </label>
         {productTemplate.attribute_values.length > 0 ? (
            <div className="w-full relative flex items-center space-x-4">
               {productTemplate.attribute_values.map((i) => (
                  <Tag key={i.id} title={i.name} />
               ))}
            </div>
         ) : (
            <div>This product template does not contain any variants.</div>
         )}
      </div>
   );
};

export default OdooProductAttributeValues;
