import { gql, useMutation } from "@apollo/client";
import { FileSelector, Input, Select } from "components/Form";
import Page from "components/Page";
import { Alert } from "components/Toast";
import { useState } from "react";
import FSIOrders from "./FSIOrders";
import Excel from "exceljs";
import moment from "moment";
import { useModals } from "ModalProvider";
import CharlesButton from "components/charles/base";
import ErrorsView from "./ErrorsView";

const FSI_CREATE_ORDERS = gql`
  mutation FSI_CREATE_ORDERS($orders: String!) {
    fsiCreateOrders(orders: $orders) {
      orders {
        id
        orderNumber
        status
      }
    }
  }
`;

const addressFields = [
  { field: "name", maxLength: 35 },
  { field: "contact", maxLength: 35 },
  { field: "address1", maxLength: 35 },
  { field: "address2", maxLength: 35 },
  { field: "city", maxLength: 30 },
  { field: "state", maxLength: 30 },
  { field: "postalCode", maxLength: 20 },
  { field: "phone", maxLength: 20 },
  { field: "email", maxLength: 100 },
];

const FsiPage = () => {
  const { present, hide } = useModals();

  const xlsxReader = new FileReader();
  const wb = new Excel.Workbook();

  xlsxReader.onload = function (e) {
    wb.xlsx
      .load(e.target.result)
      .then(handleQbXlsx)
      .catch((error) => {
        Alert("error", `Fail to read xlsx file. Error: ${error}`);
      });
  };

  function extractOrders(wb) {
    let orders = {};
    let errors = [];

    const ws = wb.getWorksheet("Sheet1");

    for (var i = 2; i <= ws.rowCount; i++) {
      const row = ws.getRow(i);

      const item = {
        itemNumber: row.getCell("N").value,
        quantity: row.getCell("O").value,
      };

      const orderNumber = row.getCell("A").value;

      if (orderNumber)
        if (orders[orderNumber]) {
          orders[orderNumber].lineItems.push(item);
        } else {
          const address = {
            name: row.getCell("E").text,
            contact: row.getCell("F").text,
            address1: row.getCell("G").text,
            address2: row.getCell("H").text,
            city: row.getCell("I").text,
            state: row.getCell("J").text,
            postalCode: row.getCell("K").text,
            countryCode: "US",
            phone: row.getCell("L").text,
            email: row.getCell("M").text,
          };

          addressFields.forEach(({ field, maxLength }) => {
            if (address[field].length > maxLength) {
              errors.push(
                `Order ${orderNumber} - ${field.charAt(0).toUpperCase() + field.slice(1)} is too long: ${
                  address[field]
                }. Max length is ${maxLength} characters.`
              );
            }
          });

          orders[orderNumber] = {
            orderNumber,
            alternateOrderNumber: row.getCell("S").text,
            orderDate: moment(row.getCell("B").value).format("YYYY-MM-DD"),
            shippingCode: row.getCell("D").text,
            shippingAddress: address,
            billingAddress: address,
            lineItems: [item],
            tradingPartner: "WHOLE",
            orderMessage: row.getCell("P").text,
          };
        }
    }
    return { orders, errors };
  }

  function handleQbXlsx(wb) {
    const { orders, errors } = extractOrders(wb);
    if (errors.length > 0) {
      present({
        title: "Errors",
        children: <ErrorsView errors={errors} />,
      });
    } else {
      present({
        title: "Create Orders",
        hideChildren: true,
        children: <NewOrders initialOrders={Object.values(orders)} hide={hide} />,
      });
    }
  }

  return (
    <Page title="FSI" subtitle="Create Orders to FSI from an excel file exported from QuickBooks.">
      <div className="">
        <div className="p-6">
          <FileSelector
            bold
            title="Import QB XLSX"
            accept=".xlsx"
            onChange={async (e) => {
              const file = e.target.files[0];
              xlsxReader.readAsArrayBuffer(file);
              e.target.value = null;
            }}
          />
          <div className="mt-1 opacity-70">
            Make sure the file you upload contains a sheet named as <b>Sheet1</b> and the columns are "Num Date | Ship Date | Via |Name | Name Contact | Ship To
            Address 1 | Ship To Address 2 | Ship To City | Ship To State | Ship Zip | Name Phone # | Name E-Mail | Item | Qty | Other 1 | Shipping # | Order
            Class | P. O. #".{" "}
            <a className="underline" href="https://cdn.waboba.com/public/QB+10252021+-+2.xlsx">
              Download example file
            </a>
            .
          </div>
        </div>

        <FSIOrders />
      </div>
    </Page>
  );
};

const TRADING_PARTNERS = [
  { code: "RJ-CAPE", name: "Ron Jon Surf Shop - Cape Canaveral" },
  { code: "RJ-ORANG", name: "Ron Jon Surf Shop - Orange Beach" },
  { code: "RJ-OCEAN", name: "Ron Jon Surf Shop - Ocean City" },
  { code: "RJ-MYRT", name: "Ron Jon Surf Shop - Myrtle Beach" },
  { code: "RJ-PCB", name: "Ron Jon Surf Shop - Panama City Beach" },
  { code: "RJ-FtM", name: "Ron Jon Surf Shop - Ft Myers" },
  { code: "RJ-NMYRT", name: "Ron Jon Surf Shop - North Myrtle Beach" },
  { code: "RJ-FESTBAY", name: "Ron Jon Surf Shop - Festival Bay" },
  { code: "RJ-SUN", name: "Ron Jon Surf Shop- Sunrise" },
  { code: "WHOLE", name: "Waboba Wholesale" },
  { code: "WHOLE-SC", name: "Waboba Wholesale Ship Complete" },
  { code: "RJ-NJ", name: "Ron Jon Surf Shop - New Jersey" },
  { code: "TROP", name: "Tropical Waves" },
  { code: "BCHUNLMT", name: "Beach Unlimited" },
  { code: "MARCO", name: "Marco Destin" },
  { code: "ALVIN", name: "Alvin's Island" },
  { code: "USVIN", name: "US Vintage" },
  { code: "BELK", name: "Belk Stores" },
  { code: "SCHEEL", name: "Scheels" },
  { code: "PACFLY", name: "Pacific Flvwav Wholesale" },
  { code: "RCI", name: "Retail Concepts Inc" },
  { code: "MEIJER", name: "Mailer" },
  { code: "FLEFT", name: "Fleet Farm" },
  { code: "HEB", name: "HEB" },
  { code: "DICKS", name: "Dicks Sporting Goods" },
  { code: "CHEWY", name: "Chewy.com" },
  { code: "BBB", name: "Bed Bath & Beyond" },
  { code: "BIG5", name: "Big 5 Sporting Goods" },
  { code: "BASS", name: "Bass Pro Shops" },
  { code: "ACADEMY", name: "Academy Sports" },
  { code: "DEFAULT", name: "DEFAULT" },
];

const NewOrders = ({ initialOrders, hide }) => {
  const [orders, setOrders] = useState(initialOrders);

  const [createOrder, createOrderRes] = useMutation(FSI_CREATE_ORDERS, {
    onCompleted(res) {
      const failOrders = res.fsiCreateOrders.orders.filter((order) => order.status === "fail");
      if (failOrders.length > 0) {
        Alert("error", `Fail to create ${failOrders.length} order${failOrders.length > 1 ? "s" : ""}.`);
      } else {
        Alert("success", "Orders created Success.");
      }
      hide();
    },
    onError(error) {
      Alert("error", error.message);
    },
    refetchQueries: ["FETCH_FSI_ORDERS"],
    awaitRefetchQueries: true,
  });

  return (
    <div>
      <div className="space-y-8">
        {orders.map((order, index) => (
          <div key={index} className="space-y-4">
            <div className="flex items-baseline space-x-6">
              <h4>{order.orderNumber}</h4>
              <div>{order.orderDate}</div>
            </div>
            <div>
              <label htmlFor="">alternateOrderNumber / PO: </label>
              {order.alternateOrderNumber}
            </div>
            <div>
              <label htmlFor="">Order Message: </label>
              {order.orderMessage}
            </div>
            <div className="flex space-x-8 items-center">
              <div className="flex space-x-2">
                <label>Shipping Code:</label>
                <div>{order.shippingCode}</div>
              </div>

              <div className="flex space-x-2 items-center">
                <label htmlFor="">Trading Partner:</label>
                <Select
                  value={order.tradingPartner}
                  onChange={(e) =>
                    setOrders((prev) =>
                      prev.map((prevOrder, prevIndex) => {
                        if (index === prevIndex)
                          return {
                            ...prevOrder,
                            tradingPartner: e.target.value,
                          };
                        return prevOrder;
                      })
                    )
                  }
                >
                  {TRADING_PARTNERS.map((i) => (
                    <option key={i.code} value={i.code}>
                      [{i.code}] {i.name}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
            <div className="flex space-x-8">
              <div>
                <label htmlFor="">Shipping Address:</label>
                <div>{order.shippingAddress.name}</div>
                <div>{order.shippingAddress.address1}</div>
                <div>{order.shippingAddress.address2}</div>
                <div>{order.shippingAddress.city}</div>
                <div>{order.shippingAddress.state}</div>
                <div>{order.shippingAddress.postalCode}</div>
                <div>
                  <Input
                    value={order.shippingAddress.countryCode}
                    onChange={(e) =>
                      setOrders((prev) =>
                        prev.map((prevOrder, prevIndex) => {
                          if (index === prevIndex)
                            return {
                              ...prevOrder,
                              shippingAddress: {
                                ...prevOrder.shippingAddress,
                                countryCode: e.target.value,
                              },
                            };
                          return prevOrder;
                        })
                      )
                    }
                  />
                </div>
              </div>
              <div>
                <label htmlFor="">Billing Address:</label>
                <div>{order.billingAddress.name}</div>
                <div>{order.billingAddress.address1}</div>
                <div>{order.billingAddress.address2}</div>
                <div>{order.billingAddress.city}</div>
                <div>{order.billingAddress.state}</div>
                <div>{order.billingAddress.postalCode}</div>
                <div>
                  <Input
                    placeholder="Country Code"
                    value={order.billingAddress.countryCode}
                    onChange={(e) =>
                      setOrders((prev) =>
                        prev.map((prevOrder, prevIndex) => {
                          if (index === prevIndex)
                            return {
                              ...prevOrder,
                              billingAddress: {
                                ...prevOrder.billingAddress,
                                countryCode: e.target.value,
                              },
                            };
                          return prevOrder;
                        })
                      )
                    }
                  />
                </div>
              </div>
            </div>

            <div>
              <label htmlFor="">Items: </label>
              <div className="mt-1">
                {order.lineItems.map((i, index) => (
                  <div key={index} className="py-2 border-t dark:border-gray-700 flex justify-between">
                    <div>[{i.itemNumber}]</div>
                    <div>&times; {i.quantity}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="mt-8">
        <CharlesButton loading={createOrderRes.loading} onClick={() => createOrder({ variables: { orders: JSON.stringify(orders) } })}>
          Create Orders to FSI
        </CharlesButton>
      </div>
    </div>
  );
};

export default FsiPage;
