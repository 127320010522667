import { gql, useLazyQuery, useMutation } from '@apollo/client';
import CharlesButton from 'components/charles/base';
import { DatePicker, Input, Select, Text } from 'components/Form';
import { Alert } from 'components/Toast';
import { CurrencyContext } from 'CurrencyProvider';
import { useModals } from 'ModalProvider';
import { useContext } from 'react';
import { useState } from 'react';
import { BsTrash } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import DeleteInvoiceView from './DeleteInvoiceView';
import SubSupplierOrdersSelector from './SubSupplierOrdersSelector';

const SAVE_INVOICE = gql`
   mutation SAVE_SUB_SUPPLIER_INVOICE(
      $id: ID!
      $orderId: ID
      $number: String
      $currency: String
      $invoiceDate: DateTime
      $dueDate: DateTime
      $lines: [SubSupplierOrderInvoiceLineInputType]
      $remark: String
   ) {
      saveSubSupplierInvoice(
         id: $id
         orderId: $orderId
         number: $number
         currency: $currency
         invoiceDate: $invoiceDate
         dueDate: $dueDate
         lines: $lines
         remark: $remark
      ) {
         invoice {
            id
            number
            currency
            invoiceDate
            dueDate
            lines {
               id
               name
               qty
               unitPrice
            }
            remark
            total
         }
      }
   }
`;

const FETCH_ODOO_PURCHASE_ORDER = gql`
   query FETCH_ODOO_PURCHASE_ORDER($orderId: ID!) {
      subSupplierOrder(id: $orderId) {
         id
         lines {
            id
            name
            qty
            unitPrice
         }
      }
   }
`;

const InvoiceForm = ({ originalInvoice, onSaved }) => {
   const [invoice, setInvoice] = useState({
      ...originalInvoice,
      orderId: originalInvoice.order ? originalInvoice.order.id : null,
      invoiceDate: originalInvoice.invoiceDate
         ? new Date(originalInvoice.invoiceDate)
         : new Date(),
      dueDate: originalInvoice.dueDate
         ? new Date(originalInvoice.dueDate)
         : new Date(),
   });
   const [saveInvoice, saveInvoiceRes] = useMutation(SAVE_INVOICE, {
      onCompleted() {
         Alert('success', 'Invoice Saved.');
         if (onSaved) onSaved();
      },
      onError(error) {
         Alert('error', error.message);
      },
   });
   const [fetchOdooPurchaseOrder, fetchOdooPurchaseOrderRes] = useLazyQuery(
      FETCH_ODOO_PURCHASE_ORDER
   );
   const modal = useModals();
   const navigate = useNavigate();

   async function importLinesFromOdooPurchaseOrder() {
      try {
         const { data } = await fetchOdooPurchaseOrder({
            variables: { orderId: invoice.orderId },
         });
         console.log('data', data);
         setInvoice((prev) => ({
            ...prev,
            lines: [
               ...prev.lines,
               ...data.subSupplierOrder.lines.map((i) => ({
                  name: i.name,
                  qty: i.qty,
                  unitPrice: i.unitPrice,
               })),
            ],
         }));
      } catch (error) {
         Alert('error', error.message);
      }
   }

   function saveHandler() {
      saveInvoice({
         variables: {
            id: invoice.id,
            orderId: invoice.orderId,
            number: invoice.number,
            currency: invoice.currency,
            invoiceDate: invoice.invoiceDate,
            dueDate: invoice.dueDate,
            lines: invoice.lines.map((i) => ({
               name: i.name,
               qty: i.qty,
               unitPrice: i.unitPrice,
            })),
            remark: invoice.remark,
         },
      });
   }

   function onChangeLine(index, values) {
      setInvoice((prev) => ({
         ...prev,
         lines: prev.lines.map((prevLine, prevIndex) =>
            prevIndex === index ? { ...prevLine, ...values } : prevLine
         ),
      }));
   }

   const total = invoice.lines.reduce(
      (res, line) => res + line.qty * line.unitPrice,
      0
   );

   function tryDeleteInvoce() {
      modal.present({
         title: 'Delete Invoice',
         center: true,
         maxWidth: 'max-w-xl',
         children: (
            <DeleteInvoiceView
               invoice={invoice}
               onDeleted={() => {
                  navigate('/sub-supplier/invoices/');
               }}
               hide={modal.hide}
            />
         ),
      });
   }

   return (
      <div>
         <div className="space-y-3">
            <div className="flex items-center space-x-2">
               <label className="w-32">Order: </label>
               <SubSupplierOrdersSelector
                  value={invoice.orderId}
                  onChange={(orderId) =>
                     setInvoice((prev) => ({
                        ...prev,
                        orderId,
                     }))
                  }
               />
            </div>

            <div className="flex items-center space-x-2">
               <label className="w-32">Invoice Number: </label>
               <Input
                  className="text-center"
                  value={invoice.number}
                  onChange={(e) =>
                     setInvoice((prev) => ({
                        ...prev,
                        number: e.target.value,
                     }))
                  }
               />
            </div>

            <div className="flex items-center space-x-2">
               <label className="w-32">Currency: </label>
               <Select
                  value={invoice.currency}
                  onChange={(e) =>
                     setInvoice((prev) => ({
                        ...prev,
                        currency: e.target.value,
                     }))
                  }
               >
                  <option value="RMB">RMB</option>
                  <option value="USD">USD</option>
               </Select>
            </div>

            <div className="flex items-center space-x-2">
               <label className="w-32">Invoice Date: </label>
               <DatePicker
                  value={invoice.invoiceDate}
                  onDayChange={(invoiceDate) =>
                     setInvoice((prev) => ({ ...prev, invoiceDate }))
                  }
               />
            </div>

            <div className="flex items-center space-x-2">
               <label className="w-32">Due Date: </label>
               <DatePicker
                  value={invoice.dueDate}
                  onDayChange={(dueDate) =>
                     setInvoice((prev) => ({ ...prev, dueDate }))
                  }
               />
            </div>
         </div>

         <hr />

         <div className="space-y-4">
            <h5>Details</h5>
            {invoice.lines.length > 0 ? (
               <div className="-mx-2">
                  <table>
                     <thead>
                        <tr className="text-gray-500 uppercase">
                           <th className="text-left">Item</th>
                           <th className="text-center">QTY</th>
                           <th className="text-center">Unit Pice</th>
                           <th className="text-right">Subtotal</th>
                        </tr>
                     </thead>
                     <tbody>
                        {invoice.lines.map((line, index) => (
                           <tr key={index}>
                              <td className="py-1 w-full">
                                 <div className="flex items-center space-x-2">
                                    <CharlesButton
                                       onClick={() =>
                                          setInvoice((prev) => ({
                                             ...prev,
                                             lines: prev.lines.filter(
                                                (_, prevIndex) =>
                                                   prevIndex !== index
                                             ),
                                          }))
                                       }
                                    >
                                       <BsTrash />
                                    </CharlesButton>

                                    <Input
                                       className="w-full"
                                       value={line.name}
                                       onChange={(e) =>
                                          onChangeLine(index, {
                                             name: e.target.value,
                                          })
                                       }
                                    />
                                 </div>
                              </td>
                              <td className="text-right py-1">
                                 <Input
                                    className="text-center"
                                    type="number"
                                    value={line.qty}
                                    onChange={(e) =>
                                       onChangeLine(index, {
                                          qty: e.target.value,
                                       })
                                    }
                                 />
                              </td>
                              <td className="text-right py-1">
                                 <Input
                                    className="text-center"
                                    type="number"
                                    value={line.unitPrice}
                                    onChange={(e) =>
                                       onChangeLine(index, {
                                          unitPrice: e.target.value,
                                       })
                                    }
                                 />
                              </td>
                              <td className="text-right py-1">
                                 {(line.qty * line.unitPrice).toFixed(2)}
                              </td>
                           </tr>
                        ))}
                     </tbody>

                     <tfoot className="font-semibold text-base">
                        <tr>
                           <td className="py-3" colSpan={3}>
                              Total
                           </td>
                           <td className="text-right">{total.toFixed(2)}</td>
                        </tr>
                     </tfoot>
                  </table>
               </div>
            ) : null}

            <div className="flex space-x-8">
               <CharlesButton
                  title="+ Add Line"
                  onClick={() => {
                     setInvoice((prev) => ({
                        ...prev,
                        lines: [
                           ...prev.lines,
                           { name: '', qty: 1, unitPrice: 0 },
                        ],
                     }));
                  }}
               >
                  + Add Line
               </CharlesButton>
               {invoice.order ? (
                  <CharlesButton
                     onClick={importLinesFromOdooPurchaseOrder}
                     loading={fetchOdooPurchaseOrderRes.loading}
                  >
                     Import from PO
                  </CharlesButton>
               ) : null}
            </div>
         </div>

         <hr />

         <div>
            <label htmlFor="">Remark</label>
            <Text
               className="mt-2"
               value={invoice.remark}
               onChange={(e) =>
                  setInvoice((prev) => ({ ...prev, remark: e.target.value }))
               }
            />
         </div>

         <hr />

         <div className="flex justify-between">
            <CharlesButton
               primary
               onClick={saveHandler}
               loading={saveInvoiceRes.loading}
            >
               Save
            </CharlesButton>
            <CharlesButton danger onClick={tryDeleteInvoce}>
               Delete Invoice
            </CharlesButton>
         </div>
      </div>
   );
};

export default InvoiceForm;
