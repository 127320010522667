import { useQuery, gql } from '@apollo/client';

const FETCH_PRODUCTS = gql`
   {
      allProducts(productType: "normal") {
         id
         name
         number
         odooId
         quantityPerCarton
         outerCartonCbm
         ctnNetWeight
         ctnGrossWeight
         outerCartonCbm
         createdAt
         isActive
         productLine {
            id
         }
      }
   }
`;

export const useProducts = () => {
   const { loading, error, data } = useQuery(FETCH_PRODUCTS);
   const allProducts = data ? data.allProducts : null;
   return { loading, error, allProducts };
};

const FETCH_PRODUCTS_WITH_CHILDREN = gql`
   query FETCH_PRODUCTS_WITH_CHILDREN {
      allProducts: productsWithChildren {
         id
         odooId
         name
         number
         itemsPerSet
         productionCategory {
            id
            name
         }
         production {
            id
         }
         children {
            id
            product {
               id
            }
            qty
         }
      }
   }
`;

export const useProductsWithChildren = () => {
   const { loading, error, data } = useQuery(FETCH_PRODUCTS_WITH_CHILDREN);
   const allProducts = data ? data.allProducts : null;
   return { loading, error, allProducts };
};

const FETCH_PRODUCT_IMAGES = gql`
   query FETCH_PRODUCT_IMAGES($id: ID!) {
      product(id: $id) {
         id
         images {
            url
            thumb
         }
      }
   }
`;

export const useProductImages = (id) => {
   const { loading, error, data } = useQuery(FETCH_PRODUCT_IMAGES, {
      variables: { id },
   });

   const images = data ? data.product.images : null;
   return { loading, error, images };
};
