import { gql, useMutation } from '@apollo/client';
import CharlesButton from 'components/charles/base';
import { Input } from 'components/Form';
import { Alert } from 'components/Toast';
import { useState } from 'react';

const DUPLICATE_PRODUCTION_PLAN = gql`
   mutation DUPLICATE_PRODUCTION_PLAN($id: ID!, $name: String) {
      duplicateProductionPlan(id: $id, name: $name) {
         productionPlan {
            id
         }
      }
   }
`;

const DuplicatePlanView = ({ originalPlan, complete }) => {
   const [name, setName] = useState('Duplicate of ' + originalPlan.name);
   const [duplicate, duplicateRes] = useMutation(DUPLICATE_PRODUCTION_PLAN, {
      variables: { id: originalPlan.id, name },
      onCompleted(res) {
         Alert('success', 'Duplicated Success.');
         if (complete) complete(res);
      },
      onError(error) {
         Alert('error', error.message);
      },
   });

   return (
      <div>
         <div>
            Duplicate Plan: <b>{originalPlan.name}</b>?
         </div>

         <div className="mt-4 space-x-3 flex items-center   ">
            <label htmlFor="">Name: </label>
            <Input
               className="flex-1"
               value={name}
               onChange={(e) => setName(e.target.value)}
            />
         </div>

         <div className="mt-4 text-xs opacity-70">
            Duplicate a plan will duplicate the basic info and products of a
            source plan. WIS will compute the ready date as a new plan after the
            source one.
         </div>

         <hr />

         <div>
            <CharlesButton
               primary
               onClick={duplicate}
               loading={duplicateRes.loading}
            >
               Confirm
            </CharlesButton>
         </div>
      </div>
   );
};

export default DuplicatePlanView;
