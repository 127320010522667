import React, { useState, useEffect } from 'react';
import { useMutation, gql } from '@apollo/client';
import { Button } from 'components/base';
import { Alert } from 'components/Toast';
import { Input, Text } from 'components/Form';
import { FETCH_ALL_LINKS } from './index';

const SAVE_LINK = gql`
   mutation SAVE_LINK(
      $name: String!
      $originLink: String!
      $slug: String!
      $description: String
      $id: ID
   ) {
      saveLink(
         name: $name
         originLink: $originLink
         slug: $slug
         description: $description
         id: $id
      ) {
         link {
            id
            name
            originLink
            slug
            shorternLink
            description
         }
      }
   }
`;

const DELTE_LINK = gql`
   mutation DELETE_LINK($id: ID!) {
      deleteLink(id: $id) {
         deleted
      }
   }
`;

const DeleteButton = ({ id, didDelete }) => {
   const [deleteLink, deleteLinkRes] = useMutation(DELTE_LINK, {
      variables: { id },
      onCompleted: () => {
         Alert('sucess', 'Link Deleted.');
         didDelete();
      },
      onError: (error) => Alert('error', error.message),
      refetchQueries: [{ query: FETCH_ALL_LINKS }],
   });
   return (
      <Button
         disabled={deleteLinkRes.loading}
         loading={deleteLinkRes.loading}
         title="Delete Link"
         color="red"
         onClick={() => {
            if (window.confirm('Are you sure to delete this link?')) {
               deleteLink();
            }
         }}
      />
   );
};

const LinkForm = ({ link, hide }) => {
   const [name, setName] = useState(link ? link.name : '');
   const [originLink, setOriginLink] = useState(link ? link.originLink : '');
   const [slug, setSlug] = useState(link ? link.slug : '');
   const [description, setDescription] = useState(link ? link.description : '');
   const [createLink, createLinkRes] = useMutation(SAVE_LINK, {
      variables: {
         id: link ? link.id : null,
         name,
         originLink,
         slug,
         description,
      },
      onCompleted: hide,
      onError: (error) => Alert('error', error.message),
      refetchQueries: [{ query: FETCH_ALL_LINKS }],
   });

   useEffect(() => {
      setName(link ? link.name : '');
      setOriginLink(link ? link.originLink : '');
      setSlug(link ? link.slug : '');
      setDescription(link ? link.description : '');
   }, [link]);

   return (
      <div className="">
         <div className="flex items-baseline">
            <label className="w-32 mr-4">Name: </label>
            <Input
               placeholder="Give a name for this link, for example: Moon Product Page."
               className="flex-1"
               value={name}
               onChange={(e) => setName(e.target.value)}
            />
         </div>
         <div className="flex items-baseline mt-4">
            <label className="w-32 mr-4">Shorten Link: </label>
            <div className="flex flex-1 items-baseline">
               <div>{process.env.REACT_APP_SHORT_LINK_ORIGIN}/</div>
               <Input
                  placeholder="whatever"
                  className="flex-1 ml-2"
                  value={slug}
                  onChange={(e) => setSlug(e.target.value)}
               />
            </div>
         </div>
         <div className="flex items-baseline mt-4">
            <label className="w-32 mr-4">Origin Link: </label>
            <Input
               className="flex-1"
               value={originLink}
               onChange={(e) => setOriginLink(e.target.value)}
            />
         </div>
         <div className="flex items-start mt-4">
            <label className="w-32 mr-4">Description: </label>
            <Text
               placeholder="Optional, write down anything in case you need to know later.
               "
               className="flex-1"
               value={description}
               onChange={(e) => setDescription(e.target.value)}
            />
         </div>

         <div className="flex items-center mt-4">
            <label className="w-32 mr-4"></label>
            <Button
               loading={createLinkRes.loading}
               disabled={createLinkRes.loading}
               bold
               title="Save"
               size="lg"
               onClick={createLink}
            />
            {link ? (
               <div className="ml-4">
                  <DeleteButton id={link.id} didDelete={hide} />
               </div>
            ) : null}
         </div>
      </div>
   );
};

export default LinkForm;
