import { useMutation } from "@apollo/client";
import { Button } from "components/base";
import Spinner from "components/Spinner";
import { Alert } from "components/Toast";
import { formatDate } from "react-day-picker/moment";
import NewSupplierSubmitProductionInfoView from "./NewSupplierSubmitProductionInfoView";
import { CONFIRM_PRODUCTION_FORM } from "./graphql";

const PreviewProductionForm = ({ previewForm, hide }) => {
  const data = JSON.parse(previewForm.data);

  const [confirm, { loading }] = useMutation(CONFIRM_PRODUCTION_FORM, {
    onCompleted: () => Alert("success", "Confirmed."),
    onError: (error) => Alert("error", error.message),
  });

  if (data instanceof Array)
    return (
      <div>
        <div>{formatDate(previewForm.createdAt, "YYYY-MM-DD")}</div>
        <div className="space-y-4 mt-4">
          {data.map((cat) => (
            <div key={cat.id}>
              <h4>{cat.name}</h4>
              <div className="opacity-60 mt-1">
                {cat.type === "BY_DAY" ? (
                  <div>{cat.qtyPerDay} / day</div>
                ) : (
                  <div>
                    {cat.timeList.map((time, timeIndex) => (
                      <div key={timeIndex}>
                        Qty: {time.qty} - {time.days} days.
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>

        <div className="mt-8 flex space-x-8 relative">
          <Button bold size="lg" title="Accept" onClick={() => confirm({ variables: { id: previewForm.id, confirmType: "accept" } })} disabled={loading} />
          <Button
            size="lg"
            color="red"
            title="Reject"
            onClick={() =>
              confirm({
                variables: { id: previewForm.id, confirmType: "reject" },
              })
            }
            disabled={loading}
          />

          {loading ? <Spinner /> : null}
        </div>
      </div>
    );

  return <NewSupplierSubmitProductionInfoView previewForm={previewForm} data={data} hide={hide} />;
};

export default PreviewProductionForm;
