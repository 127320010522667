import { IoIosCube } from "react-icons/io";

const AboutOSView = () => (
  <div className="opacity-60 mx-3 my-8 dark:border-gray-700 leading-loose">
    <p>
      <b>About Order Scheduler</b>
    </p>

    <p className="mt-2">
      Order scheduler auto compute the production ready date base on inventory(including history, forecasted qty, PO and MO expected dates).
    </p>

    <p>New plan will be always join the queue at the last.</p>

    <p>Plan will effect the later plans.</p>

    <p>Once the order plan is confirm in Odoo, this plan will not be in current plans anymore.</p>

    <p>Here are how it is computed:</p>

    <ul className="list-decimal list-inside">
      <li>
        Prepare the products and their qty, find all the required products thru their BOM in wis and the property "consider bom in production" tag. If this
        product has no BOM or NOT "consider bom in production", its required product is itself. Base on the qty of the BOM, we could compute the total required
        qty for each product. Note that if there are multiple lines of items in odoo for the same product, wis will combine them together into one line to
        compute a valid bom.
      </li>
      <li>To compute the correct ready date, we need to prepare the inventory data, which we fetch from the PO, MO, SO and Inventory adjustment in Odoo.</li>
      <li>We also consider the consumed stock qty in previous plans.</li>
      <li>
        We also consider the supplier stock usage. But we will only use the supplier stock qty only if it is enough for this plan. Otherwise we will leave this
        stock for smaller orders.
      </li>
      <li>We consider the production capacity by using extra compute method and date usages.</li>
      <li>Then we start to compute a ready date base on a start date.</li>
      <li>
        We start to loop thru each line for this plan, and for each line, we find the line product production nodes, then we put the product required
        products(from the BOM) into these nodes, and then put each production into a queue.
      </li>
      <li>
        Then we start to plan for the quee, for each production, it will has the production category info thru the production node category. We only compute the
        required product has valid data(like: production, has correct odoo id, etc). Here we compute the qty(including which PO it use) to consume, qty to
        produce, the supplier stock.
      </li>
      <li>
        Now for each production in this plan, we compute the ready date for each product line by recuring its production children nodes so that each production
        start after the prev step completed.
      </li>
    </ul>

    <p className="mt-2">
      For Odoo Stock computing: check the final forecasted qty, if it is enough for the request qty(minus the prev plans reseved qty), if the qty is enough to
      fulfill the plan, check if the PO ready date is before the plan ready date, if yes, use this PO for this plan. If the PO ready date is after the plan
      start date, check the prev PO forecasted qty recursively until to find the earleast PO whose forecaset qty is more than the request qty. If we find the PO
      forecasted qty is not enough, we will use the last found PO for this plan. If we find the PO ready date is already before the plan start date, we will use
      this PO for this plan.
    </p>

    <p className="mt-2">Customers are required to set the areas and connect Odoo thru the partner id.</p>

    <p className="mt-2">
      Note that order scheduler only compute one plan at a time to accure the ready date. If anyone is computing a plan, including changing the start date,
      recompute, cancel plan, change the plan order, etc, you will fail to do the same thing. In this case, you need to wait for the current plan to finish and
      try again later.
    </p>

    <p className="align-middle mt-2">
      <IoIosCube className="text-green-600 inline-block text-lg mr-2" />
      <span>Odoo Stock</span>

      <IoIosCube className="text-orange-600 inline-block text-lg mr-2 ml-4" />
      <span>Supplier Stock</span>
    </p>
  </div>
);

export default AboutOSView;
