import { useQuery } from "@apollo/client";
import Page from "components/Page";
import { useParams } from "react-router-dom";
import { FETCH_PRODUCT_LIST_FOR_DOC_CPC } from "./graphql";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import moment from "moment";
import { generateDocumentNumber } from "./documentNo";
import DOCForm from "./DOCForm";

const DocPage = () => {
  const { id } = useParams();
  const { loading, error, data } = useQuery(FETCH_PRODUCT_LIST_FOR_DOC_CPC, {
    variables: { id },
  });

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  let productList = {
    id,
    name: data.productList.name,
    number: data.productList.number,
    signDate: moment().format("YYYY-MM-DD"),
    sampleImages: data.productList.sampleImages,
  };

  const en71Keys = ["EN71:Part 1:2014+A1:2018", "EN71:Part 2:2020", "EN71-3:2019+A1:2021"];
  const reachKeys = ["Cadmium", "Phthalates", "PAHs", "AZO"];

  let reports = [];
  const foundReportEN71 = data.productList.testReports.find((report) => report.standard.name === "EN71-1/2/3");
  if (foundReportEN71) {
    if (foundReportEN71.fileList.length === 0) return null;

    const url = foundReportEN71.fileList[0];
    const { notifyBody, documentNo } = generateDocumentNumber(url);
    const date = moment(foundReportEN71.createdAt).format("YYYY-MM-DD");

    en71Keys.forEach((key) => {
      reports.push({ refNo: key, notifyBody, documentNo, title: key, date });
    });
  }

  const foundREACH = data.productList.testReports.find((report) => report.standard.name === "REACH AZO/REACH PAHs/REACH Pathalate/REACH Cadmium");
  if (foundREACH) {
    if (foundREACH.fileList.length === 0) return null;

    const url = foundREACH.fileList[0];
    const { notifyBody, documentNo } = generateDocumentNumber(url);
    const date = moment(foundREACH.createdAt).format("YYYY-MM-DD");

    reachKeys.forEach((key) => {
      reports.push({ refNo: key, notifyBody, documentNo, title: key, date });
    });
  }

  productList = { ...productList, reports };

  if (data.productList.docData) {
    const docData = JSON.parse(data.productList.docData);
    if (docData.status === "CONFIRMED") productList = { ...productList, ...docData };
  }

  return (
    <Page
      title={
        <div className="flex items-center">
          <div>DOC Report</div>
          <div className="font-normal pl-4 text-base">[beta]</div>
        </div>
      }
      backTo="./.."
    >
      <DOCForm originalValues={productList} />
      <div className="px-6 pb-6 opacity-70">
        <p>
          We use Product List data to generate a DOC Report. We check the uploaded files for test report EN71-1/2/3 and ASTM-F963/CPSIA/California P65. If we
          find it, we extract the number as the reference number and notify body from the uploaded filename. The images are from the product list sample images.
        </p>
        <p>Stella will complete, confirm or cancel the DOC Report depends on the data.</p>
      </div>
    </Page>
  );
};

export default DocPage;
