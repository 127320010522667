import { gql, useQuery } from "@apollo/client";
import Errors from "components/Errors";
import { InlineSpinner } from "components/Spinner";
import CharlesButton from "components/charles/base";
import AdditionPlanView from "./AdditionPlanView";

const FETCH_ADDITIONAL_PLANS = gql`
  query FETCH_ADDITIONAL_PLANS($id: ID!) {
    productionPlan(id: $id) {
      id
      state
      children {
        id
        orderType
        state
        name
        lines {
          id
          product {
            id
            number
            name
            quantityPerCarton
          }
          unitCost
          salesPrice
          totalCost
          finalQty
          totalCbm
          totalPrice
          readyDate
        }
        finalReadyDate
        finalTotalQty
        finalTotalCbm
        finalTotalCost
        finalTotalPrice
      }
    }
  }
`;

const AdditionPlansView = ({ id, tryAddAdditionalPlan }) => {
  const { loading, error, data } = useQuery(FETCH_ADDITIONAL_PLANS, {
    variables: { id },
  });

  if (loading)
    return (
      <div className="p-10 relative">
        <InlineSpinner />
      </div>
    );

  if (error) return <Errors error={error} />;

  return (
    <div className="space-y-8">
      {data.productionPlan.state === "HANDLED" && !data.productionPlan.parent ? (
        <div className="card mt-8 space-y-8">
          {data.productionPlan.children.length > 0 ? (
            <div className="space-y-8">
              {data.productionPlan.children.map((child) => (
                <AdditionPlanView key={child.id} plan={child} />
              ))}
            </div>
          ) : null}

          <div>
            <div className="opacity-70">
              The original plan is handled, you can only reduce the qty of the current plan, which will release the supplier stock if there is any. If you want
              to append new products or add qty, you add additional plans. This additional plans manage the supplier stock and help update products to Odoo.
            </div>
            <div className="mt-4">
              <CharlesButton onClick={tryAddAdditionalPlan}>+ Additional Plan</CharlesButton>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default AdditionPlansView;
