import { Select } from "components/Form";
import { useSearchParams } from "react-router-dom";
import SalesForecastView from "./SalesForecastView";

const SalesForecast = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const groupBy = searchParams.get("groupBy") || "CATEGORY";

  return (
    <div className="p-6 flex-1 overflow-auto">
      <div className="flex space-x-3 items-center">
        <label htmlFor="">Group by:</label>
        <Select value={groupBy} onChange={(e) => setSearchParams({ groupBy: e.target.value })}>
          <option value="CATEGORY">Category</option>
        </Select>
      </div>

      <SalesForecastView groupBy={groupBy} />
    </div>
  );
};

export default SalesForecast;
