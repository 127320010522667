import { Switcher } from "components/Form";
import { useContext } from "react";
import { ProductContext } from "./All/ProductForm";

const NotificationView = () => {
  const { simpleFields, onChangeSimpleFields } = useContext(ProductContext);

  return (
    <div>
      <h5>Notification</h5>
      <div className="mt-2">
        <div className="flex items-baseline justify-between space-x-4">
          <div>
            <label htmlFor="">Missing Beautyshots</label>
            <div className="text-xs opacity-70">Remind to add beautyshots to one drive.</div>
          </div>
          <div className="flex-shrink-0 flex">
            <span>&nbsp;</span>
            <Switcher
              isOn={simpleFields.reminderForMissingBeautyShot}
              onChange={() => onChangeSimpleFields("reminderForMissingBeautyShot", !simpleFields.reminderForMissingBeautyShot)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationView;
