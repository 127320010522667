import { gql, useQuery } from '@apollo/client';
import Errors from 'components/Errors';
import Spinner from 'components/Spinner';
import { Route, Routes } from 'react-router-dom';
import MaterialDetail from './MaterialDetail';
import SingleMaterial from './SingleMaterial';

const FETCH_ALL_MATERIALS = gql`
   query FETCH_ALL_MATERIALS {
      allMaterials(withCostOnly: true) {
         id
         name
      }
   }
`;

const MaterialRoutes = () => {
   return (
      <Routes>
         <Route index element={<Material />} />
         <Route path=":id" element={<MaterialDetail />} />
      </Routes>
   );
};

const Material = () => {
   const { loading, error, data } = useQuery(FETCH_ALL_MATERIALS);

   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;

   return (
      <div className="p-6">
         <div className="grid gap-6 grid-cols-2">
            {data.allMaterials.map((i) => (
               <div
                  className="bg-white dark:bg-gray-800 rounded-3xl shadow-sm px-8 py-6 relative"
                  style={{ minHeight: 200 }}
                  key={i.id}
               >
                  <SingleMaterial {...i} />
               </div>
            ))}
         </div>
      </div>
   );
};

export default MaterialRoutes;
