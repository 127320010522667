import { useQuery, useMutation, gql } from "@apollo/client";
import Errors from "components/Errors";
import { Select } from "components/Form";
import Spinner from "components/Spinner";
import { Alert } from "components/Toast";

const FETCH_INITIAL_DATA = gql`
  query FETCH_INITIAL_DATA {
    odooVendors {
      id
      name
      createDate
    }
    allFactories {
      id
      odooId
    }
  }
`;

const CONNECT_ODOO_VENDOR = gql`
  mutation CONNECT_ODOO_VENDOR($id: ID, $odooId: Int) {
    connectOdooVendor(id: $id, odooId: $odooId) {
      factory {
        id
        odooId
      }
    }
  }
`;

const NoOdooIdReminder = ({ className = "", factoryId }) => {
  const { loading, error, data } = useQuery(FETCH_INITIAL_DATA);
  const [connectOdooVendor, connectOdooVendorRes] = useMutation(CONNECT_ODOO_VENDOR, {
    onCompleted: () => Alert("success", "Updated."),
    onError: (error) => Alert("error", error.message),
  });

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const usedVendorIds = data.allFactories.filter((i) => i.odooId !== null).map((i) => i.odooId);

  return (
    <div className={`relative mt-4 bg-red-100 p-6 border border-red-600 rounded-xl ${className}`}>
      {connectOdooVendorRes.loading ? <Spinner /> : null}
      <div className="text-red-600 font-bold">
        This factory does not connect with Odoo Vendor. Select a vendor below from Odoo to setup a connection so that we could automate our workflow.
      </div>
      <Select
        className="mt-3"
        defaultValue="0"
        onChange={(e) => {
          connectOdooVendor({
            variables: { odooId: e.target.value, id: factoryId },
          });
        }}
      >
        <option value="0" disabled>
          --- Select a Vendor from Odoo ---
        </option>
        {data.odooVendors.map((vendor) => (
          <option key={vendor.id} value={vendor.id} disabled={usedVendorIds.indexOf(parseInt(vendor.id)) > -1}>
            {vendor.name}
          </option>
        ))}
      </Select>
    </div>
  );
};

export default NoOdooIdReminder;
