import React from 'react';
import { Route, Routes } from 'react-router-dom';
import CompareWithOdoo from './CompareWithOdoo';

const ALCWarehouse = () => (
   <Routes>
      <Route>
         <Route index element={<CompareWithOdoo />} />
      </Route>
   </Routes>
);

export default ALCWarehouse;
