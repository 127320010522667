import { gql, useMutation } from "@apollo/client";
import { parseError } from "apollo";
import { Switcher } from "components/Form";
import { Alert } from "components/Toast";
import CharlesButton from "components/charles/base";
import { useState } from "react";

const RECOMPUTE_PRODUCTION_PLAN = gql`
  mutation($planId: ID!, $sendNotification: Boolean) {
    recomputeProductionPlan(planId: $planId, sendNotification: $sendNotification) {
      plan {
        id
      }
    }
  }
`;

const TryRecomputeView = ({ planId, onCompleted }) => {
  const [sendNotification, setSendNotification] = useState(false);
  const [recomputePlan, recomputePlanRes] = useMutation(RECOMPUTE_PRODUCTION_PLAN, {
    variables: { planId, sendNotification },
    onCompleted(res) {
      Alert("success", "Recomputed Success.");
      onCompleted(res);
    },
    onError(error) {
      Alert("error", parseError(error));
    },
  });

  return (
    <div>
      <div className=" space-y-4">
        <div className="font-semibold">Are you sure to recompute this production plan?</div>
        <div className="leading-relaxed opacity-70">
          <p>
            This feature is designed for recomputing a plan when some production data is updated for a product, for example: bom update, production category /
            route updates, etc.
          </p>
          <p>Note that recompute a production plan might effect the production date for the later plans.</p>
        </div>
      </div>

      <hr />

      <div>
        <div className="flex items-center space-x-3">
          <label htmlFor="">Send Notification after Recompute: </label>
          <Switcher isOn={sendNotification} onChange={() => setSendNotification((prev) => !prev)} />
        </div>
        <div className="mt-2 opacity-70 text-sm">An email about this plan has been recomputed will be sent to the plan related users.</div>
      </div>

      <hr />

      <div className="flex space-x-8">
        <CharlesButton danger onClick={recomputePlan} loading={recomputePlanRes.loading}>
          Yes, Recompute Now.
        </CharlesButton>
        <CharlesButton>No</CharlesButton>
      </div>
    </div>
  );
};

export default TryRecomputeView;
