import { gql } from '@apollo/client';

export const FETCH_ALL_PATENT_TASKS = gql`
   query FETCH_ALL_PATENT_TASKS {
      patentTasksByGroup(group: "patent-search") {
         id
         name
         cpc
         startedAt
         stoppedAt
         success
      }

      allPatentTasksOrmq {
         id
         name
         startedAt
         cpc
      }
   }
`;


export const FETCH_PATENTS_BY_CPC = gql`
   query FETCH_PATENTS_BY_CPC($cpcSlash: String!) {
      patentsByCpc(cpc: $cpcSlash) {
         id
         number
         title
         status
         pdf
         summary
         isProcessedByAi
         images{
           uri
         }
      }
   }
`;


export const SAVE_PATENT_TASK = gql`
   mutation SAVE_PATENT_TASK($cpcSlash: String!, $csvUri: String!) {
      createPatentTask(cpc: $cpcSlash, csvUri: $csvUri) {
         ok
         taskid
      }
   }
`;