import { gql, useQuery } from '@apollo/client';
import Errors from 'components/Errors';
import Spinner from 'components/Spinner';
import ImagesPreview from 'pages/materials/beautyshots/ImagesPreview';

const FETCH_PRODUCT_IMAGES = gql`
   query FETCH_PRODUCT_IMAGES($id: ID!) {
      product(id: $id) {
         id
         images: computedImages {
            id
            url
         }
      }
   }
`;

const PreviewProductImages = ({ id }) => {
   const { loading, error, data } = useQuery(FETCH_PRODUCT_IMAGES, {
      variables: { id },
   });

   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;

   return <ImagesPreview images={data.product.images} />;
};

export default PreviewProductImages;
