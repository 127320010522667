import { gql, useQuery } from "@apollo/client";
import Errors from "components/Errors";
import Spinner from "components/Spinner";
import IncProductRow from "./IncProductRow";
import { useModals } from "ModalProvider";
import ChooseWisProductView from "./ChooseWisProductView";

const FETCH_INC_PRODUCTS = gql`
  query FETCH_INC_PRODUCTS {
    incProducts {
      id
      sku
      wisProduct {
        id
        number
        name
      }
    }
    products(isActive: true, productType: "normal", isSalable: true, hasOdooId: true) {
      total
      results {
        id
        number
        name
      }
    }
  }
`;

const IncProducts = () => {
  const { loading, data, error } = useQuery(FETCH_INC_PRODUCTS);
  const modal = useModals();

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  //   sort by not has wisProduct first
  const sortedProducts = data.incProducts;

  function showProductSelector(incProduct) {
    modal.present({
      title: incProduct.sku,
      children: <ChooseWisProductView incProduct={incProduct} products={data.products.results} hide={modal.hide} />,
    });
  }

  return (
    <div className="m-6 card p-0 h-full overflow-auto">
      <table>
        <thead>
          <tr className=" sticky top-0 z-10 bg-gray-50">
            <th className="py-3 px-4">ID</th>
            <th className="py-3 px-4">INC Item Number</th>
            <th className="px-4">WIS Product</th>
          </tr>
        </thead>
        <tbody>
          {sortedProducts.map((incProduct) => (
            <IncProductRow key={incProduct.id} incProduct={incProduct} showProductSelector={() => showProductSelector(incProduct)} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default IncProducts;
