import React from "react";
import Icon from "./Icon.js";

function CustomerAccount({ className = "", email }) {
   return (
      <div className={`${className} flex items-center`}>
         <Icon icon="user" size={18} />
         <span className="ml-2">{email}</span>
      </div>
   );
}

export default CustomerAccount;
