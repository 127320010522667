import { gql, useQuery } from "@apollo/client";
import { Button } from "components/base";
import Errors from "components/Errors";
import SearchBar from "components/SearchBar";
import Spinner from "components/Spinner";
import { useModals } from "ModalProvider";
import { useState } from "react";
import { searchByProp } from "utils/search";
import FolderDetail from "./FolderDetail";

const FETCH_ONE_DRIVE_FOLDER = gql`
  query FETCH_ONE_DRIVE_PRODUCTS {
    oneDriveChildren(path: "wis/beautyshots/wis")
  }
`;

const OneDrive = ({ id }) => {
  const { loading, error, data } = useQuery(FETCH_ONE_DRIVE_FOLDER, {
    variables: { id },
  });
  const [searchText, setSearchText] = useState("");
  const modal = useModals();

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const res = JSON.parse(data.oneDriveChildren).value;

  const products = res.filter((i) => searchByProp(i, ["name"], searchText));

  return (
    <div>
      <div className="p-6">
        <SearchBar placeholder="Search by name" text={searchText} onChange={setSearchText} />
        <div className="mt-2 text-xs opacity-70">
          <Button
            size="xs"
            link="https://wabobaadmin.sharepoint.com/sites/Waboba/Shared%20Documents/Forms/AllItems.aspx?RootFolder=%2Fsites%2FWaboba%2FShared%20Documents%2F💻%20IT%20Stuff%2Fbeautyshots%2Fwis&FolderCTID=0x012000ED1A44060EECF045B3DF12F1E8DEE39F"
            target="_blank"
            title="One Drive / Waboba / Documents / wis / beautyshots / wis."
          ></Button>
        </div>
      </div>
      <div>
        {products.map((i) => (
          <div
            key={i.id}
            className="py-2 px-6 border dark:border-gray-700 cursor-pointer hover:bg-gray-100 dark:bg-gray-700"
            onClick={() =>
              modal.present({
                title: "Sync Images from Onedrive to WIS",
                maxWidth: "max-w-7xl",
                children: <FolderDetail id={i.id} slug={i.name}></FolderDetail>,
              })
            }
          >
            <div>
              {i.name} ({i.folder ? i.folder.childCount : 0})
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OneDrive;
