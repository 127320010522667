import { DatePicker } from "components/Form";
import React from "react";
import { formatDate, parseDate } from "react-day-picker/moment";

function DateRangePicker({ className = "", start, end, setStart, setEnd }) {
  return (
    <div className={`${className} lg:flex`} data-testid="date-range-picker">
      <div className="flex items-center justify-between ">
        <label className="mr-2 w-32 lg:w-auto whitespace-no-wrap">Start Date:</label>
        <DatePicker
          formatDate={formatDate}
          parseDate={parseDate}
          format="YYYY-MM-DD"
          value={start}
          onDayChange={(selectedDay) => selectedDay && setStart(selectedDay)}
        />
      </div>
      <div className="flex items-center justify-between mt-2 lg:mt-0 lg:ml-4">
        <label className="mr-2 w-32 lg:w-auto whitespace-no-wrap">End Date:</label>
        <DatePicker formatDate={formatDate} format="YYYY-MM-DD" value={end} onDayChange={(selectedDay) => selectedDay && setEnd(selectedDay)} />
      </div>
    </div>
  );
}

export default DateRangePicker;
