import { gql, useQuery } from "@apollo/client";
import Errors from "./Errors";
import ImageLoader from "./Image";
import { InlineSpinner } from "./Spinner";

const FETCH_PRODUCT_IMAGES = gql`
  query FETCH_PRODUCT_IMAGES($id: ID!) {
    product(id: $id) {
      id
      images: computedImages {
        id
        url(size: "300x300")
      }
    }
  }
`;

const ProductImages = ({ id, showImages }) => {
  const { loading, error, data } = useQuery(FETCH_PRODUCT_IMAGES, {
    variables: { id },
  });

  if (loading)
    return (
      <div className="relative p-2">
        <InlineSpinner text={null} size={21} />
      </div>
    );
  if (error) return <Errors error={error} />;

  const images = data.product.images.length > 0 ? data.product.images : data.product.productLine ? data.product.productLine.newImages : [];

  return (
    <div
      className={`flex items-center justify-center space-x-2 flex-wrap
         ${showImages ? "cursor-pointer hover:opacity-60" : ""}`}
      onClick={showImages ? () => showImages(id) : null}
    >
      {images.map((i, index) => (
        <ImageLoader key={index} size="w-20 h-20 z-0" src={i.url} />
      ))}
    </div>
  );
};

export const ProductImagesV2 = ({ images, size = "w-12 h-12" }) => {
  return (
    <div className="flex flex-wrap">
      {images.map((i) => (
        <img className={`mr-2 mb-2 rounded-lg ${size}`} alt={i.url} src={i.url} key={i.id} />
      ))}
    </div>
  );
};

export default ProductImages;
