import { useMutation } from "@apollo/client";
import { Select } from "components/Form";
import { Alert } from "components/Toast";
import { INC_SAVE_ACTUAL_SALES } from "./graphql";
import { parseError } from "apollo";
import { useState } from "react";
import CharlesButton from "components/charles/base";
import moment from "moment";

const ImportActualSalesView = ({ salesItems, hide }) => {
  const [month, setMonth] = useState(new Date().getMonth());
  const [saveSales, saveSalesRes] = useMutation(INC_SAVE_ACTUAL_SALES, {
    onCompleted() {
      Alert("success", "Sales data saved successfully");
      hide();
    },
    onError(error) {
      Alert("error", parseError(error));
    },
  });

  function confirmHandler() {
    const date = moment().set("month", month).toDate();
    saveSales({
      variables: { salesItems, date: moment(date).format("YYYY-MM-DD") },
    });
  }

  return (
    <div>
      <div className="card p-0 overflow-auto max-h-96 -mx-1">
        <table>
          <thead>
            <tr className="sticky top-0 z-10 bg-gray-100">
              <th className="px-6 py-3">SKU ({salesItems.length})</th>
              <th className="px-6 text-right">Quantity</th>
            </tr>
          </thead>
          <tbody>
            {salesItems.map((item, index) => (
              <tr key={index} className="border-y border-gray-100 dark:border-gray-700">
                <td className="px-6">{item.sku}</td>
                <td className="px-6 text-right">{item.qty}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="mt-6 justify-between flex">
        <div className=" space-x-3">
          <label htmlFor="">Sales for month:</label>
          <Select value={month} onChange={(e) => setMonth(e.target.value)}>
            {Array(12)
              .fill()
              .map((_, i) => (
                <option key={i} value={i}>
                  {i + 1}
                </option>
              ))}
          </Select>
        </div>

        <CharlesButton onClick={confirmHandler} loading={saveSalesRes.loading} primary>
          Confirm Import
        </CharlesButton>
      </div>
    </div>
  );
};

export default ImportActualSalesView;
