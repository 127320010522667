import { useQuery, gql, useMutation } from "@apollo/client";
import { Button } from "components/base";
import Errors from "components/Errors";
import Spinner from "components/Spinner";
import { GENERATE_DOCUMENT } from "graphql/mutations";
import { useState } from "react";
import http from "utils/http";
import { formatDate } from "react-day-picker/moment";
import { DatePicker, Input, Text } from "components/Form";
import { Alert } from "components/Toast";

const FETCH_PRODUCTS_BY_ODOO_IDS = gql`
  query FETCH_PRODUCTS_BY_ODOO_IDS($odooIds: [Int]) {
    allProducts(odooIds: $odooIds) {
      id
      odooId
      number
      quantityPerCarton
      barCode: outerCartonBarcodeEan
      outerCartonX
      outerCartonZ
      outerCartonY
      productLine {
        id
        hsCodeForEu
      }
    }
  }
`;

const InboundSHeetForm = ({ odooStockPickingDetail }) => {
  const odooIds = odooStockPickingDetail.moves_without_package.map((line) => line.product_id[0]);
  const { loading, error, data } = useQuery(FETCH_PRODUCTS_BY_ODOO_IDS, {
    variables: { odooIds },
  });

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const transfer = {
    ...odooStockPickingDetail,
    deliveryAddress: `Wabona AB
C/O Charles Kendall Freight
Unit 2, Alpha Park, School Close, Chandlers Ford,
Southampton SO53 4BX
United Kingdom`,
    lines: odooStockPickingDetail.moves_without_package.map((line) => {
      const wisProduct = data.allProducts.find((i) => i.odooId === line.product_id[0]);
      const totalCarton = wisProduct ? Math.ceil(line.product_qty / wisProduct.quantityPerCarton) : 0;
      return {
        id: line.id,
        po: "",
        description: line.product_id[1],
        itemNumber: wisProduct ? wisProduct.number : null,
        qty: line.product_qty,
        quantityPerCarton: wisProduct ? wisProduct.quantityPerCarton : "",
        totalCarton,
        barCode: wisProduct ? wisProduct.barCode : "",
        outerCartonX: wisProduct ? wisProduct.outerCartonX : "",
        outerCartonZ: wisProduct ? wisProduct.outerCartonZ : "",
        outerCartonY: wisProduct ? wisProduct.outerCartonY : "",
      };
    }),
  };

  return <Content transfer={transfer} />;
};

const Content = ({ transfer }) => {
  const [name, setName] = useState(transfer.name);

  const [deliveryAddress, setDeliveryAddress] = useState(transfer.deliveryAddress);
  const [date, setDate] = useState(new Date());
  //    const [lines, setLines] = useState(transfer.lines);
  const [generateDocument] = useMutation(GENERATE_DOCUMENT, {
    onError: (error) => Alert("error", error.message),
  });

  const [exportingInboundSheet, setExportingInboundSheet] = useState(false);

  const totalCarton = transfer.lines.reduce((res, line) => res + line.totalCarton, 0);

  function inboundSheetHandler() {
    setExportingInboundSheet(true);
    generateDocument({
      variables: {
        docType: "inbound_sheet",
        name: transfer.name,
        data: JSON.stringify(
          {
            name,
            date: formatDate(date, "YYYY-MM-DD"),
            deliveryAddress,
            lines: transfer.lines,
            totalCarton,
          },
          "",
          4
        ),
      },
    })
      .then((res) => {
        return http.get(`${process.env.REACT_APP_SERVER_BASE_URL}generated-document/${res.data.generateDocument.document.id}/?excel=1`, {
          responseType: "blob",
          withCredentials: true,
        });
      })
      .then((res) => {
        console.log("res", res);
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", transfer.name + ".xlsx");
        document.body.appendChild(link);
        link.click();
        setExportingInboundSheet(false);
      })
      .catch((e) => {
        Alert("error", e.message);
        setExportingInboundSheet(false);
      });
  }

  return (
    <div className="w-full">
      <div className="flex space-x-8">
        <div className="flex-1 space-y-3">
          <div className="flex items-center space-x-3">
            <label htmlFor="">Inv No.:</label>
            <Input value={name} onChange={(e) => setName(e.target.value)} />
          </div>

          <div>
            <label htmlFor="">Delivery Address:</label>
            <Text className="mt-2" value={deliveryAddress} onChange={(e) => setDeliveryAddress(e.target.value)} />
          </div>
        </div>

        <div className="flex-1 space-y-3">
          <div className="flex items-center space-x-3">
            <label htmlFor="">Date:</label>
            <DatePicker value={date} onDayChange={setDate} />
          </div>
        </div>
      </div>

      <div className="mt-6 w-full overflow-auto">
        <table className="whitespace-nowrap">
          <thead className="text-left">
            <tr>
              <th>Item Number</th>
              <th>Description</th>
              <th>Quantity in Pieces</th>
              <th>Packsize Outer</th>
              <th>Colli</th>
              <th>EAN Outer Packsize</th>
              <th>L(cm)</th>
              <th>W(cm)</th>
              <th>H(cm)</th>
              <th>Expiry Date (if applicable)</th>
              <th>Batch Nunber (if applicable)</th>
              <th>Packsize Inner (if applicable)</th>
              <th>Special Instructions</th>
            </tr>
          </thead>
          <tbody>
            {transfer.lines.map((line) => (
              <tr key={line.id} className="border-b dark:border-gray-700">
                <td>{line.itemNumber}</td>
                <td>{line.description}</td>
                <td className="text-right">{line.qty}</td>
                <td className="text-right">{line.quantityPerCarton}</td>
                <td className="text-right">{line.totalCarton}</td>
                <td className="text-right">{line.barCode}</td>
                <td className="text-right">{line.outerCartonX}</td>
                <td className="text-right">{line.outerCartonZ}</td>
                <td className="text-right">{line.outerCartonY}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <hr />

      <div className="my-8 pb-16 flex space-x-8">
        <Button size="xl" title="Download" loading={exportingInboundSheet} disabled={exportingInboundSheet} onClick={inboundSheetHandler} />
      </div>
    </div>
  );
};

export default InboundSHeetForm;
