import { useQuery, gql, NetworkStatus } from '@apollo/client';
import Spinner from 'components/Spinner';
import Errors from 'components/Errors';
import { formatDate } from 'react-day-picker/moment';
import { AiFillCheckCircle } from 'react-icons/ai';
import { useModals } from 'ModalProvider';
import InvoicesForm from './InvoicesForm';
import Page from 'components/Page';
import { Button } from 'components/base';
import { useState } from 'react';
import { Alert } from 'components/Toast';

const FETCH_ALC_SHIPMENTS = gql`
   query FETCH_ALC_SHIPMENTS($startDate: Date, $force: Boolean) {
      alcLastRequest
      alcShipments(startDate: $startDate, force: $force) {
         id
         number
         orders
         status
         orderReceivedDate
         hasHandledOrdersInWis
      }
   }
`;

const ALCPage = () => {
   var today = new Date();
   var startDate = new Date().setDate(today.getDate() - 30);
   const { loading, error, data, refetch } = useQuery(FETCH_ALC_SHIPMENTS, {
      variables: { startDate: formatDate(startDate, 'YYYY-MM-DD') },
   });
   const [refetching, setRefetching] = useState(false);

   const { present } = useModals();

   if (loading) return <Spinner text="Loading data from ALC portal..." />;
   if (error) return <Errors error={error} />;

   return (
      <Page title="ALC Shipments">
         <div className="p-6 flex space-x-2 items-center">
            <label htmlFor="">
               Last refresh from ALC:{' '}
               {formatDate(data.alcLastRequest, 'YYYY-MM-DD hh:mm:ss')}.{' '}
            </label>
            <Button
               title="Refresh Now"
               loading={refetching}
               disabled={refetching}
               onClick={() => {
                  setRefetching(true);
                  refetch({
                     startDate: formatDate(startDate, 'YYYY-MM-DD'),
                     force: true,
                  })
                     .then(() => {
                        setRefetching(false);
                        Alert('success', 'Updated from ALC Portal.');
                     })
                     .catch(() => {
                        setRefetching(false);
                        Alert('error', 'Failed to update from ALC Portal.');
                     });
               }}
            />
         </div>
         <div className="px-4 pb-8">
            <table>
               <thead className="text-left">
                  <tr>
                     <th>Order</th>
                     <th>Shipment Number</th>
                     <th>Order Received Date</th>
                     <th className="text-right">Status</th>
                  </tr>
               </thead>
               <tbody>
                  {data.alcShipments.map((shipment) => (
                     <ShipmentRow
                        key={shipment.id}
                        shipment={shipment}
                        selectShipment={() =>
                           present({
                              title: shipment.orders,
                              maxWidth: 'max-w-7xl',
                              children: (
                                 <div>
                                    {shipment.hasHandledOrdersInWis ? (
                                       <InvoicesForm shipment={shipment} />
                                    ) : (
                                       <div>
                                          Orders not found in WIS. Make sure
                                          this order is created through WIS.
                                       </div>
                                    )}
                                 </div>
                              ),
                           })
                        }
                     />
                  ))}
               </tbody>
            </table>
         </div>
      </Page>
   );
};

const ShipmentRow = ({ shipment, selectShipment }) => {
   return (
      <tr
         className="border-t hover:bg-gray-100 active:bg-gray-200 dark:border-gray-700 dark:hover:bg-gray-800 dark:active:bg-gray-700 cursor-pointer"
         onClick={selectShipment}
      >
         <td>
            <div className="flex items-center space-x-2">
               <span>{shipment.orders}</span>
               {shipment.hasHandledOrdersInWis ? (
                  <div className="text-green-600 flex items-center space-x-2">
                     <AiFillCheckCircle />
                     <span className="text-xs">Handled</span>
                  </div>
               ) : null}
            </div>
         </td>
         <td>{shipment.number}</td>
         <td>{shipment.orderReceivedDate}</td>
         <td className="text-right">{shipment.status}</td>
      </tr>
   );
};

export default ALCPage;
