import { useQuery } from '@apollo/client';
import Errors from 'components/Errors';
import { Select } from 'components/Form';
import Spinner from 'components/Spinner';
import { useState } from 'react';
import { FETCH_PRODUCTS_VALIDATION } from './graphql';

const CostWithOdoo = () => {
   const { loading, error, data } = useQuery(FETCH_PRODUCTS_VALIDATION, {
      variables: { productType: 'ODOO_COST' },
   });
   const [filter, setFilter] = useState('diff');

   if (loading) return <Spinner text="Validating..." />;
   if (error) return <Errors error={error} />;

   const res = JSON.parse(data.validateProducts);

   const products = Object.values(res.products).filter((i) =>
      filter === 'all' ? true : !i.same
   );
   const acroProducts = Object.values(res.acro_products).filter((i) =>
      filter === 'all' ? true : !i.same
   );

   console.log('products', products);
   console.log('acroProducts', acroProducts);

   return (
      <div>
         <div className="px-6 py-3 flex justify-between">
            <div className=" opacity-70 italic">
               This page is compare the cost between WIS and Odoo.
            </div>
            <Select value={filter} onChange={(e) => setFilter(e.target.value)}>
               <option value="all">All Products</option>
               <option value="diff">Different Products</option>
            </Select>
         </div>

         <div className="flex flex-col space-y-8">
            <ProductsTable title="Products" products={products} />
            <ProductsTable title="Acro Products" products={acroProducts} />
         </div>
      </div>
   );
};

const ProductsTable = ({ title, products }) => {
   const invalidProducts = products.filter((i) => !i.same);

   return (
      <div>
         <table>
            <thead className="whitespace-nowrap">
               <tr>
                  <th className="px-6  sticky top-0 backdrop-blur bg-gray-200 dark:bg-gray-800 bg-opacity-80 dark:bg-opacity-80">
                     <span>{title}</span>{' '}
                     <span className="pl-3">
                        {invalidProducts.length > 0 ? (
                           <b className="text-red-500">
                              {invalidProducts.length} items price not synced
                              with Odoo.
                           </b>
                        ) : (
                           <b className="text-green-600">
                              Nice, all price are synced between Wis and Odoo!
                           </b>
                        )}
                     </span>
                  </th>
                  <th className="px-6  sticky top-0 backdrop-blur bg-gray-200 dark:bg-gray-800 bg-opacity-80 dark:bg-opacity-80">
                     WIS Cost
                  </th>
                  <th className="px-6  sticky top-0 backdrop-blur bg-gray-200 dark:bg-gray-800 bg-opacity-80 dark:bg-opacity-80">
                     Odoo Price
                  </th>
                  <th className="px-6  sticky top-0 backdrop-blur bg-gray-200 dark:bg-gray-800 bg-opacity-80 dark:bg-opacity-80">
                     Same
                  </th>
               </tr>
            </thead>
            <tbody>
               {products.map((i) => (
                  <tr
                     key={i.id}
                     className={i.same ? '' : 'bg-pink-300 dark:bg-pink-900'}
                  >
                     <td className="px-6 border-b dark:border-gray-700 whitespace-nowrap">
                        <div>
                           [{i.number}] {i.name}
                        </div>
                     </td>
                     <td className="px-6 border-b dark:border-gray-700 whitespace-pre-wrap">
                        {i.wis_cost
                           .map(
                              (x) =>
                                 `${x.min_qty}: ${x.currency} ${x.price}  [${x.factory}]`
                           )
                           .join('\n')}
                     </td>
                     <td className="px-6 border-b dark:border-gray-700 whitespace-pre-wrap">
                        {i.supplier_info
                           .map(
                              (x) =>
                                 `${x.min_qty}: ${x.currency} ${x.price} [${x.factory}]`
                           )
                           .join('\n')}
                     </td>
                     <td
                        className={`px-6 border-b dark:border-gray-700 whitespace-pre-wrap text-center font-bold
               ${i.same ? ' text-green-600' : ' text-red-600'}`}
                     >
                        {i.same ? '✓' : <span>&times;</span>}
                     </td>
                  </tr>
               ))}
            </tbody>
         </table>
      </div>
   );
};

export default CostWithOdoo;
