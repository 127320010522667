import { createContext, useContext, useEffect, useState } from 'react';
import { useQuery, gql, useMutation } from '@apollo/client';
import { formatDate } from 'react-day-picker/moment';
import Spinner, { InlineSpinner } from 'components/Spinner';
import Errors from 'components/Errors';
import { Button, CheckBox } from 'components/base';
import SearchBar from 'components/SearchBar';
import { searchByProp } from 'utils/search';
import http from 'utils/http';
import { Alert } from 'components/Toast';
import { DatePicker } from 'components/Form';

const CostExportContext = createContext({});

const FETCH_DATA = gql`
   query FETCH_DATA($id: ID!) {
      anyCollection(id: $id) {
         id
         descendants {
            id
            productLines {
               id
               name
               mainImage
               salesPriceNotes
               products {
                  id
                  number
                  name
                  isActive
                  productLine {
                     id
                  }
               }
            }
         }
      }
   }
`;

const GENERATE_DOCUMENT = gql`
   mutation GENERATE_DOCUMENT(
      $name: String!
      $data: String!
      $docType: String!
   ) {
      generateDocument(name: $name, data: $data, docType: $docType) {
         document {
            id
            name
            docType
            data
         }
      }
   }
`;

const SelectExportPage = () => {
   const { loading, error, data } = useQuery(FETCH_DATA, {
      variables: { id: process.env.REACT_APP_PRODUCT_COLLECTION_ID },
   });

   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;

   const lines = data.anyCollection.descendants
      .flatMap((i) => i.productLines)
      .map((line) => {
         const stringForSearch = line.products
            .map((p) => `${p.name} ${p.number}`)
            .join(' ');
         return { ...line, stringForSearch };
      });

   return <SelectExport lines={lines} />;
};

const SelectExport = ({ lines }) => {
   const [searchText, setSearchText] = useState('');
   const [exportingPdf, setExportingPdf] = useState(false);

   const today = new Date();
   const [dates, setDates] = useState(
      lines.map((i) => ({ lineId: i.id, date: today }))
   );
   const [selectedItems, setSelectedItems] = useState([]);

   const [loadedData, setLoadedData] = useState({});

   const [generateDocument] = useMutation(GENERATE_DOCUMENT, {
      onError: (error) => Alert('error', error.message),
   });

   const productLines = lines.filter((i) =>
      searchByProp(i, ['name', 'stringForSearch'], searchText)
   );

   const selectedItemIds = selectedItems.map((i) => i.id);
   const data = Object.entries(loadedData)
      .map(([lineId, products]) => {
         const line = lines.find((i) => i.id === lineId);
         return {
            line: { id: line.id, name: line.name },
            date: formatDate(
               dates.find((i) => i.lineId === lineId).date,
               'YYYY-MM-DD'
            ),
            products: products.filter((i) => selectedItemIds.includes(i.id)),
         };
      })
      .filter((i) => i.products.length > 0);

   const variables = {
      docType: 'product_costs',
      name: 'Cost - Sales INC',
      data: JSON.stringify(data, '', 4),
   };

   console.log('variables', variables);

   function exportPdf() {
      setExportingPdf(true);
      generateDocument({ variables })
         .then((res) => {
            return http.get(
               `${process.env.REACT_APP_SERVER_BASE_URL}generated-document/${res.data.generateDocument.document.id}/?excel=1`,
               { responseType: 'blob', withCredentials: true }
            );
         })
         .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
               'download',
               `${variables.name}_${new Date().toLocaleString()}.xlsx`
            );
            document.body.appendChild(link);
            link.click();
            setExportingPdf(false);
         })
         .catch((e) => {
            Alert('error', e.message);
            setExportingPdf(false);
         });
   }

   return (
      <CostExportContext.Provider
         value={{
            selectedItems,
            setSelectedItems,
            dates,
            setDates,
            setLoadedData,
         }}
      >
         <div className="h-full overflow-auto">
            <div className="flex p-6 h-full overflow-auto">
               <div className="w-1/2 h-full flex-col overflow-auto mr-8">
                  <div className="flex-1 pb-6 sticky top-0">
                     <SearchBar
                        autoFocus
                        className="border border-gray-100"
                        placeholder="Search by name or item number"
                        value={searchText}
                        onChange={setSearchText}
                     />
                  </div>
                  <div className="flex-1 overflow-auto">
                     {productLines.map((line) => (
                        <ProductLineDetail
                           key={line.id}
                           line={line}
                           products={line.products.filter((i) => i.isActive)}
                        />
                     ))}
                  </div>
               </div>

               <div className="flex-1 h-full overflow-auto">
                  <div className="flex justify-between">
                     <h2>Selected Products</h2>
                     <Button
                        title="Export"
                        onClick={exportPdf}
                        loading={exportingPdf}
                        disabled={exportingPdf || selectedItems.length === 0}
                     />
                  </div>

                  <div className="py-4">
                     {selectedItems.length > 0 ? (
                        <SelectedVariants
                           variants={selectedItems}
                           productLines={productLines}
                        />
                     ) : (
                        <div className="text-gray-500 font-bold">
                           Select Products to view the cost for it.
                        </div>
                     )}
                  </div>
               </div>
            </div>
         </div>
      </CostExportContext.Provider>
   );
};

const ProductLineDetail = ({ line, products }) => {
   const { selectedItems, setSelectedItems } = useContext(CostExportContext);

   const lineProductIds = products.map((i) => i.id);
   const selectedProductIds = selectedItems.map((i) => i.id);

   const isAllProductsSelected =
      selectedItems.filter((i) => i.productLine.id === line.id).length ===
      products.length;

   return (
      <div className="mb-8 bg-white dark:bg-gray-800 border border-gray-100 dark:border-gray-800 rounded-xl p-6">
         <div className="flex items-center space-x-3">
            <CheckBox
               checked={isAllProductsSelected}
               onChange={() => {
                  setSelectedItems((prev) =>
                     isAllProductsSelected
                        ? prev.filter((i) => !lineProductIds.includes(i.id))
                        : [
                             ...prev,
                             ...products.filter(
                                (i) => !selectedProductIds.includes(i.id)
                             ),
                          ]
                  );
               }}
            />
            <h4>{line.name}</h4>
         </div>
         <div className="mt-3">
            {products.map((variant) => (
               <div
                  className="py-1 flex items-center space-x-3"
                  key={variant.id}
               >
                  <CheckBox
                     checked={selectedProductIds.includes(variant.id)}
                     onChange={() => {
                        setSelectedItems((prev) =>
                           selectedProductIds.includes(variant.id)
                              ? prev.filter((i) => i.id !== variant.id)
                              : [...prev, variant]
                        );
                     }}
                  />
                  <div>
                     [{variant.number}] {variant.name}
                  </div>
               </div>
            ))}
         </div>
      </div>
   );
};

const SelectedVariants = ({ variants, productLines }) => {
   const { dates, setDates } = useContext(CostExportContext);
   const lines = productLines
      .map((line) => ({
         ...line,
         variants: variants.filter((i) => i.productLine.id === line.id),
      }))
      .filter((i) => i.variants.length > 0);

   return (
      <div className="space-y-6">
         {lines.map((line) => (
            <div
               key={line.id}
               className="bg-white dark:bg-gray-800 border border-gray-100 dark:border-gray-800 rounded-xl p-6 "
            >
               <h4>{line.name}</h4>
               <DatePicker
                  className="mt-2"
                  value={dates.find((i) => i.lineId === line.id).date}
                  onDayChange={(date) =>
                     setDates((prev) =>
                        prev.map((i) =>
                           i.lineId === line.id ? { ...i, date } : i
                        )
                     )
                  }
               />
               <div className="mt-2 relative">
                  <VariantCost line={line} />
               </div>
            </div>
         ))}
      </div>
   );
};

const VariantCost = ({ line }) => {
   const { dates, setLoadedData } = useContext(CostExportContext);

   const FETCH_DATA = gql`
      query FETCH_DATA($ids: [ID!], $date: Date) {
         products(ids: $ids) {
            results {
               id
               number
               name
               cost(date: $date)
            }
         }
      }
   `;

   const ids = line.variants.map((i) => i.id);

   const date = dates.find((i) => i.lineId === line.id);

   const { loading, error, data } = useQuery(FETCH_DATA, {
      variables: { ids, date: formatDate(date.date, 'YYYY-MM-DD') },
   });

   useEffect(() => {
      if (data)
         setLoadedData((prev) => {
            return { ...prev, [line.id]: data.products.results };
         });
   }, [data]);

   if (loading) return <InlineSpinner />;
   if (error) return <Errors error={error} />;

   return (
      <div>
         {data.products.results.map((product) => (
            <div
               key={product.id}
               className="py-1 flex justify-between space-x-8"
            >
               <div>
                  [{product.number}] {product.name}
               </div>
               <div>${product.cost}</div>
            </div>
         ))}
      </div>
   );
};

export default SelectExportPage;
