import { Route, Routes } from "react-router-dom";
import OrderList from "./OrderList";
import OrderDetail from "./OrderDetail";

const Orders = () => {
  return (
    <Routes>
      <Route>
        <Route index element={<OrderList />} />
        <Route path=":id" element={<OrderDetail />} />
      </Route>
    </Routes>
  );
};

export default Orders;
