import { useContext } from "react";
import { OrderContext } from "./OrderDetail";
import { Switcher } from "components/Form";
import { OdooContext } from "OdooProvider";
import odooIcon from "assets/odoo-icon.svg";

const OdooInfoView = () => {
  const { order, setOrder, originalOrder } = useContext(OrderContext);
  const { odooUrl } = useContext(OdooContext);

  return (
    <div className="card">
      <h5 className=" text-purple-600">Odoo</h5>
      <div className="mt-2">
        <div className="flex space-x-3 items-center">
          <label htmlFor="">Auto Sync to Odoo: </label>
          <Switcher isOn={order.autoSyncToOdoo} onChange={(e) => setOrder({ ...order, autoSyncToOdoo: !order.autoSyncToOdoo })} />
        </div>
        <div className="text-xs opacity-70 mt-2">If you turn this on, WIS will auto create or update Sales Order to Odoo once this order starts planning.</div>

        {originalOrder.odooSalesOrderId ? (
          <div className="mt-4">
            <a
              className="flex space-x-2 items-center"
              target="_blank"
              href={`${odooUrl}/web#id=${originalOrder.odooSalesOrderId}&menu_id=408&cids=1&action=312&model=sale.order&view_type=form`}
              rel="noreferer"
            >
              <img style={{ height: 16 }} src={odooIcon} alt="odoo" />
              <span>{originalOrder.odooSalesOrderName}</span>
            </a>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default OdooInfoView;
