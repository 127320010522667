import { gql, useQuery } from "@apollo/client";
import { Link } from "react-router-dom";

const FETCH_SUPPLIER_PENDING_INVENTORY_UPDATE_REQUESTS_COUNT = gql`
  query FETCH_SUPPLIER_PENDING_INVENTORY_UPDATE_REQUESTS_COUNT {
    supplierStockUpdateRequests(statesIn: ["PENDING"]) {
      total
    }
  }
`;

const SupplierUpdateRequestsView = () => {
  const { data } = useQuery(FETCH_SUPPLIER_PENDING_INVENTORY_UPDATE_REQUESTS_COUNT, {
    fetchPolicy: "network-only",
  });

  if (!data) return null;

  return (
    <div className="relative">
      <Link to="update-requests">Update Requests</Link>
      {data.supplierStockUpdateRequests.total > 0 && (
        <div className="absolute -top-3 -right-2 bg-red-600 text-white w-4 h-4 text-center rounded-full text-xxs">{data.supplierStockUpdateRequests.total}</div>
      )}
    </div>
  );
};

export default SupplierUpdateRequestsView;
