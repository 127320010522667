import CharlesButton from "components/charles/base";
import { useState } from "react";
import { CURRENCY_DICT } from "utils/invoice";
import { BATCH_SYNC_PRODUCT_PRICES_TO_ODOO } from "./suppliers/BatchSyncPriceToOdooView";
import { useMutation } from "@apollo/client";
import { Alert } from "components/Toast";

const EffectedProductsSyncTable = ({ products }) => {
  const [selectedProductIds, setSelectedProductIds] = useState(products.map((product) => product.id));

  const [sync, syncRes] = useMutation(BATCH_SYNC_PRODUCT_PRICES_TO_ODOO, {
    variables: { productIds: selectedProductIds },
    onCompleted() {
      Alert("success", "Sync Success");
    },
    onError(error) {
      Alert("error", error.message);
    },
    refetchQueries: ["FETCH_EFFECTED_PRODUCT_COST"],
    awaitRefetchQueries: true,
  });

  return (
    <div>
      <div className="-mx-2 whitespace-nowrap align-middle">
        <table>
          <thead>
            <tr>
              <th>
                <input
                  type="checkbox"
                  checked={selectedProductIds.length === products.length}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedProductIds(products.map((product) => product.id));
                    } else {
                      setSelectedProductIds([]);
                    }
                  }}
                />
              </th>
              <th>Odoo ID</th>
              <th>Product</th>
              <th className="text-right">New Cost</th>
              <th className="text-right">Odoo Cost</th>
            </tr>
          </thead>
          <tbody>
            {products.map((product, index) => (
              <tr key={index} className="border-y dark:border-gray-700">
                <td>
                  <input
                    type="checkbox"
                    checked={selectedProductIds.includes(product.id)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedProductIds([...selectedProductIds, product.id]);
                      } else {
                        setSelectedProductIds(selectedProductIds.filter((id) => id !== product.id));
                      }
                    }}
                  />
                </td>
                <td>{product.odooId}</td>
                <td className="w-full">
                  [{product.number}] {product.name}
                </td>
                <td className="text-right">${product.cost}</td>
                <td>
                  {product.supplierInfo.map((i, index) => (
                    <div key={index} className="text-right">
                      {CURRENCY_DICT[i.currency_id[1]]?.symbol}
                      {i.price}
                    </div>
                  ))}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="flex mt-4 justify-end">
        <CharlesButton className="text-purple-600" onClick={sync} loading={syncRes.loading}>
          Sync New Cost to Odoo
        </CharlesButton>
      </div>
    </div>
  );
};

export default EffectedProductsSyncTable;
