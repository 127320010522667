export const STANDARD_17 = [
  {
    products: "Lead paint on Children’s Products",
    law: "CPSA",
    section: "16 CFR 1303",
  },
  {
    products: "Total lead content in metal children’s products and in non-metal children’s products",
    law: "CPSIA",
    section: "CPSIA section 101",
  },
  {
    products: "ASTM F963-17 (Consumer Safety Specifications for Toy Safety) Section 4.3.7, 4.5-4.27, 4.30, 4.32, 4.35-4.40 Mechanical hazards",
    law: "CPSA",
    section: "16 CFR 1250",
  },
  {
    products: "ASTM F963-17 (Consumer Safety Specifications for Toy Safety) Section 4.3.5.1(2) Surface Coating Materials - Soluble Test for Metal",
    law: "CPSA",
    section: "16 CFR 1250",
  },
  {
    products: "ASTM F963-17 (Consumer Safety Specifications for Toy Safety) Section 4.3.5.2 Toy Substrate Materials",
    law: "CPSA",
    section: "16 CFR 1250",
  },
  {
    products: "Phthalates",
    law: "CPSIA",
    section: "16 CFR 1307",
  },
];

export const STANDARD_23 = [
  {
    products: "Total Lead content in metal children’s products and in non-metal children’s products. Section 4.3.5.1(1) Section 4.3.5.2(2)",
    law: "CPSA",
    section: "CPSIA Section 101",
  },
  {
    products: "Lead Paint on Children’s Products",
    law: "CPSA",
    section: "16 CFR1303",
  },
  {
    products: "Phthalates (BBP, DBP, DEHP, DINP, DIBP, DPENP, DHEXP & DCHP) Content requirements",
    law: "CPSIA",
    section: "16 CFR1307",
  },
  {
    products: "ASTM F963-23 (Consumer Safety Specifications for Toy Safety) Mechanical Hazards requirements",
    law: "CPSA",
    section: "16 CFR1250",
  },
  {
    products: "ASTM F963-23 (Consumer Safety Specifications for Toy Safety) Labelling Requirements",
    law: "CPSA",
    section: "16 CFR1250",
  },
  {
    products: "ASTM F963-23 (Consumer Safety Specifications for Toy Safety) Flammability requirements",
    law: "CPSA",
    section: "16 CFR1500.3",
  },
  {
    products: "ASTM F963-23 (Consumer Safety Specifications for Toy Safety) Soluble heavy metals content in surface and substrate coating requirement",
    law: "CPSA",
    section: "16 CFR1250",
  },
];
