import { Navigate, Outlet, Route, Routes, useNavigate, useParams } from "react-router-dom";
import Preview from "./components/Preview";
import Page from "components/Page";
import InlineReportForm from "./inline/Form";
import InlineReportList from "./inline/List";
import InlineReportPreview from "./inline/Preview";
import { useEffect } from "react";
import QCDashboard from "./QCDashboard";
import { Menu } from "./Header";
import { useModals } from "ModalProvider";
import CreateQcReportView from "./components/CreateQcReportView";
import CharlesButton from "components/charles/base";
import FinalQcReportPage from "./components/FinalQcReportPage";
import FinalQcReportListPage from "./final";

function QCPage() {
  const navigate = useNavigate();
  const modal = useModals();

  function createReport() {
    modal.present({
      title: "创建质检报告",
      center: false,
      children: (
        <CreateQcReportView
          onCreated={(link) => {
            modal.hide();
            navigate(link);
          }}
        />
      ),
    });
  }

  return (
    <Routes>
      <Route
        element={
          <Page leftButtons={<Menu />} rightButtons={<CharlesButton onClick={createReport}>+ 创建新报告</CharlesButton>}>
            <Outlet />
          </Page>
        }
      >
        <Route path="dashboard" element={<QCDashboard />} />
        <Route path="final" element={<FinalQcReportListPage />} />
        <Route path="inline" element={<InlineReportList />} />
      </Route>

      <Route path="final/:id" element={<FinalQcReportPage />} />
      <Route path="final/:id/preview" element={<Preview />} />

      <Route path="inline/:id" element={<InlineReportForm />} />
      <Route path="inline/:id/preview" element={<InlineReportPreview />} />
      <Route path="reports/:id/preview" element={<QCRedirect />} />

      <Route path="*" element={<Navigate to="/qc/dashboard" />} />
    </Routes>
  );
}

// This redirect the old URL to the new URL

const QCRedirect = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    navigate(`/qc/final/${id}/preview`);
  }, []);

  return null;
};

export default QCPage;
