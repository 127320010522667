import React, {
   createContext,
   useContext,
   useEffect,
   useRef,
   useState,
} from 'react';
import DateRangePicker from '../DateRangePicker.js';
import useDateRange from '../../../hooks/useDateRange';
import Page from 'components/Page';
import { NavigationLink } from 'components/base.js';
import Overview from './Overview.js';

export const LeftButtons = () => (
   <div className="flex space-x-4">
      <NavigationLink to="/analytics/qc/overview" title="Overview" />
      <NavigationLink to="/analytics/qc/products" title="Products" />
      <NavigationLink to="/analytics/qc/factories" title="Factories" />
   </div>
);

export const QCAnalyticsContext = createContext({});

const QCAnalyticsProvider = ({ children }) => {
   const [{ start, end }, { setStart, setEnd }] = useDateRange(12);
   const [searchText, setSearchText] = useState('');

   const [size, setSize] = useState({ x: 0, y: 0 });

   return (
      <QCAnalyticsContext.Provider
         value={{
            start,
            end,
            setStart,
            setEnd,
            searchText,
            setSearchText,
            size,
            setSize,
         }}
      >
         {children}
      </QCAnalyticsContext.Provider>
   );
};

const QCAnalyticsRoutes = () => (
   <QCAnalyticsProvider>
      <Content />
   </QCAnalyticsProvider>
);

function Content() {
   const { setSize, start, setStart, end, setEnd } =
      useContext(QCAnalyticsContext);

   const container = useRef();
   useEffect(() => {
      if (container.current) {
         setSize({
            width: container.current.clientWidth,
            height: container.current.clientHeight,
         });
      }
   }, []);

   return (
      <Page title="QC Analytics" className="lg:h-screen">
         <div className="flex items-center space-x-8 p-6">
            <DateRangePicker
               start={start}
               end={end}
               setStart={setStart}
               setEnd={setEnd}
            />
         </div>

         <div
            ref={container}
            className="flex-1 w-full flex flex-col overflow-auto"
         >
            <Overview />
         </div>
      </Page>
   );
}

export default QCAnalyticsRoutes;
