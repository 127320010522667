import { gql, useQuery } from "@apollo/client";
import Errors from "./Errors";
import { Select } from "./Form";
import { InlineSpinner } from "./Spinner";

const FETCH_DATA = gql`
  query FETCH_ALL_PRODUCT_LINES($activeForWis: Boolean) {
    allProductlines(activeForWis: $activeForWis) {
      id
      name
    }
  }
`;

const ProductLineSelector = ({ value, onChange, variables = { activeForWis: true }, ...rest }) => {
  const { loading, error, data } = useQuery(FETCH_DATA, { variables });

  if (loading) return <InlineSpinner />;
  if (error) return <Errors error={error} />;

  const sortedProductLines = [...data.allProductlines].sort((a, b) => a.name.localeCompare(b.name));

  return (
    <Select value={value || ""} onChange={(e) => onChange(e.target.value || null)} {...rest}>
      <option value="">-- Select Product Line --</option>
      {sortedProductLines.map((line) => (
        <option key={line.id} value={line.id}>
          {line.name}
        </option>
      ))}
    </Select>
  );
};

export default ProductLineSelector;
