import { useQuery, gql } from "@apollo/client";
import { Button } from "components/base";
import Errors from "components/Errors";
import Spinner from "components/Spinner";
import CommercialInvoiceForm from "./CommercialInvoiceForm";
import InboundSheetForm from "./InboundSheetForm";
import { BsArrowRight } from "react-icons/bs";
import { useModals } from "ModalProvider";
import InterCompanyInvoiceForm from "./InterCompanyInvoiceForm";

const FETCH_ODOO_STOCK_PICKING_DETAIL = gql`
  query FETCH_ODOO_STOCK_PICKING_DETAIL($pickingId: Int!) {
    odooStockPickingDetail(pickingId: $pickingId)
  }
`;

const OdooTransferDetail = ({ pickingId }) => {
  const { loading, error, data } = useQuery(FETCH_ODOO_STOCK_PICKING_DETAIL, {
    variables: { pickingId },
  });
  const modal = useModals();

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const odooStockPickingDetail = JSON.parse(data.odooStockPickingDetail);

  console.log("odooStockPickingDetail", odooStockPickingDetail);

  return (
    <div>
      <div className="space-y-2">
        <div>
          <label htmlFor="">Patner: </label> <span className="pl-4">{odooStockPickingDetail.partner_id[1]}</span>
        </div>
        <div className="flex space-x-4 items-center">
          <div className="flex space-x-4">
            <label htmlFor="">Source Location:</label>
            <span>{odooStockPickingDetail.location_id[1]}</span>
          </div>

          <BsArrowRight />

          <div className="flex space-x-4">
            <label htmlFor="">Destination Location:</label>
            <span>{odooStockPickingDetail.location_dest_id[1]}</span>
          </div>
        </div>

        <div>
          <label htmlFor="">Opertation Type: </label> <span className="pl-4">{odooStockPickingDetail.picking_type_id[1]}</span>
        </div>

        <div>
          <label htmlFor="">Scheduled Date: </label> <span className="pl-4">{odooStockPickingDetail.scheduled_date}</span>
        </div>

        <div>
          <label htmlFor="">Effetive Date: </label> <span className="pl-4">{odooStockPickingDetail.date_done}</span>
        </div>

        <div>
          <label htmlFor="">Source Document: </label> <span className="pl-4">{odooStockPickingDetail.origin}</span>
        </div>
      </div>

      <table className="mt-4">
        <thead className="text-left">
          <tr>
            <th className="px-0">Product</th>
            <th className="text-right px-0">Qty</th>
          </tr>
        </thead>
        <tbody>
          {odooStockPickingDetail.moves_without_package.map((line) => (
            <tr key={line.id} className="border-b dark:border-gray-700">
              <td className="px-0">{line.product_id[1]}</td>
              <td className="px-0 text-right">{line.product_qty}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="my-8 pb-16 flex space-x-8">
        <Button
          size="xl"
          title="Generate Commercial Invoice"
          onClick={() => {
            modal.present({
              title: "Generate Commercial Invoice",
              maxWidth: "max-w-full",
              isBeingPresented: true,
              children: <CommercialInvoiceForm odooStockPickingDetail={odooStockPickingDetail} />,
            });
          }}
        />

        <Button
          size="xl"
          title="Generate Inter Company Invoice"
          onClick={() => {
            modal.present({
              title: "Generate Inter Company Invoice",
              isBeingPresented: true,
              maxWidth: "max-w-full",
              children: <InterCompanyInvoiceForm odooStockPickingDetail={odooStockPickingDetail} />,
            });
          }}
        />

        <Button
          size="xl"
          title="Generate Inbound Sheet"
          onClick={() =>
            modal.present({
              title: "Generate Inbound Shee",
              maxWidth: "max-w-full",
              children: <InboundSheetForm odooStockPickingDetail={odooStockPickingDetail} />,
            })
          }
        />
      </div>
    </div>
  );
};

export default OdooTransferDetail;
