import React, { useRef, useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { IoMdCheckmark } from "react-icons/io";
import SearchBar from "components/SearchBar";
import { Button } from "components/base";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import { Alert } from "components/Toast";
import { searchByProp } from "utils/search";
import { FixedSizeList } from "react-window";

const FETCH_ODOO_PRODUCTS = gql`
  query FETCH_ODOO_PRODUCTS {
    products: odooProducts {
      id
      name
      defaultCode
      qtyAvailable
      virtualAvailable
    }
  }
`;

const CREATE_PRODUCTS_FROM_ODOO = gql`
  mutation CREATE_PRODUCTS_FROM_ODOO($products: [CreateProductFromOdooInput]) {
    createProductFromOdoo(products: $products) {
      products {
        id
        name
        odooId
        odooCode
      }
    }
  }
`;

function ProductList() {
  const [searchText, setSearchText] = useState("");
  const [selectedProducts, setSelectedProducts] = useState([]);
  const listRef = useRef();
  const [listHeight, setListHeight] = useState(0);

  const { loading, error, data } = useQuery(FETCH_ODOO_PRODUCTS);
  const [createProductsFromOdoo, createProductsFromOdooRes] = useMutation(CREATE_PRODUCTS_FROM_ODOO, {
    onCompleted: () => Alert("success", "Product imported into WIS."),
  });

  useEffect(() => {
    if (listRef.current) setListHeight(listRef.current.clientHeight);
  }, [data]);

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const productsToShow = data.products.filter((product) => (searchText === "" ? true : searchByProp(product, ["name", "defaultCode"], searchText)));

  function onClickProduct(product) {
    let updatedProducts = selectedProducts;
    if (!!selectedProducts.find((i) => i.id === product.id)) {
      updatedProducts = selectedProducts.filter((i) => i.id !== product.id);
    } else {
      updatedProducts = [...selectedProducts, product];
    }
    setSelectedProducts(updatedProducts);
  }

  function onAddhandler() {
    const products = selectedProducts.map((i) => ({
      name: i.name,
      odooId: i.id,
      odooCode: i.defaultCode,
    }));
    createProductsFromOdoo({
      variables: { products },
    });
  }

  return (
    <div className="p-4 lg:p-6 flex flex-col h-full relative">
      <div className="flex items-baseline">
        <div className="flex-1">
          <SearchBar autoFocus className="py-2" placeholder="Search Product Name, Product Line Number" onChange={setSearchText} />
          <div className="mt-1 px-4 text-xs opacity-70">Select and add odoo products to wis to display in Odoo stock page.</div>
        </div>
        {selectedProducts.length > 0 && (
          <div className="flex ml-6">
            <Button title="add" size="sm" loading={createProductsFromOdooRes.loading} disabled={createProductsFromOdooRes.loading} onClick={onAddhandler} />
            <Button className="ml-4" title="clear" color="red" size="sm" onClick={() => setSelectedProducts([])} />
          </div>
        )}
      </div>
      <div className="flex-1 mt-4 lg:mt-6 bg-white dark:bg-gray-800 rounded-xl overflow-auto relative" ref={listRef}>
        <FixedSizeList height={listHeight} itemSize={44} width="100%" itemData={productsToShow} itemCount={productsToShow.length}>
          {({ data, index, style }) => {
            const product = data[index];
            const selected = !!selectedProducts.find((i) => i.id === product.id);
            return (
              <div
                key={index}
                className={`
                                 py-3 flex justify-between items-center px-4 border-b dark:border-gray-700 cursor-pointer dark:bg-opacity-50 dark:hover:bg-opacity-70 h-8
                                 ${selected ? "bg-blue-100 dark:bg-green-800" : "dark:bg-gray-900"}
                              `}
                onClick={(_) => onClickProduct(product)}
                style={style}
              >
                <div>
                  #{product.id} [{product.defaultCode}] {product.name} [<label>On Hand: {product.qtyAvailable}</label>,
                  <label>Forecasted: {product.virtualAvailable} </label>]
                </div>

                {selected && (
                  <div className="text-green-500 w-6">
                    <IoMdCheckmark size={27} />
                  </div>
                )}
              </div>
            );
          }}
        </FixedSizeList>
      </div>
    </div>
  );
}

export default ProductList;
