import { gql } from "@apollo/client";

export const SHIPMENT_ANALYTICS_QUARTER_REPORT = gql`
  query SHIPMENT_ANALYTICS_QUARTER_REPORT($group: String!, $startDate: String!, $endDate: String!) {
    shipmentQuarters {
      year
      quarter
    }
    shipmentList {
      id
      shipmentStatus {
        id
        status
        created
      }
    }
    shipmentErrorsByQuarter {
      year
      quarter
      value
    }
    shipmentErrorsWithDelayByQuarter {
      year
      quarter
      errorCount
      avgDelayDays
    }
    shipment2023Goals(group: $group, startDate: $startDate, endDate: $endDate)
  }
`;
