import { gql, useQuery } from "@apollo/client";
import { parseError } from "apollo";
import Errors from "components/Errors";
import { Select } from "components/Form";
import SearchBar from "components/SearchBar";
import Spinner from "components/Spinner";
import CharlesButton from "components/charles/base";
import moment from "moment";
import { useState } from "react";
import XLSX from "xlsx";

const FETCH_COMPARE_STOCK_DATA = gql`
  query FETCH_COMPARE_STOCK_DATA($name: String!) {
    compareOdooStock(name: $name)
  }
`;

const CompareWithOdoo = () => {
  const { loading, error, data } = useQuery(FETCH_COMPARE_STOCK_DATA, {
    variables: { name: "ALC" },
  });
  const [filter, setFilter] = useState("all");
  const [searchText, setSearchText] = useState("");

  if (loading) return <Spinner />;
  if (error) return <Errors error={parseError(error)} />;

  const res = JSON.parse(data.compareOdooStock);
  const totalCount = res.length;
  const differentCount = res.filter((i) => i.odoo_alc !== 0).length;
  const sameCount = res.filter((i) => i.odoo_alc === 0).length;

  const resToShow = res
    .filter((i) => {
      if (filter === "all") return true;
      if (filter === "different") return i.odoo_alc !== 0;
      if (filter === "same") return i.odoo_alc === 0;
    })
    .filter((i) => {
      const cleanedSearchText = searchText.toLowerCase().replace(/[^a-z0-9]/g, "");
      return i.number.toLowerCase().includes(cleanedSearchText) || i.name.toLowerCase().includes(cleanedSearchText);
    });

  function exportExcel() {
    const data = resToShow.map((i) => ({
      Item: i.number,
      "Odoo QTY": i.odoo_qty,
      "ALC QTY": i.alc_qty,
      "Diff (Odoo - ALC)": i.odoo_alc,
    }));
    const sheet = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, sheet, "stock");
    const today = moment().format("YYYY-MM-DD");
    XLSX.writeFile(wb, `ALC vs Odoo ${today}.xlsx`);
  }

  return (
    <div className="p-6 flex flex-col flex-1 overflow-auto">
      <div className="opacity-70">
        This page compare the inventory between ALC and Odoo NL Warehouse. There are some item numbers different between this two systems(for example: 302C01_A
        and 302C01-A), we setup some configuration(ignore -, _, case insensitive, auto correct some numbers, etc) so that it can auto compute most items. If
        Odoo has more qty than ALC, probably there are some Sales Orders not delivered. If ALC has more qty than Odoo, there are some manual work need to be
        done to adjust.
      </div>

      <div className="flex items-center justify-between mt-4 space-x-6">
        <div className="flex items-center space-x-6 flex-1">
          <Select value={filter} onChange={(e) => setFilter(e.target.value)}>
            <option value="all">All Products({totalCount})</option>
            <option value="different">Only Products with different stock({differentCount})</option>
            <option value="same">Only Products with the same stock({sameCount})</option>
          </Select>
          <div className="w-full">
            <SearchBar placeholder="Search by item number or name" value={searchText} onChange={setSearchText} />
          </div>
          <CharlesButton onClick={exportExcel}>Export(Excel)</CharlesButton>
        </div>

        <a href={`${process.env.REACT_APP_SERVER_HOST}/static/alc-stock.xml`} target="_blank" rel="noreferer">
          Donwload Original Report from ALC
        </a>
      </div>

      <div className="card mt-4 p-0 pb-1 whitespace-nowrap flex-1 overflow-auto">
        <table>
          <thead>
            <tr className="sticky top-0 z-10 backdrop-blur dark:bg-gray-800 dark:bg-opacity-80">
              <th className="px-6 py-3">Item</th>
              <th className="px-6">Name(ALC)</th>
              <th className="text-right px-6">Odoo QTY</th>
              <th className="text-right px-6">ALC QTY</th>
              <th className="text-right px-6">Diff (Odoo - ALC)</th>
            </tr>
          </thead>
          <tbody>
            {resToShow.map((i, index) => (
              <tr
                key={index}
                className={`border-t border-gray-100 dark:border-gray-700 dark:bg-opacity-40
                ${i.odoo_alc > 0 ? "bg-red-100 dark:bg-red-800 " : i.odoo_alc < 0 ? "bg-green-100 dark:bg-green-800" : ""}
              `}
              >
                <td className="px-6">{i.number}</td>
                <td className="px-6">{i.name}</td>
                <td className="text-right px-6">{i.odoo_qty}</td>
                <td className="text-right px-6">{i.alc_qty}</td>
                <td className="text-right px-6">{i.odoo_alc}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CompareWithOdoo;
