import { Select } from "components/Form";
import { BsCheckCircle, BsCheckCircleFill } from "react-icons/bs";

export const STOCK_USAGE_STRATEGY_OPTIONS = [
  { value: "USE_ONLY_ENOUGH", label: "Use stock only if we have enough stock for the a plan." },
  { value: "USE_ALL", label: "Use all stock even we don't have enough stock for the a plan." },
];

const StockStrategyView = ({ value, onChange, disabled }) => {
  return (
    <Select value={value} onChange={(e) => onChange(e.target.value)} disabled={disabled}>
      {STOCK_USAGE_STRATEGY_OPTIONS.map((i) => (
        <option key={i.value} value={i.value}>
          {i.label}
        </option>
      ))}
    </Select>
  );

  if (disabled) {
    const option = STOCK_USAGE_STRATEGY_OPTIONS.find((i) => i.value === value);
    return <div>{option.label}</div>;
  }

  return (
    <div>
      {STOCK_USAGE_STRATEGY_OPTIONS.map((i) => (
        <div
          key={i.value}
          className={`border-b dark:border-gray-700 py-2 flex items-center space-x-2
            ${i.value === value ? " text-green-600" : "cursor-pointer hover:text-green-500 active:text-green-600"}
        `}
          onClick={() => {
            if (i.value !== value) onChange(i.value);
          }}
        >
          <div className="flex-shrink-0 text-lg">{i.value === value ? <BsCheckCircleFill /> : <BsCheckCircle />}</div>
          <div>{i.label}</div>
        </div>
      ))}
    </div>
  );
};

export default StockStrategyView;
