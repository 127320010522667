import React from "react";
import { useQuery, gql } from "@apollo/client";
import Errors from "components/Errors";
import { InlineSpinner } from "components/Spinner";
import { Tag } from "components/base";

const FETCH_ALL_WARNINGS = gql`
  query FETCH_ALL_WARNINGS {
    allProductWarnings {
      id
      name
    }
  }
`;

const ProductWarningsSelector = ({ warningIds, setWarningIds }) => {
  const { loading, error, data } = useQuery(FETCH_ALL_WARNINGS);
  if (loading) return <InlineSpinner />;
  if (error) return <Errors error={error} />;

  const warnings = data.allProductWarnings.map((i) => ({
    ...i,
    selected: warningIds.includes(i.id),
  }));

  return (
    <div className="flex flex-wrap">
      {warnings.map((i) => (
        <Tag
          className="mr-4 mb-3 text-xs"
          key={i.id}
          selected={i.selected}
          onClick={() => setWarningIds((prev) => (i.selected ? prev.filter((x) => x !== i.id) : [...prev, i.id]))}
          title={i.name}
        />
      ))}
    </div>
  );
};

export default ProductWarningsSelector;
