import { gql, useQuery } from "@apollo/client";
import Errors from "components/Errors";
import Page from "components/Page";
import Spinner from "components/Spinner";
import { Link, Route, Routes } from "react-router-dom";
import { LeftButtons } from "../Header";
import WarehouseDetail from "./WarehouseDetail";

const WarehouseRoutes = () => (
  <Routes>
    <Route>
      <Route index element={<Warehouse />} />
      <Route path=":id" element={<WarehouseDetail />} />
    </Route>
  </Routes>
);

const FETCH_WAREHOUSES = gql`
  query FETCH_WAREHOUSES {
    warehouses {
      id
      name
    }
  }
`;

const Warehouse = () => {
  const { loading, error, data } = useQuery(FETCH_WAREHOUSES);

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  return (
    <div className="m-4 md:m-6 text-sm">
      {data.warehouses.map((i) => (
        <div key={i.id} className="py-2 border-b">
          <Link to={i.id}>Warehouse {i.name}</Link>
        </div>
      ))}
    </div>
  );
};

export default WarehouseRoutes;
