import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import {
   ResponsiveContainer,
   AreaChart,
   Area,
   XAxis,
   YAxis,
   Tooltip,
} from 'recharts';
import Spinner from '../../components/Spinner';
import Errors from '../../components/Errors';
import BackButton from '../../components/BackButton.js';
import PageContainer from '../../components/PageContainer.js';
import {
   IoMdCheckmark,
   IoMdClose,
   IoMdThumbsUp,
   IoMdThumbsDown,
} from 'react-icons/io';
import { randomColors } from 'utils/colors';
import { NavigationBar } from 'components/base';

const FETCH_FACTORY = gql`
   query FETCH_FACTORY($id: ID!) {
      factory(id: $id) {
         id
         name
         address
         boss
         contactPerson
         tripData {
            id
            workerQuantity
            factoryArea
            machines
            fireExtinguisher
            wages
            workingHours
            hemostatic
            boneDrugs
            burnDrugs
            eyeDrops
            antibioticDrugs
            painkillers
            coldMedicines
            light
            noise
            workingVentilation
            workingEnvironment
            gloves
            earplugs
            masks
            glasses
            ventilation
            whereToFind
            incidentRecord
            room
            toilet
            shower
            staffRules
            health
            pregnancy
            injury
            oldage
            unemploiyment
            when
         }
      }
   }
`;

function Trip() {
   const { id } = useParams();
   const { loading, error, data } = useQuery(FETCH_FACTORY, {
      variables: { id },
   });
   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;
   const factory = data.factory;

   return (
      <div>
         <NavigationBar title={factory.name} backTo="/csr/factory" />
         <div className="m-6">
            <div>{factory.location}</div>
            <div>{factory.process}</div>
            <div>{factory.contactPerson}</div>
            <div>{factory.boss}</div>
            <hr />
            {factory.tripData.length === 0 ? (
               <div className="text-center font-body text-lg text-gray-500 font-bold">
                  No Trip Data for this factory at this moment.
               </div>
            ) : (
               <div>
                  <Charts data={factory.tripData} />
                  <Tables data={factory.tripData} />
               </div>
            )}
         </div>
      </div>
   );
}

function MyAreaChart({ data, title, dataKey, color }) {
   return (
      <div className="w-full lg:w-1/2">
         <div className="p-6 rounded-lg bg-white dark:bg-gray-800 mx-4 mb-6">
            <h3>{title}</h3>
            <ResponsiveContainer height={260}>
               <AreaChart
                  data={data.map((d) => ({
                     ...d,
                     when: new Date(d.when).toLocaleDateString(),
                  }))}
                  margin={{ top: 30, right: 30, left: 0, bottom: 30 }}
               >
                  <defs>
                     <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor={color} stopOpacity={0.8} />
                        <stop offset="95%" stopColor={color} stopOpacity={0} />
                     </linearGradient>
                  </defs>
                  <XAxis dataKey="when" />
                  <YAxis dataKey={dataKey} />
                  <Tooltip />
                  <Area
                     dataKey={dataKey}
                     type="linear"
                     stroke={color}
                     fillOpacity={1}
                     fill="url(#colorUv)"
                  />
               </AreaChart>
            </ResponsiveContainer>
         </div>
      </div>
   );
}

function Charts({ data }) {
   const colors = randomColors(6);
   return (
      <div className="flex flex-wrap -mx-4">
         <MyAreaChart
            data={data}
            color={colors[0]}
            title="Workers"
            dataKey="workerQuantity"
         />
         <MyAreaChart
            data={data}
            color={colors[1]}
            title="Area"
            dataKey="factoryArea"
         />
         <MyAreaChart
            data={data}
            color={colors[2]}
            title="Machines"
            dataKey="machines"
         />
         <MyAreaChart
            data={data}
            color={colors[3]}
            title="Fire Extinguisher"
            dataKey="fireExtinguisher"
         />
         <MyAreaChart
            data={data}
            color={colors[4]}
            title="Wages"
            dataKey="wages"
         />
         <MyAreaChart
            data={data}
            color={colors[5]}
            title="Working Hours"
            dataKey="workingHours"
         />
      </div>
   );
}

function MyTable({ title, data, dataKeys }) {
   return (
      <div className="rounded-lg overflow-auto border dark:border-gray-700 mb-6">
         <table className="bg-white dark:bg-gray-800">
            <thead className="text-center whitespace-no-wrap">
               <tr>
                  <td className="sticky left-0 z-10 px-6 font-bold text-left">
                     {title}
                  </td>
                  {data.map((d, index) => (
                     <td className="py-4 px-6" key={index}>
                        {new Date(d.when).toLocaleDateString()}
                     </td>
                  ))}
               </tr>
            </thead>
            <tbody>
               {dataKeys.map((k, index) => (
                  <tr key={index}>
                     <td className="px-6 py-4 border-b dark:border-gray-700 sticky left-0 z-10 bg-white dark:bg-gray-800">
                        {k}
                     </td>
                     {data.map((d, index) => (
                        <td
                           className="px-6 py-4 border-b dark:border-gray-700"
                           key={index}
                        >
                           <MyTableCell value={d[k]} />
                        </td>
                     ))}
                  </tr>
               ))}
            </tbody>
         </table>
      </div>
   );
}

function Tables({ data }) {
   return (
      <div>
         <MyTable
            title="First Aid"
            data={data}
            dataKeys={[
               'hemostatic',
               'boneDrugs',
               'burnDrugs',
               'eyeDrops',
               'antibioticDrugs',
               'painkillers',
               'coldMedicines',
            ]}
         />
         <MyTable
            title="Safty - Working Conditions"
            data={data}
            dataKeys={[
               'light',
               'noise',
               'workingVentilation',
               'workingEnvironment',
            ]}
         />
         <MyTable
            title="Safty - Worker Safty"
            data={data}
            dataKeys={[
               'gloves',
               'earplugs',
               'masks',
               'glasses',
               'ventilation',
               'whereToFind',
               'incidentRecord',
            ]}
         />
         <MyTable
            title="Staff Quarter"
            data={data}
            dataKeys={['room', 'toilet', 'shower', 'staffRules']}
         />
         <MyTable
            title="Staff Insurance"
            data={data}
            dataKeys={[
               'health',
               'pregnancy',
               'injury',
               'oldage',
               'unemploiyment',
            ]}
         />
      </div>
   );
}

function MyTableCell({ value }) {
   if (typeof value === 'boolean') {
      return (
         <div className="flex justify-center">
            <div
               className={`w-6
            ${value === true ? 'text-green-500' : 'text-red-500'} `}
            >
               {value === true ? <IoMdCheckmark /> : <IoMdClose />}
            </div>
         </div>
      );
   }
   if (typeof value === 'string') {
      let x = '';
      switch (value.toLowerCase()) {
         case 'good':
            x = <IoMdThumbsUp className="text-green-600" />;
            break;
         case 'ok':
            x = 'OK';
            break;
         case 'bad':
            x = <IoMdThumbsDown className="text-red-600" />;
            break;
         default:
            break;
      }
      return (
         <div className="flex justify-center">
            <div className="w-6">{x}</div>
         </div>
      );
   }
   return 'Unknow value type?';
}

export default Trip;
