import { Link } from "react-router-dom";

const ShipmentsView = ({ shipments, type }) => {
  if (type === "ON_TIME_DELIVERY") {
    return (
      <div className="text-sm whitespace-nowrap -mx-2">
        <table>
          <thead>
            <tr className="">
              <th>Shipment</th>
              <th className="text-right">Request Cargo Ready</th>
              <th className="text-right">Delivery to Port</th>
              <th className="text-right">Days Late</th>
            </tr>
          </thead>
          <tbody className="divide-y">
            {shipments.map((i, index) => {
              return (
                <tr key={index} className={i.diff_days > 7 ? "bg-red-100" : ""}>
                  <td className=" whitespace-normal">
                    <Link to={`/shipment/shipments/${i.id}`} target="_blank">
                      {i.name}
                    </Link>
                  </td>
                  <td className="text-right">{i.request_cargo_ready_date}</td>
                  <td className="text-right">{i.delivery_to_port_date}</td>
                  <td className="text-right">{i.diff_days}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  if (type === "FULL_CONTAINER_LOAD")
    return (
      <div className="text-sm whitespace-nowrap -mx-2">
        <table>
          <thead>
            <tr className="">
              <th>Shipment</th>
              <th className="text-right">Container</th>
              <th className="text-right">Total CBM</th>
              <th className="text-right">Full Container Load</th>
            </tr>
          </thead>
          <tbody className="divide-y">
            {shipments.map((i, index) => {
              return (
                <tr key={index} className={i.diff_days > 7 ? "bg-red-100" : ""}>
                  <td className=" whitespace-normal">
                    <Link to={`/shipment/shipments/${i.id}`} target="_blank">
                      {i.name}
                    </Link>
                  </td>
                  <td className="text-right">
                    {i.container}({i.container_size})
                  </td>
                  <td className="text-right">{i.total_cbm}</td>
                  <td className="text-right">{(i.full_container_load * 100).toFixed(2)} %</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );

  if (type === "AVE_DELAY_DAYS")
    return (
      <div className="text-sm whitespace-nowrap -mx-2">
        <table>
          <thead>
            <tr className="">
              <th>Shipment</th>
              <th className="text-right">Delay Days</th>
            </tr>
          </thead>
          <tbody className="divide-y">
            {shipments.map((i, index) => {
              return (
                <tr key={index}>
                  <td className=" whitespace-normal">
                    <Link to={`/shipment/shipments/${i.id}`} target="_blank">
                      {i.name}
                    </Link>
                  </td>
                  <td className="text-right">{i.delay_days}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );

  if (type === "AVE_PRODUCTION_DAYS") {
    const sortedShipments = shipments.sort((a, b) => b.production_days - a.production_days);
    return (
      <div className="text-sm whitespace-nowrap -mx-2">
        <table>
          <thead>
            <tr className="">
              <th>Shipment</th>
              <th className="text-right">Production Days</th>
            </tr>
          </thead>
          <tbody className="divide-y">
            {sortedShipments.map((i, index) => {
              return (
                <tr key={index} className={i.diff_days > 7 ? "bg-red-100" : ""}>
                  <td className=" whitespace-normal">
                    <Link to={`/shipment/shipments/${i.id}`} target="_blank">
                      {i.name}
                    </Link>
                  </td>
                  <td className="text-right">{i.production_days}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  if (type === "DELIVERED_TO_PORT") {
    return (
      <div className="text-sm whitespace-nowrap -mx-2">
        <table>
          <thead>
            <tr className="">
              <th>Shipment</th>
            </tr>
          </thead>
          <tbody className="divide-y">
            {shipments.map((i, index) => {
              return (
                <tr key={index} className={i.diff_days > 7 ? "bg-red-100" : ""}>
                  <td className=" whitespace-normal">
                    <Link to={`/shipment/shipments/${i.id}`} target="_blank">
                      {i.name}
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  return null;
};

export default ShipmentsView;
