import Page from "components/Page";
import Appearance from "./Appearance";

const UserProfilePage = () => {
  return (
    <Page title="Settings">
      <div className="p-6 grid grid-cols-12 gap-6">
        <div className=" col-span-9 space-y-6">
          <div className="card">
            <h5>
              <a href={process.env.REACT_APP_AUTH_SERVER} target="_blank">
                Waboba ID
              </a>
            </h5>
          </div>
          <Appearance />
        </div>
      </div>
    </Page>
  );
};

export default UserProfilePage;
