import { useContext } from "react";
import { useMutation } from "@apollo/client";
import ShipmentInvoiceRow from "./ShipmentInvoiceRow";
import InvoiceRow from "./InvoiceRow";
import ProductSelector from "../ProductSelector";
import SpecialItemForm from "./SpecialItemForm";
import { Alert } from "components/Toast";
import { CREATE_SHIPMENT_INVOICE_ROW } from "../../graphql";
import ShipmentContext from "../ShipmentContext";
import { createShipmentInvoicePoGroups } from "../../utils";
import CharlesButton from "components/charles/base";
import { useModals } from "ModalProvider";

function ShipmentInvoiceGroup({ shipmentInvoice, group }) {
  const { dispatch } = useContext(ShipmentContext);
  const [createShipmentInvoiceRow, createShipmentInvoiceRowRes] = useMutation(CREATE_SHIPMENT_INVOICE_ROW, {
    onCompleted: (data) => {
      Alert("success", "Product added to invoice"),
        dispatch({
          type: "createShipmentInvoiceRow",
          payload: {
            shipmentInvoiceId: shipmentInvoice.id,
            shipmentInvoiceRow: data.createShipmentInvoiceRow.shipmentInvoiceRow,
          },
        });
    },
  });
  const modal = useModals();

  function tryAddProduct() {
    modal.present({
      title: "Add Product",
      maxWidth: "max-w-3xl",
      children: (
        <ProductSelector
          onSelectProduct={(product) => {
            modal.hide();
            createShipmentInvoiceRow({
              variables: { shipmentInvoiceId: shipmentInvoice.id, productId: product.id, poId: group.po ? group.po.id : null },
            });
          }}
        />
      ),
    });
  }

  function tryAddSpecialItem() {
    modal.present({
      title: "Create Special Item",
      maxWidth: "max-w-7xl",
      children: (
        <SpecialItemForm
          onSaveProduct={(product) => {
            modal.hide();
            createShipmentInvoiceRow({
              variables: {
                shipmentInvoiceId: shipmentInvoice.id,
                productId: product.id,
                poId: group.po ? group.po.id : null,
              },
            });
          }}
        />
      ),
    });
  }

  return (
    <div>
      {group.shipmentInvoiceRows.map((row) => (
        <ShipmentInvoiceRow
          key={row.id}
          shipmentInvoice={shipmentInvoice}
          shipmentInvoiceId={shipmentInvoice.id}
          row={row}
        />
      ))}

      <div className="mx-6 my-4 flex space-x-4">
        <CharlesButton onClick={tryAddProduct} loading={createShipmentInvoiceRowRes.loading}>
          + Add Product
        </CharlesButton>
        <CharlesButton onClick={tryAddSpecialItem}>+ Add Special Item</CharlesButton>
      </div>
    </div>
  );
}

function ShipmentInvoice({ shipmentInvoice }) {
  const shipmentInvoicePoGroups = createShipmentInvoicePoGroups(shipmentInvoice);
  return (
    <div className="bg-white dark:bg-gray-900 rounded-lg border border-gray-200 dark:border-gray-700 mt-6 dark:text-gray-300">
      <InvoiceRow shipmentInvoice={shipmentInvoice} />
      {shipmentInvoicePoGroups.map((group, groupIndex) => (
        <ShipmentInvoiceGroup shipmentInvoice={shipmentInvoice} group={group} key={groupIndex} />
      ))}
    </div>
  );
}

export default ShipmentInvoice;
