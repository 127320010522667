import React, { useContext, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { InlineSpinner } from "components/Spinner";
import Errors from "components/Errors";
import InputSelect from "components/InputSelect";
import { Input } from "components/Form";
import { Alert } from "components/Toast";
import InvoiceOdooProductSelector from "./InvoiceOdooProductSelector";
import { ProductRow } from "./QCProduct";
import FactorySelector from "components/FactorySelector";
import { CREATE_QC_FINAL_REPORT, FETCH_BASE_INFO } from "../graphql";
import { AppContext } from "App";
import CharlesButton from "components/charles/base";
import { parseError } from "apollo";

function FinalQcReportCreateForm({ onCreated }) {
  const { user } = useContext(AppContext);
  const { loading, error, data } = useQuery(FETCH_BASE_INFO);
  const [searchText, setSearchText] = useState("");

  // Special case for Acro
  const [factoryId, setFactoryId] = useState(user.email === "acro@waboba.com" ? "42" : null);

  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [qty, setQty] = useState(0);
  const [createReport, createReportRes] = useMutation(CREATE_QC_FINAL_REPORT, {
    onCompleted: (res) => {
      Alert("success", "Created.");
      onCreated(res.createSimpleQcReport.qcReport.id);
    },
    onError: (error) => Alert("error", parseError(error)),
    refetchQueries: ["FETCH_QC_REPORTS"],
  });

  if (loading)
    return (
      <div className="py-8">
        <InlineSpinner />
      </div>
    );
  if (error) return <Errors error={error} />;

  return (
    <div>
      <div className="rounded-2xl p-6 bg-white dark:bg-gray-900">
        <h3>1. 选择生产商</h3>
        <div className="mt-4">
          <FactorySelector value={factoryId} onChange={setFactoryId} />
        </div>

        <h3 className="mt-8">2. 选择订单 SO</h3>
        <InputSelect
          inputProps={{ disabled: factoryId === null }}
          options={data.allInvoices.map((i) => ({
            id: i.id,
            name: i.number,
            type: i.odooModelType,
          }))}
          value={searchText}
          onChange={setSearchText}
          className="mt-4"
          placeholder="根据SO搜索 Search by SO"
          onSelect={(i) => {
            setSelectedInvoice(i);
            setSelectedProduct(null);
            setSearchText(i.name);
          }}
          renderOption={(i) => `${i.type.toUpperCase()}# ${i.name}`}
        />

        {selectedInvoice && factoryId ? (
          <div className="mt-8">
            <h3>3. 选择产品</h3>

            {selectedProduct ? (
              <div>
                <div className="mt-4">
                  <ProductRow product={selectedProduct} qty={qty} />
                  <CharlesButton className="mt-4" onClick={() => setSelectedProduct(null)}>
                    Select Product 重新选择
                  </CharlesButton>
                </div>

                {qty > 0 ? (
                  <div className="mt-8">
                    <h3>4. 确认产品数量</h3>

                    <div className="mt-4">
                      <label htmlFor="">实际数量：</label>
                      <Input className="text-center" value={qty} onChange={(e) => setQty(e.target.value)} />
                    </div>

                    <p className="pt-2 text-xs text-gray-600">若实际数量与SO订单不一致，请输入实际数量</p>

                    <hr />

                    <CharlesButton
                      next
                      loading={createReportRes.loading}
                      onClick={() =>
                        createReport({
                          variables: {
                            factoryId,
                            invoiceId: selectedInvoice.id,
                            productId: selectedProduct.id,
                            qty: qty,
                          },
                        })
                      }
                    >
                      {createReportRes.loading ? "Createing 正在创建..." : "Next 下一步"}
                    </CharlesButton>
                  </div>
                ) : null}
              </div>
            ) : (
              <InvoiceOdooProductSelector
                invoiceId={selectedInvoice.id}
                onSelectProduct={(product, qty) => {
                  setSelectedProduct(product);
                  setQty(qty);
                }}
              />
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default FinalQcReportCreateForm;
