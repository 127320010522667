import React from "react";
import { Route, Routes } from "react-router-dom";
import ArtworkCopy from "./ArtworkCopy";
import BeautyshotsPage from "./beautyshots";
import LiftstyleImages from "./LiftstyleImages";
import VideoList from "./videos/VideoList";
import LogosPage from "./logos";

function MaterialsIndex() {
  return (
    <Routes>
      <Route path="beautyshots/*" element={<BeautyshotsPage />} />
      <Route path="lifestyle-images" element={<LiftstyleImages />} />
      <Route path="logos" element={<LogosPage />} />
      <Route path="videos" element={<VideoList />} />
      <Route path="artwork-copy" element={<ArtworkCopy />} />
    </Routes>
  );
}

export default MaterialsIndex;
