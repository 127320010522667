import React, { useContext, useState } from "react";
import { Input } from "components/Form";
import { productUnitCost, productItemsPerSet } from "../utils";
import odooIcon from "assets/odoo-icon.svg";
import { ButtonWithArrow } from "components/base";
import ProductForm from "pages/manage/products/All/ProductForm";
import { shippingCartons } from "../ShippingCartons";
import { useModals } from "ModalProvider";
import ProductWithoutBOM from "./ProductWithoutBOM";
import { CurrencyContext } from "CurrencyProvider";
import CharlesButton from "components/charles/base";
import { ProductCostContext } from "./Detail";
import { GoHistory } from "react-icons/go";
import ChildProductRow from "./ChildProductRow";

const ProductCostDetailView = ({ product }) => {
  const { showProductWithBomCostHistory } = useContext(ProductCostContext);
  const [showMore, setShowMore] = useState(false);
  const [calcQty, setCalcQty] = useState(product.defaultQty);
  const { latestExchangeRate } = useContext(CurrencyContext);
  const productEditModal = useModals();

  const itemsPerSet = productItemsPerSet(product);

  const cartonCostPerUnit = shippingCartons.map((carton) => {
    const costPerUnit = ((product.outerCartonCbm / product.quantityPerCarton / carton.maxCbm) * carton.cost).toFixed(3);
    return { ...carton, costPerUnit };
  });

  const hasBom = product.children.length > 0;

  function editProduct() {
    productEditModal.present({
      title: "Edit Product",
      fullscreen: true,
      children: <ProductForm id={product.id} hide={productEditModal.hide} />,
    });
  }

  return (
    <div>
      <div>
        <div className="flex justify-between items-center">
          <div className="flex items-center space-x-3">
            <div className="flex items-center">
              {product.odooId ? <img className="mr-2" style={{ height: 16 }} src={odooIcon} alt="odoo product" /> : null}
              <div className="font-semibold text-base">
                [{product.number}] {product.name}
              </div>
            </div>
          </div>

          <CharlesButton onClick={editProduct}>Edit Product</CharlesButton>
        </div>
      </div>

      {hasBom ? (
        <table className="mt-4">
          <thead>
            <tr className="bg-gray-100 dark:bg-gray-800 uppercase">
              <th className="uppercase text-left py-3 px-4 border">
                <div className="flex items-center space-x-4">
                  <div>Components ( {itemsPerSet} / Set )</div>
                  <ButtonWithArrow className="text-sky-600 hover:text-sky-700 active:text-sky-800" show={showMore} onClick={() => setShowMore((prev) => !prev)}>
                    Show Calculation
                  </ButtonWithArrow>
                </div>
              </th>
              <th className="text-center border px-4">MOQ</th>
              <th className="text-center border px-4">Supplier</th>
              <th className="text-right border px-4">Qty</th>
              <th className="text-right border px-4">Price</th>
              <th className="text-right border px-4 whitespace-nowrap">Supplier Updated</th>
            </tr>
          </thead>
          <tbody>
            {product.children.map((child) => (
              <ChildProductRow child={child} key={child.id} calcQty={calcQty} itemsPerSet={itemsPerSet} showMore={showMore} />
            ))}
          </tbody>
        </table>
      ) : (
        <ProductWithoutBOM product={product} qty={calcQty} />
      )}

      <div className="mt-6">
        {hasBom ? (
          <div>
            <div className="flex items-center space-x-3 justify-end">
              <div className="text-right flex justify-end items-center">
                <div>
                  Product Unit Price for{" "}
                  <Input
                    className="inline-block mx-4 text-center"
                    validated={calcQty % itemsPerSet === 0}
                    value={calcQty}
                    onChange={(e) => setCalcQty(e.target.value)}
                  />{" "}
                  items:
                </div>{" "}
                <div className="ml-4 text-lg">$ {productUnitCost(product, calcQty, latestExchangeRate.supplierUsdToRmbRate).toFixed(3)}</div>
              </div>

              <CharlesButton className="ml-4" onClick={() => showProductWithBomCostHistory(product.id)}>
                <GoHistory size={18} />
              </CharlesButton>
            </div>

            {calcQty % itemsPerSet !== 0 && (
              <div className="text-red-600 dark:text-red-800 text-right mt-1 font-semibold">This quantity is not a multiple of the set quantity.</div>
            )}
          </div>
        ) : null}
      </div>

      <div>
        <h4>FOB Cost</h4>

        <div className="flex space-x-8">
          <div className="flex space-x-4">
            <div>
              <label className="pr-2">Quantity / Carton:</label>
              {product.quantityPerCarton}
            </div>
            <div>
              <label className="pr-2">Outer Carton CBM:</label>
              {product.outerCartonCbm} m³
            </div>
          </div>
          <div className="space-x-6 flex">
            {cartonCostPerUnit.map((i, index) => (
              <div key={index} className="flex space-x-2 items-center">
                <label>{i.name}: </label>
                <div>${i.costPerUnit}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCostDetailView;
