import { gql, useLazyQuery, useMutation } from '@apollo/client';
import axios from 'axios';
import { Button } from 'components/base';
import CharlesButton from 'components/charles/base';
import FactorySelector from 'components/FactorySelector';
import { DatePicker, FileSelector, Input, Text } from 'components/Form';
import Page from 'components/Page';
import { InlineSpinner } from 'components/Spinner';
import { Alert } from 'components/Toast';
import { useState } from 'react';
import { BsTrash } from 'react-icons/bs';
import { IoCheckmarkCircleOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { v4 } from 'uuid';
import SubSupplierInvoiceSelector from './SubSupplierInvoiceSelector';

const FETCH_PRESIGNED_URL = gql`
   query FETCH_PRESIGNED_URL($filename: String!) {
      qiniuPresignedUrl(filename: $filename) {
         token
         key
         url
      }
   }
`;

const SAVE_PAYMENT = gql`
   mutation SAVE_PAYMENT(
      $id: ID!
      $supplierId: ID!
      $date: DateTime!
      $remark: String
      $lines: [SubSupplierOrderPaymentLineInputType!]!
      $statements: [SubSupplierOrderPaymentStatementInputType!]!
   ) {
      saveSubSupplierPayment(
         id: $id
         supplierId: $supplierId
         date: $date
         remark: $remark
         lines: $lines
         statements: $statements
      ) {
         payment {
            id
            supplier {
               id
               name
            }
            date
            remark
            lines {
               id
               invoice {
                  id
               }
               amount
            }
            statements {
               id
               name
               url
            }
         }
      }
   }
`;

const DELETE_PAYMENT = gql`
   mutation DELETE_PAYMENT($id: ID!) {
      deleteSubSupplierPayment(id: $id) {
         res
      }
   }
`;

const SubSupplierPaymentForm = ({ originalPayment }) => {
   const [supplierId, setSupplierId] = useState(
      originalPayment.supplier ? originalPayment.supplier.id : null
   );
   const [date, setDate] = useState(new Date());
   const [fetchPresignedUrl] = useLazyQuery(FETCH_PRESIGNED_URL, {
      fetchPolicy: 'network-only',
   });

   const [lines, setLines] = useState(
      originalPayment.lines.length > 0
         ? originalPayment.lines.map((i) => ({
              invoiceId: i.invoice.id,
              amount: i.amount,
           }))
         : [{ invoiceId: null, amount: 0 }]
   );
   const [statements, setStatements] = useState(originalPayment.statements);
   const [remark, setRemark] = useState(originalPayment.remark);

   const [savePayment, savePaymentRes] = useMutation(SAVE_PAYMENT, {
      onCompleted() {
         Alert('success', 'Payment Saved.');
      },
      onError(error) {
         Alert('error', error.message);
      },
   });

   const navigate = useNavigate();
   const [deletePaymet, deletePaymentRes] = useMutation(DELETE_PAYMENT, {
      onCompleted() {
         Alert('success', 'Payment Deleted.');
         navigate('/sub-supplier/payments');
      },
      onError(error) {
         Alert('error', error.message);
      },
      refetchQueries: [
         'FETCH_SUB_SUPPLIER_PAYMENTS',
         'FETCH_SUB_SUPPLIER_INVOICES',
      ],
      awaitRefetchQueries: true,
   });

   async function uploadFiles(files) {
      for (const i of files) {
         const res = await new Promise((onCompleted, onError) =>
            fetchPresignedUrl({
               variables: { filename: i.name },
               onCompleted,
               onError,
            })
         );
         console.log('res', res);
         await uploadFile(i, res.qiniuPresignedUrl);
         const url = encodeURI(res.qiniuPresignedUrl.url);
         setStatements((prev) =>
            prev.map((x) =>
               x.id === i.id ? { ...x, uploading: false, url } : x
            )
         );
      }
   }

   async function uploadFile(item, qiniuPresignedUrl) {
      const uploadUrl = 'https://up-z2.qiniup.com';
      let xhr = new XMLHttpRequest();
      xhr.open('POST', uploadUrl, true);
      let formData = new FormData();
      formData.append('key', qiniuPresignedUrl.key);
      formData.append('token', qiniuPresignedUrl.token);
      formData.append('file', item.file);
      await axios.post(uploadUrl, formData, {
         headers: {
            'Content-Type': 'multipart/form-data',
         },
      });
   }

   function onUploaded(itemId, url) {
      // setStatements((prev) =>
      //    prev.map((i) =>
      //       i.id === itemId ? { ...i, uploading: false, url } : i
      //    )
      // );
   }

   function savePaymentHandler() {
      savePayment({
         variables: {
            id: originalPayment.id,
            supplierId,
            date,
            lines: lines
               .filter((i) => i.invoiceId)
               .map((i) => ({
                  invoiceId: i.invoiceId,
                  amount: i.amount,
               })),
            statements: statements.map((i) => ({ name: i.name, url: i.url })),
            remark,
         },
      });
   }

   function onChangeLine(index, values = {}) {
      console.log('onChangeLine', index, values);
      setLines((prev) =>
         prev.map((prevLine, prevIndex) =>
            prevIndex === index ? { ...prevLine, ...values } : prevLine
         )
      );
   }

   function deleteHandler() {
      if (window.confirm('Are you sure to delete this payment? ')) {
         deletePaymet({ variables: { id: originalPayment.id } });
      }
   }

   return (
      <Page
         backTo={'/sub-supplier/payments'}
         title={`Payment #${originalPayment.id}`}
      >
         <div className="p-6">
            <div className="card px-8 py-6">
               <div className="flex items-center mt-4">
                  <label className="w-20 pr-4">Supplier: </label>
                  <FactorySelector
                     value={supplierId}
                     onChange={setSupplierId}
                  />
               </div>

               <div className="flex items-center mt-4">
                  <label className="w-20 pr-4">Pay Date:</label>
                  <DatePicker value={date} onDayChange={setDate} />
               </div>

               {supplierId ? (
                  <div className="mt-4">
                     <div>
                        <div className="-mx-2">
                           <table>
                              <thead>
                                 <tr>
                                    <th>
                                       <h5>Invoice</h5>
                                    </th>
                                    <th>Total</th>
                                 </tr>
                              </thead>
                              <tbody>
                                 {lines.map((line, index) => (
                                    <tr key={index}>
                                       <td>
                                          <div className="flex space-x-2">
                                             <Button
                                                leftIcon={<BsTrash />}
                                                onClick={() =>
                                                   setLines((prev) =>
                                                      prev.filter(
                                                         (_, prevIndex) =>
                                                            prevIndex !== index
                                                      )
                                                   )
                                                }
                                             />
                                             <SubSupplierInvoiceSelector
                                                supplierId={supplierId}
                                                value={line.invoiceId}
                                                onChange={(
                                                   invoiceId,
                                                   invoice
                                                ) =>
                                                   onChangeLine(index, {
                                                      invoiceId,
                                                      amount: invoice.balance,
                                                   })
                                                }
                                             />
                                          </div>
                                       </td>
                                       <td>
                                          <Input
                                             value={line.amount}
                                             onChange={(e) =>
                                                onChangeLine(index, {
                                                   amount: e.target.value,
                                                })
                                             }
                                          />
                                       </td>
                                    </tr>
                                 ))}
                              </tbody>
                           </table>
                        </div>

                        <div className="mt-4">
                           <Button
                              title="+ Add Invoice"
                              onClick={() =>
                                 setLines((prev) => [
                                    ...prev,
                                    { invoiceId: null, amount: 0 },
                                 ])
                              }
                           />
                        </div>

                        <hr />

                        <div>
                           <h5>Bank Statement</h5>
                           <div className="mt-4">
                              {statements.map((i) => (
                                 <div
                                    className="flex items-center space-x-2 border-b border-gray-100 dark:border-gray-700 py-2"
                                    key={i.id}
                                 >
                                    <Button
                                       leftIcon={<BsTrash />}
                                       onClick={() =>
                                          setStatements((prev) =>
                                             prev.filter((x) => x.id !== i.id)
                                          )
                                       }
                                    />
                                    <div className="pr-2">
                                       {i.url ? (
                                          <a
                                             href={i.url}
                                             target="_blank"
                                             rel="noreferer"
                                          >
                                             {i.name}
                                          </a>
                                       ) : (
                                          i.name
                                       )}
                                    </div>
                                    {i.uploading ? (
                                       <InlineSpinner size={14} text={null} />
                                    ) : (
                                       <IoCheckmarkCircleOutline
                                          className="text-green-600"
                                          size={18}
                                       />
                                    )}
                                 </div>
                              ))}

                              <div className="mt-2">
                                 <FileSelector
                                    title="Upload"
                                    onChange={(e) => {
                                       const newFiles = [...e.target.files].map(
                                          (f) => ({
                                             id: v4(),
                                             file: f,
                                             name: f.name,
                                             completed: false,
                                          })
                                       );
                                       e.target.value = null;
                                       setStatements((prev) => [
                                          ...prev,
                                          ...newFiles,
                                       ]);
                                       uploadFiles(newFiles);
                                    }}
                                 />
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               ) : null}

               <hr />

               <div>
                  <label htmlFor="">Remark</label>
                  <Text
                     className="mt-2"
                     value={remark}
                     onChange={(e) => setRemark(e.target.value)}
                  />
               </div>

               <hr />

               <div className="flex justify-between items-center">
                  <CharlesButton
                     primary
                     onClick={savePaymentHandler}
                     loading={savePaymentRes.loading}
                  >
                     Save
                  </CharlesButton>

                  <CharlesButton
                     danger
                     onClick={deleteHandler}
                     loading={deletePaymentRes.loading}
                  >
                     Delete Payment
                  </CharlesButton>
               </div>
            </div>
         </div>
      </Page>
   );
};

export default SubSupplierPaymentForm;
