import { gql, useMutation } from '@apollo/client';
import Errors from 'components/Errors';
import OdooPartnerSelector from 'components/OdooPartnerSelector';
import OdooPickingTypeSelector from 'components/OdooPickingTypeSelector';
import OdooStockLocationSelector from 'components/OdooStockLocationSelector';
import Spinner from 'components/Spinner';
import { useOdooProducts } from 'hooks/useOdooProduct';
import { useState } from 'react';
import { Button } from 'components/base';
import { Alert } from 'components/Toast';

const ODOO_CREATE_INTERNAL_TRANSFER = gql`
   mutation ODOO_CREATE_INTERNAL_TRANSFER(
      $partnerId: Int!
      $pickingTypeId: Int!
      $locationId: Int!
      $locationDestId: Int!
      $lines: [OdooInternalTransferLineInputType]
   ) {
      odooCreateInternalTransfer(
         partnerId: $partnerId
         pickingTypeId: $pickingTypeId
         locationId: $locationId
         locationDestId: $locationDestId
         lines: $lines
      ) {
         transfer {
            id
            name
         }
      }
   }
`;

export const InternalTransferForm = ({ lines, createHandler }) => {
   const [partnerId, setPartnerId] = useState('1');
   const [pickingTypeId, setPickingTypeId] = useState('5');
   const [locationId, setLocationId] = useState('15');
   const [locationDestId, setLocationDestId] = useState('0');

   const [createInternalTransfer, createInternalTransferRes] = useMutation(
      ODOO_CREATE_INTERNAL_TRANSFER,
      {
         onCompleted: (res) => {
            Alert(
               'success',
               `Internal transfer ${res.odooCreateInternalTransfer.transfer.name} created in Odoo.`
            );
            if (createHandler) {
               createHandler(res.odooCreateInternalTransfer.transfer);
            }
         },
         onError: (error) => Alert('error', error.message),
      }
   );

   const variables = {
      partnerId,
      pickingTypeId,
      locationId,
      locationDestId,
      lines: lines.map((i) => ({ productId: i.productId, qty: i.qty })),
   };

   return (
      <div className="space-y-4 text-sm">
         <div className="flex items-center space-x-3">
            <label className="w-32">Partner: </label>
            <OdooPartnerSelector value={partnerId} onSelect={setPartnerId} />
         </div>
         <div className="flex items-center space-x-3">
            <label className="w-32">Operation Type: </label>
            <OdooPickingTypeSelector
               value={pickingTypeId}
               onSelect={setPickingTypeId}
               initialValue={5}
            />
         </div>
         <div className="flex items-center space-x-3">
            <label className="w-32">Location from: </label>
            <OdooStockLocationSelector
               value={locationId}
               onSelect={setLocationId}
               initialValue={15}
            />
         </div>

         <div className="flex items-center space-x-3">
            <label className="w-32">Location to: </label>
            <OdooStockLocationSelector
               value={locationDestId}
               onSelect={setLocationDestId}
            />
         </div>

         <hr />

         <h5>Products</h5>

         <div className="-mx-2">
            <table className="">
               <tbody>
                  {lines.map((line, index) => (
                     <tr
                        key={index}
                        className={line.productId ? '' : 'text-pink-600'}
                     >
                        <td className="">{line.name}</td>
                        <td className=" text-right">{line.qty}</td>
                     </tr>
                  ))}
               </tbody>
            </table>
         </div>

         <hr />

         <div>
            <Button
               title="Create"
               bold
               size="lg"
               loading={createInternalTransferRes.loading}
               disabled={
                  createInternalTransferRes.loading ||
                  partnerId === '0' ||
                  locationId === '0' ||
                  locationDestId === '0' ||
                  pickingTypeId === '0'
               }
               onClick={() => createInternalTransfer({ variables })}
            />
         </div>
      </div>
   );
};

export const InternalTransferFormForShipment = ({ shipment, onCompleted }) => {
   const rows = shipment.shipmentInvoices.flatMap((i) => i.shipmentInvoiceRows);

   const { loading, error, odooProducts } = useOdooProducts({
      ids: rows.map((i) => i.product.odooId).filter((i) => i),
   });

   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;

   const lines = rows
      .map((row) => {
         const odooProduct = odooProducts.find(
            (i) => i.id === row.product.odooId
         );
         return { ...row, odooProduct };
      })
      .filter((row) => row.odooProduct)
      .map((row) => ({ productId: row.odooProduct.id, qty: row.qty }));

   return (
      <InternalTransferForm
         lines={lines}
         onCompleted={onCompleted}
         extraVariables={{ shipmentId: shipment.id }}
      />
   );
};
