import { useQuery, gql, useMutation } from "@apollo/client";
import { Button } from "components/base";
import Errors from "components/Errors";
import Page from "components/Page";
import SearchBar from "components/SearchBar";
import Spinner from "components/Spinner";
import { Alert } from "components/Toast";
import { useState } from "react";

const FETCH_SHOPIFY_AND_FSI_INVENTORY = gql`
  query FETCH_SHOPIFY_AND_FSI_INVENTORY {
    fsiInventory
    shopifyInventories
  }
`;

const UPDATE_SHOPIFY_STOCK_FROM_FSI = gql`
  mutation UPDATE_SHOPIFY_STOCK_FROM_FSI {
    shopifyUpdateStockFromFsi {
      message
    }
  }
`;

const Inventory = () => {
  const { loading, error, data } = useQuery(FETCH_SHOPIFY_AND_FSI_INVENTORY);
  const [updateStock, updateStockRes] = useMutation(UPDATE_SHOPIFY_STOCK_FROM_FSI, {
    onCompleted: () => Alert("success", "Stock Updated."),
    onError: (error) => Alert("error", error.message),
    refetchQueries: [{ query: FETCH_SHOPIFY_AND_FSI_INVENTORY }],
    awaitRefetchQueries: true,
  });

  const [searchText, setSearchText] = useState("");

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  let fsiInventories = JSON.parse(data.fsiInventory).filter((i) => {
    if (searchText.trim() !== "") return i.sku.toLowerCase().includes(searchText.toLowerCase());
    return true;
  });

  let shopifyInventories = JSON.parse(data.shopifyInventories).filter((i) => {
    if (searchText.trim() !== "") return i.sku.toLowerCase().includes(searchText.toLowerCase());
    return true;
  });

  const allSkus = [...fsiInventories, ...shopifyInventories].map((i) => i.sku).filter((v, i, a) => a.indexOf(v) === i);
  const rows = allSkus
    .map((sku) => {
      const fsi = fsiInventories.find((i) => i.sku === sku);
      const shopify = shopifyInventories.find((i) => i.sku === sku);
      return {
        sku,
        description: fsi?.description || shopify?.description,
        fsiQty: parseInt(fsi?.availableQuantity || 0),
        shopifyQty: shopify?.qty || 0,
      };
    })
    .sort((a, b) => b.sku - a.sku)
    .filter((i) => i.fsiQty > 0 || i.shopifyQty > 0)
    .map((i) => ({ ...i, diff: i.fsiQty !== i.shopifyQty }));

  return (
    <Page title="Shopify">
      <div className="flex justify-between space-x-8 p-6 pb-0">
        <div className="flex-1">
          <SearchBar autoFocus className="py-1" placeholder="Search by SKU" value={searchText} onChange={setSearchText} />
        </div>
        <Button title="Update Shopify Stock from FSI" onClick={updateStock} loading={updateStockRes.loading} disabled={updateStockRes.loading} />
      </div>

      <div className="flex-1 overflow-auto">
        <div className="card m-6 px-4 py-2">
          <table>
            <thead>
              <tr>
                <th>Product({rows.length})</th>
                <th className="text-right">FSI Qty</th>
                <th className="text-right">Shopify Qty</th>
              </tr>
            </thead>
            <tbody>
              {rows.map((row, index) => (
                <tr
                  key={index}
                  className={`border-t dark:border-gray-700
                  ${row.diff ? "text-red-600" : ""}
                  `}
                >
                  <td>
                    [{row.sku}] {row.description}
                  </td>
                  <td className="text-right">{row.fsiQty}</td>
                  <td className="text-right">{row.shopifyQty}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Page>
  );
};

export default Inventory;
