import { useContext, useState } from 'react';
import { useQuery, gql, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import Spinner from 'components/Spinner';
import Errors from 'components/Errors';
import { Button, ButtonWithArrow } from 'components/base';
import { Input } from 'components/Form';
import { formatDate } from 'react-day-picker/moment';
import { calcSuitablePrice, productUnitCost, INC_AREA_IDS } from '../utils';
import PriceWithCurrency from '../PriceWithCurrency';
import { FRAGMENT_PRODUCT_FOR_COST } from 'graphql/fragments';
import { shippingCartons } from '../ShippingCartons';
import { useDebouncedCallback } from 'use-debounce';
import { SAVE_PRODUCT_SALES_PRICE } from '../graphql';
import { Alert } from 'components/Toast';
import moment from 'moment';
import XLSX from 'xlsx';
import { CurrencyContext } from 'CurrencyProvider';

const FETCH_PRODUCT_LINE_DETAIL = gql`
   query FETCH_PRODUCT_LINE_DETAIL(
      $id: ID
      $productLines: [ID]
      $productAreas: [ID]
   ) {
      products(
         productLines: $productLines
         productAreas: $productAreas
         prefetchCost: true
         isActive: true
      ) {
         results {
            id
            number
            name
            outerCartonCbm
            quantityPerCarton
            defaultQty
            productCost {
               cost
               date
            }
         }
      }
      productLine(id: $id) {
         id
         name
         salesPriceNotes
      }
      # salesPrices(productLineIds: $productLines) {
      #    id
      #    price
      #    updatedAt
      #    priceList {
      #       id
      #       name
      #    }
      #    product {
      #       id
      #    }
      # }
      # priceLists {
      #    id
      #    name
      #    currency
      # }
   }
`;

const ProductDetail = () => {
   const { id } = useParams();
   const { loading, error, data } = useQuery(FETCH_PRODUCT_LINE_DETAIL, {
      variables: { id, productLines: [id], productAreas: INC_AREA_IDS },
      fetchPolicy: 'network-only',
   });

   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;

   const { results } = data.products;

   const products = results.map((product) => {
      const cartonCost = shippingCartons.map((carton) => {
         const costPerUnit = (
            (product.outerCartonCbm /
               product.quantityPerCarton /
               carton.maxCbm) *
            carton.cost
         ).toFixed(3);
         return { ...carton, costPerUnit };
      });

      return { ...product, cartonCost };
   });

   // function download() {
   //    const data = products.map((i) => {
   //       const product = `[${i.number}] ${i.name}`;
   //       const qty = i.defaultQty;
   //       const cost = i.productCost.cost.toFixed(3);
   //       const updatedAt = i.productCost.date;
   //       const cartonCost = i.cartonCost
   //          .map((i) => `${i.name}: $${i.costPerUnit}`)
   //          .join(', ');
   //       const prices = priceLists.reduce((res, priceList) => {
   //          const found = i.prices.find((x) => x.priceList.id === priceList.id);
   //          res[priceList.name] =
   //             found && found.price ? found.price.price.toFixed(2) : '-';
   //          res[priceList.name + `(${priceList.currency})` + ' margin'] =
   //             found && found.margin ? found.margin.toFixed(2) + '%' : '-';
   //          return res;
   //       }, {});
   //       return { product, qty, cost, updatedAt, cartonCost, ...prices };
   //    });
   //    const sheet = XLSX.utils.json_to_sheet(data);
   //    const wb = XLSX.utils.book_new();
   //    XLSX.utils.book_append_sheet(wb, sheet, 'Cost - AB Sales Price');
   //    XLSX.writeFile(wb, 'Cost - AB Sales Price.xlsx');
   // }

   return (
      <div className="p-6 h-full overflow-auto">
         <div className="flex justify-between items-end">
            <h1>{data.productLine.name}</h1>
         </div>
         {data.productLine.salesPriceNotes ? (
            <div className="mt-4 bg-red-500 text-white border border-gray-100 rounded-xl p-4 text-base">
               {data.productLine.salesPriceNotes}
            </div>
         ) : null}

         <div className="mt-4 whitespace-nowrap bg-white p-6 rounded-2xl w-full overflow-auto">
            <table>
               <thead>
                  <tr>
                     <th>Item</th>
                     <th className="text-right">Qty</th>
                     <th className="text-right">Cost</th>
                     <th className="text-right">FOB Cost</th>
                  </tr>
               </thead>
               <tbody>
                  {products.map((variant) => (
                     <VariantRow key={variant.id} variant={variant} />
                  ))}
               </tbody>
            </table>
         </div>
      </div>
   );
};

const VariantRow = ({ variant }) => {
   return (
      <tr className="border-b dark:border-gray-700 border-gray-100">
         <td>
            <div>
               <div className="text-sm">
                  [{variant.number}] {variant.name}
               </div>
            </div>
         </td>
         <td className="text-right">{variant.defaultQty}</td>
         <td className="text-right">
            <div>
               <div>${variant.productCost.cost.toFixed(3)}</div>
               <div className="text-xs opacity-70">
                  {variant.productCost.date}
               </div>
            </div>
         </td>
         <td className="text-right">
            <div className="space-y-1">
               {variant.cartonCost.map((i, index) => (
                  <div
                     key={index}
                     className="flex space-x-2 items-center justify-end"
                  >
                     <label>{i.name}: </label>
                     <div>${i.costPerUnit}</div>
                  </div>
               ))}
            </div>
         </td>
      </tr>
   );
};

export default ProductDetail;
