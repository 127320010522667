import { gql, useMutation, useQuery } from "@apollo/client";
import { Button } from "components/base";
import Errors from "components/Errors";
import SearchBar from "components/SearchBar";
import Spinner from "components/Spinner";
import { Alert } from "components/Toast";
import moment from "moment";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { searchByProp } from "utils/search";

const FETCH_SUB_SUPPLIER_ORDERS = gql`
  query FETCH_SUB_SUPPLIER_ORDERS {
    subSupplierOrders {
      total
      results {
        id
        odooPoId
        odooPoName
        odooPoOrigin
        estimateDeliveryDate
        remark
        productionSupplier {
          id
          name
        }
        invoiceTotal
        createdAt
        createdBy {
          id
          email
        }
      }
    }
  }
`;

const MUTATION_CREATE_ORDER = gql`
  mutation {
    createSubSupplierOrder {
      order {
        id
      }
    }
  }
`;

const SubSupplierOrders = () => {
  const navigate = useNavigate();
  const { loading, error, data } = useQuery(FETCH_SUB_SUPPLIER_ORDERS, {
    fetchPolicy: "network-only",
  });
  const [createOrder, createOrderRes] = useMutation(MUTATION_CREATE_ORDER, {
    onCompleted(res) {
      navigate("/sub-supplier/orders/" + res.createSubSupplierOrder.order.id);
    },
    onError(error) {
      Alert("error", error.message);
    },
    refetchQueries: [FETCH_SUB_SUPPLIER_ORDERS],
  });
  const [searchText, setSearchText] = useState("");

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const orders = data.subSupplierOrders.results
    .map((i) => ({ ...i, supplierName: i.productionSupplier ? i.productionSupplier.name : "" }))
    .filter((i) => searchByProp(i, ["odooPoName", "supplierName"], searchText));

  return (
    <div className="p-6">
      <div className="flex items-center space-x-8">
        <Button title="+ Create Order" onClick={createOrder} disabled={createOrderRes.loading} loading={createOrderRes.loading} />
        <div className="flex-1">
          <SearchBar placeholder="Search by PO number or production supplier name." value={searchText} onChange={setSearchText} />
        </div>
      </div>

      <div className="card px-6 py-4 mt-4">
        <div className="-mx-2">
          <table>
            <thead>
              <tr>
                <th>Purchase Order</th>
                <th>Production Supplier</th>
                <th>Reference</th>
                <th className="text-right">Invoice Total</th>
                <th className="text-right">Estimate Delivery Date</th>
                <th className="text-right">Created</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order) => (
                <tr key={order.id} className="border-y border-gray-100 dark:border-gray-700">
                  <td>
                    <Link to={`/sub-supplier/orders/${order.id}`}>{order.odooPoName ? order.odooPoName : "#" + order.id}</Link>
                  </td>
                  <td>{order.productionSupplier ? order.productionSupplier.name : "-"}</td>
                  <td>{order.odooPoOrigin}</td>
                  <td className="text-right">{order.invoiceTotal}</td>
                  <td className="text-right">{order.estimateDeliveryDate ? moment(order.estimateDeliveryDate).format("YYYY-MM-DD") : "-"}</td>
                  <td className="text-right">
                    {moment(order.createdAt).format("YYYY-MM-DD")} by {order.createdBy ? order.createdBy.email : "-"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SubSupplierOrders;
