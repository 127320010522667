import gql from "graphql-tag";

export const CUSTOMER_FRAGMENT = gql`
  fragment customer on CustomerType {
    id
    name
    user {
      id
      email
    }
    subAccounts {
      id
      user {
        id
        firstName
        lastName
        email
      }
    }
    addresses {
      id
      address
      category
      isDefault
    }
    notes
    areas {
      id
      name
    }
    documents {
      id
      name
      uri
    }
    priceList {
      id
    }
  }
`;

export const UPDATE_CUSTOMER = gql`
  mutation UPDATE_CUSTOMER($id: ID!, $notes: String, $priceListId: ID) {
    updateCustomer(id: $id, notes: $notes, priceListId: $priceListId) {
      customer {
        id
        notes
        areas {
          id
          name
        }
        priceList {
          id
        }
      }
    }
  }
`;

export const UPDATE_CUSTOMER_PRODUCT_GROUPS = gql`
  mutation UPDATE_CUSTOMER_PRODUCT_GROUPS($customerId: ID!, $productGroupsId: [ID]) {
    updateCustomerProductGroups(customerId: $customerId, productGroupsId: $productGroupsId) {
      customer {
        id
        areas {
          id
          name
        }
      }
    }
  }
`;

export const FETCH_CUSTOMER = gql`
  query FETCH_CUSTOMER($id: ID!) {
    customer(id: $id) {
      ...customer
    }
  }
  ${CUSTOMER_FRAGMENT}
`;

export const FETCH_PRODUCT_AREAS = gql`
  query FETCH_PRODUCT_AREAS {
    allProductAreas {
      id
      name
    }
  }
`;

export const CUSTOMER_ADD_SUB_ACCOUNT = gql`
  mutation CUSTOMER_ADD_SUB_ACCOUNT($customerId: ID!, $userId: ID!) {
    customerAddSubAccount(customerId: $customerId, userId: $userId) {
      customer {
        ...customer
      }
    }
  }
  ${CUSTOMER_FRAGMENT}
`;

export const CUSTOMER_REMOVE_SUB_ACCOUNT = gql`
  mutation CUSTOMER_REMOVE_SUB_ACCOUNT($customerId: ID!, $accountId: ID!) {
    customerRemoveSubAccount(customerId: $customerId, accountId: $accountId) {
      customer {
        ...customer
      }
    }
  }
  ${CUSTOMER_FRAGMENT}
`;

export const CUSTOMER_CREATE_DOCUMENT = gql`
  mutation CUSTOMER_CREATE_DOCUMENT($id: ID!, $name: String!, $uri: String!) {
    customerCreateDocument(id: $id, name: $name, uri: $uri) {
      customer {
        ...customer
      }
    }
  }
  ${CUSTOMER_FRAGMENT}
`;

export const CUSTOMER_REMOVE_DOCUMENT = gql`
  mutation CUSTOMER_REMOVE_DOCUMENT($customerId: ID!, $documentId: ID!) {
    customerRemoveDocument(customerId: $customerId, documentId: $documentId) {
      customer {
        ...customer
      }
    }
  }
  ${CUSTOMER_FRAGMENT}
`;
