import { gql } from "@apollo/client";

export const LOGOUT = gql`
  mutation LOGOUT {
    logout {
      isLogout
    }
  }
`;

export const LOGIN_AS_USER = gql`
  mutation LOGIN_AS_USER($email: String!) {
    loginAsUser(userEmail: $email) {
      user {
        id
        email
        firstName
        lastName
        isSuperuser
        isStaff
        groups {
          id
          name
        }
      }
    }
  }
`;
