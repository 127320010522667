import React from 'react';
import randomcolor from 'randomcolor';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import Spinner from '../../../../components/Spinner';
import Errors from '../../../../components/Errors';
import Card from '../../../../components/Card';

const FETCH_OHS_DATA = gql`
   query($start: DateTime!, $end: DateTime!, $lastMonthEnd: DateTime!) {
      ohsAnalytics(start: $start, end: $end) {
         times
         title
         percentage
         userCount
         ordering
      }
      tilLastMonthOhsAnalytics: ohsAnalytics(
         start: $start
         end: $lastMonthEnd
      ) {
         times
         title
         percentage
         userCount
         ordering
      }
   }
`;

function OhsAnalytics({ start, end }) {
   const lastWeekEnd = new Date(
      new Date(end).setDate(new Date(end).getDate() - 7)
   );
   const lastMonthEnd = new Date(
      new Date(end).setMonth(new Date(end).getMonth() - 1)
   );
   const { loading, error, data } = useQuery(FETCH_OHS_DATA, {
      variables: { start, end, lastWeekEnd, lastMonthEnd },
   });
   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;
   if (!data) return null;
   console.log('data', data);
   const { ohsAnalytics, tilLastMonthOhsAnalytics } = data;
   const colors = randomcolor({ luminosity: 'bright', count: 5 });
   const sortedOhsAnalysis = [...ohsAnalytics].sort(
      (a, b) => a.ordering - b.ordering
   );
   const sortedTilLastMonthOhsAnalytics = [...tilLastMonthOhsAnalytics].sort(
      (a, b) => a.ordering - b.ordering
   );
   return (
      <Card title="OHS Visit Frequence">
         <div className="lg:flex items-start justify-center">
            <div className="lg:w-2/3 lg:mr-8 mb-6 flex-1 overflow-auto scrolling-touch">
               <table className="w-full">
                  <thead className="bg-gray-200">
                     <tr>
                        <th className="p-2">Times</th>
                        <th className="p-2">User Count</th>
                        <th className="p-2">Util Last Month</th>
                        <th className="p-2">Percentage</th>
                        <th className="p-2">Different</th>
                     </tr>
                  </thead>
                  <tbody>
                     {sortedOhsAnalysis.map((row, index) => (
                        <tr
                           className="font-bold"
                           key={index}
                           style={{ color: colors[index] }}
                        >
                           <td className="whitespace-no-wrap border-b border-gray-100 text-center p-2">
                              {row.title}
                           </td>
                           <td className="whitespace-no-wrap border-b border-gray-100 text-center p-2">
                              {row.userCount}
                           </td>
                           <td className="whitespace-no-wrap border-b border-gray-100 text-center p-2">
                              {sortedTilLastMonthOhsAnalytics[
                                 index
                              ].percentage.toFixed(2)}
                              %
                           </td>
                           <td className="whitespace-no-wrap border-b border-gray-100 text-center p-2">
                              {row.percentage.toFixed(2)}%
                           </td>
                           <td className="whitespace-no-wrap border-b border-gray-100 text-center p-2">
                              {(
                                 row.percentage -
                                 sortedTilLastMonthOhsAnalytics[index]
                                    .percentage
                              ).toFixed(2)}
                              %{' '}
                              {row.percentage -
                                 sortedTilLastMonthOhsAnalytics[index]
                                    .percentage >
                              0
                                 ? '↑'
                                 : '↓'}
                           </td>
                        </tr>
                     ))}
                  </tbody>
               </table>
            </div>
            <div className="lg:w-1/3">
               <ResponsiveContainer height={250}>
                  <PieChart>
                     <Pie
                        startAngle={90}
                        endAngle={-270}
                        data={ohsAnalytics}
                        cx="50%"
                        cy="50%"
                        label={(i) => `${i.title} times`}
                        nameKey="title"
                        dataKey="userCount"
                     >
                        {ohsAnalytics.map((_, index) => (
                           <Cell key={`cell-${index}`} fill={colors[index]} />
                        ))}
                     </Pie>
                  </PieChart>
               </ResponsiveContainer>
            </div>
         </div>
      </Card>
   );
}

export default OhsAnalytics;
