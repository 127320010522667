const ReportList = ({ reports }) => {
  return (
    <div className="mt-6 text-sm">
      <h4>Reports</h4>
      <div className="mt-2">
        {reports.map((report) => (
          <div key={report.id} className="border-b border-dashed dark:border-gray-700 py-2 flex justify-between">
            <a href={`/qc/final/${report.id}`} target="_blank" rel="noreferrer">
              {report.name}
            </a>

            <div className="w-40 ml-4 opacity-70">
              <div>Issue Rate: {report.issue_rate}%</div>

              {report.issue_types.map((desc, index) => (
                <div key={index} className="opacity-70">
                  {desc}
                </div>
              ))}
            </div>

          </div>
        ))}
      </div>
    </div>
  );
};

export default ReportList;
