import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import Spinner from './Spinner';
import Errors from './Errors';
import ProgressRing from './ProgressRing.js';
import Icon from './Icon.js';

const FETCH_AWS_PRESIGNED_URL = gql`
   query FETCH_AWS_PRESIGNED_URL($filename: String!, $contentType: String!) {
      awsPreSignedUrl(filename: $filename, contentType: $contentType) {
         url
         fields
      }
   }
`;

function Uploader({
   className = '',
   awsPreSignedUrl,
   file,
   preview,
   ignoreUploadedState,
   onUploaded,
}) {
   const [progress, setProgress] = useState(0);

   console.log('file', file);

   function uploadFile() {
      const config = {
         onUploadProgress: function (progressEvent) {
            const percentCompleted = Math.round(
               (progressEvent.loaded * 100) / progressEvent.total
            );
            setProgress(percentCompleted);
         },
      };
      const http = axios.create(config);

      const fields = JSON.parse(awsPreSignedUrl.fields);

      let data = new FormData();
      for (let [key, value] of Object.entries(
         JSON.parse(awsPreSignedUrl.fields)
      )) {
         data.append(key, value);
      }
      data.append('acl', 'public-read');
      data.append('content-type', file.type);
      data.delete('__typename');
      data.append('file', file);
      http
         .post(process.env.REACT_APP_AWS_CLIENT_UPLOAD_ENDPOINT, data, config)
         .then(function () {
            onUploaded(
               `${process.env.REACT_APP_AWS_CLIENT_UPLOAD_ENDPOINT}${fields.key}`
            );
         })
         .catch(function (err) {
            console.log(err);
         });
   }

   useEffect(uploadFile, [file]);

   const uploaded = progress === 100;

   return (
      <div className={`${className} relative`}>
         {preview ? <img className="h-32" src={preview} alt="preview" /> : null}
         <div
            className={`absolute inset-0 z-10 flex items-center justify-center
               ${preview ? (uploaded ? 'bg-dark-20' : 'bg-dark-50') : ''}
            `}
         >
            {!ignoreUploadedState && uploaded ? (
               <div className="text-green-500">
                  <Icon icon="check" size={30} />
               </div>
            ) : (
               <div style={{ width: 40, height: 40 }}>
                  <ProgressRing radius={20} stroke={2} progress={progress} />
               </div>
            )}
         </div>
      </div>
   );
}

function AWSUploader({
   file,
   preview,
   onUploaded,
   ignoreUploadedState,
   className,
}) {
   const { loading, error, data } = useQuery(FETCH_AWS_PRESIGNED_URL, {
      variables: { filename: file.name, contentType: file.type },
      fetchPolicy: 'network-only',
   });

   if (loading) return <Spinner text={null} />;
   if (error) return <Errors error={error} />;
   return (
      <Uploader
         className={className}
         awsPreSignedUrl={data.awsPreSignedUrl}
         file={file}
         preview={preview}
         onUploaded={onUploaded}
         ignoreUploadedState={ignoreUploadedState}
      />
   );
}

export default AWSUploader;
