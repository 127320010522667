import { gql, useQuery } from '@apollo/client';
import { Button } from 'components/base';
import { ProductRow } from './Products';

const FETCH_NEW_PRODUCTS = gql`
   query FETCH_NEW_PRODUCTS {
      products(isNew: true) {
         results {
            id
            name
            number
            odooId
            isActive
            createdAt
         }
      }
   }
`;

const NewProducts = ({ editProduct }) => {
   const { data } = useQuery(FETCH_NEW_PRODUCTS);

   if (data) {
      if (data.products.results.length > 0)
         return (
            <div>
               <div className="flex-shrink-0 w-96 bg-white dark:bg-gray-800 p-6 rounded-2xl">
                  <h5>New Products</h5>
                  <div className="mt-4">
                     {data.products.results.map((i) => (
                        <ProductRow
                           product={i}
                           key={i.id}
                           editProduct={() => editProduct(i)}
                        />
                     ))}
                  </div>
               </div>
            </div>
         );
   }

   return null;
};

export default NewProducts;
