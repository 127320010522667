import { useContext, useState } from "react";
import { ProductContext } from "./ProductForm";
import { InlineSpinner } from "components/Spinner";
import CharlesButton from "components/charles/base";
import { Text } from "components/Form";
import Syncer from "../odoo/Syncer";
import { useModals } from "ModalProvider";
import { Alert } from "components/Toast";

const NoteModal = ({ hide, originalNote, onSave, validInput, variables, saveProduct }) => {
  const [note, setNote] = useState(originalNote || "");
  variables.sendNotification = true;

  return (
    <div className="overflow-auto">
      <div>
        <h5>Note:</h5>
        <Text
          value={note}
          onChange={(e) => {
            setNote(e.target.value);
          }}
          placeholder="Add a note here to remind others about this product."
          rows={4}
          className="mt-2"
        />
        <p className="text-xs opacity-50 mb-4">Click the button to save product info and send a notification email.</p>

        <CharlesButton onClick={() => {
          if (validInput()) {
            variables.simpleFields.note = note;
            saveProduct({ variables }).then((res) => {
              if (onSave) onSave(res.data.saveProduct.product);
              Alert("success", "Notification email sent.");
              hide();
            });
          }
        }}>Send</CharlesButton>
      </div>
    </div>
  );
};

const BottomBar = () => {
  const {
    id,
    simpleFields,
    duplicate,
    onSave,
    onSaveAndClose,
    variables,
    saveProductRes,
    saveProduct,
    validInput,
    odooSyncerModal,
    duplicateHandler,
  } = useContext(ProductContext);

  const modal = useModals();

  return (
    <div className="sticky bottom-0 py-4 backdrop-filter backdrop-blur-lg bg-gray-100 dark:bg-gray-800 bg-opacity-50 -mx-10 px-10 border-t border-gray-200 dark:border-gray-700">
      <div className="flex space-x-8 items-center">
        {saveProductRes.loading ? (
          <div className="relative flex items-center space-x-2">
            <InlineSpinner size={18} text={null} />
            <b className="opacity-70">Saving...</b>
          </div>
        ) : (
          <>
            <CharlesButton
              loading={saveProductRes.loading}
              onClick={() => {
                if (validInput())
                  saveProduct({ variables }).then((res) => {
                    if (onSave) onSave(res.data.saveProduct.product);
                  });
              }}
            >
              Save
            </CharlesButton>
            <CharlesButton
              onClick={() => {
                modal.present({
                  title: "Notify Managers",
                  center: true,
                  children: <NoteModal hide={modal.hide} originalNote={simpleFields.note} onSave={onSave} validInput={validInput} variables={variables} saveProduct={saveProduct} />,
                });
              }}
            >
              Save and Notfiy
            </CharlesButton>
            <CharlesButton
              loading={saveProductRes.loading}
              onClick={() => {
                if (validInput()) saveProduct({ variables }).then(onSaveAndClose);
              }}
            >
              Save and Close
            </CharlesButton>
          </>
        )}

        {id && duplicate ? <CharlesButton onClick={duplicateHandler}>Duplicate Product</CharlesButton> : null}

        {id && !simpleFields.odooId ? (
          <CharlesButton
            onClick={() => {
              odooSyncerModal.present({
                title: "Sync Product to Odoo",
                maxWidth: "max-w-5xl",
                children: <Syncer id={id} onSave={odooSyncerModal.hide} />,
              });
            }}
          >
            Sync to Odoo
          </CharlesButton>
        ) : null}
      </div>
    </div>
  );
};

export default BottomBar;
