import CharlesButton from "components/charles/base";
import moment from "moment";
import { useMutation } from "@apollo/client";
import { Alert } from "components/Toast";
import { parseError } from "apollo";
import { useState } from "react";
import { CONFIRM_PRODUCTION_FORM } from "./graphql";

const WEEK_DAYS = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

const NewSupplierSubmitProductionInfoView = ({ previewForm, data, hide }) => {
  const [confirmType, setConfirmType] = useState(null);
  const [confirm, confirmRes] = useMutation(CONFIRM_PRODUCTION_FORM, {
    onCompleted: () => {
      Alert("success", "Confirmed.");
      hide();
    },
    onError: (error) => Alert("error", parseError(error)),
  });

  console.log("data", data);

  return (
    <div>
      <div className="">
        Supplier has submit the following production information at <b>{moment(data.createdAt).format("YYYY-MM-DD")}</b>.<br />
        please review and confirm.
      </div>

      <div className="mt-6 card">
        <h3>{data.name}</h3>
        <div className="mt-4 space-y-3">
          <div>
            <label className="mr-3">Type: </label>
            <span>{data.type}</span>
          </div>

          {data.type === "BY_DAY" ? (
            <div>
              <label className="mr-3">Qty per day: </label>
              <span>{data.qtyPerDay}</span>
            </div>
          ) : (
            <div className="mt-1 space-y-1">
              {data.timeList.map((time, timeIndex) => (
                <div key={timeIndex}>
                  <span>
                    Qty: {time.qty} - {time.days} days.
                  </span>
                </div>
              ))}
            </div>
          )}

          <div>
            <label className="mr-3">Max Capacity: </label>
            <span>{data.maxCapacity} / month</span>
          </div>

          <div>
            <label className="mr-3">Work on Week Days</label>
            <div className="mt-2 space-x-4 flex">
              {WEEK_DAYS.map((day, dayIndex) => (
                <span key={dayIndex} className="mr-2 flex items-center space-x-2">
                  <span>{day}</span>
                  <span>{data["workOn" + day] ? "✅" : "❌"}</span>
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="mt-6 space-y-2">
        <p>
          Note that accepting production update will not trigger recompute production plans. It will only effect the future plans. If you need to recompute the
          plan after accepting, please do it manually. <br />
          Right now the max capacity has no effect on production plans. It will be considered in the future.
        </p>
        <p>
          By Accepting this production update, you are confirming that you have reviewed the information and it is correct. This information will be saved to
          our system and will be used to in related places like production plans.
        </p>
      </div>

      <hr />

      {previewForm.acceptedAt ? (
        <div className="text-lg font-semibold text-teal-600">
          <div className="mt-6">Accepted at: {moment(previewForm.acceptedAt).format("YYYY-MM-DD HH:mm")}</div>
        </div>
      ) : previewForm.rejectedAt ? (
        <div className="text-lg font-semibold text-red-600">
          <div className="mt-6">Rejected at: {moment(previewForm.rejectedAt).format("YYYY-MM-DD HH:mm")}</div>
        </div>
      ) : (
        <div className="flex space-x-6 mt-8">
          <CharlesButton
            onClick={() => {
              confirm({ variables: { id: previewForm.id, confirmType: "accept" } });
              setConfirmType("accept");
            }}
            loading={confirmRes.loading && confirmType === "accept"}
            disabled={confirmType !== null}
          >
            Accept
          </CharlesButton>
          <CharlesButton
            danger
            onClick={() => {
              confirm({ variables: { id: previewForm.id, confirmType: "reject" } });
              setConfirmType("reject");
            }}
            loading={confirmRes.loading && confirmType === "reject"}
            disabled={confirmType !== null}
          >
            Reject
          </CharlesButton>
        </div>
      )}
    </div>
  );
};

export default NewSupplierSubmitProductionInfoView;
