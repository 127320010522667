import { Input } from "components/Form";
import CharlesButton from "components/charles/base";
import { useState } from "react";
import { CREATE_PRODUCT_LIST_VERSION } from "./graphql";
import { useMutation } from "@apollo/client";
import { Alert } from "components/Toast";
import { useNavigate } from "react-router-dom";

const CreatenewVersionView = ({ productListId }) => {
  const [version, setVersion] = useState("");
  const navigate = useNavigate();

  const [createProductListVersion, createProductListVersionRes] = useMutation(CREATE_PRODUCT_LIST_VERSION, {
    variables: { productListId, version },
    onCompleted: (data) => {
      Alert("sucess", "New Product List Version Created.");
      navigate(`/product-list/${data.createProductListVersion.productList.id}`);
    },
    onError: (error) => Alert("error", error.message),
    refetchQueries: ["FETCH_PRODUCT_LISTS"],
  });

  return (
    <div>
      <div>
        <p>
          Please input a new version number e.g: 1.1, 1.2, 2, 2.1, etc. Note that You are creaing a new version base on the current version, the new version
          will duplicate the data from the current version.
        </p>

        <div className="mt-3">
          <label htmlFor="">Version Number: </label>
          <Input className="text-center" type="number" value={version} onChange={(e) => setVersion(e.target.value)} />
        </div>
      </div>

      <div className="mt-8">
        <CharlesButton primary onClick={createProductListVersion} loading={createProductListVersionRes.loading}>
          Create
        </CharlesButton>
      </div>
    </div>
  );
};

export default CreatenewVersionView;
