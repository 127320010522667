import React from "react";
import { gql, useQuery } from "@apollo/client";
import { InlineSpinner } from "components/Spinner";
import Errors from "components/Errors";
import { Select } from "components/Form";

const FETCH_ALL_SUPPLIERS = gql`
  query FETCH_ALL_SUPPLIERS {
    allFactories(isActive: true) {
      id
      name
      status
      preferPaymentTerms
      fullAddressCn
      bankName
      bankAccountNumber
      bankAccountUsername
    }
  }
`;

function SupplierSelector({ value, onChange, ...rest }) {
  const { loading, error, data } = useQuery(FETCH_ALL_SUPPLIERS);

  if (loading)
    return (
      <div className="flex relative">
        <InlineSpinner size={18} text={null} />
      </div>
    );
  if (error) return <Errors error={error} />;

  return (
    <Select value={value || "0"} onChange={(e) => onChange(data.allFactories.find((x) => x.id === e.target.value))} {...rest}>
      <option value="0">-- Select a Factory --</option>
      {data.allFactories
        .filter((i) => i.status !== "0")
        .map((factory) => (
          <option key={factory.id} value={factory.id}>
            {factory.name}
          </option>
        ))}
    </Select>
  );
}

export default SupplierSelector;
