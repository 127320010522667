import { Input } from "components/Form";
import { Button, ButtonX } from "components/base";
import CharlesButton from "components/charles/base";
import { BsTrash } from "react-icons/bs";

const MaterialsInput = ({ className = "", materials, setMaterials }) => {
  return (
    <div className={className}>
      {materials.map((i, index) => (
        <div key={index} className="flex mb-2 space-x-4 items-center">
          <Input
            className="flex-1"
            placeholder="Name"
            value={i.name}
            onChange={(e) =>
              setMaterials((prev) =>
                prev.map((j, jIndex) => {
                  if (index === jIndex)
                    return {
                      ...j,
                      name: e.target.value,
                    };
                  return j;
                })
              )
            }
          />
          <div className="flex items-center space-x-4">
            <span>&times; </span>
            <Input
              placeholder="Percentage"
              className="text-right w-20"
              value={i.percentage}
              onChange={(e) =>
                setMaterials((prev) =>
                  prev.map((j, jIndex) => {
                    if (index === jIndex)
                      return {
                        ...j,
                        percentage: e.target.value,
                      };
                    return j;
                  })
                )
              }
            />
            <span>%</span>
          </div>

          <div>
            <CharlesButton danger onClick={() => setMaterials((prev) => prev.filter((_, prevIndex) => prevIndex !== index))}>
              <BsTrash />
            </CharlesButton>
          </div>
        </div>
      ))}

      <div className="pb-2">
        <Button title="+ add material" onClick={() => setMaterials((prev) => [...prev, { name: "", percentage: "" }])} />
      </div>
    </div>
  );
};

export default MaterialsInput;
