import { useQuery } from "@apollo/client";
import { INC_PRODUCT_CATEGORIES } from "./graphql";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import Overview from "./Overview";

const OverviewLoader = () => {
  const { loading, error, data } = useQuery(INC_PRODUCT_CATEGORIES);

  if (loading) return <Spinner />;
  if (error) return <Errors errors={error} />;

  const categories = data.incProductCategories;

  if (categories.length === 0) {
    return (
      <div className="flex items-center justify-center h-full">
        <div className="text-2xl font-semibold">No Product Categories Yet.</div>
      </div>
    );
  }

  const sortedCategoryByYear = [...categories].sort((a, b) => b.year - a.year);
  const latestCategory = sortedCategoryByYear[0];

  const categoryGroups = categories.reduce((acc, category) => {
    if (!acc[category.year]) {
      acc[category.year] = [];
    }
    acc[category.year].push(category);
    return acc;
  }, {});

  return <Overview categoryGroups={categoryGroups} latestCategory={latestCategory} />;
};

export default OverviewLoader;
