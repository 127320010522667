import { gql, useQuery } from "@apollo/client";
import Errors from "./Errors";
import { Select } from "./Form";

const GET_PRICE_LISTS = gql`
  query GET_PRICE_LISTS {
    priceLists {
      id
      odooId
      name
      currency
    }
  }
`;

const WisPriceListSelector = ({ value, onChange, ...rest }) => {
  const { loading, error, data } = useQuery(GET_PRICE_LISTS);

  if (error) return <Errors error={error} />;

  return (
    <Select
      value={value || "0"}
      onChange={(e) => {
        const found = e.target.value !== "0" ? data.priceLists.find((i) => i.id === e.target.value) : null;
        onChange(found);
      }}
      {...rest}
    >
      {loading ? (
        <option disabled>loading...</option>
      ) : (
        <>
          <option value="0">Not Set</option>
          {data.priceLists.map((i) => (
            <option value={i.id} key={i.id}>
              {i.name} ({i.currency})
            </option>
          ))}
        </>
      )}
    </Select>
  );
};

export default WisPriceListSelector;
