import { useContext, useState } from "react";
import { ProductContext } from "./ProductForm";
import CharlesButton, { CharlesButtonWithArrow } from "components/charles/base";
import ProductPrice from "./ProductPrice";
import { Switcher } from "components/Form";

const CostView = () => {
  const { id, prices, setPrices, onChangeComponentPrice, syncPriceToOdoo, syncPriceToOdooRes, showCost, setShowCost, odooProduct } = useContext(ProductContext);

  const hasArchivedCost = prices.filter((i) => i.archivedAt).length > 0;

  const [showArchivedCost, setShowArchivedCost] = useState(false);

  return (
    <section>
      <div className="">
        <CharlesButtonWithArrow className="text-xl" show={showCost} onClick={() => setShowCost((prev) => !prev)}>
          Cost
        </CharlesButtonWithArrow>
      </div>

      {showCost ? (
        <>
          <div className="flex-1 space-y-4 mt-4 -mx-2">
            <table>
              <thead>
                <tr>
                  <th>Supplier</th>
                  <th>QTY</th>
                  <th>Cost</th>
                  <th>Currency</th>
                  <th>Available</th>
                  <th>Effective Date</th>
                  <th>Archive at</th>
                  <th>Archive Code</th>
                  <th>Archive Note</th>
                </tr>
              </thead>
              <tbody>
                {prices.map((price, index) => (
                  <ProductPrice
                    key={index}
                    price={price}
                    onChange={(field, value) => onChangeComponentPrice(index, field, value)}
                    onDelete={() => setPrices((prev) => prev.filter((_, prevIndex) => prevIndex !== index))}
                    showArchivedCost={showArchivedCost}
                  />
                ))}
              </tbody>
            </table>
          </div>

          <div className="flex items-center justify-between">
            <div className="flex space-x-8 mt-4">
              <CharlesButton
                onClick={() => {
                  setPrices((prev) => [
                    ...prev,
                    {
                      minQty: 0,
                      price: 0,
                      currency: "USD",
                      factoryId: "0",
                      avaliableFromMonth: 1,
                      avaliableToMonth: 12,
                    },
                  ]);
                }}
              >
                + Price
              </CharlesButton>

              {odooProduct && prices.filter((i) => !i.archivedAt).length > 0 ? (
                <CharlesButton
                  loading={syncPriceToOdooRes.loading}
                  onClick={() =>
                    syncPriceToOdoo({
                      variables: { id, computed: false },
                    })
                  }
                >
                  Sync WIS Prices to Odoo
                </CharlesButton>
              ) : null}

              {hasArchivedCost ? (
                <div className="flex items-center space-x-2">
                  <label htmlFor="">Show Archived Cost: </label>
                  <Switcher isOn={showArchivedCost} onChange={() => setShowArchivedCost((prev) => !prev)} />
                </div>
              ) : null}
            </div>
          </div>
        </>
      ) : null}
    </section>
  );
};

export default CostView;
