import React from 'react';
import Card from '../../../components/Card.js';
import ShipmentStatus from '../../../components/ShipmentStatus.js';

const statusCalculatingMethods = {
   'waiting for deposit payment': {
      start: 'waiting for deposit payment',
      end: 'in production',
   },
   'in production': {
      start: 'in production',
      end: 'delivered to port',
   },
   'delivered to port': {
      start: 'delivered to port',
      end: 'shipping',
   },
   shipping: {
      start: 'shipping',
      end: 'arrived at destination',
   },
};

function calcTimeForStatus(shipment, status) {
   const method = statusCalculatingMethods[status];
   const start = shipment.shipmentStatus.find((s) => s.status === method.start);
   if (!start) return 0;
   const end = shipment.shipmentStatus.find((s) => s.status === method.end);
   if (!end || end < start) return 0;
   return Math.round((end.created - start.created) / (24 * 60 * 60));
}

function StatusDays({ shipmentStatusList, shipments }) {
   const statusList = shipmentStatusList
      .filter(
         (i) =>
            i !== 'canceled' &&
            i !== 'on hold' &&
            i !== 'arrived at destination'
      )
      .map((status) => {
         let totalTime = 0;
         let totalCount = 0;
         shipments.forEach((shipment) => {
            const time = calcTimeForStatus(shipment, status);
            if (time) {
               totalTime += time;
               totalCount += 1;
            }
         });
         return {
            status,
            aveDays: totalCount > 0 ? Math.round(totalTime / totalCount) : 0,
         };
      });

   return (
      <Card title="Average Time">
         {statusList.map((i, index) => {
            return (
               <div className="flex items-center mt-4" key={index}>
                  <div className="w-24">
                     <span className="text-gray-700 font-bold">
                        {i.aveDays}
                     </span>{' '}
                     days
                  </div>
                  <ShipmentStatus className="ml-4 text-xs" status={i.status} />
               </div>
            );
         })}
      </Card>
   );
}

export default StatusDays;
