import { formatDate } from 'react-day-picker/moment';
import { getDates } from 'utils/date';
import {
   ResponsiveContainer,
   LineChart,
   Line,
   XAxis,
   YAxis,
   Tooltip,
} from 'recharts';
import { randomColors } from 'utils/colors';
import { DatePicker } from 'components/Form';
import { useState } from 'react';

const StockChart = ({ history }) => {
   const historyDates = history.map((i) => i.date);

   let x = new Date().addDays(-180);
   const [startDate, setStartDate] = useState(x);

   const start = startDate;
   const end = Math.max.apply(null, historyDates);

   const dates = getDates(new Date(start), new Date(end)).map((i) =>
      formatDate(i, 'YYYY-MM-DD')
   );

   let chartData = [];
   dates.forEach((date, index) => {
      const rows = history.filter(
         (row) => formatDate(row.date, 'YYYY-MM-DD') === date
      );
      let inventory = 0;
      if (rows.length > 0) {
         inventory = rows[rows.length - 1].cumsum;
      } else if (index > 0) {
         inventory = chartData[index - 1].inventory;
      }

      const res = { date, inventory };
      chartData.push(res);
   });

   return (
      <div className="flex-1 overflow-auto space-y-4">
         <div className="flex items-center">
            <h4>Inventory</h4>
         </div>
         <div className="flex space-x-3 items-center">
            <label htmlFor="">Start Date:</label>
            <DatePicker value={startDate} onDayChange={setStartDate} />
         </div>
         <div className="py-6">
            <ResponsiveContainer width="100%" height={300}>
               <LineChart data={chartData} margin={0}>
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip content={CustomTooltip} />
                  <Line
                     dot={false}
                     type="step"
                     dataKey="inventory"
                     stroke={randomColors(10)[0]}
                  />
               </LineChart>
            </ResponsiveContainer>
         </div>
      </div>
   );
};

const CustomTooltip = ({ active, payload, label }) => {
   if (active && payload && payload.length) {
      const data = payload[0];

      return (
         <div className="px-6 py-3 rounded-2xl bg-opacity-80 bg-white dark:bg-gray-800 dark:bg-opacity-80">
            <div>{`${label}: ${data.value}`}</div>
         </div>
      );
   }

   return null;
};

export default StockChart;
