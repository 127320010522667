import { gql, useQuery } from "@apollo/client";
import { InlineSpinner } from "components/Spinner";
import Errors from "components/Errors";
import { Select } from "components/Form";
import OdooProductCategoryForm from "../All/OdooProductCategoryForm";
import { Button } from "components/base";
import { useModals } from "ModalProvider";

const FETCH_DATA = gql`
  {
    odooProductCategories {
      id
      displayName
    }
  }
`;

const OdooProductCategorySelector = ({ value, onChange }) => {
  const { loading, error, data } = useQuery(FETCH_DATA);
  const { present } = useModals();

  if (loading) return <InlineSpinner />;
  if (error) return <Errors error={error} />;

  return (
    <div className="flex items-center space-x-4">
      <Select className="w-full" value={value || "0"} onChange={(e) => onChange(e.target.value)}>
        <option value="0">Select a Category</option>
        {data.odooProductCategories.map((cat) => (
          <option key={cat.id} value={cat.id}>
            {cat.displayName}
          </option>
        ))}
      </Select>
      <Button
        title="+"
        bold
        size="lg"
        onClick={() =>
          present({
            title: "Create Category",
            subtitle: "Odoo Category",
            center: true,
            children: <OdooProductCategoryForm categories={data.odooProductCategories} onSave={(i) => onChange(i.id)} />,
          })
        }
      />
    </div>
  );
};

export default OdooProductCategorySelector;
