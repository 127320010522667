import React, { useState, useContext, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { Button } from '../../../../components/base';
import { DatePicker, Text, Input, Switcher } from '../../../../components/Form';
import { Alert } from '../../../../components/Toast';
import { formatDate, parseDate } from 'react-day-picker/moment';
import { CREATE_SHIPMENT_ERROR, UPDATE_SHIPMENT_ERROR } from '../../graphql';
import ShipmentContext from '../ShipmentContext';
import track, { actions } from '../../../../utils/track';
import ErrorSelector from './ErrorSelector';
import FactoriesSelector from './FactoriesSelector';
import ErrorProductsSelector from './ErrorProductsSelector';
import moment from 'moment';
import CharlesButton from 'components/charles/base';

function ShipmentErrorForm({
   shipmentStatusId,
   editingShipmentStatusError,
   hideForm,
}) {
   const { state, dispatch } = useContext(ShipmentContext);
   const [error, setError] = useState(null);
   const [factoriesRequired, setFactoriesRequired] = useState(false);
   const [productsRequired, setProductsRequired] = useState(false);
   const [factoryIds, setFactoryIds] = useState([]);
   const [shipmentInvoiceRowIds, setShipmentInvoiceRowIds] = useState([]);
   const [notes, setNotes] = useState('');
   const [created, setCreated] = useState(+new Date());
   const [hasDelay, setHasDelay] = useState(false);
   const [newEtd, setNewEtd] = useState(null);
   const [newEta, setNewEta] = useState(null);
   const [delayDays, setDelayDays] = useState(0);

   const shipmentStatus = state.shipmentStatus.find(
      (i) => i.id === shipmentStatusId
   );
   console.log('shipmentStatus', shipmentStatus);

   useEffect(() => {
      if (editingShipmentStatusError) {
         setError(editingShipmentStatusError.error);
         checkRequiredFieldsForError(editingShipmentStatusError.error);
         setFactoryIds(
            editingShipmentStatusError.shipmentStatusErrorFactories.map(
               (f) => f.factory.id
            )
         );
         setShipmentInvoiceRowIds(
            editingShipmentStatusError.shipmentStatusErrorProductRows.map(
               (row) => row.shipmentInvoiceRow.id
            )
         );
         setNotes(editingShipmentStatusError.notes);
         setCreated(editingShipmentStatusError.created * 1000);
         setHasDelay(editingShipmentStatusError.hasDelay);
         setNewEtd(editingShipmentStatusError.newEtd * 1000);
         setNewEta(editingShipmentStatusError.newEta * 1000);
         setDelayDays(editingShipmentStatusError.delayDays);
      }
   }, [editingShipmentStatusError]);

   useEffect(() => {
      if (shipmentStatus.status === 'delivered to port') {
         let deliveredToPortDate = new Date(shipmentStatus.created * 1000);
         deliveredToPortDate.setHours(0, 0, 0, 0);
         let requestCargoReadyDate = new Date(
            state.requestCargoReadyDate * 1000
         );
         requestCargoReadyDate.setHours(0, 0, 0, 0);
         const diff = moment
            .duration(
               moment(deliveredToPortDate).diff(moment(requestCargoReadyDate))
            )
            .asDays();

         if (diff >= 7) {
            setHasDelay(true);
            setDelayDays(diff);
         }
      }
   }, []);

   const [createShipmentError, { loading }] = useMutation(
      CREATE_SHIPMENT_ERROR,
      {
         onCompleted: ({ createShipmentError }) => {
            dispatch({
               type: 'createShipmentStatusError',
               payload: {
                  shipmentStatusId,
                  shipmentStatusError: createShipmentError.shipmentError,
                  shipment: createShipmentError.shipment,
               },
            });
            hideForm();
         },
      }
   );

   const [updateShipmentError, updateShipmentErrorRes] = useMutation(
      UPDATE_SHIPMENT_ERROR,
      {
         onCompleted: ({ updateShipmentError }) => {
            Alert('success', 'Saved.');
            dispatch({
               type: 'updateShipmentStatusError',
               payload: {
                  shipmentStatusId,
                  shipmentStatusError: updateShipmentError.shipmentError,
                  shipment: updateShipmentError.shipment,
               },
            });
            hideForm();
         },
         onError: (error) => Alert('error', error.message),
      }
   );

   function checkRequiredFieldsForError(error) {
      const requiredString = error.tags
         .map((i) => i.name)
         .join('')
         .toLowerCase()
         .replace(/\s+/g, '');

      const factoriesRequired = requiredString.indexOf('factoryrelated') > -1;
      const productsRequired = requiredString.indexOf('productrelated') > -1;
      setFactoriesRequired(factoriesRequired);
      setProductsRequired(productsRequired);
      if (!factoriesRequired) setFactoryIds([]);
      if (!productsRequired) setShipmentInvoiceRowIds([]);
   }

   function onSelect(error) {
      setError(error);
      checkRequiredFieldsForError(error);
   }

   function saveShipmentError() {
      if (editingShipmentStatusError) {
         console.log('editingShipmentStatusError', editingShipmentStatusError);
         updateShipmentError({
            variables: {
               id: editingShipmentStatusError.id,
               errorId: error.id,
               shipmentInvoiceRowIds,
               factoryIds,
               notes,
               hasDelay,
               delayDays,
               created: created / 1000,
               newEtd: newEtd / 1000,
               newEta: newEta / 1000,
            },
         });
      } else {
         createShipmentError({
            variables: {
               shipmentStatusId,
               errorId: error.id,
               shipmentInvoiceRowIds,
               factoryIds,
               notes,
               hasDelay,
               delayDays,
               created: created / 1000,
               newEtd: newEtd / 1000,
               newEta: newEta / 1000,
            },
         });
         track(actions.shipment.createShipmentError.name, {
            errorId: error.id,
         });
      }
   }

   let isSaveButtonEnable = true;
   if (loading) isSaveButtonEnable = false;
   if (factoriesRequired && factoryIds.length === 0) isSaveButtonEnable = false;
   if (productsRequired && shipmentInvoiceRowIds.length === 0)
      isSaveButtonEnable = false;

   return (
      <div className="shadow-lg rounded-lg bg-white dark:bg-gray-800 p-4 lg:p-6">
         <div className="text-base font-bold">
            {editingShipmentStatusError ? 'Edit Error' : 'Add an Error'}
         </div>
         <div className="mt-2 lg:mt-4">
            <div className="relaitve flex items-center">
               <label className="mr-4 w-16">Error:</label>
               <ErrorSelector
                  value={error ? error.id : 0}
                  onSelect={onSelect}
               />
            </div>
            {factoriesRequired && (
               <div className="relaitve mt-2 lg:mt-4 flex items-baseline">
                  <label className="mr-4 w-16">Factories:</label>
                  <div className="flex">
                     <FactoriesSelector
                        value={factoryIds}
                        onSelect={(value) => setFactoryIds(value)}
                     />
                  </div>
               </div>
            )}
            {productsRequired && (
               <div className="relaitve mt-2 lg:mt-4 flex items-start">
                  <label className="mr-4 w-16 pt-2">Products:</label>
                  <div className="flex-1">
                     <ErrorProductsSelector
                        shipmentInvoices={state.shipmentInvoices}
                        value={shipmentInvoiceRowIds}
                        onSelect={(value) => setShipmentInvoiceRowIds(value)}
                     />
                  </div>
               </div>
            )}
            <div className="flex items-center mt-2">
               <label className="w-16 mr-4">Date: </label>
               <DatePicker
                  className="mt-1"
                  parseDate={parseDate}
                  formatDate={formatDate}
                  format="YYYY-MM-DD"
                  value={new Date(created)}
                  onDayChange={(selectedDay) => {
                     if (selectedDay) setCreated(selectedDay.getTime());
                  }}
               />
            </div>
            <Text
               placeholder="Error notes"
               rows={3}
               async={true}
               className="mt-4"
               value={notes}
               onChange={(e) => setNotes(e.target.value)}
            />
         </div>
         <div className="pt-4 flex items-center space-x-3">
            <label className="pr-2 flex items-center cursor-pointer">
               <span>Cause Delay?</span>
               <Switcher
                  className="ml-4"
                  isOn={hasDelay}
                  onChange={() => setHasDelay(!hasDelay)}
               />
            </label>
            {hasDelay && (
               <div>
                  <label className="pr-2">Delay Days: </label>
                  <Input
                     className="mt-1 lg:mt-0 text-center"
                     type="number"
                     value={delayDays}
                     onChange={(e) => setDelayDays(e.target.value)}
                  />
               </div>
            )}
         </div>
         <div className="mt-4 flex space-x-6">
            <CharlesButton
               disabled={loading || !isSaveButtonEnable}
               loading={loading || updateShipmentErrorRes.loading}
               onClick={saveShipmentError}
            >
               Save
            </CharlesButton>
            <CharlesButton onClick={hideForm}>Cancel</CharlesButton>
         </div>
      </div>
   );
}

export default ShipmentErrorForm;
