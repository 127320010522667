import gql from 'graphql-tag';

export const VIDEO_FILE_FRAGMENT = gql`
   fragment videoFile on VideoFileNode {
      id
      name
      videoFile
      uri
      dimension
   }
`;

export const VIDEO_DETAIL_FRAGMENT = gql`
   fragment videoDetail on VideoNode {
      id
      isGif
      name
      description
      poster
      previewUri
      categories {
         id
         flatName
      }
      files {
         ...videoFile
      }
   }
   ${VIDEO_FILE_FRAGMENT}
`;

export const FETCH_VIDEO = gql`
   query FETCH_VIDEO($id: ID!) {
      video(id: $id) {
         ...videoDetail
      }
   }
   ${VIDEO_DETAIL_FRAGMENT}
`;

export const FETCH_ALL_VIDEOS = gql`
   query FETCH_ALL_VIDEOS {
      allVideos {
         id
         name
         description
         ordering
         active
         sources {
            id
            file
            filename
            thumbSrc
            convertedVideoSrc
            sourceUrl
            height
            status
         }
      }
   }
`;

export const UPDATE_VIDEO = gql`
   mutation UPDATE_VIDEO($video: VideoInput!) {
      updateVideo(videoInput: $video) {
         video {
            ...videoDetail
         }
      }
   }
   ${VIDEO_DETAIL_FRAGMENT}
`;

export const DELETE_VIDEO = gql`
   mutation DELETE_VIDEO($id: ID!) {
      deleteVideo(id: $id) {
         id
      }
   }
`;

export const UPDATE_VIDEO_FILE = gql`
   mutation UPDATE_VIDEO_FILE(
      $id: ID!
      $name: String
      $file: Upload
      $dimension: String
   ) {
      updateVideoFile(
         id: $id
         name: $name
         file: $file
         dimension: $dimension
      ) {
         videoFile {
            ...videoFile
         }
      }
   }
   ${VIDEO_FILE_FRAGMENT}
`;

export const DELETE_VIDEO_FILE = gql`
   mutation DELETE_VIDEO_FILE($id: ID!) {
      deleteVideoFile(id: $id) {
         id
      }
   }
`;
