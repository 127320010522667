import Spinner from 'components/Spinner';
import Errors from 'components/Errors';
import { formatDate } from 'react-day-picker/moment';
import { useQuery, gql } from '@apollo/client';
import StockChart from './StockChart';
import { useState } from 'react';
import { Button } from 'components/base';

const FETCH_ODOO_PRODUCT_INVENTORY = gql`
   query FETCH_ODOO_PRODUCT_INVENTORY($id: Int!, $ids: [Int]) {
      odooProduct(id: $id) {
         id
         name
         defaultCode
      }
      odooStockHistory(productIds: $ids)
   }
`;

const OdooProductStock = ({ id }) => {
   const { loading, error, data } = useQuery(FETCH_ODOO_PRODUCT_INVENTORY, {
      variables: { id, ids: [id] },
   });
   const [showAll, setShowAll] = useState(false);

   if (loading) return <Spinner text="Loading data from Odoo..." />;
   if (error) return <Errors error={error} />;

   const history = JSON.parse(data.odooStockHistory).map((i) => ({
      ...i,
      ...i.products[0],
   }));

   const batches = computeBatches(history);
   const batchesToShow = showAll ? batches : batches.slice(-5);

   return (
      <div>
         <div className="bg-white dark:bg-gray-900 dark:bg-opacity-70 rounded-2xl border border-gray-100 dark:border-gray-900 p-6">
            <StockChart history={history} />
         </div>

         <div className="mt-8">
            <Button
               title={showAll ? 'Show Recent Orders' : 'Show All Orders'}
               onClick={() => setShowAll((prev) => !prev)}
            />
         </div>

         <div className="overflow-auto space-y-6 pt-8">
            {batchesToShow.map((batch, batchIndex) => (
               <div
                  key={batchIndex}
                  className="bg-white dark:bg-gray-900 dark:bg-opacity-7 rounded-2xl border border-gray-100 dark:border-gray-900 overflow-auto py-2"
               >
                  <table>
                     <thead>
                        <tr>
                           <th className="text-left px-4 py-2">
                              {formatDate(batch.po.date, 'YYYY-MM-DD')}{' '}
                              {batch.po.name}
                           </th>
                           <th className="px-4 py-2 w-1/5 text-right">
                              {batch.po.state}
                           </th>
                           <th className="px-4 py-2 w-1/5 text-right">
                              {batch.po.qty}
                           </th>
                           <th className="px-4 py-2 w-1/5 text-right">
                              {batch.po.cumsum}
                           </th>
                        </tr>
                     </thead>
                     <tbody>
                        {batch.updates.map((row) => (
                           <tr key={row.id} className="text-right">
                              <td className="px-4 py-1 text-left">
                                 {formatDate(row.date, 'YYYY-MM-DD')}:{' '}
                                 {row.name} / [{row.origin}]
                              </td>
                              <td className="px-4 py-1">{row.state}</td>
                              <td className="px-4 py-1">{row.qty}</td>
                              <td className="px-4 py-1">{row.cumsum}</td>
                           </tr>
                        ))}
                     </tbody>
                     {batch.updates.length > 0 ? (
                        <tfoot>
                           <tr className="font-semibold">
                              <td className="px-4 py-2" colSpan={2}>
                                 Left
                              </td>
                              <td></td>
                              <td className="px-4 py-2 text-right">
                                 {batch.left}
                              </td>
                           </tr>
                        </tfoot>
                     ) : null}
                  </table>
               </div>
            ))}
         </div>
      </div>
   );
};

function computeBatches(history) {
   let batches = [];
   let currentBatch = null;
   history.forEach((row, index) => {
      if (row.type === 'PO') {
         if (currentBatch) batches.push(currentBatch);
         currentBatch = {
            po: row,
            updates: [],
            left: row.cumsum,
         };
      } else {
         if (currentBatch) currentBatch.updates.push(row);
         if (index === history.length - 1) {
            batches.push(currentBatch);
         }
      }
   });

   return batches.map((i) => ({
      ...i,
      left:
         i.updates.length > 0
            ? i.updates[i.updates.length - 1].cumsum
            : i.po.cumsum,
   }));
}

export default OdooProductStock;
