import React, { useState, useRef, useEffect } from 'react';
import { useQuery, gql } from '@apollo/client';
import { FixedSizeList } from 'react-window';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import Spinner from 'components/Spinner';
import Errors from 'components/Errors';
import SearchBar from 'components/SearchBar';
import { searchByProp } from 'utils/search';
import ProductRow from './ProductRow';
import { Select } from 'components/Form';

const FETCH_PAGE_DATA = gql`
   query FETCH_PAGE_DATA {
      allProducts(isActive: true, isPurchasable: true) {
         id
         number
         name
         odooId
         archivedAt
         preferedSupplier {
            id
            name
         }
         productionCategory {
            id
            name
         }
      }
      allFactories {
         id
         name
         productionCategories {
            id
            name
         }
      }
   }
`;

const ProductVariants = () => {
   const { loading, error, data } = useQuery(FETCH_PAGE_DATA);

   const listContainer = useRef();
   const [listHeight, setListHeight] = useState(0);
   const location = useLocation();

   const searchingOdooId = queryString.parse(location.search).odoo_id;
   const [searchText, setSearchText] = useState(
      searchingOdooId ? searchingOdooId : ''
   );
   const [filterSupplier, setFilterSupplier] = useState(
      localStorage.getItem('products:filterSupplier') || 'all'
   );
   const [filterProductionCategory, setFilterProductionCategory] = useState(
      localStorage.getItem('products:filterProductionCategory') || 'all'
   );

   useEffect(() => {
      if (filterSupplier)
         localStorage.setItem('products:filterSupplier', filterSupplier);
   }, [filterSupplier]);

   useEffect(() => {
      if (filterProductionCategory)
         localStorage.setItem(
            'products:filterProductionCategory',
            filterProductionCategory
         );
   }, [filterProductionCategory]);

   useEffect(() => {
      if (listContainer.current)
         setListHeight(listContainer.current.clientHeight);
   }, [loading, error, data]);

   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;

   const productsToShow = data.allProducts
      .filter((i) => {
         if (filterSupplier === 'with-supplier')
            return i.preferedSupplier !== null;
         return true;
      })
      .filter((i) => {
         if (filterProductionCategory === 'with-production-category')
            return i.productionCategory !== null;
         if (filterProductionCategory === 'without-production-category')
            return i.productionCategory === null;
         return true;
      })
      .filter(
         (i) =>
            i.archivedAt === null &&
            searchByProp(i, ['number', 'name', 'odooId'], searchText)
      );

   function render({ data, index, style }) {
      const product = data.productsToShow[index];

      return (
         <div
            key={product.id}
            className="border-b border-gray-200 dark:border-gray-700 flex items-center px-6"
            style={style}
         >
            <ProductRow product={product} allFactories={data.allFactories} />
         </div>
      );
   }

   const allFactories = data.allFactories
      .filter((i) => i.productionCategories.length > 0)
      .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));

   const unsetProductionCategoryComponentsCount = productsToShow.filter(
      (i) => !i.productionCategory
   ).length;

   return (
      <div className="p-6 flex-1 overflow-auto flex flex-col">
         <div className="flex items-center justify-between space-x-8">
            <div className="flex-1">
               <SearchBar
                  className="p-2 flex-1"
                  value={searchText}
                  onChange={setSearchText}
                  placeholder="Search produc name, item number"
               />
            </div>

            <div className="flex space-x-4 items-center">
               <Select
                  value={filterSupplier}
                  onChange={(e) => setFilterSupplier(e.target.value)}
               >
                  <option value="all">All</option>
                  <option value="with-supplier">Only with Suppliers</option>
               </Select>

               <Select
                  value={filterProductionCategory}
                  onChange={(e) => setFilterProductionCategory(e.target.value)}
               >
                  <option value="all">All</option>
                  <option value="with-production-category">
                     Only with Production Category
                  </option>
                  <option value="without-production-category">
                     Only without Production Category
                  </option>
               </Select>

               {unsetProductionCategoryComponentsCount ? (
                  <div className="bg-red-600 text-xs text-white px-4 py-1 rounded-full">
                     {unsetProductionCategoryComponentsCount}
                  </div>
               ) : null}
            </div>
         </div>
         <div
            className="bg-white dark:bg-gray-800 rounded-xl flex-1 overflow-auto mt-4"
            ref={listContainer}
         >
            <FixedSizeList
               height={listHeight}
               itemSize={44}
               width="100%"
               itemData={{
                  productsToShow,
                  allFactories,
               }}
               itemCount={productsToShow.length}
            >
               {render}
            </FixedSizeList>
         </div>
      </div>
   );
};

export default ProductVariants;
