import React, { createContext } from 'react';
import { Route, Routes, Navigate, Outlet } from 'react-router-dom';
import Page from 'components/Page';
import { Button, NavigationLink } from 'components/base';
import DateRangePicker from '../DateRangePicker';
import useDateRange from 'hooks/useDateRange';
import OverView from './overview';
import ProductView from './ProductView';
import OHS from './orderHandlingSystem';

const BizAnalyticsIndex = () => (
   <Routes>
      <Route element={<BizAnalyticsContainer />}>
         <Route path="overview" element={<OverView />} />
         <Route path="ohs" element={<OHS />} />
         <Route path="products" element={<ProductView />} />
         <Route path="*" element={<Navigate to="overview" />} />
      </Route>
   </Routes>
);

export const BizAnalyticsContext = createContext({});

function BizAnalyticsContainer() {
   const [{ start, end }, { setStart, setEnd }] = useDateRange(12);
   return (
      <Page
         leftButtons={
            <div className="flex space-x-4">
               <NavigationLink to="/analytics/biz/overview" title="Overview" />
               <NavigationLink to="/analytics/biz/ohs" title="OHS" />
               <NavigationLink to="/analytics/biz/products" title="Products" />
            </div>
         }
      >
         <div className="m-4 lg:m-6">
            <DateRangePicker
               start={start}
               end={end}
               setStart={setStart}
               setEnd={setEnd}
            />
            <BizAnalyticsContext.Provider
               value={{ start, end, setStart, setEnd }}
            >
               <Outlet />
            </BizAnalyticsContext.Provider>
         </div>
      </Page>
   );
}

export default BizAnalyticsIndex;
