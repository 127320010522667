import { useContext } from "react";
import { calcSuitablePrice, childCost } from "../utils";
import { ProductCostContext } from "./Detail";
import ComponentTitle from "../ComponentTitle";
import PriceWithCurrency from "../PriceWithCurrency";
import moment from "moment";
import { CurrencyContext } from "CurrencyProvider";

const ChildProductRow = ({ child, calcQty, itemsPerSet, showMore }) => {
  const { showProductCosts } = useContext(ProductCostContext);
  const { latestExchangeRate } = useContext(CurrencyContext);
  const prices = child.product.prices.filter((i) => i.archivedAt === null);
  const hasPrices = prices.filter((i) => i.price > 0).length > 0;
  const rowSpan = hasPrices ? prices.length + 1 : 2;
  const sets = calcQty / itemsPerSet;
  const itemsQty = sets * child.qty;
  const suitablePrice = calcSuitablePrice(child.product, itemsQty);

  return (
    <>
      <tr>
        <td className="border px-4" rowSpan={rowSpan}>
          <div className="flex items-center">
            <ComponentTitle product={child.product} onClick={() => showProductCosts(child.product)} />
            <div className="mx-2 whitespace-nowrap">&times; {child.qty}</div>
          </div>
          {showMore ? (
            <div className="whitespace-nowrap mt-1 text-teal-600">
              <div>
                {sets.toFixed(1)} sets, {itemsQty.toFixed(1)} items. {childCost(child, sets, latestExchangeRate.supplierUsdToRmbRate).toFixed(3)}
              </div>
            </div>
          ) : null}
        </td>
        <td className="border px-4 text-right" rowSpan={rowSpan}>
          {child.product.moq > 0 ? child.product.moq : "-"}
        </td>
      </tr>
      {prices.length > 0 ? (
        prices.map((price, priceIndex) => {
          const using = suitablePrice && suitablePrice.id === price.id;
          return (
            <tr key={priceIndex} className={`${using ? "" : "opacity-30"}`}>
              <td className={`border px-4 text-center text-xs`}>{price.factory ? price.factory.name : "-"}</td>
              <td className="border px-4 text-right">{price.minQty}</td>
              <td className="border px-4 text-right whitespace-nowrap">
                <PriceWithCurrency price={price} />
              </td>
              <td className="border px-4 text-right">{moment(price.supplierUpdatedAt).format("YYYY-MM-DD")}</td>
            </tr>
          );
        })
      ) : (
        <tr>
          <td colSpan={4} className="border px-4 text-right">
            No Cost.
          </td>
        </tr>
      )}
    </>
  );
};

export default ChildProductRow;
