const { useQuery, gql } = require('@apollo/client');

const FETCH_ALL_CUSTOMERS = gql`
   query FETCH_ALL_CUSTOMERS {
      customers {
         id
         name
         odooPartnerId
         user {
            id
            email
         }
         areas {
            id
            name
         }
         shipments {
            id
         }
      }
   }
`;

export const useAllCustomers = () => {
   const { loading, error, data } = useQuery(FETCH_ALL_CUSTOMERS);
   const customers = data ? data.customers : null;
   return { loading, error, customers };
};
