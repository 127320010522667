import { gql, useQuery } from '@apollo/client';
import { createContext } from 'react';

export const OdooContext = createContext({});

const FETCH_ODOO_URL = gql`
   query FETCH_ODOO_URL {
      odooUrl
   }
`;

const OdooProvider = ({ children }) => {
   const fetchOdooUrl = useQuery(FETCH_ODOO_URL);
   const odooUrl = fetchOdooUrl.data ? fetchOdooUrl.data.odooUrl : null;

   return (
      <OdooContext.Provider value={{ odooUrl }}>
         {children}
      </OdooContext.Provider>
   );
};

export default OdooProvider;
