const reducer = (state, action) => {
  const payload = action.payload;
  switch (action.type) {
    case "selectCustomer":
      return { ...state, selectedCustomer: payload };
    case "toggleSelectedProduct":
      return {
        ...state,
        selectedProducts: state.selectedProducts.includes(payload) ? state.selectedProducts.filter((i) => i !== payload) : [...state.selectedProducts, payload],
      };
    case "selectProductInRow":
      return {
        ...state,
        invoices: state.invoices.map((so, soIndex) => {
          if (soIndex !== payload.soIndex) return so;
          return {
            ...so,
            orderLines: so.orderLines.map((row, rowIndex) => {
              if (rowIndex !== payload.rowIndex) return row;
              if (row.selectedProduct && row.selectedProduct.id === payload.product.id) {
                if (payload.force) return row;
                return { ...row, selectedProduct: null };
              }
              return {
                ...row,
                selectedProduct: payload.product,
              };
            }),
          };
        }),
      };
    case "updateInvoiceNumber":
      return {
        ...state,
        invoices: state.invoices.map((so, soIndex) => {
          if (soIndex !== payload.soIndex) return so;
          return {
            ...so,
            number: payload.number,
          };
        }),
      };
    case "addInvoicePo":
      return {
        ...state,
        invoices: state.invoices.map((so, soIndex) => {
          if (soIndex !== payload.soIndex) return so;
          return {
            ...so,
            pos: [...so.pos, payload.po],
          };
        }),
      };
    case "updateInvoicePo":
      return {
        ...state,
        invoices: state.invoices.map((so, soIndex) => {
          if (soIndex !== payload.soIndex) return so;
          const updatedPos = so.pos.map((prevPo, prevPoIndex) => {
            if (prevPoIndex !== payload.poIndex) return prevPo;
            return payload.po;
          });
          let updatedSo = {
            ...so,
            pos: updatedPos,
            orderLines: so.orderLines.map((row) => {
              if (updatedPos.length === 1) return { ...row, po: payload.po };
              if (row.po !== payload.lastPo) return row;
              return { ...row, po: payload.po };
            }),
          };
          return updatedSo;
        }),
      };
    case "removeInvoicePo":
      return {
        ...state,
        invoices: state.invoices.map((so, soIndex) => {
          if (soIndex !== payload.soIndex) return so;
          return {
            ...so,
            pos: so.pos.filter((_, prevPoIndex) => prevPoIndex !== payload.poIndex),
            orderLines: so.orderLines.map((row) => {
              if (row.po !== payload.po) return row;
              return { ...row, po: null };
            }),
          };
        }),
      };
    case "selectRowPo":
      return {
        ...state,
        invoices: state.invoices.map((so, soIndex) => {
          if (soIndex !== payload.soIndex) return so;
          return {
            ...so,
            orderLines: so.orderLines.map((row, rowIndex) => {
              if (rowIndex !== payload.rowIndex) return row;
              return {
                ...row,
                po: row.po === null ? payload.po : row.po === payload.po ? null : payload.po,
              };
            }),
          };
        }),
      };
    case "updateRowQty":
      return {
        ...state,
        invoices: state.invoices.map((so, soIndex) => {
          if (soIndex !== payload.soIndex) return so;
          return {
            ...so,
            orderLines: so.orderLines.map((row, rowIndex) => {
              if (rowIndex !== payload.rowIndex) return row;
              return {
                ...row,
                qty: payload.qty,
              };
            }),
          };
        }),
      };
    default:
      return state;
  }
};

export default reducer;
