import React from 'react';
import { useMutation, gql } from '@apollo/client';
import { Button } from '../../../components/base';
import { Alert } from '../../../components/Toast';

const REQUEST_TO_SET_ODOO_ID_FOR_PRODUCT = gql`
   mutation REQUEST_TO_SET_ODOO_ID_FOR_PRODUCT(
      $odooProductId: Int!
      $odooProductName: String!
      $soInfo: String
   ) {
      requestToSetOdooIdForProduct(
         odooProductId: $odooProductId
         odooProductName: $odooProductName
         soInfo: $soInfo
      ) {
         message
      }
   }
`;

const MissingOdooIdRow = ({ row, soInfo }) => {
   const [requestToSetOdooIdForProduct, { loading }] = useMutation(
      REQUEST_TO_SET_ODOO_ID_FOR_PRODUCT,
      {
         variables: {
            odooProductId: row.productId,
            odooProductName: row.name,
            soInfo: soInfo,
         },
         onCompleted: () =>
            Alert('success', '我们已经收到请求，会尽快处理，谢谢！'),
         onError: (error) => Alert('error', error.message),
      }
   );

   return (
      <div className="rounded-2xl p-4 mt-4 bg-gray-100 dark:bg-gray-900 opacity-50 flex items-center">
         <div className="mr-4 flex-1 lg:flex items-center">
            <div className="text-xs text-gray-600">
               <p>
                  #{row.productId} <b>{row.name}</b>
               </p>
               <p>This is not ready in our system yet. Please contact us.</p>
               <p>该产品资料并未完善，暂时未能创建质检报告。</p>
               <div className="mt-2">
                  <Button
                     title="请求完善数据"
                     disabled={loading}
                     loading={loading}
                     onClick={requestToSetOdooIdForProduct}
                  ></Button>
               </div>
            </div>
         </div>
         <div className="lg:mt-0 lg:ml-4 text-gray-500">✕ {row.qty}</div>
      </div>
   );
};

export default MissingOdooIdRow;
