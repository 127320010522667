import gql from 'graphql-tag';

export const USER_FRAGMENT = gql`
   fragment user on UserType {
      id
      username
      email
      firstName
      lastName
      isActive
   }
`;

export const USER_FRAGMENT_WITH_CUSTOMER_DATA = gql`
   fragment userWithCustomerData on UserType {
      ...user
      customers {
         id
         name
      }
      subAccounts {
         id
         customer {
            id
            name
         }
      }
   }
   ${USER_FRAGMENT}
`;

export const FETCH_ALL_USRS = gql`
   query FETCH_ALL_USERS {
      allUsers {
         ...userWithCustomerData
      }
   }
   ${USER_FRAGMENT_WITH_CUSTOMER_DATA}
`;

export const SAVE_USER = gql`
   mutation SAVE_USER($userInput: UserInput!) {
      saveUser(userInput: $userInput) {
         user {
            ...user
         }
      }
   }
   ${USER_FRAGMENT}
`;
