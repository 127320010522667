import { useMutation, useQuery } from "@apollo/client";
import { NavigationBar } from "components/base";
import Errors from "components/Errors";
import Spinner, { InlineSpinner } from "components/Spinner";
import { useParams, useSearchParams } from "react-router-dom";
import { DatePicker, Select } from "components/Form";
import { createContext, useEffect, useState } from "react";
import { FETCH_ASSESSMENT, SAVE_ASSESSMENT } from "./graphql";
import CharlesButton from "components/charles/base";
import { Alert } from "components/Toast";
import { parseError } from "apollo";
import moment from "moment";
import AssessmentStatusChain from "./AssessmentStatus";
import { useModals } from "ModalProvider";
import CategoryQuestions from "./CategoryQuestions";
import ConfirmAssessmentQuestionView from "./ConfirmAssessmentQuestionView";
import AssessmentSummaryView from "./AssessmentSummaryView";
import AssessmentStatusNoteView from "./AssessmentStatusNoteView";
import AssessmentBaseInfoView from "./AssessmentBaseInfoView";
import AssessmentExtraInfoView from "./AssessmentExtraInfoView";
import { GENERATE_DOCUMENT } from "graphql/mutations";
import http from "utils/http";

export const AssessmentQuestionContext = createContext({});

const AssessmentDetail = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const { loading, error, data } = useQuery(FETCH_ASSESSMENT, { variables: { id } });
  const [generateDocument] = useMutation(GENERATE_DOCUMENT, {
    onError: (error) => Alert("error", error.message),
  });

  const assessmentQuestionId = searchParams.get("assessmentQuestionId");

  const [saveAssessment, saveAssessmentRes] = useMutation(SAVE_ASSESSMENT, {
    onCompleted() {
      Alert("success", "Assessment saved.");
    },
    onError(error) {
      Alert("error", parseError(error));
    },
  });
  const [downloading, setDownloading] = useState(false);

  useEffect(() => {
    if (data && assessmentQuestionId) {
      setTimeout(() => {
        const element = document.getElementById(assessmentQuestionId);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 500);
    }
  }, [data, assessmentQuestionId]);

  const [filter, setFilter] = useState("all");

  const confirmModal = useModals();

  function confirmAssessmentQuestion(assessmentQuestion) {
    confirmModal.present({
      title: "Confirm",
      center: true,
      maxWidth: "max-w-3xl",
      children: <ConfirmAssessmentQuestionView assessmentQuestion={assessmentQuestion} hide={confirmModal.hide} />,
    });
  }

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const questions = data.assessment.questions.edges.map(({ node }) => node);
  function getAllCategoryQuestions(category) {
    let result = [];
    if (category.children) {
      category.children.edges.forEach(({ node }) => {
        result = result.concat(getAllCategoryQuestions(node));
      });
    }

    result = result.concat(
      questions.filter((question) => {
        return question.question.category.id === category.id;
      })
    );

    return result;
  }

  function buildCategoriesTree(category) {
    let result = [];
    if (category.children) {
      category.children.edges.forEach(({ node }) => {
        result.push({
          ...node,
          children: buildCategoriesTree(node),
          questions: getAllCategoryQuestions(node).filter((i) => {
            if (filter === "all") return true;
            if (filter === "score_a") return i.score === "A";
            if (filter === "score_b") return i.score === "B";
            if (filter === "score_c") return i.score === "C";
            if (filter === "score_d") return i.score === "D";
            if (filter === "score_no") return i.score === null;
            if (filter === "required_rectification") return i.requiredRectification;
            if (filter === "received_rectification") return i.rectifications.edges.length > 0;
            if (filter === "follow_up_next_time") return i.followUpNextTime;
            if (filter === "check_docs_required") return i.question.checkDocsRequired;
            if (filter === "check_environment_required") return i.question.checkEnvironmentRequired;
            if (filter === "check_with_workers_required") return i.question.checkWithWorkersRequired;
            if (filter === "check_with_owner_required") return i.question.checkWithOwnerRequired;
            return false;
          }),
        });
      });
    }
    return result;
  }

  const categories = buildCategoriesTree(data.assessment.category);

  async function downloadAssessment() {
    try {
      setDownloading(true);
      const name = `${data.assessment.supplier.name} - ${data.assessment.category.name}`;
      const res = await generateDocument({
        variables: {
          name,
          docType: "assessment",
          data: JSON.stringify({ id: data.assessment.id }),
        },
      });
      http
        .get(`${process.env.REACT_APP_SERVER_BASE_URL}generated-document/${res.data.generateDocument.document.id}/?pdf=1`, {
          responseType: "blob",
          withCredentials: true,
        })
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", name + ".pdf");
          document.body.appendChild(link);
          link.click();
          setDownloading(false);
        });
    } catch (error) {
      Alert("error", error.message);
      setDownloading(false);
    }
  }

  const baseInfo = JSON.parse(data.assessment.baseInfo);

  return (
    <AssessmentQuestionContext.Provider value={{ confirmAssessmentQuestion, assessmentStatus: data.assessment.status }}>
      <NavigationBar
        title={`${data.assessment.supplier.name}${baseInfo ? `(${baseInfo.name})` : ""} - ${data.assessment.category.name}`}
        rightButtons={
          <div className="flex space-x-6">
            <CharlesButton loading={downloading} onClick={downloadAssessment}>
              Download
            </CharlesButton>

            <a href={`${process.env.REACT_APP_SERVER_ADMIN_URL}assessment/assessment/${atob(id).split(":")[1]}`} target="_blank" rel="noreferrer">
              Admin
            </a>

            <CharlesButton onClick={saveAssessment} loading={saveAssessmentRes.loading}>
              Save
            </CharlesButton>
          </div>
        }
      />

      <div className="m-6">
        <AssessmentStatusChain status={data.assessment.status} />
      </div>

      <div className="m-6 grid grid-cols-12 gap-6 leading-relaxed text-sm">
        <div className="col-span-full 2xl:col-span-9 space-y-6">
          <AssessmentBaseInfoView data={data.assessment.baseInfo} />
          <div className="card px-6 py-4">
            <div className="border-b dark:border-gray-700 pb-4">
              <div className="flex justify-between items-center">
                <h3>Questions</h3>
                {data.assessment.status === "DRAFT" || data.assessment.status === "SELF_ASSESSMENT" ? null : (
                  <div className="flex justify-end">
                    <div className="space-x-3">
                      <label>Filter: </label>
                      <Select
                        value={filter}
                        onChange={(e) => {
                          setFilter(e.target.value);
                        }}
                      >
                        <option value="all">All</option>
                        <optgroup label="Score">
                          <option value="score_a">Score A</option>
                          <option value="score_b">Score B</option>
                          <option value="score_c">Score C</option>
                          <option value="score_d">Score D</option>
                          <option value="score_no">No Score</option>
                        </optgroup>

                        <optgroup label="Rectification">
                          <option value="required_rectification">Required Rectification</option>
                          <option value="received_rectification">Received Rectification</option>
                        </optgroup>

                        <option value="check_docs_required">Check Docs Required</option>
                        <option value="check_environment_required">Check Environment Required</option>
                        <option value="check_with_workers_required">Check With Workers Required</option>
                        <option value="check_with_owner_required">Check With Owner Required</option>
                        <option value="follow_up_next_time">Follow Up Next Time</option>
                      </Select>
                    </div>
                  </div>
                )}
              </div>

              <div className="opacity-70 mt-1 text-xs">
                We can edit the question answers directly here for convience. But these questions are supposed to be answered by suppliers.
              </div>
            </div>

            <div>
              {categories.map((category, index) => (
                <div key={category.id} className="py-6 border-gray-100 dark:border-gray-700 ">
                  <h2>
                    {index + 1}. {category.name}
                  </h2>
                  {category.requiredSupplier ? null : (
                    <div className="opacity-70">This section does not required supplier to fill in, we could just write the content by ourselves.</div>
                  )}
                  <div className="mt-2">{category.description}</div>
                  <CategoryQuestions number={index + 1} category={category} requiredSupplier={category.requiredSupplier} />
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="col-span-full 2xl:col-span-3 space-y-6">
          <AssessmentStatusNoteView id={id} status={data.assessment.status} categories={categories} />

          <div className="card px-6 py-4 relative">
            {saveAssessmentRes.loading && (
              <div className=" absolute top-4 right-4">
                <InlineSpinner size={16} text={null} />
              </div>
            )}
            <h5>Due Date</h5>
            <div className="mt-2">
              <DatePicker
                value={data.assessment.dueDate}
                onDayChange={(day) => {
                  saveAssessment({ variables: { id, dueDate: moment(day).format("YYYY-MM-DD") } });
                }}
                disabled={saveAssessment.loading}
              />
            </div>
            <div className="mt-2 opacity-70 text-xs">Supplier is expected to complete this self assessment in time.</div>
          </div>

          <AssessmentExtraInfoView assessment={data.assessment} />

          <div className="card px-6 py-4 space-y-2">
            <h5>Summary</h5>
            <AssessmentSummaryView assessment={data.assessment} />
          </div>
        </div>
      </div>
    </AssessmentQuestionContext.Provider>
  );
};

export default AssessmentDetail;
