import { gql, useQuery } from '@apollo/client';
import { NavigationBar } from 'components/base';
import Errors from 'components/Errors';
import Spinner from 'components/Spinner';
import { CharlesButton } from 'components/charles/base';
import { useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { useModals } from 'ModalProvider';
import ExportStockView from './ExportStockView';
import { MultiGrid, AutoSizer } from 'react-virtualized';

const FETCH_ODOO_LOCATION_STOCK_HISTORY = gql`
   query FETCH_ODOO_LOCATION_STOCK_HISTORY($id: Int!) {
      odooLocationStockHistory(id: $id)
   }
`;

const LocationStockHistory = () => {
   const { id } = useParams();
   const location = useLocation();
   const q = queryString.parse(location.search);
   const modal = useModals();

   const { loading, error, data } = useQuery(
      FETCH_ODOO_LOCATION_STOCK_HISTORY,
      { variables: { id } }
   );

   function exportExcel(data) {
      const res = JSON.parse(data.odooLocationStockHistory);
      modal.present({
         title: 'Export',
         children: (
            <ExportStockView
               warehouse={q.name}
               data={res}
               complete={modal.hide}
            />
         ),
      });
   }

   return (
      <div className="h-full flex flex-col">
         <NavigationBar
            backTo={`/stock/warehouses/${id}?name=${q.name}`}
            title={`${q.name} / History`}
            rightButtons={
               data ? (
                  <div>
                     <CharlesButton onClick={() => exportExcel(data)}>
                        Export Excel
                     </CharlesButton>
                  </div>
               ) : null
            }
         />

         <div className="relative flex-1 flex flex-col">
            <Content loading={loading} error={error} data={data} />
         </div>
      </div>
   );
};

const Content = ({ loading, error, data }) => {
   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;

   if (!data.odooLocationStockHistory)
      return <div className="p-6">This warehouse does not have any items.</div>;

   const res = JSON.parse(data.odooLocationStockHistory);

   function cellRenderer({ columnIndex, key, rowIndex, style }) {
      return (
         <div
            className={`flex items-center justify-center text-center px-2 border-b border-gray-100
            ${rowIndex === 0 ? 'text-xs' : ''}
            `}
            key={key}
            style={style}
         >
            {rowIndex === 0
               ? Object.keys(res[0])[columnIndex]
               : Object.values(res[rowIndex])[columnIndex]}
         </div>
      );
   }

   return (
      <div className="bg-white dark:bg-gray-800 overflow-auto flex-1">
         {res.length > 0 ? (
            <AutoSizer>
               {({ height, width }) => (
                  <MultiGrid
                     fixedColumnCount={1}
                     fixedRowCount={1}
                     cellRenderer={cellRenderer}
                     columnWidth={200}
                     columnCount={Object.keys(res[0]).length}
                     height={height}
                     rowHeight={40}
                     rowCount={res.length}
                     width={width}
                     hideTopRightGridScrollbar
                     hideBottomLeftGridScrollbar
                     enableFixedColumnScroll
                     enableFixedRowScroll
                  />
               )}
            </AutoSizer>
         ) : (
            <div>This location does not have any items.</div>
         )}
      </div>
   );
};

export default LocationStockHistory;
