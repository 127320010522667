import CharlesButton from 'components/charles/base';

const PreviewQBFileView = ({ lines, hide }) => {
   return (
      <div>
         <div className="-mx-2 text-sm">
            <table>
               <thead>
                  <tr>
                     <th>Product</th>
                     <th className="text-right">QTY</th>
                  </tr>
               </thead>
               <tbody>
                  {lines.map((line, index) => {
                     return (
                        <tr
                           key={index}
                           className="border-b dark:border-gray-700"
                        >
                           <td>
                              <span className=" text-red-600 font-semibold">
                                 {line.itemNumber}
                              </span>
                           </td>
                           <td className="text-right">{line.qty}</td>
                        </tr>
                     );
                  })}
               </tbody>
            </table>
         </div>

         <div className="mt-3">Please add them manually.</div>

         <div className="mt-8">
            <CharlesButton primary onClick={hide}>
               OK
            </CharlesButton>
         </div>
      </div>
   );
};

export default PreviewQBFileView;
