import React from "react";
import Icon from "./Icon.js";

function ShipmentDelay({ className = "", days, iconSize = 18 }) {
   return (
      <div className={`${className} text-pink-500 font-bold flex items-center`}>
         <Icon icon="time" size={iconSize} />
         <span className="ml-2">
            {days} Day{days > 1 ? "s" : ""} Delay
         </span>
      </div>
   );
}

export default ShipmentDelay;
