import { useQuery } from '@apollo/client';
import { FETCH_ODOO_PRODUCTS_MARGIN } from './graphql';
import Errors from 'components/Errors';
import Spinner from 'components/Spinner';
import { OdooContext } from 'OdooProvider';
import { useContext } from 'react';

const SingleCategoryView = ({ category, startDate, endDate, orderType }) => {
  const { odooUrl } = useContext(OdooContext);
  const { loading, error, data } = useQuery(FETCH_ODOO_PRODUCTS_MARGIN, {
    variables: {
      productIds: category.category_product_ids,
      startDate,
      endDate,
      orderType,
    },
  });

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const res = JSON.parse(data.odooProductsMargin).sort((a, b) =>
    a.product_name.localeCompare(b.product_name),
  );

  const totalQty = res.reduce((acc, item) => acc + item.product_uom_qty, 0);
  const totalCost = res.reduce((acc, item) => acc + item.cost_in_sek, 0);
  const totalSales = res.reduce((acc, item) => acc + item.sales_in_sek, 0);
  const totalMargin = totalSales - totalCost;
  const totalMarginPercent = totalSales > 0 ? totalMargin / totalSales : 0;

  return (
    <div className="text-sm -mx-2">
      <div>
        <table>
          <thead>
            <tr>
              <th>Product</th>
              <th>Order</th>
              <th className="text-right">Qty</th>
              <th className="text-right">Sales Price(SEK)</th>
              <th className="text-right">Cost(SEK)</th>
              <th className="text-right">Margin %</th>
            </tr>
          </thead>
          <tbody>
            {res.map((item) => (
              <tr key={item.id} className="border-y">
                <td>{item.product_name}</td>
                <td>
                  <a
                    href={`${odooUrl}/web#id=${item.order_id}&menu_id=408&cids=1&action=312&model=sale.order&view_type=form`}
                    target="_blank"
                  >
                    {item.order_name}
                  </a>
                </td>
                <td className="text-right">{item.product_uom_qty}</td>
                <td className="text-right">{item.sales_in_sek.toFixed(2)}</td>
                <td className="text-right">{item.cost_in_sek.toFixed(2)}</td>
                <td className="text-right">
                  {(item.margin_percent * 100).toFixed(2)}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr className=" font-semibold text-base">
              <td>Total</td>
              <td></td>
              <td className="text-right">{totalQty}</td>
              <td className="text-right">{totalSales.toFixed(2)}</td>
              <td className="text-right">{totalCost.toFixed(2)}</td>
              <td className="text-right">
                {(totalMarginPercent * 100).toFixed(2)}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

export default SingleCategoryView;
