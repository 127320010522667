import { useEffect, useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import { formatDate } from 'react-day-picker/moment';
import Spinner from 'components/Spinner';
import Errors from 'components/Errors';
import SearchBar from 'components/SearchBar';
import { searchByProp } from 'utils/search.js';
import odooIcon from 'assets/odoo-icon.svg';
import { IoMdCheckmarkCircle } from 'react-icons/io';
import { Button } from 'components/base';
import useDocumentGenerator from 'hooks/useDocumentGenerator';
import { Alert } from 'components/Toast';
import http from 'utils/http';
import OdooWarehouseSelector from 'components/OdooWarehouseSelector';
import { useModals } from 'ModalProvider';
import OdooOrderDetail from './Detail';

const FETCH_SO_FOR_UK = gql`
   query FETCH_SO_FOR_UK($warehouseId: Int!) {
      odooSoList: odooSoListSimple(
         warehouseId: $warehouseId
         orderDateAfter: "2021-01-01 00:00:00"
      ) {
         id
         name
         partnerName
         salesStage
         address
         dateOrder
         amountTotal
         currencyName
      }
   }
`;

const OdooInvoiceList = () => {
   const [warehouseId, setWarehouseId] = useState(3);
   const [searchText, setSearchText] = useState('');
   const [selectedRows, setSelectedRows] = useState([]);

   return (
      <div className="p-6 flex flex-col overflow-auto h-full">
         <div>
            <div className="flex space-x-8 items-center">
               <div className="flex-1">
                  <SearchBar
                     className="flex-1"
                     onChange={setSearchText}
                     placeholder="Search"
                     autoFocus
                  />
               </div>
               <div className="flex items-center space-x-3">
                  <label htmlFor="">Warehouse: </label>
                  <OdooWarehouseSelector
                     value={warehouseId}
                     onSelect={setWarehouseId}
                  />
               </div>
            </div>
         </div>

         <div className="relative flex-1 overflow-auto">
            <Orders
               warehouseId={warehouseId}
               searchText={searchText}
               selectedRows={selectedRows}
               setSelectedRows={setSelectedRows}
            />
         </div>
      </div>
   );
};

const Orders = ({ warehouseId, searchText, selectedRows, setSelectedRows }) => {
   const { loading, error, data } = useQuery(FETCH_SO_FOR_UK, {
      variables: { warehouseId },
   });
   const orderModal = useModals();
   const [generateDoc] = useDocumentGenerator();
   const [exporting, setExporting] = useState(false);
   const [exportingInter, setExportingInter] = useState(false);

   if (loading) return <Spinner text="Loading Orders from Odoo.." />;
   if (error) return <Errors error={error} />;

   const orders = data.odooSoList.filter((invoice) =>
      searchByProp(invoice, ['name', 'partnerName'], searchText)
   );

   const selectedRowIds = selectedRows.map((i) => i.id);
   const isRowSelected = (id) => selectedRowIds.includes(id);

   function updateExporting(invoiceType, isLoading) {
      if (invoiceType === 'Commercial Invoice') setExporting(isLoading);
      if (invoiceType === 'Intercompany Invoice') setExportingInter(isLoading);
   }

   function exportHandler(invoiceType) {
      updateExporting(invoiceType, true);
      generateDoc({
         variables: {
            name: 'orders',
            docType: 'odoo_invoices_for_alc_tax_report',
            data: JSON.stringify(
               { odooSoIds: selectedRowIds, invoiceType },
               '',
               4
            ),
         },
      })
         .then((res) => {
            return http.get(
               `${process.env.REACT_APP_SERVER_BASE_URL}generated-document/${res.data.generateDocument.document.id}/?pdf=1`,
               {
                  responseType: 'blob',
                  withCredentials: true,
                  timeout: 300 * 1000,
               }
            );
         })
         .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'orders.pdf');
            document.body.appendChild(link);
            link.click();
            updateExporting(invoiceType, false);
         })
         .catch((error) => {
            Alert('error', error.message);
            updateExporting(invoiceType, false);
         });
   }

   return (
      <div className="h-full overflow-auto flex flex-col">
         <div className="flex space-x-6 mt-4">
            {selectedRows.length > 0 ? (
               <Button title="Deselect" onClick={() => setSelectedRows([])} />
            ) : null}

            <Button
               title="Select All"
               onClick={() => setSelectedRows(orders)}
            />

            {selectedRows.length > 0 ? (
               <>
                  <Button
                     title={`Export Commercial Invoices${
                        selectedRows.length > 0
                           ? `(${selectedRows.length})`
                           : ''
                     }`}
                     disabled={exporting || selectedRows.length === 0}
                     onClick={() => exportHandler('Commercial Invoice')}
                     loading={exporting}
                  />

                  <Button
                     title={`Export Intercompnay Invoices${
                        selectedRows.length > 0
                           ? `(${selectedRows.length})`
                           : ''
                     }`}
                     disabled={exportingInter || selectedRows.length === 0}
                     onClick={() => exportHandler('Intercompany Invoice')}
                     loading={exportingInter}
                  />
               </>
            ) : null}
         </div>

         <div className="bg-white dark:bg-gray-800 rounded-xl mt-4 p-4 shadow-lg relative flex-1 overflow-auto">
            {orders.map((so) => (
               <div key={so.id} className="flex items-center flex-1">
                  <div
                     className={`text-xl mr-4 cursor-pointer ${
                        isRowSelected(so.id)
                           ? 'text-green-600 hover:text-green-400 active:text-gray-300'
                           : 'text-gray-300 hover:text-green-500 active:text-green-600'
                     }`}
                     onClick={() =>
                        setSelectedRows((prev) =>
                           selectedRows.find((i) => i.id === so.id)
                              ? prev.filter((i) => i.id !== so.id)
                              : [...prev, so]
                        )
                     }
                  >
                     <IoMdCheckmarkCircle />
                  </div>

                  <div
                     className="flex-1 flex px-4 py-2 border-b border-gray-100 dark:border-gray-700 dark:hover:bg-gray-700 hover:bg-gray-100 active:bg-gray-200 cursor-pointer items-center select-none"
                     onClick={() =>
                        orderModal.present({
                           title: so.name,
                           maxWidth: 'max-w-7xl',
                           children: <OdooOrderDetail id={so.id} />,
                        })
                     }
                  >
                     <div>
                        <img
                           className="mr-2"
                           style={{ height: 16 }}
                           src={odooIcon}
                           alt="odoo product"
                        />
                     </div>
                     <div className="mr-6">{so.name}</div>
                     <div className="flex-1">{so.partnerName}</div>
                     <div className="text-right w-1/5">
                        {so.currencyName} {so.amountTotal}
                     </div>
                     <div className="text-right w-32">
                        {formatDate(new Date(so.dateOrder), 'YYYY-MM-DD')}
                     </div>
                  </div>
               </div>
            ))}
         </div>
      </div>
   );
};

export default OdooInvoiceList;
