import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import queryString from "query-string";
import { useMutation, useQuery, gql } from "@apollo/client";
import { Input } from "components/Form";
import { Button } from "components/base";
import WabobaLogo from "assets/waboba-logo.svg";
import { Alert } from "components/Toast";
import version from "utils/version";
import { AppContext } from "App";
import http from "utils/http";
import { GetAssertReq, publicKeyCredentialToJSON } from "./auth";
import CharlesButton from "components/charles/base";
import { LiaKeySolid } from "react-icons/lia";

const FETCH_RANDOM_IMAGE = gql`
  query FETCH_RANDOM_IMAGE {
    randomPromoImage {
      id
      url
    }
  }
`;

const LOGIN = gql`
  mutation LOGIN($email: String!, $password: String!, $device: String) {
    secureLogin(email: $email, password: $password, device: $device) {
      userId
      enableMfa
      loginDirectly
      me {
        id
        email
        firstName
        lastName
        isSuperuser
        isStaff
        groups {
          id
          name
        }
      }
    }
  }
`;

const VERFY_MFA_CODE = gql`
  mutation VERFY_MFA_CODE($userId: ID!, $code: String!) {
    verifyMfaCode(userId: $userId, code: $code) {
      user {
        id
        email
        firstName
        lastName
        isSuperuser
        isStaff
        groups {
          id
          name
        }
      }
      device
    }
  }
`;

const Login = () => {
  const { data } = useQuery(FETCH_RANDOM_IMAGE);
  const { user, setUser } = useContext(AppContext);
  const [email, setEmail] = useState(process.env.REACT_APP_TEST_LOGIN_EMAIL);
  const [password, setPassword] = useState(process.env.REACT_APP_TEST_LOGIN_PASSWORD);
  const [userId, setUserId] = useState(null);
  const [enableMfa, setEnableMfa] = useState(false);
  const [showLoginInWithPasskeys, setShowLoginInWithPasskeys] = useState(false);
  const [passkeyLoading, setPasskeyLoading] = useState(false);
  const [code, setCode] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const [secureLogin, secureLoginRes] = useMutation(LOGIN, {
    variables: { email, password, device: localStorage.getItem("device") },
    onCompleted: (data) => {
      if (data.secureLogin.loginDirectly) {
        setUser(data.secureLogin.me);
      } else if (data.secureLogin.enableMfa) {
        setUserId(data.secureLogin.userId);
        setEnableMfa(data.secureLogin.enableMfa);
      }
    },
    onError: (error) => {
      var message;
      if (error.graphQLErrors) {
        message = error.graphQLErrors.map((err) => err.message).join(",");
      }
      if (!message) message = error.message;
      Alert("error", message);
    },
  });

  async function signInWithPassskeys() {
    setPasskeyLoading(true);
    try {
      let res = await http.get("passkeys/auth/begin");
      const abortController = new AbortController();
      let options = GetAssertReq(res.data);
      options.signal = abortController.signal;
      const credential = await navigator.credentials.get(options);
      const passkeys = JSON.stringify(publicKeyCredentialToJSON(credential));
      let data = new FormData();
      data.append("passkeys", passkeys);
      data.append("username", "");
      data.append("password", "");
      res = await http.post("login-with-passkeys", data, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (res.data.success) {
        const redirectTo = queryString.parse(location.search).redirectTo || "/";
        open(redirectTo, "_self");
      }
    } catch (error) {
      console.dir(error);
      setPasskeyLoading(false);
      if (error.name === "NotAllowedError") return;

      Alert("error", error.message);
    }
  }

  async function checkIfPasskeyIsAvailable() {
    if (window.PublicKeyCredential && PublicKeyCredential.isConditionalMediationAvailable) {
      const isCMA = await PublicKeyCredential.isConditionalMediationAvailable();
      if (isCMA) setShowLoginInWithPasskeys(true);
    }
  }

  useEffect(() => {
    checkIfPasskeyIsAvailable();
  }, []);

  useEffect(() => {
    if (user) {
      let redirectTo = queryString.parse(location.search).redirectTo || "/";
      navigate(redirectTo);
    }
  }, [user]);

  const [verifyMfaCode, verifyMfaCodeRes] = useMutation(VERFY_MFA_CODE, {
    variables: { userId, code },
    onCompleted: (data) => {
      setUser(data.verifyMfaCode.user);
      let { from } = location.state || { from: { pathname: "/" } };
      navigate(from);
      localStorage.setItem("device", data.verifyMfaCode.device);
    },
    onError: (error) => Alert("error", error.message),
  });

  function submit(e) {
    e.preventDefault();
    if (enableMfa) {
      verifyMfaCode();
    } else {
      secureLogin();
    }
  }

  return (
    <div
      className="flex items-center justify-center bg-center bg-cover h-screen"
      style={
        data && data.randomPromoImage
          ? {
              backgroundImage: `url(${
                process.env.NODE_ENV == "development"
                  ? "https://cdn.waboba.com/assets/promo_images/a79cdf555e194db9a8dbbfe5dd6bc64e-Jeff_Nass_Productions_WingmanPro_Web_Res-66.jpg"
                  : data.randomPromoImage.url
              })`,
            }
          : null
      }
    >
      <div className="p-12 md:rounded-2xl shadow-lg backdrop-filter backdrop-blur-xl bg-gray-100 dark:bg-gray-900 bg-opacity-50 h-full flex flex-col items-center justify-center md:h-auto w-full md:max-w-md">
        <div className="w-full max-w-xs">
          <div className="flex justify-center">
            <img src={WabobaLogo} alt="Waboba" width={150} />
          </div>
          <div className="text-center mt-4 text-lg text-gray-600">Sign In to WIS</div>
          <div className="my-2 absolute bottom-0 right-0 p-4 text-xs opacity-50">v{version}</div>
          <form className="mt-6 max-w-" onSubmit={submit}>
            <div>
              <Input
                className="text-center w-full"
                required
                type="email"
                autoComplete="email webauthn"
                value={email}
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="mt-4">
              <Input
                className="text-center w-full"
                required
                type="password"
                value={password}
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            {enableMfa ? (
              <div>
                <div className="mt-4">
                  <Input
                    className="text-center w-full"
                    value={code}
                    autoFocus={true}
                    placeholder="Authentication Code"
                    onChange={(e) => setCode(e.target.value)}
                  />
                </div>
                <div className="mt-4 flex justify-center">
                  <Button
                    bold={true}
                    size="xl"
                    title={verifyMfaCodeRes.loading ? "Signing in..." : "Sign In"}
                    disabled={verifyMfaCodeRes.loading}
                    type="submit"
                  />
                </div>
              </div>
            ) : (
              <div className="mt-8 flex justify-center">
                <Button bold={true} size="lg" title={secureLoginRes.loading ? "Signing in..." : "Sign in"} disabled={secureLoginRes.loading} type="submit" />
              </div>
            )}
          </form>

          {showLoginInWithPasskeys ? (
            <div className="flex justify-center border-t border-opacity-50 my-6 pt-6">
              <CharlesButton primary onClick={signInWithPassskeys} loading={passkeyLoading} icon={<LiaKeySolid size={18} />}>
                Sign In with Passkeys
              </CharlesButton>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Login;
