import { useContext } from "react";
import { ProductListContext } from "./Form";
import CharlesButton from "components/charles/base";
import InputSelect from "components/InputSelect";
import { Input } from "components/Form";
import { BsTrash } from "react-icons/bs";

const MaterialsView = () => {
  const { data, setData, allMaterials } = useContext(ProductListContext);

  function addMaterial() {
    setData((prev) => ({ ...prev, materials: [...prev.materials, { name: "", weight: 0 }] }));
  }

  function onChangeMaterial(index, field, value) {
    setData((prev) => ({ ...prev, materials: prev.materials.map((m, mIndex) => (mIndex === index ? { ...m, [field]: value } : m)) }));
  }

  function removeMaterial(index) {
    setData((prev) => ({ ...prev, materials: prev.materials.filter((_, mIndex) => mIndex !== index) }));
  }

  return (
    <div className="card pt-4">
      <div className="-mx-2">
        <table>
          <thead>
            <tr>
              <th>
                <h5>Materials</h5>
              </th>
              <th className="text-right">Weight(g)</th>
              <th className="text-right">Percentage(item weight)</th>
            </tr>
          </thead>
          <tbody>
            {data.materials.map((material, index) => (
              <tr key={index}>
                <td>
                  <div className="flex space-x-2 items-center">
                    <CharlesButton danger onClick={() => removeMaterial(index)}>
                      <BsTrash />
                    </CharlesButton>
                    <div className="flex-1">
                      <InputSelect
                        options={allMaterials}
                        onChange={(value) => onChangeMaterial(index, "name", value)}
                        onSelect={(material) => onChangeMaterial(index, "name", material.name)}
                        value={material.name}
                      />
                    </div>
                  </div>
                </td>
                <td>
                  <div className="flex justify-end">
                    <Input className="text-right" value={material.weight} onChange={(e) => onChangeMaterial(index, "weight", e.target.value)} />
                  </div>
                </td>
                <td className="text-right">{data.weight > 0 ? ((material.weight * 100) / data.weight).toFixed(2) : 0}%</td>
              </tr>
            ))}
          </tbody>
          {data.materials.length > 1 ? (
            <tfoot>
              <tr className="font-semibold text-base">
                <td>Total</td>
                <td className="text-right">{data.materials.reduce((acc, m) => acc + Number(m.weight), 0).toFixed(2)}</td>
                <td className="text-right">{data.weight > 0 ? data.materials.reduce((acc, m) => acc + (m.weight * 100) / data.weight, 0).toFixed(2) : 0}%</td>
              </tr>
            </tfoot>
          ) : null}
        </table>
      </div>
      <div className="mt-4">
        <CharlesButton onClick={addMaterial}>+ Material</CharlesButton>
      </div>
    </div>
  );
};

export default MaterialsView;
