import { AppContext } from "App";
import { useContext, useEffect, useState } from "react";
import { uuidv4 } from "utils/uuid";

// Deprecated, use useModals() instead
const Modal = ({
  className = "",
  title = null,
  smallerTitle = false,
  subtitle,
  maxWidth = "max-w-4xl",
  show,
  onHide,
  isBeingPresented = false,
  children,
  center = true,
  pinLeft,
}) => {
  const { modals, setModals, showMainMenu } = useContext(AppContext);
  const [id] = useState(uuidv4());

  // TO DO: think about how to deal with this situation...

  // useEffect(() => {
  //    if (id) {
  //       if (show) {
  //          setModals((prev) => [...prev, id]);
  //       } else {
  //          setModals((prev) => prev.filter((i) => i !== id));
  //       }
  //    }
  // }, [show]);

  useEffect(() => {
    function keyboardHandler(e) {
      if (show && e.key === "Escape" && !isBeingPresented) {
        onHide();
        // if (modals.length > 0) {
        //    const lastModal = modals[modals.length - 1];
        //    console.log('lastModal', lastModal);
        //    if (id === lastModal) {
        //       onHide();
        //    }
        // }
      }
    }

    document.addEventListener("keyup", keyboardHandler);

    return () => {
      document.removeEventListener("keyup", keyboardHandler);
    };
  }, [show, modals]);

  return (
    <div
      className={`fixed inset-0 bg-gray-200 dark:bg-gray-900 z-50 animate-ease-5 flex flex-col overflow-auto px-8 lg:items-center
               ${isBeingPresented ? "bg-opacity-100" : "bg-opacity-90"}
               ${center ? "lg:justify-center" : ""}
               ${show ? "opacity-100 visiable" : "opacity-0 invisible"}
               ${pinLeft ? "" : showMainMenu ? "md:left-56" : ""}
               ${className}
            `}
    >
      {isBeingPresented ? null : <div className="fixed inset-0" onClick={onHide}></div>}
      <div
        className={`w-full flex flex-col relative animate-cuber-5
            ${maxWidth}
            ${center ? "" : "flex-1"}
            ${center ? "pb-10" : ""}
            ${show ? `opacity-1 mt-24 ${center ? "lg:-mt-10" : ""}` : `opacity-0 mt-36 ${center ? "lg:mt-0" : ""}`}
            bg-gray-50 dark:bg-gray-800
            ${center ? "rounded-t-xl lg:rounded-3xl" : "rounded-t-3xl"}
            `}
        style={{
          transition: "margin 0.5s cubic-bezier(0.3, 2.1, 0.55, 1) 0s, opacity 0.5s ease",
        }}
      >
        <div className="absolute top-0 right-0 px-6 py-4 z-40 opacity-60 cursor-pointer hover:opacity-100 text-4xl" onClick={onHide}>
          &times;
        </div>
        {title ? (
          <div className="p-10">
            {smallerTitle ? <h2>{title}</h2> : <h1>{title}</h1>}

            {subtitle ? <div className="text-lg opacity-60 mt-2">{subtitle}</div> : null}
          </div>
        ) : null}

        <div className="relative" style={{ minHeight: 200 }}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
