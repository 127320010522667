import React, { useState } from "react";
import Icon from "./Icon.js";
import BackButton from "./BackButton";

export function NavigationBar({
  title,
  subtitle = null,
  prompt = null,
  backButton,
  backTo,
  leftButtons,
  rightButtons,
  className = "",
  useMoreIconForRightButtonsForMobile = false,
}) {
  const [showRightButtons, setShowRightButtons] = useState(false);
  return (
    <nav
      className={`
            ${className}
            flex flex-col relative backdrop-filter backdrop-blur-lg
            border-b border-gray-700 dark:border-gray-700
            bg-black bg-opacity-75 dark:bg-gray-900
            `}
    >
      <div>{prompt}</div>
      <div
        className={`
               flex justify-between py-3 overflow-auto scrolling-touch
            `}
      >
        <div className="flex items-center">
          {backTo ? (
            <div className="ml-2 lg:ml-4">
              <BackButton to={backTo} />
            </div>
          ) : backButton ? (
            <div className="ml-2 lg:ml-4">{backButton}</div>
          ) : null}
          {title ? (
            <div className="leading-normal ml-4 lg:ml-6 whitespace-nowrap font-semibold text-gray-100">
              {subtitle ? (
                <div>
                  <div className="text-base">{title}</div>
                  <div className="text-gray-600 text-xs">{subtitle}</div>
                </div>
              ) : (
                <div className="text-base">{title}</div>
              )}
            </div>
          ) : null}
          <div className="pl-4 lg:pl-6 flex items-center whitespace-nowrap">{leftButtons}</div>
        </div>
        {useMoreIconForRightButtonsForMobile ? (
          <div className="pr-4 lg:pr-6 flex items-center whitespace-nowrap cursor-pointer">
            <div className="md:hidden" onClick={(_) => setShowRightButtons((prev) => !prev)}>
              <Icon icon="more" />
            </div>
            <div className="hidden md:flex">{rightButtons}</div>
          </div>
        ) : (
          <div className="pr-4 lg:pr-6 flex items-center whitespace-nowrap">{rightButtons}</div>
        )}
      </div>
      {useMoreIconForRightButtonsForMobile && showRightButtons ? <div className="md:hidden">{rightButtons}</div> : null}
    </nav>
  );
}

// Deprecated, use <Page> instead
function PageContainer({
  className = "",
  navigationBarClassName = "",
  bgColor = "",
  leftButtons,
  rightButtons,
  backButton,
  backTo,
  title,
  subtitle = null,
  content,
  children,
  prompt = null,
  requiredBiggerScreen = false,
  useMoreIconForRightButtonsForMobile = false,
  hideBar = false,
}) {
  return (
    <div className={`${className} ${bgColor} flex flex-col flex-1 min-h-screen`}>
      {hideBar ? null : (
        <NavigationBar
          title={title}
          subtitle={subtitle}
          leftButtons={leftButtons}
          rightButtons={rightButtons}
          prompt={prompt}
          className={`${navigationBarClassName} sticky z-20 left-240 top-0 right-0`}
          backButton={backButton}
          backTo={backTo}
          useMoreIconForRightButtonsForMobile={useMoreIconForRightButtonsForMobile}
        />
      )}
      <div
        className={`
               flex-1 flex-col relative overflow-y-auto dark:bg-gray-900
               ${requiredBiggerScreen ? "hidden lg:flex" : "flex"}
            `}
      >
        {content ? content : children}
      </div>
      {requiredBiggerScreen && (
        <div className="flex flex-1 items-center justify-center text-gray-600 font-bold lg:hidden">This Page requires a bigger window to work.</div>
      )}
    </div>
  );
}

export default PageContainer;
