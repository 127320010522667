import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { Outlet } from 'react-router-dom';
import { Button } from 'components/base';
import Spinner from 'components/Spinner';
import Errors from 'components/Errors';
import Image from 'components/Image';
import AppSettings from 'AppSettings';

const FETCH_PRODUCT_COLLECTIONS = gql`
   query FETCH_PRODUCT_COLLECTIONS($name: String) {
      anyCollection(name: $name) {
         id
         children {
            id
            name
            productLines {
               id
               name
               slug
               mainImage(size: "300x300")
            }
            children {
               id
               name
               productLines {
                  id
                  name
                  slug
                  mainImage(size: "300x300")
               }
               children {
                  id
                  name
                  productLines {
                     id
                     name
                     slug
                     mainImage(size: "300x300")
                  }
               }
            }
         }
      }
   }
`;

function CollectionView() {
   const { loading, error, data } = useQuery(FETCH_PRODUCT_COLLECTIONS, {
      variables: { name: AppSettings.productCategory },
   });
   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;

   const { anyCollection } = data;

   return (
      <div className="flex flex-col flex-1 h-full">
         <div className="flex flex-1 overflow-auto">
            <div className="overflow-auto border-r border-gray-300">
               <div className="m-4">
                  {anyCollection.children.map((collection) => (
                     <Collection collection={collection} key={collection.id} />
                  ))}
               </div>
            </div>
            <div className="flex-1 relative overflow-auto scrolling-touch">
               <Outlet />
            </div>
         </div>
      </div>
   );
}

function ProductLine({ productLine }) {
   return (
      <div className="flex">
         <Image size="w-8 h-8" src={productLine.mainImage} />
         <Button
            className="py-1 ml-2"
            color="gray"
            size="sm"
            navLink={`${productLine.slug}`}
            title={productLine.name}
            rightIcon="cheveron-right"
         />
      </div>
   );
}

function Collection({ className = '', collection, area }) {
   if (collection.children.length === 0 && collection.productLines.length === 0)
      return null;

   return (
      <div className={`${className}`}>
         <div className="font-bold my-1">{collection.name}</div>
         {collection.children.length > 0 ? (
            <div className="ml-4">
               {collection.children.map((collection) => (
                  <Collection collection={collection} key={collection.id} />
               ))}
            </div>
         ) : null}
         {collection.productLines.length > 0 ? (
            <div className="ml-4">
               {collection.productLines
                  .filter((i) =>
                     area ? area.productlinesId.indexOf(i.id) > -1 : true
                  )
                  .map((p) => (
                     <ProductLine productLine={p} key={p.id} />
                  ))}
            </div>
         ) : null}
      </div>
   );
}

export default CollectionView;
