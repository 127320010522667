import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import writtenNumber from "written-number";
import { FETCH_COMMERCIAL_INVOICES, SAVE_COMMERCIAL_INVOICE } from "./graphql";
import { Input, Select } from "components/Form.js";
import { Button } from "components/base.js";
import { Addresses } from "components/InvoiceHeader";
import track, { actions } from "utils/track";
import { createShipmentInvoicePoGroups } from "../../utils";
import { CURRENCIES } from "utils/invoice";
import { Alert } from "components/Toast";

const calcShipmentBalance = (shipment, deposite = 0) => {
  let balance = 0;
  shipment.shipmentInvoices.forEach((i) => {
    i.shipmentInvoiceRows.forEach((row) => {
      balance += row.qty * row.unitPrice;
    });
  });
  balance = (balance - parseFloat(deposite)).toFixed(2);
  return balance;
};

function CommercialInvoiceForm({ initialShipment, commercialInvoice }) {
  const navigate = useNavigate();
  const id = commercialInvoice ? commercialInvoice.id : null;
  const [shipment, setshipment] = useState(initialShipment);

  const [currency, setCurrency] = useState(
    commercialInvoice
      ? CURRENCIES.find((i) => i.code === commercialInvoice.currency)
      : initialShipment.odooCurrency.length === 1
        ? CURRENCIES.find((i) => i.code === initialShipment.odooCurrency[0])
        : "0"
  );
  const [deposite, setdeposite] = useState(commercialInvoice ? commercialInvoice.deposite : 0);
  const [balance, setbalance] = useState(commercialInvoice ? commercialInvoice.balance : calcShipmentBalance(initialShipment));

  const [saveCommercialInvoice, { loading }] = useMutation(SAVE_COMMERCIAL_INVOICE, {
    onCompleted(res) {
      console.log("res", res);
      navigate(`/shipment/doc/commercial-invoice/${res.saveCommercialInvoice.commercialInvoice.id}`);
    },
    onError(error) {
      Alert(error.message);
    },
    refetchQueries: [{ query: FETCH_COMMERCIAL_INVOICES }],
  });

  const onChangePrice = (shipmentInvoiceId, rowId, unitPrice) => {
    const updatedShipment = {
      ...shipment,
      shipmentInvoices: shipment.shipmentInvoices.map((i) =>
        i.id === shipmentInvoiceId
          ? {
            ...i,
            shipmentInvoiceRows: i.shipmentInvoiceRows.map((r) =>
              r.id === rowId
                ? {
                  ...r,
                  unitPrice,
                }
                : r
            ),
          }
          : i
      ),
    };
    setshipment(updatedShipment);
    setbalance(calcShipmentBalance(updatedShipment));
  };

  const onChangeDeposite = (shipment, newDeposite) => {
    setdeposite(newDeposite);
    setbalance(calcShipmentBalance(shipment, newDeposite));
  };

  const calcRowPrice = (row) => (row.qty * row.unitPrice).toFixed(2);

  const sayTotal = (value) => {
    let s = "";
    const [a, b] = (value + "").split(".");
    if (parseInt(a, 10) > 0) {
      s = writtenNumber(a);
    }
    if (parseInt(b, 10) > 0) {
      s += " AND CENTS " + writtenNumber(b);
    }
    return s;
  };

  return (
    <div className="container mx-auto max-w-7xl my-8">
      <div className="font-bold text-center text-gray-600">Fill the item price and currency to generate a commercial invoice.</div>
      <div className="shadow-lg my-4 m-8 p-8 relative bg-white dark:bg-gray-800 overflow-auto rounded-xl">
        <div className="absolute pin-t pin-l pl-4">
          <img className="logo" width="180" src="https://cdn.waboba.com/public/waboba-logo-w-waboba.png" alt="Waboba Group" />
        </div>
        <h2 className="py-4 text-center">WABOBA {shipment.stampForPackingList}</h2>
        <div className="my-6">
          <Addresses />
        </div>

        <div className="flex justify-center">
          <h2 className="text-center px-4 pb-2 my-6 border-b">Commercial Invoice</h2>
        </div>

        <div className="pt-4 flex justify-between">
          <div>
            <div className="mb-4 text-base">
              <b>Inv No.:</b> #{shipment.invoiceNumber}
            </div>
            <div className="flex leading-normal">
              <div className="mr-6">
                <div>
                  <b>Consignee</b>
                </div>
                <div>{shipment.shipmentConsigneeName}</div>
                <div className="whitespace-pre-wrap">{shipment.addr}</div>
                <div className="mt-2">
                  <b>Notify Party: </b>
                  <div>{shipment.shipmentNotifyPartyName}</div>
                  <div className="whitespace-pre-wrap">{shipment.notifyParty}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-right leading-normal">
            <div>
              <b>Invoice Date: </b> {commercialInvoice ? new Date(commercialInvoice.created).toLocaleDateString() : new Date().toLocaleDateString()}
            </div>
            <div className="leading-normal">
              <div>
                <b>Customer PO#: </b>
                {shipment.po}
              </div>
              <div>
                <b>VV: </b>
                {shipment.vv}
              </div>
              <div>
                <b>BL Number: </b>
                {shipment.blNumber}
              </div>
              <div>
                <b>Container Number: </b>
                {shipment.containerNumber}
              </div>
              <div>
                <b>Shipping Terms: </b>
                {shipment.shippingTerm}
              </div>
              <div>
                <b>Port of Destination: </b> {shipment.portOfDestination}
              </div>
              <div>
                <b>Port of Discharge: </b>
                {shipment.portOfDischarge}
              </div>
              <div>
                <b>Payment Terms: </b>
                {shipment.paymentTerms}
              </div>
              <div>
                <b>Country of Origin: </b>
                {shipment.countryOfOrigin}
              </div>
            </div>
          </div>
        </div>

        <div className="text-right">
          <Select
            textAlignLast="center"
            value={currency.code || 0}
            onChange={(e) => setCurrency(CURRENCIES.find((currency) => currency.code === e.target.value))}
          >
            <option value="0" disabled>
              -- Currency --
            </option>
            {CURRENCIES.map((currency, index) => (
              <option key={index} value={currency.code}>
                {currency.code} {currency.symbol}
              </option>
            ))}
          </Select>
          {initialShipment.odooCurrency.length === 0 ? (
            <div className="mt-1 text-red-600 font-semibold">No currency found in Odoo order, please manually set currency.</div>
          ) : (
            ""
          )}
        </div>
        <div className="mt-4">
          <table className="border border-gray-200 dark:border-gray-700">
            <thead className="uppercase bg-gray-800 text-gray-100">
              <tr>
                {shipment.hasRowOrderNo ? (
                  <th align="left" width={120}>
                    ORDER NO
                  </th>
                ) : null}
                <th align="left" width={120}>
                  ITEM NO
                </th>
                <th align="center" width={120}>
                  HS CODE
                </th>
                <th align="left">Item Description</th>
                <th className="text-right" width={100}>
                  Qty
                </th>
                <th className="text-right" width={150}>
                  Price/pcs
                </th>
                <th className="text-right" width={90}>
                  Total Price
                </th>
              </tr>
            </thead>
            <tbody>
              {shipment.shipmentInvoices.map((shipmentInvoice) => (
                <ShipmentInvoice
                  key={shipmentInvoice.id}
                  hasRowOrderNo={shipment.hasRowOrderNo}
                  shipmentInvoice={shipmentInvoice}
                  onChangePrice={onChangePrice}
                  calcRowPrice={calcRowPrice}
                  currency={currency}
                />
              ))}
              <tr>
                <td className="font-bold" colSpan={shipment.hasRowOrderNo ? 6 : 5}>
                  Deposite Payment
                </td>
                <td align="right">
                  <Input className="text-right" type="number" value={deposite} onChange={(e) => onChangeDeposite(shipment, e.target.value)} />
                </td>
              </tr>
              <tr>
                <td colSpan={shipment.hasRowOrderNo ? 4 : 3} className="font-bold">
                  Total Balance Amount
                </td>
                <td align="right">{shipment.totalQty}</td>
                <td />
                <td align="right">
                  {currency.symbol} {balance}
                </td>
              </tr>

              <tr>
                <td className="uppercase" colSpan={shipment.hasRowOrderNo ? 7 : 6}>
                  SAY TOTAL {currency.saying}
                  {": "}
                  {sayTotal(balance)} ONLY.
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="flex justify-between mt-4">
          <div>
            <div>
              <b>Carton Marking</b>
            </div>
            <div className="mt-4">{shipment.shippingMark}</div>
          </div>
          <div />
        </div>

        <div className="mt-4">
          <div>
            <b>Company Name and Authorized Signature</b>
          </div>
          <div className="mt-4">
            <div>
              <img
                className="inline-block"
                width="160"
                src={
                  shipment.stampForPackingList === "AB"
                    ? "https://cdn.waboba.com/wiswabobacomshipmentstampforinternaluseonly.jpg"
                    : "https://cdn.waboba.com/wiswabobacomshipmentstampltdforinternaluseonly.jpg"
                }
                alt="STAMP"
              />
            </div>
            <div>WABOBA {shipment.stampForPackingList}</div>
          </div>
        </div>
      </div>

      <div className="p-8 flex justify-end">
        <Button
          bold
          size="lg"
          title={loading ? "Generating..." : "Generate"}
          loading={loading}
          disabled={loading}
          onClick={(_) => {
            if (currency === "0") {
              Alert("error", "Please set currency.");
              return;
            }
            saveCommercialInvoice({
              variables: {
                id: id,
                shipmentId: shipment.id,
                data: JSON.stringify(shipment),
                currency: currency.code,
                deposite: deposite,
                balance: balance,
              },
            });
            track(id ? actions.shipment.updateCommercialInvoice.name : actions.shipment.createCommercialInvoice.name, {
              invoiceNumber: shipment.invoiceNumber,
            });
          }}
        />
      </div>
    </div>
  );
}

const ShipmentInvoice = ({ hasRowOrderNo, shipmentInvoice, onChangePrice, calcRowPrice, currency }) => {
  const poGroups = createShipmentInvoicePoGroups(shipmentInvoice);
  return poGroups.map((group, groupIndex) => (
    <React.Fragment key={groupIndex}>
      <tr className="bg-gray-200 dark:bg-gray-900 font-bold">
        <td colSpan={hasRowOrderNo ? 7 : 6}>SO #{shipmentInvoice.invoice.number}</td>
      </tr>
      {group.po ? (
        <tr className="bg-gray-100 text-gray-600">
          <td colSpan={hasRowOrderNo ? 7 : 6}>PO #{group.po.number}</td>
        </tr>
      ) : null}

      {group.shipmentInvoiceRows.map((row, rowIndex) => (
        <tr key={rowIndex}>
          {hasRowOrderNo ? <td className="border-b dark:border-gray-700">{row.orderNo}</td> : null}
          <td className="border-b dark:border-gray-700">{row.newProductItemNumber}</td>
          <td className="border-b dark:border-gray-700">{row.hsCodeForDestination}</td>
          <td className="border-b dark:border-gray-700 text-xs">{row.packingName}</td>
          <td className="border-b dark:border-gray-700" align="right">
            {row.qty}
          </td>
          <td className="border-b dark:border-gray-700" align="right">
            <Input
              className="text-right p-1 w-full"
              type="number"
              min={0}
              value={row.unitPrice}
              onChange={(e) => onChangePrice(shipmentInvoice.id, row.id, e.target.value)}
            />
          </td>
          <td className="border-b dark:border-gray-700" align="right">
            {currency.symbol} {calcRowPrice(row)}
          </td>
        </tr>
      ))}
    </React.Fragment>
  ));
};

export default CommercialInvoiceForm;
