import React from "react";
import { useQuery, gql } from "@apollo/client";
import Errors from "components/Errors";
import { InlineSpinner } from "components/Spinner";
import { Tag } from "components/base";

const FETCH_DATA = gql`
  query FETCH_DATA {
    allLabTestStandards {
      id
      name
    }
  }
`;

const LabTestReportsSelector = ({ values, setValues }) => {
  const { loading, error, data } = useQuery(FETCH_DATA);
  if (loading) return <InlineSpinner />;
  if (error) return <Errors error={error} />;

  return (
    <div className="flex flex-wrap">
      {data.allLabTestStandards.map((x, index) => {
        const selected = values.includes(x.id);
        return (
          <Tag
            className="mr-4 mb-3"
            key={index}
            selected={selected}
            onClick={() => setValues((prev) => (selected ? prev.filter((i) => i !== x.id) : [...prev, x.id]))}
            title={x.name}
          />
        );
      })}
    </div>
  );
};

export default LabTestReportsSelector;
