import { gql, useMutation, useQuery } from '@apollo/client';
import { Button } from 'components/base';
import { FileSelector, Input, Switcher, Text } from 'components/Form';
import { Alert } from 'components/Toast';
import { useState } from 'react';

const FETCH_VIDEO = gql`
   query FETCH_VIDEO($id: ID!) {
      video(id: $id) {
         id
         sources {
            id
            filename
            thumbSrc
            status
         }
      }
   }
`;

const CREATE_VIDEO_SOURCE = gql`
   mutation CREATE_VIDEO_SOURCE($videoId: ID!, $file: Upload!) {
      createVideoSource(videoId: $videoId, file: $file) {
         video {
            id
            sources {
               id
               status
               convertedVideoSrc
               sourceUrl
               thumbSrc
            }
         }
      }
   }
`;

const UPDATE_VIDEO = gql`
   mutation UPDATE_VIDEO(
      $id: ID!
      $name: String
      $description: String
      $active: Boolean
   ) {
      updateVideo(
         id: $id
         name: $name
         description: $description
         active: $active
      ) {
         video {
            id
            name
            description
            active
         }
      }
   }
`;

const VideoForm = ({ video }) => {
   const [name, setName] = useState(video.name);
   const [description, setDescription] = useState(video.description);
   const [active, setActive] = useState(video.active);
   const [createVideoSource, createVideoSourceRes] = useMutation(
      CREATE_VIDEO_SOURCE,
      {
         onCompleted() {
            Alert('success', 'Video uploaded.');
         },
         onError(error) {
            Alert('error', error.message);
         },
      }
   );
   const [updateVideo, updateVideoRes] = useMutation(UPDATE_VIDEO, {
      onCompleted() {
         Alert('success', 'Updated.');
      },
      onError(error) {
         Alert('error', error.message);
      },
   });
   const [sourceVideoUpdated, setSourceVideoUpdated] = useState(false);
   const fetchVideoSources = useQuery(FETCH_VIDEO, {
      pollInterval: 1000,
      variables: { id: video.id },
      skip:
         createVideoSourceRes.loading ||
         !createVideoSourceRes.called ||
         !video.sources.filter((i) => i.status) !== 'COMPLETE' > 0 ||
         sourceVideoUpdated,
   });

   function uploadFile(file) {
      createVideoSource({ variables: { videoId: video.id, file } });
   }

   return (
      <div className="px-10 pb-10 space-y-4">
         <div className="flex items-center space-x-3">
            <label htmlFor="">Name: </label>
            <Input
               className="flex-1"
               value={name}
               onChange={(e) => setName(e.target.value)}
            />
         </div>

         <div className="">
            <Text
               placeholder="Description"
               className="mt-2"
               value={description}
               onChange={(e) => setDescription(e.target.value)}
            />
         </div>

         <div className="flex items-center space-x-3">
            <label htmlFor="">Active:</label>
            <Switcher
               isOn={active}
               onChange={() => setActive((prev) => !prev)}
            />
         </div>

         <div>
            <Button
               size="xl"
               bold
               loading={updateVideoRes.loading}
               disabled={updateVideoRes.loading}
               title="Save"
               onClick={() =>
                  updateVideo({
                     variables: { id: video.id, name, description, active },
                  })
               }
            />
         </div>

         <hr />

         <div>
            <h4>Video Sources</h4>

            <div className="mt-4">
               {video.sources.map((source) => (
                  <div
                     key={source.id}
                     className="border-b dark:border-gray-700 py-4"
                  >
                     <VideoSource
                        source={source}
                        setSourceVideoUpdated={setSourceVideoUpdated}
                     />
                  </div>
               ))}
            </div>

            <div className="mt-4">
               <FileSelector
                  bold
                  title="Add a new source video"
                  accept="video"
                  uploading={createVideoSourceRes.loading}
                  onChange={(e) => {
                     uploadFile(e.target.files[0]);
                     e.target.value = null;
                  }}
               />
            </div>
         </div>
      </div>
   );
};

const UPDATE_VIDEO_SOURCE = gql`
   mutation UPDATE_VIDEO_SOURCE($id: ID!, $file: Upload!) {
      updateVideoSource(id: $id, file: $file) {
         videoSource {
            id
            status
         }
      }
   }
`;

const DELETE_VIDEO_SOURCE = gql`
   mutation DELETE_VIDEO_SOURCE($id: ID!) {
      deleteVideoSource(id: $id) {
         video {
            id
            sources {
               id
            }
         }
      }
   }
`;

const VideoSource = ({ source, setSourceVideoUpdated }) => {
   const [updateVideoSource, updateVideoSourceRes] = useMutation(
      UPDATE_VIDEO_SOURCE,
      {
         onCompleted() {
            setSourceVideoUpdated(true);
         },
         onError(error) {
            Alert('error', error.message);
         },
      }
   );

   const [deleteVideoSource, deleteVideoSourceRes] = useMutation(
      DELETE_VIDEO_SOURCE,
      {
         variables: { id: source.id },
         onError(error) {
            Alert('error', error.message);
         },
      }
   );

   return (
      <div className="grid grid-cols-5 gap-4">
         <div className="col-span-2">
            {source.thumbSrc ? (
               <img
                  className="w-full"
                  src={source.thumbSrc}
                  alt={source.filename}
               />
            ) : null}
         </div>

         <div className="col-span-3">
            <div className="space-y-1">
               <div className="text-lg font-semibold">{source.filename}</div>

               <div>
                  <label htmlFor="">{source.height}p</label>
               </div>

               <div>
                  <label htmlFor="">Status: </label>
                  {source.status}
               </div>

               <div>
                  <label htmlFor="">Video Source Download Link:</label>
                  <Button
                     title={source.sourceUrl}
                     link={source.sourceUrl}
                     target="_blank"
                  />
               </div>
               <div>
                  <label htmlFor="">Preview Link:</label>
                  {source.convertedVideoSrc ? (
                     <Button
                        title={source.convertedVideoSrc}
                        link={source.convertedVideoSrc}
                        target="_blank"
                     />
                  ) : (
                     <div> Not ready yet.</div>
                  )}
               </div>
            </div>

            <div className="flex space-x-4 items-start mt-4">
               <div>
                  <FileSelector
                     title="Replace Srouce Video"
                     uploading={updateVideoSourceRes.loading}
                     onChange={(e) => {
                        updateVideoSource({
                           variables: {
                              id: source.id,
                              file: e.target.files[0],
                           },
                        });
                        e.target.value = null;
                     }}
                  />
               </div>
               <Button
                  title="Delete"
                  color="red"
                  loading={deleteVideoSourceRes.loading}
                  disabled={deleteVideoSourceRes.loading}
                  onClick={() => {
                     if (
                        window.confirm(
                           'Are you sure to delete this source video?'
                        )
                     ) {
                        deleteVideoSource();
                     }
                  }}
               />
            </div>
         </div>
      </div>
   );
};

const VideoMeta = ({ meta }) => {
   if (!meta) return null;

   const videoStream = meta.streams.find((i) => i.codec_type === 'video');

   return (
      <div>
         <div className="flex">
            <label htmlFor="">{videoStream.height}p</label>
         </div>
      </div>
   );
};

export default VideoForm;
