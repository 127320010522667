import { useEffect, useState } from "react";
import { Legend, PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart, ResponsiveContainer, Tooltip } from 'recharts';

const colors = ['#5ed6ef', '#b68bb1', '#f3bc2d', '#dcaf86', '#1a6d75', '#5784f8', '#31352a', '#469297', '#f24122', '#3dd045'];
const keyMapping = {
  quality: "Quality",
  shipmentErrors: "Shipment Error",
  leadTime: "Lead Time",
  esg: "ESG",
  communications: "Communication"
};

export const toRadarData = (supplierScores) => {
  if (!supplierScores || supplierScores.length === 0) {
    return null;
  }

  // get the indicator keys excluding __typename
  const { __typename, ...validScores } = supplierScores[0].scores;
  const indicatorKeys = Object.keys(validScores);

  const radarChartData = indicatorKeys.map(key => {
    const indicatorItem = { indicator: key, fullMark: 100 };

    // each indicator will record the score of each supplier
    supplierScores.forEach(supplierInfo => {
      indicatorItem[supplierInfo.name] = supplierInfo.scores[key];
    });

    return indicatorItem;
  });

  return radarChartData;
};

const ScoreRadarChart = ({ supplierScores, title, showLegend = true, tickShowLess = false, cx = "50%" }) => {
  const radarChartData = toRadarData(supplierScores);
  if (!radarChartData || radarChartData.length === 0) {
    return null; // or return a placeholder component
  }

  // Initialize visibleSuppliers state. By default, only the first three suppliers are visible.
  const supplierNames = supplierScores.map(supplier => supplier.name);
  const [visibleSuppliers, setVisibleSuppliers] = useState(
    supplierNames.reduce((acc, supplier, index) => ({ ...acc, [supplier]: index < 3 }), {})
  );

  useEffect(() => {
    setVisibleSuppliers(
      supplierNames.reduce((acc, supplier, index) => ({ ...acc, [supplier]: index < 3 }), {})
    );
  }, [supplierScores]);


  const handleLegendClick = (event) => {
    setVisibleSuppliers({
      ...visibleSuppliers,
      [event.dataKey]: !visibleSuppliers[event.dataKey]  // if it's visible, hide it, if it's hidden, show it
    });
  };

  return (
    <div>
      {title && <h3>{title}</h3>}
      <div>
        <ResponsiveContainer width="100%" height="100%" minWidth={300} minHeight={300} className="justify-center items-center" >
          <RadarChart data={radarChartData} height="100%" width="100%" cx={cx} cy="50%" outerRadius="70%">
            <PolarGrid stroke="#555" strokeOpacity={0.3} strokeDasharray="3 3" />
            <PolarAngleAxis dataKey="indicator"
              tick={{ fontSize: 18, fill: "#555555" }}
              tickFormatter={(value) => {
                // Map the keys to a more readable format
                const formattedValue = keyMapping[value] || value;
                // tick show less text
                return (tickShowLess || window.innerWidth < 1350) ? `${formattedValue.slice(0, 5)}...` : formattedValue;
              }} />
            <PolarRadiusAxis angle={30} domain={[0, 100]} />
            {showLegend && <Legend layout="vertical" align="right" verticalAlign="middle" width="25%" onClick={handleLegendClick} />}
            <Tooltip labelStyle={{ fontSize: '20px' }} labelFormatter={(value) => keyMapping[value] || value} />

            {supplierNames.map((supplierName, index) => (
              <Radar
                data-testid="radar"
                key={supplierName}
                name={supplierName}
                dataKey={supplierName}
                stroke={colors[index]}
                fill={colors[index]}
                fillOpacity={0.3}
                hide={!visibleSuppliers[supplierName]}
                animationDuration={500}
                animationEasing='ease-in-out'
              />
            ))}
          </RadarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};
export default ScoreRadarChart;