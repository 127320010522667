const points = [29, 56, 69];
const containerNames = {
  29: "20GP",
  56: "40GP",
  69: "40HQ",
};

function ProgressColor(value) {
  return value > 100 ? "#f56565" : value >= 90 ? "#48bb78" : value >= 80 ? "#ecc94b" : "#f56565";
}

function tips(value) {
  return value >= 90
    ? "You can go ahead to place the order."
    : value >= 80
    ? "Please try to fill more goods into the container."
    : "Your order is not enough to fill a container. Please adjust the QTY to fill up the container.";
}

export function transformData(value) {
  const maxPoint = Math.max(...points);
  let hasSelectedContainer = false;
  let selectedContainerTips = "Wow, there are a lot of products!!!";
  const status = points.map((point) => {
    let isUsing = false;
    let hasReached = false;
    let progress = (value * 100) / point;
    if (value > point) hasReached = true;
    if (value <= point) {
      if (!hasSelectedContainer) {
        isUsing = true;
        hasSelectedContainer = true;
        hasReached = true;
        selectedContainerTips = tips(progress);
      }
    }
    const width = (point * 100) / maxPoint;
    const background = hasReached ? ProgressColor(progress) : "transparent";
    return { point, progress, isUsing, hasReached, width, background };
  });
  return { status, selectedContainerTips };
}

function CapacityBar({ value = 0, className }) {
  const { status, selectedContainerTips } = transformData(value);
  return (
    <div className={`${className} w-full`}>
      <div className=" w-full relative text-gray-800 dark:text-gray-300">
        {status.map((s, index) => (
          <div
            key={index}
            className="relative"
            style={{
              width: `${s.width}%`,
            }}
          >
            <div className="text-right px-2 text-xs leading-none z-10">
              {s.point} CBM({containerNames[s.point]})
            </div>
            <div className={`relative mt-1 h-1 rounded-full bg-gray-100 dark:bg-gray-700`}>
              <div
                className="h-1 rounded-full overflow-hidden max-w-full"
                style={{
                  width: `${s.progress}%`,
                  transition: "all 0.5s ease-in-out",
                  background: s.background,
                }}
              />
            </div>
          </div>
        ))}
      </div>
      <div className="mt-2 text-gray-600 dark:text-gray-500">{selectedContainerTips}</div>
    </div>
  );
}

export default CapacityBar;
