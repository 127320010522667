import { useState } from "react";
import ProductForecastView from "./ProductForecastView";

const ForecastInput = ({ sku, yearMonth, initialValue, onChangeForecast }) => {
  const [value, setValue] = useState(initialValue || 0);
  const [showInfo, setShowInfo] = useState(false);

  return (
    <div className="relative">
      <input
        className="appearance-none bg-blue-50 dark:bg-blue-900 py-2 px-6 text-right w-full"
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
          onChangeForecast(parseInt(e.target.value));
        }}
        onFocus={() => setShowInfo(true)}
        // onBlur={() => setShowInfo(false)}
      />
      {showInfo && (
        <>
          <div className="absolute top-full left-0 w-96 p-4 rounded-3xl bg-blue-50 dark:bg-blue-900 border border-blue-300 dark:border-blue-500 z-20">
            <ProductForecastView sku={sku} yearMonth={yearMonth} />
          </div>
          <div className="fixed inset-0 z-10" onClick={() => setShowInfo(false)}></div>
        </>
      )}
    </div>
  );
};

export default ForecastInput;
