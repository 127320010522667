import { gql, useMutation } from '@apollo/client';
import { AppContext } from 'App';
import { Button } from 'components/base';
import FactorySelector from 'components/FactorySelector';
import { Input } from 'components/Form';
import { ProductImagesV2 } from 'components/ProductImages';
import { Alert } from 'components/Toast';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { calcCheckQtyFromQty } from './utils';

const CREATE_SIMPLE_QC_REPORT = gql`
   mutation CREATE_SIMPLE_QC_REPORT(
      $factoryId: ID!
      $invoiceId: ID!
      $productId: ID!
      $qty: Int!
      $checkQty: Int!
   ) {
      createSimpleQcReport(
         factoryId: $factoryId
         invoiceId: $invoiceId
         productId: $productId
         qty: $qty
         checkQty: $checkQty
      ) {
         qcReport {
            id
         }
      }
   }
`;

const CreateQCReport = ({ product, qty, invoice, onCreated }) => {
   const { user } = useContext(AppContext);
   const [checkQty, setCheckQty] = useState(qty);
   const [factoryId, setFactoryId] = useState(
      user.email === 'acro@waboba.com' ? '42' : null
   );
   const navigate = useNavigate();

   const [createReport, createReportRes] = useMutation(
      CREATE_SIMPLE_QC_REPORT,
      {
         onCompleted: (res) => {
            Alert('success', 'Created.');
            navigate(`/qc/final/${res.createSimpleQcReport.qcReport.id}`);
         },
         onError: (error) => Alert('error', error.message),
         refetchQueries: ['FETCH_QC_SHIPMENTS', 'FETCH_RECENT_QC_REPORTS'],
      }
   );

   return (
      <div className="space-y-6 text-base">
         <div>
            <label>Order: </label> {invoice.number}
         </div>

         <div className="">
            <div className="flex flex-wrap">
               <ProductImagesV2 images={product.images} />
            </div>
            <div className="mt-3 font-bold">
               {product.name} &times; {qty}
            </div>
         </div>

         <div>
            <div className="flex items-center space-x-2">
               <label htmlFor="">Check QTY 产品数量:</label>
               <Input
                  className="text-center"
                  value={checkQty}
                  onChange={(e) => setCheckQty(e.target.value)}
               />
            </div>
            <div className="opacity-60 text-xs mt-1">
               若实际数量与SO不一致，请输入实际数量
            </div>
         </div>

         <div className="flex items-center space-x-3">
            <label htmlFor="">Factory 供应商:</label>
            <div className="relative">
               <FactorySelector value={factoryId} onChange={setFactoryId} />
            </div>
         </div>

         <Button
            size="lg"
            next
            disabled={createReportRes.loading}
            onClick={(_) =>
               createReport({
                  variables: {
                     factoryId,
                     invoiceId: invoice.id,
                     productId: product.id,
                     qty: qty,
                     checkQty: calcCheckQtyFromQty(checkQty),
                  },
               })
            }
            title={
               createReportRes.loading ? 'Createing 正在创建...' : 'Next 下一步'
            }
         />
      </div>
   );
};

export default CreateQCReport;
