import { Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="px-6 py-3 rounded-2xl bg-opacity-80 bg-white dark:bg-gray-800 dark:bg-opacity-80">
        <div>{label}</div>
        {payload.map((data, index) => (
          <div key={index}>{`${data.name}: ${data.value}`}</div>
        ))}
      </div>
    );
  }

  return null;
};

const CategoryView = ({ name, data }) => {
  return (
    <div>
      <h4>{name}</h4>
      <div className="mt-4">
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={data} margin={{ top: 0, left: 0, right: 0, bottom: 0 }}>
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip content={CustomTooltip} />
            <Line type="monotone" dataKey="sales" stroke="#16a34a" dot={false} />
            <Line type="monotone" dataKey="forecast" stroke="#7c3aed" dot={false} />
            <Line type="monotone" dataKey="my_forecast" stroke="#ec4899" dot={false} aria-label="My Forecast" />
            <Legend
              labelFormatter={(value) => {
                console.log("value", value);
                return value === "sales" ? "Actual Sales" : value === "forecast" ? "Forecast" : "My Forecast";
              }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default CategoryView;
