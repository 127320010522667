import Status from "components/Status";
import React from "react";
import { BsArrowRight } from "react-icons/bs";

export const ASSESSMENT_STATUS = ["DRAFT", "SELF_ASSESSMENT", "REVIEWING", "IN_RECTIFICATION", "COMPLETED"];

export const AssessmentStatusChain = ({ status }) => {
  return (
    <div className="flex items-center space-x-2">
      {ASSESSMENT_STATUS.map((i, index) => (
        <React.Fragment key={index}>
          {index === 0 ? null : <BsArrowRight className={ASSESSMENT_STATUS.indexOf(status) >= index ? "" : " opacity-30"} />}
          <Status status={i} active={ASSESSMENT_STATUS.indexOf(status) >= index} />
        </React.Fragment>
      ))}
    </div>
  );
};

export default AssessmentStatusChain;
