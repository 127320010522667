import { useContext, useState } from "react";
import ProductWarningsSelector from "./ProductWarningsSelector";
import { ProductContext } from "./ProductForm";
import { CharlesButtonWithArrow } from "components/charles/base";
import { Input } from "components/Form";
import LabTestReportsSelector from "./LabTestReportsSelector";

const WarningsView = () => {
  const { warningIds, setWarningIds, labTestStandardIds, setLabTestStandardIds, pantone, setPantone } = useContext(ProductContext);
  const [show, setShow] = useState(false);

  return (
    <div>
      <CharlesButtonWithArrow className="text-xl" show={show} onClick={() => setShow((prev) => !prev)}>
        Warnings, Lab Test Reports, Pantone
      </CharlesButtonWithArrow>

      {show ? (
        <div className="mt-2">
          <div className="opacity-70 text-xs">
            These data could be inherited from Product Line. If you don't set these info, it will show as the info from product line. Otherwise it will show as
            the info from product.
          </div>

          <div className="mt-4 flex items-baseline space-x-4">
            <label htmlFor="">Warnings: </label>
            <div className="mt-3">
              <ProductWarningsSelector warningIds={warningIds} setWarningIds={setWarningIds} />
            </div>
          </div>

          <div className="mt-4 flex items-baseline space-x-4">
            <label htmlFor="">Lab Test Reports: </label>
            <div className="mt-3">
              <LabTestReportsSelector values={labTestStandardIds} setValues={setLabTestStandardIds} />
            </div>
          </div>

          <div className="mt-4 flex items-baseline space-x-4">
            <label htmlFor="">Pantone: </label>
            <Input className="flex-1" value={pantone} onChange={(e) => setPantone(e.target.value)} />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default WarningsView;
