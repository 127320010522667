import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import Page from "components/Page";
import { FETCH_PATENTS_BY_CPC } from "./graphql";
import { BsFileEarmarkPdf } from "react-icons/bs";

const PatentDetail = () => {
  const { cpc } = useParams();
  let cpcSlash = cpc.replace("-", "/");
  const { loading, error, data } = useQuery(FETCH_PATENTS_BY_CPC, { variables: { cpcSlash } });

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  return (
    <Page title={cpcSlash} backTo={`/tools/patentai`}>
      <div className="p-4 lg:p-6 space-y-6 text-sm">
        {data.patentsByCpc.map((patent, index) => (
          <div key={index}>
            <div className="card flex border dark:border-gray-700">
              <div className="w-2/3 flex-shrink-0">
                <div>
                  <h4>{patent.title}</h4>
                  <div className="mt-2 flex space-x-8 items-center">
                    <div>
                      <label>Status: </label>
                      {patent.status.startsWith("Expired") ? (
                        <span className="text-green-600 font-semibold">{patent.status}</span>
                      ) : (
                        <span>{patent.status}</span>
                      )}
                    </div>

                    <div className=" font-semibold">
                      <label>By AI: </label>
                      {patent.isProcessedByAi ? <span className="text-green-600">Yes</span> : <span className="text-red-500">No</span>}
                    </div>

                    <div className="">
                      <label>Original: </label>
                      <a href={`https://patents.google.com/patent/${patent.number}/en`} target="_blank" rel="noreferrer">
                        {patent.number}
                      </a>
                    </div>

                    {patent.pdf !== "" ? (
                      <a href={patent.pdf} target="_blank" rel="noreferrer" className="flex items-center space-x-1">
                        <BsFileEarmarkPdf />
                        <span className="">PDF</span>
                      </a>
                    ) : null}
                  </div>

                  <div className="mt-3 whitespace-pre-wrap leading-relaxed">{patent.summary}</div>
                </div>
              </div>

              <div className="w-1/3">
                <div className="flex flex-wrap justify-center">
                  {patent.images.map((image, index) => (
                    <a key={index} href={image.uri} target="_blank" rel="noreferrer">
                      <img className="w-50 h-32 transition hover:scale-150 m-2" src={image.uri} alt={image.uri} />
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Page>
  );
};

export default PatentDetail;
