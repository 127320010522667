import { gql, useMutation, useQuery } from "@apollo/client";
import { Button } from "components/base";
import Errors from "components/Errors";
import SearchBar from "components/SearchBar";
import Spinner from "components/Spinner";
import { Alert } from "components/Toast";
import moment from "moment";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { searchByProp } from "utils/search";

const FETCH_SUB_SUPPLIER_PAYMENTS = gql`
  query FETCH_SUB_SUPPLIER_PAYMENTS {
    subSupplierPayments {
      total
      results {
        id
        date
        total
        supplier {
          id
          name
        }
        lines {
          id
          invoice {
            id
            number
          }
        }
      }
    }
  }
`;

const MUTATION_CREATE_PAYMENT = gql`
  mutation MUTATION_CREATE_PAYMENT {
    createSubSupplierPayment {
      payment {
        id
        date
        total
      }
    }
  }
`;

const SubSupplierPayments = () => {
  const navigate = useNavigate();
  const { loading, error, data } = useQuery(FETCH_SUB_SUPPLIER_PAYMENTS, {
    fetchPolicy: "network-only",
  });

  const [createPayment, createPaymentRes] = useMutation(MUTATION_CREATE_PAYMENT, {
    onCompleted(res) {
      navigate("/sub-supplier/payments/" + res.createSubSupplierPayment.payment.id);
    },
    onError(error) {
      Alert("error", error.message);
    },
    refetchQueries: ["FETCH_SUB_SUPPLIER_PAYMENTS", "FETCH_SUB_SUPPLIER_INVOICES"],
  });

  const [searchText, setSearchText] = useState("");

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const payments = data.subSupplierPayments.results
    .map((i) => ({
      ...i,
      supplierName: i.supplier ? i.supplier.name : "-",
      invoices: i.lines.map((line) => "INV" + (line.invoice ? line.invoice.number : " - ")).join(" / "),
    }))
    .filter((i) => searchByProp(i, ["invoices", "supplierName"], searchText));

  return (
    <div className="p-6">
      <div className="flex items-center space-x-8">
        <Button title="+ Create Payment" onClick={createPayment} disabled={createPaymentRes.loading} loading={createPaymentRes.loading} />
        <div className="flex-1">
          <SearchBar placeholder="Search by PO number or production supplier name." value={searchText} onChange={setSearchText} />
        </div>
      </div>

      <div className="card px-6 py-4 mt-4">
        <div className="-mx-2">
          <table>
            <thead>
              <tr>
                <th>Payment</th>
                <th>Supplier</th>
                <th className="text-right">Payment Date</th>
                <th className="text-right">Total</th>
              </tr>
            </thead>
            <tbody>
              {payments.map((i) => (
                <tr key={i.id} className="border-y border-gray-100 dark:border-gray-700">
                  <td>
                    <Link to={`/sub-supplier/payments/${i.id}`}>
                      #{i.id} {i.invoices}
                    </Link>
                  </td>
                  <td>{i.supplier ? i.supplier.name : "-"}</td>
                  <td className="text-right">{moment(i.date).format("YYYY-MM-DD")}</td>
                  <td className="text-right">{i.total.toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SubSupplierPayments;
