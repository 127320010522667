import moment from "moment";
import CharlesButton from "components/charles/base";
import { useModals } from "ModalProvider";
import AddpackingInstructionView from "./AddpackingInstructionView";
import { useMutation, useQuery } from "@apollo/client";
import { FETCH_PRODUCT_LIST_PACKING_INSTRUCTIONS } from "./graphlq";
import Errors from "components/Errors";
import Spinner from "components/Spinner";
import { BsTrash } from "react-icons/bs";
import { parseError } from "apollo";
import { DELETE_CONTENT_BLOCK } from "graphql/query";
import { Alert } from "components/Toast";

const PackingInstructions = ({ productListId }) => {
  const { loading, error, data } = useQuery(FETCH_PRODUCT_LIST_PACKING_INSTRUCTIONS, {
    variables: { productListId },
  });
  const modal = useModals();
  const addInstruction = () => {
    modal.present({
      title: "Add Packing Instruction",
      isBeingPresented: true,
      children: <AddpackingInstructionView productListId={productListId} hide={modal.hide} />,
    });
  };

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const packingInstructions = data.productList.packingInstructions;

  return (
    <div className="card px-6 py-4">
      <h5>Packing Instructions</h5>
      <div className="mt-4 space-y-2">
        {packingInstructions.map((i) => (
          <PackingInstructionView instruction={i} key={i.id} />
        ))}
      </div>

      <div className="mt-4 pt-4 border-t border-gray-100 dark:border-gray-700">
        <CharlesButton onClick={addInstruction}>+ Add Instruction</CharlesButton>
      </div>
    </div>
  );
};

const PackingInstructionView = ({ instruction }) => {
  const [deletePackingInstruction, deletePackingInstructionRes] = useMutation(DELETE_CONTENT_BLOCK, {
    variables: { id: instruction.id },
    onCompleted() {
      Alert("success", "Instruction deleted.");
    },
    onError(error) {
      Alert("error", parseError(error));
    },
    refetchQueries: ["FETCH_PRODUCT_LIST_PACKING_INSTRUCTIONS"],
    awaitRefetchQueries: true,
  });

  function deleteHandler() {
    if (window.confirm("Are you sure to delete this instruction?")) {
      deletePackingInstruction();
    }
  }

  return (
    <div className="border-t border-gray-100 dark:border-gray-700 pt-5">
      <div className="flex justify-between">
        <div className="text-xs">
          <span className="font-semibold">{instruction.createdBy.email}</span>
          {" - "}
          <span className="opacity-70">{moment(instruction.updatedAt).format("YYYY-MM-DD HH:mm")}</span>
        </div>

        <div>
          <CharlesButton danger onClick={deleteHandler} loading={deletePackingInstructionRes.loading}>
            <BsTrash />
          </CharlesButton>
        </div>
      </div>

      <div className="mt-2 text-sm">{instruction.content}</div>

      <div className="mt-2 flex">
        {instruction.images.map((url, index) => (
          <a key={index} href={url} target="_blank" className="block mr-2 mb-2">
            <img src={url + "?imageMogr2/thumbnail/100x/strip/quality/50/format/webp"} alt="" className="w-12 h-12 object-cover rounded-lg border" />
          </a>
        ))}
      </div>
    </div>
  );
};

export default PackingInstructions;
