function File({ name, link }) {
  return (
    <div className="py-2 flex flex-1 items-center">
      {link ? (
        <a className="break-all" href={link} target="_blank" rel="noopener noreferrer">
          {name}
        </a>
      ) : (
        <span className="ml-4">{name}</span>
      )}
    </div>
  );
}

export default File;
