const intertekTestingHKRegex = /HKGH[A-Za-z0-9]*/;
const bureauVeritasRegex = /882[A-Za-z0-9]*/;

export function generateDocumentNumber(url) {
  // Get document no from the url. The url is like: https://qiniu-waboba.cors.tech/33ed67a6b8bc43c9ad78cf9051902ead-HKGH0305789601 EU AU.pdf. The document no should be HKGH0305789601.
  const parts = url.split("/");
  const filename = parts[parts.length - 1];

  const intertekTestingHKMatch = filename.match(intertekTestingHKRegex);
  if (intertekTestingHKMatch)
    return {
      notifyBody: "Intertek Testing HK",
      documentNo: intertekTestingHKMatch[0],
    };

  const bureauVeritasMatch = filename.match(bureauVeritasRegex);

  if (bureauVeritasMatch)
    return {
      notifyBody: "Bureau Veritas",
      documentNo: bureauVeritasMatch[0],
    };

  return { notifyBody: "Unknown", documentNo: "Unknown" };
}

export const PLACE_OF_TESTING = {
  "Bureau Veritas": `BUREAU VERITAS SHENZHEN CO., LTD.,
DONGGUAN BRANCH NO.34,
CHENWULU SECTION, GUANTAI RD.,
HOUJIE TOWN,
DONGGUAN CITY,
GUANGDONG PROVINCE, CHINA
523942
TEL: +86-769-89982098`,

  "Intertek Testing HK": `Intertek Testing Services Hong Kong Limited
2/F Garment Centre
576 Castle Peak Road
Kowloon, Hong Kong
Tel +852 2173 8888
Fax +852 2786 1903
intertek.com.hk`,
};

// CPC file - ASTM-F963/CPSIA/California P65

// Intertek Testing Services Hong Kong Limited
