import React from "react";

export default ({ src }) => (
    <div
        className="product-image bg-contain bg-no-repeat bg-center"
        style={{
            backgroundImage: `url(${src})`,
            width: "30px",
            height: "30px"
        }}
    />
);
