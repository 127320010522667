import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import Spinner from "./Spinner";
import Errors from "./Errors.js";
import SearchBar from "./SearchBar.js";
import { searchByProp } from "../utils/search.js";

const FETCH_ALL_USERS = gql`
  query FETCH_ALL_USERS {
    allUsers {
      id
      firstName
      lastName
      email
    }
  }
`;

function UserSelector({ onSelectUser }) {
  const [searchText, setSearchText] = useState("");
  const { loading, error, data } = useQuery(FETCH_ALL_USERS);
  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;
  const users = data.allUsers.filter((user) => searchByProp(user, ["firstName", "lastName", "email"], searchText));

  return (
    <div className="relative flex-1 flex-col flex">
      <div className="mb-4">
        <SearchBar
          autoFocus
          value={searchText}
          onChange={(text) => {
            setSearchText(text);
          }}
          placeholder="Search by email or name"
        />
      </div>
      {users.length > 0 && (
        <div className="flex-1 bg-white">
          {users.map((user) => (
            <div
              className="cursor-pointer hover:bg-blue-200 px-4 lg:px-6 py-2 border-b border-gray-200 animate-ease-2"
              key={user.id}
              onClick={(_) => onSelectUser(user)}
            >
              {user.email}{" "}
              {user.firstName && user.lastName ? (
                <span className="text-gray-500">
                  {user.firstName} {user.lastName}
                </span>
              ) : null}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default UserSelector;
