import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import ErrorSelector from './ErrorSelector';
import FactoriesSelector from './FactoriesSelector';
import ErrorProductsSelector from './ErrorProductsSelector';
import PageContainer from '../../../../components/PageContainer.js';
import { Button } from '../../../../components/base';
import Spinner from '../../../../components/Spinner';
import Errors from '../../../../components/Errors';
import ShipmentTitle from '../../../../components/ShipmentTitle';
import ShipmentErrorComponent from '../../../../components/ShipmentError';
import { Alert } from '../../../../components/Toast';
import ShipmentStatusComponent from '../../../../components/ShipmentStatus';
import { IoIosCheckmark } from 'react-icons/io';

const getUpdatedErrorIds = () => {
   let res = localStorage.getItem('updatedErrorIds');
   if (res) {
      return JSON.parse(res);
   } else {
      return [];
   }
};

const appendUpdatedErrorId = (id) => {
   let res = localStorage.getItem('updatedErrorIds');
   if (res) {
      res = JSON.parse(res);
   } else {
      res = [];
   }
   res.push(id);
   const resString = JSON.stringify(res);
   localStorage.setItem('updatedErrorIds', resString);
   return res;
};

const UPDATE_SHIPMENT_ERROR = gql`
   mutation UPDATE_SHIPMENT_ERROR(
      $shipmentStatusErrorId: Int
      $errorId: Int
      $shipmentInvoiceRowIds: [Int]
      $factoryIds: [Int]
   ) {
      createShipmentError(
         shipmentStatusErrorId: $shipmentStatusErrorId
         errorId: $errorId
         shipmentInvoiceRowIds: $shipmentInvoiceRowIds
         factoryIds: $factoryIds
      ) {
         shipmentError {
            id
         }
      }
   }
`;

const ShipmentErrorForm = ({
   shipmentStatusId,
   initialData,
   shipmentInvoices,
   shipmentDidUpdate,
}) => {
   const [oldError, setOldError] = useState(null);
   const [error, setError] = useState(null);
   const [factoriesRequired, setFactoriesRequired] = useState(false);
   const [productsRequired, setProductsRequired] = useState(false);
   const [factoryIds, setFactoryIds] = useState([]);
   const [shipmentInvoiceRowIds, setShipmentInvoiceRowIds] = useState([]);
   const [hasUpdated, setHasUpdated] = useState(
      getUpdatedErrorIds().indexOf(initialData.id) > -1
   );

   const [createShipmentError, { loading }] = useMutation(
      UPDATE_SHIPMENT_ERROR,
      {
         onCompleted: () => {
            Alert('success', 'Saved.');
            if (!hasUpdated) {
               appendUpdatedErrorId(initialData.id);
               setHasUpdated(true);
               shipmentDidUpdate();
            }
         },
         onError: (err) => Alert('error', err.message),
      }
   );

   function checkRequiredFieldsForError(error) {
      const requiredString = error.tags
         .map((i) => i.name)
         .join('')
         .toLowerCase()
         .replace(/\s+/g, '');
      setFactoriesRequired(requiredString.indexOf('factoryrelated') > -1);
      setProductsRequired(requiredString.indexOf('productrelated') > -1);
      if (initialData.oldError.errorCategory === null) {
         setShipmentInvoiceRowIds(
            initialData.oldProductErrors.map((row) => row.shipmentInvoiceRow.id)
         );
      }
   }

   function onSelect(error) {
      setError(error);
      setFactoryIds([]);
      setShipmentInvoiceRowIds([]);
      checkRequiredFieldsForError(error);
   }

   useEffect(() => {
      if (initialData) {
         if (initialData.oldError.errorCategory === null) {
            setOldError(initialData.oldError);
         } else {
            setError(initialData.error);
            setFactoryIds(
               initialData.shipmentStatusErrorFactories.map((f) => f.factory.id)
            );
            setShipmentInvoiceRowIds(
               initialData.shipmentStatusErrorProductRows.map(
                  (row) => row.shipmentInvoiceRow.id
               )
            );
            const requiredString = initialData.error.tags
               .map((i) => i.name)
               .join('')
               .toLowerCase()
               .replace(/\s+/g, '');
            setFactoriesRequired(requiredString.indexOf('factoryrelated') > -1);
            setProductsRequired(requiredString.indexOf('productrelated') > -1);
         }
      }
   }, [initialData]);

   let isSaveButtonEnable = true;
   if (loading) isSaveButtonEnable = false;
   if (factoriesRequired && factoryIds.length === 0) isSaveButtonEnable = false;
   if (
      (productsRequired && shipmentInvoiceRowIds.length === 0) ||
      error === null
   )
      isSaveButtonEnable = false;

   return (
      <div className="relative">
         <div className="mt-2 lg:mt-4">
            {oldError ? (
               <div className="bg-gray-100 rounded-xl px-6 py-4">
                  <div className="font-bold text-lg mb-2">Old Error</div>
                  <ShipmentErrorComponent
                     code={oldError.code}
                     description={oldError.description}
                  />
                  {initialData.oldProductErrors.length > 0 ? (
                     <div className="mt-2">
                        <label>Products:</label>
                        <div className="mt-2">
                           {initialData.oldProductErrors.map((productError) => (
                              <div
                                 key={productError.id}
                                 className="flex items-center justify-between py-2 border-b"
                              >
                                 <div>
                                    {
                                       productError.shipmentInvoiceRow.product
                                          .name
                                    }
                                 </div>
                                 <div>
                                    &times;{' '}
                                    {productError.shipmentInvoiceRow.qty}
                                 </div>
                              </div>
                           ))}
                        </div>
                     </div>
                  ) : (
                     <div className="mt-2">
                        <label>No Error Products.</label>
                     </div>
                  )}
                  <div className="whitespace-pre-wrap mt-2">
                     <label>Notes</label>: {initialData.notes}
                  </div>
               </div>
            ) : null}
            <div className="relaitve mt-4 flex items-center">
               <label className="mr-4 w-24">New Error:</label>
               <ErrorSelector
                  value={error ? error.id : 0}
                  onSelect={onSelect}
               />
            </div>
            {factoriesRequired && (
               <div className="relaitve mt-2 lg:mt-4 flex items-baseline">
                  <label className="mr-4 w-24">Factories:</label>
                  <FactoriesSelector
                     value={factoryIds}
                     onSelect={(value) => setFactoryIds(value)}
                  />
               </div>
            )}
            {productsRequired && (
               <div className="relaitve mt-2 lg:mt-4 flex items-start">
                  <label className="mr-4 w-24 pt-2">Products:</label>
                  <div className="flex-1">
                     <ErrorProductsSelector
                        shipmentInvoices={shipmentInvoices}
                        value={shipmentInvoiceRowIds}
                        onSelect={(value) => setShipmentInvoiceRowIds(value)}
                     />
                  </div>
               </div>
            )}
         </div>
         <div className="mt-4 flex items-baseline justify-between">
            <Button
               size="sm"
               disabled={!isSaveButtonEnable}
               loading={loading}
               title={loading ? 'Saving...' : 'Save'}
               border
               color="blue"
               onClick={() => {
                  createShipmentError({
                     variables: {
                        shipmentStatusId: shipmentStatusId,
                        shipmentStatusErrorId: initialData.id,
                        errorId: error.id,
                        shipmentInvoiceRowIds,
                        factoryIds,
                     },
                  });
               }}
            />
            {hasUpdated && (
               <div className="flex items-center text-green-500 z-10">
                  <div className="text-5xl">
                     <IoIosCheckmark />
                  </div>
                  <span className="text-base font-bold">Updated</span>
               </div>
            )}
         </div>
      </div>
   );
};

const ShipmentEditor = ({ shipment, shipmentDidUpdate }) => (
   <div className="mb-6 bg-white rounded-xl p-6">
      <ShipmentTitle
         target="_blank"
         link={`/shipment/shipments/${shipment.id}`}
         number={shipment.invoiceNumber}
         customerName={shipment.customer.name}
      />
      <div className="mt-4">
         {shipment.shipmentStatus
            .filter((s) => s.errors.length > 0)
            .map((shipmentStatus) => (
               <div
                  className="mt-4 pt-4 border-t border-gray-200"
                  key={shipmentStatus.id}
               >
                  <ShipmentStatusComponent status={shipmentStatus.status} />
                  <div>
                     {shipmentStatus.errors.map((shipmentStatusError) => (
                        <div
                           key={shipmentStatusError.id}
                           className="pb-4 mb-4 border-b"
                        >
                           <ShipmentErrorForm
                              shipmentStatusId={shipmentStatus.id}
                              shipmentInvoices={shipment.shipmentInvoices}
                              initialData={shipmentStatusError}
                              shipmentDidUpdate={shipmentDidUpdate}
                           />
                        </div>
                     ))}
                  </div>
               </div>
            ))}
      </div>
   </div>
);

const FETCH_ALL_SHIPMENTS = gql`
   query FETCH_ALL_SHIPMENTS {
      shipmentList {
         id
         created
         customer {
            id
            name
         }
         invoiceNumber
         shipmentInvoices {
            id
            shipmentInvoiceRows {
               id
               product {
                  id
                  name
               }
               qty
            }
         }
         shipmentStatus {
            id
            status
            errors {
               id
               notes
               error {
                  id
                  code: fullCode
                  description
                  tags {
                     id
                     name
                  }
               }
               shipmentStatusErrorFactories {
                  id
                  factory {
                     id
                     name
                  }
               }
               shipmentStatusErrorProductRows {
                  id
                  shipmentInvoiceRow {
                     id
                     product {
                        id
                        name
                     }
                  }
               }
               oldError: error {
                  id
                  code
                  description
                  errorCategory {
                     id
                  }
               }
               oldProductErrors: shipmentErrorProducts {
                  id
                  shipmentInvoiceRow {
                     id
                     product {
                        id
                        name
                     }
                     qty
                  }
               }
            }
         }
      }
   }
`;

function ShipmentUpdateErrors() {
   const { loading, error, data } = useQuery(FETCH_ALL_SHIPMENTS);
   const [updatedShipments, setUpdatedShipments] = useState(0);

   useEffect(() => {
      let res = getUpdatedErrorIds();
      if (res) setUpdatedShipments(res.length);
   }, []);

   if (error) return <Errors error={error} />;
   if (loading) return <Spinner text="Loading Shipments..." />;

   const shipments = data.shipmentList.filter((i) => {
      if (i.shipmentStatus.flatMap((s) => s.errors).length > 0) return true;
      return false;
   });

   function shipmentDidUpdate() {
      setUpdatedShipments((prev) => prev + 1);
   }

   return (
      <PageContainer
         title="Update Shipment Errors"
         subtitle="This is a temporary page for updating the old error code and related data."
         rightButtons={
            <div>
               Update Progress: {updatedShipments}/
               {shipments.reduce(
                  (prev, shipment) =>
                     prev +
                     shipment.shipmentStatus.reduce(
                        (prev, shipmentStatus) =>
                           prev + shipmentStatus.errors.length,
                        0
                     ),
                  0
               )}
            </div>
         }
         content={
            <div className="p-6">
               {shipments.map((shipment) => (
                  <ShipmentEditor
                     shipment={shipment}
                     key={shipment.id}
                     shipmentDidUpdate={shipmentDidUpdate}
                  />
               ))}
            </div>
         }
      />
   );
}

export default ShipmentUpdateErrors;
