import React, { useState } from "react";
import { searchByProp } from "utils/search";
import { Input } from "./Form";

function InputSelect({ className = "", options = [], onChange, onSelect, onBlur, value, renderOption, placeholder = "", inputClasses = {}, inputProps = {} }) {
  const [mouseOver, setMouseOver] = useState(false);
  const [inputFocus, setInputFocus] = useState(false);

  const showOptions = inputFocus ? true : mouseOver;

  return (
    <div className={`${className} ${showOptions ? "z-10" : "z-0"} relative`}>
      <Input
        placeholder={placeholder}
        className={`w-full relative z-10 ${inputClasses}`}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onFocus={() => setInputFocus(true)}
        onBlur={() => {
          setInputFocus(false);
          if (onBlur) onBlur();
        }}
        {...inputProps}
      />

      {showOptions && (
        <div
          className="absolute w-full rounded overflow-auto z-40 shadow max-h-72"
          onMouseOver={() => setMouseOver(true)}
          onMouseLeave={() => setMouseOver(false)}
        >
          {options
            .filter((option) => searchByProp(option, ["name"], value))
            .map((option, index) => (
              <div
                className="py-2 cursor-pointer border-b border-gray-200 dark:border-gray-700 px-4 bg-white dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700 w-full"
                key={index}
                onClick={() => {
                  setMouseOver(false);
                  onSelect(option);
                }}
              >
                {renderOption ? renderOption(option) : option.name}
              </div>
            ))}
        </div>
      )}
    </div>
  );
}

export default InputSelect;
