import { Input } from "components/Form";
import { useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { SAVE_SIMPLE_REPORT } from "../graphql";
import { useMutation } from "@apollo/client";
import { Alert } from "components/Toast";
import { parseError } from "apollo";
import { InlineSpinner } from "components/Spinner";
import CharlesButton from "components/charles/base";
import { useModals } from "ModalProvider";
import ConvertCodeView from "./ConvertCodeView";
import { FaChevronRight } from "react-icons/fa";
import { BsTrash } from "react-icons/bs";
import { QCReportContext } from "./FinalQcReportEditForm";

const BarcodesView = () => {
  const { report } = useContext(QCReportContext);
  let timerRef = useRef();
  const [barcodes, setBarcodes] = useState({
    barcodeInnerboxEan13List: report.barcodeInnerboxEan13.split(","),
    barcodeInnerboxUpcList: report.barcodeInnerboxUpc.split(","),
    barcodeOuterboxEan13: report.barcodeOuterboxEan13,
    barcodeOuterboxUpc: report.barcodeOuterboxUpc,
    barcodeCarton: report.barcodeCarton,
  });

  const modal = useModals();

  const newInputRefEan13 = useRef(null);
  let prevInputRefEan13Length = useRef(barcodes.barcodeInnerboxEan13List.length);

  const newInputRefUpc = useRef(null);
  let prevInputRefUpcLength = useRef(barcodes.barcodeInnerboxUpcList.length);

  const [save, { loading }] = useMutation(SAVE_SIMPLE_REPORT, {
    onError: (error) => Alert("error", parseError(error)),
    // onCompleted: () => Alert("success", "Saved."),
  });

  function onChange(key, value) {
    const newBarcodes = { ...barcodes, [key]: value };
    setBarcodes(newBarcodes);

    if (timerRef.current) clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      save({
        variables: {
          id: report.id,
          barcodeInnerboxEan13: newBarcodes.barcodeInnerboxEan13List.filter((i) => i !== "").join(","),
          barcodeInnerboxUpc: newBarcodes.barcodeInnerboxUpcList.filter((i) => i !== "").join(","),
          barcodeOuterboxEan13: newBarcodes.barcodeOuterboxEan13,
          barcodeOuterboxUpc: newBarcodes.barcodeOuterboxUpc,
          barcodeCarton: newBarcodes.barcodeCarton,
        },
      });
    }, 700);
  }

  function showConvertCode() {
    modal.present({
      title: "转换码",
      children: <ConvertCodeView />,
    });
  }

  useEffect(() => {
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, []);

  useLayoutEffect;

  useLayoutEffect(() => {
    if (barcodes.barcodeInnerboxEan13List.length > prevInputRefEan13Length.current) newInputRefEan13.current?.focus();
    prevInputRefEan13Length = barcodes.barcodeInnerboxEan13List.length;
  }, [barcodes.barcodeInnerboxEan13List.length]);

  useEffect(() => {
    if (barcodes.barcodeInnerboxUpcList.length > prevInputRefUpcLength.current) newInputRefUpc.current?.focus();
    prevInputRefUpcLength = barcodes.barcodeInnerboxUpcList.length;
  }, [barcodes.barcodeInnerboxUpcList]);

  function allBarcodesMatchIgnoreOrdering(product, barcodeField, checkBarcodes) {
    if (!product[barcodeField]) return true;

    const systemBarcodes = product[barcodeField].replace(/ /g, "").split(",");

    if (systemBarcodes.length !== checkBarcodes.length) return false;

    if (barcodeField === "barcodeInnerboxEan13") {
      // checked barcodes should be 13 digits
      for (let i = 0; i < checkBarcodes.length; i++) {
        if (checkBarcodes[i].length !== 13) return false;
      }
    } else if (barcodeField === "barcodeInnerboxUpc") {
      // checked barcodes should be 12 digits
      for (let i = 0; i < checkBarcodes.length; i++) {
        if (checkBarcodes[i].length !== 12) return false;
      }
    }

    for (let i = 0; i < checkBarcodes.length; i++) {
      let found = false;
      for (let j = 0; j < systemBarcodes.length; j++) {
        if (systemBarcodes[j].includes(checkBarcodes[i])) {
          found = true;
          break;
        }
      }
      if (!found) return false;
    }

    return true;
  }

  return (
    <div className="my-6">
      <div className="px-4 lg:px-6 mb-2 text-gray-500 flex space-x-4 items-center">
        <div>Barcodes 条码</div>
        <div className="relative">{loading && <InlineSpinner text={null} size={14} />}</div>
      </div>
      <div className="px-4 md:p-6 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 py-4 space-y-4">
        <div>
          <div>
            我们的产品小彩盒和大彩盒一般都会有两个条码，上面的条码是EAN13，下面的条码是UPC，请根据你们的产品条码填写或者扫描，下图是明确的说明，EAN13会有13位数，最后面会有一个"&gt;",
            UPC只有12位。如果产品只有一个条码，只需要输入一个条码即可，但是需要选择是UPC还是EAN13，判断凭据是位数以及最后一位是否是"&gt;"。
          </div>
          <img
            className="w-full max-w-xl mt-2"
            src="https://qiniu-waboba.cors.tech/b8c293e5f17c4609892adcf1667f5866-productFrontSide/da37cf88-fea7-4017-be9e-b24b1f1fbf6a-upc-vs-ean.jpg"
          />

          <div className="mt-4">
            <CharlesButton onClick={showConvertCode}>
              <div className="flex items-center space-x-2">
                <span>查看转换码</span>
                <FaChevronRight />
              </div>
            </CharlesButton>
          </div>
        </div>
        <div className="lg:flex lg:space-x-4 space-y-4 lg:space-y-0">
          <div className="flex flex-1 flex-col space-y-2">
            <label htmlFor="">Inner Box 小彩盒(EAN13)</label>

            {barcodes.barcodeInnerboxEan13List.map((item, index) => (
              <div key={index} className="flex justify-between items-center space-x-2">
                <Input
                  className="w-full"
                  value={item}
                  onChange={(e) =>
                    onChange(
                      "barcodeInnerboxEan13List",
                      barcodes.barcodeInnerboxEan13List.map((item, i) => (i === index ? e.target.value : item))
                    )
                  }
                  ref={index === barcodes.barcodeInnerboxEan13List.length - 1 ? newInputRefEan13 : null}
                />
                <CharlesButton
                  danger
                  onClick={() =>
                    onChange(
                      "barcodeInnerboxEan13List",
                      barcodes.barcodeInnerboxEan13List.filter((_, i) => i !== index)
                    )
                  }
                >
                  <BsTrash />
                </CharlesButton>
              </div>
            ))}
            <CharlesButton onClick={() => onChange("barcodeInnerboxEan13List", [...barcodes.barcodeInnerboxEan13List, ""])}>+ 增加条码</CharlesButton>

            {allBarcodesMatchIgnoreOrdering(report.product, "barcodeInnerboxEan13", barcodes.barcodeInnerboxEan13List) ? null : (
              <div className="text-red-500 text-xs font-semibold">条码跟系统不一致，如果你确认所扫的条码正确，请马上联系我们。</div>
            )}
          </div>
          <div className="flex flex-1 flex-col space-y-2">
            <label htmlFor="">Inner Box 小彩盒(UPC)</label>
            {barcodes.barcodeInnerboxUpcList.map((item, index) => (
              <div key={index} className="flex justify-between items-center space-x-2">
                <Input
                  className="w-full"
                  value={item}
                  onChange={(e) =>
                    onChange(
                      "barcodeInnerboxUpcList",
                      barcodes.barcodeInnerboxUpcList.map((item, i) => (i === index ? e.target.value : item))
                    )
                  }
                  ref={index === barcodes.barcodeInnerboxUpcList.length - 1 ? newInputRefUpc : null}
                />
                <CharlesButton
                  danger
                  onClick={() =>
                    onChange(
                      "barcodeInnerboxUpcList",
                      barcodes.barcodeInnerboxUpcList.filter((_, i) => i !== index)
                    )
                  }
                >
                  <BsTrash />
                </CharlesButton>
              </div>
            ))}
            <CharlesButton onClick={() => onChange("barcodeInnerboxUpcList", [...barcodes.barcodeInnerboxUpcList, ""])}>+ 增加条码</CharlesButton>

            {allBarcodesMatchIgnoreOrdering(report.product, "barcodeInnerboxUpc", barcodes.barcodeInnerboxUpcList) ? null : (
              <div className="text-red-500 text-xs font-semibold">条码跟系统不一致，如果你确认所扫的条码正确，请马上联系我们。</div>
            )}
          </div>
        </div>

        <div className="lg:flex lg:space-x-4 space-y-4 lg:space-y-0">
          <div className="flex flex-1 flex-col space-y-2">
            <label htmlFor="">Outer Box 大彩盒(EAN13)</label>
            <Input className="w-full" value={barcodes.barcodeOuterboxEan13} onChange={(e) => onChange("barcodeOuterboxEan13", e.target.value)} />
            {report.product.barcodeOuterboxEan13 &&
              report.product.barcodeOuterboxEan13 !== "" &&
              barcodes.barcodeOuterboxEan13 &&
              barcodes.barcodeOuterboxEan13 !== "" &&
              report.product.barcodeOuterboxEan13 !== barcodes.barcodeOuterboxEan13 && (
                <div className="text-red-500 text-xs font-semibold">条码跟系统不一致，如果你确认所扫的条码正确，请马上联系我们。</div>
              )}
          </div>
          <div className="flex flex-1 flex-col space-y-2">
            <label htmlFor="">Outer Box 大彩盒(UPC)</label>
            <Input className="w-full" value={barcodes.barcodeOuterboxUpc} onChange={(e) => onChange("barcodeOuterboxUpc", e.target.value)} />
            {report.product.barcodeOuterboxUpc &&
              report.product.barcodeOuterboxUpc !== "" &&
              barcodes.barcodeOuterboxUpc &&
              barcodes.barcodeOuterboxUpc !== "" &&
              report.product.barcodeOuterboxUpc !== barcodes.barcodeOuterboxUpc && (
                <div className="text-red-500 text-xs font-semibold">条码跟系统不一致，如果你确认所扫的条码正确，请马上联系我们。</div>
              )}
          </div>
        </div>

        <div className="flex space-x-4">
          <div className="flex flex-1 flex-col space-y-2">
            <label htmlFor="">Carton 外箱(UPC/EAN13/GTIN14)</label>
            <Input className="w-full" value={barcodes.barcodeCarton} onChange={(e) => onChange("barcodeCarton", e.target.value)} />
            {report.product.barcodeCarton1 &&
              report.product.barcodeCarton1 !== "" &&
              report.product.barcodeCarton2 &&
              report.product.barcodeCarton2 !== "" &&
              barcodes.barcodeCarton &&
              barcodes.barcodeCarton !== "" &&
              report.product.barcodeCarton1 !== barcodes.barcodeCarton &&
              report.product.barcodeCarton2 !== barcodes.barcodeCarton && (
                <div className="text-red-500 text-xs font-semibold">条码跟系统不一致，如果你确认所扫的条码正确，请马上联系我们。</div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BarcodesView;
