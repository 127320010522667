import { gql } from "@apollo/client";

export const FETCH_PROMO_IMAGE_COLLECTIONS = gql`
  query FETCH_PROMO_IMAGE_COLLECTIONS {
    promoImageCollections {
      id
      name
    }
  }
`;

export const FETCH_LOGOS = gql`
  query FETCH_LOGOS($collectionName: String) {
    logos(collectionName: $collectionName) {
      id
      name
      thumb: url(size: "300x300")
      url
      originFile
    }
  }
`;

export const SYNC_LOGOS_FROM_ONE_DRIVE = gql`
  mutation SYNC_LOGOS_FROM_ONE_DRIVE($collectionName: String!) {
    syncLogos(collectionName: $collectionName) {
      result
    }
  }
`;
