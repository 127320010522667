export function download(name, url) {
  const link = document.createElement("a");
  link.href = url;
  link.download = name;
  document.body.appendChild(link);
  link.click();
}

export function downloadSvg(svg, name) {
  console.log("downloadSvg");
  var serializer = new XMLSerializer();
  var source = serializer.serializeToString(svg);
  const url = "data:image/svg+xml;charset=utf-8," + encodeURIComponent(source);
  download(`${name}.svg`, url);
}

export function downloadImageFromCanvas(canvas, name, format) {
  const url = canvas.toDataURL(`image/${format}`);
  const link = document.createElement("a");
  link.href = url;
  link.download = `${name}.${format}`;
  document.body.appendChild(link);
  link.click();
}

export function isEmpty(obj) {
  if (obj) return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
  return true;
}
