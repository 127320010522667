export function createShipmentInvoicePoGroups(shipmentInvoice) {
   if (shipmentInvoice.pos && shipmentInvoice.pos.length > 0)
      return shipmentInvoice.pos.reduce(
         (res, po) => [
            ...res,
            {
               po,
               shipmentInvoiceRows: shipmentInvoice.shipmentInvoiceRows.filter(
                  (row) => row.purchaseOrder && row.purchaseOrder.id === po.id
               ),
            },
         ],
         []
      );
   return [{ shipmentInvoiceRows: shipmentInvoice.shipmentInvoiceRows }];
}
