import { gql, useQuery } from '@apollo/client';
import Errors from 'components/Errors';
import { InlineSpinner } from 'components/Spinner';

const OdooPurchaseOrderView = ({ order, lines }) => {
   console.log('order', order);
   if (order) return <Content order={order} lines={lines} />;
   // if (id) return <OdooPurchaseOrderViewLoader id={id} />;

   return null;
};

const Content = ({ order, lines }) => {
   const totalPrice = lines.reduce((res, line) => res + line.totalPrice, 0);

   return (
      <div className="space-y-6 text-xs">
         <div className="space-y-3">
            <div>
               <label className="pr-2">Partner: </label>
               <span>{order.partner_id[1]}</span>
            </div>

            <div>
               <label className="pr-2">Source Document: </label>
               <span>{order.origin}</span>
            </div>

            <div>
               <label className="pr-2">Receipt Date:</label>
               <span>{order.date_planned}</span>
            </div>
         </div>

         <div className="-mx-2">
            <table className="text-right">
               <thead>
                  <tr className="whitespace-nowrap">
                     <th>Products</th>
                     <th className="text-right">QTY</th>
                     <th className="text-right">Unit Price</th>
                     <th className="text-right">Subtotal</th>
                  </tr>
               </thead>
               <tbody>
                  {lines.map((line) => (
                     <tr key={line.id} className="border-y border-gray-100">
                        <td className="text-left">{line.name}</td>
                        <td>{line.qty}</td>
                        <td>{line.unitPrice.toFixed(2)}</td>
                        <td>{line.totalPrice.toFixed(2)}</td>
                     </tr>
                  ))}
               </tbody>
               <tfoot className="font-semibold text-base whitespace-nowrap">
                  <tr>
                     <td colSpan={3}>Total</td>
                     <td>{totalPrice.toFixed(2)}</td>
                  </tr>
               </tfoot>
            </table>
         </div>
      </div>
   );
};

const FETCH_ODOO_PURCHASE_ORDER = gql`
   query FETCH_ODOO_PURCHASE_ORDER($id: Int!) {
      odooPurchaseOrder(id: $id)
   }
`;

const OdooPurchaseOrderViewLoader = ({ id, lines }) => {
   const { loading, error, data } = useQuery(FETCH_ODOO_PURCHASE_ORDER, {
      variables: { id },
   });

   if (loading) return <InlineSpinner className="p-10" />;
   if (error) return <Errors error={error} />;

   const order = JSON.parse(data.odooPurchaseOrder);

   return <Content order={order} lines={lines} />;
};

export default OdooPurchaseOrderView;
