import Errors from "components/Errors";
import Spinner from "components/Spinner";
import Page from "components/Page";
import { Button, NavigationBar } from "components/base";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import StockDetail from "./StockDetail";
import NewStockForm from "../NewStockForm";
import XLSX from "xlsx";
import { FETCH_AMAZON_STOCK_CATEGORY } from "../graphql";
import StockCategoryForm from "./StockCategoryForm";
import { useModals } from "ModalProvider";
import moment from "moment";
import { FiCircle } from "react-icons/fi";

const StockCategoryDetail = () => {
  const { id } = useParams();
  const { loading, error, data } = useQuery(FETCH_AMAZON_STOCK_CATEGORY, {
    variables: { id },
  });

  const { present, hide } = useModals();

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const category = data.amazonStockCategory;

  const stocks = category.stocks
    .map((stock) => {
      const totalSold = stock.initialQty - stock.latestQty;
      const rejectedLines = stock.purchaseOrderLines.filter((line) => line.actionType === "reject" && stock.qtyPerMaster <= line.qtyRequested);

      const totalRejectForNoStock = rejectedLines.reduce((res, line) => res + line.qtyRequested, 0);
      return {
        ...stock,
        totalRejectForNoStock,
        totalSold,
        sales: stock.unitCost * totalSold,
      };
    })
    .sort((a, b) => a.number.localeCompare(b.number));

  const acceptedLines = stocks
    .flatMap((s) => s.purchaseOrderLines)
    .filter((l) => l.actionType === "accept")
    .map((i) => ({ ...i, date: new Date(i.purchaseOrder.orderDate) }));
  const orderLines = stocks.flatMap((stock) =>
    stock.orderLines.filter((i) => ["PLANNING", "HANDLED"].includes(i.order.state)).map((i) => ({ ...i, date: i.order.createdAt }))
  );
  const editRecords = stocks.flatMap((s) => s.editRecords);
  const allLines = [...acceptedLines, ...orderLines, ...editRecords];

  const datesWithSales = allLines
    .map((i) => i.date)
    .sort((a, b) => a - b)
    .map((d) => moment(d).format("YYYY-MM-DD"))
    .reduce((res, d) => (res.includes(d) ? res : [...res, d]), []);

  const stockQtyForDate = (stock, dateString) => {
    const datePurchaseOrderLines = stock.purchaseOrderLines.filter((l) => l.purchaseOrder.orderDate === dateString);
    const dateOrderLines = stock.orderLines.filter((i) => moment(i.order.createdAt).format("YYYY-MM-DD") === dateString);

    const editRecords = stock.editRecords.filter((i) => moment(i.date).format("YYYY-MM-DD") === dateString);

    return (
      datePurchaseOrderLines.reduce((res, line) => (res += line.actionQty), 0) +
      editRecords.reduce((res, i) => res + i.qty, 0) +
      dateOrderLines.reduce((res, i) => res + i.qty, 0)
    );
  };

  const totalStockValueForDate = (date) => stocks.reduce((res, stock) => (res += stockQtyForDate(stock, date) * stock.unitCost), 0);

  const totalSales = () => stocks.reduce((res, stock) => (res += stock.sales), 0);

  function exportXlxs() {
    const data = [
      ...stocks.map((s) => ({
        product: `[ ${s.number} ] ${s.name}`,
        asin: s.asin,
        barcode: s.barcode,
        qtyPerMaster: s.qtyPerMaster,
        unitCost: s.unitCost,
        initialStock: s.initialStock,
        latestQty: s.latestQty,
        ...datesWithSales.reduce(
          (res, date) => ({
            ...res,
            [date]: stockQtyForDate(s, date),
          }),
          {}
        ),
        totalSold: s.totalSold,
        totalRejectForNoStock: s.totalRejectForNoStock,
        sales: s.sales.toFixed(2),
      })),
      {
        product: "Total Value",
        asin: "",
        barcode: "",
        qtyPerMaster: "",
        unitCost: "",
        initialStock: "",
        latestQty: "",
        ...datesWithSales.reduce(
          (res, date) => ({
            ...res,
            [date]: totalStockValueForDate(date),
          }),
          {}
        ),
        totalRejectForNoStock: "",
        totalSold: "",
        sales: totalSales().toFixed(2),
      },
    ];
    const sheet = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, sheet, "stock");
    XLSX.writeFile(wb, "stock.xlsx");
  }

  return (
    <div className="flex-1 overflow-auto flex flex-col">
      <NavigationBar
        title={`[${category.regions}] ${category.name}`}
        rightButtons={
          <div className="flex items-center space-x-4">
            <Button
              title="+ Stock"
              onClick={() =>
                present({
                  title: "Create Stock",
                  center: true,
                  children: <NewStockForm categoryId={category.id} hide={hide} />,
                })
              }
            />
            <Button
              title="Duplicate"
              onClick={() =>
                present({
                  title: "Duplicate Stock Category",
                  center: true,
                  children: <StockCategoryForm fromStockCategory={category} hide={hide} />,
                })
              }
            />
            <Button title="Export XLXS" onClick={exportXlxs} />
          </div>
        }
      />
      <div className="flex-1 overflow-auto">
        <table className="bg-white dark:bg-gray-800 rounded whitespace-nowrap">
          <thead>
            <tr className="text-left">
              <th className="sticky top-0 left-0 z-10 bg-white dark:bg-gray-800 px-6 py-3">Product</th>
              <th className="sticky top-0 bg-white dark:bg-gray-800 px-4">ASIN</th>
              <th className="sticky top-0 bg-white dark:bg-gray-800 px-4">Barcode</th>
              <th className="sticky top-0 bg-white dark:bg-gray-800 px-4">odooId</th>
              <th className="sticky top-0 bg-white dark:bg-gray-800 px-4 text-right">Qty / Master</th>
              <th className="sticky top-0 bg-white dark:bg-gray-800 px-4 text-right">Unit Cost</th>
              <th className="sticky top-0 bg-white dark:bg-gray-800 px-4 text-right">Inital Stock</th>
              <th className="sticky top-0 bg-white dark:bg-gray-800 px-4 text-right">Latest Stock</th>
              {datesWithSales.map((d, index) => (
                <th className="sticky top-0 bg-white dark:bg-gray-800 px-4 text-right" key={index}>
                  {d}
                </th>
              ))}
              <th className="sticky top-0 bg-white dark:bg-gray-800 px-4 text-right">Total Reject for No Stock</th>
              <th className="sticky top-0 bg-white dark:bg-gray-800 px-4 text-right">Total Sold</th>
              <th className="sticky top-0 bg-white dark:bg-gray-800 px-6 text-right">Sales</th>
            </tr>
          </thead>
          <tbody>
            {stocks.map((stock) => (
              <tr
                key={stock.id}
                className="border-t dark:border-gray-700 cursor-pointer hover:text-blue-600 active:text-blue-700"
                onClick={() =>
                  present({
                    title: "Stock Detail",
                    children: <StockDetail stock={stock} hide={hide} />,
                  })
                }
              >
                <td className="px-6 sticky left-0 bg-white dark:bg-gray-800">
                  <div className="flex space-x-2 items-center">
                    {stock.odooId ? <FiCircle className="text-purple-600" size={14} /> : null}
                    <div>
                      {stock.number ? `[${stock.number}] ` : null}
                      {stock.name}
                    </div>
                  </div>
                </td>
                <td className="px-4">{stock.asin}</td>
                <td className="px-4">{stock.barcode}</td>
                <td className="px-4">{stock.odooId}</td>
                <td className="px-4 text-right">{stock.qtyPerMaster}</td>
                <td className="px-4 text-right">{stock.unitCost}</td>
                <td className="px-4 text-right">{stock.initialQty}</td>
                <td className="px-4 text-right">{stock.latestQty}</td>
                {datesWithSales.map((date, index) => (
                  <td className="px-4 text-right" key={index}>
                    {stockQtyForDate(stock, date)}
                  </td>
                ))}
                <td className="px-4 text-right">{stock.totalRejectForNoStock}</td>
                <td className="px-4 text-right">{stock.totalSold}</td>
                <td className="px-6 text-right">€ {stock.sales.toFixed(2)}</td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr className="border-t dark:border-gray-700 font-semibold text-base">
              <td className="sticky left-0 bottom-0 bg-gray-50 dark:bg-gray-800 py-3 px-6 z-10">Total Value</td>
              <td colSpan={7} className="sticky bg-gray-50 dark:bg-gray-800 py-3 bottom-0 px-4"></td>
              {datesWithSales.map((date, index) => (
                <td className="sticky bg-gray-50 dark:bg-gray-800 py-3 bottom-0 text-right p-2" key={index}>
                  € {totalStockValueForDate(date).toFixed(2)}
                </td>
              ))}
              <td className="sticky bg-gray-50 dark:bg-gray-800 py-3 bottom-0 p-2"></td>
              <td className="sticky bg-gray-50 dark:bg-gray-800 py-3 bottom-0 p-2"></td>
              <td className="sticky bg-gray-50 dark:bg-gray-800 py-3 bottom-0 p-2 px-6 text-right">€ {totalSales().toFixed(2)}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

export default StockCategoryDetail;
