import { useQuery, useMutation, gql } from '@apollo/client';
import { formatDate } from 'react-day-picker/moment';
import writtenNumber from 'written-number';
import { Button, ButtonX } from 'components/base';
import Errors from 'components/Errors';
import { DatePicker, Input, Select, Text } from 'components/Form';
import Spinner from 'components/Spinner';
import React, { useState } from 'react';
import { Alert } from 'components/Toast';
import http from 'utils/http';

const sayTotal = (value) => {
   let s = '';
   const [a, b] = (value + '').split('.');
   if (parseInt(a, 10) > 0) {
      s = writtenNumber(a);
   }
   if (parseInt(b, 10) > 0) {
      s += ' AND ' + writtenNumber(b) + ' CENTS.';
   }
   return s;
};

const FETCH_AMAZON_PURCHASE_ORDERS = gql`
   query FETCH_AMAZON_PURCHASE_ORDERS(
      $odooSalesOrderNames: [String]
      $id: ID!
   ) {
      amzVcPurchaseOrders(odooSalesOrderNames: $odooSalesOrderNames) {
         id
         odooSalesOrderId
      }
      alcShipment(id: $id) {
         id
         number
         loadingNumber
         orders
         parcels {
            id
            number
            weight
            qty
            lines {
               id
               itemNumber
               qty
            }
         }
      }
   }
`;

const InvoiceFormLoader = ({ shipment }) => {
   const { loading, error, data } = useQuery(FETCH_AMAZON_PURCHASE_ORDERS, {
      variables: {
         odooSalesOrderNames: shipment.orders.split(',').map((i) => `SO${i}`),
         id: shipment.id,
      },
      fetchPolicy: 'network-only',
   });

   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;

   if (data.amzVcPurchaseOrders.length === 0)
      return (
         <div className="px-10">
            Orders not found in WIS. Make sure this order is created through
            WIS.
         </div>
      );

   return (
      <ShipmentInvoiceForm
         soIds={data.amzVcPurchaseOrders.map((i) => i.odooSalesOrderId)}
         shipment={data.alcShipment}
      />
   );
};

const ShipmentInvoiceForm = ({ soIds, shipment }) => {
   const { loading, error, data } = useQuery(FETCH_MULTIPLE_SO_DETAIL, {
      variables: { ids: soIds },
   });

   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;

   const soLines = data.odooMultipleSoDetailSimple.flatMap((i) =>
      i.lines.map((line) => ({ ...line, so: i }))
   );

   const invoiceNumber = data.odooMultipleSoDetailSimple
      .map((i) => i.name)
      .join('_');

   const parcels = shipment.parcels.map((parcel, parcelIndex) => {
      const lines = parcel.lines.map((line) => {
         const soLine = soLines.find(
            (i) =>
               i.product.number.toLowerCase() === line.itemNumber.toLowerCase()
         );
         const hsCode =
            soLine &&
            soLine.product &&
            soLine.product.productLine &&
            soLine.product.productLine.hsCodeForDestination
               ? soLine.product.productLine.hsCodeForDestination
               : '';
         const priceUnitWithCurrency = soLine ? `€ ${soLine.priceUnit}` : ' - ';
         const priceSubtotal = soLine ? soLine.priceUnit * line.qty : 0;
         const priceSubtotalWithCurrency = soLine
            ? `€ ${priceSubtotal.toFixed(2)}`
            : ' - ';
         return {
            ...line,
            soLine,
            hsCode,
            priceSubtotal,
            priceUnitWithCurrency,
            priceSubtotalWithCurrency,
         };
      });

      const combinedNumbers =
         invoiceNumber + (parcelIndex > 0 ? `-${parcelIndex}` : '');

      const linesWithSo = lines.filter((i) => i.soLine);
      const so = linesWithSo.length > 0 ? linesWithSo[0].soLine.so : null;
      if (so) {
         return {
            ...parcel,
            partnerName: so.partnerName,
            partnerShipping: so.partnerShipping,
            currencyName: so.currencyName,
            combinedNumbers,
            packingListNumber: `${shipment.number} / ${shipment.loadingNumber}`,
            lines,
         };
      }
      return { ...parcel, combinedNumbers };
   });

   const parceLineItemNumbers = parcels
      .flatMap((i) => i.lines)
      .map((i) => i.itemNumber);
   const missingSoLines = soLines.filter(
      (soLine) => !parceLineItemNumbers.includes(soLine.product.number)
   );

   const invoices = parcels;

   return (
      <div className="relative">
         {parcels.length === 0 ? (
            <div>No Parcel data for this shipment yet.</div>
         ) : (
            <div className="space-y-8">
               {invoices.map((invoice, invoiceIndex) => (
                  <InvoiceForm
                     key={invoiceIndex}
                     invoice={invoice}
                     soLines={soLines}
                     missingSoLines={missingSoLines}
                  />
               ))}
            </div>
         )}
      </div>
   );
};

const InvoiceForm = ({ invoice, missingSoLines }) => {
   const [invoiceDate, setInvoiceDate] = useState(new Date());
   const [notes, setNotes] = useState(`SSCC: ${invoice.number}`);
   const [shippingTerms, setShippingTerms] = useState('DELIVERED DUTY PAID');
   const [packingListNumber, setPackingListNumber] = useState(
      invoice.packingListNumber
   );
   const [exportingPDF, setExportingPDF] = useState(false);
   const [generateDocument] = useMutation(GENERATE_DOCUMENT, {
      onError: (error) => Alert('error', error.message),
   });
   const [newLines, setNewLines] = useState([]);

   const computedNewLines = newLines.map((line) => {
      const priceUnitWithCurrency = `€ ${line.priceUnit.toFixed(2)}`;
      const priceSubtotalWithCurrency = `€ ${(
         line.priceUnit * line.qty
      ).toFixed(2)}`;

      return { ...line, priceUnitWithCurrency, priceSubtotalWithCurrency };
   });

   const validLines = invoice.lines.filter((i) => i.soLine);
   const missingParcelLines = invoice.lines.filter((i) => !i.soLine);

   const finalLines = [...validLines, ...computedNewLines];

   const totalQty = finalLines.reduce((res, line) => res + line.qty, 0);
   const soTotal = finalLines.reduce(
      (prev, line) =>
         line.soLine ? prev + line.soLine.priceUnit * line.qty : prev,
      0
   );

   const totalWithCurrency = `€ ${soTotal.toFixed(2)}`;
   const sayTotalString = `SAY TOTAL EURO ${sayTotal(
      soTotal.toFixed(2)
   )}`.toUpperCase();

   const consignee = `WABOBA AB
Sweden
Hornsgatan 110
117 26 Stockholm
Sweden
VAT: GB324122352`;

   const shippingAddress = `${invoice.partnerName}
${invoice.partnerShipping ? invoice.partnerShipping.street : ''}
${invoice.partnerShipping ? invoice.partnerShipping.street2 : ''}
${invoice.partnerShipping ? invoice.partnerShipping.city : ''} ${
      invoice.partnerShipping ? invoice.partnerShipping.zip : ''
   }
${invoice.partnerShipping ? invoice.partnerShipping.countryName : ''}`;

   const computedInvoice = {
      ...invoice,
      lines: finalLines
         .filter((i) => i.soLine)
         .map((line) => ({
            hsCode: line.hsCode,
            name: line.soLine.name,
            priceUnitWithCurrency: line.priceUnitWithCurrency,
            priceSubtotalWithCurrency: line.priceSubtotalWithCurrency,
            qty: line.qty,
         })),
   };

   const variables = {
      docType: 'invoice_from_alc_pdf',
      name: invoice.number,
      data: JSON.stringify(
         {
            invoice: computedInvoice,
            invoiceDate: formatDate(invoiceDate, 'YYYY-MM-DD'),
            consignee,
            shippingAddress,
            packingListNumber,
            notes,
            shippingTerms,
            totalQty,
            totalWithCurrency,
            sayTotalString,
         },
         '',
         4
      ),
   };

   function exportPdf() {
      setExportingPDF(true);
      generateDocument({ variables })
         .then((res) =>
            http.get(
               `${process.env.REACT_APP_SERVER_BASE_URL}generated-document/${res.data.generateDocument.document.id}/?pdf=1`,
               { responseType: 'blob', withCredentials: true }
            )
         )
         .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', invoice.combinedNumbers + '.pdf');
            document.body.appendChild(link);
            link.click();
            setExportingPDF(false);
         })
         .catch((e) => {
            Alert('error', e.message);
            setExportingPDF(false);
         });
   }

   function onChangeNewLine(index, field, value) {
      setNewLines((prev) =>
         prev.map((prevLine, prevIndex) => {
            if (prevIndex !== index) return prevLine;
            return { ...prevLine, [field]: value };
         })
      );
   }

   function onSelectNewLineSoLine(index, soLineId) {
      if (soLineId === '0') return;
      const soLine = missingSoLines.find((i) => i.id === parseInt(soLineId));
      setNewLines((prev) =>
         prev.map((prevLine, prevIndex) => {
            if (prevIndex !== index) return prevLine;
            return {
               soLine,
               hsCode:
                  soLine.product && soLine.product.productLine
                     ? soLine.product.productLine.hsCodeForDestination
                     : ' - ',
               qty: soLine.qty,
               priceUnit: soLine.priceUnit,
            };
         })
      );
   }

   return (
      <div className="bg-white dark:bg-gray-900 dark:bg-opacity-60 p-6 rounded-2xl">
         <div className="space-y-4 px-2">
            <div className="flex space-x-8 text-base">
               <div>
                  <label className="pr-3">Inv No.</label>
                  {invoice.combinedNumbers}
               </div>
               <div>
                  <label className="pr-3">SSCC:</label>
                  {invoice.number}
               </div>
            </div>

            <div className="flex space-x-8">
               <div className="flex justify-end items-center space-x-3">
                  <label className="mr-4">Invoice Date: </label>
                  <DatePicker
                     value={invoiceDate}
                     onDayChange={(selectedDay) => {
                        setInvoiceDate(selectedDay);
                     }}
                  />
               </div>

               <div className="flex items-center space-x-3">
                  <label htmlFor="">Shipping Terms: </label>
                  <Input
                     value={shippingTerms}
                     onChange={(e) => setShippingTerms(e.target.value)}
                  />
               </div>

               <div className="flex justify-end items-center">
                  <label className="mr-4">Packinglist Number: </label>
                  <Input
                     className="w-auto text-center"
                     value={packingListNumber}
                     onChange={(e) => setPackingListNumber(e.target.value)}
                  />
               </div>
            </div>
         </div>

         <table className="mt-4">
            <thead className="whitespace-nowrap">
               <tr className="border-b border-t dark:border-gray-700">
                  <th className="text-left">Item Description</th>
                  <th className="text-left">HS Code</th>
                  <th className="text-right">Qty</th>
                  <th className="text-right">Weight-kg</th>
                  <th className="text-right">Price/pc</th>
                  <th className="text-right">Total Price</th>
               </tr>
            </thead>
            <tbody>
               {validLines.map((line, index) => (
                  <tr key={index}>
                     <td>{line.soLine.name}</td>
                     <td>{line.hsCode}</td>
                     <td className="text-right">{line.qty}</td>
                     <td></td>
                     <td className="text-right">
                        {line.priceUnitWithCurrency}
                     </td>
                     <td className="text-right">
                        {line.priceSubtotalWithCurrency}
                     </td>
                  </tr>
               ))}

               {computedNewLines.map((line, index) => (
                  <tr key={index}>
                     <td>
                        <div className="flex items-center space-x-2">
                           <ButtonX
                              onClick={() =>
                                 setNewLines((prev) =>
                                    prev.filter(
                                       (_, prevIndex) => prevIndex !== index
                                    )
                                 )
                              }
                           />
                           <Select
                              value={line.soLine ? line.soLine.id : '0'}
                              onChange={(e) =>
                                 onSelectNewLineSoLine(index, e.target.value)
                              }
                           >
                              <option value="0" disabled>
                                 {' '}
                                 -- Select a Product --{' '}
                              </option>
                              {missingSoLines.map((line) => (
                                 <option key={line.id} value={line.id}>
                                    [{line.so.name}] {line.name}
                                 </option>
                              ))}
                           </Select>
                        </div>
                     </td>
                     <td>{line.hsCode}</td>
                     <td className="text-right">
                        <Input
                           className="text-right"
                           value={line.qty}
                           onChange={(e) =>
                              onChangeNewLine(
                                 index,
                                 'qty',
                                 parseInt(e.target.value) || 0
                              )
                           }
                        />
                     </td>
                     <td></td>
                     <td className="text-right">
                        {line.priceUnitWithCurrency}
                     </td>
                     <td className="text-right">
                        {line.priceSubtotalWithCurrency}
                     </td>
                  </tr>
               ))}

               {missingParcelLines.map((line, index) => (
                  <tr
                     key={index}
                     className="bg-pink-100 dark:bg-pink-900 dark:bg-opacity-30 text-pink-600"
                  >
                     <td>Missing: {line.itemNumber}</td>
                     <td>{line.hsCode}</td>
                     <td className="text-right">{line.qty}</td>
                     <td></td>
                     <td className="text-right">
                        {line.priceUnitWithCurrency}
                     </td>
                     <td className="text-right">
                        {line.priceSubtotalWithCurrency}
                     </td>
                  </tr>
               ))}

               {missingSoLines.length > 0 && missingParcelLines.length > 0 ? (
                  <tr>
                     <td colSpan="6">
                        <Button
                           title="+ Add Item"
                           onClick={() =>
                              setNewLines((prev) => [
                                 ...prev,
                                 {
                                    itemNumber: '',
                                    qty: 0,
                                    priceUnit: 0,
                                    priceSubtotal: 0,
                                 },
                              ])
                           }
                        />
                     </td>
                  </tr>
               ) : null}

               <tr className="text-base font-bold border-t border-b dark:border-gray-700 whitespace-nowrap">
                  <td className="py-3" colSpan={2}>
                     Total:
                  </td>
                  <td className="text-right">{totalQty}</td>
                  <td className="text-right">{invoice.weight}</td>
                  <td></td>
                  <td className="text-right">{totalWithCurrency}</td>
               </tr>
            </tbody>
         </table>

         <div className="mt-6">
            <label htmlFor="">Notes:</label>
            <Text
               className="mt-2"
               value={notes}
               onChange={(e) => setNotes(e.target.value)}
            />
         </div>

         <div className="mt-4 flex justify-end">
            <Button
               disabled={exportingPDF}
               loading={exportingPDF}
               title="Generate Invoice"
               bold
               size="lg"
               onClick={exportPdf}
            />
         </div>
      </div>
   );
};

const FETCH_MULTIPLE_SO_DETAIL = gql`
   query FETCH_MULTIPLE_SO_DETAIL($ids: [Int]) {
      odooMultipleSoDetailSimple(ids: $ids) {
         id
         name
         partnerId
         partnerName
         partner {
            id
            street
            street2
            city
            zip
            countryId
            countryName
            vat
         }
         partnerShipping {
            id
            street
            street2
            city
            zip
            countryId
            countryName
            vat
         }
         dateOrder
         clientOrderRef
         incotermName
         amountTax
         amountUntaxed
         amountTax
         amountTotal
         currencyName
         lines: orderLines {
            id
            name
            priceUnit
            priceSubtotal
            priceTotal
            qty: productUomQty
            product {
               id
               name
               number
               itemWeight
               productLine {
                  hsCodeForDestination(destination: "EU")
               }
            }
         }
      }
   }
`;

const GENERATE_DOCUMENT = gql`
   mutation GENERATE_DOCUMENT(
      $name: String!
      $docType: String!
      $data: String!
   ) {
      generateDocument(name: $name, docType: $docType, data: $data) {
         document {
            id
            name
            docType
            data
         }
      }
   }
`;

export default InvoiceFormLoader;
