/**
 * Generates a random integer between the specified min and max, inclusive.
 *
 * @param {number} min - The minimum value, inclusive.
 * @param {number} max - The maximum value, inclusive.
 * @returns {number} A random integer between min and max, inclusive.
 */
export const getRandomIntInclusive = (min, max) =>
   Math.floor(Math.random() * (Math.floor(max) - Math.ceil(min) + 1)) +
   Math.ceil(min);

/**
 * Converts a string to an integer.
 *
 * @param {string} str - The input string to be converted.
 * @returns {number} The parsed integer or 0 if the input is not a valid number.
 */
export const toInt = (str) => (isNaN(parseInt(str)) ? 0 : parseInt(str));

/**
 * Converts a string to a floating-point number.
 *
 * @param {string} str - The input string to be converted.
 * @returns {number} The parsed floating-point number or 0 if the input is not a valid number.
 */
export const toFloat = (str) => (isNaN(parseFloat(str)) ? 0 : parseFloat(str));
