import React from 'react';
import { NavigationLink } from '../../components/base.js';

export function LeftButtons() {
   return (
      <div className="flex space-x-4">
         <NavigationLink to="/shipment/shipments" title="Shipments" />
         <NavigationLink to="/shipment/doc/packinglist" title="Packing List" />
         <NavigationLink to="/shipment/doc/customform" title="Custom Form" />
         <NavigationLink
            to="/shipment/doc/commercial-invoice"
            title="Commercial Invoice"
         />
      </div>
   );
}

export function RightButtons() {
   return (
      <div className="flex space-x-4">
         <NavigationLink to="/shipment/overview" title="Overview" />
         <NavigationLink to="/shipment/reports" title="Reports" />
         <NavigationLink to="/shipment/error-codes" title="Error Codes" />
         <NavigationLink to="/shipment/export" title="Export" />
      </div>
   );
}
