import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import Spinner from '../../../components/Spinner.js';
import Errors from '../../../components/Errors.js';
import Calculator from './Calculator';

const FETCH_CALCULATE_HISTORY = gql`
   query FETCH_CALCULATE_HISTORY($id: ID!) {
      calculateHistory(id: $id) {
         id
         name
         rows {
            id
            product {
               id
               name
            }
            qty
            ctn
            pallet
            gw
            cbm
         }
         totalQty
         totalCtn
         totalPallet
         totalGw
         totalCbm
      }
   }
`;

function Edit() {
   const { id } = useParams();
   const { loading, error, data } = useQuery(FETCH_CALCULATE_HISTORY, {
      variables: { id },
      fetchPolicy: 'cache-and-network',
   });
   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;
   return <Calculator initialItem={data.calculateHistory} />;
}

export default Edit;
