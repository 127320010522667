import { graphql } from 'msw';
import { faker } from '@faker-js/faker';


const allUsers = ['Alice', 'Bob', 'Charlie', 'David', 'Eve'].map((name, index) => ({ id: index + 1, username: name, email: `${name.toLowerCase()}@example.com` }));
const allReceiversByDestId = ['Frank', 'Grace', 'Helen'].map((name, index) => ({ user: { id: index + 1, username: name, email: `${name.toLowerCase()}@example.com` } }));
let allAddresses = [
  {
    id: 1,
    addressDetail: faker.location.country() + " " + faker.location.streetAddress(),
    shortName: 'test address 1'
  },
  {
    id: 2,
    addressDetail: faker.location.country() + " " + faker.location.streetAddress(),
    shortName: 'test address 2'
  }];
let allItems = [
  // just used for UI development
  {
    id: 1,
    name: 'test product sample',
    quantity: 1,
    senders: [allUsers[0], allUsers[1]],
    receivers: [allReceiversByDestId[0], allReceiversByDestId[1]],
    description: 'test description',
  },
  {
    id: 2,
    name: 'test product sample 2',
    quantity: 2,
    senders: [allUsers[2], allUsers[3]],
    receivers: [allReceiversByDestId[1], allReceiversByDestId[2]],
    description: 'test description 2',
  },
  {
    id: 3,
    name: 'test product sample 3',
    quantity: 3,
    senders: [allUsers[0], allUsers[4]],
    receivers: [allReceiversByDestId[0], allReceiversByDestId[2]],
    description: 'test description 3',
  }
];
let allImages = [
  // for testing UI
  // {
  //   id: 1,
  //   name: 'test image 1',
  //   url: 'https://qiniu-waboba.cors.tech/fc8829b1b6a243f3a9a08656395335fc-packageTracking/cow.jpeg',
  // },
  // {
  //   id: 1,
  //   name: 'test image 2',
  //   url: 'https://qiniu-waboba.cors.tech/29e982eb46854ea3a753ea75c5077429-packageTracking/cow_and_bird.jpeg',
  // },
];
let allPackages = [
  {
    id: 3,
    destination: {
      id: allAddresses[0].id,
      addressDetail: allAddresses[0].addressDetail,
      shortName: allAddresses[0].shortName
    },
    status: 'SHIPPED',
    trackingNumber: faker.string.alphanumeric({ length: { min: 10, max: 18 } }),
    method: 'Fedex',
    createdAt: faker.date.recent(),
    items: []
  },
  {
    id: 4,
    destination: {
      id: allAddresses[1].id,
      addressDetail: allAddresses[1].addressDetail,
      shortName: allAddresses[1].shortName
    },
    status: 'DELIVERED',
    trackingNumber: faker.string.alphanumeric({ length: { min: 10, max: 18 } }),
    method: 'DHL',
    createdAt: faker.date.recent(),
    items: allItems,
  },
];

const packageTrackingHandlers = [
  // * package & package address

  graphql.query('FETCH_ALL_PACKAGES', (req, res, ctx) => {
    return res(
      ctx.data({
        allPackages: allPackages
      })
    );
  }),

  graphql.query('FETCH_COURIER_MATCH_INFO', (req, res, ctx) => {
    const { trackingNumber, method } = req.variables;

    return res(
      ctx.data({
        courierMatchInfo: {
          isMatched: trackingNumber.startsWith(method), // just for quick test
        }
      })
    );
  }),

  graphql.mutation('CREATE_PACKAGE', (req, res, ctx) => {
    const { destinationId, trackingNumber, method } = req.variables;

    // mock package creation
    const newPackage = {
      id: faker.number.int({ min: 6, max: 100 }),
      destination: {
        id: destinationId,
        addressDetail: allAddresses[0].addressDetail,
        shortName: allAddresses[0].shortName,
      },
      status: 'SHIPPED',
      trackingNumber: trackingNumber,
      method: method.toUpperCase(),
      createdAt: new Date(),
      items: []
    };
    allPackages.push(newPackage);

    return res(
      ctx.data({
        createPackage: newPackage
      })
    );
  }),

  graphql.mutation('UPDATE_PACKAGE', (req, res, ctx) => {
    const { id, destinationId, trackingNumber, method } = req.variables;

    const updatedPackage = allPackages.find(pkg => pkg.id === id);

    updatedPackage.destination.destinationId = destinationId;
    updatedPackage.trackingNumber = trackingNumber;
    updatedPackage.method = method.toUpperCase();

    return res(
      ctx.data({
        updatePackage: updatedPackage
      })
    );
  }),

  graphql.mutation('DELETE_PACKAGE', (req, res, ctx) => {
    const { id } = req.variables;

    const deletedPackage = allPackages.find(pkg => pkg.id === id);
    allPackages = allPackages.filter(pkg => pkg.id !== id);

    return res(
      ctx.data({
        deletePackage: {
          id: deletedPackage.id
        }
      })
    );
  }),


  graphql.query('FETCH_ALL_PACKAGE_ADDRESSES', (req, res, ctx) => {

    const allPackageAddresses = allAddresses.map((address, index) => ({
      id: address.id,
      addressDetail: address.addressDetail,
      shortName: address.shortName,
      receivers: Array.from({ length: 3 }, () => ({
        user: {
          id: faker.number.int({ min: 1, max: 100 }),
          username: faker.person.fullName(),
          email: faker.internet.email(),
        }
      })),
    }));

    return res(
      ctx.data({
        allPackageAddresses
      })
    );
  }),

  graphql.query('FETCH_PACKAGE_INFO', (req, res, ctx) => {
    const { id } = req.variables;

    const packageInfo = allPackages.find(pkg => pkg.id === id);

    return res(
      ctx.data({
        packageInfo,
        allPackageItems: packageInfo.items,
        allPackageImagesByPkgId: allImages
      })
    );
  }),

  graphql.query('FETCH_PACKAGE_USERS_AND_RECEIVERS', (req, res, ctx) => {
    return res(
      ctx.data({
        allUsers,
        allReceiversByDestId
      })
    );
  }),

  graphql.query('FETCH_COURIER_MATCH_INFO', (req, res, ctx) => {
    const { trackingNumber, method } = req.variables;
    // console.log('MSW trackingNumber', trackingNumber);
    // console.log('MSW method', method);

    return res(
      ctx.data({
        courierMatchInfo: {
          isMatched: trackingNumber.startsWith(method), // just for quick test
        }
      })
    );
  }),

  // * package item

  graphql.mutation('CREATE_PACKAGE_ITEM', (req, res, ctx) => {
    const { packageId, name, quantity, senders, receivers, description } = req.variables;

    const newItem = {
      id: faker.number.int({ min: 1, max: 100 }),
      name: name,
      quantity: quantity,
      senders: senders.map(senderId => allUsers.find(user => user.id === Number(senderId))),
      receivers: receivers.map(receiverId => allReceiversByDestId.find(receiver => receiver.user.id === Number(receiverId))),
      description,
    };
    allItems.push(newItem);

    return res(
      ctx.data({
        packageItem: newItem
      })
    );
  }),

  graphql.mutation('DELETE_PACKAGE_ITEM', (req, res, ctx) => {
    const { itemId } = req.variables;

    allItems = allItems.filter(item => item.id !== itemId);

    return res(
      ctx.data({
        success: true,
      })
    );
  }),

  graphql.mutation('UPDATE_PACKAGE_ITEM', (req, res, ctx) => {
    const { itemId, name, quantity, senders, receivers, description } = req.variables;

    const updatedItem = allItems.find(item => item.id === itemId);
    updatedItem.name = name;
    updatedItem.quantity = quantity;
    updatedItem.senders = senders;
    updatedItem.receivers = receivers;
    updatedItem.description = description;

    return res(
      ctx.data({
        packageItem: updatedItem
      })
    );
  }),
];


const supplierGradingHandlers = [
  graphql.query('FETCH_SUPPLIER_SCORES', (req, res, ctx) => {
    const { startDate, endDate } = req.variables;
    // console.log('MSW startDate', startDate);
    // console.log('MSW endDate', endDate);
    const start = new Date(startDate);
    const end = new Date(endDate);

    if (start > end) {
      return res(
        ctx.errors([
          {
            message: 'Start date must be before end date'
          },
        ]),
      );
    }

    return res(
      ctx.data({
        supplierScores: [
          {
            name: 'Supplier A',
            scores: {
              quality: 80,
              shipmentErrors: 90,
              leadTime: 60,
              esg: 70,
              communications: 75
            }
          },
          {
            name: 'Supplier B',
            scores: {
              quality: 85,
              shipmentErrors: 80,
              leadTime: 70,
              esg: 65,
              communications: 90
            }
          },
          {
            name: 'Supplier C',
            scores: {
              quality: 90,
              shipmentErrors: 85,
              leadTime: 75,
              esg: 80,
              communications: 85
            }
          },
          {
            name: 'Supplier D',
            scores: {
              quality: 75,
              shipmentErrors: 70,
              leadTime: 65,
              esg: 60,
              communications: 65
            }
          },
          {
            name: 'Supplier E',
            scores: {
              quality: 70,
              shipmentErrors: 75,
              leadTime: 80,
              esg: 85,
              communications: 80
            }
          }
        ],
      })
    );
  }),

  graphql.query('FETCH_SUPPLIER_INDICATOR_INFOS', (req, res, ctx) => {
    const { indicator, start, end } = req.variables;

    const suppliers = ['Supplier A', 'Supplier B', 'Supplier C', 'Supplier D', 'Supplier E'];

    const supplierIndicatorInfos = suppliers.map(supplierName => ({
      supplierName,
      supplierId: faker.number.int(1, 100),
      assessmentId: faker.string.uuid(),
      score: Math.round(Math.random() * 100),
      rate: Number(faker.finance.amount(0, 1, 2)),
      cnt: Math.floor(Math.random() * 100),
      totalCnt: Math.floor(Math.random() * 100 + 100),
    }));

    return res(
      ctx.data({
        supplierIndicatorInfos,
      })
    );
  }),

  graphql.query('FETCH_COMMUNICATION_INFOS_BY_SUPPLIER', (req, res, ctx) => {
    const { supplierId, start, end } = req.variables;

    const surveys = Array.from({ length: 2 }, () => ({
      id: faker.string.uuid(),
      title: faker.lorem.sentence(),
    }));

    const qas = Array.from({ length: 7 }, () => ({
      questionText: faker.lorem.sentence(),
      answerScore: faker.number.int(5) * 10,
      totalScore: 5 * 10,
    }));
    return res(
      ctx.data({
        communicationInfosBySupplier: {
          surveys,
          qas,
        },
      })
    );
  }),
];

export const handlers = [
  ...supplierGradingHandlers,
  ...packageTrackingHandlers,
];