import React from "react";

function ImageLoader({
  className = "",
  bgStyle = "bg-center bg-cover bg-no-repeat bg-gray-200 dark:bg-gray-700",
  size = "w-48 h-48",
  src = null,
  originalSrc = null,
}) {
  if (!src) return null;

  return (
    <div
      className={`flex items-center justify-center relative border rounded-lg
            ${className}
            ${size}
            ${bgStyle}
            ${originalSrc ? "cursor-pointer" : ""}
         `}
      style={{ backgroundImage: `url(${src})` }}
    >
      {originalSrc ? (
        <a href={originalSrc} target="_blank" rel="noopener noreferrer" className="block w-full h-full hover:bg-gray-100 opacity-25 animate-ease-2">
          {" "}
        </a>
      ) : null}
    </div>
  );
}

export default ImageLoader;
