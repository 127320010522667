import { useQuery, gql } from '@apollo/client';
import { Button } from './base';
import Errors from './Errors';
import { Select } from './Form';
import { byName } from 'utils/sort';
import { InlineSpinner } from './Spinner';
import { useModals } from 'ModalProvider';
import ProductionEditor from 'pages/production/productionNodes/ProductionEditor';

const FETCH_ALL_PRODUCTION_NODES = gql`
   query FETCH_ALL_PRODUCTION_NODES{
      allProductionNodes {
         id
         name
         parent {
            id
         }
         category {
            id
            name
            factory {
               id
               name
            }
         }
      }
   }
`;

const ProductionSelector = ({ productionId, onSelect }) => {
   const { loading, error, data } = useQuery(FETCH_ALL_PRODUCTION_NODES);
   const { present, hide } = useModals();

   const nodes = data
      ? data.allProductionNodes.filter((i) => i.name && i.parent === null)
      : [];

   const factories = nodes
      .reduce((res, node) => {
         if (res.find((x) => x.id === node.category.factory.id)) {
            return res;
         } else {
            return [...res, node.category.factory];
         }
      }, [])
      .sort(byName);

   return (
      <div>
         <div className="flex">
            <div className="flex items-baseline space-x-4">
               <label className="font-bold mr-4 w-24">Routes:</label>
               {loading ? (
                  <div className="relative">
                     <InlineSpinner size={16} text={null} />
                  </div>
               ) : error ? (
                  <Errors error={error} />
               ) : (
                  <div>
                     <div className="flex">
                        <Select
                           value={productionId || '0'}
                           onChange={(e) => onSelect(e.target.value)}
                        >
                           <option value="0">-- select a production --</option>
                           {factories.map((factory) => (
                              <optgroup key={factory.id} label={factory.name}>
                                 {nodes
                                    .filter(
                                       (i) =>
                                          i.category.factory.id === factory.id
                                    )
                                    .sort(byName)
                                    .map((i) => (
                                       <option value={i.id} key={i.id}>
                                          {i.category.factory.name} - {i.name}
                                       </option>
                                    ))}
                              </optgroup>
                           ))}
                        </Select>
                        <Button
                           className="ml-4"
                           title="+ New Production"
                           onClick={() =>
                              present({
                                 title: 'Create New Production',
                                 maxWidth: 'max-w-7xl',
                                 children: (
                                    <ProductionEditor
                                       onSave={(production) => {
                                          hide();
                                          onSelect(production.id);
                                       }}
                                    />
                                 ),
                              })
                           }
                        />
                     </div>

                     <div className="text-xs opacity-60 mt-1 px-2">
                        Choose a Production Node to set how this product is
                        produce step by step. This is used to compute the
                        leadtime for a completed product.
                     </div>
                  </div>
               )}
            </div>
         </div>
      </div>
   );
};

export default ProductionSelector;
