import React, { useRef, useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import { formatDate } from "react-day-picker/moment";
import { Button } from "components/base";
import { useModals } from "ModalProvider";
import OdooProductStock from "./OdooProductStock";
import { IoStatsChart } from "react-icons/io5";
import Status from "components/Status";
import { FETCH_ODOO_PRODUCT_STOCKS } from "./graphql";

// This data if only for computing stock in China!

function StocksViewNew({ productOdooIds }) {
  const { loading, error, data } = useQuery(FETCH_ODOO_PRODUCT_STOCKS, {
    variables: { ids: productOdooIds },
  });

  if (loading) return <Spinner text="Loading data from Odoo..." />;
  if (error) return <Errors error={error} />;

  return <StockTable data={data} />;
}

const StockTable = ({ data }) => {
  const [showAllRows, setShowAllRows] = useState(false);
  const [showOnHandHistory, setShowOnHandHistory] = useState(window.localStorage.getItem("showOnHandHistory") === "1");

  const headerRowRef = useRef();
  const lastRowRef = useRef();
  const odooStockHistory = JSON.parse(data.odooStockHistory);
  const historyModal = useModals();

  useEffect(() => {
    if (data) {
      lastRowRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [data]);

  useEffect(() => {
    if (showAllRows === true) {
      headerRowRef.current.scrollIntoView({
        inline: "start",
        behavior: "smooth",
      });
    } else {
      lastRowRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [showAllRows]);

  let rows = odooStockHistory.reduce((res, row, index) => {
    const products = data.products.map((product) => {
      const productData = row.products.find((i) => i.product_id === product.id);
      if (productData) return productData;
      if (index === 0)
        return {
          product_id: product.id,
          qty: 0,
          assignedQty: 0,
          cumsum: 0,
          doneCumsum: 0,
        };
      const lastRowProductData = res[index - 1].products.find((i) => i.product_id === product.id);
      return { ...lastRowProductData, qty: 0, assignedQty: 0 };
    });
    return [...res, { ...row, products }];
  }, []);

  console.log("rows", rows);

  const odooStockChina = JSON.parse(data.odooStockChina);

  const calculatedProducts = data.products.map((product) => {
    const productRows = rows.flatMap((row) => row.products.map((p) => ({ ...p, type: row.type, state: row.state }))).filter((i) => i.product_id === product.id);
    const inProductionQty = productRows.filter((i) => i.type === "PO" && i.state === "assigned").reduce((res, product) => (res += product.qty), 0);
    const assignedQtySum = productRows.reduce((res, product) => (res += product.assignedQty), 0);
    const lastDoneQty = productRows.length > 0 ? productRows[productRows.length - 1].doneCumsum : 0;
    const lastCusum = productRows.length > 0 ? productRows[productRows.length - 1].cumsum : 0;

    const chinaStock = odooStockChina.find((i) => i.product_id[0] === product.id);
    const qtyOnHandInChina = chinaStock ? chinaStock.quantity : 0;

    const qtyMatches = lastDoneQty === qtyOnHandInChina;

    return {
      ...product,
      assignedQtySum,
      inProductionQty,
      qtyMatches,
      qtyOnHandInChina,
      lastDoneQty,
      lastCusum,
    };
  });

  console.log("calculatedProducts", calculatedProducts);

  let today = new Date();
  today.setMonth(today.getMonth() - 1);
  let lastMonthOrderIndex = rows.findIndex((row) => row.date > today);
  let lastPoIndex = -1;
  const firstInProductionPoRowIndex = rows.findIndex((o) => o.type === "PO" && o.state === "assigned");
  if (firstInProductionPoRowIndex > -1) {
    lastPoIndex = firstInProductionPoRowIndex;
  } else {
    rows.forEach((row, index) => {
      if (row.type === "po") lastPoIndex = index;
    });
  }

  const startIndex = Math.min(lastPoIndex, lastMonthOrderIndex);

  if (!showAllRows && startIndex > -1) {
    rows = rows.slice(Math.max(startIndex, 0));
  }

  const rowStyle = (row) => {
    switch (row.type) {
      case "PO":
        return "bg-green-200 dark:bg-green-900 dark:bg-opacity-50";
      case "INV":
        return "bg-purple-200 dark:bg-purple-800";
      default:
        return "hover:bg-blue-100 dark:hover:bg-blue-700";
    }
  };

  const rowProductQtyStyle = (row) => {
    if (row.type === "PO") {
      if (row.state === "assigned") {
        return "text-red-600 font-bold";
      }
      if (row.state === "done") {
        return "text-green-700 dark:text-green-600 font-bold";
      }
    }
    return "";
  };

  return (
    <table className="bg-white dark:bg-gray-900">
      <thead className="text-xs">
        <tr ref={headerRowRef}>
          <th className="text-left px-4 bg-gray-200 dark:bg-gray-800 lg:sticky top-0 w-40 sticky left-0 z-40">
            <div className="py-2">
              <div>Date & Ref</div>
              <div className="flex">
                <Button
                  className="mr-4 whitespace-nowrap"
                  size="sm"
                  title={showAllRows ? "show recent orders" : "show all orders"}
                  onClick={(_) => setShowAllRows((prev) => !prev)}
                />
                <Button
                  className="whitespace-nowrap"
                  size="sm"
                  title={showOnHandHistory ? "hide on hand history" : "show on hand history"}
                  onClick={(_) =>
                    setShowOnHandHistory((prev) => {
                      window.localStorage.setItem("showOnHandHistory", !prev ? "1" : "0");
                      return !prev;
                    })
                  }
                />
              </div>
            </div>
          </th>
          {calculatedProducts.map((product) => (
            <th
              className={`
                        text-right py-2 px-4 bg-gray-200 dark:bg-gray-800 lg:sticky top-0 z-100
                        ${product.qtyMatches ? "" : "bg-pink-200 dark:bg-pink-800"}
                     `}
              key={product.id}
            >
              <div className="text-xs whitespace-nowrap align-middle">
                #{product.id} - [{product.defaultCode}] {product.name}{" "}
                <IoStatsChart
                  className="inline-block cursor-pointer text-blue-700 hover:text-blue-600 pl-2 w-6"
                  size={18}
                  onClick={() =>
                    historyModal.present({
                      title: product.name,
                      maxWidth: "max-w-7xl",
                      children: <OdooProductStock id={product.id} />,
                    })
                  }
                />
              </div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, index) => (
          <tr key={index} className={rowStyle(row)}>
            <td className="border-b border-t dark:border-gray-700 px-4 sticky left-0 z-20 bg-gray-50 dark:bg-gray-800">
              <div>
                <div className="flex items-center">
                  <div className="">{row.date ? formatDate(row.date, "YYYY-MM-DD") : "-"}</div>
                </div>

                <div className="font-bold flex items-center justify-between space-x-3">
                  <span>
                    [{row.type}] {row.name}
                  </span>

                  <div>
                    {row.type === "PO" && row.state === "assigned" && <Status status="IN_PRODUCTION" />}
                    {row.type === "MO" && row.state !== "done" && <Status status="UNDONE" />}
                  </div>
                </div>
                <div className="text-xs ">{row.origin}</div>
                {/* <div>[{row.state}]</div> */}
              </div>
            </td>
            {calculatedProducts.map((product, index) => {
              const res = row.products.find((p) => p.product_id === product.id);
              return (
                <td key={index} className="border-b dark:border-gray-700 text-right px-4">
                  {res ? (
                    <>
                      <div className={rowProductQtyStyle(row, product)}>
                        {res.qty > 0 ? "+" : ""}
                        {res.qty === 0 ? "-" : res.qty}
                      </div>
                      <div
                        className={`
                                 text-xs
                                 ${showOnHandHistory ? "block" : "hidden"}
                              `}
                      >
                        on hand: {res ? res.doneCumsum : "-"}
                      </div>
                      <div className="text-xs">
                        {showOnHandHistory ? "forecasted: " : null}
                        {res ? res.cumsum : "-"}
                      </div>
                    </>
                  ) : (
                    "-"
                  )}
                </td>
              );
            })}
          </tr>
        ))}

        <tr>
          <td className="px-4 py-2 text-base font-bold sticky left-0 z-20 bg-gray-200 dark:bg-gray-800" ref={lastRowRef}>
            Odoo Summary
          </td>
          {calculatedProducts.map((product) => (
            <td
              className={`text-right py-2 px-4
                     ${product.qtyMatches ? "" : "bg-pink-200 dark:bg-pink-800"}
                  `}
              key={product.id}
            >
              <ProductSummaryCell product={product} />
            </td>
          ))}
        </tr>
      </tbody>
    </table>
  );
};

const ProductSummaryCell = ({ product }) => {
  console.log("product", product);
  return (
    <div className="flex flex-col items-end whitespace-nowrap">
      <div className="ml-2 whitespace-no-wrap">
        <div className="flex items-baseline justify-end">
          <div className="font-normal">On Hand{product.stockInChina ? "(CN)" : ""}:</div>
          <div className="font-bold text-base text-green-600 dark:bg-text-800 ml-2">{product.qtyOnHandInChina}</div>
        </div>
        <div className="flex items-baseline justify-end">
          <div className="font-normal ">In Production:</div>
          <div className="ml-2 font-bold text-base text-blue-600">{product.inProductionQty}</div>
        </div>
        <div className="flex items-baseline justify-end">
          <div className="font-normal ">Forecasted{product.stockInChina ? "(CN)" : ""}:</div>
          <div className="ml-2 font-bold text-base text-orange-600">{product.lastCusum}</div>
        </div>
      </div>
    </div>
  );
};

export default StocksViewNew;
