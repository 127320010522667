import useOdooDeliveryMethods from 'hooks/useOdooDeliveryMethods';
import { Select } from './Form';

const OdooDeliveryMethodSelector = ({ value, onSelect, ...rest }) => {
   const { loading, error, odooDeliveryMethods } = useOdooDeliveryMethods();

   return (
      <Select
         value={value || '0'}
         onChange={(e) => onSelect(parseInt(e.target.value))}
         {...rest}
      >
         {loading ? (
            <option value="0" disabled>
               loading...
            </option>
         ) : error ? (
            <option value="0" disabled>
               {error.message}
            </option>
         ) : (
            <>
               <option value="0" disabled>
                  --- Select ---
               </option>
               {odooDeliveryMethods.map((i, index) => (
                  <option key={index} value={i.id}>
                     {i.display_name}
                  </option>
               ))}
            </>
         )}
      </Select>
   );
};

export default OdooDeliveryMethodSelector;
