import React, { createContext, useState } from 'react';
import { Routes, Route, Outlet, Navigate } from 'react-router-dom';
import Products from './Products';
import CollectionView from './collectionView';
import ExportAll from './ExportAll';
import ProductGroups from './groupView';
import ProductWarnings from './ProductWarnings';
import Page from 'components/Page';
import { LeftButtons } from './Header';
import ProductLineAsscociateLifeStyle from './collectionView/ProductLineAsscociateLifeStyle';
import ProductLineAssociateVideos from './collectionView/ProductLineAssociateVideos';
import ProductLineDetail from './collectionView/ProductLineDetail';
import GroupDetail from './groupView/GroupDetail';

export const ProductsPageContext = createContext({});

const ProductProvider = ({ children }) => {
   const [previewProductImages, setPreviewProductImages] = useState(null);

   return (
      <ProductsPageContext.Provider
         value={{ previewProductImages, setPreviewProductImages }}
      >
         {children}
      </ProductsPageContext.Provider>
   );
};

function ProductsIndex() {
   return (
      <ProductProvider>
         <Routes>
            <Route element={<Layout />}>
               <Route path="index" element={<Products />} />
               <Route path="warnings" element={<ProductWarnings />} />
               <Route path="export" element={<ExportAll />} />
               <Route path="collection-view/*" element={<CollectionView />}>
                  <Route path=":slug" element={<ProductLineDetail />} />
                  <Route
                     path=":slug/associate/lifestyleimages"
                     element={<ProductLineAsscociateLifeStyle />}
                  />
                  <Route
                     path=":slug/associate/videos"
                     element={<ProductLineAssociateVideos />}
                  />
               </Route>
               <Route path="groups" element={<ProductGroups />}>
                  <Route path=":id" element={<GroupDetail />} />
               </Route>
               <Route path="*" element={<Navigate to="index" />} />
            </Route>
         </Routes>
      </ProductProvider>
   );
}

const Layout = () => {
   return (
      <Page className="h-screen" leftButtons={<LeftButtons />}>
         <Outlet />
      </Page>
   );
};

export default ProductsIndex;
