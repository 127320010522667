import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import queryString from "query-string";
import { useDebouncedCallback } from "use-debounce";
import { formatDate } from "react-day-picker/moment";
import { InlineSpinner } from "components/Spinner.js";
import Errors from "components/Errors";
import SearchBar from "components/SearchBar";
import Status from "components/ShipmentStatus.js";
import ShipmentTitle from "components/ShipmentTitle.js";
import { FETCH_SHIPMENT_LIST, CREATE_SHIPMENT } from "../graphql";
import ShipmentStatusOptions from "./ShipmentStatusOptions";
import { Alert } from "components/Toast.js";
import { BsExclamationCircle } from "react-icons/bs";
import expeditorsIcon from "assets/expeditors-icon.png";
import CharlesButton from "components/charles/base";
import { useModals } from "ModalProvider";
import ChooseCustomerView from "./ChooseCustomerView";
import ChooseOdooObjectsView from "./invoices/ChooseOdooObjectView";
import ConfirmOdooObjectView from "./invoices/ConfirmOdooObjectView";
import ReviewCustomerView from "../ReviewCustomerView";
import { parseError } from "apollo";
import { Button } from "components/base";

const PAGE_SIZE = 30;

const localDateTime = (datetimeString) => {
  const d = new Date(datetimeString);
  return d.toLocaleString();
};

function computeShipment(shipment) {
  const isDelayed = shipment.latestStatus === "in production" && new Date(shipment.etd) < new Date();
  const shouldBeShipButNot = shipment.latestStatus === "delivered to port" && new Date(shipment.latestEtd) < new Date();
  return { ...shipment, isDelayed, shouldBeShipButNot };
}

function ShipmentList() {
  let savedFilterStatus = localStorage.getItem("shipmentFilterStatus");

  const navigate = useNavigate();
  const location = useLocation();
  const offsetFromUrl = queryString.parse(location.search).offset || 0;
  const lastQuery = queryString.parse(location.search).query || "";

  const [query, setQuery] = useState(lastQuery);
  const [latestStatusIn, setLatestStatusIn] = useState(savedFilterStatus);
  const [offset, setOffset] = useState(parseInt(offsetFromUrl));
  const { data, loading, error } = useQuery(FETCH_SHIPMENT_LIST, {
    variables: {
      latestStatusIn: latestStatusIn === "0" ? null : latestStatusIn,
      options: { q: query, limit: PAGE_SIZE, offset },
    },
    fetchPolicy: "network-only",
    onError() {
      Alert("Fail to fetch shipments.");
    },
  });

  useEffect(() => {
    const q = queryString.stringify({ query, offset });
    navigate(`/shipment/shipments?${q}`, { replace: true });
  }, [offset]);

  const debouncedSetQuery = useDebouncedCallback(setQuery, 500);
  const chooseCustomerModal = useModals();
  const reviewCustomerNotesModal = useModals();
  const chooseOdooObjectsModal = useModals();
  const confirmOdooObjectModal = useModals();

  const [createShipment, createShipmentRes] = useMutation(CREATE_SHIPMENT, {
    onError: (error) => Alert("error", parseError(error)),
  });

  function tryCreateShipment() {
    chooseCustomerModal.present({
      title: "Choose Customer",
      children: <ChooseCustomerView choose={didChooseCustomer} />,
    });
  }

  function didChooseCustomer(customer) {
    reviewCustomerNotesModal.present({
      title: customer.name,
      subtitle: "Review customer",
      maxWidth: "max-w-6xl",
      children: <ReviewCustomerView customer={customer} complete={() => didCompleteReviewCustomerNotes(customer)} />,
    });
  }

  function didCompleteReviewCustomerNotes(customer) {
    reviewCustomerNotesModal.hide();
    chooseOdooObjectsModal.present({
      title: "Sales Order or Transfer",
      subtitle: customer.name,
      children: <ChooseOdooObjectsView odooPartnerId={customer.odooPartnerId} onSelect={(item) => didChooseOdooObject(customer, item)} />,
    });
  }

  function didChooseOdooObject(customer, item) {
    if (item) {
      confirmOdooObjectModal.present({
        title: item.name,
        subtitle: "Choose product and qty to create shipment invoice",
        maxWidth: "max-w-5xl",
        children: <ConfirmOdooObjectView item={item} customer={customer} type="CREATE_SHIPMENT" complete={didCreateShipment} />,
      });
    } else {
      chooseCustomerModal.hide();
      chooseOdooObjectsModal.hide();
      createShipment({ variables: { customerId: customer.id } });
    }
  }

  function didCreateShipment(shipment) {
    navigate(`/shipment/shipments/${shipment.id}`);
  }

  // if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const shipmentsToShow = data ? data.shipments.results.map(computeShipment) : [];

  return (
    <div>
      <div className="flex justify-between m-4 lg:m-6 space-x-6">
        <div className="flex-1">
          <SearchBar
            autoFocus
            className="text-base py-1"
            initialQuery={query}
            onChange={debouncedSetQuery}
            placeholder="Search shipment by customer name, invoice number, po or product."
          />
        </div>
        <div className="flex items-center space-x-4">
          <ShipmentStatusOptions
            initialValue={latestStatusIn ? latestStatusIn : "0"}
            disabled={loading}
            onSelected={(value) => {
              localStorage.setItem("shipmentFilterStatus", value);
              const latestStatusIn = value !== "0" ? [value] : [];
              setLatestStatusIn(latestStatusIn);
            }}
          />
          <CharlesButton loading={createShipmentRes.loading} onClick={tryCreateShipment}>
            + Create Shipment
          </CharlesButton>
        </div>
      </div>

      {loading ? (
        <div className="p-10">
          <InlineSpinner />
        </div>
      ) : (
        <div className="card m-6 p-0 overflow-auto whitespace-nowrap">
          <table>
            <thead>
              <tr>
                <th className="px-6 py-3">Shipment</th>
                <th>Status</th>
                <th>Shipping Term</th>
                <th className="px-6 text-left">Port of Loading</th>
                <th className="px-6">ETD</th>
                <th className="px-6">ETA</th>
                <th className="px-6 whitespace-nowrap">Cargo Ready Date</th>
                <th className="px-6 text-right">Created</th>
              </tr>
            </thead>
            <tbody>
              {shipmentsToShow.map((s) => (
                <tr
                  key={s.id}
                  className="border-y font-normal border-gray-100 dark:border-gray-700 table-row hover:bg-blue-50 dark:hover:bg-blue-900 text-gray-800 dark:text-gray-100"
                  onClick={() => navigate(`/shipment/shipments/${s.id}${query ? `?query=${query}` : ""}`)}
                >
                  <td className="px-6 whitespace-normal">
                    <div>
                      <Link to={`/shipment/shipments/${s.id}${query ? `?query=${query}` : ""}`}>
                        <ShipmentTitle customerName={s.customer.name} number={s.invoiceNumber} po={s.po} specialNotes={s.specialNotes} />
                      </Link>
                      <div>{s.shipContainer}</div>
                    </div>
                  </td>

                  <td className="align-middle">
                    <div className="flex space-x-4">
                      <Status status={s.latestStatus} />
                      {s.shouldBeShipButNot ? (
                        <div className="text-pink-500 flex items-center space-x-2">
                          <BsExclamationCircle size={18} title="This shipment status is supposed to be shipping today." />
                        </div>
                      ) : null}
                    </div>
                  </td>
                  <td>{s.shippingTerm}</td>
                  <td className="px-6 align-middle">{s.loadingPort}</td>
                  <td className={`align-middle ${s.isDelayed ? "text-red-500 font-bold" : ""}`}>{s.latestEtd}</td>
                  <td className="align-middle">
                    <div className="flex items-center space-x-1 justify-end">
                      {s.apiEta ? <img className="w-4" src={expeditorsIcon} /> : null}
                      <span>{s.latestEta}</span>
                    </div>
                  </td>
                  <td className="text-center align-middle">{s.requestCargoReadyDate ? formatDate(s.requestCargoReadyDate * 1000, "YYYY-MM-DD") : "-"}</td>
                  <td className="px-6 text-xs text-right opacity-70">
                    {localDateTime(s.created)}
                    <br />
                    {s.creator.email}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="px-4 py-3 flex justify-end items-center space-x-2 opacity-60">
            {offset > 0 ? <Button prev onClick={() => setOffset(offset - PAGE_SIZE)} /> : null}
            <div>
              {offset + 1}-{Math.min(PAGE_SIZE + offset, data.shipments.total)} / {data.shipments.total}
            </div>
            {data.shipments.total > offset + PAGE_SIZE ? <Button next onClick={() => setOffset(PAGE_SIZE + offset)} /> : null}
          </div>
        </div>
      )}
    </div>
  );
}

export default ShipmentList;
