import { gql, useQuery } from "@apollo/client";
import { parseError } from "apollo";
import Errors from "components/Errors";
import Spinner from "components/Spinner";
import { Route, Routes } from "react-router-dom";
import Suppliers from "./Suppliers";
import LumpSumSupplierView from "./LumpSumSupplierView";

const FETCH_OS_LUMP_SUM = gql`
  query FETCH_OS_LUMP_SUM {
    osLumpSum2
    allProductionPlans(statesIn: ["WAITING_TO_CONFIRM", "PLANNING"], options: { orderBy: "join_queue_at" }) {
      results {
        id
        name
        wisCustomer {
          id
          name
        }
        startDate
        readyDate
      }
      total
    }
  }
`;

const LumpSumPage = () => {
  const { loading, error, data } = useQuery(FETCH_OS_LUMP_SUM);

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const res = JSON.parse(data.osLumpSum2);

  const suppliers = res.reduce((res, i) => {
    const supplier = res.find((s) => s.id === i.supplier_id);
    if (supplier) {
      supplier.products.push(i);
      supplier.totalQty += i.qty;
      supplier.toProduce += i.to_produce;
    } else {
      res.push({ id: i.supplier_id, name: i.supplier_name, products: [i], totalQty: i.qty, toProduce: i.to_produce });
    }
    return res;
  }, []);

  return (
    <Routes>
      <Route>
        <Route index element={<Suppliers suppliers={suppliers} />} />
        <Route path=":supplierId" element={<LumpSumSupplierView plans={data.allProductionPlans.results} suppliers={suppliers} />} />
      </Route>
    </Routes>
  );
};

export default LumpSumPage;
