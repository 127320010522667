import React from 'react';

function Video({ poster, src, ratio = 9 / 16 }) {
   return (
      <div className="relative w-64 bg-gray-800">
         <div
            className="w-full bg-center bg-contain"
            style={{
               paddingBottom: `${ratio * 100}%`,
               backgroundImage: `url(${poster})`,
            }}
         ></div>
         <video controls className="absolute w-full h-full top-0 z-10">
            <source src={src} type="video/mp4" />
         </video>
      </div>
   );
}

export default Video;
