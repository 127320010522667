import { useContext } from "react";
import { ProductContext } from "./ProductForm";
import { OdooContext } from "OdooProvider";
import { BiLinkExternal } from "react-icons/bi";
import { Field } from "components/Form";
import OdooProductCategorySelector from "../odoo/OdooProductCategorySelector";

const OdooInfoView = () => {
  const { odooUrl } = useContext(OdooContext);
  const { odooProduct, simpleFields, onChangeSimpleFields } = useContext(ProductContext);

  return (
    <div>
      <h4 className="text-purple-500">Odoo Info</h4>

      <div className="mt-6 space-y-4">
        <Field label="Template ID" value={simpleFields.odooTemplateId} onChange={(value) => onChangeSimpleFields("odooTemplateId", value)} />

        <Field label="Variant ID" value={simpleFields.odooId} onChange={(value) => onChangeSimpleFields("odooId", value)} />

        <div className="flex items-center">
          {odooProduct ? (
            <div className="flex space-x-2 items-center whitespace-normal">
              <a
                href={`${odooUrl}/web#id=${odooProduct.id}&action=563&model=product.product&view_type=form&menu_id=222`}
                target="_blank"
                rel="noreferer"
                className="text-sky-600 hover:text-sky-700 active:text-sky-800"
              >
                <span className="mr-1">{odooProduct.displayName}</span>
                <BiLinkExternal className="inline" />
              </a>
            </div>
          ) : (
            " - "
          )}
        </div>

        <OdooProductCategorySelector value={simpleFields.odooCategoryId} onChange={(value) => onChangeSimpleFields("odooCategoryId", value)} />
      </div>
    </div>
  );
};

export default OdooInfoView;
