import { createContext, useRef } from 'react';
import { DateInput, Select } from 'components/Form';
import { useSearchParams } from 'react-router-dom';
import CharlesButton from 'components/charles/base';
import moment from 'moment';
import CategoriesMarginTable from './CategoriesMarginTable';

export const OdooMarginContext = createContext();

const ProductMarginPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const params = Object.fromEntries(searchParams.entries());
  console.log('paramsss', params);

  // Default YTD
  const startDate =
    searchParams.get('start') || moment(new Date()).format('YYYY-01-01');
  const endDate =
    searchParams.get('end') || moment(new Date()).format('YYYY-MM-DD');
  const orderType = searchParams.get('orderType') || 'ALL';
  const ref = useRef();

  return (
    <div className="flex flex-col overflow-auto p-6 pt-4 h-full space-y-3">
      <div className="flex items-center space-x-3 divide-x divide-gray-300">
        <div className="flex items-center space-x-3 flex-1">
          <div>
            <Select
              value={orderType}
              onChange={(e) =>
                setSearchParams({ ...params, orderType: e.target.value })
              }
            >
              <option value="ALL">All Orders</option>
              <option value="INC">Inc Only</option>
              <option value="NOT_INC">Excluded INC</option>
            </Select>
          </div>

          <label htmlFor="">Scheduled Delivery Date From</label>
          <DateInput
            value={startDate}
            onChange={(e) =>
              setSearchParams({ ...params, start: e.target.value })
            }
          />
          <label htmlFor="">to</label>
          <DateInput
            value={endDate}
            onChange={(e) =>
              setSearchParams({ ...params, end: e.target.value })
            }
          />
        </div>

        <div className="pl-3">
          <CharlesButton onClick={() => ref.current?.download()}>
            Download
          </CharlesButton>
        </div>
      </div>

      <div className="relative flex-1 card p-0 overflow-auto">
        <CategoriesMarginTable
          ref={ref}
          startDate={startDate}
          endDate={endDate}
          orderType={orderType}
        />
      </div>

      <div className="opacity-70">
        Product margin base on Odoo sales orders lines. Each sales order line
        has the product purchase pice, sales price. Only the orders fully
        delivered are included in the calculation. <br />
        Note that the orders shipped from NL Warehouse(ALC) is not included,
        because it contains the feight cost which will effect the margin.
      </div>
    </div>
  );
};

export default ProductMarginPage;
