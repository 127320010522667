import { useQuery } from '@apollo/client';
import Errors from 'components/Errors';
import Spinner from 'components/Spinner';
import { FETCH_PRODUCTS_VALIDATION } from './graphql';

const OdooConnection = () => {
   const { loading, error, data } = useQuery(FETCH_PRODUCTS_VALIDATION, {
      variables: { productType: 'ODOO_CONNECTIONS' },
   });

   if (loading) return <Spinner text="Validating..." />;
   if (error) return <Errors error={error} />;

   const res = JSON.parse(data.validateProducts);

   const duplicatedProducts = Object.values(res);

   return (
      <div>
         <div className="px-6 py-3 opacity-70 italic">
            This page shows the duplicated wis products with the same odoo
            product.{' '}
            {duplicatedProducts.length > 0 ? (
               <b className="text-red-600">
                  {duplicatedProducts.length} invalid items, please fix them.
               </b>
            ) : (
               <b className="text-green-600">
                  Nice, no duplicated connections!
               </b>
            )}
         </div>

         <table>
            <thead className="whitespace-nowrap">
               <tr>
                  <th className="px-6 w-1/2 sticky top-0 backdrop-blur bg-gray-200 dark:bg-gray-700 bg-opacity-80">
                     Odoo Product
                  </th>
                  <th className="px-6 w-1/2 sticky top-0 backdrop-blur bg-gray-200 dark:bg-gray-700 bg-opacity-80">
                     WIS Products
                  </th>
               </tr>
            </thead>
            <tbody>
               {duplicatedProducts.map((i, index) => (
                  <tr key={index}>
                     <td className="px-6 border-b dark:border-gray-700 whitespace-nowrap">
                        {i.odoo_product.display_name}
                     </td>
                     <td className="px-6 border-b dark:border-gray-700 whitespace-pre-wrap">
                        {i.wis_products
                           .map(
                              (product) => `[${product.number}] ${product.name}`
                           )
                           .join('\n')}
                     </td>
                  </tr>
               ))}
            </tbody>
         </table>
      </div>
   );
};

export default OdooConnection;
