import { gql, useQuery } from "@apollo/client";
import Errors from "components/Errors";
import Spinner from "components/Spinner";
import { useModals } from "ModalProvider";
import { Routes, Route, Link } from "react-router-dom";
import PriceListDetailPage from "./DetailPage";
import EditPriceListView from "./EditPriceListView";
import CharlesButton from "components/charles/base";
import SearchBar from "components/SearchBar";
import { searchByProp } from "utils/search";
import { useState } from "react";
import { BiCircle } from "react-icons/bi";

export const FETCH_PRICE_LISTS = gql`
  query FETCH_PRICE_LISTS {
    priceLists {
      id
      name
      currency
      odooId
      createdBy {
        id
        email
      }
    }
  }
`;

const PriceList = () => {
  return (
    <Routes>
      <Route index element={<PriceListIndex />} />
      <Route path=":id" element={<PriceListDetailPage />} />
    </Routes>
  );
};

const PriceListIndex = () => {
  const { loading, error, data } = useQuery(FETCH_PRICE_LISTS);

  const modal = useModals();
  const [searchText, setSearchText] = useState("");

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const res = data.priceLists.filter((i) => searchByProp(i, ["name"], searchText));

  return (
    <div className="p-6 text-sm">
      <div className="card px-8 py-6">
        <div className="flex items-center space-x-6">
          <div className="flex-1">
            <SearchBar value={searchText} onChange={setSearchText} autoFocus className="bg-gray-100" placeholder="Search by name" />
          </div>
          <CharlesButton
            onClick={() =>
              modal.present({
                title: "Create Price List",
                center: true,
                maxWidth: "max-w-xl",
                children: <EditPriceListView complete={modal.hide} />,
              })
            }
          >
            + Create Price List
          </CharlesButton>
        </div>
        <div className="mt-4 -mx-2">
          <table>
            <tbody>
              {res.map((priceList) => (
                <tr key={priceList.id} className="py-2 border-b border-gray-100 dark:border-gray-700">
                  <td>
                    <div className="flex items-center space-x-3">
                      <Link to={priceList.id}>
                        {priceList.name} [{priceList.currency}]
                      </Link>
                      {priceList.odooId ? <BiCircle className=" text-purple-600" size={14} /> : ""}
                    </div>
                  </td>
                  <td className="text-right opacity-70">{priceList.createdBy ? priceList.createdBy.email : "-"}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="opacity-70 mt-4 text-xs">
        <p>
          WIS pricelist is almost the same as the Odoo one. We can also sync pricelist to Odoo. However, we build this feature because Odoo does not provide
          enough flexibility.
        </p>
        <p>This pricelist will be applied to Order Scheduler plans.</p>
      </div>
    </div>
  );
};

export default PriceList;
