import { useQuery } from '@apollo/client';
import { FETCH_ODOO_CATEGORIES_MARGIN } from './graphql';
import Errors from 'components/Errors';
import Spinner from 'components/Spinner';
import { useModals } from 'ModalProvider';
import SingleCategoryView from './SingleCategorView';
import CharlesButton from 'components/charles/base';
import { forwardRef, useImperativeHandle } from 'react';
import XLSX from 'xlsx';
import { useState } from 'react';
import { RiArrowUpDownLine } from 'react-icons/ri';

const CategoriesMarginTable = forwardRef(
  ({ startDate, endDate, orderType }, ref) => {
    const { loading, error, data } = useQuery(FETCH_ODOO_CATEGORIES_MARGIN, {
      variables: { startDate, endDate, orderType },
    });
    const modal = useModals();

    const [sortBy, setSortBy] = useState('category');

    useImperativeHandle(ref, () => ({
      download() {
        const excelData = res.map((i) => ({
          Category: i.display_name,
          QTY: i.total_qty,
          Sales: parseFloat(i.total_sales.toFixed(2)),
          Cost: parseFloat(i.total_cost.toFixed(2)),
          Margin: parseFloat(i.total_margin.toFixed(2)),
          'Margin (%)': parseFloat((i.margin_percentage * 100).toFixed(2)),
        }));
        const sheet = XLSX.utils.json_to_sheet(excelData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, sheet);
        const filename = `Odoo_Product_Margin${startDate}_${endDate}.xlsx`;

        XLSX.writeFile(wb, filename);
      },
    }));

    if (loading) return <Spinner />;
    if (error) return <Errors error={error} />;

    const res = JSON.parse(data?.odooProductCategoriesMargin)
      .filter((i) => i.total_qty > 0)
      .sort((a, b) => {
        const sortOptions = {
          qty: b.total_qty - a.total_qty,
          '-qty': a.total_qty - b.total_qty,
          sales: b.total_sales - a.total_sales,
          '-sales': a.total_sales - b.total_sales,
          cost: b.total_cost - a.total_cost,
          '-cost': a.total_cost - b.total_cost,
          margin: b.total_margin - a.total_margin,
          '-margin': a.total_margin - b.total_margin,
          margin_percentage: b.margin_percentage - a.margin_percentage,
          '-margin_percentage': a.margin_percentage - b.margin_percentage,
        };
        return (
          sortOptions[sortBy] ?? a.display_name.localeCompare(b.display_name)
        );
      });

    function showCategory(category) {
      modal.present({
        title: category.display_name,
        maxWidth: 'max-w-7xl',
        children: (
          <SingleCategoryView
            category={category}
            startDate={startDate}
            endDate={endDate}
            orderType={orderType}
          />
        ),
      });
    }

    const totalQty = res.reduce((acc, item) => acc + item.total_qty, 0);
    const totalSales = res.reduce((acc, item) => acc + item.total_sales, 0);
    const totalCost = res.reduce((acc, item) => acc + item.total_cost, 0);
    const totalMargin = res.reduce((acc, item) => acc + item.total_margin, 0);
    const totalMarginPercent = totalMargin / totalSales;

    return (
      <div className="text-right">
        <table>
          <thead>
            <tr className=" sticky top-0 bg-gray-50 dark:bg-gray-800 z-10">
              <th className="px-4 py-3">
                <div className="flex justify-start items-center space-x-2">
                  <span>Category</span>
                  <CharlesButton onClick={() => setSortBy('category')}>
                    <RiArrowUpDownLine />
                  </CharlesButton>
                </div>
              </th>
              <th className="text-right px-4">
                <div className="flex justify-end items-center space-x-2">
                  <CharlesButton
                    onClick={() => {
                      setSortBy(sortBy === 'qty' ? '-qty' : 'qty');
                    }}
                  >
                    <RiArrowUpDownLine />
                  </CharlesButton>
                  <span>QTY</span>
                </div>
              </th>
              <th className="text-right px-4">
                <div className="flex justify-end items-center space-x-2">
                  <CharlesButton
                    onClick={() => {
                      setSortBy(sortBy === 'sales' ? '-sales' : 'sales');
                    }}
                  >
                    <RiArrowUpDownLine />
                  </CharlesButton>
                  <span>Sales(SEK)</span>
                </div>
              </th>
              <th className="text-right px-4">
                <div className="flex justify-end items-center space-x-2">
                  <CharlesButton
                    onClick={() => {
                      setSortBy(sortBy === 'cost' ? '-cost' : 'cost');
                    }}
                  >
                    <RiArrowUpDownLine />
                  </CharlesButton>
                  <span>Cost(SEK)</span>
                </div>
              </th>
              <th className="text-right px-4">
                <div className="flex justify-end items-center space-x-2">
                  <CharlesButton
                    onClick={() => {
                      setSortBy(sortBy === 'margin' ? '-margin' : 'margin');
                    }}
                  >
                    <RiArrowUpDownLine />
                  </CharlesButton>
                  <span>Margin(SEK)</span>
                </div>
              </th>
              <th className="text-right px-4">
                <div className="flex justify-end items-center space-x-2">
                  <CharlesButton
                    onClick={() => {
                      setSortBy(
                        sortBy === 'margin_percentage'
                          ? '-margin_percentage'
                          : 'margin_percentage',
                      );
                    }}
                  >
                    <RiArrowUpDownLine />
                  </CharlesButton>
                  <span>Margin %</span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {res.map((item) => (
              <tr
                key={item.id}
                className="border-y border-gray-100 dark:border-gray-700"
              >
                <td className="px-4 text-left">
                  <CharlesButton
                    disabled={item.total_qty === 0}
                    onClick={() => showCategory(item)}
                  >
                    {item.display_name}
                  </CharlesButton>
                </td>
                <td className="px-4">{item.total_qty.toLocaleString()}</td>
                <td className="px-4">{item.total_sales.toLocaleString()}</td>
                <td className="px-4">{item.total_cost.toLocaleString()}</td>
                <td className="px-4">{item.total_margin.toLocaleString()}</td>
                <td className="px-4">
                  {(item.margin_percentage * 100).toFixed(2)}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr className="font-semibold text-base sticky bottom-0 bg-fuchsia-50">
              <td className="text-left px-4 py-3">Total</td>
              <td className="px-4">{totalQty.toLocaleString()}</td>
              <td className="px-4">{totalSales.toLocaleString()}</td>
              <td className="px-4">{totalCost.toLocaleString()}</td>
              <td className="px-4">{totalMargin.toLocaleString()}</td>
              <td className="px-4">{(totalMarginPercent * 100).toFixed(2)}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    );
  },
);

export default CategoriesMarginTable;
