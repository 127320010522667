import { useState, useRef } from "react";

function DateRangeSlider({ min, max, onChange }) {
  const [value, setValue] = useState([min, max]);
  const sliderRef = useRef(null);

  const handleMouseDown = (index) => () => {
    const handleMouseMove = (event) => {
      const rect = sliderRef.current.getBoundingClientRect();
      const x = event.clientX - rect.left; // x position within the element.
      const width = rect.right - rect.left;
      let newValue = (x / width) * (max - min) + min;
      if (newValue < min) newValue = min;
      if (newValue > max) newValue = max;
      const newValueArray = [...value];
      // buffer is 1% of the total range. To prevent the two handles from overlapping.
      const buffer = 0.01 * (max - min);
      if (index === 0 && newValue > value[1] - buffer) {
        newValueArray[0] = value[1] - buffer;
      } else if (index === 1 && newValue < value[0] + buffer) {
        newValueArray[1] = value[0] + buffer;
      } else {
        newValueArray[index] = newValue;
      }
      setValue(newValueArray);
      onChange(newValueArray);
    };

    const handleMouseUp = () => {
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("mousemove", handleMouseMove);
    };

    document.addEventListener("mouseup", handleMouseUp);
    document.addEventListener("mousemove", handleMouseMove);
  };

  // drag the middle part of the slider to easily change the range
  const handleMiddleMouseDown = (event) => {
    const startX = event.clientX;
    const startValue = [...value];

    const handleMouseMove = (event) => {
      const rect = sliderRef.current.getBoundingClientRect();
      const dx = event.clientX - startX; // how far the mouse has moved
      const width = rect.right - rect.left;
      const dValue = (dx / width) * (max - min); // how much the value should change
      let newValueArray = startValue.map((v) => v + dValue);
      if (newValueArray[0] < min) {
        newValueArray = newValueArray.map((v) => v + min - newValueArray[0]);
      }
      if (newValueArray[1] > max) {
        newValueArray = newValueArray.map((v) => v - newValueArray[1] + max);
      }
      setValue(newValueArray);
      onChange(newValueArray);
    };

    const handleMouseUp = () => {
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("mousemove", handleMouseMove);
    };

    document.addEventListener("mouseup", handleMouseUp);
    document.addEventListener("mousemove", handleMouseMove);
  };

  return (
    <div className="p-2">
      <div ref={sliderRef} className="relative bg-gray-100 dark:bg-gray-500 w-full rounded flex items-center">
        <div className="h-[2px] bg-gray-100 w-full"></div>
        <div
          style={{ left: `${((value[0] - min) / (max - min)) * 100}%`, right: `${((max - value[1]) / (max - min)) * 100}%` }}
          className="absolute bg-blue-100 dark:bg-gray-600 h-1 cursor-pointer"
          onMouseDown={handleMiddleMouseDown}
        />
        <div
          style={{ left: `${((value[0] - min) / (max - min)) * 100}%` }}
          className="absolute bg-blue-100 dark:bg-blue-700 w-3 h-3 rounded-full cursor-pointer border border-blue-300 dark:border-blue-800 transition duration-200 ease-in-out"
          onMouseDown={handleMouseDown(0)}
        />
        <div
          style={{ left: `${((value[1] - min) / (max - min)) * 100}%` }}
          className="absolute bg-blue-100 dark:bg-blue-700 w-3 h-3 rounded-full cursor-pointer border border-blue-300 dark:border-blue-800 transition duration-200 ease-in-out"
          onMouseDown={handleMouseDown(1)}
        />
      </div>
    </div>
  );
}

export default DateRangeSlider;
