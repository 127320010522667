import { gql, useQuery } from '@apollo/client';
import Errors from 'components/Errors';
import Spinner from 'components/Spinner';
import {
   ResponsiveContainer,
   LineChart,
   Line,
   XAxis,
   YAxis,
   Tooltip,
   Legend,
} from 'recharts';

const FETCH_DATA = gql`
   query ($materialId: ID!, $productId: ID!) {
      material(id: $materialId) {
         id
         name
      }
      compareMaterialAndProduct(materialId: $materialId, productId: $productId)
   }
`;

const ProductCostWithRawMaterial = ({ materialId, productId }) => {
   const { loading, error, data } = useQuery(FETCH_DATA, {
      variables: { materialId, productId },
   });

   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;

   const res = JSON.parse(data.compareMaterialAndProduct);

   const chartData = res;

   return (
      <div className="space-y-8">
         <div className="card">
            <h5>Cost Changes Percentages</h5>
            <div className="opacity-70 mt-2">
               <p>
                  This chart shows the raw material cost and selected product
                  cost history, in this chart we could see the change
                  percentages between the first date along the time.
               </p>
               <p>
                  Ideally the product line will follow the raw materials lines.
               </p>
               <p>
                  The bigger the gap between the products and raw materials, the
                  bigger/smaller margin the supplier will get from us.
               </p>
            </div>

            <ResponsiveContainer className="mt-4" width="100%" aspect={5 / 2}>
               <LineChart data={chartData}>
                  <XAxis dataKey="date" />
                  <YAxis
                     yAxisId="material"
                     orientation="right"
                     width={20}
                     angle={-45}
                  />
                  <YAxis yAxisId="price" width={20} angle={-45} />
                  <Tooltip content={CustomTooltip} />
                  <Line
                     yAxisId="material"
                     dataKey="material"
                     stroke="#6366f1"
                     dot={false}
                     strokeDasharray="4 4"
                     strokeWidth={1}
                  />
                  <Line
                     yAxisId="price"
                     dataKey="price"
                     stroke="#0ea5e9"
                     dot={false}
                  />
                  <Line
                     yAxisId="price"
                     dataKey="ideal_price"
                     stroke="#16a34a"
                     dot={false}
                  />
                  <Legend />
               </LineChart>
            </ResponsiveContainer>
         </div>
      </div>
   );
};

const CustomTooltip = (props) => {
   const { active, payload, label } = props;
   // console.log('props', props);
   if (active && payload && payload.length) {
      return (
         <div className="px-6 py-3 rounded-2xl bg-opacity-80 bg-white dark:bg-gray-800 dark:bg-opacity-80">
            {payload.map((i, index) => (
               <div key={index} style={{ color: i.color }}>
                  {label}: {`${i.name}: ${i.value.toFixed(2)}`}
               </div>
            ))}
         </div>
      );
   }

   return null;
};

export default ProductCostWithRawMaterial;
