import { gql, useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { Select } from './Form';

const FETCH_ODOO_PICKING_TYPES = gql`
   query FETCH_ODOO_PICKING_TYPES {
      odooStockPickingTypes
   }
`;

const OdooPickingTypeSelector = ({
   value,
   onSelect,
   hasAllOption = false,
   initialValue,
   ...rest
}) => {
   const { loading, error, data } = useQuery(FETCH_ODOO_PICKING_TYPES);

   useEffect(() => {
      if (data && initialValue && value !== initialValue)
         onSelect(initialValue);
   }, [data]);

   const odooStockPickingTypes = data
      ? JSON.parse(data.odooStockPickingTypes)
      : [];

   return (
      <Select
         value={value || '0'}
         onChange={(e) => onSelect(e.target.value)}
         {...rest}
      >
         {loading ? (
            <option value="0" disabled>
               loading...
            </option>
         ) : error ? (
            <option value="0" disabled>
               {error.message}
            </option>
         ) : (
            <>
               <option value="0">
                  {hasAllOption ? 'All' : ' -- Choose -- '}
               </option>
               {odooStockPickingTypes.map((i, index) => (
                  <option key={index} value={i[0]}>
                     {i[1]}
                  </option>
               ))}
            </>
         )}
      </Select>
   );
};

export default OdooPickingTypeSelector;
