import { gql, useQuery } from '@apollo/client';
import Errors from 'components/Errors';
import Spinner from 'components/Spinner';

const FETCH_FSI_ORDER_DETAIL = gql`
   query FETCH_FSI_ORDER_DETAIL($id: ID!) {
      fsiOrder(id: $id)
   }
`;

const OrderDetail = ({ id }) => {
   const { loading, error, data } = useQuery(FETCH_FSI_ORDER_DETAIL, {
      variables: { id },
   });

   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;

   const order = JSON.parse(data.fsiOrder);

   if (order.Errors) return <div>Failed to fetch the order data from FSI.</div>;

   if (order.statusCode === 404) return <div>{order.message}</div>;

   return (
      <div className="text-sm">
         <div className="space-y-4">
            <div>
               <label htmlFor="">Order Number: </label>
               {order.orderNumber}
            </div>

            <div>
               <label htmlFor="">Order Status: </label>
               {order.orderStatus}
            </div>

            <div>
               <label htmlFor="">Shipping Status: </label>
               {order.shippingStatus}
            </div>

            <div className="flex space-x-8">
               <div>
                  <div>
                     <label htmlFor="">Billing Address</label>
                  </div>
                  <div>
                     <div>{order.billingAddress.name}</div>
                     <div>{order.billingAddress.address1}</div>
                     <div>{order.billingAddress.address2}</div>
                     <div>{order.billingAddress.city}</div>
                     <div>{order.billingAddress.state}</div>
                     <div>{order.billingAddress.postalCode}</div>
                     <div>{order.billingAddress.countryCode}</div>
                     <div>{order.billingAddress.phone}</div>
                     <div>{order.billingAddress.email}</div>
                  </div>
               </div>

               <div>
                  <div>
                     <label htmlFor="">Shipping Address</label>
                  </div>
                  <div>
                     <div>{order.shippingAddress.name}</div>
                     <div>{order.shippingAddress.address1}</div>
                     <div>{order.shippingAddress.address2}</div>
                     <div>{order.shippingAddress.city}</div>
                     <div>{order.shippingAddress.state}</div>
                     <div>{order.shippingAddress.postalCode}</div>
                     <div>{order.shippingAddress.countryCode}</div>
                     <div>{order.shippingAddress.phone}</div>
                     <div>{order.shippingAddress.email}</div>
                  </div>
               </div>
            </div>

            <div>
               <div>
                  <label htmlFor="">Items:</label>
               </div>
               <div className="mt-2 space-y-2">
                  {order.items.map((i, index) => (
                     <div key={index}>
                        [ {i.sku} ] &times; {i.quantityOrdered}
                     </div>
                  ))}
               </div>
            </div>
         </div>
      </div>
   );
};

export default OrderDetail;
