const { useQuery, gql } = require('@apollo/client');

const FETCH_ODOO_CUSTOMERS = gql`
   query FETCH_ODOO_CUSTOMERS {
      odooCustomers
   }
`;

const useOdooCustomers = () => {
   const { loading, error, data } = useQuery(FETCH_ODOO_CUSTOMERS);
   const odooCustomers = data ? JSON.parse(data.odooCustomers) : null;
   return { loading, error, odooCustomers };
};

export default useOdooCustomers;
