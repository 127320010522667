import { useMutation, useQuery } from "@apollo/client";
import Page from "components/Page";
import { useParams } from "react-router-dom";
import { GET_ORDER, SAVE_ORDER } from "./graphql";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import { OrderStatusChain } from "components/Status";
import { createContext, useState } from "react";
import CharlesButton from "components/charles/base";
import { Alert } from "components/Toast";
import { parseError } from "apollo";
import BaseInfoView from "./BaseInfoView";
import ProductsView from "./ProductsView";
import { useModals } from "ModalProvider";
import UpdateStateView from "./UpdateStateView";
import OdooInfoView from "./OdooInfoView";

export const OrderContext = createContext();

const OrderDetail = () => {
  const { id } = useParams();
  const { loading, error, data } = useQuery(GET_ORDER, {
    variables: { id },
  });

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  return <OrderForm originalOrder={data.order} />;
};

const OrderForm = ({ originalOrder }) => {
  const [order, setOrder] = useState(originalOrder);
  const [saveOrder, saveOrderRes] = useMutation(SAVE_ORDER, {
    variables: {
      id: order.id,
      input: {
        warehouseName: order.warehouseName,
        customerId: order.customer ? order.customer.id : null,
        notes: order.notes,

        odooSalesOrderClientOrderRef: order.odooSalesOrderClientOrderRef,
        odooPaymentTermsId: order.odooPaymentTermsId,
        odooTaxId: order.odooTaxId,
        odooIncoterm: order.odooIncoterm,
        odooXDeliverymethod: order.odooXDeliverymethod,
        autoSyncToOdoo: order.autoSyncToOdoo,
      },
      orderLines: order.orderLines
        .filter((i) => i.product)
        .map((i) => ({
          productId: i.product.id,
          qty: i.qty,
          salesPrice: i.salesPrice,
          discountPercentage: i.discountPercentage,
        })),
    },
    onCompleted() {
      Alert("success", "Order saved");
    },
    onError(error) {
      Alert("error", parseError(error));
    },
  });

  const [changeWarehouse, changeWarehouseRes] = useMutation(SAVE_ORDER, {
    onError(error) {
      Alert("error", parseError(error));
    },
  });

  const [changePricelist, changePricelistRes] = useMutation(SAVE_ORDER, {
    onError(error) {
      Alert("error", parseError(error));
    },
  });

  const modal = useModals();

  function updateState(operation) {
    modal.present({
      title: "Update Order State",
      center: true,
      maxWidth: "max-w-3xl",
      children: <UpdateStateView id={originalOrder.id} warehouseName={originalOrder.warehouseName} operation={operation} hide={modal.hide} />,
    });
  }

  const validStocks = order.usingStock.stocks.filter((i) => i.validOdooId);

  return (
    <Page
      title={`Sales Order #${originalOrder.id}`}
      backTo={"./.."}
      rightButtons={
        <div className="flex space-x-6">
          {originalOrder.state === "DRAFT" && <CharlesButton onClick={() => updateState("START_PLANNING")}>Start Planning</CharlesButton>}
          {originalOrder.state === "PLANNING" && <CharlesButton onClick={() => updateState("RESET_TO_DRAFT")}>Reset to Draft</CharlesButton>}
          <CharlesButton onClick={saveOrder} loading={saveOrderRes.loading}>
            Save
          </CharlesButton>
        </div>
      }
    >
      <div className="p-6">
        <OrderStatusChain status={originalOrder.state} />
      </div>

      <OrderContext.Provider
        value={{ originalOrder, order, setOrder, saveOrder, changeWarehouse, changeWarehouseRes, changePricelist, changePricelistRes, validStocks }}
      >
        <div className="grid gap-6 grid-cols-12 p-6 pt-0">
          <div className="col-span-9 space-y-6">
            <BaseInfoView />
            <ProductsView />

            <div className="p-6 pt-0 opacity-70">
              This Sales Order page is designed for order handling for AB warehouses orders. It will take the Stock Sheet managed by AB as our stock source.
              Once the order start planning, the stock will be reserved in the Stock Sheet.
            </div>
          </div>

          <div className=" col-span-3 space-y-6">
            <OdooInfoView />
          </div>
        </div>
      </OrderContext.Provider>
    </Page>
  );
};
export default OrderDetail;
