import { gql, useQuery } from '@apollo/client';
import Errors from 'components/Errors';
import { InlineSpinner } from 'components/Spinner';

const FETCH_ODOO_PRODUCT = gql`
   query FETCH_ODOO_PRODUCT($id: Int!) {
      odooProduct(id: $id) {
         id
         name
         displayName
         defaultCode
      }
   }
`;

const OdooProduct = ({ id }) => {
   const { loading, error, data } = useQuery(FETCH_ODOO_PRODUCT, {
      variables: { id },
   });

   return (
      <div className="relative">
         {loading ? (
            <div className="flex">
               <InlineSpinner text={null} size={21} />
            </div>
         ) : error ? (
            <Errors error={error} />
         ) : (
            <div>{data.odooProduct.displayName}</div>
         )}
      </div>
   );
};

export default OdooProduct;
