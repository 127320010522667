import React from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";

import FactoryList from "./FactoryList";
import FactoryDetail from "./FactoryDetail";
import TripList from "./TripList";
import Trip from "./Trip";
import Page from "components/Page";
import { LeftButtons } from "./Header";
import Assessment from "../assessment";

function CSRRoutes() {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="trips" element={<TripList />} />
        <Route path="trips/:id" element={<Trip />} />
        <Route path="factory" element={<FactoryList />} />
        <Route path="factory/:id" element={<FactoryDetail />} />
        <Route path="*" element={<Navigate to="trips" />} />
      </Route>
    </Routes>
  );
}

const Layout = () => (
  <Page
    leftButtons={<LeftButtons />}
    promot={
      <div className="py-1 px-6 text-xs text-center lg:text-right text-pink-500 font-semibold">
        Please keep in mind that this page is only for us, DO NOT share this data with people outside Waboba.
      </div>
    }
  >
    <Outlet />
  </Page>
);

export default CSRRoutes;
