import { gql, useMutation, useQuery } from "@apollo/client";
import { CharlesButton } from "components/charles/base";
import { Select } from "components/Form";
import Errors from "components/Errors";
import SearchBar from "components/SearchBar";
import Spinner from "components/Spinner";
import { Alert } from "components/Toast";
import { useState } from "react";
import { BsStarFill } from "react-icons/bs";
import { searchByProp } from "utils/search";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useModals } from "ModalProvider";
import ImagesPreview from "./ImagesPreview";
import { parseError } from "apollo";

const FETCH_COLLECTION = gql`
  query FETCH_COLLECTION($name: String) {
    anyCollection(name: $name) {
      id
      descendants {
        id
        name
        productLines {
          id
          name
          slug
          activeForBiz
          newImages {
            id
            name
            thumb: url(size: "300x300")
            url
            stage
            isPrimary
            ordering
          }
        }
      }
    }
  }
`;

const SET_PRODUCT_LINE_IMAGE_PRIMARY = gql`
  mutation SET_PRODUCT_LINE_IMAGE_PRIMARY($productLineId: ID!, $productLineImageId: ID!, $stage: String!) {
    setProductLineImagePrimary(productLineId: $productLineId, productLineImageId: $productLineImageId, stage: $stage) {
      productLine {
        id
        newImages {
          id
          name
          thumb: url(size: "300x300")
          stage
          isPrimary
        }
      }
    }
  }
`;

const SORT_PRODUCT_LINE_IMAGES = gql`
  mutation SORT_PRODUCT_LINE_IMAGES($ordering: [ProductLineImageSortInputType]!) {
    sortProductLineImages(ordering: $ordering) {
      productLine {
        id
        newImages {
          id
          name
          ordering
        }
      }
    }
  }
`;

const SYNC_PRODUCT_LINE_IMAGES = gql`
  mutation SYNC_PRODUCT_LINE_IMAGES($slug: String) {
    syncProductLineBeautyshots(slug: $slug) {
      productLine {
        id
        newImages {
          id
          name
          ordering
        }
      }
    }
  }
`;

const PRODUCT_CATEGORIES = ["Catalog 2024", "Category 2023", "2022 category"];

const BizBeautyshots = () => {
  const [category, setCategory] = useState(PRODUCT_CATEGORIES[0]);
  const { loading, error, data } = useQuery(FETCH_COLLECTION, {
    variables: { name: category },
  });
  const [searchText, setSearchText] = useState("");

  const previewModal = useModals();

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const lines = data.anyCollection.descendants.flatMap((i) => i.productLines).filter((i) => i.activeForBiz && searchByProp(i, ["name"], searchText));

  return (
    <div>
      <div className="p-6">
        <div className="flex space-x-6 items-center">
          <Select value={category} onChange={(e) => setCategory(e.target.value)}>
            {PRODUCT_CATEGORIES.map((i) => (
              <option key={i} value={i}>
                {i}
              </option>
            ))}
          </Select>
          <div className="flex-1">
            <SearchBar value={searchText} onChange={setSearchText} placeholder="Search by Name." />
          </div>
        </div>
      </div>
      <div className="px-6 opacity-70 leading-relaxed">
        <p>This page is for managing beautyshots for BIZ.</p>
        <p>
          We store our initial files in One Drive, a shred folder{" "}
          <a href="https://wabobaadmin.sharepoint.com/sites/Waboba/Shared%20Documents/wis/beautyshots/biz/" target="_blank" rel="noreferer">
            Waboba / Documents / wis / beautyshots / biz
          </a>
          .
        </p>
        <p>The images list in this page are the images in wis. These images will be shown in BIZ product pages.</p>
        <p>You can sync One Drive images to wis simply by clicking the `Sync from One Drive to WIS` button.</p>
        <p>You can drap and drop to sort the images in a percific order. </p>
        <p>
          You can also double click an image to mark as a primary image, which it shows as the product page right side from the description text. In most case
          you set a package image as the primary one.
        </p>
      </div>
      <div>
        {lines.map((i) => (
          <ProductLine
            productLine={i}
            key={i.id}
            stage={"biz"}
            preview={(index) =>
              previewModal.present({
                title: i.name,
                fullscreen: true,
                children: <ImagesPreview images={i.newImages.filter((i) => i.stage === "biz")} initialIndex={index} />,
              })
            }
          />
        ))}
      </div>
    </div>
  );
};

const ProductLine = ({ productLine, stage }) => {
  const images = productLine.newImages.filter((i) => i.stage === stage);

  const [setPrimary, setPrimaryRes] = useMutation(SET_PRODUCT_LINE_IMAGE_PRIMARY, {
    onCompleted() {
      Alert("success", "Images Synced.");
    },
    onError(error) {
      Alert("error", error.message);
    },
  });

  const [sortImages, sortImagesRes] = useMutation(SORT_PRODUCT_LINE_IMAGES, {
    onError(error) {
      Alert("error", error.message);
    },
  });

  const [syncImages, syncImagesRes] = useMutation(SYNC_PRODUCT_LINE_IMAGES, {
    variables: { slug: productLine.slug },
    onCompleted() {
      Alert("success", "Images Synced.");
    },
    onError(error) {
      Alert("error", parseError(error));
    },
  });

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  function onDragEnd(result) {
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;
    const ordering = reorder(images, result.source.index, result.destination.index).map((i, index) => ({ id: i.id, ordering: index + 1 }));
    sortImages({ variables: { ordering } });
  }

  return (
    <div className="border-b dark:border-gray-700 pb-4">
      <h4 className="p-6">{productLine.name}</h4>

      {images.length > 0 ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" direction="horizontal">
            {(provided) => (
              <div className="flex ml-6 mr-2 relative overflow-auto" ref={provided.innerRef}>
                {images.map((i, index) => (
                  <Draggable key={i.id} draggableId={i.id} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="relative"
                        onDoubleClick={() =>
                          setPrimary({
                            variables: {
                              productLineId: productLine.id,
                              productLineImageId: i.id,
                              stage,
                            },
                          })
                        }
                      >
                        {i.isPrimary ? (
                          <div className="absolute text-yellow-500 text-3xl top-2 left-2">
                            <BsStarFill />
                          </div>
                        ) : null}
                        <div className="w-36 mr-4 mb-4">
                          <img src={i.thumb} alt={i.name} />

                          <div className="break-all w-full text-xs text-center mt-2">{i.name}</div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}

                {setPrimaryRes.loading || sortImagesRes.loading ? <Spinner text="Processing..." /> : null}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <div className="px-6 opacity-70">No beautyshot for this item yet.</div>
      )}

      <div className="px-6 mt-4 opacity-70">One Drive folder name should be: {productLine.slug}.</div>
      <div className="p-6 pb-0 flex space-x-6">
        <a
          rel="noreferer"
          target="_blank"
          href={`https://wabobaadmin.sharepoint.com/sites/Waboba/Shared%20Documents/wis/beautyshots/${stage}/${productLine.slug}`}
        >
          Open One Drive Folder
        </a>
        <CharlesButton onClick={syncImages} loading={syncImagesRes.loading}>
          Sync from One Drive to WIS
        </CharlesButton>
      </div>
    </div>
  );
};

export default BizBeautyshots;
