import { useQuery, gql, useMutation, useLazyQuery } from '@apollo/client';
import { Button, CheckBox } from 'components/base';
import Errors from 'components/Errors';
import Spinner from 'components/Spinner';
import { GENERATE_DOCUMENT } from 'graphql/mutations';
import { useState } from 'react';
import http from 'utils/http';
import { formatDate } from 'react-day-picker/moment';
import { DatePicker, Input, Text } from 'components/Form';
import { Alert } from 'components/Toast';
import { sayTotal } from 'utils/string';
import { useModals } from 'ModalProvider';
import GeneratedDocuments from '../GeneratedDocs';
import { CURRENCIES } from 'utils/invoice';

const FETCH_PRODUCTS_BY_ODOO_IDS = gql`
   query FETCH_PRODUCTS_BY_ODOO_IDS($odooIds: [Int]) {
      allProducts(odooIds: $odooIds) {
         id
         odooId
         productLine {
            id
            hsCodeForEu
         }
         itemWeight
         grossWeight
         quantityPerCarton
         ctnNetWeight
         ctnGrossWeight
      }
   }
`;

const FETCH_GENERATED_DOCUMENT = gql`
   query FETCH_GENERATED_DOCUMENT($id: ID!) {
      generatedDocument(id: $id) {
         id
         name
         data
      }
   }
`;

const InterCompanyInvoiceForm = ({ odooStockPickingDetail }) => {
   const odooIds = odooStockPickingDetail.moves_without_package.map(
      (line) => line.product_id[0]
   );
   const { loading, error, data } = useQuery(FETCH_PRODUCTS_BY_ODOO_IDS, {
      variables: { odooIds },
   });

   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;

   const consignee = `WABOBA AB
Hornsgatan 110
117 26 Stockholm Sweden
VAT: SE556503946701`;
   const deliveryAddress = `Waboba AB
FBA: Waboba AB
XAR1 - KN AFTX (Eskilstuna, SE) Varnvagen 3
ESKILSTUNA, Sodermanlan 63506 Zweden`;

   const transfer = {
      ...odooStockPickingDetail,
      currencyName: odooStockPickingDetail.currencyName,
      consignee,
      deliveryAddress,
      lines: odooStockPickingDetail.moves_without_package.map((line) => {
         const wisProduct = data.allProducts.find(
            (i) => i.odooId === line.product_id[0]
         );

         let hsCode = '';
         let itemWeight = 0;
         let totalItemWeight = 0;
         let netWeight = 0;
         let grossWeight = 0;
         let unitPrice = 0;
         let cartons = 1;
         if (wisProduct) {
            itemWeight = wisProduct.itemWeight / 1000;

            if (wisProduct.productLine) {
               hsCode = wisProduct.productLine.hsCodeForEu;
               if (line.product_qty) {
                  totalItemWeight = itemWeight * line.product_qty;

                  if (
                     wisProduct.quantityPerCarton &&
                     wisProduct.quantityPerCarton > 0
                  ) {
                     cartons = line.product_qty / wisProduct.quantityPerCarton;
                  }
               }
            }
         }

         return {
            id: line.id,
            name: line.product_id[1],
            hsCode,
            itemWeight,
            totalItemWeight: Math.round(totalItemWeight / 1000) * 1000,
            qty: line.product_qty,
            cartons,
            netWeight,
            grossWeight,
            unitPrice: unitPrice,
            wisProduct,
         };
      }),
   };

   return <Content initialData={transfer} />;
};

const Content = ({ initialData }) => {
   const [name, setName] = useState(initialData.name);
   const [consignee, setConsignee] = useState(initialData.consignee);
   const [deliveryAddress, setDeliveryAddress] = useState(
      initialData.deliveryAddress
   );
   const [invoiceDate, setInvoiceDate] = useState(new Date());
   const [shippingTerm, setShippingTerm] = useState('DELIVERED AT PLACE');
   const [packingListNumber, setPackingListNumber] = useState('');
   const [lines, setLines] = useState(
      initialData.lines.map((i) => ({ ...i, selected: true }))
   );
   const [notes, setNotes] = useState(`Made in China
In terms of VAT: Intra Community Delivery`);

   const prevInvoicesModal = useModals();

   const [fetchGeneratedDocument] = useLazyQuery(FETCH_GENERATED_DOCUMENT);
   const [generateDocument] = useMutation(GENERATE_DOCUMENT, {
      onError: (error) => Alert('error', error.message),
   });
   const [isGenerating, setIsGenerating] = useState(false);

   const hsCodes = lines.reduce((res, line) => {
      if (res.indexOf(line.hsCode) === -1) return [...res, line.hsCode];
      return res;
   }, []);

   const currency = CURRENCIES.find((i) => i.code === initialData.currencyName);
   const currencySymbol = currency ? currency.symbol : '$';

   const hsCodeGroups = hsCodes.map((hsCode) => {
      const groupLines = lines
         .filter((i) => i.hsCode === hsCode)
         .map((line) => ({
            ...line,
            totalNetWeight: parseFloat(line.netWeight) * line.cartons || 0,
            totalGrossWeight: parseFloat(line.grossWeight) * line.cartons || 0,
            totalPrice: parseFloat(line.unitPrice) * line.qty || 0,
         }));
      const selectedLines = groupLines.filter((i) => i.selected);
      return {
         hsCode,
         lines: groupLines,
         totalQty: selectedLines.reduce((res, line) => res + line.qty, 0),
         totalItemWeight: selectedLines.reduce(
            (res, line) => res + parseFloat(line.totalItemWeight) || 0,
            0
         ),
         totalNetWeight: selectedLines.reduce(
            (res, line) => res + line.totalNetWeight,
            0
         ),
         totalGrossWeight: selectedLines.reduce(
            (res, line) => res + line.totalGrossWeight,
            0
         ),
         totalPrice: selectedLines.reduce(
            (res, line) => res + line.totalPrice,
            0
         ),
      };
   });

   const selectedHsCodeGroups = hsCodeGroups
      .map((group) => ({
         ...group,
         lines: group.lines.filter((line) => line.selected),
      }))
      .filter((group) => group.lines.length > 0);

   const selectedLines = lines.filter((i) => i.selected);
   const totalQty = selectedLines.reduce(
      (res, line) => res + parseInt(line.qty),
      0
   );
   const totalItemWeight = hsCodeGroups
      .reduce(
         (res, group) => (res += parseFloat(group.totalItemWeight) || 0),
         0
      )
      .toFixed(2);
   const totalNetWeight = hsCodeGroups
      .reduce((res, group) => (res += group.totalNetWeight), 0)
      .toFixed(2);
   const totalGrossWeight = hsCodeGroups
      .reduce((res, group) => (res += group.totalGrossWeight), 0)
      .toFixed(2);
   const totalPrice = hsCodeGroups
      .reduce((res, group) => res + group.totalPrice, 0)
      .toFixed(2);
   const sayTotalString = 'Say Total US Dollars: ' + sayTotal(totalPrice);

   const variables = {
      docType: 'inter_company_invoice',
      name,
      data: JSON.stringify(
         {
            lines,
            hsCodeGroups: selectedHsCodeGroups,
            name,
            invoiceDate: formatDate(invoiceDate, 'YYYY-MM-DD'),
            consignee,
            deliveryAddress,
            shippingTerm,
            packingListNumber,
            totalQty,
            totalItemWeight,
            totalNetWeight,
            totalGrossWeight,
            totalPrice,
            sayTotalString,
            currencySymbol,
            notes,
         },
         '',
         4
      ),
   };

   function onSelectPrevCI(id) {
      prevInvoicesModal.hide();
      fetchGeneratedDocument({ variables: { id } })
         .then((res) => {
            const prevData = JSON.parse(res.data.generatedDocument.data);
            if (!prevData.lines) return;
            console.log('prevData', prevData);
            setLines((lines) =>
               lines.map((line) => {
                  const found = prevData.lines.find((i) => i.id === line.id);
                  if (found)
                     return {
                        ...line,
                        unitPrice: found.unitPrice,
                        hsCode: found.hsCode,
                     };
                  return line;
               })
            );
         })
         .catch((error) => Alert('error', error.message));
   }

   function downloadHandler() {
      setIsGenerating(true);
      generateDocument({ variables })
         .then((res) => {
            return http.get(
               `${process.env.REACT_APP_SERVER_BASE_URL}generated-document/${res.data.generateDocument.document.id}/?pdf=1`,
               { responseType: 'blob', withCredentials: true }
            );
         })
         .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', name + '.pdf');
            document.body.appendChild(link);
            link.click();
            setIsGenerating(false);
         })
         .catch((e) => {
            Alert('error', e.message);
            setIsGenerating(false);
         });
   }

   function onChangeLine(id, key, value) {
      setLines((prev) =>
         prev.map((i) => {
            if (i.id !== id) return i;
            return { ...i, [key]: value };
         })
      );
   }

   return (
      <div>
         <div className="flex space-x-8">
            <div className="flex-1 space-y-3">
               <div className="flex items-center space-x-3">
                  <label htmlFor="">Inv No.:</label>
                  <Input
                     value={name}
                     onChange={(e) => setName(e.target.value)}
                  />
               </div>

               <div>
                  <label htmlFor="">Consignee:</label>
                  <Text
                     className="mt-2"
                     value={consignee}
                     onChange={(e) => setConsignee(e.target.value)}
                  />
               </div>

               <div>
                  <label htmlFor="">Delivery Address:</label>
                  <Text
                     className="mt-2"
                     value={deliveryAddress}
                     onChange={(e) => setDeliveryAddress(e.target.value)}
                  />
               </div>
            </div>

            <div className="flex-1 space-y-3">
               <div className="flex items-center space-x-3">
                  <label htmlFor="">Inv Date:</label>
                  <DatePicker
                     value={invoiceDate}
                     onDayChange={setInvoiceDate}
                  />
               </div>

               <div className="flex items-center space-x-3">
                  <label htmlFor="">Shipping Term:</label>
                  <Input
                     value={shippingTerm}
                     onChange={(e) => setShippingTerm(e.target.value)}
                  />
               </div>
               <div className="flex items-center space-x-3">
                  <label htmlFor="">Packinglist Number:</label>
                  <Input
                     value={packingListNumber}
                     onChange={(e) => setPackingListNumber(e.target.value)}
                  />
               </div>
            </div>
         </div>

         <div className="mt-6 px-2">
            <Button
               title="Load Data from Previous Generated Commercial Invoice"
               onClick={() =>
                  prevInvoicesModal.present({
                     title: 'Previouse Generated',
                     children: (
                        <GeneratedDocuments
                           docType="inter_company_invoice"
                           onSelect={onSelectPrevCI}
                        />
                     ),
                  })
               }
            />
         </div>

         <div className="relative overflow-auto mt-6">
            <table>
               <thead className="text-left whitespace-nowrap">
                  <tr>
                     <th></th>
                     <th>Product</th>
                     <th className="text-right">HS Code</th>
                     <th className="text-right">Qty</th>
                     <th className="text-right">Weight(KG)</th>
                     <th className="text-right">Price / Unit</th>
                     <th className="text-right">Total Price</th>
                  </tr>
               </thead>
               <tbody>
                  {hsCodeGroups.map((group, index) => (
                     <HSGroup
                        key={index}
                        group={group}
                        onChangeLine={onChangeLine}
                        currencySymbol={currencySymbol}
                        hasMultipleHsGroups={selectedHsCodeGroups.length > 1}
                     />
                  ))}
               </tbody>
               <tfoot>
                  <tr className="text-right font-semibold text-xl">
                     <td className="text-left p-2 py-4" colSpan={3}>
                        Total
                     </td>
                     <td className="p-2 py-4">{totalQty}</td>
                     <td className="p-2 py-4">{totalItemWeight}</td>
                     <td className="p-2 py-4"></td>
                     <td className="p-2 py-4">
                        {currencySymbol}
                        {totalPrice}
                     </td>
                  </tr>
                  <tr>
                     <td colSpan={5} className="px-2 text-xl">
                        {sayTotalString}
                     </td>
                  </tr>
               </tfoot>
            </table>
         </div>

         <div className="mt-8">
            <label className="px-2">Foot Notes:</label>
            <Text
               className="mt-2"
               value={notes}
               onChange={(e) => setNotes(e.target.value)}
            />
         </div>

         <hr />

         <div className="my-8 pb-16 flex space-x-8">
            <Button
               size="xl"
               bold
               title="Download"
               loading={isGenerating}
               disabled={isGenerating}
               onClick={downloadHandler}
            />
         </div>
      </div>
   );
};

const HSGroup = ({
   group,
   currencySymbol,
   hasMultipleHsGroups,
   onChangeLine,
}) => {
   return (
      <>
         {group.lines.map((line, index) => (
            <tr key={index} className="whitespace-nowrap">
               <td>
                  <CheckBox
                     checked={line.selected}
                     onChange={(checked) =>
                        onChangeLine(line.id, 'selected', checked)
                     }
                  />
               </td>
               <td>{line.name}</td>
               <td className="text-right">
                  <Input
                     className="text-right"
                     value={line.hsCode}
                     onChange={(e) =>
                        onChangeLine(line.id, 'hsCode', e.target.value)
                     }
                  />
               </td>
               <td className="text-right">
                  <Input
                     className="text-right w-20"
                     value={line.qty}
                     onChange={(e) => {
                        const value = parseInt(e.target.value);
                        onChangeLine(line.id, 'qty', value || 0);
                     }}
                  />
               </td>
               <td className="text-right">
                  <Input
                     className="text-right w-20"
                     value={line.totalItemWeight}
                     onChange={(e) => {
                        onChangeLine(
                           line.id,
                           'totalItemWeight',
                           e.target.value
                        );
                     }}
                  />
               </td>
               <td className="text-right">
                  <Input
                     className="text-right w-20"
                     value={line.unitPrice}
                     onChange={(e) => {
                        onChangeLine(line.id, 'unitPrice', e.target.value);
                     }}
                  />
               </td>
               <td className="text-right">{line.totalPrice.toFixed(2)}</td>
            </tr>
         ))}

         {!group.hsCode ? (
            <tr>
               <td></td>
            </tr>
         ) : hasMultipleHsGroups ? (
            <tr className="font-bold">
               <td colSpan={3}></td>
               <td className="text-right">{group.totalQty}</td>
               <td className="text-right">
                  {group.totalItemWeight.toFixed(3)}
               </td>
               <td></td>
               <td className="text-right">
                  {currencySymbol}
                  {group.totalPrice.toFixed(2)}
               </td>
            </tr>
         ) : null}
      </>
   );
};

export default InterCompanyInvoiceForm;
