import { gql, useQuery } from "@apollo/client";
import Errors from "components/Errors";
import Spinner from "components/Spinner";
import { Link } from "react-router-dom";

const FETCTH_PRODUCT_LISTS_FOR_TEST_REPORT_VALIDATION = gql`
  query FETCTH_PRODUCT_LISTS_FOR_TEST_REPORT_VALIDATION {
    allProductLists {
      id
      name
      version
      testReports {
        id
        standard {
          id
          name
        }
        fileList
      }
    }
  }
`;

const ProductListTestReports = () => {
  const { loading, error, data } = useQuery(FETCTH_PRODUCT_LISTS_FOR_TEST_REPORT_VALIDATION);

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  return (
    <div className="card rounded-xl m-6 p-0 h-full overflow-auto">
      <table>
        <thead>
          <tr className="sticky top-0 backdrop-blur bg-gray-50 dark:bg-gray-800 bg-opacity-70 z-10">
            <th className="px-6 py-3">Product List ({data.allProductLists.length})</th>
            <th>Test Reports</th>
          </tr>
        </thead>
        <tbody>
          {data.allProductLists.map((i) => (
            <tr key={i.id} className="border-t dark:border-gray-700">
              <td className="px-6">
                <Link to={`/product-list/${i.id}`} target="_blank">
                  {i.name} (v{i.version})
                </Link>
              </td>
              <td>
                {i.testReports.map((x, index) => (
                  <div key={x.id} className={`py-2 ${index > 0 ? "border-t " : ""}`}>
                    <div className="font-semibold">{x.standard.name}</div>
                    {x.fileList.length > 0 && (
                      <div className="mt-1">
                        {x.fileList.map((url) => (
                          <div key={url} className="">
                            <a href={url} target="_blank" rel="noreferrer">
                              {url}
                            </a>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </td>
              <td></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ProductListTestReports;
