import { gql, useQuery } from "@apollo/client";
import Errors from "components/Errors";
import Page from "components/Page";
import Spinner from "components/Spinner";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import { useRef } from "react";
import { Alert } from "components/Toast";

const FETCH_ODOO_SALES_ANALYTICS = gql`
  query FETCH_ODOO_SALES_ANALYTICS {
    powerbiEmbeddedInfo
  }
`;

const SalesAnalytic = () => {
  const { loading, error, data } = useQuery(FETCH_ODOO_SALES_ANALYTICS);
  const containerRef = useRef(null);
  const iframeRef = useRef(null);

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const res = JSON.parse(data.powerbiEmbeddedInfo);
  const report = res.reportConfig[0];

  return (
    <Page title="Sales Analytics" subtitle="Power BI Odoo Sales Report" className="h-screen">
      <div className="p-6 flex-1 flex flex-col" ref={containerRef}>
        <PowerBIEmbed
          ref={iframeRef}
          embedConfig={{
            type: "report",
            id: report.reportId,
            embedUrl: report.embedUrl,
            accessToken: res.accessToken,
            tokenType: models.TokenType.Embed,
            settings: {
              panes: {
                filters: {
                  // expanded: false,
                  visible: false,
                },
              },
            },
          }}
          cssClassName="flex-1"
          eventHandlers={
            new Map([
              [
                "loaded",
                function () {
                  console.log("Report loaded");
                },
              ],
              [
                "rendered",
                function () {
                  console.log("Report rendered");
                },
              ],
              [
                "error",
                function (event) {
                  Alert("error", event.detail);
                },
              ],
              ["visualClicked", () => console.log("visual clicked")],
              ["pageChanged", (event) => console.log(event)],
            ])
          }
          getEmbeddedComponent={(embeddedReport) => {
            console.log("embeddedReport", embeddedReport);
          }}
        />
      </div>
    </Page>
  );
};

export default SalesAnalytic;
