import React, { useState } from "react";
import { AiFillExclamationCircle } from "react-icons/ai";
import { Button, ButtonWithArrow } from "components/base";
import QcStatus from "components/QcStatus";

const ShipmentInvoiceRowQcReport = ({ invoiceReports, row }) => {
  const [showNoReportsInfo, setShowNoReportsInfo] = useState(false);
  const qcReports = invoiceReports.filter((i) => i.product.id === row.product.id && i.deletedAt === null);

  if (qcReports.length === 0)
    return (
      <div>
        <div className="flex space-x-4 items-center">
          <label className="text-red-600 flex items-center">
            <AiFillExclamationCircle size={18} />
            <span className="ml-2">No QC Reports. Product ID: {row.product.id}</span>
          </label>

          <ButtonWithArrow className="text-xs" show={showNoReportsInfo} onClick={() => setShowNoReportsInfo((prev) => !prev)}>
            check
          </ButtonWithArrow>
        </div>

        {showNoReportsInfo ? (
          <div className="mt-2 text-xs">
            <label htmlFor="">Reports for this order:</label>
            <div>
              {invoiceReports.map((report, index) => (
                <div key={index}>
                  {report.name}, product id: {report.product.id}
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </div>
    );
  return (
    <div className="">
      {qcReports.map((report) => (
        <div key={report.id} className="flex items-baseline">
          <QcStatus textSize="text-md" status={report.status} />
          <label className="ml-4">&times; {report.qty}</label>
          <Button className="ml-3" next iconSize={18} title="View Report" link={`/qc/reports/${report.id}/preview`} target="_blank" />
        </div>
      ))}
    </div>
  );
};

export default ShipmentInvoiceRowQcReport;
