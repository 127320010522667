import { Button } from "components/base";
import { useContext, useEffect, useState } from "react";
import { IoIosCheckmarkCircle, IoIosCheckmarkCircleOutline } from "react-icons/io";
import { useMutation } from "@apollo/client";
import { CREATE_SHIPMENT_BOM } from "../graphql";
import ShipmentContext from "./ShipmentContext";
import { Alert } from "components/Toast";
import { download } from "utils/http";
import CharlesButton from "components/charles/base";
import { BsArrowDownCircle } from "react-icons/bs";

const BomSelector = ({ saveShipment }) => {
  const { state } = useContext(ShipmentContext);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [createShipmentBom] = useMutation(CREATE_SHIPMENT_BOM);
  const [exporting, setExporting] = useState(false);

  useEffect(() => {
    if (state.shipmentInvoices) {
      setSelectedRowIds(state.shipmentInvoices.flatMap((i) => i.shipmentInvoiceRows).map((i) => i.id));
    }
  }, [state.shipmentInvoices]);

  function onClickHandler() {
    setExporting(true);
    saveShipment(false, () => {
      createShipmentBom({
        variables: {
          shipmentId: state.id,
          shipmentInvoiceRowIds: selectedRowIds,
        },
      })
        .then((res) => {
          setExporting(false);
          download(`/doc/${res.data.createShipmentBom.document.id}/?excel=1`, `BOM-SO${state.invoiceNumber}.xlsx`);
        })
        .catch((err) => {
          Alert("error", String(err));
        })
        .finally(() => {
          setExporting(false);
        });
    });
  }

  return (
    <div className="px-8 pb-8">
      <div>
        <label htmlFor="">Select Products to Generate BOM file.</label>
      </div>
      <div className="mt-4 space-y-6">
        {state.shipmentInvoices.map((shipmentInvoice) => (
          <div key={shipmentInvoice.id} className="card px-6 py-4">
            <h4>SO{shipmentInvoice.invoice.number}</h4>
            <div className="mt-2">
              {shipmentInvoice.shipmentInvoiceRows.map((row) => (
                <div
                  key={row.id}
                  className={`p-2 border-b flex items-center space-x-4 cursor-pointer hover:text-green-600
                  ${selectedRowIds.includes(row.id) ? "text-green-500 active:text-gray-700" : "text-gray-700 active:text-green-700"}`}
                  onClick={() => setSelectedRowIds((prev) => (selectedRowIds.includes(row.id) ? prev.filter((i) => i !== row.id) : [...prev, row.id]))}
                >
                  <div className="flex-shrink-0">
                    {selectedRowIds.includes(row.id) ? <IoIosCheckmarkCircle size={21} /> : <IoIosCheckmarkCircleOutline size={21} />}
                  </div>
                  <div className="flex justify-between flex-1">
                    <div>
                      [{row.product.number}] {row.product.name}
                    </div>
                    <div>{row.qty}</div>
                  </div>
                </div>
              ))}
            </div>

            <div className="mt-4">
              <Button
                size="xs"
                title="Toggle Select"
                onClick={() => setSelectedRowIds((prev) => (prev.length > 0 ? [] : shipmentInvoice.shipmentInvoiceRows.map((i) => i.id)))}
              />
            </div>
          </div>
        ))}
      </div>

      <div className="mt-6 text-base flex justify-end">
        <CharlesButton loading={exporting} onClick={onClickHandler} icon={<BsArrowDownCircle />}>
          Download
        </CharlesButton>
      </div>
    </div>
  );
};

export default BomSelector;
