import React from "react";
import { Button } from "components/base.js";
import { CHECK_STATUS } from "../utils.js";
import QcStatus from "components/QcStatus.js";
import Spinner from "components/Spinner.js";

function Summary({ reportId, packageCheck, qualityCheck, logoCheck, status, saveSimpleQcReport, loading = false }) {
  return (
    <div className="my-6 bg-white dark:bg-gray-800 p-4 lg:p-6 border border-gray-200 dark:border-gray-700">
      <div className="lg:flex items-baseline">
        <div className="text-base font-bold">OVERALL INSPECTION CONCLUTION </div>
        {loading ? <Spinner /> : <QcStatus className="mt-2 lg:mt-0 lg:ml-4" status={status} />}
      </div>

      <div className="mt-4">
        <label htmlFor="">Package 包装:</label>
        <div className="flex mt-2">
          {CHECK_STATUS.map((i, index) => (
            <Button
              key={index}
              className="mr-4"
              size="sm"
              title={i}
              border
              color={i === packageCheck ? "sky" : null}
              onClick={() => saveSimpleQcReport({ variables: { id: reportId, packageCheck: i, logoCheck } })}
            />
          ))}
        </div>
      </div>
      <div className="mt-4">
        <label htmlFor="">Quality Check 质检(Auto 自动计算):</label>
        <div className="flex mt-2">
          {CHECK_STATUS.map((i, index) => (
            <Button key={index} className="mr-4" size="sm" title={i} border color={i === qualityCheck ? "sky" : null} disabled={i !== qualityCheck} />
          ))}
        </div>
      </div>
      <div className="mt-4">
        <label htmlFor="">Logo Fastness Test 色牢度测试:</label>
        <div className="flex mt-2">
          {CHECK_STATUS.map((i, index) => (
            <Button
              key={index}
              className="mr-4"
              size="sm"
              title={i}
              border
              color={i === logoCheck ? "sky" : null}
              onClick={() => saveSimpleQcReport({ variables: { id: reportId, packageCheck, logoCheck: i } })}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Summary;
