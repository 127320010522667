import { useMutation } from "@apollo/client";
import { formatDate } from "react-day-picker/moment";
import { Input, Select } from "components/Form";
import { useContext, useState } from "react";
import { CREATE_ODOO_ORDER, ODOO_GENERATE_SO_PDF } from "./graphql";
import { Alert } from "components/Toast";
import odooIcon from "assets/odoo-icon.svg";
import OdooDeliveryMethodSelector from "components/OdooDeliveryMethodSelector";
import OdooTaxSelector from "components/OdooTaxSelector";
import http from "utils/http";
import OdooPriceListSelector from "components/OdooPriceListSelector";
import OdooWarehouseSelector from "components/OdooWarehouseSelector";
import CharlesButton from "components/charles/base";
import { useOdooProducts } from "hooks/useOdooProduct";
import { InlineSpinner } from "components/Spinner";
import Errors from "components/Errors";
import { useEffect } from "react";
import { OdooContext } from "OdooProvider";

const REGION_PRICE_LISTS = { GB: 68, DE: 67, IT: 69, ES: 71, FR: 70 };

const CreateOdooOrderForm = ({ regionCode, order, wisOrder, fcCode, odooCustomer, odooDeliveryAddress }) => {
  const { odooUrl } = useContext(OdooContext);
  const [salesStage, setsalesStage] = useState(new Date(order.windowStart) > new Date() ? "awaiting prepayment" : "pending");

  const [odooWarehouseId, setOdooWarehouseId] = useState("0");
  const [odooDeliveryMethodId, setOdooDeliveryMethodId] = useState(3);
  const [odooTaxId, setOdooTaxId] = useState(regionCode === "GB" ? 21 : 3);
  const [odooPriceListId, setOdooPriceListId] = useState(REGION_PRICE_LISTS[regionCode]);

  const [downloadingOdooSoPdf, setDownloadingOdooSoPdf] = useState(false);

  const odooProductIds = wisOrder.lines.filter((line) => line.stock && line.stock.odooId).map((line) => line.stock.odooId);

  const { loading, error, odooProducts } = useOdooProducts({
    ids: odooProductIds,
  });

  const [lines, setLines] = useState([]);

  useEffect(() => {
    if (odooProducts) {
      setLines(
        wisOrder.lines.map((line) => ({
          ...line,
          odooProduct: line.stock ? odooProducts.find((p) => p.id === line.stock.odooId) : null,
          cost: line.netCostAmount,
        }))
      );
    }
  }, [odooProducts]);

  const [createOdooOrder, createOdooOrderRes] = useMutation(CREATE_ODOO_ORDER, {
    onCompleted() {
      Alert("success", "Odoo Order Created.");
    },
    onError(error) {
      Alert("error", error.message);
    },
    refetchQueries: ["FETCH_IMPORTED_AMAZON_PURCHASE_ORDERS"],
    awaitRefetchQueries: true,
  });

  const [generateSoDocument] = useMutation(ODOO_GENERATE_SO_PDF, {
    onError(error) {
      Alert("error", error.message);
    },
  });

  if (loading) return <InlineSpinner />;
  if (error) return <Errors error={error} />;

  function onChangeLinePrice(index, cost) {
    setLines((prev) =>
      prev.map((line, prevIndex) => {
        if (prevIndex !== index) return line;
        return { ...line, cost };
      })
    );
  }

  const odooOrderLines = lines
    .filter((i) => i.odooProduct && i.actionType === "accept")
    .map((i) => [
      0,
      0,
      {
        name: i.odooProduct.displayName,
        product_id: i.odooProduct.id,
        product_uom_qty: i.actionQty,
        price_unit: i.cost,
        tax_id: [[6, false, [odooTaxId]]],
      },
    ]);

  const odooDeliveryScheduleDate = order.windowStart.slice(0, 10);
  const odooExpectedDeliveryDate = order.windowEnd.slice(0, 10);

  function createOrderInOdooHandler() {
    const orderDate = formatDate(order.orderDetails.purchaseOrderDate, "YYYY-MM-DD");

    const termNL = "DUTCH VAT Number: NL826724838B01. Goods shipped from The Netherlands.";
    const termUK = "UK VAT Number: GB324122352. Goods shipped from United Kingdom.";

    let note = "";
    if (odooWarehouseId === "3") note = termNL;
    if (odooWarehouseId === "5") note = termUK;

    const dataForOdoo = {
      client_order_ref: order.purchaseOrderNumber,
      incoterm: 15,
      order_line: odooOrderLines,
      partner_id: odooCustomer.id,
      partner_invoice_id: odooCustomer.id,
      partner_shipping_id: odooDeliveryAddress.id,
      payment_term_id: 6,
      picking_policy: "direct",
      pricelist_id: odooPriceListId,
      team_id: 1,
      user_id: 12,
      warehouse_id: odooWarehouseId,
      x_deliverymethod: odooDeliveryMethodId,
      x_salesstage: salesStage,
      x_do_min_date: odooDeliveryScheduleDate,
      x_studio_expected_delivery_date: odooExpectedDeliveryDate,
      note,
    };

    createOdooOrder({
      variables: {
        region: regionCode,
        number: order.purchaseOrderNumber,
        orderDate,
        data: JSON.stringify(dataForOdoo, "", 4),
      },
    });
  }

  function downloadHandler() {
    setDownloadingOdooSoPdf(true);
    const name = `Quotation - ${wisOrder.odooSalesOrderName}.pdf`;
    generateSoDocument({ variables: { id: wisOrder.odooSalesOrderId, name } })
      .then((res) =>
        http.get(`${process.env.REACT_APP_SERVER_BASE_URL}generated-document/${res.data.odooGenerateSoPdf.document.id}/?pdf=1`, {
          responseType: "blob",
          withCredentials: true,
        })
      )
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", name);
        document.body.appendChild(link);
        link.click();
        setDownloadingOdooSoPdf(false);
      })
      .catch((e) => {
        Alert("error", e.message);
        setDownloadingOdooSoPdf(false);
      });
  }

  return (
    <div>
      <div className="bg-white dark:bg-gray-900 rounded-2xl shadow-sm p-6 flex-1 relative mt-6">
        <div className="flex items-center space-x-4">
          <img style={{ height: 18 }} src={odooIcon} alt="odoo product" />
          <h4>ODOO Info</h4>
        </div>

        <div className="flex items-center space-x-4 mt-4">
          <label>Odoo Customer: </label>
          <div>{odooCustomer ? odooCustomer.display_name : " - "}</div>
        </div>

        <div className="mt-6">
          {odooCustomer ? (
            <div className="mt-4 flex space-x-16">
              <div className="">
                <label className="mr-2">Invoice Address: </label>
                <div className="whitespace-pre-wrap mt-2">{odooCustomer.contact_address}</div>
              </div>

              <div className="">
                <label className="mr-2">Delivery Address: </label>
                {odooDeliveryAddress ? (
                  <div className="whitespace-pre-wrap mt-2">{odooDeliveryAddress.contact_address}</div>
                ) : (
                  <label className="text-pink-600 whitespace-normal">
                    This customer has no delivery addresses. Please add delivery address for this customer in odoo before you could create order in odoo.
                  </label>
                )}
              </div>
            </div>
          ) : (
            <label className="text-pink-600 pt-2 block whitespace-normal">
              {fcCode} is not connected with any customer in Odoo. Note that you only need to connect once unless you need to change this FC CODE to another
              Odoo customer.
            </label>
          )}
        </div>

        <div className="flex items-center space-x-8 mt-6">
          <div className="flex items-center space-x-4">
            <label htmlFor="">Warehouse: </label>
            <OdooWarehouseSelector value={odooWarehouseId} onSelect={setOdooWarehouseId} filterFunction={(i) => [3, 5].includes(i.id)} />
          </div>

          <div className="flex items-center space-x-4">
            <label htmlFor="">Delivery Method: </label>
            <OdooDeliveryMethodSelector value={odooDeliveryMethodId} onSelect={setOdooDeliveryMethodId} />
          </div>

          <div className="flex items-center space-x-4">
            <label htmlFor="">Sales Stage: </label>
            <Select value={salesStage} onChange={(e) => setsalesStage(e.target.value)}>
              <option value="pending">Pending</option>
              <option value="awaiting prepayment">Awaiting Prepayment</option>
              <option value="confirmed">Confirmed</option>
            </Select>
          </div>
        </div>

        <div>
          {regionCode === "GB" && odooWarehouseId !== "5" ? (
            <div className="card px-6 py-4 bg-red-600 text-white mt-4">
              <div className="font-semibold text-base">Warning</div>
              <div className="mt-1">
                This order is from Amazon Vendor Cetner - <b>{regionCode}</b>, while you are <b>NOT</b> choosing <b>Warehouse UK(Charles Kendle)</b> for Odoo.
                Make sure this is intended!
              </div>
            </div>
          ) : regionCode !== "GB" && odooWarehouseId !== "3" ? (
            <div className="card px-6 py-4 bg-red-600 text-white mt-4">
              <div className="font-semibold text-base">Warning</div>
              <div className="mt-1">
                This order is from Amazon Vendor Cetner - <b>{regionCode}</b>, while you are <b>NOT</b> choosing <b>Warehouse Netherlands</b> for Odoo. Make
                sure this is intended!
              </div>
            </div>
          ) : null}
        </div>

        <div className="flex items-center space-x-4 mt-6">
          <label htmlFor="">Delivery Schedule Date: </label>
          <div>{odooDeliveryScheduleDate}</div>

          <div></div>
          <div></div>

          <label htmlFor="">Expected Delivery Date: </label>
          <div>{odooExpectedDeliveryDate}</div>
        </div>

        <div className="flex items-center space-x-4 mt-6">
          <label htmlFor="">Tax: </label>
          <OdooTaxSelector value={odooTaxId} onSelect={setOdooTaxId} />

          <label htmlFor="">Price List: </label>
          <OdooPriceListSelector value={odooPriceListId} onSelect={(i) => setOdooPriceListId(i.id)} />
        </div>

        <div className="flex items-center space-x-4 mt-6">
          <table className="bg-white dark:bg-gray-900">
            <thead>
              <tr>
                <th className="border dark:border-gray-700 p-4 text-left">Product</th>
                <th className="border dark:border-gray-700 p-4 text-right">Qty Accepted</th>
                <th className="border dark:border-gray-700 p-4 text-right">Unit Price</th>
              </tr>
            </thead>
            <tbody>
              {lines
                .filter((i) => i && i.actionType === "accept")
                .map((line, lineIndex) => (
                  <tr key={lineIndex}>
                    <td className="border dark:border-gray-700 px-4">
                      <div>
                        {line.odooProduct ? (
                          <div className="flex items-center">
                            <img className="mr-2" style={{ height: 16 }} src={odooIcon} alt="odoo product" />
                            <div>{line.odooProduct.displayName}</div>
                          </div>
                        ) : (
                          <div className="text-pink-600 font-bold">{`ASIN ${line.asin} `} is NOT found.</div>
                        )}
                      </div>
                    </td>

                    <td className="border dark:border-gray-700 px-4 text-right">{line ? line.actionQty : ""}</td>

                    <td className="border dark:border-gray-700 px-4 text-right">
                      <div className="flex justify-end items-center space-x-2">
                        <label htmlFor="">{line.netCostCurrency}</label>
                        <Input className="text-right w-20" value={line.cost} onChange={(e) => onChangeLinePrice(lineIndex, e.target.value)} />
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>

      <div>
        <div className="space-y-8 py-8 mt-4">
          {!wisOrder.odooSalesOrderId ? (
            <div>
              <CharlesButton
                onClick={createOrderInOdooHandler}
                loading={createOdooOrderRes.loading}
                disabled={
                  createOdooOrderRes.loading || odooWarehouseId === "0" || odooOrderLines.length === 0 || odooCustomer === null || odooDeliveryAddress === null
                }
              >
                Create Order in Odoo
              </CharlesButton>

              <div className="italic mt-2 space-y-2">
                <p>
                  By clicking the <b>Create Order in Odoo</b> button, WIS create a new Sales Order in Odoo with and only with the <b>accepted</b> items which is{" "}
                  <b>has the same barcode</b> as the item external Identifier in Amazon. If there is some items not found in Odoo, consider change the order
                  manually after, or you could create a new item first in Odoo and then come back here and it will find that product.
                </p>
                <p>Note that in this step, the stock data will not be effected.</p>
              </div>
            </div>
          ) : (
            <>
              <div className="text-xl flex items-center">
                <img className="mr-2" style={{ height: 16 }} src={odooIcon} alt="odoo product" />
                <label htmlFor="">Odoo Sales Order: </label>
                <a
                  className="ml-2"
                  href={`${odooUrl}/web#id=${wisOrder.odooSalesOrderId}&cids=1&menu_id=408&action=312&model=sale.order&view_type=form`}
                  target="_blank"
                  rel="wis"
                >
                  {wisOrder.odooSalesOrderName}
                </a>

                <CharlesButton className="ml-8" onClick={downloadHandler} loading={downloadingOdooSoPdf} disabled={downloadingOdooSoPdf}>
                  Download (Quotation / ORDER CONFIRMATION)
                </CharlesButton>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateOdooOrderForm;
