import { useContext } from "react";
import { OrderContext } from "./OrderDetail";
import { v4 } from "uuid";
import CharlesButton from "components/charles/base";
import OrderLineView from "./OrderLineView";
import { useQuery } from "@apollo/client";
import { FETCH_PRODUCTS_FOR_ORDER } from "./graphql";
import { InlineSpinner } from "components/Spinner";
import Errors from "components/Errors";

const ProductsView = () => {
  const { order, setOrder, validStocks } = useContext(OrderContext);
  const { loading, error, data } = useQuery(FETCH_PRODUCTS_FOR_ORDER, {
    variables: { odooIds: validStocks.map((i) => i.odooId) },
    fetchPolicy: "network-only",
  });

  const availableProducts = data
    ? data.products.results.map((i) => {
        const stock = validStocks.find((j) => j.odooId === i.odooId);
        return { ...i, name: `[${i.number}] ${i.name}`, stock };
      })
    : [];

  function addLine() {
    setOrder((prev) => ({
      ...prev,
      orderLines: [
        ...prev.orderLines,
        {
          id: `new-${v4()}`,
          product: null,
          qty: 1,
          salesPrice: 0,
          discountPercentage: 0,
        },
      ],
    }));
  }

  return (
    <div className="card">
      <h5>Products</h5>
      {loading ? (
        <div className="relative p-10">
          <InlineSpinner text="Loading products..." />
        </div>
      ) : error ? (
        <Errors errors={error} />
      ) : (
        <div className="mt-2 -mx-2">
          <table>
            <thead className="whitespace-nowrap">
              <tr>
                <th>Item</th>
                <th className="text-right">Qty / Set</th>
                <th className="text-right">Stock</th>
                <th className="text-right">Qty</th>
                <th className="text-right">salesPrice</th>
                <th className="text-right">Discount %</th>
                <th className="text-right">Subtotal{order.priceList ? `(${order.priceList.currency})` : null}</th>
              </tr>
            </thead>
            <tbody>
              {order.orderLines.map((line) => (
                <OrderLineView line={line} key={line.id} availableProducts={availableProducts} />
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td>
                  <CharlesButton onClick={addLine}>+ Add Product</CharlesButton>
                </td>
                <td></td>
                <td></td>
                <td className="text-right">{order.orderLines.reduce((acc, line) => acc + parseInt(line.qty), 0)}</td>
                <td></td>
                <td></td>
                <td className="text-right">
                  {order.orderLines
                    .reduce((acc, line) => acc + parseInt(line.qty) * parseFloat(line.salesPrice) * ((100 - parseFloat(line.discountPercentage)) / 100), 0)
                    .toFixed(2)}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      )}
    </div>
  );
};

export default ProductsView;
