import { useParams } from "react-router-dom";
import Page from "components/Page";
import CharlesButton from "components/charles/base";
import { Fragment, useState } from "react";
import moment from "moment";
import XLSX from "xlsx";
import buildReport from "./buildReport";
import { showCurrencySymbol } from "utils/string";

const LumpSumSupplierView = ({ plans, suppliers }) => {
  const { supplierId } = useParams();

  const supplier = suppliers.find((i) => i.id === parseInt(supplierId));

  let plansFromSupplierIds = [];
  supplier.products.forEach((product) => {
    product.plans.forEach((plan) => {
      if (!plansFromSupplierIds.includes(plan.id)) {
        plansFromSupplierIds.push(plan.id);
      }
    });
  });
  const computedPlans = plans.map((plan) => ({
    ...plan,
    hasProductForSupplier: plansFromSupplierIds.includes(parseInt(plan.id)),
  }));
  const supplierPlans = computedPlans.filter((i) => i.hasProductForSupplier);

  const [selectedPlanIds, setSelectedPlanIds] = useState(supplierPlans.map((i) => i.id));

  const products = supplier.products
    .map((i) => {
      const plans = i.plans
        .filter((plan) => selectedPlanIds.includes(`${plan.id}`))
        .map((plan) => {
          const res = computedPlans.find((x) => parseInt(x.id) === plan.id);
          return {
            ...plan,
            startDate: res.startDate,
            readyDate: res.readyDate,
          };
        });
      const plansQty = plans.reduce((res, plan) => res + plan.qty, 0);
      const totalStock = i.odoo_stock + i.supplier_stock;
      return {
        ...i,
        plans,
        plansQty,
        toProduce: plansQty - totalStock > 0 ? plansQty - totalStock : 0,
      };
    })
    .sort((a, b) => b.toProduce - a.toProduce);

  const [selectedProductIds, setSelectedProductIds] = useState(products.map((i) => i.product_id));

  function downloadHandler() {
    const supplierPlans = computedPlans.filter((i) => i.hasProductForSupplier);
    const selectedProducts = products.filter((i) => selectedProductIds.includes(i.product_id));
    const data = buildReport(supplierPlans, selectedProducts);
    const sheet = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    const filename = `${supplier.name}${moment().format("YYYY-MM-DD")}.xlsx`;
    XLSX.utils.book_append_sheet(wb, sheet, "products");
    XLSX.writeFile(wb, filename);
  }

  function toggleSelect() {
    if (selectedPlanIds.length === 0) {
      setSelectedPlanIds(supplierPlans.map((i) => i.id));
    } else {
      setSelectedPlanIds([]);
    }
  }

  return (
    <Page title={supplier.name} backTo="./.." rightButtons={<CharlesButton onClick={downloadHandler}>Download Report</CharlesButton>} className="h-screen">
      <div className="p-6 grid grid-cols-12 gap-6 flex-1 overflow-auto items-start">
        <div className="col-span-3 card py-4 px-0 overflow-auto max-h-full">
          <div className="px-6">
            <h5>Plans</h5>
            <div>Select plans to view the lump sum.</div>
          </div>
          <div className="mt-2">
            {computedPlans.map((plan) => (
              <label
                className={`flex items-center justify-between space-x-2 py-2 border-b border-gray-100 hover:bg-blue-100 dark:border-gray-700 opacity-100 cursor-pointer px-6
                ${plansFromSupplierIds.includes(parseInt(plan.id)) ? "opacity-100" : "opacity-50 cursor-not-allowed pointer-events-none"}
                ${selectedPlanIds.includes(plan.id) ? "bg-blue-100" : ""}
                `}
                key={plan.id}
                htmlFor={`plan-${plan.id}`}
              >
                <div>
                  <div>{plan.name}</div>
                  <div className="font-normal">{plan.wisCustomer.name}</div>
                  <div className="font-normal opacity-70">
                    {moment(plan.startDate).format("YYYY-MM-DD")} ~ {moment(plan.readyDate).format("YYYY-MM-DD")}
                  </div>
                </div>
                <input
                  disabled={!plan.hasProductForSupplier}
                  type="checkbox"
                  checked={selectedPlanIds.includes(plan.id)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedPlanIds([...selectedPlanIds, plan.id]);
                    } else {
                      setSelectedPlanIds(selectedPlanIds.filter((i) => i !== plan.id));
                    }
                  }}
                  id={`plan-${plan.id}`}
                />
              </label>
            ))}
          </div>
          <div className="mt-2 px-6 flex justify-end">
            <CharlesButton onClick={toggleSelect}>Toggle Select</CharlesButton>
          </div>
        </div>

        <div className="card p-0 col-span-9 overflow-auto max-h-full">
          <table>
            <thead>
              <tr className="whitespace-nowrap sticky top-0 z-20 bg-gray-50">
                <th className="px-4 py-3">
                  <label className="flex items-center space-x-2 opacity-100 cursor-pointer" htmlFor="select-all-products">
                    <input
                      type="checkbox"
                      checked={selectedProductIds.length === products.length}
                      onChange={(e) => setSelectedProductIds(e.target.checked ? products.map((i) => i.product_id) : [])}
                      id="select-all-products"
                    />
                    <span>Product</span>
                  </label>
                </th>
                <th className="px-4 py-3 text-right">QTY</th>
                <th className="px-4 py-3 text-right">Odoo Stock</th>
                <th className="px-4 py-3 text-right ">Supplier Stock</th>
                <th className="px-4 py-3 text-right">To Produce</th>
                <th className="px-4 py-3 text-right">Cost</th>
              </tr>
            </thead>
            <tbody>
              {products.map((i, index) => (
                <Fragment key={index}>
                  <tr className="border-b dark:border-gray-700 font-semibold">
                    <td className="w-full px-4">
                      <label htmlFor={`product-${i.product_id}`} className="flex cursor-pointer space-x-2 hover:text-blue-600 opacity-100">
                        <input
                          type="checkbox"
                          checked={selectedProductIds.includes(i.product_id)}
                          onChange={(e) => {
                            console.log("e.target.checked", e.target.checked);
                            if (e.target.checked) {
                              setSelectedProductIds([...selectedProductIds, i.product_id]);
                            } else {
                              setSelectedProductIds(selectedProductIds.filter((x) => x !== i.product_id));
                            }
                          }}
                          id={`product-${i.product_id}`}
                        />
                        <div>
                          #{index + 1} [{i.product_number}] {i.product_name}
                        </div>
                      </label>
                    </td>
                    <td className="text-right px-4">{i.plansQty}</td>
                    <td className="text-right px-4">{i.odoo_stock}</td>
                    <td className="text-right px-4">{i.supplier_stock}</td>
                    <td className="text-right px-4">{i.toProduce}</td>
                    <td className="text-right px-4 whitespace-nowrap">{i.cost ? `${showCurrencySymbol(i.cost.currency)} ${i.cost.price.toFixed(3)}` : null}</td>
                  </tr>
                  {i.plans.map((plan) => (
                    <tr key={plan.id} className="border-b dark:border-gray-700 text-xs opacity-70">
                      <td className="px-4">
                        <div className="flex justify-between items-center">
                          <div>
                            - {plan.name} {plan.odoo_object_name ? <span className="text-purple-600">({plan.odoo_object_name})</span> : null}
                          </div>
                          <div>{moment(plan.readyDate).format("YYYY-MM-DD")}</div>
                        </div>
                      </td>
                      <td className="text-right px-4">{plan.qty}</td>
                    </tr>
                  ))}
                </Fragment>
              ))}
            </tbody>
          </table>
          <div className="text-xs p-4 sticky bottom-0 bg-gray-50 z-20 text-gray-600">
            <p>QTY is the total QTY of the product in the plans.</p>
            <p>Odoo Stock is the forecasted qty of the product in China Warehouse in Odoo.</p>
            <p>Supplier Stock the qty from supplier stock. The number is excluded from plans.</p>
            <p>Cost is the product cost for the qty to produce.</p>
            <p className=" text-orange-600">Note that product with BOM should not be in the list and the cost for those items need to be checked.</p>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default LumpSumSupplierView;
