import { Routes, Route, Navigate } from "react-router-dom";

import Page from "components/Page";
import { NavigationLink } from "components/base";
import SupplierGradingDashboard from "./Dashboard";
import Questionnaire from "./Questionnaire";
import Tab from "./Tab";

function GradingAnalytics() {
  return (
    <Routes>
      <Route path="/" element={<GradingAnalyticsIndexPage />} />
      <Route path="questionnaire" element={<Questionnaire />} />
      <Route path="quality" element={<Tab indicator="Quality" />} />
      <Route path="lead-time" element={<Tab indicator="LeadTime" />} />
      <Route path="shipment-error" element={<Tab indicator="ShipmentError" />} />
      <Route path="esg" element={<Tab indicator="ESG" />} />
      <Route path="communication" element={<Tab indicator="Communication" />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}

const GradingAnalyticsIndexPage = () => {
  return (
    <Page
      title={
        <div className="flex items-baseline space-x-2">
          <div>Supplier Grading Analytics</div>
          <div className="text-base opacity-70 font-normal -translate-y-px">[beta 1.0]</div>
        </div>
      }
      leftButtons={
        <div className="flex space-x-4">
          <NavigationLink to="quality" title="Quality" />
          <NavigationLink to="lead-time" title="LeadTime" />
          <NavigationLink to="shipment-error" title="ShipmentError" />
          <NavigationLink to="esg" title="ESG" />
          <NavigationLink to="communication" title="Communication" />
        </div>
      }
      rightButtons={<NavigationLink to="questionnaire" title="Questionnaire" />}
    >
      <SupplierGradingDashboard />
    </Page>
  );
};

export default GradingAnalytics;
