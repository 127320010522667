const ErrorsView = ({ errors }) => {
  return (
    <div>
      <h4>There are some errors for your uploaded file:</h4>
      <div className="divide-y dark:divide-gray-700 mt-2">
        {errors.map((error, index) => (
          <div className="py-2" key={index}>
            {error}
          </div>
        ))}
      </div>

      <hr />

      <div className=" text-purple-600 font-semibold">Please fix the errors and try again.</div>
    </div>
  );
};

export default ErrorsView;
