import { gql } from "@apollo/client";

export const CREATE_ORDER = gql`
  mutation CREATE_ORDER {
    createOrder {
      order {
        id
      }
    }
  }
`;

export const FETCH_PRODUCTS_FOR_ORDER = gql`
  query FETCH_PRODUCTS_FOR_ORDER($odooIds: [Int]) {
    products(odooIds: $odooIds) {
      results {
        id
        odooId
        number
        name
        itemsPerSet
        cbm: outerCartonCbm
        areas {
          id
        }
      }
    }
  }
`;

export const GET_ORDERS = gql`
  query GET_ORDERS {
    orders {
      total
      results {
        id
        customer {
          id
          name
        }
        state
        odooSalesOrderClientOrderRef
        odooSalesOrderId
        odooSalesOrderName
        createdAt
        createdBy {
          id
          email
        }
      }
    }
  }
`;

const ORDER_DETAIL_FRAGMENT = gql`
  fragment orderDetail on OrderType {
    id
    warehouseName
    warehouse {
      id
      name
      inventory {
        id
        name
      }
    }
    customer {
      id
      name
    }
    pricelist {
      id
      currency
      latestPrices {
        id
        productId
        price
      }
    }
    state
    notes

    odooSalesOrderClientOrderRef
    odooPaymentTermsId
    odooTaxId
    odooIncoterm
    odooXDeliverymethod
    odooSalesOrderId
    odooSalesOrderName
    autoSyncToOdoo

    orderLines {
      id
      product {
        id
        odooId
        number
        name
        itemsPerSet
      }
      qty
      salesPrice
      discountPercentage
    }
  }
`;

export const GET_ORDER = gql`
  query GET_ORDER($id: ID!) {
    order(id: $id) {
      ...orderDetail
      usingStock {
        id
        name
        stocks {
          id
          odooId
          validOdooId
          latestQty
        }
      }
    }
  }
  ${ORDER_DETAIL_FRAGMENT}
`;

export const SAVE_ORDER = gql`
  mutation SAVE_ORDER($id: ID!, $orderLines: [SaveOrderLineInputType], $input: SaveOrderInputType!) {
    saveOrder(id: $id, orderLines: $orderLines, input: $input) {
      order {
        ...orderDetail
      }
    }
  }
  ${ORDER_DETAIL_FRAGMENT}
`;

export const ORDER_UPDATE_STATE = gql`
  mutation ORDER_UPDATE_STATE($id: ID!, $operation: String!) {
    orderUpdateState(id: $id, operation: $operation) {
      order {
        id
        state
        odooSalesOrderId
        odooSalesOrderName
      }
    }
  }
`;

export const SAVE_ORDER_LINE = gql`
  mutation SAVE_ORDER_LINE($id: ID, $input: SaveOrderLineInputType!) {
    saveOrderLine(id: $id, input: $input) {
      orderLine {
        id
      }
    }
  }
`;

export const DELETE_ORDER_LINE = gql`
  mutation DELETE_ORDER_LINE($id: ID!) {
    deleteOrderLine(id: $id) {
      orderLine {
        id
      }
    }
  }
`;
