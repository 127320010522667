import { useMutation, gql } from '@apollo/client';
import { Select } from 'components/Form';
import odooIcon from 'assets/odoo-icon.svg';
import { Alert } from 'components/Toast';

const SAVE_PRODUCT = gql`
   mutation SAVE_PRODUCT($id: ID!, $productionCategoryId: ID) {
      saveProduct(id: $id, productionCategoryId: $productionCategoryId) {
         product {
            id
            productionCategory {
               id
            }
         }
      }
   }
`;

const ProductRow = ({ product, allFactories }) => {
   const [saveProduct, saveProductRes] = useMutation(SAVE_PRODUCT, {
      onCompleted: () => {
         Alert('success', 'Saved.');
      },
      onError: (error) => () => Alert('error', error.message),
   });

   return (
      <>
         {product.odooId ? (
            <img
               className="mr-2"
               style={{ height: 16 }}
               src={odooIcon}
               alt="odoo product"
            />
         ) : null}
         <div className="flex items-center flex-1 justify-between space-x-8">
            <div className="flex justify-between items-center flex-1">
               <div>
                  <b>[ {product.number} ]</b> {product.name} -{' '}
                  <a
                     href={`${process.env.REACT_APP_SERVER_ADMIN_URL}/product/component/${product.id}/change/`}
                     rel="noopener noreferrer"
                     target="_blank"
                  >
                     ...
                  </a>
               </div>
               <div>
                  {product.preferedSupplier
                     ? product.preferedSupplier.name
                     : 'No prefered supplier yet.'}
               </div>
            </div>
            <div>
               <Select
                  value={
                     product.productionCategory
                        ? product.productionCategory.id
                        : '0'
                  }
                  onChange={(e) => {
                     const productionCategoryId = e.target.value;
                     saveProduct({
                        variables: {
                           id: product.id,
                           productionCategoryId:
                              productionCategoryId === '0'
                                 ? null
                                 : productionCategoryId,
                        },
                     });
                  }}
                  disabled={saveProductRes.loading}
               >
                  <option value="0">-- select a production category--</option>
                  {allFactories
                     .filter((i) =>
                        product.preferedSupplier
                           ? i.id === product.preferedSupplier.id
                           : true
                     )
                     .map((supplier) => (
                        <optgroup key={supplier.id} label={supplier.name}>
                           {supplier.productionCategories.map((cat) => (
                              <option key={cat.id} value={cat.id}>
                                 {cat.name}
                              </option>
                           ))}
                        </optgroup>
                     ))}
               </Select>
            </div>
         </div>
      </>
   );
};

export default ProductRow;
