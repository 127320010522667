import { gql, useQuery } from "@apollo/client";
import CharlesButton from "components/charles/base";
import Errors from "components/Errors";
import { InlineSpinner } from "components/Spinner";
import { useModals } from "ModalProvider";
import moment from "moment";
import OrderDetail from "./OrderDetail";
import { MdOutlineAdminPanelSettings } from "react-icons/md";
import SearchBar from "components/SearchBar";
import { useSearchParams } from "react-router-dom";

const FETCH_FSI_ORDERS = gql`
  query FETCH_FSI_ORDERS($offset: Int, $q: String) {
    fsiOrders(offset: $offset, limit: 30, q: $q) {
      total
      results {
        id
        orderId
        orderNumber
        createdAt
        data
        status
        message
      }
    }
  }
`;

const PAGE_SIZE = 30;

const FSIOrders = () => {
  const [searchParams, setSearchParams] = useSearchParams({ offset: 0, q: "" });
  const q = searchParams.get("q");
  const offset = parseInt(searchParams.get("offset")) || 0;
  const { loading, error, data } = useQuery(FETCH_FSI_ORDERS, {
    variables: { offset, q },
  });
  const modal = useModals();
  const isNew = (createdAt) => moment.duration(moment().diff(moment(createdAt))).asDays() < 1;

  return (
    <div className="mx-6 mb-6 px-6 py-4 rounded-2xl bg-white dark:bg-gray-800">
      <table>
        <thead>
          <tr className="whitespace-nowrap">
            <th>
              <div className="flex items-center space-x-2">
                <div>Order</div>
                <div className="flex-1">
                  <SearchBar className="bg-gray-100" placeholder="Search by order number" onChange={(q) => setSearchParams({ q, offset: 0 })} />
                </div>
              </div>
            </th>
            <th className="text-right">Created At</th>
            <th className="text-right">Status</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan={3}>
                <InlineSpinner />
              </td>
            </tr>
          ) : error ? (
            <tr>
              <td colSpan={3}>
                <Errors error={error} />
              </td>
            </tr>
          ) : (
            data.fsiOrders.results.map((order, index) => (
              <tr key={index} className="border-b border-gray-100 dark:border-gray-700">
                <td>
                  <div className="flex items-center space-x-2">
                    <CharlesButton
                      disabled={!order.orderId}
                      onClick={() =>
                        modal.present({
                          title: "FSI Order Detail",
                          hideChildren: true,
                          center: true,
                          children: <OrderDetail id={order.orderId} />,
                        })
                      }
                    >
                      {order.orderNumber}
                    </CharlesButton>

                    {isNew(order.createdAt) ? <div className="text-xs bg-green-500 text-white px-2 py-0.5 rounded-full leading-tight">New</div> : null}
                  </div>
                </td>
                <td className="text-right whitespace-nowrap">{moment(order.createdAt).format("YYYY-MM-DD hh:mm:ss")}</td>
                <td className="text-right whitespace-nowrap">
                  <div className="flex justify-end items-center space-x-2">
                    <OrderStatus status={order.status} />
                    <a href={`${process.env.REACT_APP_SERVER_ADMIN_URL}fsi/fsiorder/${order.id}`} target="_blank" rel="noreferer">
                      <MdOutlineAdminPanelSettings />
                    </a>
                  </div>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>

      {data ? (
        <div className="pt-6 flex justify-end items-center space-x-2 opacity-60">
          {offset > 0 ? <CharlesButton prev onClick={() => setSearchParams({ offset: offset - PAGE_SIZE, q })} /> : null}
          <div>
            {offset + 1}-{Math.min(PAGE_SIZE + offset, data.fsiOrders.total)} / {data.fsiOrders.total}
          </div>
          {data.fsiOrders.total > offset + PAGE_SIZE ? <CharlesButton next onClick={() => setSearchParams({ offset: PAGE_SIZE + offset, q })} /> : null}
        </div>
      ) : null}
    </div>
  );
};

const OrderStatus = ({ status }) => {
  return (
    <div
      className={`font-semibold text-xs
         ${status === "success" ? " text-green-600" : " text-red-600"}
         `}
    >
      {status}
    </div>
  );
};

const OrderLines = ({ data }) => {
  const order = JSON.parse(data);
  return (
    <div className="mt-1 text-xs">
      {order.lineItems.map((line, index) => (
        <div key={index}>
          - [{line.itemNumber}] &times; {line.quantity}
        </div>
      ))}
    </div>
  );
};

export default FSIOrders;
