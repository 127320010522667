import { gql, useMutation } from "@apollo/client";
import { parseError } from "apollo";
import { Alert } from "components/Toast";
import CharlesButton from "components/charles/base";
import { BsExclamationCircleFill } from "react-icons/bs";

const CONFIRM_PRODUCT_LIST = gql`
  mutation CONFIRM_PRODUCT_LIST($id: ID!) {
    updateProductList(id: $id, state: "DRAFT") {
      productList {
        id
        state
      }
    }
  }
`;

const ResetToDraftView = ({ productList, hide }) => {
  const [confirm, confirmRes] = useMutation(CONFIRM_PRODUCT_LIST, {
    variables: { id: productList.id },
    onCompleted() {
      Alert("success", "Product list has been reset to draft.");
      hide();
    },
    onError(error) {
      Alert("error", parseError(error));
    },
  });

  return (
    <div>
      <div className="flex space-x-6">
        <div>
          <h4>Are you sure to reset this product list to draft?</h4>
          <div className="mt-2  opacity-70">
            Once you reset, this product list will be marked as DRAFT. Note that the system will not do anything else like automatically notify anyone about
            this reset at the moment. You will need to manually notify the relevant parties.
          </div>
        </div>
        <div className="text-orange-600">
          <BsExclamationCircleFill size={56} />
        </div>
      </div>

      <hr />

      <div className="flex space-x-6">
        <CharlesButton onClick={confirm} loading={confirmRes.loading}>
          Yes Reset Now
        </CharlesButton>
        <CharlesButton onClick={hide}>Cancel</CharlesButton>
      </div>
    </div>
  );
};

export default ResetToDraftView;
