import { NavigationLink } from "components/base";

export const LeftButtons = () => (
  <div className="space-x-6">
    <NavigationLink title="Orders" to="/amazon/orders" />
    <NavigationLink title="Stocks" to="/amazon/stocks" />
    <NavigationLink title="Invoices" to="/amazon/invoices" />
    <NavigationLink title="Warehouses" to="/amazon/warehouses" />
  </div>
);
