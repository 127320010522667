import { gql, useQuery } from '@apollo/client';
import { InlineSpinner } from 'components/Spinner';
import Errors from 'components/Errors';
import { FixedSizeList } from 'react-window';
import { useState } from 'react';
import { searchByProp } from 'utils/search';
import { Input } from 'components/Form';
import { Button } from 'components/base';

const FETCH_ODOO_PRODUCT_TEMPLATES = gql`
   query FETCH_ODOO_PRODUCT_TEMPLATES {
      odooAllProductTemplates
   }
`;

const OdooProductTemplateSelector = ({ productTemplate, onSelect }) => {
   const { loading, error, data } = useQuery(FETCH_ODOO_PRODUCT_TEMPLATES, {
      fetchPolicy: 'cache-and-network',
   });
   const [searchText, setSearchText] = useState('');
   const [showOptions, setShowOptions] = useState(false);

   const productTemplates = data
      ? JSON.parse(data.odooAllProductTemplates)
      : [];

   const optionsToShow = productTemplates
      .filter((i) => i.attribute_line_ids.length > 0)
      .filter((i) => searchByProp(i, ['name', 'default_code'], searchText));

   function render({ data, index, style }) {
      const option = data[index];

      return (
         <div
            className="cursor-pointer border-b dark:border-gray-700 px-4 hover:bg-gray-200 dark:hover:bg-gray-700 w-full h-full flex items-center"
            key={index}
            onClick={() => {
               onSelect(option);
               setShowOptions(false);
            }}
            style={style}
         >
            <div className="flex items-center">
               [ {option.default_code} ] {option.name}
            </div>
         </div>
      );
   }

   return (
      <div className={`${showOptions ? 'z-10' : 'z-0'} w-full relative`}>
         <div className="flex items-baseline space-x-4">
            <label className="pr-2 font-bold text-gray-600">
               Product Template:{' '}
            </label>

            {loading ? (
               <InlineSpinner text={null} size={16} />
            ) : error ? (
               <Errors error={error} />
            ) : (
               <div>
                  {productTemplate ? (
                     <div className="flex space-x-4">
                        <div>
                           #{productTemplate.id} [{productTemplate.default_code}
                           ] {productTemplate.name}
                        </div>
                        <Button title="clear" onClick={() => onSelect(null)} />
                     </div>
                  ) : (
                     <div className="relative flex-1">
                        <div
                           className={`fixed inset-0
                        ${showOptions ? ' visible' : ' invisible'}`}
                           onClick={() => setShowOptions(false)}
                        ></div>
                        <Input
                           placeholder="Product"
                           className="w-full relative z-10 flex-1"
                           onChange={(e) => setSearchText(e.target.value)}
                           value={searchText}
                           onFocus={() => setShowOptions(true)}
                        />

                        <div
                           className={`absolute w-full rounded overflow-auto z-20 shadow-lg bg-white dark:bg-gray-800
                        ${showOptions ? ' visible' : ' invisible'}
                        `}
                        >
                           <FixedSizeList
                              height={300}
                              itemSize={44}
                              width="100%"
                              itemData={optionsToShow}
                              itemCount={optionsToShow.length}
                           >
                              {render}
                           </FixedSizeList>
                        </div>
                     </div>
                  )}

                  <div className="text-xs opacity-60 mt-1">
                     Optional. If you don't choose a product template, it will
                     create a new one and assciate the variant to it. Note that
                     you could only add a new variant to a template which
                     already has variants.
                  </div>
               </div>
            )}
         </div>
      </div>
   );
};

export default OdooProductTemplateSelector;
