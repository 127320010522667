import CharlesButton from 'components/charles/base';
import Page from 'components/Page';

const Orders = () => {
   return (
      <Page title="Orders">
         <div className="p-4">
            <table>
               <thead>
                  <tr>
                     <th>Number</th>
                     <th>Delivery Date</th>
                     <th>Amount</th>
                  </tr>
               </thead>
               <tbody>
                  <tr className="border-b">
                     <td>
                        <CharlesButton>10001</CharlesButton>
                     </td>
                     <td>2023-03-02</td>
                     <td>12000</td>
                  </tr>
                  <tr className="border-b">
                     <td>
                        <CharlesButton>10002</CharlesButton>
                     </td>
                     <td>2023-04-02</td>
                     <td>14000</td>
                  </tr>
               </tbody>
            </table>
         </div>
      </Page>
   );
};

export default Orders;
