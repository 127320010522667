import { gql, useMutation, useQuery } from "@apollo/client";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import { useState } from "react";
import CharlesButton from "components/charles/base";
import { Alert } from "components/Toast";
import { useModals } from "ModalProvider";
import { searchByProp } from "utils/search";
import SearchBar from "./SearchBar";

const FETCH_ONE_DRIVE_ITEM = gql`
  query FETCH_ONE_DRIVE_PRODUCTS($oneDriveFolderId: String!) {
    oneDriveItem(id: $oneDriveFolderId)
  }
`;

const ChooseImagesView = ({ oneDriveFolderId, onCompleted }) => {
  const { loading, error, data } = useQuery(FETCH_ONE_DRIVE_ITEM, {
    variables: { oneDriveFolderId },
  });
  const [selectedIds, setSelectedIds] = useState([]);

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const oneDriveImages = JSON.parse(data.oneDriveItem)
    .value.filter((i) => i.file.mimeType === "image/jpeg")
    .map((i) => ({
      ...i,
      selected: selectedIds.includes(i.id),
    }));

  return (
    <div>
      <div className="grid grid-cols-6 gap-6 mt-3">
        {oneDriveImages.map((i) => (
          <div
            key={i.id}
            className="flex flex-col items-center cursor-pointer"
            onClick={() =>
              setSelectedIds((prev) => {
                if (prev.includes(i.id)) {
                  return prev.filter((id) => id !== i.id);
                } else {
                  return [...prev, i.id];
                }
              })
            }
          >
            <div className={`border-4 ${i.selected ? "border-green-600" : "border-transparent"}`}>
              <img src={i.thumbnails[0].medium.url} alt={i.name} />
            </div>
            <div className="text-xs mt-1 break-all whitespace-pre-wrap text-center p-3">{i.name}</div>
          </div>
        ))}
      </div>

      <div className="mt-4 text-xs opacity-70">Choose images above and click complete.</div>

      <hr />

      <div>
        <CharlesButton onClick={() => onCompleted(oneDriveImages.filter((i) => i.selected))}>Complete</CharlesButton>
      </div>
    </div>
  );
};

const FETCH_ONE_DRIVE_PRODUCTS = gql`
  query FETCH_ONE_DRIVE_PRODUCTS {
    oneDriveChildren(path: "wis/beautyshots/wis")
  }
`;

const ChooseImagesFromOneDriveView = ({ onSelect }) => {
  const { loading, error, data } = useQuery(FETCH_ONE_DRIVE_PRODUCTS);
  const [searchText, setSearchText] = useState("");

  const imagesModal = useModals();

  function didSelectOneDriveFolder(oneDriveFolderId) {
    imagesModal.present({
      title: "Choose Images",
      children: <ChooseImagesView oneDriveFolderId={oneDriveFolderId} onCompleted={didChooseImages} />,
    });
  }

  function didChooseImages(images) {
    imagesModal.hide();
    onSelect(images.map((i) => ({ id: i.id, url: i.thumbnails[0].small.url })));
  }

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const res = JSON.parse(data.oneDriveChildren).value;

  const products = res.filter((i) => searchByProp(i, ["name"], searchText)).filter((i) => i.folder && i.folder.childCount > 0);

  return (
    <div className="">
      <div>
        <SearchBar className="dark:bg-gray-900" placeholder="Search by name" text={searchText} onChange={setSearchText} />
      </div>
      <div>
        {products.map((i) => (
          <div
            key={i.id}
            className="py-2 px-4 border-b dark:border-gray-700 cursor-pointer hover:bg-gray-100 dark:bg-gray-800"
            onClick={() => didSelectOneDriveFolder(i.id)}
          >
            <div>
              {i.name} ({i.folder ? i.folder.childCount : 0})
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChooseImagesFromOneDriveView;
