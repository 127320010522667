import NoCostDataView from "./NoCostDataView";
import ProductCostDetailView from "./ProductCostDetailView";

const OneProductView = ({ product, showPriceSyncer }) => {
  const selfPrices = product.prices.filter((i) => i.archivedAt === null);

  if (selfPrices.length === 0 && product.children.length === 0) return <NoCostDataView product={product} />;

  return <ProductCostDetailView product={product} showPriceSyncer={showPriceSyncer} />;
};

export default OneProductView;
