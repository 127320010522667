import { gql, useQuery } from "@apollo/client";

const FETCH_ODOO_OBJECTS = gql`
  query FETCH_ODOO_OBJECTS($salesOrderFilters: String, $salesOrderFields: [String], $transferFilters: String, $transferFields: [String]) {
    salesOrders: odooQuery(model: "sale.order", method: "search_read", filters: $salesOrderFilters, fields: $salesOrderFields)
    transfers: odooQuery(model: "stock.picking", method: "search_read", filters: $transferFilters, fields: $transferFields)
  }
`;

const useOdooShipmentObjects = (odooPartnerId) => {
  const salesOrderFilters = JSON.stringify([
    ["state", "in", ["sale", "draft"]],
    ["partner_id", "=", odooPartnerId],
  ]);
  const transferFilters = JSON.stringify([
    ["state", "in", ["draft", "confirmed", "waiting", "assigned", "partially_available"]],
    ["partner_id", "=", odooPartnerId],
  ]);
  return useQuery(FETCH_ODOO_OBJECTS, {
    variables: {
      odooPartnerId,
      salesOrderFilters,
      salesOrderFields: ["name", "date_order"],
      transferFilters,
      transferFields: ["name", "scheduled_date"],
    },
  });
};

export default useOdooShipmentObjects;
