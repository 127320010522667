import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { FileSelector } from "components/Form";
import { Alert } from "components/Toast";
import { BsUpload } from "react-icons/bs";
import useFileUploader from "hooks/useFileUploader";
import { FETCH_ALL_PATENT_TASKS, SAVE_PATENT_TASK } from "./graphql";
import CharlesButton from "components/charles/base";

const PatentTaskForm = ({ hide }) => {
  const [cpcSlash, setCpcSlash] = useState("");
  const [csvUri, setCsvUri] = useState(null);
  const { isUploading, handleFileUpload } = useFileUploader((urls) => {
    setCsvUri(urls[0]);
  });
  const [cratePatentTask, cratePatentTaskRes] = useMutation(SAVE_PATENT_TASK, {
    variables: { cpcSlash: cpcSlash, csvUri },
    onCompleted: () => {
      Alert("success", "Patent Task is created.");
      hide();
    },
    onError: (error) => Alert("error", error.message),
    refetchQueries: [{ query: FETCH_ALL_PATENT_TASKS }],
  });

  return (
    <div>
      <div className="text-sm mb-4 space-y-2">
        <p>
          Search for CPC on{" "}
          <a href="https://patents.google.com/" target="_blank" rel="noreferrer">
            Google Patent
          </a>{" "}
          first, then click "Download" -{">"} "Download (CSV)".
        </p>
        <p>
          CSV file naming format: e.g. the CPC is "A63B71/02", then the name must be "A63B71-02.csv". Download an example file{" "}
          <a className="underline" href="https://qiniu-waboba.cors.tech/48635230217d4dc4b7f3a6840a605063-A63B71-02.csv">
            A63B71-02.csv
          </a>
          .
        </p>
        <p>
          Depends on the number of articles and the text length of each article, the average process time per article may be around 5 seconds up to 5 minutes.
          Once the task is completed, you can receive an email notification.
        </p>
      </div>

      <FileSelector
        className="text-base mt-2"
        title={
          <div className="flex items-center space-x-2">
            <BsUpload />
            <span>Upload a CSV file</span>
          </div>
        }
        uploading={isUploading}
        onChange={(e) => {
          const filesOnlyOne = [e.target.files[0]];
          const csvFile = filesOnlyOne[0];
          const regex = /^[A-Z0-9]+-[0-9]+\.csv$/;
          if (regex.test(csvFile.name)) {
            setCpcSlash(csvFile.name.replace(".csv", "").replace("-", "/"));
            handleFileUpload(filesOnlyOne);
          } else {
            Alert("error", "The format of the CSV file name is not correct.");
          }
          e.target.value = null;
        }}
      />

      {cpcSlash ? (
        <div className="flex items-baseline mt-4">
          <label className="mr-3">CPC: </label>
          <h3 className="text-base">{cpcSlash}</h3>
        </div>
      ) : null}

      <div className="flex items-center mt-6">
        <CharlesButton
          loading={cratePatentTaskRes.loading}
          disabled={cratePatentTaskRes.loading || !csvUri || cpcSlash === ""}
          primary
          onClick={cratePatentTask}
        >
          Submit
        </CharlesButton>
      </div>
    </div>
  );
};

export default PatentTaskForm;
