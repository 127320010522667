import { gql, useMutation } from "@apollo/client";
import CharlesButton from "components/charles/base";
import { Input, Select } from "components/Form";
import { Alert } from "components/Toast";
import ProductSelector from "pages/manage/products/All/ProductSelector";
import { useState } from "react";

const SAVE_SALES_PRICE = gql`
  mutation($priceListId: ID!, $productId: ID!, $price: Float!, $discountPercentage: Float!, $useFixedMargin: Boolean, $fixedMargin: Float, $state: String) {
    saveProductSalesPrice(
      priceListId: $priceListId
      productId: $productId
      price: $price
      discountPercentage: $discountPercentage
      useFixedMargin: $useFixedMargin
      fixedMargin: $fixedMargin
      state: $state
    ) {
      product {
        id
        salesPrices {
          id
          price
          discountPercentage
          useFixedMargin
          fixedMargin
          finalPrice
          state
        }
      }
    }
  }
`;

const DELETE_PRODUCT_SALES_PRICE = gql`
  mutation DELETE_PRODUCT_SALES_PRICE($productId: ID!, $priceListId: ID!) {
    deleteProductSalesPrice(productId: $productId, priceListId: $priceListId) {
      res
    }
  }
`;

const AddProductToPriceView = ({ priceListId, currency, onSaved, initialData = null }) => {
  const [product, setProduct] = useState(initialData ? initialData.product : null);
  const [price, setPrice] = useState(initialData ? initialData.price : 0);
  const [state, setState] = useState(initialData ? initialData.state : "CONFIRMED");
  const [useFixedMargin, setUseFixedMargin] = useState(initialData ? initialData.useFixedMargin : false);
  const [fixedMargin, setFixedMargin] = useState(initialData ? initialData.fixedMargin : 0);
  const [discountPercentage, setDiscountPercentage] = useState(initialData ? initialData.discountPercentage : 0);

  console.log("initialData", initialData);

  const [savePrice, savePriceRes] = useMutation(SAVE_SALES_PRICE, {
    onCompleted() {
      Alert("success", "Saved.");
      onSaved();
    },
    onError(error) {
      Alert("error", error.message);
    },
    refetchQueries: ["FETCH_PRICE_LIST", "FETCH_ODOO_PRICE_LIST"],
    awaitRefetchQueries: true,
  });
  const [deleteProductSalesPrice, deleteProductSalesPriceRes] = useMutation(DELETE_PRODUCT_SALES_PRICE, {
    onCompleted() {
      Alert("success", "Saved.");
      onSaved();
    },
    onError(error) {
      Alert("error", error.message);
    },
    refetchQueries: ["FETCH_PRICE_LIST"],
    awaitRefetchQueries: true,
  });

  function saveHandler() {
    if (product) {
      savePrice({ variables: { productId: product.id, priceListId, price, discountPercentage, useFixedMargin, fixedMargin, state } });
    } else {
      Alert("error", "Please select a product.");
    }
  }

  return (
    <div className="text-base">
      <div className="space-y-4">
        <div className="flex items-baseline space-x-4">
          <label className="w-36">Product: </label>
          {product ? (
            <div className="space-y-1">
              <div>
                <div className=" font-semibold">{product.number}</div>
                <div className="text-sm">{product.name}</div>
              </div>
              {initialData ? null : (
                <CharlesButton className=" text-xs" danger onClick={() => setProduct(null)}>
                  Remove
                </CharlesButton>
              )}
            </div>
          ) : (
            <ProductSelector variables={{ isSalable: true }} className="text-xs" onSelect={setProduct} />
          )}
        </div>

        <div className="flex items-baseline space-x-4">
          <label className="w-36">Use Fixed Margin: </label>
          <div className="space-x-2">
            <input type="checkbox" checked={useFixedMargin} onChange={(e) => setUseFixedMargin(e.target.checked)} />
          </div>
        </div>

        {useFixedMargin ? (
          <div className="flex items-baseline space-x-4">
            <label className="w-36">Fixed Margin: </label>

            <div className="space-x-2">
              <Input className="text-center" type="number" value={fixedMargin} onChange={(e) => setFixedMargin(e.target.value)} />
              <span>%</span>
            </div>
          </div>
        ) : (
          <div className="flex items-center space-x-4">
            <label className="w-36">Price: </label>

            <div className="space-x-2">
              <Input className="text-center" type="number" value={price} onChange={(e) => setPrice(e.target.value)} />
              <span>{currency}</span>
            </div>
          </div>
        )}

        <div className="flex items-center space-x-4">
          <label className="w-36">Discount: </label>
          <div className="space-x-2">
            <Input className="text-center" type="number" value={discountPercentage} onChange={(e) => setDiscountPercentage(e.target.value)} />
            <span>%</span>
          </div>
        </div>

        <div className="flex items-center space-x-4">
          <label className="w-36">State: </label>
          <Select value={state} onChange={(e) => setState(e.target.value)}>
            <option value="pending">Pending</option>
            <option value="confirmed">Confirmed</option>
          </Select>
        </div>
      </div>

      <div className="mt-6 opacity-70 text-sm">
        Note that if the current price list if connected with Odoo, the new price will be automatically sync to Odoo price list.
      </div>

      <div className="flex justify-between space-x-8 mt-10">
        <CharlesButton primary onClick={saveHandler} loading={savePriceRes.loading}>
          Save
        </CharlesButton>

        {initialData ? (
          <CharlesButton
            onClick={() => {
              if (window.confirm("Are you sure to delete this product in this price list? ")) {
                deleteProductSalesPrice({
                  variables: { priceListId, productId: product.id },
                });
              }
            }}
            loading={deleteProductSalesPriceRes.loading}
            danger
          >
            Delete
          </CharlesButton>
        ) : null}
      </div>
    </div>
  );
};

export default AddProductToPriceView;
