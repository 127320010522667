import React, { useContext, useMemo } from 'react';
import { useMutation } from '@apollo/client';
import AWSUploader from '../../../components/AWSUploader.js';
import { FileSelector } from '../../../components/Form.js';
import { Button } from '../../../components/base.js';
import File from '../../../components/File.js';
import ShipmentContext from './ShipmentContext';
import {
   ADD_SHIPMENT_ATTACHMENT,
   REMOVE_SHIPMENT_ATTACHMENT,
} from '../graphql';
import track, { actions } from '../../../utils/track.js';

function AttachmentFile({ shipmentId, attachment, index, name, src, file }) {
   const { dispatch } = useContext(ShipmentContext);

   const [removeShipmentAttachment, { loading }] = useMutation(
      REMOVE_SHIPMENT_ATTACHMENT,
      {
         onCompleted: () => {
            dispatch({ type: 'deleteShipmentAttachments', payload: index });
         },
      }
   );

   const [createShipmentAttachment] = useMutation(ADD_SHIPMENT_ATTACHMENT, {
      onCompleted: (data) => {
         const attachment = data.addShipmentAttachment.attachment;
         dispatch({
            type: 'addedShipmentAttachment',
            payload: { index, attachment },
         });
      },
   });

   return useMemo(
      () => (
         <div className="flex justify-between items-center border-1 border-gray-200 dark:border-gray-700 flex-1 relative border-b">
            {src ? (
               <File name={name} link={src} />
            ) : file ? (
               <div className="relative w-16 h-16">
                  <AWSUploader
                     className="flex w-full h-full"
                     file={file}
                     onUploaded={(url) => {
                        dispatch({
                           type: 'didUploadFileToAws',
                           payload: { index, url },
                        });
                        createShipmentAttachment({
                           variables: { shipmentId, name, url },
                        });
                     }}
                  />
               </div>
            ) : null}
            <Button
               loading={loading}
               size="sm"
               title={loading ? 'deleting...' : 'delete'}
               color="red"
               onClick={() => {
                  if (window.confirm('Are you sure to delete this file?')) {
                     if (src) {
                        removeShipmentAttachment({
                           variables: { id: attachment.id },
                        });
                     } else {
                        dispatch({
                           type: 'deleteShipmentAttachments',
                           payload: index,
                        });
                     }
                     track(actions.shipment.deleteAttachment.name);
                  }
               }}
            />
         </div>
      ),
      [
         attachment.id,
         createShipmentAttachment,
         dispatch,
         file,
         index,
         loading,
         name,
         removeShipmentAttachment,
         shipmentId,
         src,
      ]
   );
}

function Attachments() {
   const {
      state: { id, attachments },
      dispatch,
   } = useContext(ShipmentContext);

   return useMemo(
      () => (
         <div className="bg-white dark:bg-gray-900 rounded-lg border border-gray-200 dark:border-gray-700 dark:text-gray-300 p-6">
            <h3>Attachments</h3>
            {attachments.length > 0 && (
               <div className="mt-4">
                  {attachments.map((attachment, index) => (
                     <AttachmentFile
                        shipmentId={id}
                        attachment={attachment}
                        key={index}
                        index={index}
                        name={attachment.name}
                        src={attachment.uri}
                        file={attachment.file}
                     />
                  ))}
               </div>
            )}

            <div className="flex mt-4">
               <FileSelector
                  title="Add Attachments"
                  onChange={(e) => {
                     dispatch({
                        type: 'onSelectAttachments',
                        payload: [...e.target.files].map((file) => ({
                           name: file.name,
                           file,
                        })),
                     });
                     e.target.value = null;
                  }}
               />
            </div>
         </div>
      ),
      [id, attachments, dispatch]
   );
}

export default Attachments;
