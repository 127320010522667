import useOdooCustomers from 'hooks/useOdooCustomers';
import { useEffect } from 'react';
import { byName } from 'utils/sort';
import { Select } from './Form';

const OdooPartnerSelector = ({ value, onSelect, initialValue }) => {
   const { loading, error, odooCustomers } = useOdooCustomers();

   useEffect(() => {
      if (odooCustomers && initialValue && value !== initialValue)
         onSelect(initialValue);
   }, [odooCustomers]);

   const options = odooCustomers
      ? odooCustomers.filter((i) => i.name).sort(byName)
      : [];

   return (
      <Select value={value || '0'} onChange={(e) => onSelect(e.target.value)}>
         {loading ? (
            <option value="0" disabled>
               loading...
            </option>
         ) : error ? (
            <option value="0" disabled>
               {error.message}
            </option>
         ) : (
            <>
               <option value="0" disabled>
                  --- Select ---
               </option>
               {options.map((i, index) => (
                  <option key={index} value={i.id}>
                     #{i.id} {i.name}
                  </option>
               ))}
            </>
         )}
      </Select>
   );
};

export default OdooPartnerSelector;
