import { useMutation } from "@apollo/client";
import { SYNC_LOGOS_FROM_ONE_DRIVE } from "./graphql";
import { Alert } from "components/Toast";
import { parseError } from "apollo";
import CharlesButton from "components/charles/base";

const SyncLogosView = ({ collectionName, hide }) => {
  const [sync, syncRes] = useMutation(SYNC_LOGOS_FROM_ONE_DRIVE, {
    variables: { collectionName },
    onCompleted() {
      Alert("success", "Logos Synced Success.");
      hide();
    },
    onError(error) {
      Alert("error", parseError(error));
    },
    refetchQueries: ["FETCH_LOGO"],
    awaitRefetchQueries: true,
  });

  return (
    <div>
      <div>
        <p>Are you sure to sync logos from One Drive to {collectionName}?</p>
        <p>Note this the process might take a while depends on the file size.</p>
      </div>

      <hr />

      <div>
        <CharlesButton primary onClick={sync} loading={syncRes.loading}>
          Confirm and Sync Now
        </CharlesButton>
      </div>
    </div>
  );
};

export default SyncLogosView;
