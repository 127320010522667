import moment from "moment";

const ResponsibilityView = ({ data }) => {
  return (
    <div className="card px-6 py-4">
      <h5>Responsibility</h5>
      <div className="mt-2 -mx-2">
        <table>
          <tbody>
            <tr>
              <td>Project Manager</td>
              <td>{data.projectManager ? data.projectManager.firstName : "-"}</td>
              <td className="text-right text-xs">{moment(data.projectManagerUpdatedAt).format("YYYY-MM-DD hh:mm:ss")}</td>
            </tr>
            <tr>
              <td>Product Designer</td>
              <td>{data.productDesigner ? data.productDesigner.firstName : "-"}</td>
              <td className="text-right text-xs">{moment(data.productDesignerUpdatedAt).format("YYYY-MM-DD hh:mm:ss")}</td>
            </tr>
            <tr>
              <td>Packaging Designer</td>
              <td>{data.packagingDesigner ? data.packagingDesigner.firstName : "-"}</td>
              <td className="text-right text-xs">{moment(data.packagingDesignerUpdatedAt).format("YYYY-MM-DD hh:mm:ss")}</td>
            </tr>
            <tr>
              <td>Production Manager</td>
              <td>{data.productionManager ? data.productionManager.firstName : "-"}</td>
              <td className="text-right text-xs">{moment(data.productionManagerUpdatedAt).format("YYYY-MM-DD hh:mm:ss")}</td>
            </tr>
            <tr>
              <td>Merchandiser</td>
              <td>{data.merchandiser ? data.merchandiser.firstName : "-"}</td>
              <td className="text-right text-xs">{moment(data.merchandiserUpdatedAt).format("YYYY-MM-DD hh:mm:ss")}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ResponsibilityView;
