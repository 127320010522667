import { useMutation } from "@apollo/client";
import { DELETE_QC_ISSUE } from "../graphql.js";
import CharlesButton from "components/charles/base";
import { Alert } from "components/Toast";
import { BsTrash } from "react-icons/bs";

function Issue({ issue }) {
  const [deleteQcIssue] = useMutation(DELETE_QC_ISSUE, {
    variables: { id: issue.id },
    onCompleted() {
      Alert("success", "删除成功");
    },
  });
  return (
    <div className="lg:flex justify-between py-4 border-b relative">
      <div className="absolute z-10 right-0 top-4">
        <CharlesButton
          danger
          onClick={(_) => {
            if (window.confirm("确认删除缺陷？")) deleteQcIssue();
          }}
        >
          <BsTrash />
        </CharlesButton>
      </div>
      <div>
        <div
          className={`font-bold
                  ${issue.defectType === "major" ? "text-red-500 " : "text-yellow-500"}`}
        >
          [<span className="uppercase">{issue.defectType}</span>] Defect Code: #{issue.defectCode.code}
        </div>
        <div className="text-gray-600 mt-1">缺陷: {issue.defectCode.description}</div>

        {issue.factory && <div className="mt-1">Factory: {issue.factory.name}</div>}

        {issue.productLine && <div className="mt-1">Product Line: {issue.productLine.name}</div>}

        <div className="mt-1">Quantity 数量: ✕ {issue.qty}</div>
        <div className="space-y-2 mt-2">
          {issue.images.map((image) => (
            <a key={image.id} href={image.src} target="_blank" className="w-full block" title="Open origin image" rel="noopener noreferrer">
              <img className="w-full lg:w-auto lg:h-32 bg-gray-700" src={`${image.src}?imageView2/2/w/600`} alt="issue image" />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Issue;
