import { gql, useMutation, useQuery } from "@apollo/client";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import { Button } from "components/base";
import { Alert } from "components/Toast";
import { productUnitCost } from "pages/price/utils";
import { useContext } from "react";
import { CurrencyContext } from "CurrencyProvider";

const FETCH_PRODUCT_ODOO_SUPPLIER_INFO = gql`
  query FETCH_PRODUCT_ODOO_SUPPLIER_INFO($productOdooId: Int) {
    odooSupplierInfo(productId: $productOdooId) {
      id
      partnerId
      partnerName
      price
      minQty
      currency
    }
  }
`;

export const SYNC_PRODUCT_PRICES_TO_ODOO = gql`
  mutation SYNC_PRODUCT_PRICES_TO_ODOO($id: ID!, $computed: Boolean) {
    syncProductPricesToOdoo(id: $id, computed: $computed) {
      product {
        id
      }
    }
  }
`;

const ComputedPriceSyncer = ({ product, hide }) => {
  const { latestExchangeRate } = useContext(CurrencyContext);
  const { loading, error, data } = useQuery(FETCH_PRODUCT_ODOO_SUPPLIER_INFO, {
    variables: { productOdooId: product.odooId },
  });

  const [syncPrice, syncPriceRes] = useMutation(SYNC_PRODUCT_PRICES_TO_ODOO, {
    onCompleted: () => {
      Alert("success", "Sync Success!");
      hide();
    },
    onError: (error) => Alert("error", error.message),
    refetchQueries: [
      {
        query: FETCH_PRODUCT_ODOO_SUPPLIER_INFO,
        variables: { productOdooId: product.odooId },
      },
    ],
    awaitRefetchQueries: true,
  });

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const price = productUnitCost(product, product.defaultQty, latestExchangeRate.rate).toFixed(3);

  return (
    <div className="text-base">
      <div className="space-y-4">
        <div>
          You are trying to sync the price for{" "}
          <b>
            #{product.id} [ {product.number} ] {product.name}
          </b>{" "}
          to Odoo.
        </div>

        <div>
          <label htmlFor="">Unit Price: </label>
          <b>{price} USD</b>
        </div>

        {product.preferedSupplier ? (
          <div>
            <label htmlFor="">Supplier: </label>
            {product.preferedSupplier.name}
          </div>
        ) : (
          <div>This product does not have a default supplier.</div>
        )}
      </div>

      <div className="border-t border-b dark:border-gray-700 py-4 my-8">
        <h4>Current Price in Odoo</h4>
        <div className="mt-2 space-y-2">
          {data.odooSupplierInfo.length > 0 ? (
            data.odooSupplierInfo.map((i, index) => (
              <div key={index} className="flex items-center justify-between space-x-8">
                <div className="flex-1">
                  <label htmlFor="">Supplier:</label> {i.partnerName}
                </div>
                <div className="flex flex-1">
                  <div className="flex-1 text-right">
                    <label htmlFor="">Min Qty:</label> {i.minQty}
                  </div>
                  <div className="flex-1 text-right">
                    {i.price} {i.currency}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="font-bold text-gray-600">No Price in Odoo right now.</div>
          )}
        </div>
      </div>

      <Button
        bold
        title="Sync!"
        size="xl"
        onClick={() => syncPrice({ variables: { id: product.id, computed: true } })}
        loading={syncPriceRes.loading}
        disabled={syncPriceRes.loading || !product.preferedSupplier}
      />

      {product.preferedSupplier ? null : <div>Choose a Default supplier to sync computed price to Odoo.</div>}
    </div>
  );
};

export default ComputedPriceSyncer;
