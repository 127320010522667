Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

export function diffDays(firstDate, secondDate) {
  firstDate.setHours(0, 0, 0, 0);
  secondDate.setHours(0, 0, 0, 0);
  const oneDay = 24 * 60 * 60 * 1000;
  return Math.round(Math.abs((firstDate - secondDate) / oneDay));
}

export const localDateTime = (datetimeString) => {
  const d = new Date(datetimeString);
  return d.toLocaleString();
};

export const getDates = (startDate, endDate) => {
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(0, 0, 0, 0);
  var dates = [],
    currentDate = startDate,
    addDays = function (days) {
      var date = new Date(this.valueOf());
      date.setDate(date.getDate() + days);
      return date;
    };
  while (currentDate <= endDate) {
    dates.push(currentDate);
    currentDate = addDays.call(currentDate, 1);
  }
  return dates;
};

export const getMonths = (startDate, endDate) => {
  var months = [];
  let currentMonth = startDate.getMonth() + 1;
  while (currentMonth <= endDate.getMonth() + 1) {
    months.push(currentMonth);
    currentMonth++;
  }
  return months;
};

export const timeSince = function (date) {
  if (typeof date !== "object") {
    date = new Date(date);
  }

  var seconds = Math.floor((new Date() - date) / 1000);
  var intervalType;

  var interval = Math.floor(seconds / 31536000);
  if (interval >= 1) {
    intervalType = "year";
  } else {
    interval = Math.floor(seconds / 2592000);
    if (interval >= 1) {
      intervalType = "month";
    } else {
      interval = Math.floor(seconds / 86400);
      if (interval >= 1) {
        intervalType = "day";
      } else {
        interval = Math.floor(seconds / 3600);
        if (interval >= 1) {
          intervalType = "hour";
        } else {
          interval = Math.floor(seconds / 60);
          if (interval >= 1) {
            intervalType = "minute";
          } else {
            interval = seconds;
            intervalType = "second";
          }
        }
      }
    }
  }

  if (interval > 1 || interval === 0) {
    intervalType += "s";
  }

  return interval + " " + intervalType;
};

export function dateAfter(dateTimestamp, days) {
  const newDate = new Date(dateTimestamp);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
}

export function nextWeekday(dateTimestamp, dow) {
  const newDate = new Date(dateTimestamp);
  newDate.setDate(newDate.getDate() + ((dow + (7 - newDate.getDay())) % 7));
  return newDate;
}

export function getDatesEvery(startDate, stopDate, every) {
  var dateArray = [];
  var currentDate = startDate;
  while (currentDate <= stopDate) {
    dateArray.push(new Date(currentDate));
    currentDate = dateAfter(currentDate, every);
  }
  return dateArray;
}

export function getQuarterStartAndEndDate(year, quarter) {
  if (quarter < 1 || quarter > 4) {
    return "Invalid quarter number. It must be between 1 and 4.";
  }

  let startDate;
  let endDate;

  switch (quarter) {
    case 1:
      startDate = new Date(year, 0, 1); // January 1st
      endDate = new Date(year, 2, 31); // March 31st
      break;
    case 2:
      startDate = new Date(year, 3, 1); // April 1st
      endDate = new Date(year, 5, 30); // June 30th
      break;
    case 3:
      startDate = new Date(year, 6, 1); // July 1st
      endDate = new Date(year, 8, 30); // September 30th
      break;
    case 4:
      startDate = new Date(year, 9, 1); // October 1st
      endDate = new Date(year, 11, 31); // December 31st
      break;
  }

  return {
    startDate: startDate,
    endDate: endDate,
  };
}
