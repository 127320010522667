const SupplierInfoView = ({ product, hasBomProductsNotInOdoo, prices, computedCost }) => {
  if (!hasBomProductsNotInOdoo && prices.length === 0) {
    return (
      <div className="card">
        <div className=" opacity-70 text-sm">No Supplier Price Info.</div>
      </div>
    );
  }

  return (
    <div className="card p-4">
      <table>
        <thead>
          <tr className="text-left border-b border-gray-100 dark:border-gray-700">
            <th>Supplier</th>
            <th>Qty</th>
            <th>Price</th>
          </tr>
        </thead>
        <tbody>
          {hasBomProductsNotInOdoo ? (
            <tr>
              <td>{product.defaultSupplier ? product.defaultSupplier.name : " - "}</td>
              <td>{product.defaultQty}</td>
              <td>{computedCost}</td>
            </tr>
          ) : (
            prices.map((i) => (
              <tr key={i.id} className="border-b dark:border-gray-700">
                <td>{i.factory ? i.factory.name : "-"}</td>
                <td>{i.minQty}</td>
                <td>
                  {i.currency} {i.price}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

export default SupplierInfoView;
