import ReactDOM from 'react-dom';
import App from './App';
import './index.css';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-virtualized/styles.css';

if (process.env.REACT_APP_USE_MOCK_SERVICE_WORKER === 'true') {
   const { worker } = require('./mocks/browser');
   worker.start();
}
ReactDOM.render(<App />, document.getElementById('root'));
