import { gql, useQuery } from '@apollo/client';
import AppSettings from 'AppSettings';
import Errors from 'components/Errors';
import Spinner from 'components/Spinner';

const FETCH_ODOO_PRODUCTS_NOT_CREATED_BY_STELLA = gql`
   query FETCH_ODOO_PRODUCTS_NOT_CREATED_BY_STELLA {
      odooFindProductsNotCreatedByStella
   }
`;

const OdooProductsNotCreatedByStella = () => {
   const { loading, error, data } = useQuery(
      FETCH_ODOO_PRODUCTS_NOT_CREATED_BY_STELLA
   );

   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;

   const products = JSON.parse(data.odooFindProductsNotCreatedByStella);

   return (
      <div className="p-4 text-sm">
         <div className="mb-4 italic opacity-70 text-xs">
            This page lists the products currently created but not created by
            Stella.
         </div>
         <div className="card px-6 py-4">
            <table>
               <thead>
                  <tr>
                     <th>Product</th>
                     <th>Create By</th>
                     <th>Created At</th>
                     <th></th>
                  </tr>
               </thead>
               <tbody>
                  {products.map((i) => (
                     <tr
                        key={i.id}
                        className="border-t border-gray-100 dark:border-gray-700"
                     >
                        <td>
                           <div className="flex space-x-2 items-center">
                              <div>{i.name}</div>
                           </div>
                        </td>
                        <td className="font-semibold">{i.create_uid[1]}</td>
                        <td>{i.create_date}</td>
                        <td className="text-right">
                           <a
                              className="text-xs"
                              target="_blank"
                              rel="noreferer"
                              href={
                                 AppSettings.odooBaseUrl +
                                 `/web#id=${i.id}&cids=1&menu_id=222&action=563&model=product.product&view_type=form`
                              }
                           >
                              open in odoo
                           </a>
                        </td>
                     </tr>
                  ))}
               </tbody>
            </table>
         </div>
      </div>
   );
};

export default OdooProductsNotCreatedByStella;
