import { useContext } from "react";
import { OrderContext } from "./OrderDetail";
import { Input, Select, Text } from "components/Form";
import CustomersSelector from "./CustomerSelector";
import WisPriceListSelector from "components/WisPriceListSelector";
import OdooPaymentTermsSelector from "components/OdooPaymentTermsSelector";
import OdooTaxSelector from "components/OdooTaxSelector";
import OdooDeliveryMethodSelector from "components/OdooDeliveryMethodSelector";
import OdooIncotermsSelector from "components/OdooIncotermsSelector";
import { InlineSpinner } from "components/Spinner";
import { useModals } from "ModalProvider";
import ConfirmChangeWarehouseView from "./ConfirmChangeWarehouseView";

const BaseInfoView = () => {
  const { originalOrder, order, setOrder, changeWarehouse, changeWarehouseRes, changePricelist, changePricelistRes } = useContext(OrderContext);

  const modal = useModals();

  function confirmChangeWarehouse(warehouseName) {
    changeWarehouse({
      variables: { id: order.id, input: { warehouseName } },
      optimisticResponse: {
        saveOrder: { order: { ...order, warehouseName } },
      },
    });
  }

  function tryChangeWarehouse(e) {
    const warehouseName = e.target.value;
    if (order.orderLines.length > 0) {
      modal.present({
        title: "Change Warehouse",
        center: true,
        maxWidth: "max-w-3xl",
        children: (
          <ConfirmChangeWarehouseView
            warehouseName={warehouseName}
            orderId={order.id}
            confirmHandler={() => {
              confirmChangeWarehouse(warehouseName);
              modal.hide();
            }}
          />
        ),
      });
    } else {
      confirmChangeWarehouse(warehouseName);
    }
  }

  return (
    <div className="card">
      <h5>Base Info</h5>
      <div className="mt-4 space-y-3">
        <div className="space-x-3 flex items-center">
          <label>Warehouse: </label>
          <Select value={originalOrder.warehouseName} onChange={tryChangeWarehouse}>
            <option>NL</option>
            <option>UK</option>
          </Select>
          {changeWarehouseRes.loading ? <InlineSpinner text={null} size={16} /> : null}
          {originalOrder.warehouse ? <span>{originalOrder.warehouse.inventory.name}</span> : null}
        </div>

        <div className="flex space-x-8">
          <div>
            <label className="pr-3">Customer: </label>
            <CustomersSelector value={order.customer ? order.customer.id : null} onChange={(customer) => setOrder({ ...order, customer })} />
          </div>

          <div>
            <label className="pr-3">Customer Ref: </label>
            <Input value={order.odooSalesOrderClientOrderRef} onChange={(e) => setOrder({ ...order, odooSalesOrderClientOrderRef: e.target.value })} />
          </div>
        </div>

        <div className="flex items-center space-x-3">
          <label className="pr-3">Pricelist: </label>
          <WisPriceListSelector
            value={originalOrder.pricelist ? originalOrder.pricelist.id : null}
            onChange={(pricelist) => {
              changePricelist({
                variables: { id: order.id, input: { pricelistId: pricelist.id } },
                optimisticResponse: {
                  saveOrder: { order: { ...order, pricelist: { ...pricelist, currency: pricelist.currency } } },
                },
              });
            }}
          />
          {changePricelistRes.loading ? <InlineSpinner text={null} size={16} /> : null}
        </div>

        <div>
          <label className="pr-3">Payment Terms: </label>
          <OdooPaymentTermsSelector value={order.odooPaymentTermsId} onSelect={(odooPaymentTermsId) => setOrder((prev) => ({ ...prev, odooPaymentTermsId }))} />
        </div>

        <div>
          <label className="pr-3">Tax:</label>
          <OdooTaxSelector value={order.odooTaxId} onSelect={(odooTaxId) => setOrder((prev) => ({ ...prev, odooTaxId }))} />
        </div>

        <div className="flex space-x-8">
          <div>
            <label className="pr-3">Delivery Method: </label>
            <OdooDeliveryMethodSelector
              value={order.odooXDeliverymethod}
              onSelect={(odooXDeliverymethod) => setOrder((prev) => ({ ...prev, odooXDeliverymethod }))}
            />
          </div>

          <div>
            <label className="pr-3">Incoterm: </label>
            <OdooIncotermsSelector value={order.odooIncoterm} onSelect={(odooIncoterm) => setOrder((prev) => ({ ...prev, odooIncoterm }))} />
          </div>
        </div>

        <div>
          <div className="mb-2">
            <label className="pr-3">Notes: </label>
          </div>
          <Text value={order.notes} onChange={(e) => setOrder({ ...order, notes: e.target.value })} placeholder="Remark" />
        </div>
      </div>
    </div>
  );
};

export default BaseInfoView;
