import React from 'react';
import Image from './Image';

function Product({
   className = '',
   name,
   number,
   image = null,
   imageSize = 'w-8 h-8',
   bgColor = 'bg-gray-300',
   ...rest
}) {
   return (
      <div className={`${className} flex items-center`} {...rest}>
         {image ? (
            <Image className="mr-2" src={image} size={imageSize} />
         ) : null}
         <div>
            <div>
               [ {number} ] {name}
            </div>
         </div>
      </div>
   );
}

export default Product;
