import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import { Button } from "components/base";
import http from "utils/http";
import { FETCH_QC_PREVIEW, SAVE_SIMPLE_REPORT } from "../graphql";
import Page from "components/Page";
import { Switcher } from "components/Form";
import { Alert } from "components/Toast";
import { AppContext } from "App";
import { INTERNAL } from "utils/permissions";

function QCReportPreview() {
  const { id } = useParams();
  const { hasPermission } = useContext(AppContext);
  const [downloadingPdf, setDownloadingPdf] = useState(false);
  const { loading, error, data } = useQuery(FETCH_QC_PREVIEW, {
    variables: { id },
  });
  const [saveReport, saveReportRes] = useMutation(SAVE_SIMPLE_REPORT, {
    onCompleted(res) {
      Alert(
        "success",
        res.saveSimpleQcReport.qcReport.sharedWithCustomerAt
          ? "Shared with Customer."
          : "Disabled shared with customer.",
      );
    },
    onError(error) {
      Alert("error", error.message);
    },
  });

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const { report } = data;
  const productName = report.product.productLine
    ? report.product.productLine.name
    : report.product.name;

  function exportPdf(id, filename) {
    setDownloadingPdf(true);
    http
      .get(`/qc_reports/${id}/`, {
        baseURL: process.env.REACT_APP_SERVER_BASE_URL,
        timeout: 60000,
        responseType: "blob",
        withCredentials: true,
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        setDownloadingPdf(false);
      })
      .catch((err) => {
        alert(err);
        setDownloadingPdf(false);
      });
  }

  const fromUrl = new URLSearchParams(window.location.search).get("from");

  return (
    <Page
      className="flex flex-col flex-1 sm:min-h-screen"
      title={`${productName} ✕ ${report.qty}`}
      subtitle={report.factory.name}
      backTo={fromUrl || "/qc/final"}
      rightButtons={
        <div className="flex space-x-6 justify-end">
          {hasPermission(INTERNAL) ? (
            <div className="flex items-center space-x-4">
              <label htmlFor="">
                Share with Customer({report.invoice.customer.name}):{" "}
              </label>
              <Switcher
                isOn={report.sharedWithCustomerAt !== null}
                disabled={saveReportRes.loading}
                loading={saveReportRes.loading}
                onChange={() =>
                  saveReport({
                    variables: {
                      id,
                      shareWithCustomer: report.sharedWithCustomerAt === null,
                      sendNotification: false,
                    },
                  })
                }
              />
            </div>
          ) : null}
          <Button
            disabled={downloadingPdf}
            className="btn"
            onClick={(_) =>
              exportPdf(
                id,
                `QC Report - ${report.invoice.odooModelType.toUpperCase()}_${report.invoice.number} - ${report.product.number}.pdf`,
              )
            }
            title={downloadingPdf ? "Downloading PDF..." : "Download PDF"}
          />
        </div>
      }
    >
      {saveReportRes.loading ? <Spinner text="Updating..." /> : null}
      <div className="flex flex-1 h-screen bg-white">
        <iframe
          title="QC Report"
          className="flex-1 w-full px-4"
          srcDoc={report.html}
        />
      </div>
    </Page>
  );
}

export default QCReportPreview;
