import { useQuery, gql } from '@apollo/client';
import Spinner from 'components/Spinner';
import Errors from 'components/Errors';
import { formatDate } from 'react-day-picker/moment';
import { useState } from 'react';
import CharlesButton from 'components/charles/base';

const FETCH_COST_UPDATE_HISTORY = gql`
   query FETCH_COST_UPDATE_HISTORY($offset: Int, $limit: Int) {
      actions(
         host: "wis cost system"
         options: { limit: $limit, offset: $offset }
      ) {
         total
         results {
            id
            name
            content
            user {
               id
               email
            }
            created
         }
      }
   }
`;

const PAGE_SIZE = 10;

const UpdateHistory = () => {
   const [offset, setOffset] = useState(0);
   const { loading, error, data } = useQuery(FETCH_COST_UPDATE_HISTORY, {
      fetchPolicy: 'network-only',
      variables: { offset, limit: PAGE_SIZE },
   });

   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;

   const results = data.actions.results;
   const total = data.actions.total;

   return (
      <div className="p-6 flex-1 overflow-auto flex flex-col">
         <div className="flex-1 card px-6 py-2">
            <div>
               {results.map((action) => (
                  <div
                     key={action.id}
                     className="border-b py-4 dark:border-gray-700 space-y-1"
                  >
                     <h4>{action.name}</h4>
                     <div className="text-gray-500">
                        {formatDate(action.created, 'YYYY-MM-DD hh:mm:ss')} by{' '}
                        <b>{action.user.email}</b>
                     </div>
                     <div className="whitespace-pre-wrap leading-7">
                        {action.content}
                     </div>
                  </div>
               ))}
            </div>

            <div className="p-6 flex justify-end items-center space-x-2 opacity-60">
               {offset > 0 ? (
                  <CharlesButton
                     prev
                     onClick={() => setOffset(offset - PAGE_SIZE)}
                  />
               ) : null}
               <div>
                  {offset + 1}-{Math.min(PAGE_SIZE + offset, total)} / {total}
               </div>
               {total > offset + PAGE_SIZE ? (
                  <CharlesButton
                     next
                     onClick={() => setOffset(PAGE_SIZE + offset)}
                  />
               ) : null}
            </div>
         </div>
      </div>
   );
};

export default UpdateHistory;
