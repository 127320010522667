import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { formatDate } from "react-day-picker/moment";
import { DatePicker, Input, Select, Text } from "components/Form";
import { Alert } from "components/Toast";
import { ARCHIVE_PRODUCT_PRICE, FETCH_PRODUCT_PRICE_DETAIL } from "./graphql";
import CharlesButton from "components/charles/base";

export const ARCHIVE_CODES = [
  {
    code: "1",
    description: "Exchange Rate",
  },
  {
    code: "2",
    description: "Raw Materials Cost Update",
  },
  {
    code: "3",
    description: "Labor Costs Changes",
  },
  {
    code: "4",
    description: "Packaging Update",
  },
  {
    code: "5",
    description: "Production Complexity",
  },
  {
    code: "6",
    description: "Order Qty",
  },
  {
    code: "7",
    description: "Re-negotiation",
  },
  {
    code: "8",
    description: "Change Supplier",
  },
  {
    code: "9",
    description: "Change Currency",
  },
  {
    code: "10",
    description: "Product Redesign",
  },
  {
    code: "1000",
    description: "Correction",
  },
];

const ArchivePriceForm = ({ productId, price, onArchived }) => {
  const [code, setCode] = useState("1");
  const [notes, setNotes] = useState("");
  const [newPrice, setNewPrice] = useState("");
  const [newPriceEffectiveDate, setNewPriceEffectiveDate] = useState();

  const [archivePrice, archivePriceRes] = useMutation(ARCHIVE_PRODUCT_PRICE, {
    onCompleted: (res) => {
      Alert("success", "Archived.");
      if (onArchived) onArchived(res.saveProductPrice.effectedProducts);
    },
    onError: (error) => Alert("error", error.message),
    refetchQueries: [{ query: FETCH_PRODUCT_PRICE_DETAIL, variables: { id: productId } }],
    awaitRefetchQueries: true,
  });

  useEffect(() => {
    setNotes("");
    setNewPrice("");
    setNewPriceEffectiveDate("");
  }, [price]);

  return (
    <div>
      <div className="space-y-4">
        <div>
          <label className="font-bold mr-4">Supplier: </label> {price && price.factory ? price.factory.name : null}
        </div>
        <div className="flex space-x-8">
          <div>
            <label className="font-bold mr-4">Min Qty: </label> {price ? price.minQty : null}
          </div>
          <div>
            <label className="font-bold mr-4">Price: </label> {price ? price.price : null} {price ? price.currency : null}
          </div>
          <div>
            <label className="font-bold mr-4">Effective Date: </label> {price ? price.supplierUpdatedAt : null}
          </div>
        </div>

        <div>
          <label className="font-bold mr-4">Update Reason:</label>
          <Select className="ml-2" value={code} onChange={(e) => setCode(e.target.value)}>
            {ARCHIVE_CODES.map(({ code, description }) => (
              <option value={code} key={code}>
                {description}
              </option>
            ))}
          </Select>
        </div>
        <div>
          <label className="font-bold mr-4">
            Archive Notes <span className=" font-normal text-base">(Optional)</span>
          </label>
          <Text className="mt-2" value={notes} onChange={(e) => setNotes(e.target.value)} />
        </div>
      </div>

      <hr />

      <div>
        <h4>
          New Cost <span className=" font-normal text-base">(Optional)</span>
        </h4>
        <div className="mt-4">
          <div className="flex items-center space-x-4">
            <label className="font-bold">Price:</label>
            <Input value={newPrice} onChange={(e) => setNewPrice(e.target.value)} />
            <span>{price ? price.currency : null}</span>

            <label className="font-bold">Effective Date: </label>
            <DatePicker value={newPriceEffectiveDate} onDayChange={(date) => setNewPriceEffectiveDate(formatDate(date, "YYYY-MM-DD"))} />
          </div>
          <div className="text-xs text-gray-500 mt-2">
            If you input a new price here, we will archive the current price, and create a new price with the same previous info include supplier, currency, min
            qty. Otherwise, leave this empty will just archive this price without creating new price.
          </div>
        </div>
      </div>

      <hr />

      <CharlesButton
        className="mt-4"
        danger
        loading={archivePriceRes.loading}
        onClick={() =>
          archivePrice({
            variables: {
              id: price.id,
              archivedCode: code,
              archivedNotes: notes,
              newPrice: newPrice ? parseFloat(newPrice) : null,
              newPriceEffectiveDate: newPriceEffectiveDate ? newPriceEffectiveDate : null,
            },
          })
        }
      >
        Confirm and Archive
      </CharlesButton>
    </div>
  );
};

export default ArchivePriceForm;
