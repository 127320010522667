import AssessmentQuestion from "./AssessmentQuestion";

const CategoryQuestions = ({ category, number, requiredSupplier }) => {
  const categorySelfQuestions = category.questions.filter((question) => question.question.category.id === category.id);

  return (
    <div>
      {category.children ? (
        <div>
          {category.children.map((child, childIndex) => {
            const childNumber = `${number}.${childIndex + 1}`;
            return (
              <div key={child.id} className="pt-4">
                <h5 className="font-bold">
                  {childNumber}. {child.name}
                </h5>
                <CategoryQuestions number={childNumber} category={child} requiredSupplier={requiredSupplier} />
              </div>
            );
          })}
        </div>
      ) : null}

      {categorySelfQuestions.length > 0 ? (
        <div>
          {categorySelfQuestions.map((question, questionIndex) => (
            <div key={question.id} className={`${questionIndex > 0 ? "border-t" : ""} border-gray-100 dark:border-gray-700 py-6`} id={question.id}>
              <AssessmentQuestion assessmentQuestion={question} number={`${number}.${questionIndex + 1}`} requiredSupplier={requiredSupplier} />
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default CategoryQuestions;
