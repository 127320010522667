import Errors from "components/Errors";
import SearchBar from "components/SearchBar";
import Spinner from "components/Spinner";
import useOdooShipmentObjects from "hooks/useOdooShipmentObjects";
import { useState } from "react";
import { searchByProp } from "utils/search";

const ChooseOdooObjectView = ({ odooPartnerId, onSelect }) => {
  const { loading, error, data } = useOdooShipmentObjects(odooPartnerId);
  const [searchText, setSearchText] = useState("");

  if (loading) return <Spinner text="Loading Data from Odoo..." />;
  if (error) return <Errors error={error} />;

  const salesOrders = JSON.parse(data.salesOrders).map((i) => ({ ...i, type: "so" }));
  const transfers = JSON.parse(data.transfers).map((i) => ({ ...i, type: "transfer" }));

  const lines = [...salesOrders, ...transfers].filter((i) => searchByProp(i, ["name"], searchText));

  return (
    <div>
      <div className=" opacity-70 text-sm leading-relaxed">
        <p>Choose a sales order or transfer from Odoo to start, you can add more after shipment created.</p>
        <p>
          Alternative you can{" "}
          <span className="text-blue-500 hover:text-blue-600 active:text-blue-700 cursor-pointer" onClick={() => onSelect()}>
            create shipment without adding order or transfer
          </span>{" "}
          right now.
        </p>
      </div>

      <div className="mt-4">
        <SearchBar bgColor="dark:bg-gray-900" value={searchText} onChange={setSearchText} placeholder="Search" />
      </div>

      <div className="mt-2">
        {lines.map((i) => (
          <div className="border-b border-gray-100 dark:border-gray-700 px-2 py-2 hover:text-sky-600 cursor-pointer" key={i.id} onClick={() => onSelect(i)}>
            {i.name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChooseOdooObjectView;
