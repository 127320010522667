import { gql, useMutation, useQuery } from "@apollo/client";
import Errors from "components/Errors";
import { Input } from "components/Form";
import Spinner from "components/Spinner";
import { Alert } from "components/Toast";
import { SAVE_PRODUCT } from "pages/manage/products/All/graphql";
import { useState } from "react";
import { useDebouncedCallback } from "use-debounce";

const FETCH_PRODUCT_SHIPPING_MARK_DATA = gql`
  query FETCH_PRODUCT_SHIPPING_MARK_DATA {
    allProducts(hasProductLine: true, isActive: true, productType: "normal", isSalable: true) {
      id
      number
      name
      shippingMarkName
      colors
      barCode
      innerBoxBarcodeEan
      innerBoxBarcodeUpc
      outerCartonBarcodeEan
      outerCartonBarcodeUpc
    }
  }
`;

const ShippingMark = () => {
  const { loading, error, data } = useQuery(FETCH_PRODUCT_SHIPPING_MARK_DATA);

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  return (
    <table className="bg-gray-50 dark:bg-gray-800">
      <thead className="whitespace-nowrap">
        <tr>
          <th className="px-6 z-20 sticky top-0 left-0 backdrop-blur bg-gray-200 dark:bg-gray-700 bg-opacity-80">Products({data.allProducts.length})</th>
          <th className="px-6 z-10 sticky top-0 backdrop-blur bg-gray-200 dark:bg-gray-700 bg-opacity-80">Shipping Mark Name / Colors</th>

          <th className="px-6 z-10 sticky top-0 backdrop-blur bg-gray-200 dark:bg-gray-700 bg-opacity-80">EAN</th>
          <th className="px-6 z-10 sticky top-0 backdrop-blur bg-gray-200 dark:bg-gray-700 bg-opacity-80">UPC</th>
        </tr>
      </thead>
      <tbody>
        {data.allProducts.map((i) => (
          <ShippingMarkName product={i} key={i.id} />
        ))}
      </tbody>
    </table>
  );
};

const ShippingMarkName = ({ product }) => {
  const [values, setValues] = useState({
    shippingMarkName: product.shippingMarkName,
    outerCartonBarcodeEan: product.outerCartonBarcodeEan,
    outerCartonBarcodeUpc: product.outerCartonBarcodeUpc,
    innerBoxBarcodeEan: product.innerBoxBarcodeEan,
    innerBoxBarcodeUpc: product.innerBoxBarcodeUpc,
    colors: product.colors,
  });
  const [saveProduct] = useMutation(SAVE_PRODUCT, {
    onError(error) {
      Alert("error", error.message);
    },
    onCompleted() {
      Alert("success", `[${product.number}] ${product.name} Updated.`);
    },
  });

  const debounceSave = useDebouncedCallback(saveProduct, 500);

  function onChange(field, value) {
    const simpleFields = { ...values, [field]: value };
    setValues(simpleFields);
    debounceSave({ variables: { id: product.id, simpleFields } });
  }

  return (
    <>
      <tr>
        <td rowSpan={2} className="px-6 sticky left-0 bg-gray-50 dark:bg-gray-800 border-b border-gray-200 border-opacity-50 z-10">
          <span>
            [{product.number}] {product.name}
          </span>
          <a
            href={`${process.env.REACT_APP_SERVER_ADMIN_URL}/product/product/${product.id}/change/#id_bar_code`}
            target="_blank"
            rel="noreferer"
            className="text-blue-700 ml-2"
          >
            admin
          </a>
        </td>
        <td rowSpan={2} className="px-6 border-b border-gray-200 border-opacity-50">
          <div className="grid grid-cols-3 gap-2 items-center">
            <label htmlFor="">Name: </label>
            <Input className="col-span-2" value={values.shippingMarkName || ""} onChange={(e) => onChange("shippingMarkName", e.target.value)} />
            <label htmlFor="">Colors: </label>
            <Input className="col-span-2" value={values.colors || ""} onChange={(e) => onChange("colors", e.target.value)} />
          </div>
        </td>

        <td className="px-6">
          <div className="grid grid-cols-3 gap-2 items-center">
            <label>Inner Box: </label>
            <Input
              className="text-center col-span-2"
              value={values.innerBoxBarcodeEan || ""}
              onChange={(e) => onChange("innerBoxBarcodeEan", e.target.value)}
            />

            <label>Outer Carton: </label>
            <Input
              className="text-center col-span-2"
              value={values.outerCartonBarcodeEan || ""}
              onChange={(e) => onChange("outerCartonBarcodeEan", e.target.value)}
            />
          </div>
        </td>

        <td className="px-6">
          <div className="grid grid-cols-3 gap-2 items-center">
            <label>Inner Box: </label>
            <Input
              className="text-center col-span-2"
              value={values.innerBoxBarcodeUpc || ""}
              onChange={(e) => onChange("innerBoxBarcodeUpc", e.target.value)}
            />

            <label>Outer Carton: </label>
            <Input
              className="text-center col-span-2"
              value={values.outerCartonBarcodeUpc || ""}
              onChange={(e) => onChange("outerCartonBarcodeUpc", e.target.value)}
            />
          </div>
        </td>
      </tr>
      <tr>
        <td className="pt-0 px-6 border-b" colSpan={2}>
          <span className="opacity-60 text-xs">Current Barcode: {product.barCode}</span>
        </td>
      </tr>
    </>
  );
};

export default ShippingMark;
