import { NavigationLink } from 'components/base';
import Page from 'components/Page';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import OdooTransfers from './transfers';
import Sales from './sales';

const OdooPages = () => {
   return (
      <Routes>
         <Route element={<Layout />}>
            <Route path="sales" element={<Sales />} />
            <Route path="transfers/*" element={<OdooTransfers />} />
            <Route path="*" element={<Navigate to="sales" />} />
         </Route>
      </Routes>
   );
};

const Layout = () => (
   <Page
      className="lg:h-screen"
      leftButtons={
         <div className="flex space-x-4">
            <NavigationLink to="sales" title="Sale Orders" />
            <NavigationLink to="transfers" title="Transfers" />
         </div>
      }
   >
      <Outlet />
   </Page>
);

export default OdooPages;
