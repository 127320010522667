const ProductLines = ({ lines, searchText }) => {
  return (
    <div className="px-6">
      {lines.map((line) => (
        <div className="border-b py-2" key={line.id}>
          {line.name}
        </div>
      ))}
    </div>
  );
};

export default ProductLines;
