import { gql, useQuery } from "@apollo/client";
import Errors from "components/Errors";
import SearchBar from "components/SearchBar";
import Spinner from "components/Spinner";
import { useState } from "react";
import { searchByProp } from "utils/search";

const FETCH_CUSTOMERS = gql`
  query FETCH_CUSTOMERS {
    customers(connectedToOdoo: true) {
      id
      name
      odooPartnerId
    }
  }
`;

const ChooseCustomerView = ({ choose }) => {
  const { loading, error, data } = useQuery(FETCH_CUSTOMERS);
  const [searchText, setSearchText] = useState("");

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const customers = data.customers.filter((i) => searchByProp(i, ["name"], searchText));

  return (
    <div>
      <div>
        <SearchBar bgColor="dark:bg-gray-900" autoFocus onChange={setSearchText} placeholder="Search by Name" />
      </div>

      <div className="mt-4">
        {customers.map((customer) => (
          <div
            key={customer.id}
            className="p-2 border-b dark:border-gray-700 flex justify-between cursor-pointer hover:text-sky-600"
            onClick={() => choose(customer)}
          >
            <div>{customer.name}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChooseCustomerView;
