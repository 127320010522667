import { useMutation, gql, useQuery } from "@apollo/client";
import { Button } from "components/base";
import { Input, Select } from "components/Form";
import { Alert } from "components/Toast";
import { useState } from "react";

const CREATE_ODOO_PRODUCT_CATEGORY = gql`
  mutation CREATE_ODOO_PRODUCT_CATEGORY($name: String, $parentId: String) {
    odooCreateProductCategory(name: $name, parentId: $parentId) {
      id
      name
    }
  }
`;

const FETCH_ODOO_PRODUCT_CATEGORIES = gql`
  query FETCH_ODOO_PRODUCT_CATEGORIES {
    odooProductCategories {
      id
      displayName
    }
  }
`;

const OdooProductCategoryForm = ({ categories, onSave }) => {
  const [create, createRes] = useMutation(CREATE_ODOO_PRODUCT_CATEGORY, {
    onCompleted: (res) => {
      Alert("success", "Odoo Category Created.");
      if (onSave) onSave(res.odooCreateProductCategory);
    },
    onError: (error) => Alert("error", error.message),
    refetchQueries: [{ query: FETCH_ODOO_PRODUCT_CATEGORIES }],
    awaitRefetchQueries: true,
  });
  const [parentId, setParentId] = useState("0");
  const [name, setName] = useState("");

  function saveHandler() {
    if (name === "") {
      Alert("error", "Please input a name.");
      return;
    }
    if (categories.map((i) => i.displayName).includes(name)) {
      Alert("error", "Name already exists.");
      return;
    }
    create({ variables: { name, parentId } });
  }

  return (
    <div>
      <div className="space-y-4">
        <div className="flex items-center">
          <label className="w-32 pr-4">Name:</label>
          <Input value={name} onChange={(e) => setName(e.target.value)} />
        </div>

        <div className="flex items-center">
          <label className="w-32 pr-4">Parent:</label>
          <Select value={parentId} onChange={(e) => setParentId(e.target.value)}>
            <option value="0"> ------ </option>
            {categories.map((i) => (
              <option key={i.id} value={i.id}>
                {i.displayName}
              </option>
            ))}
          </Select>
        </div>

        <div className="opacity-70 text-xs pt-2">Here you can create a product category directly into Odoo thru the Odoo API.</div>
      </div>

      <div className="mt-6">
        <Button title="Save" size="xl" bold onClick={saveHandler} disabled={createRes.loading} loading={createRes.loading} />
      </div>
    </div>
  );
};

export default OdooProductCategoryForm;
