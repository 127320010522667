import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import Spinner from 'components/Spinner';
import Errors from 'components/Errors';
import { Button, NavigationLink } from 'components/base.js';
import { Alert } from 'components/Toast.js';
import http from 'utils/http';
import track, { actions } from 'utils/track';
import Page from 'components/Page';

const FETCH_COMMERCIAL_INVOICE = gql`
   query FETCH_COMMERCIAL_INVOICE($id: ID!) {
      commercialInvoice(id: $id) {
         id
         shipment {
            id
            invoiceNumber
            customer {
               id
               name
            }
         }
         html
         created
      }
   }
`;

function CommercialInvoicePreview() {
   const { id } = useParams();
   const [downloadingPdf, setDownloadingPdf] = useState(false);
   const [downloadingExcel, setDownloadingExcel] = useState(false);
   const { loading, error, data } = useQuery(FETCH_COMMERCIAL_INVOICE, {
      variables: { id },
      fetchPolicy: 'cache-and-network',
   });

   function setLoading(filetype, loading) {
      if (filetype === 'Pdf') setDownloadingPdf(loading);
      if (filetype === 'Excel') setDownloadingExcel(loading);
   }

   const exportFile = (id, filename, filetype) => {
      setLoading(filetype, true);
      track(actions.shipment.exportCommercialInvoice.name, {
         format: filetype.toLowerCase(),
      });
      http
         .get(`/commercial-invoice/${id}/?${filetype.toLowerCase()}=1`, {
            baseURL: process.env.REACT_APP_SERVER_BASE_URL,
            timeout: 60000,
            responseType: 'blob',
         })
         .then((res) => {
            console.log('res', res);
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            setLoading(filetype, false);
         })
         .catch((err) => {
            Alert('error', String(err));
            setLoading(filetype, false);
         });
   };

   if (error) return <Errors error={error} />;
   if (loading) return <Spinner />;
   const { shipment, html } = data.commercialInvoice;
   return (
      <Page
         title={`#${shipment.invoiceNumber} - ${shipment.customer.name}`}
         backTo="/shipment/doc/commercial-invoice"
         rightButtons={
            <div className="flex">
               <Button
                  title="Edit Shipment"
                  link={`/shipment/shipments/${shipment.id}`}
               />
               <Button
                  className="ml-4"
                  title="Edit Commercial Invoice"
                  link={`/shipment/doc/commercial-invoice/${id}/edit`}
               />
               <Button
                  title={downloadingPdf ? 'Exporting...' : 'Export PDF'}
                  disabled={downloadingPdf}
                  loading={downloadingPdf}
                  className="ml-4"
                  onClick={(_) =>
                     exportFile(
                        id,
                        `Commercial Invoice #${shipment.invoiceNumber}.pdf`,
                        'Pdf'
                     )
                  }
               />
               <Button
                  title={downloadingExcel ? 'Exporting...' : 'Export Excel'}
                  disabled={downloadingExcel}
                  loading={downloadingExcel}
                  className="ml-4"
                  onClick={(_) =>
                     exportFile(
                        id,
                        `Commercial Invoice #${shipment.invoiceNumber}.xlsx`,
                        'Excel'
                     )
                  }
               />
            </div>
         }
      >
         <div className="flex flex-col flex-1">
            <iframe
               title="PackingLists Preview"
               className="flex-1 w-full bg-white"
               srcDoc={html}
               frameBorder="0"
            />
         </div>
      </Page>
   );
}

export default CommercialInvoicePreview;
