export const calcSuitablePrice = (product, qty) => {
   let res = null;

   const allMonthes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
   const month = new Date().getMonth() + 1;

   let sortedPrices = [
      ...product.prices
         .filter((i) => i.archivedAt === null)
         .filter((i) => {
            if (i.avaliableToMonth === 1 && i.avaliableToMonth === 12)
               return true;

            const start = i.avaliableFromMonth;
            const end = i.avaliableToMonth;
            const startBiggerThanEnd = start > end;

            const validMonths = startBiggerThanEnd
               ? allMonthes.filter((m) => m <= end || m >= start)
               : allMonthes.filter((m) => m >= start && m <= end);
            return validMonths.includes(month);
         }),
   ].sort((a, b) => a.minQty - b.minQty);

   if (product.defaultSupplier) {
      sortedPrices = sortedPrices.filter(
         (i) => i.factory.id === product.defaultSupplier.id
      );
   }
   if (sortedPrices.length > 0) {
      sortedPrices = sortedPrices
         .filter((i) => i.minQty <= qty)
         .sort((a, b) => a.price - b.price);
      res = sortedPrices[0];
   }
   return res;
};

export const productCost = (product, qty, rate) => {
   const res = calcSuitablePrice(product, qty);
   if (res) return res.currency === 'USD' ? res.price : res.price / rate;
   return 0;
};

export const childCost = (child, set, rate = 1) => {
   const qty = child.qty * set;
   const res = productCost(child.product, qty, rate) * qty;
   if (isNaN(res)) return 0;
   return res;
};

export const productItemsPerSet = (product) => {
   let itemsPerSet = product.itemsPerSet;
   if (!itemsPerSet)
      itemsPerSet = Math.max.apply(
         Math,
         product.children.map((i) => i.qty)
      );
   return itemsPerSet;
};

export const productUnitCost = (product, qty, rate) => {
   const itemsPerSet = productItemsPerSet(product);
   const sets = qty / itemsPerSet;
   if (sets < 1) return 0;
   const res =
      product.children.reduce((prev, child) => {
         return prev + childCost(child, sets, rate);
      }, 0) / qty;
   if (isNaN(res)) return 0;
   return res;
};

export const variantUpdatedAt = (variant) => {
   const itemsPerSet = productItemsPerSet(variant);

   const prices = variant.children
      .map(({ product, qty }) => {
         const sets = variant.defaultQty / itemsPerSet;
         return calcSuitablePrice(product, sets * qty);
      })
      .filter((i) => !!i);

   if (prices.length > 0) {
      const suppliersUpdatedAt = prices.map(
         (i) => new Date(i.supplierUpdatedAt)
      );
      return new Date(Math.max.apply(null, suppliersUpdatedAt));
   }

   return null;
};

export const productComponentCurrencyList = (product, qty) => {
   const maxQty = Math.max.apply(
      Math,
      product.children.map((i) => i.qty)
   );
   const set = qty / maxQty;
   if (set < 1) return [];

   const prices = product.children
      .map((productComponent) => {
         const qty = set * productComponent.qty;
         const suitablePrice = calcSuitablePrice(
            productComponent.component,
            qty
         );
         return suitablePrice;
      })
      .filter((i) => i !== null);

   return prices.map((i) => i.currency);
};

export const INC_AREA_IDS = [2, 12, 14, 15, 22, 23, 25, 25, 26, 27];

export const FOB_COST = { '20GP': 600, '40GP': 700, '40HQ': 700 };

export const productCostPerUnit = (product, qty, rate) => {
   const res = calcSuitablePrice(product, qty);
   if (res) return res.currency === 'USD' ? res.price : res.price / rate;
   return 0;
};
