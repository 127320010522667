import React, { useContext } from "react";
import ShipmentInvoice from "./ShipmentInvoice";
import ShipmentContext from "../ShipmentContext";
import { Alert } from "../../../../components/Toast";
import { PALLET_NUMBER } from "../../../calculator/v2/Calculator";
import CharlesButton from "components/charles/base";
import { useModals } from "ModalProvider";
import PreviewTotalByHSCodeView from "../PreviewTotalByHSCodeView";
import ConfirmOdooObjectView from "./ConfirmOdooObjectView";
import ChooseOdooObjectsView from "./ChooseOdooObjectView";

function ShipmentInvoices() {
  const { state, dispatch } = useContext(ShipmentContext);
  const modal = useModals();
  const chooseOdooObjectModal = useModals();
  const confirmOdooObjectModal = useModals();

  var totalPallets = 0;
  state.shipmentInvoices.forEach((shipmentInvoice) => {
    shipmentInvoice.shipmentInvoiceRows.forEach((shipmentInvoiceRow) => {
      totalPallets += Math.ceil(shipmentInvoiceRow.totalCbm / PALLET_NUMBER);
    });
  });

  function previewTotalByHSCode() {
    modal.present({
      title: "Total by HS Code",
      maxWidth: "max-w-6xl",
      children: <PreviewTotalByHSCodeView shipment={state} />,
    });
  }

  function showOdooInvoiceSelectorView() {
    chooseOdooObjectModal.present({
      title: "Sales Order or Transfer",
      subtitle: state.customer.name,
      children: <ChooseOdooObjectsView odooPartnerId={state.customer.odooPartnerId} onSelect={didChooseOdooObject} />,
    });
  }

  function didChooseOdooObject(item) {
    confirmOdooObjectModal.present({
      title: item.name,
      subtitle: "Choose product and qty to create shipment invoice",
      maxWidth: "max-w-5xl",
      children: <ConfirmOdooObjectView item={item} type="CREATE_SHIPMENT_INVOICE" shipmentId={state.id} complete={didCreateShipmentInvoice} />,
    });
  }

  function didCreateShipmentInvoice(shipmentInvoice) {
    Alert("success", "Shipment Invoice created");
    dispatch({ type: "createShipmentInvoiceFromOdoo", payload: shipmentInvoice });
    confirmOdooObjectModal.hide();
    chooseOdooObjectModal.hide();
  }

  return (
    <div>
      <div className="text-lg font-bold pb-4 dark:text-gray-300">Products</div>

      <div className="flex justify-between">
        <CharlesButton onClick={showOdooInvoiceSelectorView}>+ add shipment invoice</CharlesButton>
      </div>

      <div className="mt-6">
        {state.shipmentInvoices.map((shipmentInvoice) => (
          <ShipmentInvoice key={shipmentInvoice.id} shipmentInvoice={shipmentInvoice} />
        ))}

        {state.shipmentInvoices.length > 0 && (
          <div>
            <div className="lg:flex items-center bg-red-500 dark:bg-red-800 text-white dark:text-gray-200 text-base rounded-lg mt-6 px-4 lg:px-6 py-2">
              <div className="lg:p-2 font-bold border-red-500 w-48">Total</div>
              <div className="lg:flex flex-1 items-center">
                <div className="flex-1 font-bold">Qty: {state.totalQty}</div>
                <div className="flex-1 font-bold lg:text-right">CTN: {state.totalCarton}</div>
                <div className="flex-1 font-bold lg:text-right">PALLETS: {totalPallets}</div>
                <div className="flex-1 font-bold lg:text-right">N.W.: {parseFloat(state.totalNetWeight).toFixed(2)}</div>
                <div className="flex-1 font-bold lg:text-right">G.W.: {parseFloat(state.totalGrossWeight).toFixed(2)}</div>
                <div className="flex-1 font-bold lg:text-right">CBM: {parseFloat(state.totalCbm).toFixed(3)}</div>
              </div>
            </div>
            <div className="mt-4">
              <CharlesButton onClick={previewTotalByHSCode}>Preview Total by HS Code</CharlesButton>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ShipmentInvoices;
