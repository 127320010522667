import { useQuery } from "@apollo/client";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import ArchivePriceForm from "./ArchivePriceForm";
import useFactories from "hooks/useFactories";
import { FETCH_PRODUCT_PRICE_DETAIL } from "./graphql";
import ProductOdooPrices from "./ProductOdooPrices";
import { useModals } from "ModalProvider";
import CharlesButton from "components/charles/base";
import ProductPriceForm from "./ProductPriceForm";
import ArchivedPricesView from "./ArchivedPricesView";
import EffectedProductsView from "./EffectedProductsView";

const ProductPricesView = ({ id, currentFactory }) => {
  const factoriesQuery = useFactories();
  const { loading, error, data } = useQuery(FETCH_PRODUCT_PRICE_DETAIL, {
    variables: { id },
  });

  const archiveModal = useModals();
  const addCostModal = useModals();
  const effectedModal = useModals();

  function handleEffectedProducts(effectedProducts) {
    console.log("handleEffectedProducts", effectedProducts);
    effectedModal.present({
      title: "Effected Products",
      maxWidth: "max-w-6xl",
      isBeingPresented: true,
      children: <EffectedProductsView products={effectedProducts} />,
    });
  }

  function tryAddCost() {
    addCostModal.present({
      title: "Add Cost",
      center: true,
      children: (
        <ProductPriceForm
          initialPrice={{
            id: null,
            minQty: 0,
            price: 0,
            currency: "USD",
            factory: currentFactory,
            avaliableFromMonth: 1,
            avaliableToMonth: 12,
            archivedAt: null,
            productId: id,
          }}
          handleEffectedProducts={(effectedProducts) => {
            addCostModal.hide();
            handleEffectedProducts(effectedProducts);
          }}
          inModal
        />
      ),
    });
  }

  function tryArchive(initialPrice) {
    archiveModal.present({
      title: "Archive Cost",
      center: true,
      children: (
        <ArchivePriceForm
          productId={id}
          price={initialPrice}
          onArchived={(effectedProducts) => {
            archiveModal.hide();
            handleEffectedProducts(effectedProducts);
          }}
        />
      ),
    });
  }

  if (loading || factoriesQuery.loading) return <Spinner />;
  if (error) return <Errors error={error} />;
  if (factoriesQuery.error) return <Errors error={factoriesQuery.error} />;

  const product = data.product;

  const activePrices = product.prices
    .filter((i) => i.archivedAt === null)
    .map((i) => ({
      ...i,
      isDefaultSupplier: product.defaultSupplier && product.defaultSupplier.id && i.factory && product.defaultSupplier.id === i.factory.id,
    }))
    .sort((a, b) => (a.isDefaultSupplier === b.isDefaultSupplier ? 0 : a.isDefaultSupplier ? -1 : 1));

  const archivedPrices = product.prices.filter((i) => i.archivedAt !== null).sort((a, b) => new Date(b.archivedAt) - new Date(a.archivedAt));

  return (
    <div className="text-sm">
      <div className="flex justify-between items-center mb-4">
        <div className="font-bold text-base">
          <label>Default Supplier: </label>
          {product.defaultSupplier ? product.defaultSupplier.name : null}
        </div>
      </div>

      {product.children.length > 0 ? (
        <div className="card px-6 py-4">
          <div>This product has BOM, cost is computed from its components.</div>
          <div className="font-bold relative mt-2 text-lg text-teal-600">Computed Cost: ${product.cost}</div>
        </div>
      ) : (
        <>
          {activePrices.length > 0 ? (
            <div className="space-y-6">
              {activePrices.map((price) => (
                <ProductPriceForm
                  key={price.id}
                  initialPrice={price}
                  productId={product.id}
                  tryArchive={() => tryArchive(price)}
                  handleEffectedProducts={handleEffectedProducts}
                />
              ))}
            </div>
          ) : (
            <div className="p-6 rounded-xl bg-white border border-gray-100">
              <label htmlFor="">No prices for this item right now.</label>
            </div>
          )}

          <div className="pt-6">
            <CharlesButton className="mx-4" onClick={tryAddCost}>
              + Add Cost
            </CharlesButton>
          </div>
        </>
      )}

      {product.odooId ? (
        <div className="mt-4">
          <ProductOdooPrices product={product} productId={product.id} productOdooId={product.odooId} computed={product.children.length > 0} />
        </div>
      ) : null}

      {archivedPrices.length > 0 && <ArchivedPricesView prices={archivedPrices} />}
    </div>
  );
};

export default ProductPricesView;
