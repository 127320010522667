const NoCostDataView = ({ product }) => (
  <div>
    <div className="flex space-x-4">
      <a href={process.env.REACT_APP_SERVER_ADMIN_URL + "/product/product/" + product.id + "/change/"}>#{product.id}</a>
      {product.odooId ? <div className="text-gray-500">Odoo Id: {product.odooId}</div> : null}
    </div>

    <div className="text-base font-bold">
      [{product.number}] {product.name}
    </div>

    <div className="mt-2">
      <label>No cost data in our system yet.</label>
    </div>
  </div>
);

export default NoCostDataView;
