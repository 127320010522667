import { useMutation, gql } from '@apollo/client';

const GENERATE_DOCUMENT = gql`
   mutation GENERATE_DOCUMENT(
      $name: String!
      $docType: String!
      $data: String!
   ) {
      generateDocument(name: $name, docType: $docType, data: $data) {
         document {
            id
            name
            docType
            data
         }
      }
   }
`;

const useDocumentGenerator = () => useMutation(GENERATE_DOCUMENT);

export default useDocumentGenerator;
