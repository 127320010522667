import CharlesButton from "components/charles/base";
import XLSX from "xlsx";

const PreviewTotalByHSCodeView = ({ shipment }) => {
  const lines = shipment.shipmentInvoices.flatMap((shipmentInvoice) =>
    shipmentInvoice.shipmentInvoiceRows.map((shipmentInvoiceRow) => ({
      ...shipmentInvoiceRow,
      hsCode: shipmentInvoiceRow.hsCodeForDestination,
      shipmentInvoice,
      shipment,
      totalValue: shipmentInvoiceRow.unitPrice * shipmentInvoiceRow.qty,
    }))
  );

  console.log("shipment", shipment);

  const uniqueHSCodes = [...new Set(lines.map((line) => line.hsCode.trim()))];

  const hsCodeLines = uniqueHSCodes.map((hsCode) => {
    const totalQty = lines.filter((line) => line.hsCode.trim() === hsCode).reduce((acc, line) => acc + line.qty, 0);
    const totalNetWeight = lines.filter((line) => line.hsCode.trim() === hsCode).reduce((acc, line) => acc + line.totalNetWeight, 0);
    const totalGrossWeight = lines.filter((line) => line.hsCode.trim() === hsCode).reduce((acc, line) => acc + line.totalGrossWeight, 0);
    const totalValue = lines.filter((line) => line.hsCode.trim() === hsCode).reduce((acc, line) => acc + line.totalValue, 0);
    return {
      hsCode,
      lines: lines.filter((line) => line.hsCode.trim() === hsCode),
      totalQty,
      totalNetWeight,
      totalGrossWeight,
      totalValue,
    };
  });

  console.log("hsCodeLines", hsCodeLines);

  function exportExcel() {
    let data = [];
    for (let i = 0; i < hsCodeLines.length; i++) {
      const hsCodeLine = hsCodeLines[i];
      data.push([hsCodeLine.hsCode, "Items / Package", "QTY", "Total N.W.", "Total G.W.", "Total Value"]);
      for (let j = 0; j < hsCodeLine.lines.length; j++) {
        const line = hsCodeLine.lines[j];
        data.push([line.product.name, line.product.itemsPerSet, line.qty, line.totalNetWeight.toFixed(2), line.totalGrossWeight.toFixed(2), line.totalValue]);
      }
      data.push([
        "Total",
        "",
        hsCodeLine.totalQty,
        hsCodeLine.totalNetWeight.toFixed(2),
        hsCodeLine.totalGrossWeight.toFixed(2),
        hsCodeLine.totalValue.toFixed(2),
      ]);
      data.push([]);
    }
    const sheet = XLSX.utils.json_to_sheet(data);
    var range = XLSX.utils.decode_range(sheet["!ref"]);
    range.s.r = 1; // <-- zero-indexed, so setting to 1 will skip row 0
    sheet["!ref"] = XLSX.utils.encode_range(range);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, sheet, "Shipment_Products_by_HS_Code");
    XLSX.writeFile(wb, "Shipment_Products_by_HS_Code.xlsx");
  }

  return (
    <div>
      <div className="flex">
        <div className="bg-orange-500 text-white rounded-xl px-6 py-3 text-sm">
          Note: The value is from the shipment Commercial Invoice, so the value will be avaliable after CI is created. Please keep an eye on the currency in the
          CI too.
        </div>
      </div>

      <table className="mt-4">
        {hsCodeLines.map((hsCodeLine) => (
          <tbody key={hsCodeLine.hsCode}>
            <tr className="border-y dark:border-gray-700 bg-gray-200 dark:bg-gray-900 dark:bg-opacity-60">
              <td>{hsCodeLine.hsCode}</td>
              <td className="text-right">Items / Package</td>
              <td className="text-right">QTY</td>
              <td className="text-right">Total N.W.</td>
              <td className="text-right">Total G.W.</td>
              <td className="text-right">Total Value</td>
            </tr>
            {hsCodeLine.lines.map((line) => (
              <tr key={line.id}>
                <td>{line.product.name}</td>
                <td className="text-right">{line.product.itemsPerSet}</td>
                <td className="text-right">{line.qty}</td>
                <td className="text-right">{line.totalNetWeight.toFixed(2)}</td>
                <td className="text-right">{line.totalGrossWeight.toFixed(2)}</td>
                <td className="text-right">{line.totalValue.toFixed(2)}</td>
              </tr>
            ))}
            <tr className="font-semibold border-t dark:border-gray-700">
              <td className="py-3">Total</td>
              <td></td>
              <td className="text-right">{hsCodeLine.totalQty}</td>
              <td className="text-right">{hsCodeLine.totalNetWeight.toFixed(2)}</td>
              <td className="text-right">{hsCodeLine.totalGrossWeight.toFixed(2)}</td>
              <td className="text-right">{hsCodeLine.totalValue.toFixed(2)}</td>
            </tr>
          </tbody>
        ))}
      </table>

      <hr />

      <div className="flex justify-end">
        <CharlesButton onClick={exportExcel}>Download</CharlesButton>
      </div>
    </div>
  );
};

export default PreviewTotalByHSCodeView;
