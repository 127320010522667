import { useMutation, useQuery } from "@apollo/client";
import SearchBar from "components/SearchBar";
import CharlesButton from "components/charles/base";
import { Link, useNavigate } from "react-router-dom";
import { CREATE_ORDER, GET_ORDERS } from "./graphql";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import Page from "components/Page";
import moment from "moment";
import Status from "components/Status";
import { useContext } from "react";
import { OdooContext } from "OdooProvider";
import { RiExternalLinkFill } from "react-icons/ri";

const OrderList = () => {
  const { odooUrl } = useContext(OdooContext);
  const navigate = useNavigate();
  const { loading, error, data } = useQuery(GET_ORDERS);
  const [createOrder, createOrderRes] = useMutation(CREATE_ORDER, {
    onCompleted: (data) => {
      navigate(`/orders/${data.createOrder.order.id}`);
    },
    onError: (error) => {
      console.log(error);
    },
    refetchQueries: [GET_ORDERS],
  });

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  return (
    <Page title="Sales Orders - beta testing">
      <div className="p-6">
        <div>
          This page is designed to create sales orders for AB warehouses. The sales orders will consume the stock in WIS - Amazon Vendor Center / Stock page,
          and it will be created to Odoo automatically once you start planning. Please contact Charlie if you have any questions.
        </div>

        <div className="flex space-x-4 items-center mt-4">
          <CharlesButton onClick={createOrder} loading={createOrderRes.loading}>
            + Create Order
          </CharlesButton>
          <div className="flex-1">
            <SearchBar placeholder="Search by customer, ref, product..." />
          </div>
        </div>

        <div className="card px-4 py-2 mt-4">
          <table>
            <thead>
              <tr>
                <th>Order (Ref)</th>
                <th>Customer</th>
                <th>State</th>
                <th>Odoo</th>
                <th className="text-right">Created At</th>
              </tr>
            </thead>
            <tbody>
              {data.orders.results.map((order) => (
                <tr key={order.id} className="border-t dark:border-gray-700">
                  <td>
                    <Link to={`/orders/${order.id}`}>
                      #{order.id}
                      {order.odooSalesOrderClientOrderRef ? ` (${order.odooSalesOrderClientOrderRef})` : null}
                    </Link>
                  </td>
                  <td>{order.customer ? order.customer.name : "N/A"}</td>
                  <td>
                    <Status status={order.state} />
                  </td>
                  <td>
                    {order.odooSalesOrderId ? (
                      <a
                        target="_blank"
                        className="text-purple-600 flex items-center space-x-1"
                        rel="noreferrer"
                        href={`${odooUrl}/web#id=${order.odooSalesOrderId}&cids=1&menu_id=408&action=312&model=sale.order&view_type=form`}
                      >
                        <span>{order.odooSalesOrderName}</span>
                        <span>
                          <RiExternalLinkFill />
                        </span>
                      </a>
                    ) : null}
                  </td>
                  <td className="text-right opacity-70">
                    {moment(order.createdAt).format("YYYY-MM-DD HH:mm A")} by {order.createdBy ? order.createdBy.email : "-"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Page>
  );
};

export default OrderList;
