import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useModals } from "ModalProvider";
import Errors from "components/Errors";
import Page from "components/Page";
import Spinner from "components/Spinner";
import CharlesButton from "components/charles/base";
import { useContext, useEffect, useState } from "react";
import { FETCH_ALL_PACKAGES, CREATE_PACKAGE, UPDATE_PACKAGE, DELETE_PACKAGE, FETCH_ALL_PACKAGE_ADDRESSES, FETCH_COURIER_MATCH_INFO } from "./graphql";
import { Alert } from "components/Toast";
import { formatDate } from 'react-day-picker/moment';
import { FaCheckCircle, FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import Status, { PackageStatusChain } from "components/Status";
import { Input, Select } from "components/Form";
import { AppContext } from "App";
import { useNavigate } from "react-router-dom";



const isPackageRelatedToMe = (pkg, user) => pkg.items?.some(item => item.cc?.some(cc => cc.id === user.id)) ||
  pkg.checkItems?.some(checkItem => checkItem.senders?.some(sender => sender.id === user.id)) ||
  pkg.checkItems?.some(checkItem => checkItem.receivers?.some(receiver => receiver.id === user.id));

const PackageList = () => {
  const { present, hide } = useModals();
  const { loading, error, data } = useQuery(FETCH_ALL_PACKAGES);
  const { user } = useContext(AppContext);
  const navigate = useNavigate();

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  return (
    <div>
      <table>
        <thead>
          <tr>
            <th></th>
            <th>No.</th>
            <th>Images</th>
            <th>Destination</th>
            <th>Shipment Date</th>
            <th>Status</th>
            <th>Tracking No.</th>
            <th>Courier</th>
          </tr>
        </thead>
        <tbody>
          {data.allPackages.map((pkg, index) => (
            <tr key={index} className="h-12">
              <td className="w-12">
                <CharlesButton
                  onClick={() => navigate(`/tools/package/${pkg.id}`)}
                  className="border border-sky-300 text-sky-500 hover:text-sky-800 hover:bg-sky-500 hover:border-sky-500 active:border-sky-700 text-xs px-2 py-1 rounded-md"
                >
                  View
                </CharlesButton>
              </td>
              <td>
                {isPackageRelatedToMe(pkg, user) ? <span className="bg-yellow-200 bg-opacity-50 rounded py-1">#{pkg.id}</span> : <span>#{pkg.id}</span>}
              </td>
              <td>
                {pkg.images?.length > 0 && pkg.images.map((image, index) => (
                  <img key={index} src={image.url} alt={image.name} className="w-12 h-12 cursor-pointer"
                    onClick={() =>
                      present({
                        center: true,
                        children: <img src={image.url} alt={image.name} />,
                      })
                    }
                  />
                ))}
              </td>
              <td>{pkg.destination.shortName}</td>
              <td>{formatDate(pkg.createdAt, 'YYYY-MM-DD')}</td>
              <td><Status status={pkg.status} /></td>
              <td>
                <div className="flex items-center space-x-2">
                  <CharlesButton
                    onClick={() => {
                      const link = pkg.method === "FEDEX" ? `https://www.fedex.com/fedextrack/?trknbr=${pkg.trackingNumber}` :
                        pkg.method === "DHL" ? `https://www.dhl.com/us-en/home/tracking/tracking-express.html?submit=1&tracking-id=${pkg.trackingNumber}` :
                          pkg.method === "UPS" ? `https://www.ups.com/track?tracknum=${pkg.trackingNumber}` :
                            null;
                      window.open(link, '_blank');
                    }}
                    className="border border-sky-300 text-sky-500 hover:text-sky-800 hover:bg-sky-500 hover:border-sky-500 active:border-sky-700 text-xs px-2 py-1 rounded-md"
                  >
                    Track
                  </CharlesButton>
                  <span>{pkg.trackingNumber}</span>
                </div>
              </td>
              <td>{pkg.method}</td>
              <td className="w-24">
                {pkg.items.length === 0 && (
                  <div className="flex justify-between">
                    <div className="flex space-x-2">
                      <CharlesButton
                        data-testid="package-edit-btn"
                        onClick={
                          () =>
                            present({
                              title: "Edit Package",
                              center: true,
                              children: <PackageForm hide={hide} pkg={pkg} action={"update"} />,
                              isBeingPresented: true,
                            })
                        }
                        icon={<FaEdit className="text-base" />}
                      />
                      <CharlesButton
                        data-testid="package-delete-btn"
                        onClick={
                          () =>
                            present({
                              title: "Delete Package",
                              center: true,
                              children: <PackageForm hide={hide} pkg={pkg} action={"delete"} />,
                              isBeingPresented: true,
                            })
                        }
                        icon={<MdDelete className="text-base" />}
                        danger={true}
                      />
                    </div>
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const PackageForm = ({ hide, pkg, action }) => {
  const packageId = pkg ? pkg.id : '';
  const [destinationId, setDestinationId] = useState(pkg ? pkg.destination.id : '');
  const [method, setMethod] = useState(pkg ? pkg.method.toLowerCase() : '');
  const [trackingNumber, setTrackingNumber] = useState(pkg ? pkg.trackingNumber : '');
  const [isMatched, setIsMatched] = useState(false);

  const { loading, error, data } = useQuery(FETCH_ALL_PACKAGE_ADDRESSES);

  const [createPackage] = useMutation(CREATE_PACKAGE, {
    variables: { destinationId, method, trackingNumber },
    refetchQueries: [{ query: FETCH_ALL_PACKAGES }],
    onCompleted: (data) => {
      hide();
      Alert('success', 'The package #' + data.createPackage.package.id + ' has been created.');
    }
  });

  const [updatePackage] = useMutation(UPDATE_PACKAGE, {
    variables: { id: packageId, destinationId, method, trackingNumber },
    refetchQueries: [{ query: FETCH_ALL_PACKAGES }],
    onCompleted: () => {
      hide();
      Alert('success', 'The package #' + packageId + ' has been updated.');
    }
  });

  const [deletePackage] = useMutation(DELETE_PACKAGE, {
    variables: { id: packageId },
    refetchQueries: [{ query: FETCH_ALL_PACKAGES }],
    onCompleted: () => {
      hide();
      Alert('success', 'The package #' + packageId + ' has been deleted.');
    }
  });

  const [fetchCourierMatchInfo, { loading: loadingMatch, error: errorMatch, data: dataMatch }] = useLazyQuery(FETCH_COURIER_MATCH_INFO);

  useEffect(() => {
    if (action !== "delete" && trackingNumber && method) {
      fetchCourierMatchInfo({
        variables: { trackingNumber, method },
      });
    }
  }, [trackingNumber, method]);

  useEffect(() => {
    if (dataMatch && dataMatch.courierMatchInfo) {
      setIsMatched(dataMatch.courierMatchInfo.isMatched);
      if (!dataMatch.courierMatchInfo.isMatched) {
        setMethod('');  // reset method if not matched
      }
      if (!dataMatch.courierMatchInfo.isEnough) {
        Alert('error', 'The tracking API quota is not enough! Please contact to IT team!');
      }
    }
  }, [dataMatch]);

  if (loading || loadingMatch) return <Spinner />;
  if (error || errorMatch) return <Errors error={error || errorMatch} />;

  const handleSubmit = () => {
    if (!destinationId || !method || !trackingNumber) {
      Alert('error', 'Please fill in all fields.');
      return;
    }
    if (packageId) {
      updatePackage();
    } else {
      createPackage();
    }
  };

  return (
    <div data-testid="package-form">
      {action === "delete" ? (
        <>
          <p className="opacity-80 mb-4">Delete this package?</p>
          <div className="text-sm space-y-2 card bg-gray-100">
            <div className="flex"><label className="w-40" >Package: </label># {pkg.id}</div>
            <div className="flex"><label className="w-40" >Destination: </label>{pkg.destination.addressDetail}</div>
            <div className="flex"><label className="w-40" >Status: </label>{pkg.status}</div>
            <div className="flex"><label className="w-40" >Tracking Number: </label>{pkg.trackingNumber}</div>
            <div className="flex"><label className="w-40" >Courier: </label>{pkg.method}</div>
            <div className="flex"><label className="w-40" >Shipment Date: </label>{formatDate(pkg.createdAt, 'YYYY-MM-DD')}</div>
          </div>
        </>
      ) : (
        <>
          <p className="opacity-80 mb-4">WIS will check the tracking number is matched with the courier.</p>

          <div className="text-sm space-y-2 card bg-gray-100">
            {packageId ? <div className="flex"><label className="w-40" >Package: </label># {pkg.id}</div> : null}
            <div className="flex items-center">
              <label className="w-40" >Destination: </label>
              <Select data-testid="package-destination" value={destinationId} onChange={e => setDestinationId(e.target.value)}>
                <option value="">Select a destination</option>
                {data.allPackageAddresses.map((pkgAddress, index) => (
                  <option key={index} value={pkgAddress.id}>{pkgAddress.shortName}</option>
                ))}
              </Select>
            </div>
            <div className="flex items-center">
              <label className="w-40" >Tracking Number: </label>
              <Input type="text" value={trackingNumber} onChange={e => setTrackingNumber(e.target.value)} />
              <FaCheckCircle className={`text-xl ml-2 ${isMatched ? 'text-green-500' : 'text-gray-300'}`} />
            </div>
            <div className="flex items-center">
              <label className="w-40" >Courier: </label>
              <Select value={method} onChange={e => setMethod(e.target.value)} className=" rounded-sm">
                <option value="">Select a courier</option>
                <option value="fedex">FedEx</option>
                <option value="dhl">DHL</option>
                <option value="ups">UPS</option>
              </Select>
            </div>
          </div>
        </>
      )}

      <div className="flex space-x-4 mt-4">
        {action === "delete" ? (
          <CharlesButton onClick={deletePackage} danger={true}>Delete</CharlesButton>
        ) : (
          <CharlesButton onClick={handleSubmit}>{pkg ? 'Update' : 'Create'}</CharlesButton>
        )}
        <CharlesButton onClick={hide} className=" text-gray-500 hover:text-gray-800">Cancel</CharlesButton>
      </div>
    </div>
  );
};

const PackageTracking = () => {
  const { present, hide } = useModals();

  return (
    <Page
      title={"Package Tracking"}
      leftButtons={
        <CharlesButton
          data-testid="create-package-btn"
          onClick={() =>
            present({
              title: "Create Package",
              center: true,
              children: <PackageForm hide={hide} action={"create"} />,
              isBeingPresented: true,
            })
          }
        >
          + Create Package
        </CharlesButton>
      }
    >
      <div className="p-6">
        <div data-testid="package-list" className="card">
          <PackageList />
        </div>

        <div data-testid="package-status-note" className="mt-6 opacity-70 text-xs space-y-2">
          <p>A package will go through the following statuses:</p>
          <PackageStatusChain status={"DELIVERED"} />
          <div className="space-y-1">
            <div className="flex">
              <p className="w-20">Shipped: </p>
              <p>Now the package is on the way to the destination. You can add, edit, delete the sample information.</p>
            </div>
            <div className="flex">
              <p className="w-20">Delivered: </p>
              <p>Now the package has been delivered. WIS will send you a notification and you cannot change anything now.</p>
            </div>
          </div>
          <div className="font-bold">
            This highlight <span className="bg-yellow-200 bg-opacity-50 rounded p-1">#</span> means the package is related to you!
          </div>
        </div>
      </div>
    </Page>

  );
};

export default PackageTracking;