import React from 'react';
import { useMutation, gql } from '@apollo/client';
import { formatDate } from 'react-day-picker/moment';
import { FileSelector } from 'components/Form';
import { Alert } from 'components/Toast';
import File from 'components/File';

const ADD_QUOTATION_FILES = gql`
   mutation ADD_QUOTATION_FILES($factoryId: ID!, $files: [Upload]!) {
      addQuotationFiles(factoryId: $factoryId, files: $files) {
         factory {
            id
            quotationFiles {
               id
               name
               src
               createdAt
            }
         }
      }
   }
`;

const QuotationFiles = ({ factory }) => {
   const [addQuotationFiles, addQuotationFilesRes] = useMutation(
      ADD_QUOTATION_FILES,
      {
         onCompleted: () => Alert('success', 'Uploaded.'),
         onError: (error) => Alert('error', error.message),
      }
   );

   return (
      <div className=" dark:bg-gray-800">
         <div>
            <FileSelector
               disabled={addQuotationFilesRes.loading}
               loading={addQuotationFilesRes.loading}
               title="+ Quotation Files"
               onChange={(e) => {
                  const files = [...e.target.files];
                  addQuotationFiles({
                     variables: {
                        factoryId: factory.id,
                        files,
                     },
                  });
                  e.target.value = null;
               }}
            />
         </div>
         <div className="mt-4">
            {factory.quotationFiles.map((f) => (
               <div
                  key={f.id}
                  className="flex justify-between items-center border-b dark:border-gray-700"
               >
                  <File link={f.src} name={f.name} />
                  <div className="text-xs text-gray-500">
                     {formatDate(f.createdAt, 'YYYY-MM-DD')}
                  </div>
               </div>
            ))}
         </div>
      </div>
   );
};

export default QuotationFiles;
