import { gql, useQuery } from '@apollo/client';
import Errors from 'components/Errors';
import Spinner from 'components/Spinner';
import {
   ResponsiveContainer,
   LineChart,
   Line,
   XAxis,
   YAxis,
   Tooltip,
} from 'recharts';
import { randomColors } from 'utils/colors';

const FETCH_EXCHANGE_RATES = gql`
   query FETCH_EXCHANGE_RATES {
      allExchangeRates {
         id
         date
         rmb: rate
         eur: rateEur
      }
   }
`;

const ExchangeRates = () => {
   const { loading, error, data } = useQuery(FETCH_EXCHANGE_RATES);

   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;

   const chartData = data.allExchangeRates;

   return (
      <div className="p-6">
         <div className="bg-white dark:bg-gray-800 rounded-3xl shadow-sm px-8 py-6 relative">
            <h5>1 USD</h5>
            <div className="mt-4">
               <ResponsiveContainer
                  width="100%"
                  aspect={16 / 9}
                  maxHeight={600}
               >
                  <LineChart data={chartData} margin={{ left: 0, right: 50 }}>
                     <XAxis dataKey="date" />
                     <YAxis />
                     <Tooltip />
                     <Line
                        type="monotone"
                        dataKey="rmb"
                        stroke={randomColors(1)[0]}
                        dot={false}
                     />
                     <Line
                        type="monotone"
                        dataKey="eur"
                        stroke={randomColors(1)[0]}
                        dot={false}
                     />
                  </LineChart>
               </ResponsiveContainer>
            </div>
         </div>
      </div>
   );
};

export default ExchangeRates;
