import { gql } from "@apollo/client";

export const FETCH_SUPPLIER_SCORES = gql`
   query FETCH_SUPPLIER_SCORES($startDate: String!, $endDate: String!) {
      supplierScores(startDate: $startDate, endDate: $endDate) {
         name
         scores {
            quality
            shipmentErrors
            leadTime
            esg
            communications
         }
      }
   }
`;

export const FETCH_SUPPLIER_INDICATOR_INFOS = gql`
   query FETCH_SUPPLIER_INDICATOR_INFOS($indicator: String!, $startDate: String!, $endDate: String!) {
      supplierIndicatorInfos(indicator: $indicator, startDate: $startDate, endDate: $endDate) {
         supplierName
         supplierId
         assessmentId
         score
         rate
         cnt
         totalCnt
      }
   }
`;

export const FETCH_LATEST_SURVEY = gql`
   query FETCH_LATEST_SURVEY {
      latestSurvey {
         id
         title
         suppliers {
            id
            name
         }
         questions {
            id
            text
            answerHelp
         }
      }
   }
`;

export const FETCH_USER_ANSWERS = gql`
   query FETCH_USER_ANSWERS($surveyId: Int!, $userId: Int!) {
      userAnswers(surveyId: $surveyId, userId: $userId) {
         id
         supplier {
            id
         }
         question {
            id
         }
         scoreValue
      }
   }
`;

export const CREATE_OR_UPDATE_ANSWERS = gql`
   mutation CREATE_OR_UPDATE_ANSWERS($answersData: [AnswerInput!]!) {
      createOrUpdateAnswers(answersData: $answersData) {
         answers {
            id
         }
      }
   }
`;

export const DELETE_ANSWERS = gql`
    mutation DELETE_ANSWERS($surveyId: Int!, $supplierId: Int!, $userId: Int!) {
      deleteAnswers(surveyId: $surveyId, supplierId: $supplierId, userId: $userId) {
        success
      }
    }
`;

export const FETCH_COMMUNICATION_INFOS_BY_SUPPLIER = gql`
   query FETCH_COMMUNICATION_INFOS_BY_SUPPLIER($supplierId: Int!, $startDate: String!, $endDate: String!) {
      communicationInfosBySupplier(supplierId: $supplierId, startDate: $startDate, endDate: $endDate) {
         surveys {
            id
            title
         }
         qas {
            questionText
            answerScore
            totalScore
         }
          respondents {
            surveyId
            users
          }
      }
   }
`;

export const FETCH_SHIPMENTERROR_INFOS_BY_SUPPLIER = gql`
   query FETCH_SHIPMENTERROR_INFOS_BY_SUPPLIER($supplierId: Int!, $startDate: String!, $endDate: String!) {
      shipmentErrorInfosBySupplier(supplierId: $supplierId, startDate: $startDate, endDate: $endDate) {
         shipmentError {
            shipmentStatus{
              shipment {
                id
                po
                invoiceNumber
                customer{
                  name
                }
              }
            }
            error {
              errorCategory {
                code
              }
              code
              description
            }
         }
      }
   }
`;

export const FETCH_SUPPLIER_INDICATOR_CHNAGE_HISTORY = gql`
   query FETCH_SUPPLIER_INDICATOR_CHNAGE_HISTORY($indicator: String!, $supplierId: Int!) {
    supplierIndicatorChangeHistory(indicator: $indicator, supplierId: $supplierId) {
        date
        score
        rate
      }
   }
`;

export const FETCH_SUPPLIER_QUALITY_PRODUCT_DETAILS = gql`
  query FETCH_SUPPLIER_QUALITY_PRODUCT_DETAILS($supplierId: Int!) {
      supplierQualityProductDetails(supplierId: $supplierId) {
        id
        name
        defect {
          date
          rate
        }
      }
  }
`;