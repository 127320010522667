import React from "react";
import { useQuery } from "@apollo/client";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import Image from "components/Image";
import { FETCH_LOGOS } from "./graphql";
import { useModals } from "ModalProvider";
import SyncLogosView from "./SyncLogosView";

function LogosGridView({ collectionName }) {
  const { loading, error, data } = useQuery(FETCH_LOGOS, {
    variables: { collectionName },
  });

  const modal = useModals();

  function showSync() {
    modal.present({
      title: "Sync Logos from One Drive",
      children: <SyncLogosView hide={modal.hide} />,
      center: true,
      maxWidth: "max-w-3xl",
    });
  }

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  return (
    <div className="flex px-6 text-center gap-6 flex-wrap">
      {data.logos.map((i, index) => (
        <div key={index}>
          <div>
            <Image size="w-48 h-48" src={i.url} />
            <div className="pt-2 text-gray-500 text-xs break-all text-center">{i.name}</div>
          </div>
          <a target="_blank" download href={i.originFile} rel="noreferrer">
            Download
          </a>
        </div>
      ))}
    </div>
  );
}

export default LogosGridView;
