import { gql } from '@apollo/client';

export const FRAGMENT_PRODUCT_PRICE = gql`
   fragment productPrice on ProductPriceType {
      id
      factory {
         id
         name
         nickName
         costType
         odooId
      }
      minQty
      price
      currency
      supplierUpdatedAt
      archivedAt
      archivedNotes
      archivedCode
      updatedAt
      avaliableFromMonth
      avaliableToMonth
   }
`;

export const FRAGMENT_PRODUCT_FOR_COST = gql`
   fragment productForCost on ProductType {
      id
      odooId
      odooTemplateId
      number
      name
      isActive
      moq
      quantityPerCarton
      outerCartonCbm
      defaultQty
      itemsPerSet
      defaultSupplier {
         id
         name
         nickName
      }
      prices {
         ...productPrice
      }
      archivedAt
      children {
         id
         product {
            id
            odooId
            name
            number
            isActive
            moq
            defaultQty
            itemsPerSet
            defaultSupplier {
               id
               name
               nickName
            }
            prices {
               ...productPrice
            }
            archivedAt
         }
         qty
      }
   }
   ${FRAGMENT_PRODUCT_PRICE}
`;

export const FRAGMMENT_COMPONENT = gql`
   fragment component on ComponentType {
      id
      name
      number
      itemShape
      itemBallSize
      itemCubeSizeX
      itemCubeSizeY
      itemCubeSizeZ
      itemFlatSizeX
      itemFlatSizeY
      description
      productLines {
         id
         name
         number
         mainImage
      }
      defaultSupplier {
         id
      }
      prices {
         id
         price
         minQty
         currency
         avaliableFromMonth
         avaliableToMonth
         factory {
            id
            nickName
            name
            costType
         }
         ordering
         supplierUpdatedAt
         updatedAt
         archivedAt
      }
      priceCheckedAt
   }
`;
