import { gql, useQuery } from "@apollo/client";
import { parseError } from "apollo";
import Errors from "components/Errors";
import Spinner from "components/Spinner";
import CustomFormGenerator from "./CustomFormGenerator";

const FETCH_SHIPMENT_FOR_CUSTOM_FORM = gql`
  query FETCH_SHIPMENT_FOR_CUSTOM_FORM($id: ID!) {
    shipment(id: $id) {
      id
      invoiceNumber
      customer {
        name
      }
      consigneeName
      addr
      shipmentInvoices {
        id
        invoice {
          number
        }
        number
        po
        shipmentInvoiceRows {
          id
          hsCode
          product {
            id
            number
            name
            finalCustomsName
            finalCustomsBrand
            finalCustomsUsage
            finalCustomsValue
            finalCustomsSize
            finalCustomsMaterials
            images: computedImages {
              id
              url(size: "300x300")
            }
          }
          packingName
          qty
          newProductValue
          totalCarton
          totalNetWeight
          totalGrossWeight
          totalCbm
        }
      }
      notesForCustomform
    }
  }
`;

const CustomFormView = ({ shipmentId }) => {
  const { loading, error, data } = useQuery(FETCH_SHIPMENT_FOR_CUSTOM_FORM, {
    variables: { id: shipmentId },
    fetchPolicy: "network-only",
  });

  if (loading) return <Spinner />;
  if (error) return <Errors error={parseError(error)} />;

  return <CustomFormGenerator shipment={data.shipment} />;
};

export default CustomFormView;
