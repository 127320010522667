import { useState } from "react";
import { Alert } from "components/Toast";
import { download } from "utils/http";
import CharlesButton from "components/charles/base";
import { BsArrowDownCircle } from "react-icons/bs";
import useDocumentGenerator from "hooks/useDocumentGenerator";
import { Text } from "components/Form";

const CustomFormGenerator = ({ shipment }) => {
  const [selectedRowIds, setSelectedRowIds] = useState(shipment.shipmentInvoices.flatMap((i) => i.shipmentInvoiceRows).map((i) => i.id));
  const [notesForCustomform, setNotesForCustomform] = useState(shipment.notesForCustomform);
  const [exporting, setExporting] = useState(false);
  const [generateDocument] = useDocumentGenerator();

  const selectedRows = shipment.shipmentInvoices.flatMap((i) => i.shipmentInvoiceRows).filter((i) => selectedRowIds.includes(i.id));
  const totalQty = selectedRows.reduce((acc, row) => acc + row.qty, 0);
  const totalCarton = selectedRows.reduce((acc, row) => acc + row.totalCarton, 0);
  const totalNetWeight = selectedRows.reduce((acc, row) => acc + row.totalNetWeight, 0).toFixed(2);
  const totalGrossWeight = selectedRows.reduce((acc, row) => acc + row.totalGrossWeight, 0).toFixed(2);
  const totalCbm = selectedRows.reduce((acc, row) => acc + row.totalCbm, 0).toFixed(3);
  const totalValue = selectedRows.reduce((acc, row) => acc + row.product.finalCustomsValue * row.qty, 0).toFixed(2);

  function onClickHandler(fileType = "pdf") {
    setExporting(fileType);
    const name = `Customform-SO${shipment.invoiceNumber}`;
    const shipmentInvoices = shipment.shipmentInvoices
      .map((si) => ({
        ...si,
        shipmentInvoiceRows: si.shipmentInvoiceRows.filter((row) => selectedRowIds.includes(row.id)),
      }))
      .filter((si) => si.shipmentInvoiceRows.length > 0);

    generateDocument({
      variables: {
        name,
        docType: "customform",
        data: JSON.stringify(
          {
            shipment,
            shipmentInvoices,
            totalQty,
            totalCarton,
            totalNetWeight,
            totalGrossWeight,
            totalCbm,
            totalValue,
            notesForCustomform,
          },
          "",
          4
        ),
      },
    })
      .then(async (res) => {
        await download(
          `${process.env.REACT_APP_SERVER_BASE_URL}generated-document/${res.data.generateDocument.document.id}/?${fileType}=1`,
          `${name}.${fileType}`
        );
        setExporting(null);
      })
      .catch((err) => {
        Alert("error", String(err));
        setExporting(null);
      });
  }

  return (
    <div className="p-10">
      <div className="opacity-70">Select Products to Generate Custom Form.</div>
      <div className="mt-4 space-y-6 text-sm card">
        <table>
          <thead>
            <tr className="whitespace-nowrap">
              <th>
                <div className="flex items-center justify-center">
                  <input
                    className="cursor-pointer"
                    type="checkbox"
                    checked={selectedRowIds.length === shipment.shipmentInvoices.flatMap((i) => i.shipmentInvoiceRows).length}
                    onChange={() =>
                      setSelectedRowIds((prev) =>
                        prev.length === shipment.shipmentInvoices.flatMap((i) => i.shipmentInvoiceRows).length
                          ? []
                          : shipment.shipmentInvoices.flatMap((i) => i.shipmentInvoiceRows).map((i) => i.id)
                      )
                    }
                  />
                </div>
              </th>
              <th>货名</th>
              <th>型号</th>
              <th>牌子</th>
              <th>规格</th>
              <th>用途</th>
              <th>材质</th>
              <th>HS Code</th>
              <th className="text-center">产品图片</th>
              <th className="text-right">数量</th>
              <th className="text-right">包装件数</th>
              <th className="text-right">包装类型</th>
              <th className="text-right">净重(KGS)</th>
              <th className="text-right">毛重</th>
              <th className="text-right">体积(CBM)</th>
              <th className="text-right">货值(¥)</th>
            </tr>
          </thead>
          <tbody>
            {shipment.shipmentInvoices.map((shipmentInvoice) =>
              shipmentInvoice.shipmentInvoiceRows.map((row) => (
                <tr
                  key={row.id}
                  className={`space-x-4 cursor-pointer hover:text-blue-600 border-y
                  ${selectedRowIds.includes(row.id) ? "" : "opacity-50"}
                  `}
                  onClick={() => setSelectedRowIds((prev) => (selectedRowIds.includes(row.id) ? prev.filter((i) => i !== row.id) : [...prev, row.id]))}
                >
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedRowIds.includes(row.id)}
                      onChange={() => setSelectedRowIds((prev) => (selectedRowIds.includes(row.id) ? prev.filter((i) => i !== row.id) : [...prev, row.id]))}
                    />
                  </td>
                  <td>
                    <div>
                      <div>{row.packingName}</div>
                      <div>{row.product.finalCustomsName}</div>
                    </div>
                  </td>
                  <td>
                    <div>
                      <div>{row.product.number}</div>
                    </div>
                  </td>
                  <td>{row.product.finalCustomsBrand}</td>
                  <td>{row.product.finalCustomsSize}</td>
                  <td>{row.product.finalCustomsUsage}</td>
                  <td>
                    {row.product.finalCustomsMaterials
                      .split(",")
                      .map((material) => material.trim())
                      .join(", ")}
                  </td>
                  <td>{row.hsCode}</td>
                  <td>
                    <div className="flex items-center flex-wrap justify-center">
                      {row.product.images.length > 0 ? (
                        row.product.images.map((image) => (
                          <img key={image.id} src={image.url} alt={row.product.name} className="w-10 h-10 object-contain rounded-md" />
                        ))
                      ) : (
                        <div className="w-10 h-10 bg-gray-200 rounded-md" />
                      )}
                    </div>
                  </td>
                  <td className="text-right">{row.qty}</td>
                  <td className="text-right">{row.totalCarton}</td>
                  <td className="text-right">纸箱</td>
                  <td className="text-right">{row.totalNetWeight}</td>
                  <td className="text-right">{row.totalGrossWeight}</td>
                  <td className="text-right">{row.totalCbm}</td>
                  <td className="text-right">{row.product.finalCustomsValue}</td>
                </tr>
              ))
            )}
          </tbody>
          <tfoot>
            <tr className="font-semibold">
              <td colSpan="9">TOTAL</td>
              <td className="text-right">{totalQty}</td>
              <td className="text-right">{totalCarton}</td>
              <td></td>
              <td className="text-right">{totalNetWeight}</td>
              <td className="text-right">{totalGrossWeight}</td>
              <td className="text-right">{totalCbm}</td>
              <td className="text-right">{totalValue}</td>
            </tr>
          </tfoot>
        </table>

        <hr />

        <div className="">
          <h5>Notes</h5>
          <Text className="mt-4" value={notesForCustomform} onChange={(e) => setNotesForCustomform(e.target.value)} />
        </div>
      </div>

      <div className="mt-6 mx-2 text-base flex justify-end items-start">
        <div className="flex space-x-6">
          <CharlesButton loading={exporting === "xlsx"} onClick={() => onClickHandler("xlsx")} icon={<BsArrowDownCircle />}>
            Download Excel
          </CharlesButton>
          <CharlesButton loading={exporting === "pdf"} onClick={() => onClickHandler("pdf")} icon={<BsArrowDownCircle />}>
            Download PDF
          </CharlesButton>
        </div>
      </div>
    </div>
  );
};

export default CustomFormGenerator;
