import { gql, useQuery } from '@apollo/client';
import { NavigationBar } from 'components/base';
import Errors from 'components/Errors';
import Spinner from 'components/Spinner';
import { useParams } from 'react-router-dom';
import ProductRankings from './ProductRankings';

const FETCH_PRODUCTS = gql`
   query FETCH_PRODUCTS($id: ID!) {
      material(id: $id) {
         id
         name
      }
   }
`;

const MaterialDetail = () => {
   const { id } = useParams();
   const { loading, error, data } = useQuery(FETCH_PRODUCTS, {
      variables: { id },
   });

   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;

   return (
      <div className="flex flex-col overflow-auto">
         <NavigationBar title={data.material.name} backTo="/price/materials" />

         <div className="flex-1 overflow-auto relative">
            <ProductRankings materialId={id} />
         </div>
      </div>
   );
};

export default MaterialDetail;
