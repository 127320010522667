import { useLazyQuery, useMutation } from "@apollo/client";
import { useState } from "react";
import { SAVE_ASSESSMENT_QUESTION, UPDATE_ASSESSMENT_QUESTION } from "./graphql";
import { useDebouncedCallback } from "use-debounce";
import { InlineSpinner } from "components/Spinner";
import { BsTrash, BsUpload } from "react-icons/bs";
import { FETCH_PRESIGNED_URL, uploadFile } from "utils/upload";
import { toast } from "react-toastify";
import CharlesButton from "components/charles/base";
import { FileSelector, Input, Switcher, Text } from "components/Form";
import { SCORE_COLORS } from "./const";
import RectificationsView from "./RectificationsView";
import { Alert } from "components/Toast";
import useAssessmentOperations from "./useAssessmentOperations";

function hasChoices(question) {
  return question.answerType === "SINGLE_CHOICE" || question.answerType === "MULTIPLE_CHOICES";
}

const AssessmentQuestion = ({ assessmentQuestion, number, requiredSupplier }) => {
  const { confirmAssessmentQuestion } = useAssessmentOperations();
  const numberListCount = assessmentQuestion.question.numberListCount;
  const [answer, setAnswer] = useState({
    answerNumbers: assessmentQuestion.answerNumbers.length ? assessmentQuestion.answerNumbers : Array(numberListCount).fill(0),
    answerText: assessmentQuestion.answerText,
    answerChoices: assessmentQuestion.answerChoices,
  });
  const [rectificationNote, setRectificationNote] = useState(assessmentQuestion.rectificationNote);

  const [save, saveRes] = useMutation(SAVE_ASSESSMENT_QUESTION, {
    variables: { id: assessmentQuestion.id, ...answer, rectificationNote },
    onCompleted() {
      Alert("success", "Question updated.");
    },
    onError(error) {
      Alert("error", parseError(error));
    },
  });
  const [update, updateRes] = useMutation(UPDATE_ASSESSMENT_QUESTION, {
    onCompleted() {
      Alert("success", "Auto saved.");
    },
    onError(error) {
      Alert("error", parseError(error));
    },
  });
  const debouncedSave = useDebouncedCallback(save, 1000);
  const debouncedUpdate = useDebouncedCallback(update, 1000);

  const [fetchPresignedUrl] = useLazyQuery(FETCH_PRESIGNED_URL);
  const [isUploading, setIsUploading] = useState(false);

  function handleFileUpload(files) {
    setIsUploading(true);
    let promises = [];
    files.forEach((file) => {
      promises.push(
        new Promise((onCompleted, onError) => {
          fetchPresignedUrl({ variables: { filename: file.name } })
            .then((res) => uploadFile(res.data.qiniuPresignedUrl, file))
            .then(onCompleted)
            .catch(onError);
        })
      );
    });

    Promise.all(promises)
      .then((urls) => {
        save({ variables: { id: assessmentQuestion.id, ...answer, uploadedFiles: [...assessmentQuestion.uploadedFiles, ...urls] } });
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        setIsUploading(false);
      });
  }

  function tryDeleteFile(url) {
    if (window.confirm("Are you sure to delete this file? 你确定要删除这个文件吗?") === false) return;
    const newUploadedFiles = assessmentQuestion.uploadedFiles.filter((u) => u !== url);
    save({ variables: { id: assessmentQuestion.id, ...answer, uploadedFiles: newUploadedFiles } });
  }

  return (
    <div className="relative">
      <div className="text-5xl font-black float-right -mt-2 pl-4">
        <div className={SCORE_COLORS[assessmentQuestion.score]}>{assessmentQuestion.score}</div>
      </div>

      <div className="flex justify-between space-x-4">
        <div>
          <label htmlFor="">{number} Question 问题 :</label> {assessmentQuestion.question.question}
          {assessmentQuestion.question.answerType === "MULTIPLE_CHOICES" ? (
            <span className=" opacity-70">(Multiple Choice)</span>
          ) : assessmentQuestion.question.answerType === "SINGLE_CHOICE" ? (
            <span className=" opacity-70">(Single Choice)</span>
          ) : null}
          {assessmentQuestion.question.standardDescription ? (
            <div className="text-xs opacity-70">
              <label htmlFor="">Standard: </label>
              {assessmentQuestion.question.standardDescription}
            </div>
          ) : null}
          {assessmentQuestion.question.confirmRequired && requiredSupplier ? (
            <div className="text-fuchsia-500">This question requires to confirm during our On-Site audit, you should set score after confirm. </div>
          ) : null}
        </div>
      </div>

      {hasChoices(assessmentQuestion.question) ? (
        <div className="my-2 space-y-2 ">
          {assessmentQuestion.question.choices.map((choice) => (
            <div key={choice.id} className={`flex items-baseline space-x-3`}>
              <div
                className={`w-6 h-6 border dark:border-gray-400 rounded-full flex items-center justify-center flex-shrink-0 text-xs cursor-pointer
                ${
                  answer.answerChoices.includes(choice.choice)
                    ? "bg-blue-500  active:bg-blue-100 text-white active:text-gray-800 dark:bg-blue-600 dark:text-white dark:active:text-gray-400 dark:active:bg-gray-800"
                    : "active:bg-blue-600 hover:bg-blue-500"
                }
              `}
                onClick={() => {
                  let choices = [];
                  if (assessmentQuestion.question.answerType === "SINGLE_CHOICE") {
                    choices = [choice.choice];
                  } else if (assessmentQuestion.question.answerType === "MULTIPLE_CHOICES") {
                    if (answer.answerChoices.includes(choice.choice)) {
                      choices = answer.answerChoices.filter((c) => c !== choice.choice);
                    } else {
                      choices = [...answer.answerChoices, choice.choice];
                    }
                  }
                  setAnswer((prev) => ({ ...prev, answerChoices: choices }));
                  debouncedSave({ variables: { id: assessmentQuestion.id, ...answer, answerChoices: choices } });
                }}
              >
                {choice.choice}
              </div>
              <div className=" group-hover:text-blue-500">{choice.content}</div>
            </div>
          ))}
        </div>
      ) : assessmentQuestion.question.answerType === "TEXT" ? (
        <div className="mt-2">
          <Text
            placeholder="Input here. 请在此输入你的回答。"
            value={answer.answerText || ""}
            onChange={(e) => {
              const answerText = e.target.value;
              setAnswer((prev) => ({ ...prev, answerText }));
              debouncedSave({ variables: { id: assessmentQuestion.id, ...answer, answerText } });
            }}
          />
        </div>
      ) : numberListCount ? (
        <div className="mt-2">
          <div className="flex space-x-3 items-center">
            <label htmlFor="">Answer:</label>
            {answer.answerNumbers.map((i, index) => (
              <div key={index}>
                <Input
                  value={i}
                  onChange={(e) => {
                    const newAnswerNumbers = [...answer.answerNumbers];
                    newAnswerNumbers[index] = e.target.value;
                    setAnswer((prev) => ({ ...prev, answerNumbers: newAnswerNumbers }));
                    debouncedSave({
                      variables: { id: assessmentQuestion.id, ...answer, answerNumbers: newAnswerNumbers },
                    });
                  }}
                  className="text-center w-24"
                />
              </div>
            ))}
          </div>
          {assessmentQuestion.question.numberListCount > 1 ? (
            <div className="text-xs opacity-70 mt-2">Please input the answer in order. 请按顺序输入答案。</div>
          ) : null}
        </div>
      ) : (
        <div className="flex items-baseline mt-1 space-x-2">
          <label htmlFor="">Answer: </label>
          <div>{assessmentQuestion.answer}</div>
        </div>
      )}

      <div className="text-xs font-semibold mt-4 space-y-2">
        {assessmentQuestion.uploadedFiles.length > 0 ? (
          <div className="text-xs space-y-2">
            {assessmentQuestion.uploadedFiles.map((url, index) => (
              <div key={index} className="flex items-center space-x-2">
                <CharlesButton danger onClick={() => tryDeleteFile(url)}>
                  <BsTrash />
                </CharlesButton>
                <a href={url} target="_blank" rel="noreferrer">
                  {url}
                </a>
              </div>
            ))}
          </div>
        ) : null}

        {assessmentQuestion.question.category?.requiredSupplier ? (
          <div className="flex items-center justify-between">
            <div className="xl:flex xl:space-x-6 space-y-3 xl:space-y-0">
              <div className="flex space-x-6 items-center">
                <FileSelector
                  uploading={isUploading}
                  title={
                    <div className="flex items-center space-x-2">
                      <span>
                        <BsUpload />
                      </span>
                      <span>Upload file / image</span>
                    </div>
                  }
                  onChange={(e) => {
                    const files = [...e.target.files];
                    handleFileUpload(files);
                    e.target.value = null;
                  }}
                />

                <CharlesButton onClick={() => confirmAssessmentQuestion(assessmentQuestion)}>
                  {assessmentQuestion.score ? "Change Score" : "Set Score"}
                </CharlesButton>

                <div className="flex items-center space-x-3">
                  <label>Follow up next time: </label>
                  <Switcher
                    isOn={assessmentQuestion.followUpNextTime}
                    onChange={() =>
                      update({
                        variables: {
                          id: assessmentQuestion.id,
                          followUpNextTime: !assessmentQuestion.followUpNextTime,
                        },
                        optimisticResponse: {
                          updateAssessmentQuestion: {
                            assessmentQuestion: {
                              ...assessmentQuestion,
                              followUpNextTime: !assessmentQuestion.followUpNextTime,
                            },
                          },
                        },
                      })
                    }
                  />
                </div>
              </div>

              <div className="flex space-x-6">
                <div className="flex items-center space-x-3">
                  <label>Required Rectification: </label>
                  <Switcher
                    isOn={assessmentQuestion.requiredRectification}
                    onChange={() =>
                      update({
                        variables: {
                          id: assessmentQuestion.id,
                          requiredRectification: !assessmentQuestion.requiredRectification,
                        },
                        optimisticResponse: {
                          updateAssessmentQuestion: {
                            assessmentQuestion: {
                              ...assessmentQuestion,
                              requiredRectification: !assessmentQuestion.requiredRectification,
                            },
                          },
                        },
                      })
                    }
                  />
                </div>

                {assessmentQuestion.requiredRectification ? (
                  <div className="flex items-center space-x-3">
                    <label>Rectification Completed: </label>
                    <Switcher
                      isOn={assessmentQuestion.rectificationCompleted}
                      onChange={() => {
                        update({
                          variables: {
                            id: assessmentQuestion.id,
                            rectificationCompleted: !assessmentQuestion.rectificationCompleted,
                          },
                          optimisticResponse: {
                            updateAssessmentQuestion: {
                              assessmentQuestion: {
                                ...assessmentQuestion,
                                rectificationCompleted: !assessmentQuestion.rectificationCompleted,
                              },
                            },
                          },
                        });
                      }}
                    />
                  </div>
                ) : null}
              </div>
            </div>

            {updateRes.loading || saveRes.loading ? (
              <div className="relative">
                <InlineSpinner text={null} size={21} />
              </div>
            ) : null}
          </div>
        ) : null}

        {assessmentQuestion.requiredRectification ? (
          <div>
            <label htmlFor="">Rectification Note</label>
            <Text
              className="mt-2"
              value={rectificationNote || ""}
              onChange={(e) => {
                setRectificationNote(e.target.value);
                debouncedUpdate({
                  variables: {
                    id: assessmentQuestion.id,
                    rectificationNote: e.target.value,
                  },
                });
              }}
            />
            <div className="font-normal opacity-70">Provide clear rectification feedback for supplier to follow up.</div>
          </div>
        ) : null}
      </div>

      {assessmentQuestion.requiredRectification ? <RectificationsView rectifications={assessmentQuestion.rectifications.edges} /> : null}
    </div>
  );
};

export default AssessmentQuestion;
