import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import Page from "components/Page";
import { NavigationLink } from "components/base";
import InventoryPage from "./Inventory";
import OverviewLoader from "./OverviewLoader";
import SalesForecast from "./salesForecast";

const IncPages = () => (
  <Routes>
    <Route element={<Layout />}>
      <Route path="overview" element={<OverviewLoader />} />
      <Route path="inventory/*" element={<InventoryPage />} />
      <Route path="sales-forecast" element={<SalesForecast />} />
      <Route path="*" element={<Navigate to="overview" />} />
    </Route>
  </Routes>
);

const Layout = () => (
  <Page
    promot={
      <div className="font-semibold text-red-600 rounded-xl px-6 text-sm">
        Note that this page is being worked on, the data might be not accurrated at the moment.
      </div>
    }
    className="h-screen"
    leftButtons={
      <div className="flex space-x-6">
        <NavigationLink to="/inc/overview">Overview</NavigationLink>
        <NavigationLink to="/inc/sales-forecast">Sales Forecast</NavigationLink>
        <NavigationLink to="/inc/inventory">Inventory Report</NavigationLink>
      </div>
    }
  >
    <Outlet />
  </Page>
);

export default IncPages;
