import http from "utils/http";
import { Alert } from "components/Toast";
import { Input } from "components/Form";
import { useState } from "react";
import { Button } from "components/base";
import { BsArrowDownCircle } from "react-icons/bs";
import WabobaLogo from "assets/waboba-logo.svg";
import { useSearchParams } from "react-router-dom";

const BarcodePage = () => {
  const [searchParams, setSearchParams] = useSearchParams({ code: "" });
  const code = searchParams.get("code");
  const [loading, setLoading] = useState(false);

  // ['code128', 'code39', 'ean', 'ean13', 'ean14', 'ean8', 'gs1', 'gs1_128', 'gtin', 'isbn', 'isbn10', 'isbn13', 'issn', 'itf', 'jan', 'pzn', 'upc', 'upca']

  let barcodeType = "unknown";
  if (code.length === 12) {
    barcodeType = "upc";
  } else if (code.length === 13) {
    barcodeType = "ean13";
  } else if (code.length === 14) {
    barcodeType = "itf";
  }

  async function download() {
    if (barcodeType === "unknown") return Alert("error", "Invalid barcode number.");

    setLoading(true);
    try {
      const res = await http.get(`${process.env.REACT_APP_SERVER_BASE_URL}download/barcode/${barcodeType}/${code}`, {
        responseType: "blob",
        withCredentials: true,
      });
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${code}_${barcodeType}.pdf`);
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      const errorMessage = await e.response.data.text();
      Alert("error", errorMessage);
    }

    setLoading(false);
  }

  return (
    <div
      className="bg-no-repeat bg-cover bg-center h-screen flex flex-col"
      style={{
        backgroundImage: "url(https://cdn.waboba.com/assets/promo_images/c9fa141ca82045b2bbfe1b995bb6ad20-Jeff_Nass_Productions_WaterBalls_Web_Res-36.jpg)",
      }}
    >
      <div className=" z-0 absolute inset-0 dark:bg-gray-900 dark:bg-opacity-60"></div>
      <div className="p-10 flex-1 flex justify-center items-center">
        <div className="pt-10 pb-2 px-16 w-full max-w-xl rounded-3xl bg-white dark:bg-gray-800 bg-opacity-80 dark:bg-opacity-90 backdrop-blur">
          <div>
            <div className="flex justify-center">
              <img src={WabobaLogo} alt="Waboba" width={150} />
            </div>

            <h2 className="text-center mt-6">Barcode Generator</h2>
            <div className="mt-8">
              <Input
                className="w-full text-center py-1 tracking-wider text-lg"
                style={{ height: "auto" }}
                placeholder="Input Barcode Number 输入条码数字"
                value={code}
                onChange={(e) => setSearchParams({ code: e.target.value.replace(/ /g, "") })}
              />
            </div>

            <div className="text-center mt-3">
              Type: <span className="font-semibold uppercase">{barcodeType}</span>
            </div>
          </div>

          <div className="mt-6 text-sm">
            <div>使用说明:</div>
            <ul className="list-decimal list-inside">
              <li>确保输入正确的数字后，再生成条码。</li>
              <li>输入12位数字系统会默认生成UPC格式的条码，确保用于美洲客户订单时使用UPC格式条码。</li>
              <li>输入13位数字系统会默认生成EAN格式的条码，确保用于欧洲、亚洲、澳洲以及中东市场客户订单时使用EAN格式条码。</li>
              <li>输入14位数字系统会默认生成ITF格式的条码，确保用于外箱是使用ITF格式条码。</li>
              <li>请务必检查生成出的条码格式和数位是否正确，如因使用人疏忽检查导致条码出错需要承担部分的责任。</li>
            </ul>
          </div>

          <div className="mt-6 flex justify-center">
            <Button
              loading={loading}
              disabled={loading || !code}
              bold
              size="base"
              title="Generate 生成条码"
              onClick={download}
              leftIcon={<BsArrowDownCircle className="mr-2" />}
            />
          </div>

          <div className="mt-6 py-6 border-t border-gray-300 dark:border-gray-700 text-center text-xs">
            <div className="opacity-60">
              <p>
                Waboba offers this application to create uniform barcode designs by generating barcodes in PDF format. To use it, simply enter a barcode number,
                select the preferred barcode type, and then click on the 'Generate' button. Each barcode produced colored in CMYK black (0,0,0,100), making it
                seamlessly integrable into packaging design files.
              </p>
              <p className="mt-2">
                Please note that Waboba has acquired the license to use this specific font. Consequently, these barcodes should be used solely for Waboba
                products.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BarcodePage;
