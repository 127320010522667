import React from 'react';

export const InlineSpinner = ({
   className = '',
   text,
   color = '#00bbd6',
   thickness = 1,
   size = 32,
}) => (
   <div
      className={`${className} flex justify-center flex-col items-center z-30`}
   >
      <div className="lds-ring" style={{ width: size, height: size }}>
         <div
            style={{
               width: size,
               height: size,
               borderWidth: thickness,
               borderColor: `${color} transparent transparent transparent`,
            }}
         />
         <div
            style={{
               width: size,
               height: size,
               borderWidth: thickness,
               borderColor: `${color} transparent transparent transparent`,
            }}
         />
         <div
            style={{
               width: size,
               height: size,
               borderWidth: thickness,
               borderColor: `${color} transparent transparent transparent`,
            }}
         />
         <div
            style={{
               width: size,
               height: size,
               borderWidth: thickness,
               borderColor: `${color} transparent transparent transparent`,
            }}
         />
      </div>
      {text === null ? null : (
         <div className="mt-2 text-gray-600 text-xs">
            {text ? text : 'Loading...'}
         </div>
      )}
   </div>
);

export default ({ className = '', ...rest }) => (
   <div
      className={`absolute inset-0 flex justify-center flex-col items-center z-30 ${className}`}
   >
      <InlineSpinner {...rest} />
   </div>
);
