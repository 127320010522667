import React from 'react';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import Card from '../../../../components/Card';
import Spinner from '../../../../components/Spinner';
import Errors from '../../../../components/Errors';

const FETCH_OHS_USAGE = gql`
   query($start: DateTime!, $end: DateTime!) {
      ohsUsage(start: $start, end: $end) {
         user
         times
         lastVisit
      }
   }
`;

function OhsUsage({ start, end }) {
   const { loading, error, data } = useQuery(FETCH_OHS_USAGE, {
      variables: { start, end },
   });
   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;
   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;
   if (!data) return null;
   const { ohsUsage } = data;
   return (
      <Card title="OHS User Usage">
         <div className="overflow-auto scrolling-touch">
            <table>
               <thead className="bg-gray-200">
                  <tr>
                     <th className="p-2 text-left">User</th>
                     <th className="p-2 text-right">Times</th>
                     <th className="p-2 text-right">Last Visit</th>
                  </tr>
               </thead>
               <tbody>
                  {ohsUsage.map((node, index) => (
                     <tr className="" key={index}>
                        <td className="border-b border-gray-100 p-2">
                           {node.user}
                        </td>
                        <td className="border-b border-gray-100 text-right p-2">
                           {node.times}
                        </td>
                        <td className="border-b border-gray-100 text-right p-2">
                           {new Date(node.lastVisit).toLocaleDateString()}
                        </td>
                     </tr>
                  ))}
               </tbody>
            </table>
         </div>
      </Card>
   );
}

export default OhsUsage;
