import { gql, useQuery } from "@apollo/client";
import CharlesButton from "components/charles/base";
import Errors from "components/Errors";
import { Input } from "components/Form";
import { InlineSpinner } from "components/Spinner";
import { useState } from "react";
import { toInt } from "utils/number";

const FETCH_ODOO_PURCHASE_ORDER = gql`
  query FETCH_ODOO_PURCHASE_ORDER($id: Int!, $supplierId: ID!) {
    odooPurchaseOrder(id: $id)
    supplier: factory(id: $supplierId) {
      id
      producerProducts {
        id
        odooId
      }
    }
  }
`;

const OdooPurchaseOrderDetailView = ({ supplierId, po, onSelect }) => {
  const { loading, error, data } = useQuery(FETCH_ODOO_PURCHASE_ORDER, {
    variables: { id: po.id, supplierId },
  });

  if (loading) return <InlineSpinner className="p-10" />;
  if (error) return <Errors error={error} />;

  return <Form supplier={data.supplier} po={JSON.parse(data.odooPurchaseOrder)} onSelect={onSelect} />;
};

const Form = ({ po, supplier, onSelect }) => {
  const supplierProductIds = supplier.producerProducts.map((i) => i.odooId);
  const supplierLineIds = po.lines.filter((i) => i.product_id && supplierProductIds.includes(i.product_id[0])).map((i) => i.id);

  const [lines, setLines] = useState(
    po.lines.map((i) => ({
      odooPoLineId: i.id,
      name: i.name,
      qty: supplierLineIds.includes(i.id) ? i.product_qty : 0,
      odooQty: i.product_qty,
      unitPrice: 0,
    }))
  );

  const selectedLines = lines.filter((i) => i.qty > 0);

  return (
    <div>
      <div className="-mx-2">
        <table>
          <thead>
            <tr>
              <th>Product in this PO</th>
              <th className="text-right">Qty</th>
              <th className="text-right">Unit Price</th>
            </tr>
          </thead>
          <tbody>
            {lines.map((line) => (
              <tr
                key={line.odooPoLineId}
                className={`border-b dark:border-gray-700
                       ${line.qty > 0 ? "text-green-600 opacity-100 active:text-gray-900" : "opacity-50"}`}
              >
                <td className="font-semibold">
                  <div className="flex space-x-2 items-center">
                    <span>
                      {line.name} &times; {line.odooQty}
                    </span>
                  </div>
                </td>
                <td>
                  <div className="flex justify-end">
                    <Input
                      className="text-right"
                      value={line.qty}
                      onChange={(e) => {
                        const qty = toInt(e.target.value);
                        setLines((prev) => prev.map((prevLine) => (prevLine.odooPoLineId === line.odooPoLineId ? { ...line, qty } : prevLine)));
                      }}
                    />
                  </div>
                </td>
                <td className="text-right">
                  <div className="flex justify-end">
                    <Input
                      className="text-right"
                      value={line.unitPrice}
                      onChange={(e) => {
                        setLines((prev) =>
                          prev.map((prevLine) => (prevLine.odooPoLineId === line.odooPoLineId ? { ...line, unitPrice: e.target.value } : prevLine))
                        );
                      }}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="mt-1 mx-2 text-xs opacity-70 italic">{selectedLines.length} selected.</div>
      </div>

      <div className="mt-8">
        <CharlesButton primary onClick={() => onSelect(selectedLines)}>
          Confirm
        </CharlesButton>
      </div>
    </div>
  );
};

export default OdooPurchaseOrderDetailView;
