import { gql, useQuery } from '@apollo/client';
import XLSX from 'xlsx';
import Spinner from 'components/Spinner';
import Errors from 'components/Errors';
import { Button } from 'components/base';
import { useModals } from 'ModalProvider';
import OdooProductStock from './odoo/OdooProductStock';
import React from 'react';

export const FETCH_ODOO_STOCKS_DETAIL = gql`
   query FETCH_ODOO_STOCKS_DETAIL {
      odooStockForChina {
         id
         virtualAvailable
         virtualAvailableInChina
      }
      odooStocks {
         id
         name
         products {
            id
            name
            odooCode
            odooId
         }
      }
   }
`;

function StockInChina() {
   const { loading, error, data } = useQuery(FETCH_ODOO_STOCKS_DETAIL, {
      fetchPolicy: 'cache-and-network',
   });

   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;

   return <ForecastedStocks data={data} />;
}

function ForecastedStocks({ data }) {
   const productModal = useModals();

   const stocks = data.odooStocks.map((stock) => {
      const products = stock.products.map((product) => {
         const odooProduct = data.odooStockForChina.find(
            (i) => i.id === product.odooId
         );
         const forecastedQty = odooProduct
            ? odooProduct.virtualAvailableInChina
            : 0;
         return { ...product, forecastedQty };
      });
      const hasNegetiveStock =
         products.filter((i) => i.forecastedQty < 0).length > 0;
      return { ...stock, products, hasNegetiveStock };
   });

   const stocksWithNegetive = stocks.filter((i) => i.hasNegetiveStock);

   function exportXlxs() {
      const data = [['Product', 'Available Stock (Odoo Stock Forecasted)']];
      stocks.forEach((stock) => {
         data.push([stock.name]);
         stock.products.forEach((product) => {
            data.push([
               `[${product.odooCode}] ${product.name} (CN)`,
               product.forecastedQty,
            ]);
         });
      });
      const sheet = XLSX.utils.json_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, sheet, 'stock');
      XLSX.writeFile(wb, 'forecasted-stock.xlsx');
   }

   return (
      <div>
         <div className="flex justify-between m-4 lg:m-6 items-end">
            <div className="">
               {stocksWithNegetive.length > 0 ? (
                  <div className="py-3 px-6 bg-red-500 text-white dark:text-gray-300 dark:bg-red-700 rounded-xl overflow-auto font-bold ">
                     {stocksWithNegetive.map((i) => i.name).join(', ')} has
                     negetive stocks.
                  </div>
               ) : null}
            </div>

            <div>
               <Button title="Export" onClick={exportXlxs} />
            </div>
         </div>

         <table className="bg-white dark:bg-gray-800 dark:text-gray-300">
            <thead>
               <tr>
                  <th className="text-left px-4 lg:px-6 py-3 bg-gray-200 dark:bg-gray-700 dark:text-gray-200 font-bold sticky top-0">
                     Product
                  </th>

                  <th className="px-4 lg:px-6 py-3 bg-gray-200 dark:bg-gray-700 dark:text-gray-200 font-normal text-right sticky top-0">
                     Available Stock (Odoo Stock Forecasted)
                  </th>
                  <th className="px-4 lg:px-6 py-3 bg-gray-200 dark:bg-gray-700 dark:text-gray-200 font-normal text-right sticky top-0"></th>
               </tr>
            </thead>
            <tbody>
               {stocks.map((stock, stockIndex) => (
                  <React.Fragment key={stockIndex}>
                     <tr>
                        <td
                           colSpan={3}
                           className="p-4 lg:px-6 text-base font-bold"
                        >
                           {stock.name}
                        </td>
                     </tr>
                     {stock.products.map((product, productIndex) => (
                        <tr key={productIndex} className="text-xs">
                           <td className="px-4 lg:px-6 border-t border-gray-100 dark:border-gray-700 py-3 font-normal">
                              #{product.odooId} [{product.odooCode}]{' '}
                              {product.name} (CN)
                           </td>

                           <td className="text-base px-4 lg:px-6 border-t border-gray-100 dark:border-gray-700 text-right text-orange-600 font-bold">
                              {product.forecastedQty}
                           </td>
                           <td className="px-4 lg:px-6 border-t border-gray-100 dark:border-gray-700 py-3 font-normal">
                              <div className="flex justify-end">
                                 <Button
                                    title="View History"
                                    onClick={() =>
                                       productModal.present({
                                          title: product.name,
                                          maxWidth: 'max-w-7xl',
                                          children: (
                                             <OdooProductStock
                                                id={product.odooId}
                                             />
                                          ),
                                       })
                                    }
                                 />
                              </div>
                           </td>
                        </tr>
                     ))}

                     <tr>
                        <td
                           colSpan={4}
                           className="bg-gray-100 dark:bg-gray-900 py-4"
                        ></td>
                     </tr>
                  </React.Fragment>
               ))}
            </tbody>
         </table>
      </div>
   );
}

export default StockInChina;
