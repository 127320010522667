import { useQuery, gql } from "@apollo/client";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import ReceivedForms from "./ReceivedForms";
import { byName } from "utils/sort";
import { Link } from "react-router-dom";

const FETCH_SUPPLIERS = gql`
  query FETCH_SUPPLIERS {
    suppliers: allFactories(isActive: true) {
      id
      odooId
      name
      stockProductsCount
      productionCategories {
        id
      }
    }
  }
`;

const Suppliers = () => {
  const { loading, error, data } = useQuery(FETCH_SUPPLIERS);

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const suppliers = [...data.suppliers].sort(byName);

  return (
    <div className="p-4 md:p-6 flex space-x-8 items-start justify-between flex-1 overflow-auto">
      <div className="card p-0 flex-1 h-full overflow-auto">
        <table>
          <thead>
            <tr className="sticky top-0 bg-white backdrop-blur bg-opacity-80 dark:bg-gray-800 dark:bg-opacity-70 shadow-sm">
              <th className="pl-8 py-4">Supplier</th>
              <th>Odoo ID</th>
              <th>Production Category</th>
              <th className="pr-8">Stock Items</th>
            </tr>
          </thead>
          <tbody>
            {suppliers.map((i) => (
              <tr key={i.id} className="border-b border-gray-100 dark:border-gray-700">
                <td className="pl-8">
                  <Link to={i.id}>{i.name}</Link>
                </td>
                <td>{i.odooId}</td>
                <td>{i.productionCategories.length || "-"}</td>
                <td className="pr-8">{i.stockProductsCount || "-"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="card p-0 h-full overflow-auto">
        <ReceivedForms />
      </div>
    </div>
  );
};

export default Suppliers;
