import React from "react";
import { useMutation, useQuery } from "@apollo/client";
import gql from "graphql-tag";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import { Button } from "components/base";
import track, { actions } from "utils/track";
import Page from "components/Page";
import CharlesButton from "components/charles/base";
import { Alert } from "components/Toast";
import { parseError } from "apollo";

const FETCH_LIFTSTYLE_IMAGES =
  process.env.NODE_ENV === "development"
    ? gql`
        query FETCH_LIFTSTYLE_IMAGES {
          images: allPromoImages(collectionName: "2024 Lifestyle Images") {
            id
            name
            thumb: url
            url
          }
        }
      `
    : gql`
        query FETCH_LIFTSTYLE_IMAGES {
          images: allPromoImages(collectionName: "2024 Lifestyle Images") {
            id
            name
            thumb: url(size: "300x300")
            url
          }
        }
      `;

const SYNC_LIFESTYLE_IMAGES = gql`
  mutation SYNC_LIFESTYLE_IMAGES($collectionName: String!) {
    syncLifestyleImages(collectionName: $collectionName) {
      result
    }
  }
`;

function LiftstyleImages() {
  const { loading, error, data } = useQuery(FETCH_LIFTSTYLE_IMAGES);
  const [sync, syncRes] = useMutation(SYNC_LIFESTYLE_IMAGES, {
    variables: { collectionName: "2024 Lifestyle Images" },
    onError: (error) => {
      Alert("error", parseError(error));
    },
    onCompleted() {
      Alert("success", "Sync success");
    },
  });

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  return (
    <Page
      title="Liftstyle Images"
      rightButtons={
        <CharlesButton onClick={sync} loading={syncRes.loading}>
          Sync from OneDrive
        </CharlesButton>
      }
    >
      <div>
        <div className="p-6">
          <span className="">
            This page is used to sync images from OneDrive to the database. You manage the images in our{" "}
            <a
              href="https://wabobaadmin.sharepoint.com/sites/Waboba/Shared%20Documents/Forms/AllItems.aspx?FolderCTID=0x012000ED1A44060EECF045B3DF12F1E8DEE39F&id=%2Fsites%2FWaboba%2FShared%20Documents%2Fwis%2Flifestyle&viewid=2ef7e44d%2D79b9%2D437a%2Dbc6f%2D674c8d62a938"
              rel="noreferer"
              target="_blank"
            >
              One Drive Folder
            </a>
            , then click `Sync from OneDrive` to our system.
          </span>
        </div>
        <div className="flex flex-wrap ml-6 content-start">
          {data.images.map((i, index) => (
            <div className="mr-6 mb-6 flex flex-col justify-between items-center flex-1" key={index}>
              <div className="w-48">
                <div className="relative flex justify-center">
                  <img className="h-32" src={i.thumb} alt="〄" />
                </div>
                <div className="pt-2 text-gray-500 text-xs break-all text-center">{i.name}</div>
              </div>
              <div className="flex justify-center">
                <Button
                  link={i.thumb}
                  size="sm"
                  title="small"
                  target="_blank"
                  onClick={() => {
                    track(actions.materials.download.name, {
                      type: "small",
                      file: i.name,
                    });
                  }}
                ></Button>
                <Button
                  link={i.url}
                  className="ml-2"
                  size="sm"
                  title="large"
                  target="_blank"
                  onClick={() => {
                    track(actions.materials.download.name, {
                      type: "large",
                      file: i.name,
                    });
                  }}
                ></Button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Page>
  );
}

export default LiftstyleImages;
