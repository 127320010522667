import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import randomcolor from 'randomcolor';
import {
   ResponsiveContainer,
   BarChart,
   Bar,
   XAxis,
   YAxis,
   Cell,
   Tooltip,
} from 'recharts';
import Card from 'components/Card';
import Spinner from 'components/Spinner';
import Errors from 'components/Errors';
import { BizAnalyticsContext } from '.';

const FETCH_PRODUCT_VIEWS = gql`
   query FETCH_PRODUCT_VIEWS($start: DateTime!, $end: DateTime!) {
      bizProductPageview(start: $start, end: $end) {
         product: name
         times: value
      }
   }
`;

function ProductView() {
   const { start, end } = useContext(BizAnalyticsContext);
   const { loading, error, data } = useQuery(FETCH_PRODUCT_VIEWS, {
      variables: { start, end },
   });

   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;
   if (!data) return null;

   const colors = randomcolor({
      luminosity: 'dark',
      count: data.bizProductPageview.length,
   });

   return (
      <div className="my-6">
         <Card title="Product Views">
            <ResponsiveContainer
               className="mt-6"
               width="100%"
               height={data.bizProductPageview.length * 30}
            >
               <BarChart
                  data={data.bizProductPageview}
                  layout="vertical"
                  barGap={8}
                  barSize={20}
               >
                  <XAxis type="number" />
                  <YAxis type="category" dataKey="product" width={130} />
                  <Tooltip />
                  <Bar dataKey="times">
                     {data.bizProductPageview.map((_, index) => (
                        <Cell key={`cell-${index}`} fill={colors[index]} />
                     ))}
                  </Bar>
               </BarChart>
            </ResponsiveContainer>
         </Card>
      </div>
   );
}

export default ProductView;
