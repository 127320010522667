import React from 'react';
import Image from 'components/Image';
import { Button } from 'components/base';
import track, { actions } from 'utils/track';
import { useModals } from 'ModalProvider';
import CharlesButton from 'components/charles/base';
import AssiciateImagesView from './AssiciateImagesView';

function LifestyleImages({ productLine }) {
   const modal = useModals();

   function associateImages() {
      modal.present({
         title: 'Asscociate Lifestyle Images',
         subtitle: productLine.name,
         fullscreen: true,
         children: <AssiciateImagesView productLine={productLine} />,
      });
   }

   return (
      <div>
         <div className="flex justify-between">
            <h3>Lifestyle Images</h3>
            <CharlesButton onClick={associateImages}>Associate</CharlesButton>
         </div>
         <div className="mt-4 flex overflow-auto scrolling-touch">
            {productLine.promoImages.length > 0 ? (
               productLine.promoImages.map((promoImage) => (
                  <div key={promoImage.id}>
                     <Image size="w-32 h-32" src={promoImage.thumb} />
                     <div className="flex justify-center">
                        <Button
                           link={promoImage.thumb}
                           size="sm"
                           title="small"
                           target="_blank"
                           onClick={() => {
                              track(actions.product.download.name, {
                                 type: 'small',
                                 file: `${promoImage.name}`,
                              });
                           }}
                        ></Button>
                        <Button
                           className="ml-2"
                           link={promoImage.url}
                           size="sm"
                           title="origin"
                           target="_blank"
                           onClick={() => {
                              track(actions.product.download.name, {
                                 type: 'origin',
                                 file: `${promoImage.name}`,
                              });
                           }}
                        ></Button>
                     </div>
                  </div>
               ))
            ) : (
               <div className="flex-1 flex items-center justify-center p-8 text-gray-500 font-bold bg-gray-100">
                  Associated images will be list here.
               </div>
            )}
         </div>
      </div>
   );
}

export default LifestyleImages;
