import { gql, useMutation, useQuery } from '@apollo/client';
import Errors from 'components/Errors';
import { Select } from 'components/Form';
import { InlineSpinner } from 'components/Spinner';
import { byName } from 'utils/sort';

const FETCH_ALL_PRODUCTION_CATEGORIES = gql`
   query FETCH_ALL_PRODUCTION_CATEGORIES {
      allProductionCategories {
         id
         name
         type
         factory {
            id
            name
         }
         timeList {
            id
            qty
            days
         }
      }
   }
`;

const ProductionCategory = ({ value, onChange }) => {
   const { loading, error, data } = useQuery(FETCH_ALL_PRODUCTION_CATEGORIES, {
      fetchPolicy: 'network-only',
   });

   const factories = data
      ? data.allProductionCategories
           .reduce((res, category) => {
              if (res.find((x) => x.id === category.factory.id)) {
                 return res;
              } else {
                 return [...res, category.factory];
              }
           }, [])
           .sort(byName)
      : [];

   return (
      <div className="relative flex items-baseline space-x-4 mb-4">
         <label className="font-bold mr-4 w-24">Category:</label>

         {loading ? (
            <div className="relative">
               <InlineSpinner size={16} text={null} />
            </div>
         ) : error ? (
            <Errors error={error} />
         ) : (
            <div>
               <Select value={value} onChange={(e) => onChange(e.target.value)}>
                  <option value="0">-- Select a Production Category --</option>

                  {factories.map((factory) => (
                     <optgroup key={factory.id} label={factory.name}>
                        {data.allProductionCategories
                           .filter((i) => i.factory.id === factory.id)
                           .sort(byName)
                           .map((i) => (
                              <option value={i.id} key={i.id}>
                                 {i.factory.name} - {i.name}
                              </option>
                           ))}
                     </optgroup>
                  ))}
               </Select>
               <div className="text-xs opacity-60 mt-1 px-2">
                  Set a production category if this product could be used as a
                  component to compute the production leadtime.
               </div>
            </div>
         )}
      </div>
   );
};

export default ProductionCategory;
