import { gql } from "@apollo/client";

export const FETCH_PETE_DATA = gql`
  query FETCH_PETE_DATA {
    incPeteData
  }
`;

export const INC_PRODUCT_CATEGORIES = gql`
  query INC_PRODUCT_CATEGORIES {
    incProductCategories {
      id
      name
      year
    }
  }
`;

export const FETCH_COMPARE_SALES_FORECAST = gql`
  query FETCH_COMPARE_SALES_FORECAST($groupBy: String!, $forecast: Boolean) {
    incCompareSalesAndForecast(groupBy: $groupBy, forecast: $forecast)
  }
`;

export const INC_INV_OVERVIEW = gql`
  query INC_INV_OVERVIEW($categoryId: ID) {
    incInventoryOverview(categoryId: $categoryId)
  }
`;

export const INC_INVENTORY_ITEMS = gql`
  query INC_INVENTORY_ITEMS($itemType: String!, $startDate: String!, $endDate: String!) {
    incInventoryItems(itemType: $itemType, startDate: $startDate, endDate: $endDate) {
      id
      product {
        id
        sku
      }
      date
      qty
    }
  }
`;

export const INC_ITEM_SHIPMENTS = gql`
  query INC_ITEM_SHIPMENTS($sku: String!) {
    incItemShipments(sku: $sku)
    incFsiInventories
  }
`;

export const INC_SHIPMENT = gql`
  query INC_SHIPMENT($id: ID!) {
    shipment(id: $id) {
      id
      containerNumber
      loadingPort
      portOfDischarge
      portOfDestination
      shipmentInvoices {
        id
        invoice {
          id
          number
          pos {
            id
            number
          }
        }
        shipmentInvoiceRows {
          id
          product {
            id
            number
            name
          }
          qty
        }
      }
    }
  }
`;

export const INC_SHIPMENTS_ITEMS = gql`
  query INC_SHIPMENTS_ITEMS {
    incShipmentsItems
  }
`;

export const INC_SAVE_ACTUAL_SALES = gql`
  mutation INC_SAVE_ACTUAL_SALES($date: Date!, $salesItems: [IncActualSalesItemInputType]!) {
    incSaveActualSales(date: $date, salesItems: $salesItems) {
      success
    }
  }
`;
