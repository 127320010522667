import { useMutation } from "@apollo/client";
import { UPDATE_ASSESSMENT_STATUS } from "./graphql";
import { Alert } from "components/Toast";
import { parseError } from "apollo";
import CharlesButton from "components/charles/base";

const StartSelfAssessmentView = ({ id, dismiss }) => {
  const [updateAssessmentStatus, updateAssessmentStatusRes] = useMutation(UPDATE_ASSESSMENT_STATUS, {
    variables: {
      id,
      status: "self_assessment",
    },
    onCompleted() {
      Alert("success", "Status updated.");
      dismiss();
    },
    onError(error) {
      Alert("error", parseError(error));
    },
  });

  return (
    <div className="space-y-6">
      <div className="text-sm">
        Once you start self assessment, the system will notify the supplier and the assessment will change to `Self Assessment` status. Suppliers are supposed
        to provide self assessment at this stage.
      </div>

      <hr />

      <div>
        <CharlesButton primary onClick={updateAssessmentStatus} loading={updateAssessmentStatusRes.loading}>
          Start Self Assessment
        </CharlesButton>
      </div>
    </div>
  );
};

export default StartSelfAssessmentView;
