import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { formatDate } from "react-day-picker/moment";
import { Select, Input, DatePicker } from "components/Form";
import { Alert } from "components/Toast";
import FactorySelector from "components/FactorySelector";
import CharlesButton from "components/charles/base";
import { DELETE_PRODUCT_PRICE, SAVE_PRODUCT_PRICE } from "./graphql";

const ProductPriceForm = ({ initialPrice, tryArchive, handleEffectedProducts, inModal }) => {
  const x = { ...initialPrice };
  delete x["__typename"];
  x.factoryId = x.factory ? x.factory.id : "0";
  delete x["factory"];
  const [price, setPrice] = useState(x);

  const [savePrice, savePriceRes] = useMutation(SAVE_PRODUCT_PRICE, {
    onCompleted: (res) => {
      Alert("success", "Cost saved.");
      handleEffectedProducts(res.saveProductPrice.effectedProducts);
    },
    onError: (error) => Alert("error", error.message),
  });

  const [deletePrice, deletePriceRes] = useMutation(DELETE_PRODUCT_PRICE, {
    onCompleted: (res) => {
      Alert("success", "Deleted.");
      handleEffectedProducts(res.saveProductPrice.effectedProducts);
    },
    onError: (error) => Alert("error", error.message),
  });

  function onChange(key, value) {
    setPrice((prev) => ({ ...prev, [key]: value }));
  }

  return (
    <div
      className={`relative
        ${inModal ? "" : "card"}
    `}
    >
      {initialPrice.archivedAt ? (
        <div className="absolute z-10 inset-0 cursor-not-allowed flex justify-end">
          <div className="opacity-100 p-6 flex flex-col items-end font-bold ">
            <div className="text-red-500">Archived at {formatDate(initialPrice.archivedAt, "YYYY-MM-DD hh:mm:ss")}</div>
            <div className="text-gray-600">{initialPrice.archivedNotes}</div>
          </div>
        </div>
      ) : null}

      <div>
        <div className="flex items-center">
          <label className="mr-2">Supplier: </label>
          <FactorySelector value={price.factoryId} onChange={(id) => onChange("factoryId", id)} />
        </div>
        <div className="flex items-center mt-2">
          <div className="flex items-center">
            <label className="mr-2">Min Qty: </label>
            <Input className="text-center" value={price.minQty} onChange={(e) => onChange("minQty", e.target.value)} />
          </div>
          <div className="ml-4 flex items-center">
            <label className="mr-2">Price / Unit: </label>
            <Input className="text-center" value={price.price} onChange={(e) => onChange("price", e.target.value)} />
          </div>
          <div className="ml-4">
            <Select value={price.currency} onChange={(e) => onChange("currency", e.target.value)}>
              <option value="USD">USD</option>
              <option value="RMB">RMB</option>
            </Select>
          </div>
        </div>

        <div className="flex items-center mt-2">
          <label className="mr-2">Effetive Date: </label>
          <DatePicker value={price.supplierUpdatedAt} onDayChange={(date) => onChange("supplierUpdatedAt", formatDate(date, "YYYY-MM-DD"))} />
        </div>

        <div className="flex items-center mt-2">
          <div className="flex items-center">
            <label>Available from month</label>
            <Select className="mx-3" onChange={(e) => onChange("avaliableFromMonth", e.target.value)} value={price.avaliableFromMonth}>
              {[...Array(12).keys()].map((i) => (
                <option value={i + 1} key={i}>
                  {i + 1}
                </option>
              ))}
            </Select>
            <label>to</label>
            <Select className="mx-3" onChange={(e) => onChange("avaliableToMonth", e.target.value)} value={price.avaliableToMonth}>
              {[...Array(12).keys()].map((i) => (
                <option value={i + 1} key={i}>
                  {i + 1}
                </option>
              ))}
            </Select>
          </div>
        </div>
      </div>

      <div className="flex items-center space-x-4 mt-6">
        <CharlesButton onClick={() => savePrice({ variables: price })} loading={savePriceRes.loading}>
          Save
        </CharlesButton>

        {initialPrice.id ? (
          <>
            <CharlesButton danger onClick={tryArchive}>
              Archive
            </CharlesButton>
            <CharlesButton
              danger
              loading={deletePriceRes.loading}
              onClick={() => {
                if (window.confirm(`Are you sure to delete this price?`)) deletePrice({ variables: { id: initialPrice.id } });
              }}
            >
              Delete
            </CharlesButton>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default ProductPriceForm;
