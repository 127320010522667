import React, { useContext, useState } from "react";
import { useMutation, gql } from "@apollo/client";
import QiniuUploader from "components/QiniuUploader";
import { FileSelector } from "components/Form";
import Icon from "components/Icon";
import { Alert } from "components/Toast";
import { QC_REPORT_FRAGMENT } from "../graphql";
import { uuidv4 } from "utils/uuid";
import { QCReportContext } from "./FinalQcReportEditForm";

const CREATE_META_IMAGE = gql`
  mutation CREATE_META_IMAGE($reportId: ID!, $metaId: ID!, $src: String!) {
    createMetaImage(reportId: $reportId, metaId: $metaId, src: $src) {
      metaImage {
        id
        src
        meta {
          id
          name
        }
      }
    }
  }
`;

const DELETE_META_IMAGE = gql`
  mutation DELETE_META_IMAGE($id: ID!) {
    deleteMetaImage(id: $id) {
      qcReport {
        ...qcReport
      }
    }
  }
  ${QC_REPORT_FRAGMENT}
`;

function MetaImage({ metaId, id, src, file, filename, onCreated, onDeleted }) {
  const { report } = useContext(QCReportContext);
  const [createMetaImage] = useMutation(CREATE_META_IMAGE, {
    onCompleted() {
      Alert("success", "Saved.");
    },
    onError: (error) => Alert("error", error.message),
    update(cache, res) {
      const id = `QCReportType:${report.id}`;
      const fragment = QC_REPORT_FRAGMENT;
      const fragmentName = "qcReport";
      const prevReport = cache.readFragment({ fragment, id, fragmentName });
      const data = {
        ...prevReport,
        metaImages: [...prevReport.metaImages, res.data.createMetaImage.metaImage],
      };
      cache.writeFragment({ fragment, id, fragmentName, data });
      onCreated(data);
    },
  });
  const [deleteMetaImage] = useMutation(DELETE_META_IMAGE, {
    variables: { id },
    onCompleted: () => onDeleted(id),
    onError: (error) => Alert("error", error.message),
  });

  if (file)
    return (
      <div className="flex items-center justify-center w-32 h-32 bg-gray-100 mr-4 mt-4 relative">
        <QiniuUploader
          category="qcMetaImages"
          file={file}
          filename={filename}
          onUploaded={(url) => createMetaImage({ variables: { reportId: report.id, metaId, src: url } })}
        />
      </div>
    );

  return (
    <div className="mt-4 lg:mr-4 relative">
      <a href={src} target="_blank" title="Open origin image" rel="noopener noreferrer">
        <img className="w-full lg:w-auto lg:h-32 bg-gray-700" src={`${src}?imageView2/2/w/300`} alt="meta" style={{ minWidth: 100 }} />
      </a>
      <div
        className="absolute z-10 cursor-pointer top-0 p-2 text-red-600 hover:text-red-700 active:text-red-800"
        onClick={(_) => {
          if (window.confirm("确认删除图片?")) deleteMetaImage();
        }}
      >
        <Icon icon="close-outline" />
      </div>
    </div>
  );
}

function MetaForm({ meta }) {
  const { report } = useContext(QCReportContext);
  const [metaImages, setMetaImages] = useState(report.metaImages.filter((i) => i.meta.id == meta.id));

  function onCreated(res) {
    const images = res.metaImages.filter((metaImage) => metaImage.meta.id === meta.id);
    setMetaImages(images);
  }

  function onDeleted(id) {
    setMetaImages((prev) => prev.filter((i) => i.id !== id));
  }

  return (
    <div className="mt-4 border bg-white dark:bg-gray-900 border-gray-200 dark:border-gray-700 p-4 rounded-lg">
      <div>{meta.name}</div>
      <div className="lg:flex lg:flex-wrap">
        {metaImages.map((i, index) => (
          <MetaImage
            metaId={meta.id}
            key={index}
            id={i.id}
            src={i.src}
            index={index}
            file={i.file}
            filename={i.filename}
            onCreated={onCreated}
            onDeleted={onDeleted}
          />
        ))}
      </div>
      <FileSelector
        accept="image/*"
        title="+ images 添加图片"
        className="mt-4"
        onChange={(e) => {
          const files = [...e.target.files];
          setMetaImages((prev) => [
            ...prev,
            ...files.map((file) => ({
              reportId: report.id,
              metaId: meta.id,
              file,
              filename: `${meta.key}/${uuidv4()}-${file.name}`,
              preview: URL.createObjectURL(file),
            })),
          ]);
          e.target.value = null;
        }}
      />
    </div>
  );
}

export default MetaForm;
