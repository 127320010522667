import { useQuery, gql } from '@apollo/client';
import Errors from 'components/Errors';
import Spinner from 'components/Spinner';

const FETCH_GENERATED_DOCUMENTS = gql`
   query FETCH_GENERATED_DOCUMENTS($docType: String) {
      allGeneratedDocuments(docType: $docType) {
         id
         name
         docType
         createdAt
         createdBy {
            id
            email
         }
      }
   }
`;

const GeneratedDocuments = ({ docType, onSelect }) => {
   const { loading, data, error } = useQuery(FETCH_GENERATED_DOCUMENTS, {
      variables: { docType },
      fetchPolicy: 'cache-and-network',
   });

   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;

   return (
      <table>
         <tbody>
            {data.allGeneratedDocuments.map((i) => (
               <tr
                  key={i.id}
                  className=" cursor-pointer hover:text-blue-600 hover:dark:text-blue-700"
                  onClick={() => onSelect(i.id)}
               >
                  <td className="border-b dark:border-gray-700">{i.name}</td>
                  <td className="border-b dark:border-gray-700 text-right">
                     {i.createdBy.email}
                  </td>
                  <td className="border-b dark:border-gray-700 text-right">
                     {new Date(i.createdAt).toLocaleString()}
                  </td>
               </tr>
            ))}
         </tbody>
      </table>
   );
};

export default GeneratedDocuments;
