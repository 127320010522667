import { NavLink, Route, Routes } from 'react-router-dom';
import CostPage from './CostPage';
import Productions from './Productions';
import { FaEnvira, FaFileInvoiceDollar } from 'react-icons/fa';
import { MdBuildCircle } from 'react-icons/md';
import { IoIosCode, IoIosCube } from 'react-icons/io';
import Inventory from './Inventory';
import { useQuery, gql } from '@apollo/client';
import Spinner from 'components/Spinner';
import Errors from 'components/Errors';
import { BsJournalCheck } from 'react-icons/bs';
import {
   IoChatbubbleEllipsesOutline,
   IoDocumentTextOutline,
} from 'react-icons/io5';
import Orders from './Orders';
import QCReports from './QCReports';

const FETCH_MY_SUPPLIER = gql`
   query FETCH_MY_SUPPLIER {
      me {
         supplier {
            name
         }
      }
   }
`;

const SupplierPortal = () => {
   const { loading, error, data } = useQuery(FETCH_MY_SUPPLIER);

   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;

   return (
      <>
         <aside className="border-r dark:border-gray-800 w-56 fixed bottom-0 z-20 flex flex-col h-screen overflow-auto flex-shrink-0">
            <div className="p-6 sticky top-0">
               <h2 className="">{data.me.supplier.name}</h2>
               <div className="italic">Waboba Supplier Portal</div>
            </div>

            <div className="px-2 space-y-1">
               <NavLink
                  className={({ isActive }) =>
                     'p-4 py-1 rounded-lg flex items-center space-x-2 ' +
                     (isActive
                        ? 'bg-gray-200 dark:bg-gray-800'
                        : 'text-gray-700 dark:text-gray-300') +
                     ' '
                  }
                  to="/supplier/cost"
               >
                  <FaFileInvoiceDollar className="text-blue-600" />
                  <span>报价</span>
               </NavLink>

               <NavLink
                  className={({ isActive }) =>
                     'p-4 py-1 rounded-lg flex items-center space-x-2 ' +
                     (isActive
                        ? 'bg-gray-200 dark:bg-gray-800'
                        : 'text-gray-700 dark:text-gray-300') +
                     ' '
                  }
                  to="/supplier/orders"
               >
                  <IoDocumentTextOutline className="text-blue-600" />
                  <span>订单</span>
               </NavLink>

               <NavLink
                  className={({ isActive }) =>
                     'p-4 py-1 rounded-lg flex items-center space-x-2 ' +
                     (isActive
                        ? 'bg-gray-200 dark:bg-gray-800'
                        : 'text-gray-700 dark:text-gray-300') +
                     ' '
                  }
                  to="/supplier/production"
               >
                  <MdBuildCircle className="text-blue-600" />
                  <span>生产</span>
               </NavLink>

               <NavLink
                  className={({ isActive }) =>
                     'p-4 py-1 rounded-lg flex items-center space-x-2 ' +
                     (isActive
                        ? 'bg-gray-200 dark:bg-gray-800'
                        : 'text-gray-700 dark:text-gray-300') +
                     ' '
                  }
                  to="/supplier/inventory"
               >
                  <IoIosCube className="text-blue-600" />
                  <span>库存</span>
               </NavLink>

               <NavLink
                  className={({ isActive }) =>
                     'p-4 py-1 rounded-lg flex items-center space-x-2 ' +
                     (isActive
                        ? 'bg-gray-200 dark:bg-gray-800'
                        : 'text-gray-700 dark:text-gray-300') +
                     ' '
                  }
                  to="/supplier/qc"
               >
                  <BsJournalCheck className="text-blue-600" />
                  <span>质检</span>
               </NavLink>

               <NavLink
                  className={({ isActive }) =>
                     'p-4 py-1 rounded-lg flex items-center space-x-2 ' +
                     (isActive
                        ? 'bg-gray-200 dark:bg-gray-800'
                        : 'text-gray-700 dark:text-gray-300') +
                     ' '
                  }
                  to="/supplier/csr"
               >
                  <FaEnvira className="text-blue-600" />
                  <span>CSR</span>
               </NavLink>

               <NavLink
                  className={({ isActive }) =>
                     'p-4 py-1 rounded-lg flex items-center space-x-2 ' +
                     (isActive
                        ? 'bg-gray-200'
                        : 'text-gray-700 dark:text-gray-300') +
                     ' '
                  }
                  to="/supplier/api"
               >
                  <IoIosCode className="text-blue-600" />
                  <span>API</span>
               </NavLink>
               <NavLink
                  className={({ isActive }) =>
                     'p-4 py-1 rounded-lg flex items-center space-x-2 ' +
                     (isActive
                        ? 'bg-gray-200'
                        : 'text-gray-700 dark:text-gray-300') +
                     ' '
                  }
                  to="/supplier/feedback"
               >
                  <IoChatbubbleEllipsesOutline className="text-blue-600" />
                  <span>Feedback</span>
               </NavLink>
            </div>
         </aside>

         <div className="ml-56 bg-white dark:bg-gray-900 min-h-screen flex-1 relative">
            <Routes>
               <Route path="cost" element={<CostPage />} />
               <Route path="production" element={<Productions />} />
               <Route path="inventory" element={<Inventory />} />
               <Route path="orders" element={<Orders />} />
               <Route path="qc" element={<QCReports />} />
               <Route path="inventory" element={<Inventory />} />
               <Route path="inventory" element={<Inventory />} />
            </Routes>
         </div>
      </>
   );
};

export default SupplierPortal;
