import { gql } from "@apollo/client";

export const FRAGMENT_PRODUCT_SIMPLE_FIELDS = gql`
  fragment productSimpleFields on ProductType {
    odooTemplateId
    odooId
    odooCategoryId
    productType
    name
    packingName
    number
    description
    defaultQty
    itemsPerSet
    shape
    ballDiameter
    cubeX
    cubeZ
    cubeY
    cylindricalDiameter
    cylindricalHeight
    flatX
    flatY
    multiSizes
    packing
    shippingMarkName
    colors
    useProductLineImagesIfNoImages
    innerBoxBarcodeEan
    innerBoxBarcodeUpc
    outerCartonBarcodeEan
    outerCartonBarcodeUpc
    innerCartonBarcodeEan
    innerCartonBarcodeUpc
    itemWeight
    grossWeight
    quantityPerDisplayBox
    quantityPerCarton
    innerBoxX
    innerBoxZ
    innerBoxY
    displayBoxX
    displayBoxZ
    displayBoxY
    innerCartonX
    innerCartonZ
    innerCartonY
    outerCartonX
    outerCartonZ
    outerCartonY
    ctnNetWeight
    ctnGrossWeight
    outerCartonCbm
    archivedAt
    isCoreProduct
    isActive
    isActiveForBiz
    isLaborCost
    isSalable
    isPurchasable
    endProductionAt
    careProduction
    moq
    reminderForMissingBeautyShot
    note
  }
`;

export const FRAGMENT_PRODUCT_DETAIL = gql`
  fragment productDetail on ProductType {
    id
    ...productSimpleFields
    images: computedImages {
      id
      url(size: "300x300")
    }
    defaultSupplier {
      id
      name
    }
    producer {
      id
      name
    }
    deliverToSupplier {
      id
      name
    }
    preferedSupplier {
      id
      name
    }
    productLine {
      id
    }
    productList {
      id
    }
    prices {
      id
      factory {
        id
        odooId
        name
      }
      minQty
      currency
      price
      supplierUpdatedAt
      avaliableFromMonth
      avaliableToMonth
      archivedAt
      archivedCode
      archivedNotes
    }
    children {
      id
      product {
        id
        name
        number
        odooId
        prices {
          id
          factory {
            id
            odooId
            name
          }
          minQty
          currency
          price
          avaliableFromMonth
          avaliableToMonth
          supplierUpdatedAt
          archivedAt
          archivedCode
          archivedNotes
        }
      }
      qty
    }
    production {
      id
    }
    productionCategory {
      id
    }
    areas {
      id
    }
    excludedCustomers {
      id
      name
    }
    warnings {
      id
    }
    labTestStandards {
      id
    }
    pantone
  }
  ${FRAGMENT_PRODUCT_SIMPLE_FIELDS}
`;

export const FRAGMENT_PRODUCT_LINE_DETAIL = gql`
  fragment productLine on ProductLineType {
    id
    name
    number
    nameForOdooTemplate
    hsCode
    hsCodeForEu
    hsCodeForUs
    age
    pantone
    printingMethod
    materials {
      id
      material {
        id
        name
      }
      percentage
    }
    warnings {
      id
      name
    }
    labTestStandards {
      id
      name
    }
    children {
      id
      name
    }
    anyCollections {
      id
      name
    }
    customsName
    customsBrand
    customsUsage
    customsValue
    customsSize
    customsMaterials
    activeForWis
    activeForBiz
    fromProductList {
      id
    }
  }
`;

export const SAVE_PRODUCT = gql`
  mutation SAVE_PRODUCT(
    $id: ID
    $simpleFields: ProductSimpleFields
    $areaIds: [ID]
    $excludedCustomerIds: [ID]
    $prices: [ProductPriceInputType]
    $children: [InputProductChildType]
    $oneDriveImageIds: [String]
    $productLineId: ID
    $productionId: ID
    $defaultSupplierId: ID
    $productionCategoryId: ID
    $warningIds: [ID]
    $labTestStandardIds: [ID]
    $pantone: String
    $productListId: ID
    $sendNotification: Boolean
  ) {
    saveProduct(
      id: $id
      simpleFields: $simpleFields
      areaIds: $areaIds
      excludedCustomerIds: $excludedCustomerIds
      prices: $prices
      oneDriveImageIds: $oneDriveImageIds
      children: $children
      productLineId: $productLineId
      productionId: $productionId
      defaultSupplierId: $defaultSupplierId
      productionCategoryId: $productionCategoryId
      warningIds: $warningIds
      labTestStandardIds: $labTestStandardIds
      pantone: $pantone
      productListId: $productListId
      sendNotification: $sendNotification
    ) {
      product {
        ...productDetail
      }
    }
  }
  ${FRAGMENT_PRODUCT_DETAIL}
`;

export const FETCH_PRODUCT_DETAIL = gql`
  query FETCH_PRODUCT_DETAIL($id: ID!) {
    product(id: $id) {
      ...productDetail
    }
  }
  ${FRAGMENT_PRODUCT_DETAIL}
`;
