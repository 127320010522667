import React from 'react';
import moment from 'moment';
import gitlog from 'gitlog';

const DevLogs = () => {
   const logs = gitlog
      .filter((i) => !i.includes('wip') && !i.includes('deploy'))
      .map((i) => {
         const [time, work] = i.split('|');
         return { time, work };
      });

   const groups = logs.reduce((acc, log) => {
      // create a composed key: 'year-week'
      const date = moment(log.time);
      const yearWeek = `${date.year()} week ${date.week()}`;

      // add this key as a property to the result object
      if (!acc[yearWeek]) acc[yearWeek] = [];

      // push the current date that belongs to the year-week calculated befor
      acc[yearWeek].push(log);

      return acc;
   }, {});

   console.log('groups', groups);

   return (
      <div className="p-6">
         {Object.keys(groups).map((key, index) => {
            const yearWeek = key;
            const logs = groups[key];
            return (
               <div
                  key={index}
                  className="p-6 bg-white dark:bg-gray-800 rounded-xl shadow mb-8"
               >
                  <h4 className="dark:text-gray-300">{yearWeek}</h4>
                  {logs.map((log, groupIndex) => {
                     return (
                        <div
                           key={groupIndex}
                           className="py-3 border-b border-gray-200 dark:border-gray-700"
                        >
                           <div className="text-xs text-gray-500 dark:text-gray-500">
                              {new Date(log.time).toLocaleString()}
                           </div>
                           <div className="text-base dark:text-gray-400">
                              {log.work}
                           </div>
                        </div>
                     );
                  })}
               </div>
            );
         })}
      </div>
   );
};

export default DevLogs;
