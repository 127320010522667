import { useQuery } from "@apollo/client";
import Page from "components/Page";
import SearchBar from "components/SearchBar";
import { FETCH_PRODUCTS_NEW_PAGE_DATA } from "./graphql";
import Errors from "components/Errors";
import Spinner from "components/Spinner";
import { parseError } from "apollo";
import ProductLines from "./ProductLines";
import { Select } from "components/Form";
import { useState } from "react";

const ProductsNew = () => {
  const { loading, error, data } = useQuery(FETCH_PRODUCTS_NEW_PAGE_DATA);
  const [groupId, setGroupId] = useState("ALL");

  if (loading) return <Spinner />;
  if (error) return <Errors error={parseError(error)} />;

  return (
    <Page title="Products" className="h-screen flex flex-col">
      <div className="p-6 flex items-center justify-between space-x-6">
        <div className="flex-1">
          <SearchBar placeholder="Search by name or number." />
        </div>
        <div>
          <Select value={groupId} onChange={(e) => setGroupId(e.target.value)}>
            <option value="ALL">All Groups</option>
            {data.groups.map((group) => (
              <option key={group.id} value={group.id}>
                {group.name}
              </option>
            ))}
          </Select>
        </div>
      </div>

      <div className="flex-1 overflow-auto flex">
        <div className="flex-shrink-0 overflow-auto">
          <ProductLines lines={data.productLines.results} />
        </div>
        <div className="flex-1 overflow-auto">products</div>
      </div>
    </Page>
  );
};

export default ProductsNew;
