import { useMutation } from "@apollo/client";
import { ORDER_UPDATE_STATE } from "./graphql";
import { Alert } from "components/Toast";
import { parseError } from "apollo";
import CharlesButton from "components/charles/base";

const UpdateStateView = ({ id, warehouseName, operation, hide }) => {
  const [updateState, updateStateRes] = useMutation(ORDER_UPDATE_STATE, {
    variables: { id, operation },
    onCompleted() {
      Alert("success", "Order state updated");
      hide();
    },
    onError(error) {
      Alert("error", parseError(error));
    },
  });

  return (
    <div>
      <div className=" whitespace-pre-wrap leading-loose">
        {operation === "START_PLANNING" ? (
          <div>
            <p>Are you sure to update the order state to Planning? Once the state is change to Planning, the stock for the {warehouseName} will be reseved.</p>
            <p className=" text-purple-600 font-semibold">WIS will also create an Sales Order in Odoo automatically.</p>
          </div>
        ) : operation === "RESET_TO_DRAFT" ? (
          `Are you sure to reset this order to draft?\nThis will release the stock usage for this plan.`
        ) : null}
      </div>

      <div className="mt-8">
        <CharlesButton primary onClick={updateState} loading={updateStateRes.loading}>
          Confirm
        </CharlesButton>
      </div>
    </div>
  );
};

export default UpdateStateView;
