const IssueImages = ({ issues }) => {
   const images = issues.flatMap((i) =>
      i.images.map((image) => ({ src: image, description: i.description }))
   );

   return (
      <div className="mt-6">
         <h4>Issues:</h4>
         <div className="flex items-end overflow-auto mt-2">
            {images.map((image, index) => (
               <div key={index} className="w-80 flex-shrink-0">
                  <a
                     href={image.src}
                     target="_blank"
                     key={index}
                     rel="noreferrer"
                  >
                     <img
                        src={`${image.src}?imageView2/2/w/300`}
                        alt="Issue Image"
                     />
                  </a>
                  <div className="my-2 text-center">{image.description}</div>
               </div>
            ))}
         </div>
      </div>
   );
};
export default IssueImages;
