import { gql, useMutation, useQuery } from "@apollo/client";
import Errors from "components/Errors";
import { Select } from "components/Form";
import Spinner from "components/Spinner";
import { Alert } from "components/Toast";
import { useState } from "react";

const FETCH_PRODUCTS_FOR_TEST_REPORT_VALIDATION = gql`
  query FETCH_PRODUCTS_FOR_TEST_REPORT_VALIDATION {
    allProductLists {
      id
      name
      version
    }
    products(productType: "normal", isActiveOnly: true, isSalable: true) {
      total
      results {
        id
        name
        number
        odooId
        labTestStandards {
          id
          name
        }
        productList {
          id
        }
      }
    }
  }
`;

const TestReports = () => {
  const { loading, error, data } = useQuery(FETCH_PRODUCTS_FOR_TEST_REPORT_VALIDATION);
  const [filter, setFilter] = useState("NO_PRODUCT_LIST");

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const products = data.products.results.filter((i) => {
    if (filter === "ALL_PRODUCTS") return true;
    if (filter === "NO_PRODUCT_LIST") return !i.productList;
    return true;
  });

  const productsWithoutProductList = products.filter((i) => !i.productList);

  return (
    <div className="m-6">
      <div className="flex justify-between items-center">
        <div>This page shows the products not connecting with a product list. Please select a product list for each product.</div>
        <div className="flex space-x-3 items-center">
          <label htmlFor="">Filter: </label>
          <Select value={filter} onChange={(e) => setFilter(e.target.value)}>
            <option value="ALL_PRODUCTS">All Products({data.products.total})</option>
            <option value="NO_PRODUCT_LIST">No Product List({productsWithoutProductList.length})</option>
          </Select>
        </div>
      </div>

      <div className="card rounded-xl p-0 h-full overflow-auto mt-4">
        <table>
          <thead>
            <tr className="sticky top-0 backdrop-blur bg-gray-50 dark:bg-gray-800 bg-opacity-70 z-10">
              <th className="px-6 py-3">Product({data.products.total})</th>
              <th>Test Reports(variant)</th>
              <th>Product List</th>
            </tr>
          </thead>
          <tbody>
            {products.map((product) => (
              <ProductRow key={product.id} allProductLists={data.allProductLists} product={product} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const SAVE_PRODUCT = gql`
  mutation SAVE_PRODUCT($id: ID!, $productListId: ID) {
    saveProduct(id: $id, productListId: $productListId) {
      product {
        id
        productList {
          id
        }
      }
    }
  }
`;

const ProductRow = ({ allProductLists, product }) => {
  const [saveProduct, saveProductRes] = useMutation(SAVE_PRODUCT, {
    onCompleted() {
      Alert("success", "Product saved.");
    },
    onError(error) {
      Alert("error", error.message);
    },
  });

  return (
    <tr className="border-t border-gray-100 dark:border-gray-700">
      <td className="px-6">
        <a href={`/manage/products?id=${product.id}`} target="_blank">
          [{product.number}] {product.name}
        </a>
      </td>
      <td>
        {product.labTestStandards.map((standard) => (
          <div key={standard.id}>{standard.name}</div>
        ))}
      </td>
      <td>
        <Select
          value={product.productList ? product.productList.id : 0}
          onChange={(e) => {
            const productListId = e.target.value === "0" ? null : e.target.value;
            saveProduct({ variables: { id: product.id, productListId } });
          }}
          disabled={saveProductRes.loading}
        >
          <option value="0">--- Select ---</option>
          {allProductLists.map((i, index) => (
            <option key={index} value={i.id}>
              {i.name} (v{i.version})
            </option>
          ))}
        </Select>
      </td>
    </tr>
  );
};

export default TestReports;
