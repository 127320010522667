import React from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { NavigationLink } from 'components/base';
import Calculator from './Calculator';
import History from './History';
import Edit from './Edit';
import Page from 'components/Page';

const CalculatorIndex = () => {
   return (
      <Routes>
         <Route element={<Layout />}>
            <Route index path="index" element={<Calculator />} />
            <Route path="history" element={<History />} />
            <Route path="history/:id" element={<Edit />} />
            <Route path="*" element={<Navigate to="index" />} />
         </Route>
      </Routes>
   );
};

const Layout = () => (
   <Page
      className="h-screen"
      leftButtons={
         <div className="flex space-x-4">
            <NavigationLink to="index" title="Calculator" />
            <NavigationLink to="history" title="History" />
         </div>
      }
   >
      <Outlet />
   </Page>
);

export default CalculatorIndex;
