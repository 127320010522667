import { gql, useQuery } from '@apollo/client';

export const FETCH_ALL_PRODUCTION_NODES = gql`
   query FETCH_ALL_PRODUCTION_NODES {
      allProductionNodes {
         id
         name
         parent {
            id
         }
         category {
            id
            name
            type
            factory {
               id
               name
            }
            qtyPerDay
            timeList {
               id
               qty
               days
            }
            products {
               id
            }
         }
         daysToTransfer
      }
   }
`;

const useProduction = () => {
   const { loading, error, data } = useQuery(FETCH_ALL_PRODUCTION_NODES);
   const allProductionNodes = data ? data.allProductionNodes : null;
   return { loading, error, allProductionNodes };
};

export default useProduction;
