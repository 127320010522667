import React, { useState, useContext, useMemo } from "react";
import { useMutation } from "@apollo/client";
import { DatePicker, Text } from "../../../../components/Form.js";
import Status from "../../../../components/ShipmentStatus.js";
import { formatDate, parseDate } from "react-day-picker/moment";
import { DELETE_SHIPMENT_STATUS, UPDATE_SHIPMENT_STATUS } from "../../graphql";
import ShipmentErrorForm from "./ShipmentErrorForm";
import ShipmentContext from "../ShipmentContext";
import ShipmentError from "./ShipmentError";
import { Alert } from "components/Toast.js";
import CharlesButton from "components/charles/base.js";
import { parseError } from "apollo.js";

function ShipmentStatus({ errorOptions }) {
  const [showErrorFormForShipmentStatus, setShowErrorFormForShipmentStatus] = useState(null);
  const [editingStatus, setEditingStatus] = useState(null);
  const [notes, setNotes] = useState("");
  const [created, setCreated] = useState("");
  const { state, dispatch } = useContext(ShipmentContext);

  const editStatus = (editingStatus) => {
    setEditingStatus(editingStatus);
    setNotes(editingStatus.notes);
    setCreated(editingStatus.created);
  };

  const [updateShipmentStatus, { loading }] = useMutation(UPDATE_SHIPMENT_STATUS, {
    onCompleted: ({ updateShipmentStatus }) => {
      Alert("success", "Status updated");
      dispatch({ type: "updateShipmentStatus", payload: updateShipmentStatus.shipmentStatus });
      setEditingStatus(null);
    },
    onError(error) {
      Alert("error", parseError(error));
    },
  });

  const [deleteShipmentStatus] = useMutation(DELETE_SHIPMENT_STATUS, {
    onCompleted: ({ deleteShipmentStatus }) =>
      dispatch({
        type: "deleteShipmentStatus",
        payload: deleteShipmentStatus.id,
      }),
  });

  return useMemo(() => {
    return state.shipmentStatus.map((s) => (
      <div key={s.id} className={`mt-4 lg:mt-6 px-4 lg:px-6 py-4 rounded-xl bg-gray-100 dark:bg-gray-900 animate-ease-2`}>
        <div className="lg:flex items-center justify-between">
          <div className="lg:flex items-center">
            <div className="whitespace-no-wrap">{formatDate(s.created * 1000, "YYYY-MM-DD")}</div>
            <Status className="mt-2 lg:mt-0 lg:ml-4" status={s.status} />
          </div>
          <div className="flex mt-2 lg:mt-0 lg:justify-end space-x-4">
            <CharlesButton onClick={() => setShowErrorFormForShipmentStatus(s.id)}>add error</CharlesButton>
            <CharlesButton onClick={() => editStatus(s)}>edit status</CharlesButton>
            <CharlesButton
              danger
              onClick={() => {
                if (window.confirm(`Are you sure you want to delete this status?`)) {
                  deleteShipmentStatus({ variables: { id: s.id } });
                }
              }}
            >
              delete status
            </CharlesButton>
          </div>
        </div>

        {editingStatus && editingStatus.id === s.id ? (
          <div className="bg-white dark:bg-gray-800 rounded-lg p-4 py-2 mt-4 lg:p-6 lg:py-4 lg:mt-6">
            <div className="py-2">
              <Text placeholder="Notes" autoFocus rows={3} async={true} className="h-auto text-sm" value={notes} onChange={(e) => setNotes(e.target.value)} />
              <div className="flex items-center mt-2">
                <div className="pr-4">Date: </div>
                <DatePicker
                  parseDate={parseDate}
                  formatDate={formatDate}
                  format="YYYY-MM-DD"
                  value={created ? new Date(created * 1000) : ""}
                  onDayChange={(selectedDay) => {
                    if (selectedDay) setCreated(new Date(selectedDay) / 1000);
                  }}
                />
              </div>
              <div className="pt-4 flex space-x-4">
                <CharlesButton
                  loading={loading}
                  onClick={(_) => {
                    updateShipmentStatus({
                      variables: {
                        id: s.id,
                        notes: notes,
                        created: created,
                      },
                    });
                  }}
                >
                  Save
                </CharlesButton>
                <CharlesButton
                  onClick={(_) => {
                    setEditingStatus(null);
                    setNotes("");
                  }}
                >
                  Cancel
                </CharlesButton>
              </div>
            </div>
          </div>
        ) : s.notes && s.notes !== "" ? (
          <div className="mt-4">
            <div className="whitespace-pre-wrap mt-2">
              <b>Notes: </b>
              {s.notes}
            </div>
          </div>
        ) : null}

        {showErrorFormForShipmentStatus === s.id ? (
          <div className="mt-6">
            <ShipmentErrorForm shipmentStatusId={s.id} errorOptions={errorOptions} hideForm={() => setShowErrorFormForShipmentStatus(null)} />
          </div>
        ) : null}

        {s.errors.length > 0 ? (
          <div className="mt-6">
            {s.errors.map((i, index) => (
              <ShipmentError key={index} shipmentStatusId={s.id} shipmentStatusError={i} errorOptions={errorOptions} />
            ))}
          </div>
        ) : null}
      </div>
    ));
  }, [state.shipmentStatus, created, deleteShipmentStatus, editingStatus, errorOptions, loading, notes, showErrorFormForShipmentStatus, updateShipmentStatus]);
}

export default ShipmentStatus;
