import React from 'react';
import { useQuery, gql } from '@apollo/client';
import Spinner from '../../../../components/Spinner';
import Errors from '../../../../components/Errors';
import { Tag } from '../../../../components/base';

const FETCH_ALL_FACTORIES = gql`
   query FETCH_ALL_FACTORIES {
      allFactories {
         id
         name
         nickName
         status
      }
   }
`;

function FactorySelector({ value, onSelect }) {
   const { loading, error, data } = useQuery(FETCH_ALL_FACTORIES);

   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;

   return (
      <div className="flex flex-wrap">
         {data.allFactories
            .filter((f) => f.status === 1)
            .map((factory) => (
               <Tag
                  className="mr-2 mb-2"
                  key={factory.id}
                  title={factory.nickName}
                  selected={value.indexOf(factory.id) > -1}
                  onClick={() => {
                     if (value.indexOf(factory.id) > -1) {
                        onSelect(value.filter((i) => i !== factory.id));
                     } else {
                        onSelect([...value, factory.id]);
                     }
                  }}
               />
            ))}
      </div>
   );
}

export default FactorySelector;
