import CharlesButton from "components/charles/base";

const IncProductRow = ({ incProduct, showProductSelector }) => {
  return (
    <tr>
      <td className="px-4 border border-gray-100 dark:border-gray-700">#{incProduct.id}</td>
      <td className="px-4 border border-gray-100 dark:border-gray-700">{incProduct.sku}</td>
      <td className="px-4 border border-gray-100 dark:border-gray-700 w-full">
        {incProduct.wisProduct ? (
          <span>
            [{incProduct.wisProduct.number}] {incProduct.wisProduct.name}
          </span>
        ) : (
          <CharlesButton onClick={showProductSelector}>Choose</CharlesButton>
        )}
      </td>
    </tr>
  );
};

export default IncProductRow;
