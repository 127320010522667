import { gql, useQuery } from "@apollo/client";
import Errors from "components/Errors";
import Spinner from "components/Spinner";
import { createContext, useState } from "react";
import { searchByProp } from "utils/search";
import SearchBar from "components/SearchBar";
import { Button } from "components/base";
import ProductLineForm from "./ProductLineForm";
import { byName } from "utils/sort";
import ImageLoader from "components/Image";
import { IoMdCheckmark, IoMdClose } from "react-icons/io";
import { useModals } from "ModalProvider";

export const FETCH_PRODUCT_LINES = gql`
  query FETCH_PRODUCT_LINES {
    allProductlines {
      id
      name
      number
      mainImage(size: "300x300")
      activeForWis
      activeForBiz
      customsName
      customsBrand
      customsUsage
      customsValue
      customsSize
      customsMaterials
    }
  }
`;

export const AllProductsContext = createContext({});

const AllProductLines = () => {
  const { loading, error, data } = useQuery(FETCH_PRODUCT_LINES);
  const [searchText, setSearchText] = useState("");

  const newProductLineModal = useModals();
  const editProductLineModal = useModals();

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  function onCreatedProductLine(productLine) {
    editProductLineModal.present({
      title: "Edit Product Line",
      maxWidth: "max-w-full",
      children: <ProductLineForm id={productLine.id} />,
    });
    newProductLineModal.hide();
  }

  const linesActiveForWis = data.allProductlines.filter((i) => i.activeForWis).sort(byName);
  const inactiveLines = data.allProductlines.filter((i) => !i.activeForWis).sort(byName);

  const lines = [...linesActiveForWis, ...inactiveLines].filter((i) => searchByProp(i, ["name", "number"], searchText));

  function edit(id) {
    editProductLineModal.present({
      title: "Edit Product Line",
      fullscreen: true,
      isBeingPresented: true,
      children: (
        <ProductLineForm
          id={id}
          duplicate={(res) => {
            editProductLineModal.hide();
            newProductLineModal.present({
              title: "Create Product Line",
              fullscreen: true,
              children: <ProductLineForm duplicatingProduct={res} onSave={onCreatedProductLine} />,
            });
          }}
        />
      ),
    });
  }

  return (
    <div className="flex-1 overflow-auto flex flex-col card p-0 rounded-2xl relative m-6">
      <div className="flex sticky top-0 px-6 py-4 bg-white shadow-sm bg-opacity-80 backdrop-blur-lg dark:bg-gray-800 dark:bg-opacity-80 z-10">
        <div className="flex-1">
          <SearchBar autoFocus className="p-2 flex-1" value={searchText} onChange={setSearchText} placeholder="Search produc name, item number" />
        </div>

        <div className="flex space-x-4 items-center ml-8">
          <Button
            title="+ Product Line"
            onClick={() =>
              newProductLineModal.present({
                title: "Create Product Line",
                fullscreen: true,
                children: <ProductLineForm onSave={onCreatedProductLine} />,
              })
            }
          />
        </div>
      </div>

      <div className="pt-0 relative">
        {lines.map((i) => (
          <div
            key={i.id}
            className="border-b dark:border-gray-700 py-2 px-6 cursor-pointer hover:bg-gray-100 active:bg-gray-200 dark:hover:bg-gray-700 flex justify-between"
            onClick={() => edit(i.id)}
          >
            <div className="flex items-center">
              <div className="w-8">
                <ImageLoader size="w-8 h-8" src={i.mainImage} />
              </div>
              <div className="ml-6">
                {i.number ? `[ ${i.number} ] ` : null}
                {i.name}
              </div>
            </div>
            <div className="flex items-center space-x-3">
              {i.customsName ? (
                <div className="mr-6">
                  {i.customsName}, brand: {i.customsBrand}
                </div>
              ) : null}
              <div>WIS</div>
              {i.activeForWis ? <IoMdCheckmark size={21} className="text-green-600" /> : <IoMdClose size={21} className="text-red-500" />}

              <div>BIZ</div>
              {i.activeForBiz ? <IoMdCheckmark size={21} className="text-green-600" /> : <IoMdClose size={21} className="text-red-500" />}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AllProductLines;
