import { gql, useMutation } from "@apollo/client";
import CharlesButton from "components/charles/base";
import { InlineSpinner } from "components/Spinner";
import { Alert } from "components/Toast";

const CANCEL_PRODUCTION_PLAN = gql`
  mutation($planId: ID!, $cancelLaterPlans: Boolean!) {
    cancelProductionPlan(planId: $planId, cancelLaterPlans: $cancelLaterPlans) {
      productionPlan {
        id
        state
      }
    }
  }
`;

const CancelPlanView = ({ onCompleted, plan }) => {
  const [cancelPlan, cancelPlanRes] = useMutation(CANCEL_PRODUCTION_PLAN, {
    onCompleted() {
      Alert("success", "Plan is Canceled.");
      if (onCompleted) onCompleted();
    },
    onError(error) {
      Alert("error", error.message);
    },
  });

  return (
    <div className="text-sm">
      <div className="space-y-2">
        <p>Are you sure to cancel this plan? </p>
        <p>Canceling plans will release the stock and production dates usages.</p>
        <p>If you want to only cancel this plan, later plans dates might change due to stock and production usage.</p>
      </div>

      <hr />

      <div>
        {cancelPlanRes.loading ? (
          <InlineSpinner size={27} text="Canceling Plan..." />
        ) : (
          <div className="flex space-x-6">
            <CharlesButton
              loading={cancelPlanRes.loading}
              onClick={() =>
                cancelPlan({
                  variables: {
                    planId: plan.id,
                    cancelLaterPlans: false,
                  },
                })
              }
            >
              Cancel only this plan
            </CharlesButton>
            <CharlesButton
              onClick={() =>
                cancelPlan({
                  variables: {
                    planId: plan.id,
                    cancelLaterPlans: true,
                  },
                })
              }
            >
              Cancel this plan and all the later plans
            </CharlesButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default CancelPlanView;
