import { gql, useQuery } from "@apollo/client";
import Errors from "components/Errors";
import Spinner from "components/Spinner";
import { useParams } from "react-router-dom";
import SubSupplierOrderForm from "./Form";

const FETCH_SUB_SUPPLIER_ORDER_DETAIL = gql`
  query FETCH_SUB_SUPPLIER_ORDER_DETAIL($id: ID!) {
    subSupplierOrder(id: $id) {
      id
      odooPoId
      odooPoName
      odooPurchaseOrder
      productionSupplierId
      orderDate
      paymentTerms
      estimateDeliveryDate
      deliveryAddress
      bankName
      bankAccountNumber
      bankAccountUsername
      remark
      imgUrl
      productionSupplier {
        id
        name
        producerProducts {
          id
          name
          odooId
        }
      }
      lines {
        id
        name
        qty
        unitPrice
        totalPrice
      }
      invoices {
        id
        number
        currency
        total
        invoiceDate
        dueDate
      }
    }
  }
`;

const SubSupplierOrderDetail = () => {
  const { id } = useParams();
  const { loading, error, data, refetch } = useQuery(FETCH_SUB_SUPPLIER_ORDER_DETAIL, {
    variables: { id },
    fetchPolicy: "network-only",
  });

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  return <SubSupplierOrderForm originalOrder={data.subSupplierOrder} refetch={refetch} />;
};

export default SubSupplierOrderDetail;
