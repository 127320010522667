import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import Spinner from '../../../../components/Spinner';
import Errors from '../../../../components/Errors';
import CommercialInvoiceForm from './Form';
import Page from 'components/Page';

const FETCH_COMMERCIAL_INVOICE = gql`
   query ($id: ID!) {
      commercialInvoice(id: $id) {
         id
         data
         deposite
         currency
         balance
         created
      }
   }
`;

function EditCommercialInvoice() {
   const { id } = useParams();
   const { loading, error, data } = useQuery(FETCH_COMMERCIAL_INVOICE, {
      variables: { id },
   });
   if (error) return <Errors error={error} />;
   if (loading) return <Spinner />;

   const { commercialInvoice } = data;
   const shipment = JSON.parse(commercialInvoice.data);

   return (
      <Page
         title="Edit Commercial Invoice"
         backTo={`/shipment/doc/commercial-invoice/${commercialInvoice.id}`}
      >
         <CommercialInvoiceForm
            commercialInvoice={commercialInvoice}
            initialShipment={shipment}
         />
      </Page>
   );
}

export default EditCommercialInvoice;
