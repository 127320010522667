import { gql, useQuery } from "@apollo/client";
import { parseError } from "apollo";
import Errors from "components/Errors";
import Spinner from "components/Spinner";
import { NavigationBar } from "components/base";
import moment from "moment";
import { Link, useParams, useSearchParams } from "react-router-dom";
import ProductStockSettingsView from "./ProductStockSettingsView";
import CharlesButton from "components/charles/base";
import { useModals } from "ModalProvider";
import CreateSupplierStockRecord from "./CreateSupplierStockRecord";
import Status from "components/Status";
import { STOCK_USAGE_STRATEGY_OPTIONS } from "./StockUseStrategyView";

const FETCH_PRODUCT_SUPPLIER_STOCK = gql`
  query FETCH_PRODUCT_SUPPLIER_STOCK($id: ID!) {
    product(id: $id) {
      id
      number
      name
      supplierStocks {
        id
        qty
        latestQty
        updatedAt
        readyDate
        useStrategy
        supplier {
          id
          name
        }
        active
        remark
        usages {
          id
          plan {
            id
            name
            state
          }
          qty
        }
      }
    }
  }
`;

const ProductStock = () => {
  const { id } = useParams();
  const { loading, error, data } = useQuery(FETCH_PRODUCT_SUPPLIER_STOCK, {
    variables: { id },
  });
  const modal = useModals();
  const [searchParams] = useSearchParams({ filterSupplierId: "ALL" });
  const filterSupplierId = searchParams.get("filterSupplierId") || "ALL";

  if (loading) return <Spinner />;
  if (error) return <Errors error={parseError(error)} />;

  const stocks = [...data.product.supplierStocks].sort((a, b) => (a.updatedAt < b.updatedAt ? 1 : -1));

  function tryCreateNewStockRecord() {
    const latestStock = stocks.length > 0 ? stocks[0] : null;
    const supplierId = latestStock ? latestStock.supplier.id : null;
    modal.present({
      title: "Create Product Stock",
      children: <CreateSupplierStockRecord initData={{ product: data.product, supplierId }} complete={modal.hide} />,
    });
  }

  return (
    <div>
      <NavigationBar
        backTo={`./..?filterSupplierId=${filterSupplierId}`}
        title={`[${data.product.number}] ${data.product.name}`}
        rightButtons={
          <div>
            <CharlesButton onClick={tryCreateNewStockRecord}>+ Create New Stock Record</CharlesButton>
          </div>
        }
      />
      <div className="p-6 grid grid-cols-12 gap-6">
        <div className="col-span-8">
          <StocksView stocks={stocks} />
        </div>

        <div className="col-span-5"></div>
      </div>
    </div>
  );
};

const StocksView = ({ stocks }) => {
  if (stocks.length === 0) {
    return <div className="text-center text-gray-500">No stocks found.</div>;
  }

  const latestStock = stocks[0];

  const oldStocks = stocks.length > 1 ? stocks.slice(1) : [];

  return (
    <div className="space-y-6">
      <StockUsagesView title="Latest Stock" stock={latestStock} isLatest />

      {oldStocks.map((stock) => (
        <StockUsagesView key={stock.id} title={`Stock ${moment(stock.updatedAt).format("YYYY-MM-DD")}`} stock={stock} />
      ))}
    </div>
  );
};

const StockUsagesView = ({ title, stock, isLatest }) => {
  let left = stock.qty;

  const usages = stock.usages
    .filter((i) => i.plan && i.qty > 0)
    .map((i) => {
      left = left - i.qty;
      return { ...i, left };
    });

  return (
    <div className="card p-6">
      <h5>{title}</h5>
      <div className="mt-4">
        <div className="flex items-center space-x-3">
          <label htmlFor="">How to use: </label>
          <ProductStockSettingsView stock={stock} disabled={!isLatest} />
        </div>

        {stock.remark && (
          <div className="mt-2">
            <label htmlFor="">Remark: </label>
            <span>{stock.remark}</span>
          </div>
        )}

        {isLatest ? (
          stock.active ? (
            <div className="mt-2">
              If you want to deactivate this stock record, please go to{" "}
              <a href={`${process.env.REACT_APP_SERVER_ADMIN_URL}product/supplierstockrecord/${stock.id}/change/`} target="_blank" rel="noreferer">
                Admin
              </a>
              .
            </div>
          ) : (
            <div className="mt-2 font-semibold text-red-600">
              The latest stock record is marked as `NOT active`, it means that we are not going to use the supplier stock for this product. Order Scheduler
              plans will not consider this stock record when computing. To manage this, please go to{" "}
              <a href={`${process.env.REACT_APP_SERVER_ADMIN_URL}product/supplierstockrecord/${stock.id}/change/`} target="_blank" rel="noreferer">
                Admin
              </a>
              .
            </div>
          )
        ) : null}
      </div>
      <div className="-mx-2 mt-4">
        <table>
          <tbody>
            <tr>
              <td>
                <div>
                  <h5>Initial QTY at {moment(stock.readyDate).format("YYYY-MM-DD")}</h5>
                  <div className=" opacity-70">Updated at {moment(stock.updatedAt).format("YYYY-MM-DD")}</div>
                </div>
              </td>
              <th></th>
              <td className="text-right">{stock.qty}</td>
            </tr>
            {usages.length > 0 ? (
              <>
                {usages.map((usage) => (
                  <tr key={usage.id} className="border-t border-gray-100 dark:border-gray-700">
                    <td>
                      <div className="flex items-center space-x-3">
                        <div>
                          <Link to={`/order-scheduler/plans/${usage.plan.id}`} target="_blank">
                            {usage.plan.name}
                          </Link>
                        </div>
                        <Status status={usage.plan.state} />
                      </div>
                    </td>
                    <td className="text-right">{usage.qty}</td>
                    <td className="text-right">{usage.left}</td>
                  </tr>
                ))}
                <tr className="font-semibold text-base border-t border-gray-100 dark:border-gray-700">
                  <td>Left</td>
                  <td></td>
                  <td className="text-right">{stock.latestQty}</td>
                </tr>
              </>
            ) : (
              <tr>
                <td>No plans using this stock.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProductStock;
