import { gql, useQuery } from '@apollo/client';
import { Button } from 'components/base';
import Errors from 'components/Errors';
import Spinner from 'components/Spinner';
import moment from 'moment';

const FETCH_EXPEDITOR_SHIPMENTS = gql`
   query FETCH_EXPEDITOR_SHIPMENTS {
      expeditorShipments
   }
`;

const Shipments = () => {
   const { loading, error, data } = useQuery(FETCH_EXPEDITOR_SHIPMENTS);

   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;

   const shipments = JSON.parse(data.expeditorShipments);

   console.log('shipments', shipments);

   return (
      <div className="p-6 bg-white flex-1">
         <table className="with-line">
            <thead>
               <tr>
                  <th>ID</th>
                  <th>Origin</th>
                  <th>Destination</th>
                  <th>Status</th>
                  <th>Updated At</th>
               </tr>
            </thead>
            <tbody>
               {shipments.map((shipment) => (
                  <tr key={shipment.id}>
                     <td>
                        <Button title={shipment.id} link={shipment.id} />
                     </td>
                     <td>{shipment.origin}</td>
                     <td>{shipment.destination}</td>
                     <td>{shipment.status}</td>
                     <td className="text-right">
                        {moment(shipment.lastUpdateTime).format(
                           'YYYY-MM-DD hh:mm:ss'
                        )}
                     </td>
                  </tr>
               ))}
            </tbody>
         </table>
      </div>
   );
};

export default Shipments;
