import React from "react";
import { NavigationLink } from "components/base";

export const LeftButtons = () => (
  <div className="flex space-x-4">
    <NavigationLink to="/csr/assessment" title="Assessment" />
    <NavigationLink to="/csr/trips" title="Trips" />
    <NavigationLink to="/csr/factory" title="Factories" />
  </div>
);
