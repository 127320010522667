import { useQuery } from "@apollo/client";
import { INC_INVENTORY_ITEMS } from "./graphql";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import moment from "moment";
import { useState } from "react";
import CharlesButton from "components/charles/base";

const InventoryPage = () => {
  const [startDate, setStartDate] = useState(new Date().addDays(-30 * 1));
  const [endDate, setEndDate] = useState(new Date());

  const { loading, error, data } = useQuery(INC_INVENTORY_ITEMS, {
    variables: {
      itemType: "inventory",
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
    },
  });

  return (
    <div className="m-6 flex flex-col flex-1 overflow-auto">
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-3">
          <label htmlFor="">Start Date: </label>
          <input type="date" value={moment(startDate).format("YYYY-MM-DD")} onChange={(e) => setStartDate(new Date(e.target.value))} />

          <label htmlFor="">End Date:</label>
          <input type="date" value={moment(endDate).format("YYYY-MM-DD")} onChange={(e) => setEndDate(new Date(e.target.value))} />
        </div>
        {data && (
          <div>
            <CharlesButton>Download</CharlesButton>
          </div>
        )}
      </div>

      <div className="overflow-auto flex-1 mt-3 relative">{loading ? <Spinner /> : error ? <Errors error={error} /> : <TableView data={data} />}</div>
    </div>
  );
};

const TableView = ({ data }) => {
  let dates = [];
  data.incInventoryItems.forEach((item) => {
    if (!dates.includes(item.date)) {
      dates.push(item.date);
    }
  });
  const sortedDates = dates.sort((a, b) => new Date(a) - new Date(b));

  let skus = [];
  data.incInventoryItems.forEach((item) => {
    if (!skus.includes(item.product.sku)) {
      skus.push(item.product.sku);
    }
  });

  let items = {};
  skus.forEach((sku) => {
    items[sku] = [];
    sortedDates.forEach((date) => {
      const found = data.incInventoryItems.find((item) => item.product.sku === sku && item.date === date);

      if (sku === "101C01_SS12") {
        console.log("sku", sku, "date", date, found);
      }
      if (found) {
        items[sku].push(found.qty);
      } else {
        items[sku].push(0);
      }
    });
  });

  return (
    <table className="bg-white whitespace-nowrap">
      <thead>
        <tr className=" sticky top-0 z-20 bg-white">
          <th className="border sticky top-0 left-0 z-20 bg-white">SKU</th>
          {sortedDates.map((date, index) => (
            <th key={index} className="text-right border">
              {date}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {Object.entries(items).map(([sku, qtyList]) => (
          <tr key={sku} className="border-y">
            <td className="border sticky left-0 z-10 bg-white font-semibold">{sku}</td>
            {qtyList.map((qty, index) => (
              <td key={index} className="text-right border">
                {qty}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default InventoryPage;
