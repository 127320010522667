import gql from "graphql-tag";

export const PAGE_SIZE = 50;

export const FETCH_COMMERCIAL_INVOICES = gql`
  query FETCH_COMMERCIAL_INVOICES($limit: Int, $offset: Int) {
    commercialInvoices(limit: $limit, offset: $offset) {
      id
      shipment {
        id
        invoiceNumber
        customer {
          id
          name
        }
      }
      created
    }
  }
`;

export const SAVE_COMMERCIAL_INVOICE = gql`
  mutation SAVE_COMMERCIAL_INVOICE($id: ID, $shipmentId: ID!, $data: String!, $currency: String!, $deposite: Float!, $balance: Float!) {
    saveCommercialInvoice(id: $id, shipmentId: $shipmentId, data: $data, currency: $currency, deposite: $deposite, balance: $balance) {
      commercialInvoice {
        id
      }
    }
  }
`;
