import { Input } from "components/Form";
import ProductSelector from "./ProductsSelector";
import { BiTrash } from "react-icons/bi";
import moment from "moment";
import CharlesButton from "components/charles/base";
import { IoIosInformationCircleOutline } from "react-icons/io";
import Status from "components/Status";
import { PiWarningCircle } from "react-icons/pi";

const PlanLine = ({
  index,
  state,
  line,
  products,
  onChangeLine,
  onChangeLineProduct,
  removeLine,
  setNeedToRecompute,
  disabled,
  showSalesPrice,
  isComputing,
  showBom,
}) => {
  let lineStyle = "";
  if (state !== "DRAFT") {
    if (!line.readyDate) lineStyle = "text-red-600 font-semibold";
  }

  return (
    <tr
      className={disabled ? `border-b dark:border-gray-700 ${lineStyle}` : null}
    >
      <td>
        <div className="flex space-x-2 items-center">
          {disabled ? null : (
            <CharlesButton
              disabled={isComputing}
              danger
              onClick={() => {
                removeLine(line.id);
                if (line.qty !== 0) setNeedToRecompute(true);
              }}
            >
              <BiTrash />
            </CharlesButton>
          )}
          <span>{index + 1}. </span>
          <div className="relative w-full flex items-center space-x-2">
            {state === "DRAFT" ? (
              <div className="flex flex-1 items-center space-x-2">
                <ProductSelector
                  disabled={isComputing}
                  initialValue={
                    line.product
                      ? `[${line.product.number}] ${line.product.name}`
                      : ""
                  }
                  options={products}
                  value={line.product ? line.product.id : "0"}
                  onSelect={(product) => {
                    onChangeLineProduct(line.id, product);
                    setNeedToRecompute(true);
                  }}
                />
                {line.product ? (
                  <CharlesButton
                    className="text-sm"
                    onClick={() => showBom(line.product)}
                  >
                    <IoIosInformationCircleOutline size={16} />
                  </CharlesButton>
                ) : null}
              </div>
            ) : (
              <div className="flex items-center space-x-2">
                {line.product ? (
                  <div className="flex space-x-2 items-center">
                    <div>
                      [{line.product.number}] {line.product.name}
                    </div>
                    <CharlesButton
                      className="text-sm"
                      onClick={() => showBom(line.product)}
                    >
                      <IoIosInformationCircleOutline size={16} />
                    </CharlesButton>
                    {line.product.isActive ? null : (
                      <div>
                        <span className="ml-1 text-red-600 dark:text-red-700 font-semibold">
                          [Inactive Product]
                        </span>
                      </div>
                    )}
                  </div>
                ) : (
                  " - "
                )}
              </div>
            )}
          </div>
        </div>
      </td>
      <td className="text-right">
        {line.product ? line.product.quantityPerCarton : " - "}
      </td>
      <td className="text-right">
        <div className="flex justify-end space-x-2">
          {state === "DRAFT" ? (
            <Input
              disabled={isComputing}
              validated={line.validQty}
              className="text-right w-20"
              value={line.qty}
              onChange={(e) => {
                onChangeLine(line.id, "qty", parseInt(e.target.value) || 0);
                setNeedToRecompute(true);
              }}
            />
          ) : (
            <span className={`whitespace-nowrap`}>{line.finalQty}</span>
          )}
        </div>
      </td>
      <td className="text-right">{line.totalCbm.toFixed(3)}</td>
      <td className="text-right">
        {isComputing
          ? "..."
          : line.readyDate
            ? moment(line.readyDate).format("YYYY-MM-DD")
            : " - "}
      </td>
      <td className="text-right">
        {line.unitCost ? line.unitCost.toFixed(2) : "0.00"}
      </td>

      <td className="text-right">{line.totalCost.toFixed(2)}</td>
      {showSalesPrice ? (
        <>
          <td className="text-right">
            {disabled ? (
              line.salesPrice
            ) : (
              <div className="flex items-center justify-end space-x-2">
                {line.priceState === "pending" ? (
                  <PiWarningCircle
                    className=" text-red-600"
                    size={16}
                    title="This price is not confirmed. If you have any question about the price, please contact Stella."
                  />
                ) : null}
                <Input
                  disabled={isComputing}
                  className="text-right w-20"
                  value={line.salesPrice}
                  onChange={(e) =>
                    onChangeLine(line.id, "salesPrice", e.target.value)
                  }
                />
              </div>
            )}
          </td>
          <td className="text-right">
            {disabled ? (
              line.discountPercentage
            ) : (
              <Input
                disabled={isComputing}
                className="text-right w-20"
                value={line.discountPercentage || 0}
                onChange={(e) =>
                  onChangeLine(line.id, "discountPercentage", e.target.value)
                }
              />
            )}
          </td>
          <td className="text-right">
            {line.totalPrice ? line.totalPrice.toFixed(2) : "0.00"}
          </td>
        </>
      ) : null}
    </tr>
  );
};

export default PlanLine;
