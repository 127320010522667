import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import Spinner from "./Spinner";
import Errors from "./Errors";
import SearchBar from "./SearchBar.js";
import { searchByProp } from "../utils/search";
import { parseError } from "apollo";

const FETCH_CUSTOMERS = gql`
  query fetchCustomers {
    customers {
      id
      name
    }
  }
`;

function CustomersSelector({ initialSearchText = "", onSelectCustomer }) {
  const [searchText, setSearchText] = useState(initialSearchText);
  const { loading, error, data } = useQuery(FETCH_CUSTOMERS);

  if (loading) return <Spinner />;
  if (error) return <Errors error={parseError(error)} />;

  const customersToShow = data.customers.filter((i) => searchByProp(i, ["name", "email"], searchText));

  return (
    <div>
      <SearchBar autoFocus={true} className="py-2 " type="text" placeholder="Search customer by name or email" value={searchText} onChange={setSearchText} />

      <div className="mt-4 flex-1 bg-white overflow-auto rounded-lg">
        {customersToShow.length > 0 ? (
          <table>
            <tbody>
              {customersToShow.map((customer, key) => (
                <tr className="cursor-pointer hover:bg-blue-100" key={key} onClick={() => onSelectCustomer(customer)}>
                  <td className="py-2 px-4 border-b border-gray-100">{customer.name}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="flex flex-col items-center justify-center p-8">
            <div className="text-lg opacity-70 mb-4">No customers found.</div>
          </div>
        )}
      </div>
    </div>
  );
}

export default CustomersSelector;
