import { useState } from 'react';
import { DatePicker } from 'components/Form';
import { CharlesButton } from 'components/charles/base';
import XLSX from 'xlsx';
import moment from 'moment';

const ExportStockView = ({ warehouse, data, complete }) => {
	const [date, setDate] = useState(new Date());

	function exportExcel() {
		const stock = data.find(
			(i) => i.date === moment(date).format('YYYY-MM-DD')
		);
		console.log('Object.entries(stock)', Object.entries(stock));
		const sheetData = Object.entries(stock);
		const sheet = XLSX.utils.json_to_sheet(sheetData);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, sheet, 'stock');
		XLSX.writeFile(wb, warehouse + '.xlsx');
		complete();
	}

	return (
		<div>
			<div>
				<div className="flex items-center space-x-3">
					<label>by Date: </label>
					<DatePicker value={date} onDayChange={setDate} />
				</div>
			</div>

			<hr />

			<div>
				<CharlesButton onClick={exportExcel}>Export</CharlesButton>
			</div>
		</div>
	);
};

export default ExportStockView;
