import { gql, useQuery } from "@apollo/client";
import Errors from "components/Errors";
import { InlineSpinner } from "components/Spinner";
import { Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

const FETCH_PRODUCT_FORECASTED_SALES = gql`
  query FETCH_PRODUCT_FORECASTED_SALES($skus: [String]!) {
    incForecastSales(skus: $skus)
  }
`;

const ProductForecastView = ({ sku, yearMonth }) => {
  const { loading, error, data } = useQuery(FETCH_PRODUCT_FORECASTED_SALES, {
    variables: { skus: [sku] },
  });

  if (loading)
    return (
      <div>
        <InlineSpinner size={16} text={null} />
      </div>
    );

  if (error) return <Errors errors={error} />;

  const res = JSON.parse(data.incForecastSales).map((i) => ({ ...i, forecast: parseInt(i.my_forecast) }));
  const found = res.find((x) => x.date === yearMonth);

  if (found)
    return (
      <div className="relative z-50 text-left space-y-4">
        <div>
          <label htmlFor="">Auto Forecast Sales: </label>
          <span>{parseInt(found.my_forecast)}</span>
        </div>

        <ResponsiveContainer height={160}>
          <LineChart width="100%" data={res} margin={{ top: 14, right: 10, left: -20, bottom: 0 }}>
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip content={CustomTooltip} />
            <Line type="monotone" dataKey="sales" dot={false} stroke="#059669" />
            <Line type="monotone" dataKey="forecast" dot={false} stroke="#9333ea" />
            <Legend />
          </LineChart>
        </ResponsiveContainer>

        <div className="whitespace-normal text-left opacity-50">
          Auto forecast base on historical sales data for the last two years and do a forecast for the next 12 months. It forecast the sales base on "ARIMA -
          Autoregressive Integrated Moving Average". Let's test it and see how good it is.
        </div>
      </div>
    );

  return <div>No forecast.</div>;
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="px-4 py-2 rounded-2xl bg-opacity-80 bg-white dark:bg-gray-800 dark:bg-opacity-80">
        <table>
          <tbody>
            <tr>
              <td colSpan={2}>{label}</td>
            </tr>
            {payload.map((item, index) => (
              <tr key={index} className="border-b dark:border-gray-700">
                <td className="py-1">{item.dataKey}</td>
                <td className="py-1 text-right">{item.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  return null;
};

export default ProductForecastView;
