import { gql, useQuery } from '@apollo/client';
import { useState } from 'react';
import { useDebounce } from 'use-debounce';
import Errors from './Errors';
import SearchBar from './SearchBar';
import { InlineSpinner } from './Spinner';

const FETCH_ODOO_PURCHASE_ORDERS = gql`
   query FETCH_ODOO_PURCHASE_ORDERS($q: String) {
      odooPurchaseOrders(q: $q)
   }
`;

const OdooPurchaseOrderPicker = ({ onSelect }) => {
   const [searchText, setSearchText] = useState('');
   const [debouncedSearchText] = useDebounce(searchText, 500);
   const { loading, error, data } = useQuery(FETCH_ODOO_PURCHASE_ORDERS, {
      variables: { q: debouncedSearchText },
      fetchPolicy: 'network-only',
   });

   return (
      <div className="text-sm">
         <div>
            <SearchBar
               autoFocus
               className="dark:bg-gray-700 dark:bg-opacity-30"
               placeholder="Search by PO Number"
               onChange={setSearchText}
            />
         </div>

         <div className="mt-4 relative">
            {loading ? (
               <InlineSpinner className="p-20" />
            ) : error ? (
               <Errors error={error} />
            ) : (
               <OrdersView data={data} onSelect={onSelect} />
            )}
         </div>
      </div>
   );
};

const OrdersView = ({ data, onSelect }) => {
   const orders = JSON.parse(data.odooPurchaseOrders);

   return (
      <table>
         <thead>
            <tr>
               <th>Order</th>
               <th>Supplier</th>
               <th className="text-right">Order Date</th>
            </tr>
         </thead>
         <tbody>
            {orders.map((order) => (
               <tr
                  key={order.id}
                  className="cursor-pointer hover:text-blue-600 active:text-blue-700 border-b dark:border-gray-700"
                  onClick={() => onSelect(order)}
               >
                  <td className="font-semibold">{order.name}</td>
                  <td>{order.partner_id[1]}</td>
                  <td className="text-right">{order.date_order}</td>
               </tr>
            ))}
         </tbody>
      </table>
   );
};

export default OdooPurchaseOrderPicker;
