import { useState } from "react";
import { useQuery, gql } from "@apollo/client";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import Image from "components/Image";
import SearchBar from "components/SearchBar";
import { searchByProp } from "utils/search";
import { productItemsPerSet } from "../utils";
import { Button, ButtonWithArrow } from "components/base";
import { Select } from "components/Form";

const FETCH_PRODUCTS_COMBINATION = gql`
  query FETCH_PRODUCTS_COMBINATION {
    productsCombination
  }
`;

const Combines = () => {
  const { loading, error, data } = useQuery(FETCH_PRODUCTS_COMBINATION);

  const [searchText, setSearchText] = useState("");

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const productLines = JSON.parse(data.productsCombination)
    .map((line) => {
      const stringForSearch = line.products.map((p) => `${p.name} ${p.number}`).join(" ");
      return { ...line, stringForSearch };
    })
    .filter((i) => searchByProp(i, ["name", "stringForSearch"], searchText));

  console.log("productLines", productLines);

  return (
    <div>
      <div className="sticky top-0 p-6 blur-20 bg-gray-50 dark:bg-gray-900 bg-opacity-70 z-30">
        <div className="flex-1">
          <SearchBar autoFocus placeholder="Search by name or item number" value={searchText} onChange={setSearchText} />
        </div>

        <div className="mt-4 font-semibold text-red-600">Note that this page is currently not correct because the category has not been set for a while.</div>
      </div>

      <div className="p-6 pt-0">
        {productLines.map((line) => (
          <ProductLineDetail key={line.id} line={line} products={line.products} searchText={searchText} />
        ))}
      </div>
    </div>
  );
};

const ProductLineDetail = ({ line, products }) => {
  return (
    <div key={line.id} className="mb-8 bg-white dark:bg-gray-800 border border-gray-100 dark:border-gray-800 rounded-xl p-6">
      <table>
        <thead>
          <tr>
            <th>
              <div className="flex text-right items-end space-x-8 font-bold text-gray-500">
                <div className="my-1 flex items-end">
                  <h2 className="pr-4">{line.name}</h2>
                  <Image src={line.mainImage} size="w-10 h-10" />
                </div>
              </div>
            </th>
            {COMPONENT_TYPES.map((type, index) => (
              <th key={index} className="text-right">
                <div className="uppercase">{type}</div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {products.map((variant) => (
            <ProductVariant key={variant.id} variant={variant} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

const COMPONENT_TYPES = ["accessory", "product", "set", "packaging", "labor"];

const ProductVariant = ({ variant }) => {
  const [showDetail, setShowDetail] = useState(false);
  const [editingTypes, setEditingTypes] = useState(false);

  const sets = variant.defaultQty / productItemsPerSet(variant);

  const children = [...variant.children].sort((a, b) => (a.product.componentType > b.product.componentType ? -1 : 1));

  const types = children.map((i) => i.product.componentType).reduce((prev, i) => (prev.includes(i) ? prev : [...prev, i]), []);

  return (
    <>
      <tr className="border-t border-gray-100 dark:border-gray-700 py-3 px-4">
        <td className="">
          <ButtonWithArrow
            className={children.length > 0 ? "hover:text-blue-600" : "cursor-not-allowed"}
            show={showDetail}
            onClick={() => {
              if (children.length > 0) setShowDetail((prev) => !prev);
            }}
          >
            [ {variant.number} ] {variant.name} &times; {variant.defaultQty} ({sets} sets)
          </ButtonWithArrow>
        </td>

        {COMPONENT_TYPES.map((type, index) => (
          <td key={index} className="text-right">
            {variant.combines[type].toFixed(3)}%
          </td>
        ))}
      </tr>

      {showDetail ? (
        <tr>
          <td colSpan={COMPONENT_TYPES.length + 1}>
            <div className="w-full flex justify-between items-end bg-gray-100 py-4 px-6 rounded-xl relative">
              <div>
                <div className="flex justify-end pr-2">
                  <Button title={editingTypes ? "Done" : "Edit Types"} onClick={() => setEditingTypes((prev) => !prev)} />
                </div>
                <table className="text-xs text-gray-700 space-y-1 mt-2 w-auto">
                  <tbody>
                    {children.map((child, index) => (
                      <tr key={index}>
                        <td className="border-b pl-0">
                          {editingTypes ? (
                            <div>
                              {child.product.name}
                              <Select className="mx-4" value={child.product.componentType} onChange={(e) => {}}>
                                {COMPONENT_TYPES.map((type) => (
                                  <option value={type} key={type}>
                                    {type}
                                  </option>
                                ))}
                              </Select>
                              &times; {child.totalQty}
                            </div>
                          ) : (
                            <div>
                              {child.product.name} [{child.product.componentType}] &times; {child.totalQty}
                            </div>
                          )}
                        </td>
                        <td className="border-b text-right">
                          {child.unitCost.toFixed(3)} &times; {sets * child.qty}
                        </td>
                        <td className="border-b text-right">{child.totalCost.toFixed(3)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div className="text-right text-xl">
                <table>
                  <tbody>
                    {types.map((type, index) => (
                      <tr key={index} className="">
                        <td className="capitalize">{type}</td>
                        <td className="pr-0">{variant.combines[type].toFixed(3)}%</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </td>
        </tr>
      ) : null}
    </>
  );
};

export default Combines;
