import React from "react";
import { useQuery, gql } from "@apollo/client";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import CreateInvoiceForm from "./Form";

const FETCH_SO_DETAIL = gql`
  query FETCH_SO_DETAIL($id: Int!) {
    odooSoDetailSimple(id: $id) {
      id
      name
      partnerId
      partnerName
      partner {
        id
        street
        street2
        city
        zip
        countryId
        countryName
        vat
      }
      partnerShipping {
        id
        street
        street2
        city
        zip
        countryId
        countryName
        vat
      }
      dateOrder
      clientOrderRef
      incotermName
      amountUntaxed
      amountTax
      amountTotal
      currencyName
      lines: orderLines {
        id
        name
        priceUnit
        discount
        priceSubtotal
        priceTotal
        qty: productUomQty
        hsCode
        defaultCode
        weight
        product {
          id
          name
          number
          itemWeight
          ctnNetWeight
          ctnGrossWeight
          quantityPerCarton
          productLine {
            hsCodeForDestination(destination: "EU")
            hsCodeForEu
          }
        }
      }
    }
  }
`;

const OdooOrderDetail = ({ id }) => {
  const { loading, error, data } = useQuery(FETCH_SO_DETAIL, {
    variables: { id },
    skip: !id,
  });
  if (loading) return <Spinner text="Loading Odoo Order.." />;
  if (error) return <Errors error={error} />;

  const so = { ...data.odooSoDetailSimple };

  return <CreateInvoiceForm so={so} />;
};

export default OdooOrderDetail;
