import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Projects from './Projects';
import ProjectDetail from './ProjectDetail';

function ProductionPage() {
   return (
      <Routes>
         <Route path="/projects/:id" component={ProjectDetail} />
         <Route component={Projects} />
      </Routes>
   );
}

export default ProductionPage;
