import React from "react";
import { InlineSpinner } from "./Spinner";
import Errors from "./Errors";
import { Select } from "./Form";
import useFactories from "hooks/useFactories";

function FactorySelector({ value, onChange, ...rest }) {
  const { loading, error, allFactories } = useFactories();

  if (loading)
    return (
      <div className="flex relative">
        <InlineSpinner size={18} text={null} />
      </div>
    );
  if (error) return <Errors error={error} />;

  return (
    <Select value={value || ""} onChange={(e) => onChange(e.target.value || null)} {...rest}>
      <option value="">-- Select a Factory --</option>
      {allFactories.map((factory) => (
        <option key={factory.id} value={factory.id}>
          {factory.name}
        </option>
      ))}
    </Select>
  );
}

export default FactorySelector;
