import { AppContext } from "App";
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { BackLink } from "./BackButton";
import { useLocation } from "react-router-dom";

export const PageContext = createContext({});

function Page({
  className = "",
  childrenClassName = "flex-1 flex flex-col overflow-auto relative",
  leftButtons,
  rightButtons,
  backTo,
  title,
  subtitle = null,
  promot,
  children,
  ...rest
}) {
  const { windowWidth } = useContext(AppContext);
  const [offset, setOffset] = useState(0);
  const { state } = useLocation();

  const contentRef = useRef();
  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    if (contentRef.current) setContentHeight(contentRef.current.clientHeight);
  }, []);

  useEffect(() => {
    function scrollHandler() {
      setOffset(window.scrollY);
    }
    window.addEventListener("scroll", scrollHandler);
    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  const progress = offset / 120;
  const fontSize =
    backTo || windowWidth < 768 ? 21 : Math.max(32 * (1 - progress), 21);

  // build search params for url from state object

  let searchParams = null;
  console.log("state", state);
  if (state) {
    searchParams = new URLSearchParams();
    Object.keys(state).forEach((key) => {
      searchParams.append(key, state[key]);
    });
  }

  return (
    <PageContext.Provider value={{ contentHeight, setContentHeight }}>
      <div
        id="page"
        className={`flex flex-col flex-1 min-h-screen ${className}`}
        {...rest}
      >
        <nav className="bg-gray-50 dark:bg-gray-900 flex justify-between items-center border-b dark:border-gray-700 sticky top-0 backdrop-filter backdrop-blur-lg bg-opacity-60 dark:bg-opacity-80 z-20 py-3 overflow-auto flex-shrink-0">
          <div className={backTo ? "flex items-center" : ""}>
            <div className="flex items-center">
              {backTo ? (
                <div className="px-4 opacity-50 hover:opacity-100">
                  <BackLink
                    to={backTo + (searchParams ? "?" + searchParams : "")}
                  />
                </div>
              ) : null}
            </div>

            {title ? (
              <div className={backTo ? "" : "px-6"}>
                <div
                  className="font-bold"
                  style={{ fontSize, lineHeight: 1.5 }}
                >
                  {title}
                </div>
                {subtitle ? <div className="mt-1">{subtitle}</div> : null}
              </div>
            ) : null}

            {leftButtons ? (
              <div
                className={`pl-4 lg:pl-6 flex items-center whitespace-nowrap
               ${title ? " mt-1" : ""}
            `}
              >
                {leftButtons}
              </div>
            ) : null}
          </div>

          <div className="space-y-2">
            {promot ? promot : null}
            {rightButtons ? <div className="px-6">{rightButtons}</div> : null}
          </div>
        </nav>
        <div className={childrenClassName} ref={contentRef}>
          {children}
        </div>
      </div>
    </PageContext.Provider>
  );
}

export default Page;
