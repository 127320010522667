import React, { useState, useRef } from 'react';
import QRCode from 'qrcode.react';
import Page from 'components/Page';
import { Input } from 'components/Form';
import { Button } from 'components/base';
import { downloadSvg, downloadImageFromCanvas } from './utils';

const QRCodePage = () => {
   const [string, setString] = useState('https://waboba.com');
   const qrcodeContainerRef = useRef();

   return (
      <Page title="QRCode Generator" className="h-screen">
         <div className="p-4 lg:p-6 flex flex-1 items-center justify-center">
            <div className="w-64 md:w-1/3">
               <Input
                  autoFocus
                  placeholder="Input any string here and you will get a qrcode for it."
                  className="w-full text-center"
                  value={string}
                  onChange={(e) => setString(e.target.value)}
               />
               <div
                  ref={qrcodeContainerRef}
                  className="my-6 flex justify-center"
               >
                  <QRCode renderAs="svg" value={string} size={300} />
                  <QRCode
                     renderAs="canvas"
                     value={string}
                     size={300}
                     className="hidden"
                  />
               </div>
               <div className="mt-4 flex justify-center">
                  <Button
                     title="Download SVG"
                     onClick={() =>
                        downloadSvg(
                           qrcodeContainerRef.current.children[0],
                           string + '-shorten-link'
                        )
                     }
                  />
                  <Button
                     className="ml-4"
                     title="Download PNG"
                     onClick={() =>
                        downloadImageFromCanvas(
                           qrcodeContainerRef.current.children[1],
                           string + '-shorten-link',
                           'png'
                        )
                     }
                  />
                  <Button
                     className="ml-4"
                     title="Download JPG"
                     onClick={() =>
                        downloadImageFromCanvas(
                           qrcodeContainerRef.current.children[1],
                           string + '-shorten-link',
                           'jpg'
                        )
                     }
                  />
               </div>
            </div>
         </div>
      </Page>
   );
};

export default QRCodePage;
