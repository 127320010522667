import { useMutation } from "@apollo/client";
import CharlesButton from "components/charles/base";
import { Input } from "components/Form";
import { Alert } from "components/Toast";
import { useState } from "react";
import { CREATE_PRODUCT_LIST, FETCH_PRODUCT_LISTS } from "./graphql";

const CreateProductListView = ({ onCreated }) => {
  const [name, setName] = useState("");
  const [createProductList, createProductListRes] = useMutation(CREATE_PRODUCT_LIST, {
    variables: { name },
    onCompleted: (data) => {
      onCreated(data.createProductList.productList);
    },
    onError: (error) => {
      Alert("error", error.message);
    },
    refetchQueries: [{ query: FETCH_PRODUCT_LISTS }],
  });

  return (
    <div>
      <div className="flex items-center space-x-3">
        <label htmlFor="">Name: </label>
        <Input className="flex-1" value={name} onChange={(e) => setName(e.target.value)} />
      </div>

      <div className="mt-8">
        <CharlesButton primary loading={createProductListRes.loading} onClick={createProductList}>
          Create
        </CharlesButton>
      </div>
    </div>
  );
};

export default CreateProductListView;
