import React from "react";
import { DatePicker, Input, Select } from "components/Form";
import { ARCHIVE_CODES } from "pages/price/ArchivePriceForm";
import { useMutation } from "@apollo/client";
import { Alert } from "components/Toast";
import moment from "moment";
import FactorySelector from "components/FactorySelector";
import CharlesButton from "components/charles/base";
import { BsTrash } from "react-icons/bs";
import { DELETE_PRODUCT_PRICE } from "pages/price/graphql";

const ProductPrice = ({ price, showArchivedCost, onChange, onDelete }) => {
  const [deleteProductPrice, { loading }] = useMutation(DELETE_PRODUCT_PRICE, {
    onCompleted() {
      Alert("success", "Price deleted.");
    },
    onError(error) {
      Alert("error", error.message);
    },
  });

  if (!showArchivedCost && price.archivedAt) return null;

  let availableOptions = [];
  for (let i = 1; i <= 12; i++) {
    for (let j = 1; j <= 12; j++) {
      availableOptions.push(`${i} ~ ${j}`);
    }
  }

  const availableOptionsGroupsByMonth = availableOptions.reduce((acc, cur) => {
    const [from] = cur.split(" ~ ");
    const key = from;
    if (!acc[key]) acc[key] = [];
    acc[key].push(cur);
    return acc;
  }, {});

  const selectedAvailableOption = `${price.avaliableFromMonth} ~ ${price.avaliableToMonth}`;

  return (
    <tr>
      <td>
        <div className="flex space-x-2 items-center">
          <CharlesButton
            danger
            loading={loading}
            onClick={() => {
              if (price.id) {
                if (window.confirm("Are you sure to delete this price?")) deleteProductPrice({ variables: { id: price.id } });
              } else {
                onDelete();
              }
            }}
          >
            <BsTrash />
          </CharlesButton>
          <FactorySelector value={price.factoryId} onChange={(v) => onChange("factoryId", v)} />
        </div>
      </td>
      <td>
        <Input className="text-center w-20" value={price.minQty || 0} onChange={(e) => onChange("minQty", e.target.value)} />
      </td>
      <td>
        <Input className="text-center w-20" value={price.price || 0} onChange={(e) => onChange("price", e.target.value)} />
      </td>
      <td>
        <Select value={price.currency} onChange={(e) => onChange("currency", e.target.value)}>
          <option value="USD">USD</option>
          <option value="SEK">SEK</option>
          <option value="RMB">RMB</option>
        </Select>
      </td>

      <td>
        <div className="flex space-x-1 items-center">
          <Select
            value={selectedAvailableOption}
            onChange={(e) => {
              const [from, to] = e.target.value.split(" ~ ");
              onChange("avaliableFromMonth", from);
              onChange("avaliableToMonth", to);
            }}
          >
            {Object.keys(availableOptionsGroupsByMonth).map((i) => (
              <optgroup label={i} key={i}>
                {availableOptionsGroupsByMonth[i].map((j) => (
                  <option value={j} key={j}>
                    {j}
                  </option>
                ))}
              </optgroup>
            ))}
          </Select>
        </div>
      </td>

      <td className="">
        <DatePicker className="w-28" value={price.supplierUpdatedAt} onDayChange={(date) => onChange("supplierUpdatedAt", moment(date).format("YYYY-MM-DD"))} />
      </td>

      <td>
        <DatePicker className="w-28" value={price.archivedAt} onDayChange={(date) => onChange("archivedAt", date)} />
      </td>

      <td>
        <Select value={price.archivedCode} onChange={(e) => onChange("archivedCode", e.target.value)}>
          <option value="0">-- Select --</option>
          {ARCHIVE_CODES.map(({ code, description }) => (
            <option value={code} key={code}>
              {description}
            </option>
          ))}
        </Select>
      </td>

      <td>
        <Input className="text-center" value={price.archivedNotes || ""} onChange={(e) => onChange("archivedNotes", e.target.value)} />
      </td>
    </tr>
  );
};

export default ProductPrice;
