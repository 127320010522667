import Page from "components/Page";
import { useContext } from "react";
import { Link, Route, Routes } from "react-router-dom";
import { AmazonContext } from "..";
import StockCategoryDetail from "./StockCategoryDetail";

const StockRoutes = () => (
  <Routes>
    <Route>
      <Route index element={<StockCategories />} />
      <Route path=":id" element={<StockCategoryDetail />} />
    </Route>
  </Routes>
);

const StockCategories = () => {
  const { stockCategories } = useContext(AmazonContext);

  return (
    <div className="card px-4 py-2 m-6">
      <table>
        <tbody>
          {stockCategories.map((i, index) => (
            <tr className={`${index > 0 ? "border-t" : ""} dark:border-gray-700`} key={i.id}>
              <td>
                <Link to={i.id} className="block">
                  [{i.regions}] {i.name}
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default StockRoutes;
