import { useQuery } from "@apollo/client";
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Tooltip, LabelList, Legend } from "recharts";
import Card from "components/Card";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import { useModals } from "ModalProvider";
import ShipmentsView from "./ShipmentsView";
import { SHIPMENT_ANALYTICS_QUARTER_REPORT } from "./graphql";
import ShipmentErrorsView from "./ShipmentErrorsView";
import { useEffect, useState } from "react";
import DateRangeSlider from "components/DateRangeSlider";
import { Select } from "components/Form";
import DateRangePicker from "pages/analytics/DateRangePicker";
import { useDateRangeFrom } from "hooks/useDateRange";
import { formatDate } from "react-day-picker/moment";

function validAndHasStartProduction(shipment) {
  return (
    shipment.shipmentStatus.filter((i) => i.status === "in production").length > 0 &&
    shipment.shipmentStatus.filter((i) => ["canceled", "old", "-"].indexOf(i.status) > -1).length === 0
  );
}

function ReportPage() {
  const [group, setGroup] = useState("Q");
  const [{ start, end }, { setStart, setEnd }] = useDateRangeFrom(new Date(2022, 0, 1));

  const { loading, error, data } = useQuery(SHIPMENT_ANALYTICS_QUARTER_REPORT, {
    variables: {
      group: group,
      startDate: formatDate(start, "YYYY-MM-DD"),
      endDate: formatDate(end, "YYYY-MM-DD"),
    },
  });
  const modal = useModals();

  if (loading) return <Spinner />;
  if (error) return <Errors />;

  let shipmentsInProductionErrorsDelays = [];
  let shipments = data.shipmentList.filter(validAndHasStartProduction).map((shipment) => {
    const deliveredToPortStatus = shipment.shipmentStatus.find((i) => i.status === "delivered to port");
    return {
      ...shipment,
      productionStart: shipment.shipmentStatus.find((i) => i.status === "in production").created,
      productionEnd: deliveredToPortStatus ? deliveredToPortStatus.created : null,
    };
  });

  let countedShipments = [];
  data.shipmentQuarters.forEach((quarter) => {
    let quarterData = { ...quarter };
    delete quarterData.__typename;

    let quarterStartDate = new Date(quarter.year, (quarter.quarter - 1) * 3);
    let quarterEndDate = new Date(quarter.year, quarterStartDate.getMonth() + 3, 0);

    quarterStartDate = quarterStartDate.getTime() / 1000;
    quarterEndDate = quarterEndDate.getTime() / 1000;

    let ordersCount = 0;

    for (let i = 0; i < shipments.length; i++) {
      let shipment = shipments[i];
      if (shipment.productionStart < quarterEndDate) {
        if (shipment.productionEnd === null || shipment.productionEnd >= quarterStartDate) {
          ordersCount += 1;
          countedShipments.push(shipment.id);
        }
      }
    }

    let errorsForThisQuarter = data.shipmentErrorsByQuarter.find((i) => i.year === quarter.year && i.group === quarter.quarter);

    let errorsWithDelayForThisQuarter = data.shipmentErrorsWithDelayByQuarter.find((i) => i.year === quarter.year && i.group === quarter.quarter);

    quarterData = {
      year: quarter.year,
      quarter: quarter.quarter,
      ordersCount,
      errorCount: errorsForThisQuarter ? errorsForThisQuarter.value : 0,
      delayCount: errorsWithDelayForThisQuarter ? errorsWithDelayForThisQuarter.errorCount : 0,
    };
    shipmentsInProductionErrorsDelays.push(quarterData);
  });

  const shipment2023Goals = JSON.parse(data.shipment2023Goals);

  function onClickAveDelayDaysBarHandler(e) {
    modal.present({
      title: e.name,
      subtitle: `Average Delay Days: ${e.value}`,
      children: <ShipmentsView shipments={e.shipments} type="AVE_DELAY_DAYS" />,
    });
  }

  function onClickOnTimeDeliveryBarHandler(e) {
    modal.present({
      title: e.name,
      subtitle: "On Time Delivery",
      children: <ShipmentsView shipments={e.shipments} type="ON_TIME_DELIVERY" />,
    });
  }

  function onClickFullContainerLoadBarHandler(e) {
    modal.present({
      tile: e.name,
      subtitle: "Full Container Load",
      children: <ShipmentsView shipments={e.shipments} type="FULL_CONTAINER_LOAD" />,
    });
  }

  function onClickAveProductionDaysBarHandler(e) {
    modal.present({
      title: e.name,
      subtitle: `Average Production Days: ${e.value}`,
      children: <ShipmentsView shipments={e.shipments} type="AVE_PRODUCTION_DAYS" />,
    });
  }

  function onClickDeliveredToPortBarHandler(e) {
    modal.present({
      title: e.name,
      subtitle: `Delivered to Port: ${e.value} shipments`,
      children: <ShipmentsView shipments={e.shipments} type="DELIVERED_TO_PORT" />,
    });
  }

  return (
    <div>
      <div className="p-6">
        <div className="mb-4 flex space-x-4 items-center">
          <DateRangePicker start={start} end={end} setStart={setStart} setEnd={setEnd} />

          <Select value={group} onChange={(e) => setGroup(e.target.value)}>
            <option value="M">by Month</option>
            <option value="Q">by Quarter</option>
            <option value="Y">by Year</option>
          </Select>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-4 lg:gap-x-6 lg:gap-y-6">
          <ReportChart
            title="Average Delay Days"
            subtitle="Shipments are grouped base on their `delivered to port` date."
            data={shipment2023Goals.delay_days.map((i) => ({
              ...i,
              name: i.group,
              value: i.ave_delay_days,
            }))}
            fillColor="#f687b3"
            valueName="Days"
            onClick={onClickAveDelayDaysBarHandler}
          />

          <ReportChart
            title="Average Production Days"
            subtitle="Shipments are grouped base on their `delivered to port` date."
            data={shipment2023Goals.production_days.map((i) => ({
              ...i,
              name: i.group,
              value: i.ave_production_days,
            }))}
            fillColor="#68D391"
            valueName="Days"
            onClick={onClickAveProductionDaysBarHandler}
          />

          <ReportChart
            title="On Time Delivery Rate"
            subtitle="Shipments are grouped base on their `delivered to port` date."
            data={shipment2023Goals.on_time_delivery.map((i) => ({
              ...i,
              name: i.group,
              value: i.rate,
            }))}
            fillColor="#3b82f6"
            valueName="Rate %"
            onClick={onClickOnTimeDeliveryBarHandler}
          />

          <ReportChart
            title="Full Container Load"
            subtitle="Shipments are grouped base on their `delivered to port` date."
            data={shipment2023Goals.container_load.map((i) => ({
              ...i,
              name: i.group,
              value: i.avg_full_container_load,
            }))}
            fillColor="#84cc16"
            valueName="Rate %"
            onClick={onClickFullContainerLoadBarHandler}
          />

          <ReportChart
            width="w-full"
            title="Delivered to Port"
            data={shipment2023Goals.shipments.map((i) => ({
              ...i,
              name: i.group,
              value: i.total_shipments,
            }))}
            fillColor="#9333ea"
            valueName="Shipments"
            onClick={onClickDeliveredToPortBarHandler}
            className="lg:col-span-2 "
          />
        </div>

        <div className="mt-4">
          {/* a problem with the statistical results, should be grouped according to "delivered to port" status creation time.  */}
          <QuarterCombinedReport title="Orders in Production, Errors and Delays" data={shipmentsInProductionErrorsDelays} />
        </div>
      </div>

      <div className="p-6 relative">
        <ShipmentErrorsView />
      </div>
    </div>
  );
}

const QuarterCombinedReport = ({ title, data }) => {
  const [dateRange, setDateRange] = useState([0, data.length - 1]);

  const chartData = data.map((i) => ({
    ...i,
    name: `${i.year} - Q${i.quarter}`,
  }));

  const filteredData = chartData.slice(dateRange[0], dateRange[1] + 1);

  return (
    <div className="w-full">
      <Card title={title}>
        <ResponsiveContainer className="mt-8" width="100%" height={200}>
          <BarChart data={filteredData} stackOffset="silhouette" margin={{ top: 20, right: 5, bottom: 0, left: 0 }}>
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip cursor={{ fill: "#f5f5f5" }} />
            <Legend />
            <Bar dataKey="ordersCount" fill="#4299E1" name="Orders">
              <LabelList dataKey="ordersCount" position="top" />
            </Bar>
            <Bar dataKey="errorCount" fill="#F6AD55" name="Errors">
              <LabelList dataKey="errorCount" position="top" />
            </Bar>
            <Bar dataKey="delayCount" fill="#FC8181" name="Delay">
              <LabelList dataKey="delayCount" position="top" />
            </Bar>
          </BarChart>
        </ResponsiveContainer>

        <DateRangeSlider min={0} max={data.length - 1} onChange={setDateRange} />
      </Card>
    </div>
  );
};

const ReportChart = ({ title, subtitle, valueName, data, fillColor, width = "w-full", onClick, className }) => {
  const [dateRange, setDateRange] = useState([0, data.length - 1]);
  const [activeAnimation, setActiveAnimation] = useState(true);

  useEffect(() => {
    setDateRange([0, data.length - 1]);
  }, [data]);

  useEffect(() => {
    // Only animate the first time
    if (activeAnimation) {
      setTimeout(() => {
        setActiveAnimation(false);
      }, 500);
    }
  }, []);

  const filteredData = data.slice(dateRange[0], dateRange[1] + 1);

  return (
    <div className={`${width} ${className}`}>
      <Card title={title} subtitle={subtitle}>
        <ResponsiveContainer width="100%" height={200}>
          <BarChart data={filteredData} stackOffset="silhouette" margin={{ top: 20, right: 0, bottom: 0, left: -30 }}>
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip cursor={{ fill: "#f5f5f5" }} />
            <Bar
              key={Date.now()}
              dataKey="value"
              maxBarSize={40}
              minBarSize={10}
              fill={fillColor}
              name={valueName}
              onClick={onClick ? onClick : null}
              className={onClick ? "cursor-pointer" : ""}
              isAnimationActive={activeAnimation}
            >
              <LabelList dataKey="value" position="top" />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
        <DateRangeSlider key={data.length} min={0} max={data.length - 1} onChange={setDateRange} />
      </Card>
    </div>
  );
};

export default ReportPage;
