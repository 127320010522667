import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { IoIosCloseCircle } from 'react-icons/io';
import InputSelect from './InputSelect';
import { Input } from './Form';
import { InlineSpinner } from './Spinner';
import Errors from './Errors';
import { Alert } from './Toast';
import { Button } from './base';

const FETCH_INVOICES = gql`
   query FETCH_INVOICES($id: ID!) {
      customer(id: $id) {
         id
         invoices {
            id
            number
            pos {
               id
               number
            }
         }
      }
   }
`;

const CREATE_INVOICE = gql`
   mutation CREATE_INVOICE($customerId: ID!, $number: String!, $pos: [String]) {
      createInvoice(customerId: $customerId, number: $number, pos: $pos) {
         invoice {
            id
            number
            pos {
               id
               number
            }
         }
      }
   }
`;

const FETCH_CUSTOMER_INVOICES = gql`
   query fetchCustomerInvoices($id: ID!) {
      customer(id: $id) {
         id
         name
         invoices(status: "NEW") {
            id
            number
            pos {
               id
               number
            }
            po
         }
      }
   }
`;

const InvoiceForm = ({
   className = '',
   customerId = null,
   shipmentId = null,
   onChangeSoPos,
   hideControls = false,
   saveHandler,
   onSave,
   onCancel,
   initialSo = '',
   initialPos = [''],
   editNumbersOnly = false,
}) => {
   const [so, setSo] = useState(initialSo);
   const [pos, setPos] = useState(initialPos);
   const [justAddedPo, setJustAddedPo] = useState(false);
   const [createInvoice, createInvoiceRes] = useMutation(CREATE_INVOICE, {
      onCompleted: (data) => {
         onSave(data.createInvoice);
      },
      onError: (error) => Alert('error', error.message),
      refetchQueries: [
         { query: FETCH_CUSTOMER_INVOICES, variables: { id: customerId } },
      ],
   });

   const { loading, error, data } = useQuery(FETCH_INVOICES, {
      variables: { id: customerId },
      skip: customerId === null,
   });

   useEffect(() => {
      if (so && data && data.customer && data.customer.invoices) {
         const foundSo = data.customer.invoices.find((i) => i.number === so);
         if (foundSo) {
            const foundPos = foundSo.pos.map((p) => p.number);
            setPos(foundPos.length > 0 ? foundPos : ['']);
         } else {
            setPos(['']);
         }
      }
   }, [so, data]);

   function onChangeSo(newSo) {
      setSo(newSo);
      if (customerId === null) return;
      let newPos = [''];
      const foundSo = data.customer.invoices.find((i) => i.number === newSo);
      if (foundSo) {
         const foundPos = foundSo.pos.map((p) => p.number);
         if (foundPos.length > 0) newPos = foundPos;
      }
      setPos(newPos);
      if (onChangeSoPos) onChangeSoPos(newSo, newPos);
   }

   function onChangePos(newPos) {
      setPos(newPos);
      if (onChangeSoPos) onChangeSoPos(so, newPos);
   }

   if (loading)
      return (
         <div>
            <InlineSpinner />
         </div>
      );
   if (error) return <Errors error={error} />;

   const selectOptions = data
      ? data.customer.invoices.map((i) => ({ ...i, name: i.number }))
      : [];

   return (
      <div className={className}>
         <div className="flex items-center">
            <label className="w-12 mr-4">SO:</label>
            <InputSelect
               className="flex-1"
               value={so}
               options={selectOptions}
               onSelect={(invoice) => onChangeSo(invoice.number)}
               onChange={onChangeSo}
            />
         </div>
         <div className="flex items-baseline mt-2">
            <label className="w-12 mr-4">PO:</label>
            <div className="flex flex-col flex-1">
               {pos.map((po, poIndex) => (
                  <div
                     className="flex items-center justify-between mb-2"
                     key={poIndex}
                  >
                     <Input
                        className="w-full"
                        placeholder="Optional"
                        autoFocus={justAddedPo}
                        value={po}
                        onChange={(e) => {
                           const newPO = e.target.value;
                           onChangePos(
                              pos.map((prevPo, prevPoIndex) => {
                                 if (poIndex !== prevPoIndex) return prevPo;
                                 return newPO;
                              })
                           );
                        }}
                     />
                     {pos.length > 1 && editNumbersOnly === false && (
                        <div
                           className="cursor-pointer ml-4 text-red-500 hover:text-red-600 active:text-red-700 flex justify-end"
                           onClick={() => {
                              onChangePos(
                                 pos.filter(
                                    (_, prevPoIndex) => prevPoIndex !== poIndex
                                 )
                              );
                           }}
                        >
                           <IoIosCloseCircle size={21} />
                        </div>
                     )}
                  </div>
               ))}
               {editNumbersOnly ? null : (
                  <div className="px-2">
                     <Button
                        title="+ add PO"
                        size="sm"
                        onClick={() => {
                           setJustAddedPo(true);
                           onChangePos([...pos, '']);
                        }}
                     ></Button>
                  </div>
               )}
            </div>
         </div>

         {hideControls ? null : (
            <div className="mt-4 flex">
               <Button
                  title="Save"
                  bold
                  disabled={createInvoiceRes.loading}
                  loading={createInvoiceRes.loading}
                  color="blue"
                  onClick={(_) =>
                     saveHandler
                        ? saveHandler(so, pos)
                        : createInvoice({
                             variables: {
                                customerId,
                                shipmentId,
                                number: so,
                                pos,
                             },
                          })
                  }
               />
               {onCancel ? (
                  <Button
                     disabled={createInvoiceRes.loading}
                     className="ml-4"
                     title="Cancel"
                     color="gray"
                     size="sm"
                     onClick={onCancel}
                  />
               ) : null}
            </div>
         )}
      </div>
   );
};

export default InvoiceForm;
