import { SCORE_COLORS } from "./const";

const AssessmentSummaryView = ({ assessment }) => {
  const scores = ["A", "B", "C", "D"].map((score) => {
    const questions = assessment.questions.edges.map((i) => i.node);
    const count = questions.filter((q) => q.score === score).length;
    return { score, count };
  });

  const questionsWithoutScore = assessment.questions.edges.filter((i) => !i.node.score);
  const questionsRequiredRectification = assessment.questions.edges.filter((i) => i.node.requiredRectification);
  const questionsReceivedRectification = assessment.questions.edges.filter((i) => i.node.rectifications.edges.length > 0);
  const questionsFollowUpNextTime = assessment.questions.edges.filter((i) => i.node.followUpNextTime);

  return (
    <div>
      <div className="flex space-x-4 text-lg">
        {scores.map((i) => (
          <div key={i.score} className="">
            <div className="flex-1 flex space-x-1 items-center">
              <div className={`${SCORE_COLORS[i.score]} font-bold`}>{i.score}</div>
              <div className=" opacity-70">&times; {i.count}</div>
            </div>
          </div>
        ))}
      </div>

      {questionsWithoutScore.length > 0 ? (
        <div className="mt-2">
          <b>{questionsWithoutScore.length}</b> questions without score. Set score for them otherwise we will ignore these questions.
        </div>
      ) : null}

      {questionsRequiredRectification.length > 0 ? (
        <div className="mt-2">
          <b>{questionsRequiredRectification.length}</b> questions required rectification.
        </div>
      ) : null}

      {questionsReceivedRectification.length > 0 ? (
        <div className="mt-2">
          <b>{questionsReceivedRectification.length}</b> questions received rectification.
        </div>
      ) : null}

      {questionsFollowUpNextTime.length > 0 ? (
        <div className="mt-2">
          <b>{questionsFollowUpNextTime.length}</b> questions follow up next time.
        </div>
      ) : null}

      <div className="text-xl mt-3">
        Total Score: <b className={SCORE_COLORS[assessment.totalScore.scoreText]}>{assessment.totalScore.scoreText}</b> {assessment.totalScore.score}
      </div>
    </div>
  );
};

export default AssessmentSummaryView;
