import { NavigationLink } from 'components/base';
import Page from 'components/Page';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import ShipmentDetail from './ShipmentDetail';
import Shipments from './Shipments';

const ExpeditorPage = () => {
   return (
      <Routes>
         <Route element={<Layout />}>
            <Route path="shipments" element={<Shipments />} />
            <Route path="shipments/:id" element={<ShipmentDetail />} />
            <Route path="*" element={<Navigate to="shipments" />} />
         </Route>
      </Routes>
   );
};

const Layout = () => (
   <Page
      className="lg:h-screen"
      leftButtons={
         <div className="flex space-x-4">
            <NavigationLink to="shipments" title="Shipments" />
         </div>
      }
   >
      <Outlet />
   </Page>
);

export default ExpeditorPage;
