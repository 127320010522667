import moment from "moment";
import { showCurrencySymbol } from "utils/string";

function buildReport(plans, products) {
  let res = [["Product", "Ready Date", "QTY", "Odoo Stock", "Supplier Stock", "To Produce", "Cost"]];
  products.forEach((product) => {
    console.log("product", product);
    res.push([
      `[${product.product_number}] ${product.product_name}`,
      "",
      product.plansQty,
      product.odoo_stock,
      product.supplier_stock,
      product.toProduce,
      product ? `${showCurrencySymbol(product.cost.currency)} ${product.cost.price.toFixed(3)}` : 0,
    ]);
    product.plans.forEach((plan) => {
      res.push([`- ${plan.name}`, moment(plan.readyDate).format("YYYY-MM-DD"), plan.qty, "", "", ""]);
    });
  });

  res.push([]);
  res.push(["Plans"]);
  plans.forEach((plan) => {
    res.push([plan.name]);
  });

  return res;
}

export default buildReport;
