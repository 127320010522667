import { Fragment, useState } from "react";
import { formatDate } from "react-day-picker/moment";
import { ButtonWithArrow } from "components/base";
import moment from "moment";
import { BsArrowRight, BsInfoCircle } from "react-icons/bs";
import { IoIosCube } from "react-icons/io";
import Errors from "components/Errors";
import { InlineSpinner } from "components/Spinner";
import { gql, useQuery } from "@apollo/client";
import ExtraComputeString from "./ExtraComputeString";
import { useModals } from "ModalProvider";
import ProductionDatesView from "./ProductionDatesView";
import CharlesButton from "components/charles/base";
import { isEmpty } from "pages/tools/utils";

const FETCH_PLAN_DATA = gql`
  query FETCH_PLAN_DATA($id: ID!) {
    productionPlan(id: $id) {
      id
      data
    }
  }
`;

const ProductionsView = ({ planId }) => {
  const { loading, error, data } = useQuery(FETCH_PLAN_DATA, {
    variables: { id: planId },
    fetchPolicy: "network-only",
  });

  const modal = useModals();

  function showProductionDates(production) {
    modal.present({
      title: "Production Dates",
      center: true,
      maxWidth: "max-w-3xl",
      children: <ProductionDatesView production={production} />,
    });
  }

  if (loading)
    return (
      <div className="p-20">
        <InlineSpinner />
      </div>
    );
  if (error) return <Errors error={error} />;

  const { productions } = JSON.parse(data.productionPlan.data);

  return (
    <div>
      <h4>Inventory, Purchase and Manufacturing</h4>

      <div className="space-y-4 mt-4 -mx-2">
        {productions.length > 0 ? (
          <table>
            <thead>
              <tr className="whitespace-nowrap">
                <th className="py-3 text-left">Production</th>
                <th className="py-3 text-right">Request QTY</th>
                <th className="py-3 text-right">Prev Plans Consumed</th>
                <th className="py-3 text-right">Using Stock</th>
                <th className="py-3 text-right">To Consumed</th>
                <th className="py-3 text-right">To Produce</th>
              </tr>
            </thead>
            <tbody>
              {productions.map((production, index) => (
                <ProductionSection key={index} index={index} production={production} showProductionDates={showProductionDates} />
              ))}
            </tbody>
          </table>
        ) : (
          <div>No purchase or manufacturing. Make sure each product in orders has correct bom and production nodes.</div>
        )}
      </div>
    </div>
  );
};

export const ProductionSection = ({ index, production, showProductionDates }) => {
  const [showPrevPos, setShowPrevPos] = useState(false);

  console.log("production", index + 1, production);

  return (
    <Fragment>
      <tr>
        <td
          colSpan={7}
          className={`p-2
                    ${production.production_time < 0 ? "bg-pink-200 text-pink-600" : "bg-gray-100 dark:bg-gray-900 dark:bg-opacity-80"}
                 `}
        >
          <div>
            <div className="flex items-center space-x-3">
              <div className="font-semibold">
                {index + 1}. {production.node.category.name} - {production.node.category.supplier.name}{" "}
                {production.node.category.time_string
                  ? `[
                       ${production.node.category.time_string}]`
                  : null}{" "}
                &times; {production.to_produce}
                {production.production_time < 0 ? " - Warning: Qty overflow!!" : null}
              </div>
            </div>

            {production.node.category.extra_computings && production.node.category.extra_computings.length > 0 ? (
              <div>
                <label className="pr-2">Exra Computings:</label>
                {production.node.category.extra_computings.map((i) => (
                  <span key={i.id} className="mr-2">
                    <span>{i.name}: </span>
                    <span>
                      <ExtraComputeString type={i.type} value={i.value} />
                    </span>
                  </span>
                ))}
              </div>
            ) : null}
          </div>
        </td>
      </tr>

      {production.items.length === 0 ? (
        <tr>
          <td>No products in this node.</td>
        </tr>
      ) : (
        production.items.map((item, itemIndex) => (
          <tr key={itemIndex}>
            <td className="px-2 py-1">
              <div className="space-x-2 flex items-center">
                <span>
                  [{item.wis_product.number}] {item.wis_product.name}{" "}
                </span>
                {item.has_odoo_stock ? <IoIosCube className="text-green-600 text-base" /> : null}

                {item.supplier_stock ? (
                  <div className="flex items-center space-x-2">
                    <IoIosCube className="text-orange-600 text-base" />
                    <span>{item.supplier_stock.latest_qty}</span>
                    {item.supplier_stock.ready_date && item.supplier_stock.latest_qty > 0 ? (
                      <span>[{moment(item.supplier_stock.ready_date).format("YYYY-MM-DD")}]</span>
                    ) : null}
                  </div>
                ) : null}
              </div>
            </td>
            <td className="text-right px-2 py-1">{item.qty}</td>
            <td className="text-right px-2 py-1 whitespace-nowrap">
              {item.prev_plan_consumed && item.prev_plan_consumed.length > 0 ? item.prev_plan_consumed.reduce((res, i) => res + i.qty, 0) : 0}
            </td>
            <td className="text-right px-2 py-1 whitespace-nowrap">
              {item.batch ? (
                <div className="flex space-x-2 items-center justify-end text-xs">
                  {/* <CharlesButton onClick={() => showProductionItemDetail(item)}>{item.batch.po.name}</CharlesButton> */}
                  <span>{item.batch.po.name}</span>
                  <span className="opacity-50">{moment(item.batch.po.date).format("YYYY-MM-DD")}</span>
                  <span>{item.batch_left}</span>
                </div>
              ) : (
                "-"
              )}
            </td>
            <td className="text-right px-2 py-1">{item.to_consume}</td>
            <td className="text-right px-2 py-1">{item.to_produce}</td>
          </tr>
        ))
      )}

      <tr>
        <td className="p-2 pb-4" colSpan={7}>
          <div className="flex items-baseline space-x-4">
            <div>
              {production.prev_batch ? (
                <div className="text-green-600 font-semibold">
                  Use Previous {production.prev_batch.name} {formatDate(production.prev_batch.date * 1000, "YYYY-MM-DD")}
                </div>
              ) : null}

              <div className="flex items-baseline space-x-2">
                <div className="text-teal-600 font-semibold text-base flex items-center space-x-2">
                  <span>{moment(production.production_start_date).format("YYYY-MM-DD")}</span>
                  <BsArrowRight />
                  <span>{moment(production.ready_date).format("YYYY-MM-DD")} </span>
                </div>
              </div>

              {production.to_produce > 0 ? (
                <div className="flex items-center space-x-2">
                  <div className="space-x-2 opacity-50">
                    <span>Production: {production.production_time} days</span>
                    {production.transfer_time > 0 ? (
                      <>
                        <span>+</span>
                        <span>Transfer: {production.transfer_time} days</span>
                      </>
                    ) : null}
                  </div>
                  {!isEmpty(production.production_dates_with_qty) && (
                    <CharlesButton onClick={() => showProductionDates(production)}>
                      <BsInfoCircle />
                    </CharlesButton>
                  )}
                </div>
              ) : null}
            </div>

            {production.prev_pos ? (
              <ButtonWithArrow className="text-sky-600" show={showPrevPos} onClick={() => setShowPrevPos((prev) => !prev)}>
                {showPrevPos ? "Hide" : "Show"} Current Production
              </ButtonWithArrow>
            ) : null}
          </div>
        </td>
      </tr>

      {showPrevPos ? (
        <tr>
          <td colSpan={6}>
            <div className="flex">
              <div className="bg-gray-100 dark:bg-gray-900 dark:bg-opacity-50 rounded-2xl p-4 mb-4">
                <div className="px-2">Total qty in PO not confirmed for this production category by month.</div>
                <div className="flex">
                  <div className="mt-2">
                    {production.prev_pos.map((po) => (
                      <div key={po.id} className="text-xs opacity-70 flex items-center justify-between border-b dark:border-gray-700 space-x-8">
                        <div className="px-2 py-1 ">
                          {po.year} - {po.month}
                        </div>

                        <div className="px-2 py-1">{po.assignedQty}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      ) : null}
    </Fragment>
  );
};

export default ProductionsView;
