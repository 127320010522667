import { gql, useQuery } from '@apollo/client';
import Errors from 'components/Errors';
import Page from 'components/Page';
import Spinner from 'components/Spinner';

const FETCH_SUPPLIER_INVENTORY = gql`
   query FETCH_SUPPLIER_INVENTORY {
      me {
         supplier {
            supplierStocks {
               id
               latestQty
               product {
                  id
                  number
                  name
               }
            }
         }
      }
   }
`;

const Inventory = () => {
   const { loading, error, data } = useQuery(FETCH_SUPPLIER_INVENTORY);

   if (loading) return <Spinner />;
   if (error) return <Errors error={errors} />;

   const stocks = data.me.supplier.supplierStocks;

   return (
      <Page title="库存">
         <div className="px-6 py-4">
            <div className="-mx-2">
               <table>
                  <thead>
                     <tr>
                        <th>Product / 产品</th>
                        <th className="text-right">QTY / 数量</th>
                     </tr>
                  </thead>
                  <tbody>
                     {stocks.map((i) => (
                        <tr key={i} className="border-b">
                           <td>{i.product.name}</td>
                           <td className="text-right">{i.latestQty}</td>
                        </tr>
                     ))}
                  </tbody>
               </table>
            </div>
         </div>
      </Page>
   );
};

export default Inventory;
