import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { formatDate } from 'react-day-picker/moment';
import randomcolor from 'randomcolor';
import {
   ResponsiveContainer,
   LineChart,
   Line,
   XAxis,
   YAxis,
   Tooltip,
} from 'recharts';
import Card from '../../../../components/Card';
import Spinner from '../../../../components/Spinner';
import Errors from '../../../../components/Errors';
import { BizAnalyticsContext } from '..';

const FETCH_ACTION_BY_DATE = gql`
   query FETCH_ACTION_BY_DATE($start: DateTime!, $end: DateTime!) {
      actionsTotalByDate(start: $start, end: $end, host: "biz") {
         date
         actions: value
      }
   }
`;

function ActionAnalytics() {
   const { start, end } = useContext(BizAnalyticsContext);
   const { loading, error, data } = useQuery(FETCH_ACTION_BY_DATE, {
      variables: { start, end },
   });
   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;
   if (!data) return null;

   var dateRange = [];
   let gap = (end - start) / (1000 * 3600 * 24 * 20);
   for (var d = new Date(start); d <= end; d.setDate(d.getDate() + gap)) {
      dateRange.push(formatDate(new Date(d), 'YYYY-MM-DD'));
   }
   const transformedData = dateRange.map((d) => {
      let a = data.actionsTotalByDate.find((i) => d === i.date);
      return {
         date: d,
         actions: a ? a.actions : 0,
      };
   });
   const color = randomcolor({ luminosity: 'dark' });
   return (
      <Card title="All Actions Overview">
         <ResponsiveContainer aspect={3.5}>
            <LineChart
               data={transformedData}
               margin={{ top: 30, right: 30, left: 0, bottom: 0 }}
            >
               <XAxis dataKey="date" name="date" />
               <YAxis dataKey="actions" width={30} />
               <Tooltip />
               <Line
                  dot={false}
                  strokeWidth={2}
                  type="basis"
                  dataKey="actions"
                  stroke={color}
               />
            </LineChart>
         </ResponsiveContainer>
      </Card>
   );
}

export default ActionAnalytics;
