import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import OdooMarginPage from "./OdooMarginPage";
import Page from "components/Page";
import ProductMarginPage from "./product";
import { NavigationLink } from "components/base";
import { useContext } from "react";
import { AppContext } from "App";
import { ODOO_MARGIN_EDITOR } from "utils/permissions";

const OdooAnalyticsPages = () => {
  const { hasPermission } = useContext(AppContext);

  if (!hasPermission(ODOO_MARGIN_EDITOR))
    return (
      <div className="flex flex-1 items-center justify-center font-bold text-2xl h-screen text-gray-600">
        Not Allowed.
      </div>
    );

  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="invoice" element={<OdooMarginPage />} />
        <Route path="product" element={<ProductMarginPage />} />
        <Route path="*" element={<Navigate to="invoice" />} />
      </Route>
    </Routes>
  );
};

const Layout = () => (
  <Page
    className="h-screen"
    leftButtons={
      <div className="space-x-6">
        <NavigationLink to="./invoice">Invoice Margin</NavigationLink>
        <NavigationLink to="./product">Product Margin</NavigationLink>
      </div>
    }
  >
    <Outlet />
  </Page>
);

export default OdooAnalyticsPages;
