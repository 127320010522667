import { gql, useMutation, useQuery } from '@apollo/client';
import { NavigationLink } from 'components/base';
import Page from 'components/Page';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import BizBeautyshots from './biz';
import OneDrive from './OneDrive';

const BeautyshotsRoutes = () => {
   return (
      <Routes>
         <Route element={<Layout />}>
            <Route path="biz" element={<BizBeautyshots />} />
            <Route path="one-drive" element={<OneDrive />} />
            <Route path="*" element={<Navigate to="biz" />} />
         </Route>
      </Routes>
   );
};

const Layout = () => (
   <Page
      leftButtons={
         <div className="flex space-x-4">
            <NavigationLink to="/materials/beautyshots/biz" title="BIZ" />
            <NavigationLink
               to="/materials/beautyshots/one-drive"
               title="One Drive"
            />
         </div>
      }
   >
      <Outlet />
   </Page>
);

export default BeautyshotsRoutes;
