import React, { useState, useContext } from 'react';
import { useMutation } from '@apollo/client';
import { formatDate } from 'react-day-picker/moment';
import { Button, Tag } from '../../../../components/base.js';
import ShipmentErrorComponent from '../../../../components/ShipmentError.js';
import ShipmentDelay from '../../../../components/ShipmentDelay.js';
import NotifyCustomerErrorForm from './NotifyCustomerErrorForm';
import Image from '../../../../components/Image';
import ShipmentErrorForm from './ShipmentErrorForm';
import ShipmentContext from '../ShipmentContext';
import track, { actions } from '../../../../utils/track';

import { DELETE_SHIPMENT_ERROR } from '../../graphql';

function ShipmentError({ shipmentStatusId, shipmentStatusError }) {
   const { state, dispatch } = useContext(ShipmentContext);
   const [showErrorProducts, setShowErrorProducts] = useState(
      shipmentStatusError.shipmentStatusErrorProductRows.length < 3
   );
   const [isShowNotifyCustomerErrorForm, setIsShowNotifyCustomerErrorForm] =
      useState(false);
   const [editingShipmentError, setEditingShipmentError] = useState(null);

   const [deleteShipmentError, { loading }] = useMutation(
      DELETE_SHIPMENT_ERROR,
      {
         onCompleted: ({ deleteShipmentError }) => {
            dispatch({
               type: 'deleteShipmentStatusError',
               payload: {
                  shipmentStatusId,
                  shipmentStatusErrorId: deleteShipmentError.id,
                  shipment: deleteShipmentError.shipment,
               },
            });
         },
      }
   );

   if (editingShipmentError)
      return (
         <div className="mt-4 lg:mt-6">
            <ShipmentErrorForm
               editingShipmentStatusError={shipmentStatusError}
               shipmentStatusId={shipmentStatusId}
               hideForm={(_) => setEditingShipmentError(null)}
            />
         </div>
      );

   return (
      <div className="box">
         <div>
            <div className="lg:flex justify-between items-start">
               <div>
                  <div className="text-gray-600">
                     {formatDate(
                        shipmentStatusError.created * 1000,
                        'YYYY-MM-DD'
                     )}
                  </div>
                  <ShipmentErrorComponent
                     prefix="Error "
                     className="mt-2"
                     code={shipmentStatusError.error.code}
                     description={shipmentStatusError.error.description}
                  />
               </div>
               <div className="mt-2 lg:mt-0 text-right flex">
                  <Button
                     bold={false}
                     className="mr-4"
                     size="sm"
                     title="edit error"
                     onClick={(_) =>
                        setEditingShipmentError(shipmentStatusError)
                     }
                  />
                  <Button
                     bold={false}
                     loading={loading}
                     title={loading ? 'deleting...' : 'delete error'}
                     size="sm"
                     color="red"
                     onClick={(_) => {
                        if (
                           window.confirm(
                              `Are you sure you want to delete this shipment error?`
                           )
                        ) {
                           deleteShipmentError({
                              variables: { id: shipmentStatusError.id },
                           });
                           track(actions.shipment.deleteShipmentError.name);
                        }
                     }}
                  />
               </div>
            </div>

            {shipmentStatusError.notes ? (
               <div className="whitespace-pre-wrap mt-2">
                  <label htmlFor="">Notes: </label>
                  {shipmentStatusError.notes}
               </div>
            ) : null}
         </div>

         {shipmentStatusError.hasDelay ? (
            <div className="mt-6 rounded-lg p-4 bg-gray-100 dark:bg-gray-800">
               <ShipmentDelay days={shipmentStatusError.delayDays} />
            </div>
         ) : null}

         {shipmentStatusError.shipmentStatusErrorFactories.length > 0 && (
            <div className="mt-1 lg:mt-4 flex flex-wrap">
               {shipmentStatusError.shipmentStatusErrorFactories.map((i) => (
                  <Tag
                     className="text-xs mr-2 mb-2"
                     title={i.factory.nickName}
                     key={i.id}
                  />
               ))}
            </div>
         )}

         {shipmentStatusError.shipmentStatusErrorProductRows.length > 0 ? (
            <div className="mt-2 lg:mt-4">
               <Button
                  title={
                     showErrorProducts
                        ? 'Hide error products'
                        : 'Show error products'
                  }
                  size="sm"
                  onClick={() => setShowErrorProducts((prev) => !prev)}
               ></Button>
               {showErrorProducts ? (
                  <div className="pt-2 lg:p-4 mt-2 lg:mt-4 bg-gray-100 dark:bg-gray-800 rounded-lg">
                     <div className="text-base font-bold">Products</div>
                     <div className="mt-1 lg:mt-4">
                        {shipmentStatusError.shipmentStatusErrorProductRows.map(
                           (row) => (
                              <div
                                 className="border-t border-gray-200 dark:border-gray-700 py-2"
                                 key={row.id}
                              >
                                 <div className="lg:flex justify-between text-xs">
                                    <div className="flex items-center">
                                       <div className="flex items-center">
                                          {row.shipmentInvoiceRow.product.images.map(
                                             (i, index) => (
                                                <Image
                                                   size="h-12"
                                                   className="mr-2"
                                                   src={i.thumb}
                                                   key={index}
                                                />
                                             )
                                          )}
                                       </div>
                                       <div>
                                          {row.shipmentInvoiceRow.product.name}
                                       </div>
                                    </div>
                                    <div>
                                       &times; {row.shipmentInvoiceRow.qty}
                                    </div>
                                 </div>
                              </div>
                           )
                        )}
                     </div>
                  </div>
               ) : null}
            </div>
         ) : null}

         {shipmentStatusError.shipmentErrorCustomerMessages.length > 0 && (
            <div className="pt-2 lg:p-4 mt-4 lg:mt-6 bg-gray-100 dark:bg-gray-800 rounded-lg">
               <div className="text-base font-bold mb-2">For Customer</div>
               {shipmentStatusError.shipmentErrorCustomerMessages.map((i) => (
                  <div
                     key={i.id}
                     className="py-2 border-t border-gray-300 dark:border-gray-700"
                  >
                     <div className="text-gray-500 text-xs">
                        {formatDate(i.created * 1000, 'YYYY-MM-DD')}
                     </div>
                     <div className="whitespace-pre-wrap mt-2 leading-normal">
                        {i.message}
                     </div>
                     <div className="flex items-center mt-1">
                        <span className="text-xs text-gray-500">
                           Message Sent
                        </span>
                        <span className="text-green-500 text-base ml-2">✓</span>
                     </div>
                  </div>
               ))}
            </div>
         )}

         <div className="mt-2 lg:mt-4">
            {isShowNotifyCustomerErrorForm ? (
               <div className="mt-2 lg:mt-4 p-4 lg:p-6 bg-gray-100 rounded-lg shadow-lg">
                  <NotifyCustomerErrorForm
                     shipmentStatusId={shipmentStatusId}
                     shipmentStatusError={shipmentStatusError}
                     hideForm={() => setIsShowNotifyCustomerErrorForm(false)}
                  />
               </div>
            ) : (
               <Button
                  bold={false}
                  size="sm"
                  noPadding
                  title="Notify customer about this error"
                  onClick={() => setIsShowNotifyCustomerErrorForm(true)}
               />
            )}
         </div>
      </div>
   );
}

export default ShipmentError;
