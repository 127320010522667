import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import randomcolor from 'randomcolor';
import {
   ResponsiveContainer,
   BarChart,
   Bar,
   XAxis,
   YAxis,
   Cell,
   Tooltip,
} from 'recharts';
import Card from 'components/Card';
import Spinner from 'components/Spinner';
import Errors from 'components/Errors';
import { BizAnalyticsContext } from '..';

const FETCH_BIZ_PAGEVIEW = gql`
   query FETCH_BIZ_PAGEVIEW($start: DateTime!, $end: DateTime!) {
      bizPageview(start: $start, end: $end) {
         page: name
         times: value
      }
   }
`;

function PageViewAnalytics() {
   const { start, end } = useContext(BizAnalyticsContext);
   const { loading, error, data } = useQuery(FETCH_BIZ_PAGEVIEW, {
      variables: { start, end },
   });
   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;
   const colors = randomcolor({
      luminosity: 'dark',
      count: data.bizPageview.length,
   });
   return (
      <Card title="Pages Views">
         <ResponsiveContainer width="100%" height={300}>
            <BarChart data={data.bizPageview} layout="vertical">
               <XAxis type="number" />
               <YAxis type="category" dataKey="page" width={80} />
               <Tooltip />
               <Bar dataKey="times">
                  {data.bizPageview.map((_, index) => (
                     <Cell key={`cell-${index}`} fill={colors[index]} />
                  ))}
               </Bar>
            </BarChart>
         </ResponsiveContainer>
      </Card>
   );
}

export default PageViewAnalytics;
