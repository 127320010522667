import { gql, useMutation } from '@apollo/client';
import CharlesButton from 'components/charles/base';
import { Input, Select } from 'components/Form';
import OdooPriceListSelector from 'components/OdooPriceListSelector';
import { Alert } from 'components/Toast';
import { useState } from 'react';
import { FETCH_PRICE_LISTS } from '.';

const CREATE_PRICE_LIST = gql`
   mutation ($name: String!, $currency: String, $odooId: Int) {
      createPriceList(name: $name, currency: $currency, odooId: $odooId) {
         priceList {
            id
            name
            currency
            odooId
         }
      }
   }
`;

const SAVE_PRICE_LIST = gql`
   mutation ($id: ID!, $name: String!, $currency: String, $odooId: Int) {
      savePriceList(
         id: $id
         name: $name
         currency: $currency
         odooId: $odooId
      ) {
         priceList {
            id
            name
            currency
            odooId
         }
      }
   }
`;

const EditPriceListView = ({
   id,
   initialName = '',
   initialCurrency = 'USD',
   initialOdooId = null,
   complete,
}) => {
   const [name, setName] = useState(initialName);
   const [currency, setCurrency] = useState(initialCurrency);
   const [odooId, setOdooId] = useState(initialOdooId);
   const [createPriceList, createPriceListRes] = useMutation(
      CREATE_PRICE_LIST,
      {
         variables: { name, currency, odooId },
         refetchQueries: [{ query: FETCH_PRICE_LISTS }],
         awaitRefetchQueries: true,
         onCompleted(res) {
            Alert('success', 'Price List Created.');
            complete(res.createPriceList.priceList);
         },
         onError(error) {
            Alert('error', error.message);
         },
      }
   );
   const [savePriceList, savePriceListRes] = useMutation(SAVE_PRICE_LIST, {
      variables: { id, name, currency, odooId },
      refetchQueries: [{ query: FETCH_PRICE_LISTS }],
      awaitRefetchQueries: true,
      onCompleted(res) {
         Alert('success', 'Price List Saved.');
         complete(res.savePriceList.priceList);
      },
      onError(error) {
         Alert('error', error.message);
      },
   });

   return (
      <div className="space-y-10">
         <div className="space-y-4 text-base">
            <div className="flex space-x-3 items-center">
               <label htmlFor="">Name: </label>
               <Input
                  className="w-full"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
               />
            </div>
            <div className="flex space-x-3 items-center">
               <label htmlFor="">Currency: </label>
               <Select
                  value={currency}
                  onChange={(e) => setCurrency(e.target.value)}
               >
                  <option value="USD">USD</option>
                  <option value="EUR">EUR</option>
               </Select>
            </div>

            <div className="flex space-x-3 items-center">
               <label>Connected Odoo Price List: </label>
               <OdooPriceListSelector
                  value={odooId}
                  onSelect={(i) => setOdooId(i ? i.id : null)}
               />
            </div>

            <div className="text-xs opacity-70 italic">
               To connect with Odoo Price list, you will need to choose an
               exsiting price list from Odoo. Create one if you have not. Not
               that each price list in wis could only be connected to one price
               list in Odoo.
            </div>
         </div>

         <div className="text-lg">
            {id ? (
               <CharlesButton
                  primary
                  onClick={savePriceList}
                  loading={savePriceListRes.loading}
               >
                  Save
               </CharlesButton>
            ) : (
               <CharlesButton
                  primary
                  onClick={createPriceList}
                  loading={createPriceListRes.loading}
               >
                  Save
               </CharlesButton>
            )}
         </div>
      </div>
   );
};

export default EditPriceListView;
