import { gql } from "@apollo/client";

export const FETCH_PRODUCT_LIST_PACKING_INSTRUCTIONS = gql`
  query FETCH_PRODUCT_LIST_PACKING_INSTRUCTIONS($productListId: ID!) {
    productList(id: $productListId) {
      id
      packingInstructions: newPackingInstructions {
        id
        content
        updatedAt
        createdBy {
          email
        }
        images
      }
    }
  }
`;
