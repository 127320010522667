import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation, gql } from '@apollo/client';
import PageContainer from 'components/PageContainer';
import Spinner from 'components/Spinner';
import Errors from 'components/Errors';
import Image from 'components/Image';
import { Alert } from 'components/Toast';
import BackButton from 'components/BackButton';

const FETCH_GROUP_DETAIL = gql`
   query FETCH_GROUP_DETAIL($id: ID!) {
      productArea(id: $id) {
         id
         name
         customers {
            id
            name
         }
         products {
            id
            name
            productLine {
               id
               name
               mainImage
            }
         }
      }
   }
`;

const SAVE_PRODUCT_AREA = gql`
   mutation SAVE_PRODUCT_AREA($id: ID!, $distributionMarkId: ID) {
      saveProductArea(id: $id, distributionMarkId: $distributionMarkId) {
         productArea {
            id
            distributionMark {
               id
            }
         }
      }
   }
`;

function GroupDetail() {
   const { id } = useParams();
   const { loading, error, data } = useQuery(FETCH_GROUP_DETAIL, {
      variables: { id },
   });
   const [saveProductArea, saveProductAreaRes] = useMutation(
      SAVE_PRODUCT_AREA,
      {
         onCompleted: () => Alert('success', 'Updated.'),
         onError: (error) => Alert('error', error.message),
      }
   );
   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;

   const group = data.productArea;

   const productLines = group.products
      .filter((i) => i.productLine !== null)
      .reduce((arr, product) => {
         console.log('product', product);
         if (arr.find((j) => j.id === product.productLine.id)) return arr;
         return [...arr, product.productLine];
      }, [])
      .sort((a, b) => {
         if (a.name > b.name) {
            return 1;
         } else if (a.name < b.name) {
            return -1;
         }
         return 0;
      });

   return (
      <PageContainer
         navigationBarClassName="lg:hidden"
         backButton={<BackButton to="/products/groups" />}
         title={group.name}
         content={
            <div className="flex flex-col h-full relative">
               <div className="flex justify-between items-center p-6">
                  <h1 className="hidden lg:block sticky top-0">{group.name}</h1>
               </div>
               <div className="flex flex-1 overflow-auto border-t border-gray-200">
                  <div className="overflow-auto border-r border-gray-200">
                     <div className="p-4">
                        {group.customers.length > 0 ? (
                           group.customers.map((customer) => (
                              <div
                                 className="my-2 border-b pb-1 border-gray-100"
                                 key={customer.id}
                              >
                                 {customer.name}
                              </div>
                           ))
                        ) : (
                           <div className="my-1 text-gray-600">
                              No Customers for this group.
                              <br />
                              Consider delete this group to keep this clean.
                           </div>
                        )}
                     </div>
                  </div>
                  <div className="overflow-auto">
                     <div className="p-4">
                        {productLines.map((line) => (
                           <ProductLine
                              key={line.id}
                              line={line}
                              products={group.products.filter(
                                 (p) =>
                                    p.productLine &&
                                    p.productLine.id === line.id
                              )}
                           />
                        ))}
                     </div>
                  </div>
               </div>
            </div>
         }
      />
   );
}

const ProductLine = ({ line, products }) => {
   return (
      <div className="mb-4">
         <div className="flex items-center mb-2">
            <Image size="w-8 h-8" src={line.mainImage} />
            <div className="font-bold ml-2">{line.name}</div>
         </div>
         <div className="text-gray-600 text-sm">
            {products.map((p) => (
               <div
                  key={p.id}
                  className="border-b border-gray-100 py-1 lg:whitespace-no-wrap"
               >
                  {p.name}
               </div>
            ))}
         </div>
      </div>
   );
};

export default GroupDetail;
