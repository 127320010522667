import PlanList from "./PlanList";
import Page from "components/Page";

const HandledPlanList = () => {
  return (
    <Page title="Order Scheduler Handled Plans" backTo="./..">
      <PlanList handled />
    </Page>
  );
};

export default HandledPlanList;
