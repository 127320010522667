import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import File from "../../components/File.js";
import Card from "../../components/Card.js";
import { Button } from "../../components/base.js";
import { FileSelector } from "../../components/Form.js";
import AWSUploader from "../../components/AWSUploader.js";
import { CUSTOMER_CREATE_DOCUMENT, CUSTOMER_REMOVE_DOCUMENT } from "./graphql.js";
import CharlesButton from "components/charles/base.js";
import { BsTrash } from "react-icons/bs";

function Document({ customerId, documentId, name, uri, showDeleteButton }) {
  const [customerRemoveDocument, { loading }] = useMutation(CUSTOMER_REMOVE_DOCUMENT, {
    variables: { customerId, documentId },
  });
  return (
    <div className="flex px-2 space-x-2 items-center border-b border-gray-100 flex-1 relative">
      {showDeleteButton ? (
        <CharlesButton
          danger
          loading={loading}
          onClick={(e) => {
            if (window.confirm("Are you sure to delete this file?")) {
              customerRemoveDocument();
            }
          }}
        >
          <BsTrash />
        </CharlesButton>
      ) : null}
      <File name={name} link={uri} />
    </div>
  );
}

function Documents({ customer }) {
  const [editing, setEditing] = useState(false);
  const [customerCreateDocument] = useMutation(CUSTOMER_CREATE_DOCUMENT);
  const [uploadingFiles, setUploadingFiles] = useState([]);

  function onUploaded(index, name, url) {
    customerCreateDocument({
      variables: {
        id: customer.id,
        name: name,
        uri: url,
      },
      update: (_) => {
        setUploadingFiles((prev) => prev.filter((_, jIndex) => jIndex !== index));
      },
    });
  }

  return (
    <Card className="mt-4 lg:mt-6" title="Documents" rightButtons={<Button title={editing ? "done" : "edit"} onClick={(_) => setEditing((prev) => !prev)} />}>
      {customer.documents.length > 0 ? (
        <div className="-mx-2">
          {customer.documents.map((i) => (
            <Document showDeleteButton={editing} key={i.id} customerId={customer.id} documentId={i.id} name={i.name} uri={i.uri} />
          ))}
        </div>
      ) : (
        <div className="text-gray-500 text-xs mt-2">No documents asscicated with this customer.</div>
      )}

      <div>
        {uploadingFiles.map((i, index) => (
          <div className="flex justify-between items-center border-1 border-gray-200 flex-1 relative border-b" key={index}>
            <div className="relative w-16 h-16">
              <AWSUploader
                className="w-full h-full"
                file={i.file}
                name={i.file.name}
                preview={i.preview}
                onUploaded={(url) => onUploaded(index, i.file.name, url)}
              />
            </div>
          </div>
        ))}
      </div>
      {editing ? (
        <FileSelector
          title="+ add documents"
          className="mt-4"
          onChange={(e) => {
            const files = [...e.target.files];
            setUploadingFiles((prev) => [...prev, ...files.map((file) => ({ file }))]);
            e.target.value = null;
          }}
        />
      ) : null}
    </Card>
  );
}

export default Documents;
