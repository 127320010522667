import { gql, useMutation } from "@apollo/client";
import { Button } from "components/base";
import FactorySelector from "components/FactorySelector";
import { DatePicker, Input } from "components/Form";
import { Alert } from "components/Toast";
import ProductSelector from "pages/manage/products/All/ProductSelector";
import { useState } from "react";
import StockStrategyView from "./StockUseStrategyView";
import CharlesButton from "components/charles/base";

const CREATE_SUPPLIER_STOCK_RECORD = gql`
  mutation CREATE_SUPPLIER_STOCK_RECORD($productId: ID!, $supplierId: ID!, $readyDate: DateTime!, $qty: Int!, $useStrategy: String!) {
    createSupplierStockRecord(productId: $productId, supplierId: $supplierId, readyDate: $readyDate, qty: $qty, useStrategy: $useStrategy) {
      supplierStockRecord {
        id
        qty
        latestQty
        readyDate
        useStrategy
        product {
          id
          supplierStocks {
            id
            qty
            latestQty
            updatedAt
            useStrategy
            supplier {
              id
              name
            }
            usages {
              id
              plan {
                id
                name
              }
              qty
            }
          }
        }
      }
    }
  }
`;

const CreateSupplierStockRecord = ({
  initData = {
    supplierId: null,
    product: null,
  },
  complete,
}) => {
  const [product, setProduct] = useState(initData.product);
  const [supplierId, setSupplierId] = useState(initData.supplierId);
  const [qty, setQty] = useState(0);
  const [readyDate, setReadyDate] = useState(new Date());
  const [useStrategy, setUseStrategy] = useState("USE_ONLY_ENOUGH");

  const [createStockRecord, createStockRecordRes] = useMutation(CREATE_SUPPLIER_STOCK_RECORD, {
    onCompleted() {
      Alert("success", "New Stock Record Created.");
      complete();
    },
    onError(error) {
      Alert("error", error.message);
    },
    refetchQueries: ["GET_SUPPLIER_STOCKS"],
  });

  return (
    <div className="space-y-8 text-sm">
      <div className="space-y-6">
        <div className="flex space-x-3 items-center">
          <label className="w-24">Supplier: </label>
          <FactorySelector value={supplierId} onChange={setSupplierId} />
        </div>
        <div className="flex space-x-3 items-center">
          <label className="w-24">Product: </label>
          {product ? (
            <div className="flex items-center space-x-3">
              <div>
                [{product.number}] {product.name}
              </div>
              <Button size="sm" title="Change Product" onClick={() => setProduct(null)} />
            </div>
          ) : (
            <ProductSelector onSelect={setProduct} />
          )}
        </div>
        <div className="flex space-x-10">
          <div className="space-x-3 flex items-center">
            <label className="w-24">Initial QTY: </label>
            <Input className="text-center" value={qty} onChange={(e) => setQty(e.target.value)} />
          </div>
          <div className="flex items-center space-x-3">
            <label className="w-24">Ready Date: </label>
            <DatePicker value={readyDate} onDayChange={setReadyDate} />
          </div>
        </div>

        <div className="space-x-3 flex items-baseline">
          <label className="w-24">How to use: </label>
          <div>
            <StockStrategyView value={useStrategy} onChange={setUseStrategy} />
          </div>
        </div>
      </div>

      {/* {prevProductStock && prevProductStock.length > 0 ? (
        <>
          <hr className="bg-gray-200" />

          <div>
            <h5>Previous Stock Records</h5>
            <div className="mt-4 -mx-2">
              <table>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Inital QTY</th>
                    <th>Latest QTY</th>
                  </tr>
                </thead>
                <tbody>
                  {prevProductStock.map((stock) => (
                    <tr key={stock.id}>
                      <td className="border-b border-opacity-70">{moment(stock.readyDate).format("YYYY-MM-DD")}</td>
                      <td className="border-b border-opacity-70">{stock.qty}</td>
                      <td className="border-b border-opacity-70">{stock.latestQty}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      ) : null} */}

      {/* <hr className="bg-gray-200 bg-opacity-70" /> */}

      <div className="text-xs opacity-70">
        When you are creating a new stock record for a supplier, if this product is already in our current production plans, it will re-compute all the plans.
        Make sure you understand this before createing new stock for suppliers.
      </div>

      <div className="mt-8">
        <CharlesButton
          className="text-lg"
          loading={createStockRecordRes.loading}
          onClick={() =>
            createStockRecord({
              variables: { productId: product.id, supplierId, qty, readyDate, useStrategy },
            })
          }
        >
          Create
        </CharlesButton>
      </div>
    </div>
  );
};

export default CreateSupplierStockRecord;
