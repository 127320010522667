import { useQuery } from "@apollo/client";
import Spinner from "components/Spinner.js";
import Errors from "components/Errors.js";
import { FETCH_ODOO_STOCKS } from "./graphql";
import { NavLink, Route, Routes } from "react-router-dom";
import OdooStockProductsPage from "./OdooStockProductsPage";

function OdooStock() {
  const { loading, error, data } = useQuery(FETCH_ODOO_STOCKS);

  if (loading) return <Spinner text="Loading data from Odoo..." />;
  if (error) return <Errors error={error} />;

  return (
    <div className="flex p-4 lg:p-6 flex-1 overflow-auto">
      <div className="lg:py-1 rounded-xl bg-white dark:bg-gray-800 h-full overflow-auto divide-y divide-gray-100 dark:divide-gray-700">
        {data.odooStocks.map((stock, index) => (
          <NavLink
            className={({ isActive }) => {
              return `pl-4 pr-6 py-2 block ${isActive ? " bg-blue-100 dark:bg-gray-700" : ""}`;
            }}
            to={stock.id}
            key={index}
          >
            {stock.name}
          </NavLink>
        ))}
      </div>

      <div className="flex-1 h-full relative overflow-auto ml-4 lg:ml-6">
        <Routes>
          <Route path=":stockId" element={<OdooStockProductsPage />} />
        </Routes>
      </div>
    </div>
  );
}

export default OdooStock;
