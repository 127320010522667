import { gql } from "@apollo/client";

export const FETCH_FACTORY_DETAIL = gql`
  query FETCH_FACTORY_DETAIL($id: ID!) {
    factory(id: $id) {
      id
      name
      odooId
      quotationFiles {
        id
        name
        src
        createdAt
      }
    }
  }
`;

export const FETCH_ACTIVE_PRODUCT_LINES = gql`
  query FETCH_ACTIVE_PRODUCT_LINES {
    productLines(activeForWis: true, hasActiveProducts: true) {
      results {
        id
        name
        productsSearchString
        mainImage(size: "80x80")
      }
    }
  }
`;

export const DELETE_CONTENT_BLOCK = gql`
  mutation DELETE_CONTENT_BLOCK($id: ID!) {
    deleteContentBlock(id: $id) {
      contentBlock {
        id
      }
    }
  }
`;

export const FETCH_ODOO_OBJECTS = gql`
  query FETCH_ODOO_OBJECTS($model: String!, $method: String!, $filters: String, $fields: [String], $limit: Int, $offset: Int) {
    odooQuery(model: $model, method: $method, filters: $filters, fields: $fields, limit: $limit, offset: $offset)
  }
`;
