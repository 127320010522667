const OrderSchedulerDescription = () => (
  <div className="opacity-60 mx-3 my-8 dark:border-gray-700 leading-loose">
    <div>Notes</div>
    <p className="font-bold">
      1. In case of the May Day and National Day holidays, orders should be ready one week before the holiday. During the Spring Festival holiday, orders must
      be ready 3 weeks before the holiday.
    </p>
    <p className="font-bold">
      2. Avoid scheduling orders for Stadium before the Spring Festival. Better to schedule the ready date one month advance the Spring Festival.
    </p>
    <p className="font-bold">3. For orders shipped before the holidays, please book with forwarder at least 3 weeks in advance.</p>
    <p className="font-bold">
      4. For orders shipped before the holidays, please reserve a vehicle, warehouse delivery and order pickup at least 3 working days in advance.
    </p>
    <p>5. Avoid additional expenses.</p>
    <p>
      6. For Amazon orders which are shipped by air/express to the warehouse, the labels of the air freight and express companies cannot be directly affixed to
      the outer carton. We need to wrap a layer of plastic wrap on the outer carton before shipping, so that the label can be affixed on the plastic wrap.
    </p>
    <p>
      7. When placing an inventory order to the factory, suggest shipping the goods in batches according to the order needs. Do not send the inventory to
      Jiuzhou in advance because early shipment means early payment, which might lead to high pressure on cash flow.
    </p>
    <p>8. If there is an air shipment, schedule the ship date within the first or two weeks after the holiday, because the price will be more favorable.</p>
    <p>
      9. For shipments to ALC warehouse, DAT terms are used for FCL shipments and DAP terms are used for LCL shipments. For shipments to CK warehouse, DAP terms
      are used for all shipments.
    </p>
    <p>
      10. Send samples with lithium batteries via Deller. Because it requires a transportation certificate for the product, we only have a transportation
      certificate for the battery.
    </p>
    <p>
      11. If FEDEX sends samples exceeding a certain weight or a certain amount, customs declaration is required. If too many samples are sent at one time,
      confirm whether customs declaration is required before making arrangements, or directly split the goods into several shipments.
    </p>
    <p>
      12. Shipping battery products must be reported to the freight forwarder and provide the battery report required by the freight forwarder (the report we
      need is for the battery, not the product).
    </p>
  </div>
);

export default OrderSchedulerDescription;
