import { gql, useQuery } from "@apollo/client";
import { InlineSpinner } from "components/Spinner";
import Errors from "components/Errors";
import { Select } from "components/Form";
import { Button } from "components/base";
import { IoTrash } from "react-icons/io5";
import CharlesButton from "components/charles/base";

function buildName(node, allNodes) {
  if (node.parent) {
    const parent = allNodes.find((i) => i.id === node.parent.id);
    if (parent) return buildName(parent, allNodes) + " / " + node.name;
  }
  return node.name;
}

const CollectionSelector = ({ ids, onChange }) => {
  const { loading, error, data } = useQuery(gql`
    query FETCH_ANY_COLLECTIONS {
      anyCollections(all: true) {
        id
        name
        parent {
          id
        }
      }
    }
  `);

  if (loading) return <InlineSpinner />;
  if (error) return <Errors error={error} />;

  return (
    <div className="space-y-3">
      {ids.map((id, index) => (
        <div key={index} className="flex space-x-3 items-center justify-between">
          <Select
            className="w-full"
            value={id}
            onChange={(e) => onChange((prev) => prev.map((prevId, prevIndex) => (prevIndex === index ? e.target.value : prevId)))}
          >
            <option value="0">-- select -- </option>
            {data.anyCollections.map((i) => (
              <option key={i.id} value={i.id}>
                {buildName(i, data.anyCollections)}
              </option>
            ))}
          </Select>
          <div>
            <CharlesButton danger onClick={() => onChange((prev) => prev.filter((_, prevIndex) => prevIndex !== index))}>
              <IoTrash />
            </CharlesButton>
          </div>
        </div>
      ))}
      <div>
        <Button title="+ Collection" onClick={() => onChange((prev) => [...prev, "0"])} />
      </div>
    </div>
  );

  return (
    <Select value={value} onChange={onChange}>
      <option value="0">-- select -- </option>
      {data.anyCollection.descendants.map((i) => (
        <option key={i.id} value={i.id}>
          {buildName(i, data.anyCollection.descendants)}
        </option>
      ))}
    </Select>
  );
};

export default CollectionSelector;
