import { useQuery, gql } from "@apollo/client";
import Errors from "components/Errors";
import Spinner from "components/Spinner";

const FETCH_CUSTOMER_ADDRESSES = gql`
  query FetchCustomerAddresses($id: ID!) {
    customer(id: $id) {
      id
      addresses {
        id
        name
        address
      }
    }
  }
`;

const ChooseCustomerAddressView = ({ customer, onSelect }) => {
  const { loading, error, data } = useQuery(FETCH_CUSTOMER_ADDRESSES, { variables: { id: customer.id } });

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  return (
    <div>
      {data.customer.addresses.map((address) => (
        <div key={address.id} onClick={() => onSelect(address)} className="border-b  dark:border-gray-700 py-3 cursor-pointer hover:text-sky-600">
          <div className="font-semibold">{address.name}</div>
          <div className="whitespace-pre-wrap">{address.address}</div>
        </div>
      ))}
    </div>
  );
};

export default ChooseCustomerAddressView;
