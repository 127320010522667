import React, { useState } from 'react';
import { formatDate } from 'react-day-picker/moment';
import Card from 'components/Card';
import Icon from 'components/Icon';
import { Button } from 'components/base';
import ShipmentDelay from 'components/ShipmentDelay';
import ShipmentError, { isCustomerRelated } from 'components/ShipmentError';

const calcTotalDelayDays = (shipment) =>
   shipment.shipmentStatus.reduce(
      (res, shipmentStatus) =>
         res +
         shipmentStatus.errors
            .filter((e) => (e.delayDays ? e.delayDays : 0))
            .reduce((res, error) => res + error.delayDays, 0),
      0
   );

function OrderErrors({ shipments }) {
   const [showShipments, setShowShipments] = useState(false);
   let shipmentWithErrors = [];
   let shipmentWithDelays = [];
   let shipmentWithErrorsReletedToCustomers = [];
   shipments.forEach((shipment) => {
      if (
         shipment.shipmentStatus.filter((status) => status.errors.length > 0)
            .length > 0
      )
         shipmentWithErrors.push(shipment);
      if (
         shipment.shipmentStatus.filter(
            (status) =>
               status.errors.filter(
                  (error) => error.delayDays && error.delayDays > 0
               ).length > 0
         ).length > 0
      )
         shipmentWithDelays.push(shipment);
      if (
         shipment.shipmentStatus.filter(
            (status) =>
               status.errors.filter((error) =>
                  isCustomerRelated(error.error.code)
               ).length > 0
         ).length > 0
      )
         shipmentWithErrorsReletedToCustomers.push(shipment);
   });
   let aveDelayDays = 0;
   if (shipmentWithDelays.length > 0)
      aveDelayDays =
         shipmentWithDelays.reduce(
            (res, shipment) => (res += calcTotalDelayDays(shipment)),
            0
         ) / shipmentWithDelays.length;

   return (
      <Card title="Orders with errors">
         <div>
            <div className="flex">
               <div className="mr-4 text-gray-600">
                  <span className="font-bold text-red-600">
                     {shipmentWithErrors.length}
                  </span>{' '}
                  orders has errors.
               </div>
               <div className="mr-4 text-gray-600">
                  <span className="font-bold text-red-600">
                     {shipmentWithDelays.length}
                  </span>{' '}
                  orders has delay.
               </div>
               <div className="mr-4 text-gray-600">
                  Average{' '}
                  <span className="font-bold text-red-600">
                     {Math.round(aveDelayDays)}
                  </span>{' '}
                  delay days.
               </div>
            </div>
            <div className="text-gray-600 mt-2 flex items-center">
               <div className="text-yellow-500">
                  <Icon icon="user" />
               </div>
               <span className="ml-2 font-bold text-red-600">
                  {shipmentWithErrorsReletedToCustomers.length}
               </span>
               <span className="ml-2">
                  orders has errors(code: 013, 014) related to customers.
               </span>{' '}
            </div>
         </div>
         <Button
            size="sm"
            className="mt-4"
            title={`${showShipments ? 'Hide Shipments' : 'Show Shipments'}`}
            onClick={(_) => setShowShipments(!showShipments)}
         />
         {showShipments ? (
            <div className="mt-4">
               {shipmentWithErrors.map((shipment, index) => {
                  const totalDelayDays = calcTotalDelayDays(shipment);
                  return (
                     <div
                        key={index}
                        className="my-4 border-t border-gray-100 flex"
                     >
                        <div className="w-1/4 mt-4">
                           <div>
                              <Button
                                 link={`/shipment/shipments/${shipment.id}`}
                                 title={shipment.invoiceNumber}
                              />
                           </div>
                           <div className="text-gray-500">
                              {shipment.customer.name}
                           </div>
                           {totalDelayDays ? (
                              <ShipmentDelay
                                 className="mt-2"
                                 days={totalDelayDays}
                              />
                           ) : null}
                        </div>
                        <div className="ml-6 flex-1">
                           {shipment.shipmentStatus
                              .filter(
                                 (shipmentStatus) =>
                                    shipmentStatus.errors.length > 0
                              )
                              .map((shipmentStatus, shipmentStatusIndex) =>
                                 shipmentStatus.errors.map(
                                    (shipmentError, shipmentErrorIndex) => (
                                       <div
                                          className={`${
                                             shipmentError.delayDays &&
                                             shipmentError.delayDays > 0
                                                ? 'bg-red-100'
                                                : 'bg-gray-100'
                                          } rounded-lg p-4 mt-4`}
                                          key={shipmentErrorIndex}
                                       >
                                          <div className="text-gray-500 text-xs">
                                             {formatDate(
                                                shipmentError.created * 1000,
                                                'YYYY-MM-DD'
                                             )}
                                          </div>
                                          <ShipmentError
                                             code={shipmentError.error.code}
                                             description={
                                                shipmentError.error.description
                                             }
                                          />

                                          {shipmentError.shipmentErrorProducts
                                             .length > 0 && (
                                             <div className="text-xs text-gray-600">
                                                {shipmentError.shipmentErrorProducts.map(
                                                   (
                                                      shipmentErrorProduct,
                                                      shipmentErrorProductsIndex
                                                   ) => (
                                                      <div
                                                         className="mt-2"
                                                         key={
                                                            shipmentErrorProductsIndex
                                                         }
                                                      >
                                                         -{' '}
                                                         <span>
                                                            {
                                                               shipmentErrorProduct
                                                                  .shipmentInvoiceRow
                                                                  .product.name
                                                            }
                                                         </span>{' '}
                                                         <span>
                                                            ✕{' '}
                                                            {
                                                               shipmentErrorProduct
                                                                  .shipmentInvoiceRow
                                                                  .qty
                                                            }
                                                         </span>
                                                      </div>
                                                   )
                                                )}
                                             </div>
                                          )}

                                          {shipmentError.delayDays &&
                                          shipmentError.delayDays > 0 ? (
                                             <ShipmentDelay
                                                iconSize={14}
                                                className="mt-2"
                                                days={shipmentError.delayDays}
                                             />
                                          ) : null}
                                       </div>
                                    )
                                 )
                              )}
                        </div>
                     </div>
                  );
               })}
            </div>
         ) : null}
      </Card>
   );
}

export default OrderErrors;
