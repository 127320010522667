import InputSelect from "components/InputSelect";
import CharlesButton from "components/charles/base";
import { useContext, useState } from "react";
import { BsTrash } from "react-icons/bs";
import { OrderContext } from "./OrderDetail";
import { Input } from "components/Form";

const OrderLineView = ({ line, availableProducts }) => {
  const { originalOrder, setOrder, validStocks } = useContext(OrderContext);
  const [searchText, setSearchText] = useState(line.product ? `[${line.product.number}] ${line.product.name}` : "");

  function selectProduct(product) {
    setSearchText(product.name);
    const foundPrice = originalOrder.pricelist ? originalOrder.pricelist.latestPrices.find((i) => i.productId === product.id) : null;
    onChangeLine({ product, salesPrice: foundPrice ? foundPrice.price : 0 });
  }

  function onChangeLine(values) {
    const newLine = { ...line, ...values };
    setOrder((prev) => ({
      ...prev,
      orderLines: prev.orderLines.map((i) => (i.id === line.id ? newLine : i)),
    }));
  }

  function removeLine() {
    setOrder((prev) => ({
      ...prev,
      orderLines: prev.orderLines.filter((i) => i.id !== line.id),
    }));
  }

  const stock = line.product ? validStocks.find((i) => i.odooId === line.product.odooId) : null;
  console.log("stock", stock);

  function renderProductOption(product) {
    return (
      <div className="flex justify-between">
        <div>
          [{product.number}] {product.name}
        </div>
        <div>{product.stock ? product.stock.latestQty : "0"}</div>
      </div>
    );
  }

  return (
    <tr>
      <td className="w-full">
        <div className="flex space-x-2">
          <CharlesButton danger onClick={removeLine}>
            <BsTrash />
          </CharlesButton>

          <InputSelect
            className="w-full"
            value={searchText}
            onChange={setSearchText}
            options={availableProducts}
            onSelect={selectProduct}
            renderOption={renderProductOption}
          />
        </div>
      </td>
      <td className="text-right">{line.product ? line.product.itemsPerSet : " - "}</td>
      <td className="text-right">{stock ? stock.latestQty : 0}</td>
      <td className="text-right">
        <Input className="text-right w-20" value={line.qty} onChange={(e) => onChangeLine({ qty: e.target.value })} />
      </td>
      <td className="text-right">
        <Input className="text-right w-20" value={line.salesPrice} onChange={(e) => onChangeLine({ salesPrice: e.target.value })} />
      </td>
      <td className="text-right">
        <Input className="text-right w-20" value={line.discountPercentage} onChange={(e) => onChangeLine({ discountPercentage: e.target.value })} />
      </td>
      <td className="text-right">{(parseInt(line.qty) * parseFloat(line.salesPrice) * ((100 - parseFloat(line.discountPercentage)) / 100)).toFixed(2)}</td>
    </tr>
  );
};

export default OrderLineView;
