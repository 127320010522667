import { useMutation, gql } from '@apollo/client';

const PING_FOR_HELP = gql`
   mutation PING_FOR_HELP($message: String) {
      pingForHelp(message: $message) {
         result
      }
   }
`;

const usePing = (config) => useMutation(PING_FOR_HELP, { ...config });

export default usePing;
