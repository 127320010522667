import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Button, ButtonWithArrow } from "components/base";
import Errors from "components/Errors";
import { DatePicker, Input } from "components/Form";
import Page from "components/Page";
import Spinner, { InlineSpinner } from "components/Spinner";
import { Alert } from "components/Toast";
import { useModals } from "ModalProvider";
import moment from "moment";
import { Productions } from "pages/orderScheduler/OrderScheduler";
import { InternalTransferForm } from "pages/shipment/shipments/InternalTransferForm";
import { useContext, useState } from "react";
import { BsCheckCircleFill } from "react-icons/bs";
import { useParams } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { byName } from "utils/sort";
import { v4 } from "uuid";
import ProductSelector from "./ProductsSelector";
import odooIcon from "assets/odoo-icon.svg";
import { OdooContext } from "OdooProvider";

const FETCH_PURCHASE = gql`
  query FETCH_PURCHASE($id: ID!) {
    purchase(id: $id) {
      id
      createdBy {
        id
        email
      }
      data
      state
      createdAt
      startDate
      readyDate
      totalCbm
      productCost
      shippingCost
      totalCost
      state
      confirmTimeAt
      confirmCostAt
      odooTransferId
      odooTransferName
    }
    allProductlines(activeForWis: true) {
      id
      name
    }
    allProducts(productAreas: [18]) {
      id
      odooId
      number
      name
      quantityPerCarton
      cbm: outerCartonCbm
      productLine {
        id
      }
      latestCost
      latestCostUpdatedAt
    }
  }
`;

const COMPUTE_PRODUCTION_PLAN = gql`
  query COMPUTE_PRODUCTION_PLAN($startDate: DateTime!, $lines: [PlanProductionLineInputType]!) {
    computeProductionPlan(startDate: $startDate, lines: $lines)
  }
`;

const SAVE_PURCHASE = gql`
  mutation SAVE_PURCHASE(
    $id: ID
    $startDate: Int
    $readyDate: Int
    $data: String
    $totalCbm: Float
    $productCost: Float
    $shippingCost: Float
    $totalCost: Float
    $state: String
    $confirmTimeAt: Int
    $confirmCostAt: Int
    $odooTransferId: Int
    $odooTransferName: String
  ) {
    savePurchase(
      id: $id
      startDate: $startDate
      readyDate: $readyDate
      data: $data
      totalCbm: $totalCbm
      productCost: $productCost
      shippingCost: $shippingCost
      totalCost: $totalCost
      state: $state
      confirmCostAt: $confirmCostAt
      confirmTimeAt: $confirmTimeAt
      odooTransferId: $odooTransferId
      odooTransferName: $odooTransferName
    ) {
      purchase {
        id
        data
        state
        createdAt
        startDate
        readyDate
        totalCbm
        productCost
        shippingCost
        totalCost
        state
        confirmCostAt
        confirmTimeAt
        odooTransferId
        odooTransferName
      }
    }
  }
`;

const PurchaseDetail = () => {
  const { id } = useParams();

  const { loading, error, data } = useQuery(FETCH_PURCHASE, {
    variables: { id },
  });

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const productLines = data.allProductlines
    .map((i) => ({
      ...i,
      products: data.allProducts.filter((p) => p.productLine && p.productLine.id === i.id).sort(byName),
    }))
    .filter((i) => i.products.length > 0)
    .sort(byName);

  return <PurchaseForm id={id} originalPurchase={data.purchase} productLines={productLines} />;
};

function computeLine(line) {
  let totalCbm = 0;
  let totalCost = 0;
  let validQty = false;
  if (line.product) {
    if (line.product.latestCost) totalCost = line.qty * line.product.latestCost;
    if (line.product.quantityPerCarton) {
      validQty = line.qty % line.product.quantityPerCarton === 0;
      totalCbm = (line.qty * line.product.cbm) / line.product.quantityPerCarton;
    }
  }

  return { ...line, validQty, totalCbm, totalCost };
}

const PurchaseForm = ({ id, originalPurchase, productLines }) => {
  const originalPurchaseData = JSON.parse(originalPurchase.data);

  const [startDate, setStartDate] = useState(originalPurchase.startDate ? new Date(originalPurchase.startDate) : new Date().addDays(7));
  const [lines, setLines] = useState(originalPurchaseData ? originalPurchaseData.lines : []);
  const [showProductions, setShowProductions] = useState(false);
  const [shippingCost, setShippingCost] = useState(originalPurchase.shippingCost);
  const { odooUrl } = useContext(OdooContext);

  const totalQty = lines.reduce((res, line) => res + line.qty, 0);
  const totalCbm = lines.reduce((res, line) => res + line.totalCbm, 0);
  const productCost = lines.reduce((res, line) => res + line.totalCost, 0);
  const totalCost = productCost + shippingCost;

  const [computeQuery, computeQueryRes] = useLazyQuery(COMPUTE_PRODUCTION_PLAN);
  const [savePurchase, savePurchaseRes] = useMutation(SAVE_PURCHASE, {
    onCompleted() {
      Alert("success", "Purchase Updated.");
    },
    onError(error) {
      Alert("error", error.message);
    },
  });
  const internalTransferFormModal = useModals();

  const debouncedCompute = useDebouncedCallback((variables) => {
    computeQuery({ variables });
  }, 500);

  function onChangeLine(id, field, value) {
    const newLines = lines.map((line) => (line.id === id ? { ...line, [field]: value } : line)).map(computeLine);
    setLines(newLines);
    const queryLines = newLines
      .filter((i) => i.validQty && i.qty > 0 && i.product && i.product.odooId)
      .map((i) => ({ productId: i.product.odooId, qty: i.qty }));

    debouncedCompute({ startDate, lines: queryLines });
  }

  function removeLine(id) {
    const newLines = lines.filter((prevLine) => prevLine.id !== id);
    setLines(newLines);
    const queryLines = newLines
      .filter((i) => i.validQty && i.qty > 0 && i.product && i.product.odooId)
      .map((i) => ({ productId: i.product.odooId, qty: i.qty }));
    debouncedCompute({ startDate, lines: queryLines });
  }

  const computeResult =
    !computeQueryRes.loading && !computeQueryRes.error && computeQueryRes.data ? JSON.parse(computeQueryRes.data.computeProductionPlan) : null;

  let computedLines = lines;
  let productions = originalPurchaseData ? originalPurchaseData.computeResult.productions : [];

  if (computeResult) {
    computedLines = computedLines.map((line, index) => ({
      ...line,
      result: computeResult.lines[index],
    }));
    productions = computeResult.productions;
  }

  const selectedProductIds = lines.filter((i) => i.product).map((i) => i.product.id);

  const disabledInput = originalPurchase.state !== "DRAFT";

  return (
    <Page backTo="/purchase" title={`Purchase #${id}`}>
      <div className="p-6">
        <div className="flex space-x-4 items-center mb-4">
          <label>Start Date: </label>
          <DatePicker value={startDate} onDayChange={setStartDate} disabled={disabledInput} />
        </div>

        <table className="mt-4 with-line -mx-2">
          <thead>
            <tr className="whitespace-nowrap">
              <th>Product</th>
              <th className="text-right w-32">QTY / Set</th>
              <th className="text-right w-32">QTY</th>
              <th className="text-right w-32">CBM</th>
              <th className="text-right w-32">
                <div className="flex items-center justify-end space-x-2">
                  <InlineSpinner
                    className={`transition-opacity
                              ${computeQueryRes.loading ? " opacity-100" : " opacity-0"}
                           `}
                    size={16}
                    text={null}
                  />
                  <span>Ready Date</span>
                </div>
              </th>
              <th className="text-right w-32">Cost / Unit</th>
              <th className="text-right w-32">Cost($)</th>
            </tr>
          </thead>
          <tbody>
            {computedLines.map((line) => (
              <tr key={line.id}>
                <td>
                  <div className="flex space-x-2">
                    <ProductSelector
                      disabled={disabledInput}
                      value={line.product ? line.product.id : "0"}
                      productLines={productLines}
                      selectedProductIds={selectedProductIds}
                      onSelect={(product) => onChangeLine(line.id, "product", product)}
                    />
                    {disabledInput ? null : <Button title="remove" onClick={() => removeLine(line.id)} />}
                  </div>
                </td>
                <td className="text-right">{line.product ? line.product.quantityPerCarton : " - "}</td>
                <td className="text-right">
                  <Input
                    disabled={disabledInput}
                    validated={line.validQty}
                    className="text-right"
                    value={line.qty}
                    onChange={(e) => onChangeLine(line.id, "qty", parseInt(e.target.value) || 0)}
                  />
                </td>
                <td className="text-right">{line.totalCbm.toFixed(3)}</td>
                <td className="text-right">
                  {computeQueryRes.loading ? null : line.result && line.result.ready_date ? moment(line.result.ready_date * 1000).format("YYYY-MM-DD") : null}
                </td>
                <td className="text-right">{line.product ? line.product.latestCost : " - "}</td>

                <td className="text-right">{line.totalCost.toFixed(2)}</td>
              </tr>
            ))}

            <tr>
              <td colSpan={6}>
                {disabledInput ? null : (
                  <Button
                    title="+ Add Product"
                    onClick={() =>
                      setLines((prev) => [
                        ...prev,
                        {
                          id: v4(),
                          product: null,
                          qty: 0,
                          totalCbm: 0,
                          totalCost: 0,
                        },
                      ])
                    }
                  />
                )}
              </td>
              <td className="text-right">{productCost.toFixed(2)}</td>
            </tr>

            <tr>
              <td colSpan={7} className="text-right">
                <div className="flex space-x-2 justify-end items-center">
                  <label>Shipping Cost: </label>
                  <Input
                    disabled={disabledInput}
                    className="text-right"
                    value={shippingCost}
                    onChange={(e) => setShippingCost(parseFloat(e.target.value) || 0)}
                  />
                </div>
              </td>
            </tr>
            <tr className="text-base">
              <td>Total</td>
              <td></td>
              <td className="text-right">{totalQty}</td>
              <td className="text-right">{totalCbm.toFixed(3)}</td>
              <td className="text-right">
                {computeQueryRes.loading
                  ? null
                  : computeResult
                  ? moment(computeResult.ready_date * 1000).format("YYYY-MM-DD")
                  : originalPurchase.readyDate
                  ? moment(originalPurchase.readyDate).format("YYYY-MM-DD")
                  : null}
              </td>
              <td></td>
              <td className="text-right">{totalCost.toFixed(2)}</td>
            </tr>
          </tbody>
        </table>

        <div className="flex justify-end mt-4">
          <div className="text-sm">
            The estimated ready date is computed base on the production capacity provided by suppliers. The actual ready date might be +/- 7 days.
          </div>
        </div>

        {computeQueryRes.loading || productions.length === 0 ? null : (
          <>
            <ButtonWithArrow
              className="mt-8 text-sm text-blue-600 hover:text-blue-700 active:text-blue-800"
              show={showProductions}
              onClick={() => setShowProductions((prev) => !prev)}
            >
              {showProductions ? "Hide Productions" : "Show Productions"}
            </ButtonWithArrow>

            {showProductions ? (
              <div className="p-6 bg-white dark:bg-gray-800 rounded-2xl mt-8 space-y-4">
                <Productions productions={productions} />
              </div>
            ) : null}
          </>
        )}

        {savePurchaseRes.loading ? (
          <div className="p-6 flex">
            <InlineSpinner size={21} text="Saving..." />
          </div>
        ) : computeQueryRes.loading ? (
          <div className="p-6 flex">
            <InlineSpinner size={21} text="Computing dates..." />
          </div>
        ) : (
          <div className="flex text-base space-x-6 my-8">
            {originalPurchase.state === "DRAFT" ? (
              <>
                <Button
                  title="Save"
                  bold
                  loading={savePurchaseRes.loading}
                  disabled={savePurchaseRes.loading}
                  onClick={() =>
                    savePurchase({
                      variables: {
                        id,
                        startDate: startDate.getTime() / 1000,
                        readyDate: computeResult ? computeResult.ready_date : null,
                        data: computeResult
                          ? JSON.stringify({
                              lines: computedLines,
                              computeResult,
                            })
                          : originalPurchase.data,
                        totalQty,
                        totalCbm,
                        productCost,
                        shippingCost,
                        totalCost,
                      },
                    })
                  }
                />
                <Button
                  title="Request to Confirm"
                  bold
                  onClick={() =>
                    savePurchase({
                      variables: {
                        id,
                        state: "REQUEST_TO_CONFIRM",
                      },
                    })
                  }
                />
              </>
            ) : originalPurchase.state === "REQUEST_TO_CONFIRM" ? (
              <>
                {originalPurchase.confirmTimeAt ? (
                  <div className="text-green-600 flex space-x-2 items-center">
                    <span>Confirmed Ready Date</span>
                    <BsCheckCircleFill />
                  </div>
                ) : (
                  <Button
                    title="Confirm Time"
                    bold
                    onClick={() =>
                      savePurchase({
                        variables: {
                          id,
                          confirmTimeAt: new Date().getTime() / 1000,
                        },
                      })
                    }
                  />
                )}

                {originalPurchase.confirmCostAt ? (
                  <div className="text-green-600 flex space-x-2 items-center">
                    <span>Confirmed Cost</span>
                    <BsCheckCircleFill />
                  </div>
                ) : (
                  <Button
                    title="Confirm Cost"
                    bold
                    onClick={() =>
                      savePurchase({
                        variables: {
                          id,
                          confirmCostAt: new Date().getTime() / 1000,
                        },
                      })
                    }
                  />
                )}
              </>
            ) : null}

            {originalPurchase.confirmTimeAt && originalPurchase.confirmCostAt ? (
              originalPurchase.odooTransferId ? (
                <div className="flex items-center">
                  <img className="mr-2" style={{ height: 16 }} src={odooIcon} alt="odoo" />
                  <Button
                    title={originalPurchase.odooTransferName}
                    key={originalPurchase.odooTransferId}
                    target="_blank"
                    link={`${odooUrl}/web#id=${originalPurchase.odooTransferId}&action=350&active_id=17&model=stock.picking&view_type=form&menu_id=222`}
                  />
                </div>
              ) : (
                <Button
                  bold
                  title="Create Internal Transfer to Odoo"
                  onClick={() =>
                    internalTransferFormModal.present({
                      title: "Create Odoo Internal Transfer",
                      children: (
                        <InternalTransferForm
                          lines={lines.map((line) => ({
                            productId: line.product.odooId,
                            name: line.product.name,
                            productUomQty: line.qty,
                          }))}
                          extraVariables={{ purchaseId: id }}
                          onCompleted={(payload) => {
                            internalTransferFormModal.hide();
                            savePurchase({
                              variables: {
                                id,
                                odooTransferId: payload["id"],
                                odooTransferName: payload["name"],
                              },
                            });
                          }}
                        />
                      ),
                    })
                  }
                />
              )
            ) : null}
          </div>
        )}
      </div>
    </Page>
  );
};

export default PurchaseDetail;
