import { useContext } from "react";
import { ProductContext } from "./All/ProductForm";
import ProductAreasSelector from "components/ProductAreasSelector";
import CharlesButton, { CharlesButtonWithArrow } from "components/charles/base";
import { useModals } from "ModalProvider";
import CustomersSelector from "components/CustomerSelector";
import { BsTrash } from "react-icons/bs";

const CustomersView = () => {
  const { showCustomers, setShowCustomers, areaIds, setAreaIds, excludedCustomers, setExcludedCustomers } = useContext(ProductContext);

  const modal = useModals();

  function tryAddExcludedCustomer() {
    modal.present({
      title: "Add Excluded Customer",
      children: <CustomersSelector onSelectCustomer={didSelectCustomer} />,
    });
  }

  function didSelectCustomer(customer) {
    modal.hide();
    setExcludedCustomers((prev) => [...prev, customer]);
  }

  return (
    <section className="space-y-4">
      <CharlesButtonWithArrow className="text-xl" show={showCustomers} onClick={() => setShowCustomers((prev) => !prev)}>
        Customers
      </CharlesButtonWithArrow>

      {showCustomers ? (
        <div className="mt-4">
          <div className="opacity-70 text-xs">
            We control the customers who can see this product by using groups and excludes. Only the product and the customer in the same group can see the
            product. If the customer is in the excluded list, they will not be able to see the product even if they are in the same group.
          </div>

          <div className="grid grid-cols-2 gap-8 mt-4">
            <div className="">
              <label className="w-24 pr-4 flex-shrink-0">Areas / Groups: </label>
              <div className="mt-4">
                <ProductAreasSelector value={areaIds} onChange={setAreaIds} />
              </div>
            </div>

            <div className="">
              <label htmlFor="">Excluded Customers</label>
              <div className="mt-2">
                {excludedCustomers.map((customer) => (
                  <div key={customer.id} className="flex items-center space-x-2 py-2 border-b border-gray-100 dark:border-gray-700">
                    <CharlesButton danger onClick={() => setExcludedCustomers((prev) => prev.filter((i) => i.id !== customer.id))}>
                      <BsTrash />
                    </CharlesButton>
                    <div>{customer.name}</div>
                  </div>
                ))}
                <CharlesButton className="mt-2" onClick={tryAddExcludedCustomer}>
                  + Add Excluded Customer
                </CharlesButton>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </section>
  );
};

export default CustomersView;
