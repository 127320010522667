import React, { useContext, useState } from 'react';
import productProps from '../productProps';
import Image from '../../../../components/Image';
import { Button } from '../../../../components/base';
import track, { actions } from '../../../../utils/track';
import { AppContext } from 'App';

function ProductField({
   className = '',
   product,
   field,
   displayName,
   value,
   metric,
}) {
   return (
      <div className={`${className} leading-loose flex`}>
         <label htmlFor="">{displayName}: </label>
         <div
            className={`${
               field === 'pantone' ? 'whitespace-pre-wrap' : ''
            } ml-2`}
         >
            {value ? value(product, metric) : product[field]}
         </div>
      </div>
   );
}

function ProductFieldGroup({
   className = '',
   product,
   title,
   fields = [],
   metric,
}) {
   return (
      <div
         className={`
            bg-gray-100 rounded-lg border border-gray-200 p-4 whitespace-no-wrap
            ${className}
         `}
      >
         {title ? <div className="text-gray-500 text-base">{title}</div> : null}
         {productProps
            .filter((i) => fields.includes(i.field))
            .map((i, index) => (
               <ProductField
                  key={index}
                  product={product}
                  {...i}
                  metric={metric}
               />
            ))}
      </div>
   );
}

function ProductDetail({ className = '', product }) {
   const { metric } = useContext(AppContext);
   const [showData, setShowData] = useState(false);
   return (
      <div
         className={`
            border-b border-gray-200 py-2
            ${className}

         `}
      >
         <div className="lg:flex items-center">
            {product.images.length > 0 ? (
               <div className="flex">
                  {product.images.map((i) => (
                     <Image
                        className="mr-2"
                        key={i.uuid}
                        size="w-8 h-8"
                        src={i.thumb}
                     />
                  ))}
               </div>
            ) : null}
            <div className="mt-2 lg:mt-0 ">
               <Button
                  size="sm"
                  color={product.isActive ? 'gray' : 'red'}
                  rightIcon={showData ? 'down' : 'right'}
                  title={product.name}
                  onClick={() =>
                     setShowData((prev) => {
                        let res = !prev;
                        if (res === true)
                           track(actions.product.showProductDetail.name, {
                              id: product.id,
                              name: product.name,
                           });
                        return res;
                     })
                  }
               />
               <div className="font-bold text-gray-600">#{product.number}</div>
            </div>
         </div>

         {showData ? (
            <div className="flex overflow-auto scrolling-touch items-start mt-4 mb-2 -mx-4 pr-4 lg:pr-6 lg:-mx-6">
               <ProductFieldGroup
                  className="mx-4 lg:mx-6"
                  product={product}
                  metric={metric}
                  fields={['moq', 'hsCode', 'productMaterial', 'pantone']}
               />

               <ProductFieldGroup
                  className="mr-4 lg:mr-6"
                  title="Size"
                  product={product}
                  metric={metric}
                  fields={[
                     'itemSize',
                     'innerBoxSize',
                     'displayBoxSize',
                     'innerCartonSize',
                     'outerCartonSize',
                     'outerCartonCbm',
                  ]}
               />

               <ProductFieldGroup
                  className="mr-4 lg:mr-6"
                  title="Weight"
                  product={product}
                  metric={metric}
                  fields={['itemWeight', 'ctnNetWeight', 'ctnGrossWeight']}
               />

               <div>
                  <ProductFieldGroup
                     className="mr-4 lg:mr-6"
                     title="Packing"
                     product={product}
                     metric={metric}
                     fields={[
                        'packing',
                        'quantityPerDisplayBox',
                        'quantityPerCarton',
                     ]}
                  />
               </div>
            </div>
         ) : null}
      </div>
   );
}

export default ProductDetail;
