import React, { useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import Spinner from 'components/Spinner';
import Errors from 'components/Errors';
import { Select } from 'components/Form';
import Product from 'components/Product';
import Page from 'components/PageContainer';

export const FETCH_AWC_DATA = gql`
   query FETCH_AWC_DATA {
      allLanguages {
         id
         name
      }
      allCommonText {
         id
         text
         trans {
            id
            text
            lang {
               id
            }
         }
      }
      allProductlines {
         id
         name
         mainImage
         texts {
            id
            text
            trans {
               id
               text
               lang {
                  id
                  name
               }
            }
         }
      }
   }
`;

function ArtworkCopy() {
   const [currentLang, setCurrentLang] = useState('1');

   const translatedText = (text, langId) => {
      const transText = text.trans.find((t) => t.lang.id === langId);
      if (transText) return transText.text;
      return null;
   };

   const { loading, error, data } = useQuery(FETCH_AWC_DATA);

   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;

   return (
      <Page className="lg:h-screen" title="Artwork Copy">
         <div className="m-4 lg:m-6 flex-1 overflow-auto rounded-lg">
            <table>
               <thead>
                  <tr>
                     <th className="px-6 py-3 sticky backdrop-blur-lg backdrop-filter bg-gray-900 dark:bg-opacity-70 top-0 border dark:border-gray-700 text-left w-1/2">
                        English
                     </th>
                     <th className="px-6 py-3 sticky backdrop-blur-lg backdrop-filter bg-gray-900 dark:bg-opacity-70 top-0 border dark:border-gray-700 text-left w-1/2">
                        <Select
                           value={
                              currentLang
                                 ? currentLang
                                 : data.allLanguages[0].id
                           }
                           onChange={(e) => setCurrentLang(e.target.value)}
                        >
                           {data.allLanguages.map((l) => (
                              <option key={l.id} value={l.id}>
                                 {l.name}
                              </option>
                           ))}
                        </Select>
                     </th>
                  </tr>
               </thead>
               <tbody className="bg-white dark:bg-gray-800">
                  {data.allCommonText.map((i) => (
                     <tr key={i.id}>
                        <td className="px-4 lg:px-6 py-4 border dark:border-gray-700 whitespace-pre-wrap">
                           {i.text}
                        </td>
                        <td className="px-4 lg:px-6 py-4 border dark:border-gray-700 whitespace-pre-wrap">
                           {translatedText(i, currentLang)}
                        </td>
                     </tr>
                  ))}

                  {data.allProductlines
                     .filter((p) => p.texts.length > 0)
                     .map((p) => (
                        <React.Fragment key={p.id}>
                           <tr className="bg-gray-300">
                              <td colSpan="2" className="py-4 px-6">
                                 <Product name={p.name} image={p.mainImage} />
                              </td>
                           </tr>
                           {p.texts.map((t) => (
                              <tr key={t.id}>
                                 <td className="py-4 px-4 lg:px-6 border dark:border-gray-700 whitespace-pre-wrap">
                                    {t.text}
                                 </td>
                                 <td className="py-4 px-4 lg:px-6 border dark:border-gray-700 whitespace-pre-wrap">
                                    {translatedText(t, currentLang)}
                                 </td>
                              </tr>
                           ))}
                        </React.Fragment>
                     ))}
               </tbody>
            </table>
         </div>
      </Page>
   );
}

export default ArtworkCopy;
