import { gql, useMutation, useQuery } from '@apollo/client';
import { Button, ButtonX } from 'components/base';
import Errors from 'components/Errors';
import { ArcherContainer, ArcherElement } from 'react-archer';
import { Field, Input, Select } from 'components/Form';
import { InlineSpinner } from 'components/Spinner';
import { useState } from 'react';
import { uuidv4 } from 'utils/uuid';
import { Alert } from 'components/Toast';

const SAVE_PRODUCTION = gql`
   mutation SAVE_PRODUCTION(
      $id: ID!
      $name: String!
      $categoryId: ID!
      $daysToTransfer: Int!
      $descendants: [ProductionNodeInputType]!
   ) {
      saveProduction(
         id: $id
         name: $name
         categoryId: $categoryId
         daysToTransfer: $daysToTransfer
         descendants: $descendants
      ) {
         production {
            id
            name
            category {
               id
               name
               factory {
                  id
                  name
               }
            }
            daysToTransfer
            descendants(includeSelf: false) {
               id
               name
               category {
                  id
                  name
                  factory {
                     id
                     name
                  }
               }
               parent {
                  id
               }
               daysToTransfer
            }
         }
      }
   }
`;

const ProductionEditor = ({
   initialData = {
      id: uuidv4(),
      name: '',
      category: null,
      daysToTransfer: 0,
      descendants: [],
   },
   onSave,
}) => {
   const [name, setName] = useState(initialData.name);
   const [rootNode, setRootNode] = useState({
      id: initialData.id,
      category: initialData.category,
      daysToTransfer: initialData.daysToTransfer,
   });
   const [descendants, setDescendants] = useState(initialData.descendants);

   const [saveProduction, saveProductionRes] = useMutation(SAVE_PRODUCTION, {
      onCompleted: (res) => {
         Alert('success', 'Saved.');
         if (onSave) onSave(res.saveProduction.production);
      },
      onError: (error) => Alert('error', error.message),
      refetchQueries: ['FETCH_ALL_PRODUCTION_NODES'],
      awaitRefetchQueries: true,
   });

   function saveHandler() {
      const variables = {
         id: rootNode.id,
         name,
         categoryId: rootNode.category.id,
         daysToTransfer: rootNode.daysToTransfer,
         descendants: descendants.map((d) => ({
            id: d.id,
            parentId: d.parentId,
            categoryId: d.category.id,
            daysToTransfer: d.daysToTransfer,
         })),
      };
      console.log('variables', variables);
      saveProduction({ variables });
   }

   function addNode(parentId) {
      const node = {
         id: uuidv4(),
         category: null,
         parentId,
         daysToTransfer: 0,
      };
      setDescendants((prev) => [...prev, node]);
   }

   function removeNode(id) {
      setDescendants((prev) => prev.filter((i) => i.id !== id));
   }

   function onChange(id, field, value) {
      if (id === rootNode.id) {
         setRootNode((prev) => ({ ...prev, [field]: value }));
      } else {
         setDescendants((prev) =>
            prev.map((i) => {
               if (i.id !== id) return i;
               return { ...i, [field]: value };
            })
         );
      }
   }

   return (
      <div>
         <div className="flex-1 overflow-auto">
            <Field label="Production Name" value={name} onChange={setName} />

            <div className="mt-6 w-full overflow-auto bg-white dark:bg-gray-900 p-10 rounded-3xl border border-gray-100 dark:border-gray-700">
               <ArcherContainer strokeColor="#aaa" strokeWidth={1}>
                  <ProductionNode
                     isRootNode={true}
                     node={rootNode}
                     descendants={descendants}
                     onChange={onChange}
                     addNode={addNode}
                     removeNode={removeNode}
                  />
               </ArcherContainer>
            </div>

            <div className="mt-8 flex justify-end">
               <Button
                  title="Save"
                  bold
                  size="2xl"
                  loading={saveProductionRes.loading}
                  disabled={saveProductionRes.loading}
                  onClick={saveHandler}
               />
            </div>
         </div>
      </div>
   );
};

const ProductionNode = ({
   isRootNode = false,
   node,
   descendants,
   onChange,
   addNode,
   removeNode,
}) => {
   const children = descendants.filter((i) => i.parentId === node.id);
   return (
      <div className="flex items-center space-x-24">
         {children.length > 0 ? (
            <div className="dark:border-gray-700 space-y-6 flex flex-col items-start">
               {children.map((i) => (
                  <ProductionNode
                     key={i.id}
                     node={i}
                     descendants={descendants}
                     onChange={onChange}
                     addNode={addNode}
                     removeNode={removeNode}
                  />
               ))}
            </div>
         ) : null}

         <ArcherElement
            id={node.id}
            relations={
               node.parentId
                  ? [
                       {
                          targetId: node.parentId,
                          targetAnchor: 'left',
                          sourceAnchor: 'right',
                       },
                    ]
                  : []
            }
         >
            <div
               className="bg-gray-50 dark:bg-gray-800 border shadow-sm p-6 rounded-2xl relative
               border-gray-100 dark:border-gray-700"
            >
               <div className="space-y-4">
                  <ProductionCategorySelector
                     value={node.category}
                     onChange={(value) => onChange(node.id, 'category', value)}
                  />
                  <div className="space-x-2">
                     <label htmlFor="">Transfer: </label>
                     <Input
                        className="w-12 text-center"
                        value={node.daysToTransfer}
                        onChange={(e) =>
                           onChange(node.id, 'daysToTransfer', e.target.value)
                        }
                     />
                     <span>Days</span>
                  </div>

                  <div className="flex justify-between items-center">
                     <Button
                        title="+ Prev Step"
                        onClick={(e) => {
                           e.stopPropagation();
                           addNode(node.id);
                        }}
                     />
                     {isRootNode ? null : (
                        <ButtonX onClick={() => removeNode(node.id)} />
                     )}
                  </div>
               </div>
            </div>
         </ArcherElement>
      </div>
   );
};

const FETCH_PRODUCTION_CATEGORIES = gql`
   query FETCH_PRODUCTION_CATEGORIES {
      allProductionCategories {
         id
         name
         factory {
            id
            name
         }
      }
   }
`;

const ProductionCategorySelector = ({ value, onChange }) => {
   const { loading, error, data } = useQuery(FETCH_PRODUCTION_CATEGORIES);

   if (loading) return <InlineSpinner size={16} />;
   if (error) return <Errors error={error} />;

   return (
      <Select
         className="w-60 text-xs"
         value={value ? value.id : '0'}
         onChange={(e) =>
            onChange(
               data.allProductionCategories.find((i) => i.id === e.target.value)
            )
         }
      >
         <option value="0" disabled>
            -- Select Producion Category --
         </option>
         {data.allProductionCategories.map((i) => (
            <option key={i.id} value={i.id}>
               {i.factory.name} : {i.name}
            </option>
         ))}
      </Select>
   );
};

export default ProductionEditor;
