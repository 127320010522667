import { gql, useQuery } from '@apollo/client';
import { NavigationBar } from 'components/base';
import Errors from 'components/Errors';
import Spinner from 'components/Spinner';
import { Link, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { DatePicker } from 'components/Form';
import { useState } from 'react';
import CharlesButton from 'components/charles/base';
import XLSX from 'xlsx';

const FETCH_INVENTORY_DATA = gql`
   query FETCH_INVENTORY_DATA($locationId: Int!, $date: DateTime!) {
      odooLocationStockAndCost(locationId: $locationId, date: $date)
   }
`;

const WarehouseDetail = () => {
   const { id } = useParams();
   const location = useLocation();
   const [date, setDate] = useState(new Date());
   const q = queryString.parse(location.search);

   const query = useQuery(FETCH_INVENTORY_DATA, {
      variables: { locationId: id, date },
   });

   function exportExcel(data) {
      const res = JSON.parse(data.odooLocationStockAndCost).map((i) => ({
         ...i,
         cost: Number(i.cost.toFixed(2)),
         total: Number((i.cost * i.qty).toFixed(2)),
      }));
      const sheet = XLSX.utils.json_to_sheet(res);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, sheet, 'stock');
      XLSX.writeFile(wb, q.name + '.xlsx');
   }

   return (
      <div className="flex flex-col flex-1 overflow-auto">
         <NavigationBar
            backTo="/stock/warehouses"
            title={q.name}
            rightButtons={
               <div>
                  <Link to={`history/?name=${q.name}`}>History</Link>
               </div>
            }
         />
         <div className="flex-1 flex flex-col p-4 overflow-auto">
            <div className="flex justify-between items-center px-1">
               <div className="flex space-x-3 items-center relative z-50">
                  <label>Inventory Date: </label>
                  <DatePicker value={date} onDayChange={setDate} />
               </div>
               <div>
                  <CharlesButton
                     disabled={!query.data}
                     onClick={() => exportExcel(query.data)}
                  >
                     Download
                  </CharlesButton>
               </div>
            </div>
            <div className="flex-1 overflow-auto card mt-4 p-0">
               <Content {...query} date={date} />
            </div>
         </div>
      </div>
   );
};

const Content = ({ loading, error, data }) => {
   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;

   if (!data.odooLocationStockAndCost)
      return <div className="p-6">This warehouse does not have any items.</div>;

   const res = JSON.parse(data.odooLocationStockAndCost).map((i) => ({
      ...i,
      cost: i.cost.toFixed(2),
      total: (i.cost * i.qty).toFixed(2),
   }));

   const totalQty = res.reduce((res, i) => res + i.qty, 0);
   const total = res
      .reduce((res, i) => res + parseFloat(i.total), 0)
      .toFixed(2);

   return (
      <>
         {res.length > 0 ? (
            <table>
               <thead>
                  <tr className="sticky top-0 bg-gray-50 bg-opacity-80 backdrop-blur">
                     <th className="px-4 py-3">Product</th>
                     <th className="text-right py-3 px-4">Cost</th>
                     <th className="text-right py-3 px-4">Quantity</th>
                     <th className="text-right py-3 px-4">Total</th>
                  </tr>
               </thead>
               <tbody>
                  {res.map((i) => (
                     <tr key={i.id} className="border-b border-gray-100">
                        <td className="px-4">
                           [{i.number}] {i.name}
                        </td>
                        <td className="text-right px-4">{i.cost}</td>
                        <td className="text-right px-4">{i.qty}</td>
                        <td className="text-right px-4">{i.total}</td>
                     </tr>
                  ))}
               </tbody>
               <tfoot>
                  <tr className="sticky bottom-0 bg-gray-50 bg-opacity-80 backdrop-blur text-sm">
                     <td className="px-4 py-3">
                        Total ({res.length} products)
                     </td>
                     <td></td>
                     <td className="px-4 text-right">{totalQty}</td>
                     <td className="px-4 text-right">{total}</td>
                  </tr>
               </tfoot>
            </table>
         ) : (
            <div className="py-4">This location does not have any items.</div>
         )}
      </>
   );
};

export default WarehouseDetail;
