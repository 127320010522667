import { AppContext } from 'App';
import React, { useContext } from 'react';

const ProductImage = ({ src }) => (
  <div
    className="product-image bg-contain bg-no-repeat bg-center"
    style={{
      backgroundImage: `url(${src})`,
      width: '30px',
      height: '30px',
    }}
  />
);

export const ProductRow = ({ product, qty, odooProductId }) => {
  const { hasPermission } = useContext(AppContext);
  return (
    <div className="rounded-2xl p-4 cursor-pointer mt-4 flex items-center bg-gray-100 hover:bg-gray-200 dark:bg-gray-800 dark:hover:bg-opacity-50">
      <div className="mr-4 flex-1 lg:flex items-center">
        <div className="flex mr-4">
          {product.images.map((image, index) => (
            <ProductImage key={index} src={image.url} />
          ))}
        </div>
        <div className="mt-2 lg:mt-0">
          {product.productLine ? (
            <div className="font-bold text-base mb-1">
              {product.productLine.name}
            </div>
          ) : null}
          <div className="text-xs opacity-50">
            {hasPermission()
              ? `#Odoo Product Id: #${odooProductId} `
              : null}
            [ {product.number} ] {product.name}
          </div>
        </div>
      </div>
      <div className="lg:mt-0 lg:ml-4 text-gray-500">✕ {qty}</div>
    </div>
  );
};
