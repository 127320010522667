import { gql, useMutation } from "@apollo/client";
import CharlesButton from "components/charles/base";
import { Input, Switcher } from "components/Form";
import { Alert } from "components/Toast";
import { useState } from "react";

const REDUCE_PLAN = gql`
  mutation REDUCE_PLAN($planId: ID!, $lines: [ProductionPlanLineInputType]!) {
    reducePlan(planId: $planId, lines: $lines) {
      productionPlan {
        id
        lines {
          id
          qty
          reduceQty
          finalQty
        }
        totalQty
        totalCbm
        totalPrice
      }
    }
  }
`;

const ReduceQtyView = ({ originalPlan, complete }) => {
  console.log("originalPlan", originalPlan);

  const [lines, setLines] = useState(originalPlan.lines);
  const [reducePlan, reducePlanRes] = useMutation(REDUCE_PLAN, {
    onCompleted() {
      Alert("success", "Plan updated.");
      if (complete) complete(lines);
    },
    onError(error) {
      Alert("error", error.message);
    },
  });

  const validLines = lines.every((i) => i.qty >= i.finalQty);

  return (
    <div>
      <div className="-mx-2">
        <table>
          <thead>
            <tr className=" whitespace-nowrap">
              <th>Product</th>
              <th className="text-right">Original QTY</th>
              <th className="text-right">Final QTY</th>
            </tr>
          </thead>
          <tbody>
            {lines.map((line, index) => (
              <tr key={line.id} className="border-b">
                <td>{line.name}</td>
                <td className="text-right">{line.qty}</td>
                <td className="text-right">
                  <div className="flex justify-end">
                    <Input
                      className="text-right border-0 bg-gray-200"
                      value={line.finalQty}
                      onChange={(e) => {
                        const finalQty = parseInt(e.target.value);
                        setLines((prev) =>
                          prev.map((prevLine, prevIndex) =>
                            prevIndex === index
                              ? {
                                  ...prevLine,
                                  finalQty,
                                  reduceQty: prevLine.qty - finalQty,
                                }
                              : prevLine
                          )
                        );
                      }}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="text-sm mt-6 text-red-600 font-semibold">
        When you reduce qty for a handled plan, there will be stock released back to our supplier. This might effect the later plans.
        <br />
        Reduce qty will not recompute the current plan. However, reduce qty will recompute the later plans.
      </div>

      <hr />

      <div>
        <CharlesButton
          primary
          loading={reducePlanRes.loading}
          disabled={!validLines}
          onClick={() => {
            reducePlan({
              variables: {
                planId: originalPlan.id,
                lines: lines.map((i) => ({
                  id: i.id,
                  reduceQty: i.qty - i.finalQty,
                })),
              },
            });
          }}
        >
          Confirm Reduce
        </CharlesButton>
        {validLines ? null : <div className="text-sm mt-2 text-red-600 font-semibold">You can only reduce qty.</div>}
      </div>
    </div>
  );
};

export default ReduceQtyView;
