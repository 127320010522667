import React from "react";
import { Button } from "./base";

function ShipmentTitle({ className = "", number, po = null, customerName, specialNotes = null, link = null, target = "_self", buttonSize = "sm", ...rest }) {
  return (
    <div className={`${className}`} {...rest}>
      {link ? (
        <Button size={buttonSize} target={target} link={link} title={`#${number} ${po ? `, PO: ${po}` : ""}`} />
      ) : (
        <div className="break-all font-semibold">
          #{number} {po ? `, PO: ${po}` : null}
        </div>
      )}
      <div className="opacity-70">{customerName}</div>
      {specialNotes !== null && <div className="opacity-70">{specialNotes}</div>}
    </div>
  );
}

export default ShipmentTitle;
