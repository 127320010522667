import { gql, useQuery } from '@apollo/client';
import Errors from 'components/Errors';
import { Select } from 'components/Form';
import { InlineSpinner } from 'components/Spinner';

const FETCH_SUB_SUPPLIER_ORDERS = gql`
   query FETCH_SUB_SUPPLIER_ORDERS {
      subSupplierOrders {
         total
         results {
            id
            odooPoId
            odooPoName
            odooPoOrigin
            estimateDeliveryDate
            remark
            productionSupplier {
               id
               name
            }
            invoiceTotal
         }
      }
   }
`;

const SubSupplierOrdersSelector = ({ value, onChange }) => {
   const { loading, error, data } = useQuery(FETCH_SUB_SUPPLIER_ORDERS);

   if (loading)
      return (
         <div className="relative">
            <InlineSpinner text={null} size={14} />
         </div>
      );

   if (error) return <Errors error={error} />;

   return (
      <Select value={value || '0'} onChange={(e) => onChange(e.target.value)}>
         <option value="0" disabled>
            Choose an Order
         </option>
         {data.subSupplierOrders.results.map((i) => (
            <option key={i.id} value={i.id}>
               {i.productionSupplier ? `${i.productionSupplier.name} - ` : null}
               {i.odooPoName ? i.odooPoName : `#${i.id}`}
            </option>
         ))}
      </Select>
   );
};

export default SubSupplierOrdersSelector;
