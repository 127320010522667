import { useState } from "react";
import { useLocation } from "react-router-dom";
import InlineReportCreateForm from "../inline/InlineReportCreateForm";
import FinalQcReportCreateForm from "./FinalQcReportCreateForm";

const QC_REPORT_TYPES = ["final", "inline"];

const CreateQcReportView = ({ onCreated }) => {
  const location = useLocation();
  const [type, setType] = useState(location.pathname.includes("final") ? "final" : "inline");

  return (
    <div>
      <div className="sm:flex items-center sm:space-x-4 mb-6">
        <div>
          <label>Report Type 类型:</label>
        </div>

        <div className="sm:flex items-center space-y-2 sm:space-y-0 sm:space-x-2 mt-4 sm:mt-0">
          {QC_REPORT_TYPES.map((i) => (
            <label key={i} className="flex space-x-1 items-center" htmlFor={`${i}-type`}>
              <input id={`${i}-type`} type="radio" value={i} checked={type === i} onChange={() => setType(i)} />
              <span className="capitalize">{i} Inspection</span>
            </label>
          ))}
        </div>
      </div>

      <div className="relative">
        {type === "final" ? (
          <FinalQcReportCreateForm onCreated={(id) => onCreated(`/qc/final/${id}`)} />
        ) : (
          <InlineReportCreateForm onCreated={(id) => onCreated(`/qc/inline/${id}`)} />
        )}
      </div>
    </div>
  );
};

export default CreateQcReportView;
