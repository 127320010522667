import { gql, useQuery } from "@apollo/client";
import Errors from "components/Errors";
import { InlineSpinner } from "components/Spinner";
import { CurrencyContext } from "CurrencyProvider";
import { productUnitCost } from "pages/price/utils";
import { useContext } from "react";

const FETCH_BOM = gql`
  query FETCH_BOM($ids: [ID]) {
    products(ids: $ids) {
      results {
        id
        defaultSupplier {
          id
        }
        prices {
          id
          price
          archivedAt
          avaliableFromMonth
          avaliableToMonth
          minQty
          currency
          factory {
            id
          }
        }
      }
    }
  }
`;

const CostPreview = ({ itemsPerSet, bom, qty }) => {
  const { latestExchangeRate } = useContext(CurrencyContext);
  const ids = bom.map((i) => i.product.id);
  const { loading, error, data } = useQuery(FETCH_BOM, {
    variables: { ids },
  });

  if (loading) return <InlineSpinner text={null} size={21} />;
  if (error) return <Errors error={error} />;

  let notFoundChildProduct = false;

  const product = {
    prices: [],
    itemsPerSet: parseInt(itemsPerSet),
    children: bom.map((i) => {
      const childProduct = data.products.results.find((p) => p.id === i.product.id);
      if (!childProduct) notFoundChildProduct = true;
      return {
        product: childProduct,
        qty: parseInt(i.qty),
      };
    }),
  };

  if (notFoundChildProduct) return null;

  if (qty < itemsPerSet) return `Product default qty must be at least ${itemsPerSet}`;

  return `$ ${productUnitCost(product, qty, latestExchangeRate.rate).toFixed(3)}`;
};

export default CostPreview;
