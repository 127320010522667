const { useQuery, gql } = require('@apollo/client');

const FETCH_DATA = gql`
   query FETCH_ODOO_PRODUCT_CATEGORIES {
      odooProductCategories {
         id
         displayName
      }
   }
`;

const useOdooProductCategories = () => {
   const { loading, error, data } = useQuery(FETCH_DATA);

   const odooProductCategories = data ? data.odooProductCategories : null;

   return { loading, error, odooProductCategories };
};

export default useOdooProductCategories;
