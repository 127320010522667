import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import Spinner from '../../../components/Spinner.js';
import Errors from '../../../components/Errors.js';

const FETCH_CALCULATE_HISTORY = gql`
   query FETCH_CALCULATE_HISTORY {
      allCalculateHistories {
         id
         name
         createdAt
         createdBy {
            id
            email
         }
      }
   }
`;

function CalculateHistory() {
   const navigate = useNavigate();
   const { loading, error, data } = useQuery(FETCH_CALCULATE_HISTORY, {
      fetchPolicy: 'cache-and-network',
   });
   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;
   return (
      <div className="m-6 overflow-auto rounded-lg">
         <table className="bg-white dark:bg-gray-800 mb-6">
            <tbody>
               {data.allCalculateHistories.map((i, index) => (
                  <tr
                     className="cursor-pointer hover:bg-blue-200 dark:hover:bg-blue-800"
                     key={index}
                     onClick={(_) => navigate(`${i.id}`)}
                  >
                     <td className="px-6 border-b border-gray-100 dark:border-gray-700">
                        <div>
                           <div>{i.name}</div>
                           <div className="text-xs text-gray-600">
                              {i.createdBy.email}
                           </div>
                        </div>
                     </td>
                     <td className="px-6 border-b border-gray-100 dark:border-gray-700">
                        <div className="text-right text-xs text-gray-600">
                           {new Date(i.createdAt).toLocaleString()}
                        </div>
                     </td>
                  </tr>
               ))}
            </tbody>
         </table>
      </div>
   );
}

export default CalculateHistory;
