import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { LayoutWithProductLines } from '../products';
import ProductDetail from './Detail';

const SalesAB = () => (
   <Routes>
      <Route element={<LayoutWithProductLines />}>
         <Route path=":id" element={<ProductDetail />} />
         <Route
            index
            render={() => (
               <div className="flex items-center justify-center h-full">
                  <label className="text-xl">
                     Select a Product to view the cost.
                  </label>
               </div>
            )}
         />
      </Route>
   </Routes>
);

export default SalesAB;
