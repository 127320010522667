import React from 'react';
import { HiOutlineChevronLeft } from 'react-icons/hi';
import { Link } from 'react-router-dom';

export function BackLink({ to, ...rest }) {
   return (
      <Link className="block cursor-pointer animate-ease-2" to={to} {...rest}>
         <HiOutlineChevronLeft className="inline-block" size={21} />
      </Link>
   );
}

function BackButton({ size, color, to, onClick, ...rest }) {
   const style = {
      width: size ? size : 24,
      height: size ? size : 24,
   };
   const backImage = (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
         <path
            fill={`${color ? color : 'white'}`}
            d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"
         />
      </svg>
   );
   if (to) {
      return (
         <a
            className="block cursor-pointer animate-ease-2"
            href={to}
            style={style}
            {...rest}
         >
            {backImage}
         </a>
      );
   }
   return (
      <div
         className="block cursor-pointer animate-ease-2"
         style={style}
         onClick={onClick}
      >
         {backImage}
      </div>
   );
}

export default BackButton;
