import { useMutation, useQuery } from "@apollo/client";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import { FETCH_PRODUCT_DETAIL, SAVE_PRODUCT } from "./graphql";
import { createContext, useContext, useEffect, useState } from "react";
import { Alert } from "components/Toast";
import useOdooProduct from "hooks/useOdooProduct";
import { SYNC_PRODUCT_PRICES_TO_ODOO } from "../odoo/ComputedPriceSyncer";
import { useModals } from "ModalProvider";
import { newSimpleFields, newSize } from "./FormFields";
import BottomBar from "./BottomBar";
import OdooInfoView from "./OdooInfoView";
import CostView from "./CostView";
import BOMView from "./BOMView";
import ProductionView from "./ProductionView";
import PackingShipmentView from "./PackingShipmentView";
import CustomersView from "../CustomersView";
import BaseInfoView from "./BaseInfoView";
import NotificationView from "../NotificationView";
import WarningsView from "./WarningsView";
import { parseError } from "apollo";
import ProductListSelector from "./ProductListSelector";
import { AppContext } from "App";
import { MANAGE_EDITOR_PARTIAL } from "utils/permissions";

export const ProductContext = createContext({});

const ProductForm = ({
  id = null,
  initialData = null,
  duplicate,
  duplicatingProduct,
  onSave,
  hide,
  onSaveAndClose,
}) => {
  const { hasPermission } = useContext(AppContext);

  const { loading, error, data } = useQuery(FETCH_PRODUCT_DETAIL, {
    variables: { id },
    skip: id === null,
  });

  const [saveProduct, saveProductRes] = useMutation(SAVE_PRODUCT, {
    onCompleted() {
      Alert("success", "Saved.");
    },
    onError: (err) => Alert("error", parseError(err)),
    refetchQueries:
      id === null
        ? [
            "FETCH_PRODUCTS_FOR_SALE",
            "FETCH_WIS_PRODUCTS",
            "FETCH_NEW_PRODUCTS",
          ]
        : [],
  });
  const [syncPriceToOdoo, syncPriceToOdooRes] = useMutation(
    SYNC_PRODUCT_PRICES_TO_ODOO,
    {
      onCompleted: () => Alert("success", "Prices is synced."),
      onError: (error) => Alert("error", error.message),
    },
  );

  const [showCost, setShowCost] = useState(false);
  const [showBom, setShowBom] = useState(false);
  const [showPackingShipment, setShowPackingShipment] = useState(false);
  const [showCustomers, setShowCustomers] = useState(false);
  const [showProduction, setShowProduction] = useState(false);

  const [simpleFields, setSimpleFields] = useState(newSimpleFields);
  const [productLineId, setProductLineId] = useState("0");
  const [defaultSupplierId, setDefaultSupplierId] = useState(null);
  const [producerId, setProduceerId] = useState(null);
  const [deliverToSupplierId, setDeliverToSupplierId] = useState(null);
  const [size, setSize] = useState(newSize);
  const [children, setChildren] = useState([]);
  const [areaIds, setAreaIds] = useState([]);
  const [excludedCustomers, setExcludedCustomers] = useState([]);
  const [prices, setPrices] = useState([]);
  const [productionId, setProductionId] = useState(null);
  const [productionCategoryId, setProductionCategoryId] = useState("0");
  const [sendNotification, setSendNotification] = useState(false);
  const [images, setImages] = useState([]);

  const [productListId, setProductListId] = useState("0");

  const [warningIds, setWarningIds] = useState([]);
  const [labTestStandardIds, setLabTestStandardIds] = useState([]);
  const [pantone, setPantone] = useState("");

  const odooProduct = useOdooProduct(data ? data.product.odooId : undefined);
  const odooSyncerModal = useModals();

  useEffect(() => {
    if (duplicatingProduct) {
      setSimpleFields(duplicatingProduct.simpleFields);
      setProductLineId(duplicatingProduct.productLineId);
      setProductionCategoryId(duplicatingProduct.productionCategoryId);
      setProductionId(duplicatingProduct.productionId);
      setSize(duplicatingProduct.size);
      setChildren(duplicatingProduct.children);
      setAreaIds(duplicatingProduct.areaIds);
      setExcludedCustomers(duplicatingProduct.excludedCustomers);
      setPrices(duplicatingProduct.prices);
      setLabTestStandardIds(duplicatingProduct.labTestStandardIds);
      setWarningIds(duplicatingProduct.warningIds);
      setPantone(duplicatingProduct.pantone);
      setProductListId(duplicatingProduct.productListId);

      setShowCost(duplicatingProduct.prices.length > 0);
      setShowBom(duplicatingProduct.children.length > 0);
      setShowProduction(
        !!(
          duplicatingProduct.productionCategoryId ||
          duplicatingProduct.productionId
        ),
      );
      setShowPackingShipment(
        !!duplicatingProduct.simpleFields.packing ||
          !!duplicatingProduct.simpleFields.packingName ||
          !!duplicatingProduct.simpleFields.barCode,
      );
      setShowCustomers(duplicatingProduct.areaIds.length > 0);
    } else if (!id) {
      setSimpleFields(newSimpleFields);
      setSize(newSize);
      setPrices([]);
      setChildren([]);
      setAreaIds([]);
      setExcludedCustomers([]);
      setShowCost(false);
      setShowBom(false);
      setShowPackingShipment(false);
      setShowCustomers(false);
      setShowProduction(false);
    }
  }, [id, duplicatingProduct]);

  useEffect(() => {
    if (data) {
      const fields = Object.keys(newSimpleFields).reduce((prev, key) => {
        return { ...prev, [key]: data.product[key] };
      }, {});
      console.log("fields", fields);
      setSimpleFields(fields);
      setProductLineId(
        data.product.productLine ? data.product.productLine.id : "0",
      );
      setDefaultSupplierId(
        data.product.defaultSupplier ? data.product.defaultSupplier.id : null,
      );
      setProduceerId(data.product.producer ? data.product.producer.id : null);
      setDeliverToSupplierId(
        data.product.deliverToSupplier
          ? data.product.deliverToSupplier.id
          : null,
      );
      setSize({
        shape: data.product.shape,
        ballDiameter: data.product.ballDiameter,
        cubeX: data.product.cubeX,
        cubeY: data.product.cubeY,
        cubeZ: data.product.cubeZ,
        flatX: data.product.flatX,
        flatY: data.product.flatY,
        cylindricalDiameter: data.product.cylindricalDiameter,
        cylindricalHeight: data.product.cylindricalHeight,
        multiSizes: data.product.multiSizes,
      });
      setChildren(data.product.children);
      setAreaIds(data.product.areas.map((i) => i.id));
      setExcludedCustomers(
        data.product.excludedCustomers.map((i) => ({ id: i.id, name: i.name })),
      );
      setPrices(
        data.product.prices.map((i) => ({
          id: i.id,
          minQty: i.minQty,
          price: i.price,
          currency: i.currency,
          factoryId: i.factory ? i.factory.id : null,
          supplierUpdatedAt: i.supplierUpdatedAt,
          avaliableFromMonth: i.avaliableFromMonth,
          avaliableToMonth: i.avaliableToMonth,
          archivedAt: i.archivedAt ? new Date(i.archivedAt) : null,
          archivedCode: i.archivedCode,
          archivedNotes: i.archivedNotes,
        })),
      );
      setProductionId(
        data.product.production ? data.product.production.id : null,
      );
      setProductionCategoryId(
        data.product.productionCategory
          ? data.product.productionCategory.id
          : null,
      );
      setWarningIds(data.product.warnings.map((i) => i.id));
      setLabTestStandardIds(data.product.labTestStandards.map((i) => i.id));
      setPantone(data.product.pantone);
      setProductListId(
        data.product.productList ? data.product.productList.id : null,
      );

      setShowProduction(
        !!(data.product.production || data.product.productionCategory),
      );
      setShowCost(data.product.prices.length > 0);
      setShowBom(data.product.children.length > 0);
      setShowPackingShipment(
        !!data.product.packing ||
          !!data.product.packingName ||
          !!data.product.barCode,
      );
      setShowCustomers(data.product.areas.length > 0);

      if (initialData)
        if (initialData.type === "production") {
          setShowBom(false);
          setShowCustomers(false);
          setShowPackingShipment(false);
          setShowProduction(true);
        }
    }
  }, [data]);

  useEffect(() => {
    if (initialData) {
      if (initialData.productLineId)
        setProductLineId(initialData.productLineId);
      setPrices(initialData.prices);
      setShowCost(true);
    }
  }, [initialData]);

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const outerCartonCbm = (product) =>
    ((parseFloat(product.outerCartonX) + 0.5) *
      (parseFloat(product.outerCartonY) + 0.5) *
      (parseFloat(product.outerCartonZ) + 0.5)) /
    (100 * 100 * 100);

  function onChangeSimpleFields(field, value) {
    setSimpleFields((prev) => ({ ...prev, [field]: value }));
  }

  function onChangeComponentPrice(index, field, value) {
    setPrices((prev) =>
      prev.map((prevPrice, prevPriceIndex) => {
        if (prevPriceIndex !== index) return prevPrice;
        return { ...prevPrice, [field]: value };
      }),
    );
  }

  function validInput() {
    if (simpleFields.name === "") {
      Alert("error", "At least give me a name please..");
      return false;
    }
    if (simpleFields.name === "") {
      Alert(
        "error",
        "Item number for a product is required. Everything has a number.",
      );
      return false;
    }

    const invalidPrices = prices.filter(
      (i) => i.minQty === "" || i.price === "" || i.factoryId === "0",
    );
    if (invalidPrices.length > 0) {
      Alert(
        "error",
        "You have invalid price, make sure you input factory, qty and price for each price.",
      );
      return false;
    }
    return true;
  }

  const cleanedSize = Object.entries(size).reduce((res, [key, value]) => {
    if (key === "shape" || key === "multiSizes") {
      res[key] = value;
    } else {
      if (isNaN(parseFloat(value))) {
        res[key] = 0;
      } else {
        res[key] = parseFloat(value);
      }
    }
    return res;
  }, {});

  const variables = {
    id,
    simpleFields: {
      ...cleanedSize,
      ...simpleFields,
      producerId,
      deliverToSupplierId,
      odooTemplateId: simpleFields.odooTemplateId
        ? simpleFields.odooTemplateId
        : null,
      odooId: simpleFields.odooId ? simpleFields.odooId : null,
      odooCategoryId:
        simpleFields.odooCategoryId !== "0"
          ? simpleFields.odooCategoryId
          : null,
      barCode: simpleFields.barCode !== "" ? simpleFields.barCode : null,
      packing: simpleFields.packing,
      packingName: simpleFields.packingName,
      itemsPerSet: simpleFields.itemsPerSet ? simpleFields.itemsPerSet : null,
      defaultQty: simpleFields.defaultQty ? simpleFields.defaultQty : null,
      moq: simpleFields.moq ? simpleFields.moq : null,
      itemWeight: simpleFields.itemWeight ? simpleFields.itemWeight : null,
      grossWeight: simpleFields.grossWeight ? simpleFields.grossWeight : null,
      innerBoxX: simpleFields.innerBoxX ? simpleFields.innerBoxX : null,
      innerBoxY: simpleFields.innerBoxY ? simpleFields.innerBoxY : null,
      innerBoxZ: simpleFields.innerBoxZ ? simpleFields.innerBoxZ : null,
      displayBoxX: simpleFields.displayBoxX ? simpleFields.displayBoxX : null,
      displayBoxY: simpleFields.displayBoxY ? simpleFields.displayBoxY : null,
      displayBoxZ: simpleFields.displayBoxZ ? simpleFields.displayBoxZ : null,
      innerCartonX: simpleFields.innerCartonX
        ? simpleFields.innerCartonX
        : null,
      innerCartonY: simpleFields.innerCartonY
        ? simpleFields.innerCartonY
        : null,
      innerCartonZ: simpleFields.innerCartonZ
        ? simpleFields.innerCartonZ
        : null,
      outerCartonX: simpleFields.outerCartonX
        ? simpleFields.outerCartonX
        : null,
      outerCartonY: simpleFields.outerCartonY
        ? simpleFields.outerCartonY
        : null,
      outerCartonZ: simpleFields.outerCartonZ
        ? simpleFields.outerCartonZ
        : null,
      quantityPerDisplayBox: simpleFields.quantityPerDisplayBox
        ? simpleFields.quantityPerDisplayBox
        : null,
      quantityPerCarton: simpleFields.quantityPerCarton
        ? simpleFields.quantityPerCarton
        : null,
      ctnNetWeight: simpleFields.ctnNetWeight
        ? simpleFields.ctnNetWeight
        : null,
      ctnGrossWeight: simpleFields.ctnGrossWeight
        ? simpleFields.ctnGrossWeight
        : null,
    },
    productLineId,
    productionId,
    productionCategoryId,
    defaultSupplierId,
    children: children
      .filter((i) => i.product !== null)
      .map((i) => ({
        qty: i.qty,
        productId: i.product.id,
        productOdooId: i.product.odooId,
      })),
    areaIds,
    excludedCustomerIds: excludedCustomers.map((i) => i.id),
    prices,
    oneDriveImageIds: images.map((i) => i.id),
    pantone,
    warningIds,
    labTestStandardIds,
    productListId,
    sendNotification,
  };

  // console.log("variables", variables);

  function duplicateHandler() {
    duplicate({
      simpleFields: {
        ...simpleFields,
        odooTemplateId: "",
        odooId: "",
      },
      productLineId,
      size,
      children,
      areaIds,
      excludedCustomers,
      prices: prices.map((i) => ({
        minQty: i.minQty,
        price: i.price,
        currency: i.currency,
        factoryId: i.factoryId,
        avaliableFromMonth: i.avaliableFromMonth,
        avaliableToMonth: i.avaliableToMonth,
        supplierUpdatedAt: i.supplierUpdatedAt,
      })),
      productionCategoryId,
      productionId,
    });
  }

  return (
    <ProductContext.Provider
      value={{
        id,
        data,
        simpleFields,
        setSimpleFields,
        duplicate,
        onSave,
        onSaveAndClose,
        variables,
        saveProductRes,
        saveProduct,
        validInput,
        odooSyncerModal,
        setSendNotification,
        sendNotification,
        duplicateHandler,
        hide,
        odooProduct,
        showCost,
        setShowCost,
        showBom,
        setShowBom,
        prices,
        setPrices,
        syncPriceToOdoo,
        syncPriceToOdooRes,
        children,
        setChildren,
        showProduction,
        setShowProduction,
        showPackingShipment,
        setShowPackingShipment,
        outerCartonCbm,
        showCustomers,
        setShowCustomers,
        areaIds,
        setAreaIds,
        excludedCustomers,
        setExcludedCustomers,
        size,
        setSize,
        images,
        setImages,
        productionId,
        setProductionId,
        productionCategoryId,
        setProductionCategoryId,
        productLineId,
        setProductLineId,
        defaultSupplierId,
        setDefaultSupplierId,
        producerId,
        setProduceerId,
        deliverToSupplierId,
        setDeliverToSupplierId,
        onChangeSimpleFields,
        onChangeComponentPrice,
        warningIds,
        setWarningIds,
        labTestStandardIds,
        setLabTestStandardIds,
        pantone,
        setPantone,
      }}
    >
      <div
        className="p-10 pb-0 flex flex-col flex-1 text-xs"
        data-testid="manage-product-form"
      >
        {hasPermission() ? (
          <div className="flex-1 pb-10">
            <div className="flex-1 grid grid-cols-12 gap-6 pb-10">
              <div className="col-span-9 space-y-6">
                <div className="card px-6 py-4">
                  <BaseInfoView />
                </div>
              </div>

              <div className="space-y-6 col-span-3 flex flex-col">
                <div className="card px-6 py-4 relative overflow-auto">
                  <h5>Product List</h5>
                  <div className="mt-3">
                    <ProductListSelector
                      value={productListId}
                      onChange={setProductListId}
                    />
                  </div>
                </div>

                <div className="card px-6 py-4 relative overflow-auto">
                  <OdooInfoView />
                </div>

                <div className="card px-6 py-4">
                  <NotificationView />
                </div>

                {/* <div className="card px-6 py-4 relative overflow-auto">
                <div>
                  <h5>Note</h5>
                  <Text
                    value={simpleFields.note}
                    onChange={(e) => {
                      onChangeSimpleFields("note", e.target.value);
                    }}
                    placeholder="Add a note here to remind others about this product."
                    rows={4}
                    className="mt-2"
                  />
                </div>
              </div> */}
              </div>
            </div>

            <div className="space-y-8">
              <div className="card px-6 py-4 relative">
                <CostView />
              </div>

              <div className="card px-6 py-4">
                <BOMView />
              </div>

              <div className="card px-6 py-4">
                <WarningsView />
              </div>

              <div className="card px-6 py-4 relative overflow-auto">
                <ProductionView />
              </div>

              <div className="card px-6 py-4 relative overflow-auto">
                <PackingShipmentView />
              </div>

              <div className="card p-6 relative overflow-auto">
                <CustomersView />
              </div>
            </div>
          </div>
        ) : hasPermission(MANAGE_EDITOR_PARTIAL) ? (
          <div className="flex-1 pb-10">
            <div className="card px-6 py-4 relative overflow-auto">
              <PackingShipmentView />
            </div>
          </div>
        ) : null}

        <BottomBar />
      </div>
    </ProductContext.Provider>
  );
};

export default ProductForm;
