import { useContext } from "react";
import { ProductListContext } from "./Form";
import CharlesButton from "components/charles/base";
import { Input, Text } from "components/Form";
import { BsTrash } from "react-icons/bs";
import InputSelect from "components/InputSelect";
import { CubeSizeInput } from "components/ItemSizeInput";
import Images from "./Images";

const newPackaging =
  process.env.NODE_ENV === "development"
    ? {
        name: "Test Packaging",
        weight: 10,
        materialName: "ABS",
        size: { x: 60, y: 90, z: 50 },
        plasticWeight: 3,
      }
    : {
        name: "",
        weight: 0,
        materialName: "",
        size: { x: 0, y: 0, z: 0 },
        plasticWeight: 0,
      };

const Packaging = () => {
  const { allMaterials, data, setData, onAddNewImages, removeImage, removeNewImage } = useContext(ProductListContext);

  function addPackaging() {
    setData((prev) => ({
      ...prev,
      packagings: [...prev.packagings, newPackaging],
    }));
  }

  function onChangePackaging(index, key, value) {
    setData((prev) => ({
      ...prev,
      packagings: prev.packagings.map((i, iIndex) => {
        if (iIndex === index) {
          return { ...i, [key]: value };
        }
        return i;
      }),
    }));
  }

  return (
    <div className="card">
      <h5>Packaging Specification</h5>

      <div>
        {data.packagings.length === 0 ? (
          <div className="mt-3 opacity-70">No packaging for this product list at the momment.</div>
        ) : (
          data.packagings.map((i, index) => (
            <div key={index} className="border-b border-gray-100 dark:border-gray-700 py-8 space-y-4 relative">
              <div className="flex justify-between items-start">
                <div className="space-x-3 flex items-center">
                  <label className="w-16">Name: </label>
                  <Input onChange={(e) => onChangePackaging(index, "name", e.target.value)} value={i.name} />
                </div>

                <CharlesButton
                  danger
                  onClick={() => {
                    if (window.confirm("Are you sure you want to delete this packaging?")) {
                      setData((prev) => {
                        const packagings = [...prev.packagings];
                        packagings.splice(index, 1);
                        return { ...prev, packagings };
                      });
                    }
                  }}
                >
                  <BsTrash />
                </CharlesButton>
              </div>

              <div className="space-x-3 flex items-center">
                <label className="w-16">Material: </label>
                <InputSelect
                  className="flex-1"
                  options={allMaterials}
                  onChange={(value) => onChangePackaging(index, "materialName", value)}
                  onSelect={(material) => onChangePackaging(index, "materialName", material.name)}
                  value={i.materialName}
                />
              </div>

              <div className="space-x-3 flex items-center">
                <label className="w-16">Size: </label>
                <CubeSizeInput {...i.size} onChange={(size) => onChangePackaging(index, "size", size)} />
              </div>

              <div className="flex space-x-8">
                <div className="space-x-3 flex items-center">
                  <label className="w-16">Weight: </label>
                  <Input type="number" className="ml-4 text-right" value={i.weight} onChange={(e) => onChangePackaging(index, "weight", e.target.value)} />
                  <span>g</span>
                </div>

                <div className="space-x-3 flex items-center">
                  <label>Plastic Weight: </label>
                  <Input
                    type="number"
                    className="ml-4 text-right"
                    value={i.plasticWeight}
                    onChange={(e) => onChangePackaging(index, "plasticWeight", e.target.value)}
                  />
                  <span>g</span>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
      <div className="mt-4">
        <div>
          <CharlesButton onClick={addPackaging}>+ Add Packaging</CharlesButton>
        </div>
      </div>

      <hr className=" dark:bg-gray-800 my-6" />

      <div>
        <h5>Outer Carton</h5>
        <div className="mt-2">
          <div className="flex items-center mb-4">
            <label className="inline-block mr-4">Master Carton Size 长(x) x 宽(z) x 高(y):</label>
            <CubeSizeInput
              x={data.cartonX}
              y={data.cartonY}
              z={data.cartonZ}
              unit="cm"
              onChange={({ x, y, z }) => setData((prev) => ({ ...prev, cartonX: x, cartonY: y, cartonZ: z }))}
            />
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div className=" space-x-3">
              <label htmlFor="">Qty / Display Box: </label>
              <Input className="ml-4" value={data.qtyPerDisplayBox} onChange={(e) => setData({ ...data, qtyPerDisplayBox: e.target.value })} />
            </div>
            <div className=" space-x-3">
              <label htmlFor="">Qty / Master Carton: </label>
              <Input className="ml-4" value={data.qtyPerCarton} onChange={(e) => setData({ ...data, qtyPerCarton: e.target.value })} />
            </div>
            <div className=" space-x-3">
              <label htmlFor="">Outer Carton Net Weight: </label>
              <Input className="ml-4" value={data.cartonNetWeight} onChange={(e) => setData({ ...data, cartonNetWeight: e.target.value })} />
              <span>KG</span>
            </div>
            <div className=" space-x-3">
              <label htmlFor="">Outer Carton Gross Weight: </label>
              <Input className="ml-4" value={data.cartonGrossWeight} onChange={(e) => setData({ ...data, cartonGrossWeight: e.target.value })} />
              <span>KG</span>
            </div>

            <div className="col-span-2">
              <label htmlFor="">Packaging Remark</label>
              <Text className="mt-3" value={data.packagingRemark} onChange={(e) => setData({ ...data, packagingRemark: e.target.value })} />
            </div>
          </div>

          <div className="mt-4">
            <Images
              buttonText="Upload Packaging Specification Images"
              type="packagingRemarkImages"
              newType="newPackagingRemarkImages"
              data={data}
              onAddNewImages={onAddNewImages}
              removeImage={removeImage}
              removeNewImage={removeNewImage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Packaging;
