import { Select } from "components/Form";
import { useEffect, useState } from "react";
import { setTheme as setGlobalTheme } from "utils/theme";

const Appearance = () => {
  const [theme, setTheme] = useState(localStorage.getItem("theme") ? localStorage.getItem("theme") : "auto");

  useEffect(() => {
    setGlobalTheme(theme);
    localStorage.setItem("theme", theme);
  }, [theme]);

  function toggleTheme() {
    switch (theme) {
      case "auto":
        setTheme("dark");
        break;
      case "dark":
        setTheme("light");
        break;
      case "light":
        setTheme("auto");
        break;
      default:
        setTheme("auto");
    }
  }

  return (
    <div className="card">
      <h5>Appearance</h5>
      <div className="mt-4">
        <div className="space-x-4">
          <label htmlFor="">Dark Mode:</label>
          <Select value={theme} onChange={(e) => setTheme(e.target.value)}>
            <option value="auto">Auto</option>
            <option value="light">Light</option>
            <option value="dark">Dark</option>
          </Select>
        </div>
      </div>
    </div>
  );
};

export default Appearance;
