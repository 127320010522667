export const STATUS_COLOR = {
  PASS: "green",
  FAIL: "red",
  PENDING: "orange",
};

export const CHECK_STATUS = ["PASS", "FAIL", "PENDING", "N/A"];

export const calcOverallStatus = (packageCheck, qualityCheck, logoCheck) => {
  if (packageCheck === "FAIL" || qualityCheck === "FAIL" || logoCheck === "FAIL") {
    return "FAIL";
  } else if (packageCheck === "PENDING" || qualityCheck === "PENDING" || logoCheck === "PENDING") {
    return "PENDING";
  }
  return "PASS";
};

const getAQLIndex = (qty) => {
  if (qty < 2) return 0;
  if (qty >= 2 && qty <= 8) return 0;
  if (qty >= 9 && qty <= 15) return 1;
  if (qty >= 16 && qty <= 25) return 2;
  if (qty >= 26 && qty <= 50) return 3;
  if (qty >= 51 && qty <= 90) return 4;
  if (qty >= 91 && qty <= 150) return 5;
  if (qty >= 151 && qty <= 280) return 6;
  if (qty >= 281 && qty <= 500) return 7;
  if (qty >= 501 && qty <= 1200) return 8;
  if (qty >= 1201 && qty <= 3200) return 9;
  if (qty >= 3201 && qty <= 10000) return 10;
  if (qty >= 10001 && qty <= 35000) return 11;
  if (qty >= 35001 && qty <= 150000) return 12;
  if (qty >= 150001 && qty <= 500000) return 13;
  if (qty >= 500001) return 14;
};

const AQLTable = [
  {
    checkQty: 2,
    aql2_5: {
      accept: 0,
      reject: 0,
    },
    aql4_0: {
      aceept: 0,
      reject: 0,
    },
  },
  {
    checkQty: 3,
    aql2_5: {
      accept: 0,
      reject: 0,
    },
    aql4_0: {
      aceept: 0,
      reject: 1,
    },
  },
  {
    checkQty: 5,
    aql2_5: {
      accept: 0,
      reject: 1,
    },
    aql4_0: {
      aceept: 0,
      reject: 1,
    },
  },
  {
    checkQty: 8,
    aql2_5: {
      accept: 0,
      reject: 1,
    },
    aql4_0: {
      aceept: 0,
      reject: 1,
    },
  },
  {
    checkQty: 13,
    aql2_5: {
      accept: 0,
      reject: 1,
    },
    aql4_0: {
      aceept: 1,
      reject: 2,
    },
  },
  {
    checkQty: 20,
    aql2_5: {
      accept: 1,
      reject: 2,
    },
    aql4_0: {
      aceept: 2,
      reject: 3,
    },
  },
  {
    checkQty: 32,
    aql2_5: {
      accept: 2,
      reject: 3,
    },
    aql4_0: {
      aceept: 3,
      reject: 4,
    },
  },
  {
    checkQty: 50,
    aql2_5: {
      accept: 3,
      reject: 4,
    },
    aql4_0: {
      aceept: 5,
      reject: 6,
    },
  },
  {
    checkQty: 80,
    aql2_5: {
      accept: 5,
      reject: 6,
    },
    aql4_0: {
      aceept: 7,
      reject: 8,
    },
  },
  {
    checkQty: 125,
    aql2_5: {
      accept: 7,
      reject: 8,
    },
    aql4_0: {
      aceept: 10,
      reject: 11,
    },
  },

  {
    checkQty: 200,
    aql2_5: {
      accept: 10,
      reject: 11,
    },
    aql4_0: {
      aceept: 14,
      reject: 15,
    },
  },
  {
    checkQty: 315,
    aql2_5: {
      accept: 14,
      reject: 15,
    },
    aql4_0: {
      aceept: 21,
      reject: 22,
    },
  },
  {
    checkQty: 500,
    aql2_5: {
      accept: 21,
      reject: 22,
    },
    aql4_0: {
      aceept: 0,
      reject: 0,
    },
  },
  {
    checkQty: 800,
    aql2_5: {
      accept: 0,
      reject: 0,
    },
    aql4_0: {
      aceept: 0,
      reject: 0,
    },
  },
  {
    checkQty: 1250,
    aql2_5: {
      accept: 0,
      reject: 0,
    },
    aql4_0: {
      aceept: 0,
      reject: 0,
    },
  },
];

export const calcCheckQtyFromQty = (qty) => {
  if (!qty) return 0;
  const i = AQLTable[getAQLIndex(qty)];
  return i.checkQty;
};

export const calculateQualityCheck = (issues, qty) => {
  if (issues.length === 0) {
    return "PASS";
  }
  const aql = AQLTable[getAQLIndex(qty)];
  let majorDefectCount = 0;
  let minorDefectCount = 0;
  issues.forEach((i) => {
    if (i.defectType === "major") {
      majorDefectCount += i.qty;
    }
    if (i.defectType === "minor") {
      minorDefectCount += i.qty;
    }
  });
  if (majorDefectCount > 0) {
    if (majorDefectCount >= aql.aql2_5.reject) {
      return "FAIL";
    }
  }
  if (minorDefectCount > 0) {
    if (minorDefectCount >= aql.aql4_0.reject) {
      return "FAIL";
    }
  }

  return "PASS";
};
