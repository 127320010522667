import { gql, useQuery } from '@apollo/client';
import Errors from 'components/Errors';
import Page from 'components/Page';
import Spinner from 'components/Spinner';
import { Link, useParams } from 'react-router-dom';
import InvoiceForm from './Form';

const FETCH_SUB_SUPPLIER_INVOICE_DETAIL = gql`
   query FETCH_SUB_SUPPLIER_INVOICE_DETAIL($id: ID!) {
      subSupplierInvoice(id: $id) {
         id
         number
         invoiceDate
         dueDate
         currency
         total
         order {
            id
            productionSupplier {
               id
               name
            }
            odooPoName
         }
         lines {
            id
            name
            unitPrice
            qty
         }
         paymentLines {
            id
            payment {
               id
               status
            }
         }
         remark
      }
   }
`;

const SubSupplierInvoiceDetail = () => {
   const { id } = useParams();
   const { loading, error, data } = useQuery(
      FETCH_SUB_SUPPLIER_INVOICE_DETAIL,
      { variables: { id } }
   );

   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;

   const invoice = data.subSupplierInvoice;
   const payments = invoice.paymentLines.map((i) => i.payment);

   return (
      <Page backTo={'/sub-supplier/invoices'} title={`INV ${invoice.number}`}>
         <div className="p-6 grid grid-cols-4 gap-6">
            <div className="card px-8 py-6 text-sm col-span-3">
               <InvoiceForm originalInvoice={invoice} />
            </div>

            <div className="space-y-6">
               <div className="card px-8 py-6 text-sm">
                  <h5>Payments</h5>
                  <div className="mt-4">
                     {payments.length > 0 ? (
                        payments.map((i) => (
                           <div key={i.id}>
                              <Link to={`/sub-supplier/payments/${i.id}`}>
                                 Payment {i.id}
                              </Link>
                           </div>
                        ))
                     ) : (
                        <div className=" opacity-70">
                           No payments for this invoice.
                        </div>
                     )}
                  </div>
               </div>
            </div>
         </div>
      </Page>
   );
};

export default SubSupplierInvoiceDetail;
