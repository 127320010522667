import { gql, useQuery } from '@apollo/client';
import CharlesButton from 'components/charles/base';
import Errors from 'components/Errors';
import { Select } from 'components/Form';
import Spinner, { InlineSpinner } from 'components/Spinner';
import { useModals } from 'ModalProvider';
import { useState } from 'react';
import { IoArrowDown, IoArrowUp } from 'react-icons/io5';
import ProductCostWithRawMaterial from './ProductCostWithRawMaterial';

const FETCH_DATA = gql`
   query ($materialId: ID!) {
      materialProductsAnalysis(materialId: $materialId)
      material(id: $materialId) {
         properStartDate
      }
   }
`;

const ProductRankings = ({ materialId }) => {
   const { loading, error, data } = useQuery(FETCH_DATA, {
      variables: { materialId },
   });
   const [supplierId, setSupplierId] = useState('0');
   const modal = useModals();

   if (loading)
      return (
         <div className="p-20">
            <InlineSpinner />
         </div>
      );
   if (error) return <Errors error={error} />;

   const res = JSON.parse(data.materialProductsAnalysis);

   const suppliers = res.reduce((res, i) => {
      if (res.find((x) => x.id === i.supplierId)) return res;
      return [...res, { id: i.supplierId, name: i.supplierName }];
   }, []);

   const products = res
      .filter((i) =>
         supplierId === '0' ? true : i.supplierId === parseInt(supplierId)
      )
      .map((i) => {
         const diff =
            ((i.latest_price - i.ideal_price_today) * 100) /
            i.ideal_price_today;
         return { ...i, diff };
      });

   function showProduct(product) {
      modal.present({
         title: `[${product.product_number}] ${product.product_name}`,
         maxWidth: 'max-w-6xl',
         children: (
            <ProductCostWithRawMaterial
               materialId={materialId}
               productId={product.product_id}
            />
         ),
      });
   }

   console.log('res', res);

   return (
      <div className="p-6">
         <div className="card">
            <div>Start Date: {data.material.properStartDate}.</div>
            <div className="-mx-2 mt-2">
               <table>
                  <thead>
                     <tr>
                        <th>Product</th>
                        <th className="text-right">Start Price</th>
                        <th className="text-right">Ideal Price Today</th>
                        <th className="text-right">Actual Price Today</th>
                     </tr>
                  </thead>
                  <tbody>
                     {products.map((i, index) => (
                        <tr
                           key={index}
                           className="border-b border-gray-100 dark:border-gray-700 hover:bg-gray-100 hover:bg-opacity-50 dark:hover:bg-gray-900 dark:hover:bg-opacity-50"
                        >
                           <td>
                              <CharlesButton onClick={() => showProduct(i)}>
                                 [{i.product_number}] {i.product_name}
                              </CharlesButton>
                           </td>

                           <td className="text-right space-x-3">
                              <span className="opacity-70">{i.start_date}</span>
                              <span>{i.start_price.toFixed(2)}</span>
                           </td>
                           <td className="text-right">
                              {i.ideal_price_today.toFixed(2)}
                           </td>
                           <td>
                              <div className="flex justify-end space-x-2">
                                 <div
                                    className={`flex justify-end items-center space-x-1
                                          ${
                                             i.diff > 0
                                                ? ' text-red-600'
                                                : ' text-green-600'
                                          }`}
                                 >
                                    {i.diff > 0 ? (
                                       <IoArrowUp />
                                    ) : (
                                       <IoArrowDown />
                                    )}
                                    <span className="font-semibold">
                                       {i.diff.toFixed(2)}%
                                    </span>
                                 </div>
                                 <div>{i.latest_price.toFixed(2)}</div>
                              </div>
                           </td>
                        </tr>
                     ))}
                  </tbody>
               </table>
            </div>
         </div>
      </div>
   );
};

export default ProductRankings;
