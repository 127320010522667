import { gql, useQuery } from '@apollo/client';
import { InlineSpinner } from 'components/Spinner';
import Errors from 'components/Errors';
import InputSelect from 'components/InputSelect';

const FETCH_ODOO_PRODUCT_VARIANTS = gql`
   query FETCH_ODOO_PRODUCT_VARIANTS {
      odooProductAttributeValues {
         id
         name
         displayName
      }
   }
`;

const OdooAttributeInput = ({ value, onChange }) => {
   const { loading, error, data } = useQuery(FETCH_ODOO_PRODUCT_VARIANTS, {
      fetchPolicy: 'cache-and-network',
   });

   const options = data
      ? [...data.odooProductAttributeValues].sort((a, b) =>
           a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
        )
      : [];

   return (
      <div className="w-full relative flex items-baseline space-x-4">
         <label className="font-bold text-gray-600">New Variant: </label>
         {loading ? (
            <InlineSpinner size={16} text={null} />
         ) : error ? (
            <Errors error={error} />
         ) : (
            <div className="flex-1">
               <InputSelect
                  placeholder="Packing"
                  className="flex-1"
                  value={value}
                  onChange={onChange}
                  onSelect={(i) => onChange(i.name)}
                  options={options}
               />
               <div className="opacity-60 text-xs px-2 mt-1">
                  We use packing to describe this product variant.
               </div>
            </div>
         )}
      </div>
   );
};

export default OdooAttributeInput;
