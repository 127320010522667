import { Button, CheckBox } from "components/base";
import { useQuery, gql, useMutation } from "@apollo/client";
import { InlineSpinner } from "components/Spinner";
import Errors from "components/Errors";
import OdooWarehouseSelector from "components/OdooWarehouseSelector";
import { formatDate } from "react-day-picker/moment";
import { useContext, useEffect, useState } from "react";
import { DatePicker, Select } from "components/Form";
import { Alert } from "components/Toast";
import SearchBar from "components/SearchBar";
import { searchByProp } from "utils/search";
import { useModals } from "ModalProvider";
import { OdooContext } from "OdooProvider";

const FETCH_ORDERS_FOR_AMAZON = gql`
  query FETCH_ORDERS_FOR_AMAZON($startDate: String, $invoiceStatus: String, $warehouseId: Int) {
    odooOrdersForAmazon(warehouseId: $warehouseId, startDate: $startDate, invoiceStatus: $invoiceStatus)
  }
`;

const ODOO_CREATE_INVICES = gql`
  mutation ODOO_CREATE_INVICES($ids: [Int]!, $invoiceDate: String!) {
    odooCreateInvoices(ids: $ids, invoiceDate: $invoiceDate) {
      result
    }
  }
`;

const INVOICE_STATUS = {
  no: "Nothing to Invoice",
  invoiced: "Fully Invoiced",
  "to invoice": "To Invoice",
};

const Invoices = () => {
  const today = new Date();
  const [warehouseId, setWarehouseId] = useState(localStorage.getItem("odoo:warehouseId") ? localStorage.getItem("odoo:warehouseId") : 3);
  const [startDate, setStartDate] = useState(formatDate(new Date().setDate(today.getDate() - 30), "YYYY-MM-DD"));
  const [searchText, setSearchText] = useState("");
  const [filterStatus, setFilterStatus] = useState("all");

  const { present } = useModals();

  const { loading, error, data } = useQuery(FETCH_ORDERS_FOR_AMAZON, {
    variables: {
      startDate: formatDate(startDate, "YYYY-MM-DD"),
      invoiceStatus: filterStatus,
      warehouseId,
    },
  });

  useEffect(() => {
    if (warehouseId) localStorage.setItem("odoo:warehouseId", warehouseId);
  }, [warehouseId]);

  const [selectedIds, setSelectedIds] = useState([]);

  useEffect(() => {
    setSelectedIds([]);
  }, [startDate]);

  const orders = data ? JSON.parse(data.odooOrdersForAmazon) : [];
  const ordersToShow = orders.filter((i) => searchByProp(i, ["name"], searchText));

  return (
    <div className="flex-1 flex flex-col overflow-auto">
      <div className="p-6">
        <div className="flex space-x-8 items-center ">
          <div className="flex-1">
            <SearchBar value={searchText} onChange={setSearchText} placeholder="Search by Order Number" />
          </div>

          <div className="flex items-center space-x-2">
            <label htmlFor="">Start Date:</label>
            <DatePicker onDayChange={setStartDate} value={startDate} />
          </div>

          <OdooWarehouseSelector value={warehouseId} onSelect={setWarehouseId} />

          <Select value={filterStatus} onChange={(e) => setFilterStatus(e.target.value)}>
            <option value="all">All Status</option>
            {Object.entries(INVOICE_STATUS).map(([key, value]) => (
              <option value={key} key={key}>
                {value}
              </option>
            ))}
          </Select>

          {selectedIds.length > 0 ? (
            <div className="flex space-x-6">
              <Button
                title={`Create Invoices(${selectedIds.length})`}
                onClick={() =>
                  present({
                    title: "Create Odoo Invoices",
                    subtitle: "Confirm, deliver and create invoice for Odoo Orders..",
                    children: <ConfirmCreateInvoiceForm orders={orders.filter((i) => selectedIds.includes(i.id))} />,
                  })
                }
              />
              <Button title="Deselect" onClick={() => setSelectedIds([])} />
            </div>
          ) : null}
        </div>

        <div className="mt-2 text-xs">
          In this page fetch the orders from odoo. You can confirm quotations, check validation and create invoice in Odoo. If you find any issues when creating
          invoice here, please contact Charlie.
        </div>
      </div>

      <div className="overflow-auto flex-1">
        <OrdersTable startDate={startDate} selectedIds={selectedIds} setSelectedIds={setSelectedIds} loading={loading} error={error} orders={ordersToShow} />
      </div>
    </div>
  );
};

const OrdersTable = ({ selectedIds, setSelectedIds, loading, error, orders }) => {
  const { odooUrl } = useContext(OdooContext);
  if (loading)
    return (
      <div className="relative p-36">
        <InlineSpinner />
      </div>
    );
  if (error) return <Errors error={error} />;

  const isSelected = (id) => selectedIds.includes(id);
  const allSelected = selectedIds.length === orders.length;

  if (orders.length === 0) return <div className="p-6">No orders found.</div>;

  return (
    <table>
      <thead className="text-left">
        <tr>
          <th className="px-6 sticky z-10 top-0 bg-gray-100 dark:bg-gray-800 w-14">
            <CheckBox checked={allSelected} onChange={() => setSelectedIds(allSelected ? [] : orders.map((i) => i.id))} />
          </th>
          <th className="px-2 sticky z-10 top-0 bg-gray-100 dark:bg-gray-800">Order</th>
          <th className="px-2 sticky z-10 top-0 bg-gray-100 dark:bg-gray-800">Order Date</th>
          <th className="px-2 sticky z-10 top-0 bg-gray-100 dark:bg-gray-800">Invoice Status</th>
          <th className="px-2 sticky z-10 top-0 bg-gray-100 dark:bg-gray-800"></th>
        </tr>
      </thead>
      <tbody>
        {orders.map((order) => (
          <tr
            key={order.id}
            className={`border-t dark:border-gray-700 dark:hover:bg-gray-800 dark:active:bg-gray-700 cursor-pointer
                    ${isSelected(order.id) ? "bg-green-100 dark:bg-green-800 dark:bg-opacity-50" : ""}
                  `}
            onClick={(e) => {
              e.stopPropagation();
              setSelectedIds((prev) => (prev.includes(order.id) ? prev.filter((i) => i !== order.id) : [...prev, order.id]));
            }}
          >
            <td className="px-6">
              <CheckBox checked={isSelected(order.id)} />
            </td>
            <td className="flex space-x-8 items-baseline">{order.name}</td>
            <td>{order.date_order}</td>
            <td>{INVOICE_STATUS[order.invoice_status] || order.invoice_status}</td>
            <td className="text-right">
              <div
                className=" inline-block underline hover:text-blue-600 text-xs opacity-50 px-6"
                onClick={(e) => {
                  e.stopPropagation();
                  window.open(`${odooUrl}/web#id=${order.id}&action=312&model=sale.order&view_type=form&menu_id=408`);
                }}
              >
                Open in Odoo
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const ConfirmCreateInvoiceForm = ({ orders }) => {
  const [date, setDate] = useState(new Date());
  const [result, setResult] = useState(null);
  const [createInvoices, createInvoicesRes] = useMutation(ODOO_CREATE_INVICES, {
    onCompleted(response) {
      Alert("success", "Process done.");
      setResult(JSON.parse(response.odooCreateInvoices.result));
    },
    onError(error) {
      Alert("error", error.message);
    },
    awaitRefetchQueries: true,
    refetchQueries: ["FETCH_ORDERS_FOR_AMAZON"],
  });

  const unInvoicedCount = result ? result.filter((i) => i.invoice_status !== "invoiced").length : 0;

  return (
    <div>
      <table>
        <thead>
          <tr className="text-left">
            <th className="border dark:border-gray-700">Number</th>
            <th className="border dark:border-gray-700">Invoice Status</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order) => {
            let finalStatus = order.invoice_status;
            if (result) finalStatus = result.find((i) => i.id === order.id).invoice_status;
            return (
              <tr key={order.id}>
                <td className="border dark:border-gray-700">{order.name}</td>
                <td className="border dark:border-gray-700">{INVOICE_STATUS[finalStatus]}</td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className="mt-6">
        <div className="flex items-center space-x-4">
          <label htmlFor="">Delivery and Invoice Date: </label>
          <DatePicker value={date} onDayChange={setDate} />
        </div>

        <div className="mt-4 italic opacity-60">Fully invoiced orders will be ignore.</div>

        <div className="mt-6">
          {result ? null : (
            <Button
              title="Process"
              size="xl"
              bold
              loading={createInvoicesRes.loading}
              disabled={createInvoicesRes.loading}
              onClick={() => {
                createInvoices({
                  variables: {
                    ids: orders.map((i) => i.id),
                    invoiceDate: formatDate(date, "YYYY-MM-DD"),
                  },
                });
              }}
            />
          )}

          {createInvoicesRes.called && !createInvoicesRes.loading && unInvoicedCount > 0 ? (
            <div className="mt-2 text-pink-700">
              {unInvoicedCount} order{unInvoicedCount > 1 ? "s" : null} failed to create invoices in Odoo. Please manage those ones manually.
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Invoices;
