import { useContext, useEffect, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import { AppContext } from "App";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import { Alert } from "components/Toast";

import { CREATE_OR_UPDATE_ANSWERS, FETCH_LATEST_SURVEY, FETCH_USER_ANSWERS, DELETE_ANSWERS } from "./graphql";
import Page from "components/Page";
import CharlesButton from "components/charles/base";
import { FaCheckCircle } from "react-icons/fa";
import { useModals } from "ModalProvider";

const SCORE_ENUM = Object.freeze(Array.from({ length: 5 }, (_, i) => i + 1)); // [1, 2, 3, 4, 5]
const QUESTION_ENUM = Object.freeze(Array.from({ length: 7 }, (_, i) => (i + 1).toString())); // ["1", "2", "3", "4", "5", "6", "7"]

const RatingButton = ({ value, isSelected, handleSelect }) => {
  return (
    <div
      className={`w-6 h-6 mr-2 border dark:border-gray-400 rounded-full flex items-center justify-center flex-shrink-0 text-xs cursor-pointer
            ${
              isSelected
                ? "bg-blue-500  active:bg-blue-100 text-white active:text-gray-800 dark:bg-blue-600 dark:text-white dark:active:text-gray-400 dark:active:bg-gray-800"
                : "active:bg-blue-600 hover:bg-blue-500"
            }`}
      onClick={handleSelect}
    >
      {value}
    </div>
  );
};

const RatingButtonGroup = ({ optinons = SCORE_ENUM, initialOption = null, onSelect }) => {
  const [currentButtonValue, setCurrentButtonValue] = useState(initialOption);

  useEffect(() => {
    setCurrentButtonValue(initialOption);
  }, [initialOption]);

  const handleSelect = (value) => {
    setCurrentButtonValue(value);
    onSelect(value);
  };

  return (
    <div className="flex">
      {optinons.map((value, index) => (
        <RatingButton key={index} value={value} isSelected={value === currentButtonValue} handleSelect={() => handleSelect(value)} />
      ))}
    </div>
  );
};

const ClearAllAnswers = ({ surveyId, userId, supplierId, hide, setSupplierAnswersDict, setIsSubmitted, setHasAnsweredBefore }) => {
  const [deleteAnswers, _] = useMutation(DELETE_ANSWERS, {
    onCompleted: () => {
      Alert("success", "Successfuly Clear All Answers.");
      setSupplierAnswersDict({});
      setIsSubmitted(false);
      setHasAnsweredBefore(false);
      hide();
    },
    onError: (error) => {
      console.error("An error occurred while creating or updating answers:", error);
    },
  });

  const handleDelete = () => {
    deleteAnswers({ variables: { surveyId, userId, supplierId } });
    hide();
  };

  return (
    <div className=" space-y-6">
      <p>Are you sure you want to clear all answers for this supplier?</p>
      <div className="flex space-x-4">
        <CharlesButton primary onClick={handleDelete}>
          Yes
        </CharlesButton>
        <CharlesButton onClick={hide}>Cancel</CharlesButton>
      </div>
    </div>
  );
};

const SupplierCard = ({ surveyId, userId, supplier, questions, allAnswers }) => {
  const [supplierAnswersDict, setSupplierAnswersDict] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [hasAnsweredBefore, setHasAnsweredBefore] = useState(false);
  const { present, hide } = useModals();

  const [submitAnswers, _] = useMutation(CREATE_OR_UPDATE_ANSWERS, {
    refetchQueries: [
      {
        query: FETCH_USER_ANSWERS,
        variables: {
          surveyId: surveyId,
          userId: userId,
        },
      },
    ],
    onCompleted: () => {
      Alert("success", "Answer saved.");
      setIsSubmitted(true);
    },
    onError: (error) => {
      console.error("An error occurred while creating or updating answers:", error);
    },
  });

  const handleSubmit = (supplierName) => {
    const isAllQuestionsAnswered = Object.keys(supplierAnswersDict).length === QUESTION_ENUM.length;
    if (!isAllQuestionsAnswered) {
      Alert("error", "Please answer all questions for " + supplierName);
      return;
    }

    console.log("currenctAnswersDict: ", supplierAnswersDict);
    const answersData = Object.entries(supplierAnswersDict).map(([questionId, { answerId, score }]) => ({
      id: answerId,
      userId: userId,
      surveyId: surveyId,
      supplierId: supplier.id,
      questionId: questionId,
      score: score,
    }));
    submitAnswers({ variables: { answersData: answersData } });
  };

  useEffect(() => {
    // try to load from allAnswers(previous answers data)
    const newDict =
      allAnswers?.reduce((dict, answer) => {
        if (answer.supplier.id === supplier.id) {
          dict[answer.question.id] = { answerId: answer.id, score: answer.scoreValue };
        }
        return dict;
      }, {}) || {};

    setSupplierAnswersDict(newDict);
    setHasAnsweredBefore(Object.keys(newDict).length === QUESTION_ENUM.length);
  }, [allAnswers]);

  return (
    <div className="card">
      <h3 className="flex mb-2 items-center">
        {supplier.name} {isSubmitted && <FaCheckCircle className="h-5 w-5 ml-2 text-green-500" />}
      </h3>

      {questions.map((question, index) => {
        // if the user has answered before, then we will show the previous answer, otherwise, it will be undefined
        const previousAnswer = supplierAnswersDict[question.id];

        return (
          <div key={index} className="mb-4">
            <p className="font-medium">
              {index + 1}. {question.text}
            </p>
            <p className="text-sm text-gray-500">{question.answerHelp}</p>

            <RatingButtonGroup
              optinons={SCORE_ENUM}
              initialOption={previousAnswer?.score}
              onSelect={(currentScore) => {
                setSupplierAnswersDict((prevDict) => ({
                  ...prevDict,
                  [question.id]: { answerId: previousAnswer?.answerId || null, score: currentScore },
                }));
              }}
            />
          </div>
        );
      })}

      <div className="flex space-x-6 items-center border-t-gray-200 dark:border-t-gray-700 self-stretch border-t-2">
        <CharlesButton className="mt-2" onClick={() => handleSubmit(supplier.name)}>
          Submit
        </CharlesButton>
        {hasAnsweredBefore && (
          <CharlesButton
            danger
            className="mt-2"
            onClick={() =>
              present({
                title: "Clear All Answers For " + supplier.name,
                center: true,
                children: (
                  <ClearAllAnswers
                    surveyId={surveyId}
                    userId={userId}
                    supplierId={supplier.id}
                    hide={hide}
                    setSupplierAnswersDict={setSupplierAnswersDict}
                    setIsSubmitted={setIsSubmitted}
                    setHasAnsweredBefore={setHasAnsweredBefore}
                  />
                ),
              })
            }
          >
            Clear Answers
          </CharlesButton>
        )}
      </div>
    </div>
  );
};

const Questionnaire = () => {
  const { user } = useContext(AppContext);
  const { loading, error, data } = useQuery(FETCH_LATEST_SURVEY);
  const [getAnswers, { loading: answersLoading, error: answersError, data: answersData }] = useLazyQuery(FETCH_USER_ANSWERS);

  useEffect(() => {
    if (data?.latestSurvey?.id) {
      getAnswers({
        variables: {
          surveyId: data.latestSurvey.id,
          userId: user.id,
        },
      });
    }
  }, [data]);

  if (loading || answersLoading) return <Spinner />;
  if (error) return <Errors error={error} />;
  if (answersError) return <Errors error={answersError} />;

  const survey = data.latestSurvey;

  return (
    <Page title="Supplier Grading Questionnaire" backTo="..">
      <div data-testid="supplier-grading-questionnaire" className="p-6 space-y-6 text-sm">
        <h2>{survey.title}</h2>
        <p className="text-sm text-gray-500">This is your questionnaire, you won't see the answers chosen by others.</p>

        {/* Each supplier card */}
        {survey.suppliers.map((supplier, index) => (
          <SupplierCard
            key={index}
            surveyId={data?.latestSurvey?.id}
            userId={user.id}
            supplier={supplier}
            questions={survey.questions}
            allAnswers={answersData?.userAnswers}
          />
        ))}
      </div>
    </Page>
  );
};

export default Questionnaire;
