import React from 'react';

function ProgressRing({
   radius = 60,
   stroke = 6,
   progress = 0,
   showPercentage = false
}) {
   const normalizedRadius = radius - stroke * 2;
   const circumference = normalizedRadius * 2 * Math.PI;
   const strokeDashoffset = circumference - (progress / 100) * circumference;
   return (
      <svg height={radius * 2} width={radius * 2}>
         <circle
            stroke="#00bcd8"
            fill="transparent"
            strokeWidth={stroke}
            strokeDasharray={circumference + ' ' + circumference}
            style={{
               transition: `stroke-dashoffset 0.35s`,
               transform: `rotate(-90deg)`,
               transformOrigin: `50% 50%`,
               strokeDashoffset
            }}
            r={normalizedRadius}
            cx={radius}
            cy={radius}
         />
         {showPercentage ? (
            <text
               x="50%"
               y="50%"
               textAnchor="middle"
               alignmentBaseline="middle"
               font-size="0.5em"
            >
               {progress.toFixed(2)}%
            </text>
         ) : null}
      </svg>
   );
}

export default ProgressRing;
