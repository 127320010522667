import { gql, useMutation, useQuery } from "@apollo/client";
import { parseError } from "apollo";
import Errors from "components/Errors";
import { Select } from "components/Form";
import Spinner from "components/Spinner";
import { Alert } from "components/Toast";
import { byName } from "utils/sort";

const FETCH_PRODUCT_LINES_FOR_VALIDATE_TEST_REPORTS = gql`
  query FETCH_PRODUCT_LINES_FOR_VALIDATE_TEST_REPORTS {
    productLines(activeForWis: true) {
      total
      results {
        id
        name
        number
        productList: fromProductList {
          id
          testReports {
            id
            fileList
          }
        }
      }
    }
    allProductLists {
      id
      name
      version
    }
  }
`;

const SAVE_PRODUCT_LINE = gql`
  mutation SAVE_PRODUCT_LINE($id: ID!, $simpleFields: ProductLineSimpleFields) {
    saveProductLine(id: $id, simpleFields: $simpleFields) {
      productLine {
        id
        productList: fromProductList {
          id
        }
      }
    }
  }
`;

const ProductLineTestReports = () => {
  const { loading, error, data } = useQuery(FETCH_PRODUCT_LINES_FOR_VALIDATE_TEST_REPORTS);
  const [saveProductLine, saveProductLineRes] = useMutation(SAVE_PRODUCT_LINE, {
    onCompleted() {
      Alert("success", "Saved");
    },
    onError(error) {
      Alert("error", parseError(error));
    },
  });

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const sortedProductList = [...data.allProductLists].sort(byName);

  return (
    <div className="card rounded-xl m-6 p-0 h-full overflow-auto">
      <table>
        <thead>
          <tr className="sticky top-0 backdrop-blur bg-gray-50 dark:bg-gray-800 bg-opacity-70 z-10">
            <th className="px-6 py-3">Product Line ({data.productLines.total})</th>
            <th>Product List</th>
            <th>Test Reports</th>
          </tr>
        </thead>
        <tbody>
          {data.productLines.results.map((i) => (
            <tr key={i.id} className="border-b dark:border-gray-700">
              <td className="px-6">
                [{i.number}] {i.name}
              </td>
              <td>
                <Select
                  value={i.productList ? i.productList.id : 0}
                  onChange={(e) => {
                    const fromProductListId = e.target.value === "0" ? null : e.target.value;
                    saveProductLine({ variables: { id: i.id, simpleFields: { fromProductListId } } });
                  }}
                  disabled={saveProductLineRes.loading}
                >
                  <option value="0">--- Select ---</option>
                  {sortedProductList.map((i, index) => (
                    <option key={index} value={i.id}>
                      {i.name} (v{i.version})
                    </option>
                  ))}
                </Select>
              </td>
              <td>{i.productList ? i.productList.testReports.map((standard) => <div key={standard.id}>{standard.name}</div>) : null}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ProductLineTestReports;
