import React from 'react';
import Icon from './Icon.js';

function QcStatus({
   className = '',
   iconSize = 14,
   textSize = 'text-lg',
   status,
}) {
   let icon = '';
   let color = '';
   if (!status) return null;
   switch (status.toLowerCase()) {
      case 'pass':
         icon = 'check';
         color = 'text-green-500';
         break;
      case 'fail':
         icon = 'block';
         color = 'text-red-500';
         break;
      case 'pending':
         icon = 'hand-stop';
         color = 'text-yellow-500';
         break;
      default:
         break;
   }
   return (
      <div className={`${className} ${color} flex items-center`}>
         <div className={`uppercase mr-1 font-bold ${textSize}`}>{status}</div>
         {status.toLowerCase() !== 'pending' ? (
            <Icon icon={icon} size={iconSize} />
         ) : null}
      </div>
   );
}

export default QcStatus;
