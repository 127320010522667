export const conversions = {
  oneKgInPounds: 2.20462,
  oneCubicMetreInCubicFeet: 35.3147,
  oneMmInInches: 0.0393701,
};

export default function convertUnits(properties = [], fromUnit = "mm", toUnit = "in", metric = true, showUnits = true) {
  const valid = properties.filter((prop) => {
    return Boolean(prop);
  });

  if (valid.length !== properties.length) {
    return "-";
  }

  if (metric) {
    return `${properties.join(" x ")}${showUnits ? " " + fromUnit : ""}`;
  }

  let conversion = null;

  if (fromUnit === "mm" && toUnit === "in") {
    conversion = conversions.oneMmInInches;
  }

  if (fromUnit === "cm" && toUnit === "in") {
    conversion = conversions.oneMmInInches * 10;
  }

  if (fromUnit === "g" && toUnit === "lb") {
    conversion = conversions.oneKgInPounds / 1000;
  }

  if (fromUnit === "kg" && toUnit === "lb") {
    conversion = conversions.oneKgInPounds;
  }

  if (fromUnit === "m3" && toUnit === "ft3") {
    conversion = conversions.oneCubicMetreInCubicFeet;
  }

  if (!conversion) {
    return "Could not convert";
  }

  let converted = [];

  properties.forEach((prop) => {
    converted.push(Math.round(prop * conversion * 100) / 100);
  });

  return `${converted.join(" x ")}${showUnits ? " " + toUnit : ""}`;
}
