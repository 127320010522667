import { useState } from "react";
import { useMutation, gql } from "@apollo/client";
import { Input, Select, Text } from "components/Form";
import { Alert } from "components/Toast";
import ConvertProductForm from "./ConvertProductForm";
import CharlesButton from "components/charles/base";
import { BsTrash } from "react-icons/bs";
import { useModals } from "ModalProvider";
import ProductForm from "pages/manage/products/All/ProductForm";

const CREATE_FACTORY = gql`
  mutation CREATE_FACTORY($name: String!, $cooperatedSince: DateTime) {
    createFactory(nickName: $name, cooperatedSince: $cooperatedSince) {
      factory {
        id
        nickName
        status
      }
    }
  }
`;

const PriceBreakDown = ({ priceItems, onChangePriceItems, allFactories }) => {
  const [selectingPriceItem, setSelectingPriceItem] = useState(null);
  const createModal = useModals();
  const editModal = useModals();

  const [createSupplier, createSupplierRes] = useMutation(CREATE_FACTORY, {
    onCompleted: (data) => {
      Alert("success", "Created.");
      onChangePriceItem(selectingPriceItem, {
        supplierId: data.createFactory.factory.id,
      });
    },
    onError: (error) => Alert("error", error.message),
    refetchQueries: [
      {
        query: gql`
          query FETCH_ALL_FACTORIES {
            allFactories {
              id
              nickName
              status
            }
          }
        `,
      },
    ],
    awaitRefetchQueries: true,
  });

  function onChangePriceItem(index, value) {
    const updatedPriceItems = priceItems.map((prevItem, prevIndex) => {
      if (index !== prevIndex) return prevItem;
      return { ...prevItem, ...value };
    });
    onChangePriceItems(updatedPriceItems);
  }

  function createNewSupplier(name) {
    createSupplier({
      variables: { name, cooperatedSince: new Date() },
    });
  }

  function tryCreateProduct(data) {
    const initialData = {
      odooCategoryId: 1,
      name: data.name,
      prices: data.itemPrices.map((i) => ({
        minQty: i.minQty,
        price: i.value,
        currency: data.currency,
        factoryId: data.supplierId,
        avaliableFromMonth: 1,
        avaliableToMonth: 12,
      })),
    };
    createModal.present({
      title: "Create Product",
      fullscreen: true,
      children: <ProductForm initialData={initialData} hide={createModal.hide} onSave={onCreated} onSaveAndClose={createModal.hide} />,
    });
  }

  function onCreated({ id }) {
    createModal.hide();
    editModal.present({
      title: "Edit Product",
      fullscreen: true,
      BeingPresented: true,
      children: (
        <ProductForm
          id={id}
          hide={editModal.hide}
          duplicate={(data) => {
            editModal.hide();
            createModal.present({
              title: "Duplicate Product",
              fullscreen: true,
              BeingPresented: true,
              children: <ProductForm duplicatingProduct={data} hide={createModal.hide} onSave={onCreated} />,
            });
          }}
        />
      ),
    });
  }

  return (
    <div>
      <div className="space-y-6">
        {priceItems.map((priceItem, priceItemIndex) => (
          <div className="bg-gray-100 dark:bg-gray-900 rounded-xl px-6 py-4" key={priceItemIndex}>
            <div className="flex justify-between mb-4">
              <div className="flex space-x-3 items-center">
                <CharlesButton
                  danger
                  onClick={() => {
                    if (window.confirm("Are you sure to delete this price?")) {
                      onChangePriceItems(priceItems.filter((_, prevIndex) => prevIndex !== priceItemIndex));
                    }
                  }}
                >
                  <BsTrash />
                </CharlesButton>
                <h5>Component {priceItemIndex + 1}</h5>
              </div>

              <div className="flex space-x-3 items-center">
                <CharlesButton onClick={() => tryCreateProduct(priceItem)}>Convert to Component</CharlesButton>
              </div>
            </div>

            <div>
              <div className="flex items-center space-x-4">
                <div className="flex items-center">
                  <label className="mr-3">Item: </label>
                  <Input
                    onChange={(e) =>
                      onChangePriceItem(priceItemIndex, {
                        name: e.target.value,
                      })
                    }
                    value={priceItem.name}
                    placeholder="Name"
                  />
                </div>
                <div className="flex items-center">
                  <label className="mr-3">Supplier: </label>
                  <Select
                    disabled={createSupplierRes.loading}
                    className="text-cen"
                    value={priceItem.supplierId}
                    onChange={(e) => {
                      let value = e.target.value;
                      if (value === "new") {
                        const newSupplierName = window.prompt("Supplier Name");
                        if (newSupplierName) {
                          setSelectingPriceItem(priceItemIndex);
                          createNewSupplier(newSupplierName);
                        }
                      } else {
                        onChangePriceItem(priceItemIndex, {
                          supplierId: e.target.value,
                        });
                      }
                    }}
                  >
                    <option value={0}>---- Select Supplier ----</option>

                    {allFactories.map((factory) => (
                      <option key={factory.id} value={factory.id}>
                        {factory.nickName}
                      </option>
                    ))}
                    <option value="new">+ New Supplier</option>
                  </Select>
                </div>

                <div>
                  <label className="mr-3">Currency: </label>
                  <Select
                    value={priceItem.currency}
                    onChange={(e) =>
                      onChangePriceItem(priceItemIndex, {
                        currency: e.target.value,
                      })
                    }
                  >
                    <option value="USD">USD</option>
                    <option value="SEK">SEK</option>
                    <option value="RMB">RMB</option>
                  </Select>
                </div>
              </div>

              <div className="mt-4">
                <PriceList itemPrices={priceItem.itemPrices} onChangeItemPrices={(itemPrices) => onChangePriceItem(priceItemIndex, { itemPrices })} />
              </div>

              <div className="mt-4">
                <label className="block mb-3">Notes: </label>
                <Text
                  value={priceItem.notes ? priceItem.notes : ""}
                  onChange={(e) =>
                    onChangePriceItem(priceItemIndex, {
                      notes: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
        ))}

        <div>
          <CharlesButton
            onClick={() =>
              onChangePriceItems([
                ...priceItems,
                {
                  name: "",
                  itemPrices: [{ minQty: 0, value: 0 }],
                  currency: "USD",
                  supplierId: 0,
                },
              ])
            }
          >
            + Add Component
          </CharlesButton>
        </div>
      </div>
    </div>
  );
};

const PriceList = ({ itemPrices, onChangeItemPrices }) => {
  function onChangeItemPrice(index, value) {
    const updatedPrices = itemPrices.map((prevPrice, prevIndex) => (prevIndex === index ? { ...prevPrice, ...value } : prevPrice));
    onChangeItemPrices(updatedPrices);
  }

  return (
    <div>
      <div className="space-y-3">
        {itemPrices.map((price, index) => (
          <div key={index} className="flex items-center space-x-4">
            <CharlesButton danger onClick={() => onChangeItemPrices(itemPrices.filter((_, prevIndex) => prevIndex !== index))}>
              <BsTrash />
            </CharlesButton>
            <div className="flex items-center">
              <label className="mr-4">Min Qty: </label>
              <Input className="text-right" value={price.minQty} onChange={(e) => onChangeItemPrice(index, { minQty: e.target.value })} />
            </div>
            <div className="flex items-center">
              <label className="mr-4">Price: </label>
              <Input className="text-right" value={price.value} onChange={(e) => onChangeItemPrice(index, { value: e.target.value })} />
            </div>
          </div>
        ))}
      </div>

      <div className="mt-3">
        <CharlesButton onClick={() => onChangeItemPrices([...itemPrices, { minQty: 0, value: 0 }])}>+ Price for another qty</CharlesButton>
      </div>
    </div>
  );
};

export default PriceBreakDown;
