import { gql, useQuery } from '@apollo/client';
import Errors from 'components/Errors';
import Page from 'components/Page';
import Spinner from 'components/Spinner';
import Status from 'components/Status';

const FETCH_SUPPLIER_QC_REPORTS = gql`
   query FETCH_SUPPLIER_QC_REPORTS {
      me {
         supplier {
            id
            qcReports: qcreportSet {
               id
               name
               status
            }
         }
      }
   }
`;

const QCReports = () => {
   const { loading, error, data } = useQuery(FETCH_SUPPLIER_QC_REPORTS);

   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;

   return (
      <Page title="QC报告">
         <div className="p-6 text-sm">
            {data.me.supplier.qcReports.map((i) => (
               <div className="py-2 border-b flex justify-between items-center">
                  <div key={i.id}>{i.name}</div>
                  <div>
                     <Status status={i.status} />
                  </div>
               </div>
            ))}
         </div>
      </Page>
   );
};

export default QCReports;
