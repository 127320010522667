import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import Spinner from '../../components/Spinner';
import Errors from '../../components/Errors';
import { Switcher } from 'components/Form';
import { LuCopy, LuCopyCheck } from "react-icons/lu";


// isActive boolean
const FETCH_FACTORIES = gql`
   query FETCH_FACTORIE($isActive: Boolean) {
      factories: allFactories(isActive: $isActive) {
         id
         name
         nameCn
         nameEn
         location
         fullAddressCn
         fullAddressEn
         contactPerson
         email
         user {
            email
         }
         phone
         cooperatedSince
         cooperatedEnd
         process {
            id
            name
         }
         trackingLabel
         status
      }
   }
`;

function CopyableCell({ text, hint }) {
  const [isCopied, setIsCopied] = useState(false);
  const [isIconVisible, setIsIconVisible] = useState(false);
  const [isHintVisible, setIsHintVisible] = useState(false);

  const handleCopy = (event) => {
    event.stopPropagation(); // prevent the row click event
    navigator.clipboard.writeText(text)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 3000);
      })
      .catch(err => console.error('Copy failed:', err));
  };

  return (
    <div
      onMouseEnter={() => { setIsIconVisible(true); setIsHintVisible(true); setTimeout(() => setIsIconVisible(false), 3000); }}
      onMouseLeave={() => setIsHintVisible(false)}
      className="cursor-pointer flex items-center justify-between "
      onClick={handleCopy}
    >
      {text}
      {hint && (
        <span className={`absolute bottom-full px-2 py-1 text-xs text-white bg-gray-700 rounded w-64 whitespace-normal z-20 ${isHintVisible ? 'block' : 'hidden'}`}>
          {hint}
        </span>
      )}
      <span className={`absolute right-0 transform -translate-y-1/2 top-1/2 ${isIconVisible ? 'inline' : 'hidden'}`}>
        {isCopied ? <LuCopyCheck className='text-base text-green-600' /> : <LuCopy className='text-base' />}
      </span>
    </div>
  );
}

function FactoryList() {
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(true);
  const { loading, error, data } = useQuery(FETCH_FACTORIES, {
    variables: { isActive },
  });

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const factories = [...data.factories].sort((a, b) => b.status - a.status);

  return (
    <div className="m-6 flex-1 rounded-lg">
      <div className='flex items-center space-x-4 mb-2'>
        <label >Is Active:</label>
        <Switcher isOn={isActive} onChange={() => setIsActive(!isActive)} />
      </div>

      <div className="overflow-x-auto h-[calc(100vh-120px)]">
        <table className=" bg-white dark:bg-gray-800">
          <thead className=" sticky top-0 z-10 bg-gray-200 dark:bg-gray-900 dark:bg-opacity-50">
            <tr >
              <th className="px-6 py-4 whitespace-nowrap">Tracking Label</th>
              <th className="px-6 py-4 whitespace-nowrap">Factory</th>
              <th className="px-6 py-4 whitespace-nowrap">Factory CN</th>
              <th className="px-6 py-4 whitespace-nowrap">Factory EN</th>
              <th className="px-6 py-4 whitespace-nowrap">Contact Person</th>
              <th className="px-6 py-4 whitespace-nowrap">Contact Phone</th>
              <th className="px-6 py-4 whitespace-nowrap">Contact Email</th>
              <th className="px-6 py-4 whitespace-nowrap">Location CN</th>
              <th className="px-6 py-4 whitespace-nowrap">Location EN</th>
              <th className="px-6 py-4 whitespace-nowrap">ID</th>
              <th className="px-6 py-4 whitespace-nowrap">Process</th>
            </tr>
          </thead>
          <tbody>
            {factories.map((f) => (
              <tr
                className="cursor-pointer hover:bg-blue-200 dark:hover:bg-gray-700"
                onClick={(_) => navigate(`/csr/factory/${f.id}`)}
                key={f.id}
                style={{
                  opacity: f.status === 1 ? 1 : 0.3,
                }}
              >
                <td className="px-6 py-3 border-b dark:border-gray-700 whitespace-nowrap group relative">
                  <CopyableCell text={f.trackingLabel} hint={"Add month+year numbers to the front of the 5 letters to make a completed tracking label. For example, June 2024: 624" + f.trackingLabel} />
                </td>
                <td className="px-6 py-3 border-b dark:border-gray-700 whitespace-nowrap group relative">
                  <CopyableCell text={f.name} />
                </td>
                <td className="px-6 py-3 border-b dark:border-gray-700 whitespace-nowrap group relative">
                  <CopyableCell text={f.nameCn} />
                </td>
                <td className="px-6 py-3 border-b dark:border-gray-700 group relative">
                  <CopyableCell text={f.nameEn} />
                </td>
                <td className="px-6 py-3 border-b dark:border-gray-700 group relative">
                  <CopyableCell text={f.contactPerson} />
                </td>
                <td className="px-6 py-3 border-b dark:border-gray-700 whitespace-nowrap group relative">
                  <CopyableCell text={f.phone} />
                </td>
                <td className="px-6 py-3 border-b dark:border-gray-700 group relative">
                  {/* <CopyableCell text={f.user?.email} /> */}
                  <CopyableCell text={f.email} />
                </td>
                <td className="px-6 py-3 border-b dark:border-gray-700 whitespace-nowrap group relative">
                  <CopyableCell text={f.fullAddressCn} />
                </td>
                <td className="px-6 py-3 border-b dark:border-gray-700 group relative">
                  <CopyableCell text={f.fullAddressEn} />
                </td>
                <td class="px-6 py-3 border-b dark:border-gray-700 whitespace-nowrap group relative">
                  <CopyableCell text={`#M-${f.id}`} />
                </td>
                <td className="px-6 py-3 border-b dark:border-gray-700 group relative">
                  <CopyableCell text={f.process.map((i) => i.name).join(',')} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default FactoryList;
