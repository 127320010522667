import { gql, useQuery } from "@apollo/client";
import { parseError } from "apollo";
import { Select } from "components/Form";

const FETCH_ALL_PRODUCT_LISTS = gql`
  query FETCH_ALL_PRODUCT_LISTS {
    allProductLists {
      id
      name
      version
    }
  }
`;

const ProductListSelector = ({ value, onChange }) => {
  const { loading, error, data } = useQuery(FETCH_ALL_PRODUCT_LISTS);

  return (
    <Select value={value || "0"} onChange={(e) => onChange(e.target.value)}>
      {loading ? (
        <option value="0" disabled>
          loading...
        </option>
      ) : error ? (
        <option value="0" disabled>
          {parseError(error)}
        </option>
      ) : (
        <>
          <option value="0">--- Select ---</option>
          {data.allProductLists.map((i, index) => (
            <option key={index} value={i.id}>
              {i.name} (v{i.version})
            </option>
          ))}
        </>
      )}
    </Select>
  );
};

export default ProductListSelector;
