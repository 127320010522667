import React from 'react';
import { Button } from '../../../components/base.js';
import http from '../../../utils/http.js';
import track, { actions } from '../../../utils/track';

class ExportProductData extends React.Component {
   fields = ['number', 'item_weight', 'hs_code', 'product_material'];

   state = {
      selectedFields: [],
      loading: false,
      error: null,
   };

   onChangeField = (field) => {
      if (this.state.selectedFields.indexOf(field) > -1) {
         this.setState((prevState) => ({
            selectedFields: prevState.selectedFields.filter((i) => i !== field),
         }));
      } else {
         this.setState((prevState) => ({
            selectedFields: [...prevState.selectedFields, field],
         }));
      }
   };

   export = (_) => {
      this.setState({ loading: true });
      const fields = this.state.selectedFields
         .map((i) => `field=${i}`)
         .join('&');
      const url = `/export/product/?${fields}`;
      http
         .get(url, {
            baseURL: process.env.REACT_APP_SERVER_BASE_URL,
         })
         .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Waboba Products Data.csv'); //or any other extension
            document.body.appendChild(link);
            link.click();
            this.setState({ loading: false });
         })
         .catch((error) => this.setState({ error, loading: false }));
      track(actions.product.exportAll.name, this.state.selectedFields);
   };

   render() {
      return (
         <div className="p-6">
            <div className="my-2">Select the fields you want:</div>
            {this.fields.map((i) => (
               <label
                  className="flex items-center cursor-pointer mt-4"
                  key={i}
                  htmlFor={`field-${i}`}
               >
                  <span className="pr-2">{i}</span>
                  <input
                     id={`field-${i}`}
                     type="checkbox"
                     checked={this.state.selectedFields.indexOf(i) > -1}
                     onChange={(_) => this.onChangeField(i)}
                  />
               </label>
            ))}

            <Button
               loading={this.state.loading}
               disabled={this.state.loading}
               border
               color="blue"
               className="mt-6"
               onClick={this.export}
               title={this.state.loading ? 'Exporting...' : 'Export CSV'}
            />
         </div>
      );
   }
}

export default ExportProductData;
