import { gql } from "@apollo/client";

export const FETCH_ODOO_STOCKS = gql`
  query FETCH_ODOO_STOCKS {
    odooStocks {
      id
      name
    }
  }
`;

export const FETCH_ODOO_STOCK_PRODUCTS = gql`
  query FETCH_ODOO_STOCK_PRODUCTS($stockId: ID!) {
    odooStock(id: $stockId) {
      id
      products {
        id
        name
        odooCode
        odooId
      }
    }
  }
`;

export const FETCH_ODOO_PRODUCT_STOCKS = gql`
  query FETCH_ODOO_PRODUCT_STOCKS($ids: [Int]) {
    products: odooProducts(ids: $ids) {
      id
      name
      defaultCode
      virtualAvailable
    }
    odooStockHistory(productIds: $ids)
    odooStockChina(productIds: $ids)
  }
`;
