import { gql, useMutation, useQuery } from '@apollo/client';
import { Button } from 'components/base';
import Errors from 'components/Errors';
import Spinner, { InlineSpinner } from 'components/Spinner';
import { Alert } from 'components/Toast';
import { useModals } from 'ModalProvider';
import { useState } from 'react';

const FETCH_ONE_DRIVE_ITEM = gql`
   query FETCH_ONE_DRIVE_PRODUCTS($slug: String!, $id: String!) {
      oneDriveItem(id: $id)
      productLine(slug: $slug) {
         id
         name
         newImages(stage: "wis") {
            id
            url(size: "300x300")
         }
         products {
            id
            number
            name
            isActive
            images {
               id
               url(size: "300x300")
            }
         }
      }
   }
`;

const SYNC_PRODUCT_LINE_WIS_IMAGES = gql`
   mutation SYNC_PRODUCT_LINE_WIS_IMAGES($slug: String!, $ids: [String]!) {
      syncWisProductLineImagesFromOneDrive(slug: $slug, ids: $ids) {
         productLine {
            id
            newImages(stage: "wis") {
               id
               url(size: "300x300")
            }
         }
      }
   }
`;

const OneDriveFolder = ({ id, slug }) => {
   const { loading, error, data } = useQuery(FETCH_ONE_DRIVE_ITEM, {
      variables: { id, slug },
   });
   const [selectedIds, setSelectedIds] = useState([]);
   const [syncProductLineWisImages, syncProductLineWisImagesRes] = useMutation(
      SYNC_PRODUCT_LINE_WIS_IMAGES,
      {
         onCompleted() {
            Alert('success', 'Images synced.');
            setSelectedIds([]);
         },
         onError(error) {
            Alert('error', error.message);
         },
      }
   );

   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;

   const oneDriveImages = JSON.parse(data.oneDriveItem)
      .value.filter((i) => i.file.mimeType === 'image/jpeg')
      .map((i) => ({
         ...i,
         selected: selectedIds.includes(i.id),
      }));

   return (
      <div className="space-y-8">
         <div>
            <h4>One Drive Images</h4>
            <div className="grid grid-cols-6 gap-6 mt-3">
               {oneDriveImages.map((i) => (
                  <div
                     key={i.id}
                     className={`flex flex-col items-center border-2 cursor-pointer
                            ${
                               i.selected
                                  ? 'border-blue-600'
                                  : 'border-transparent'
                            }
                `}
                     onClick={() =>
                        syncProductLineWisImagesRes.loading
                           ? null
                           : setSelectedIds((prev) =>
                                prev.includes(i.id)
                                   ? prev.filter((j) => j !== i.id)
                                   : [...prev, i.id]
                             )
                     }
                  >
                     <OneDriveImage thumbnails={i.thumbnails} />
                     <div className="text-xs mt-1 break-all whitespace-pre-wrap text-center p-3">
                        {i.name}
                     </div>
                  </div>
               ))}
            </div>
         </div>

         <div>
            <h4>Product Line: {data.productLine.name}</h4>
            <div className="grid grid-cols-6 gap-6 mt-3">
               {data.productLine.newImages.map((i) => (
                  <div key={i.id} className="flex flex-col items-center ">
                     <div>
                        <img src={i.url} alt={i.id} />
                     </div>
                     <div className="text-xs mt-1 break-all whitespace-pre-wrap text-center">
                        {i.name}
                     </div>
                  </div>
               ))}
            </div>

            {syncProductLineWisImagesRes.loading ? (
               <div className="relative flex">
                  <InlineSpinner size={21} text="Updating..." />
               </div>
            ) : (
               <div className="flex text-lg mt-4 space-x-8">
                  <Button
                     bold
                     title="Sync Images to Product Line"
                     loading={syncProductLineWisImagesRes.loading}
                     disabled={
                        selectedIds.length === 0 ||
                        syncProductLineWisImagesRes.loading
                     }
                     onClick={() =>
                        syncProductLineWisImages({
                           variables: { slug, ids: selectedIds },
                        })
                     }
                  />

                  <Button
                     title="Delete Product Line Images"
                     color="red"
                     onClick={() => {
                        if (
                           window.confirm(
                              'Are you sure to delete images for this product line?'
                           )
                        )
                           syncProductLineWisImages({
                              variables: { slug, ids: [] },
                           });
                     }}
                  />
               </div>
            )}
         </div>

         <div>
            <h4>Product Items</h4>
            <div className="space-y-4 mt-6">
               {data.productLine.products
                  .filter((i) => i.isActive)
                  .map((product) => (
                     <ProductImages
                        key={product.id}
                        product={product}
                        imageIds={selectedIds}
                     />
                  ))}
            </div>
         </div>

         <div className="text-xs opacity-70 mt-8">
            Sync images will replace all the product images for wis only with
            the selected one drive images. Which is now mainly used in Customer
            Form and as product preview in wis.
         </div>
      </div>
   );
};

const OneDriveImage = ({ thumbnails }) => {
   const medium = thumbnails[0].medium;

   return (
      <div>
         <img src={medium.url} />
      </div>
   );
};

const SYNC_PRODUCT_WIS_IMAGES = gql`
   mutation SYNC_PRODUCT_WIS_IMAGES($productId: ID!, $ids: [String]!) {
      syncWisProductImagesFromOneDrive(productId: $productId, ids: $ids) {
         product {
            id
            images {
               id
               url(size: "300x300")
            }
         }
      }
   }
`;

const ProductImages = ({ product, imageIds }) => {
   const [syncProductWisImages, syncProductWisImagesRes] = useMutation(
      SYNC_PRODUCT_WIS_IMAGES,
      {
         onCompleted() {
            Alert('success', 'Images synced.');
         },
         onError(error) {
            Alert('error', error.message);
         },
      }
   );

   return (
      <div className="border-t pt-4">
         <div>
            [{product.number}] {product.name}
         </div>
         <div className="mt-2">
            {product.images.length === 0 ? (
               <div className="opacity-70">
                  No product images, using product line images.
               </div>
            ) : (
               <div className="grid grid-cols-6 gap-6 mt-3">
                  {product.images.map((i) => (
                     <div key={i.id} className="flex flex-col items-center ">
                        <div>
                           <img src={i.url} alt={i.id} />
                        </div>
                        <div className="text-xs mt-1 break-all whitespace-pre-wrap text-center">
                           {i.name}
                        </div>
                     </div>
                  ))}
               </div>
            )}
         </div>

         {syncProductWisImagesRes.loading ? (
            <div className="relative flex mt-2">
               <InlineSpinner size={21} text="Updating..." />
            </div>
         ) : (
            <div className="flex space-x-6 mt-2">
               <Button
                  bold
                  title="Sync Images to Product"
                  loading={syncProductWisImagesRes.loading}
                  disabled={
                     imageIds.length === 0 || syncProductWisImagesRes.loading
                  }
                  onClick={() =>
                     syncProductWisImages({
                        variables: {
                           productId: product.id,
                           ids: imageIds,
                        },
                     })
                  }
               />

               {product.images.length > 0 ? (
                  <Button
                     title="Delete Product Images"
                     color="red"
                     onClick={() => {
                        if (
                           window.confirm(
                              'Are you sure to delete images for this product line?'
                           )
                        )
                           syncProductWisImages({
                              variables: {
                                 productId: product.id,
                                 ids: [],
                              },
                           });
                     }}
                  />
               ) : null}
            </div>
         )}
      </div>
   );
};

export default OneDriveFolder;
