import { gql } from "@apollo/client";

export const FETCH_SUPPLIER_PENDING_INVENTORY_UPDATE_REQUEST = gql`
  query FETCH_SUPPLIER_PENDING_INVENTORY_UPDATE_REQUEST($id: ID!) {
    supplierStockUpdateRequest(id: $id) {
      id
      stocks {
        product {
          id
          name
          number
        }
        qty
        readyDate
        remark
      }
    }
  }
`;

export const CONFIRM_SUPPLIER_STOCK_UPDATE_REQUEST = gql`
  mutation CONFIRM_SUPPLIER_STOCK_UPDATE_REQUEST($id: ID!, $state: String!) {
    confirmSupplierStockUpdateRequest(id: $id, state: $state) {
      request {
        id
        state
      }
    }
  }
`;
