import { gql, useQuery } from "@apollo/client";
import { Select } from "components/Form";

const FETCH_CUSTOMERS_FOR_SALES_ORDER = gql`
  query FETCH_CUSTOMERS_FOR_SALES_ORDER {
    customers(hasAreas: true, prefetchProductionPlans: true) {
      id
      name
      areas {
        id
        name
      }
      odooPartnerId
      lastProductionPlan {
        id
        wisPriceList {
          id
        }
        odooSalesOrderPriceListId
        odooSalesOrderPaymentTermsId
        odooTaxId
        odooXDeliverymethod
        odooIncoterm
      }
    }
  }
`;

const CustomersSelector = ({ value, onChange, disabled }) => {
  const { loading, error, data } = useQuery(FETCH_CUSTOMERS_FOR_SALES_ORDER);

  return (
    <Select
      disabled={disabled || loading || error}
      value={value || "0"}
      onChange={(e) => {
        const customer = data.customers.find((i) => i.id === e.target.value);
        onChange(customer);
      }}
    >
      {loading ? (
        <option>Loading...</option>
      ) : error ? (
        <option>{error.message}</option>
      ) : (
        <>
          <option value="0" disabled>
            Choose Customer
          </option>
          {data.customers.map((customer) => (
            <option key={customer.id} value={customer.id} disabled={!customer.odooPartnerId}>
              {customer.name}
              {customer.odooPartnerId ? null : ` [not connect with Odoo]`}
            </option>
          ))}
        </>
      )}
    </Select>
  );
};

export default CustomersSelector;
