import { DatePicker, Input } from "components/Form";
import CharlesButton from "components/charles/base";
import moment from "moment";
import { BsTrash } from "react-icons/bs";

const ConsumedRecords = ({ orderLines, editRecords, setEditRecords }) => {
  function onChangeRecord(index, field, value) {
    setEditRecords((prevData) =>
      prevData.map((prev, prevIndex) => {
        if (index !== prevIndex) return prev;
        return { ...prev, [field]: value };
      })
    );
  }

  return (
    <div className="-mx-2 mt-2">
      <table className="text-sm">
        <thead>
          <tr className="text-right">
            <th className="text-left">Date</th>
            <th className="text-right">Qty</th>
            <th className="text-right ">Notes</th>
          </tr>
        </thead>
        <tbody>
          {orderLines.map((line, index) => (
            <tr key={index}>
              <td>{line.date}</td>
              <td className="text-right">{line.qty}</td>
              <td className="text-right">{line.notes}</td>
            </tr>
          ))}

          {editRecords.map((i, index) => (
            <tr key={index}>
              <td>
                <div className="flex items-center space-x-2">
                  <CharlesButton danger onClick={() => setEditRecords((prev) => prev.filter((_, prevIndex) => prevIndex !== index))}>
                    <BsTrash />
                  </CharlesButton>
                  <DatePicker value={i.date} onDayChange={(d) => onChangeRecord(index, "date", moment(d).format("YYYY-MM-DD"))} />
                </div>
              </td>
              <td className="text-right">
                <Input className="text-right" placeholder="Qty" value={i.qty} onChange={(e) => onChangeRecord(index, "qty", e.target.value)} />
              </td>
              <td className="text-right">
                <Input className="text-center" placeholder="Notes" value={i.notes} onChange={(e) => onChangeRecord(index, "notes", e.target.value)} />
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td>
              <CharlesButton
                className="text-sm"
                onClick={() => setEditRecords((prev) => [...prev, { qty: "", date: moment(new Date()).format("YYYY-MM-DD"), notes: "" }])}
              >
                + New Record
              </CharlesButton>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default ConsumedRecords;
