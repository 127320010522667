import InputSelect from "components/InputSelect";
import { useState } from "react";

const ProductSelector = ({ options, initialValue, onSelect, disabled }) => {
  const [searchText, setSearchText] = useState(initialValue);

  return (
    <InputSelect
      disabled={disabled}
      className="w-full"
      options={options}
      onSelect={(product) => {
        onSelect(product);
        setSearchText(product.name);
      }}
      value={searchText}
      onChange={setSearchText}
      inputProps={{ disabled }}
    />
  );
};

export default ProductSelector;
