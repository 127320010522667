import { byName } from 'utils/sort';

const { useQuery, gql } = require('@apollo/client');

const FETCH_ALL_FACTORIES = gql`
   query FETCH_ALL_FACTORIES {
      allFactories(isActive: true) {
         id
         name
      }
   }
`;

const useFactories = () => {
   const { loading, error, data } = useQuery(FETCH_ALL_FACTORIES);

   const allFactories = data ? [...data.allFactories].sort(byName) : null;

   return { loading, error, allFactories };
};

export default useFactories;
