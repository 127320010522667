import React, { useState } from "react";
import { useQuery, useMutation, gql } from "@apollo/client";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import { Select, Input, FileSelector } from "components/Form";
import { Tag } from "components/base";
import { QC_REPORT_FRAGMENT } from "../graphql";
import { Alert } from "components/Toast";
import CharlesButton from "components/charles/base";
import useFileUploader from "hooks/useFileUploader";
import { BsTrash } from "react-icons/bs";
import { parseError } from "apollo";
import FactorySelector from "components/FactorySelector";
import ProductLineSelector from "components/ProductLineSelector";

const DEFECT_TYPES = ["major", "minor"];

const FETCH_DEFECT_CODES = gql`
  query FETCH_DEFECT_CODES {
    allDefectCodes {
      id
      code
      description
    }
  }
`;

const SAVE_QC_ISSUE = gql`
  mutation SAVE_QC_ISSUE(
    $reportId: ID
    $issueId: ID
    $defectCodeId: ID!
    $defectType: String!
    $qty: Int!
    $images: [String]
    $factoryId: ID
    $productLineId: ID
  ) {
    saveQcIssue(
      reportId: $reportId
      issueId: $issueId
      defectCodeId: $defectCodeId
      defectType: $defectType
      qty: $qty
      images: $images
      factoryId: $factoryId
      productLineId: $productLineId
    ) {
      qcReport {
        ...qcReport
      }
    }
  }
  ${QC_REPORT_FRAGMENT}
`;

function IssueForm({ reportId, hide }) {
  const [defectCodeId, setDefectCodeId] = useState("0");
  const [defectType, setDefectType] = useState(DEFECT_TYPES[0]);
  const [qty, setQty] = useState(1);
  const [images, setImages] = useState([]);
  const [factoryId, setFactoryId] = useState(null);
  const [productLineId, setProductLineId] = useState(null);

  const { isUploading, handleFileUpload } = useFileUploader((urls) => {
    setImages((prev) => [...prev, ...urls]);
  });

  const { loading, error, data } = useQuery(FETCH_DEFECT_CODES);
  const [saveQcIssue, saveQcIssueRes] = useMutation(SAVE_QC_ISSUE, {
    onCompleted() {
      Alert("success", "保存成功");
      hide();
    },
    onError(error) {
      Alert("error", parseError(error));
    },
  });

  function validateInput() {
    if (!parseInt(qty, 10)) {
      Alert("error", "Qty must be a valid number. 请输入正确数量.");
      return false;
    }
    if (defectCodeId === "0") {
      Alert("error", "Defect code is empty. 请选择缺陷类型.");
      return false;
    }
    return true;
  }

  function trySave() {
    const valid = validateInput();
    if (!valid) return false;

    saveQcIssue({ variables: { reportId, defectCodeId, defectType, qty, images, factoryId, productLineId } });
  }

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  return (
    <div className="space-y-6">
      <Select className="w-full overflow-auto" onChange={(e) => setDefectCodeId(e.target.value)} value={defectCodeId}>
        <option value="0" disabled>
          -- Choose Error Code 请选择缺陷类型 --
        </option>
        {data.allDefectCodes.map((i, index) => (
          <option key={index} value={i.id}>
            {i.code} - {i.description}
          </option>
        ))}
      </Select>

      <div className="flex items-center space-x-4 justify-between">
        <label>缺陷程度:</label>
        <div className="flex">
          {DEFECT_TYPES.map((i, index) => (
            <Tag title={i} key={index} className="mr-2 text-sm" onClick={(_) => setDefectType(i)} selected={defectType === i} />
          ))}
        </div>
      </div>

      <div className="mt-4 flex items-center space-x-4">
        <label>Quantity 不良数:</label>
        <Input className="text-center w-auto flex-1" value={qty} onChange={(e) => setQty(e.target.value)} />
      </div>

      <div>
        {images.map((url, index) => (
          <div key={index} className="flex items-start justify-between space-x-4 py-4 border-t">
            <div className="flex-1">
              <img src={url} />
            </div>
            <CharlesButton danger onClick={() => setImages((prev) => prev.filter((_, imageIndex) => imageIndex !== index))}>
              <BsTrash />
            </CharlesButton>
          </div>
        ))}
        <FileSelector
          accept="image/*"
          title="+ add images 添加图片"
          isUploading={isUploading}
          onChange={(e) => {
            const files = [...e.target.files];
            handleFileUpload(files);
            e.target.value = null;
          }}
        />
      </div>

      <hr />

      <div className="opacity-70">如果本产品是一个由多个不同产品组合的套装，请选择本缺陷所属的产品以及其供应商。否则下面不需要选择。</div>

      <div className="flex justify-between items-center">
        <label className="pr-3">缺陷产品：</label>
        <ProductLineSelector value={productLineId} onChange={(productLineId) => setProductLineId(productLineId)} />
      </div>
      <div className="flex justify-between items-center">
        <label className="pr-3">缺陷产品的供应商：</label>
        <FactorySelector value={factoryId} onChange={(factoryId) => setFactoryId(factoryId)} />
      </div>

      <hr />

      <div className="flex items-center space-x-6">
        <CharlesButton primary loading={saveQcIssueRes.loading} disabled={isUploading || saveQcIssueRes.loading} onClick={trySave}>
          Save
        </CharlesButton>
        <CharlesButton onClick={hide}>Cancel</CharlesButton>
      </div>
    </div>
  );
}

export default IssueForm;
