import { gql, useQuery } from "@apollo/client";
import { NavigationBar } from "components/base";
import CharlesButton from "components/charles/base";
import Errors from "components/Errors";
import Spinner from "components/Spinner";
import { Alert } from "components/Toast";
import { useModals } from "ModalProvider";
import { useParams } from "react-router-dom";
import { byName } from "utils/sort";
import Holidays from "./Holidays";
import { RxCalendar } from "react-icons/rx";

const FETCH_PRODUCTION_SUPPLIER_INFO = gql`
  query FETCH_PRODUCTION_SUPPLIER_INFO($id: ID!) {
    factory(id: $id) {
      id
      odooId
      name
      sharedCode
      status
      products {
        id
      }
      productionForms {
        id
      }
      productionCategories {
        id
        name
        type
        timeList {
          id
          days
          qty
        }
        qtyPerDay
      }
      offDates {
        id
        date
      }
    }
  }
`;

const SupplierDetail = () => {
  const { id } = useParams();
  const { loading, error, data } = useQuery(FETCH_PRODUCTION_SUPPLIER_INFO, {
    variables: { id },
  });

  const modal = useModals();

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const sortedProductionCategories = [...data.factory.productionCategories].sort(byName);

  function showHolidays() {
    modal.present({
      title: "Holidays",
      subtitle: data.factory.name,
      maxWidth: "max-w-7xl",
      children: <Holidays offDates={data.factory.offDates} />,
    });
  }

  return (
    <div>
      <NavigationBar
        title={data.factory.name}
        backTo="/production/suppliers"
        rightButtons={
          <div>
            <a href={`${process.env.REACT_APP_SERVER_ADMIN_URL}csr/csrfactory/${id}`} target="_blank" rel="noopener noreferrer">
              Admin
            </a>
          </div>
        }
      />
      <div className="grid grid-cols-12 gap-8 p-6">
        <div className="space-y-8 col-span-9">
          <div className="card">
            <h5>Production Categories</h5>
            <div className="space-y-4 mt-4">
              {sortedProductionCategories.length > 0 ? (
                sortedProductionCategories.map((i) => (
                  <div key={i.id} className="">
                    <div className="font-semibold">{i.name}</div>
                    <div className="text-xs opacity-70">
                      <Time productionCategory={i} />
                    </div>
                  </div>
                ))
              ) : (
                <div className="opacity-60">No production category for this supplier.</div>
              )}
            </div>
          </div>
        </div>

        <div className="col-span-3">
          <div className="card">
            <h5>Holidays</h5>
            <div className="mt-2 opacity-70">
              Supplier holidays are the days that the supplier is not available for production. Supplier could set it directly in Supplier Portal.
            </div>
            <div className="mt-4">
              <CharlesButton icon={<RxCalendar size={16} />} onClick={showHolidays}>
                Show Supplier Holidays
              </CharlesButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Time = ({ productionCategory }) => {
  if (productionCategory.type === "BY_QTY") {
    if (productionCategory.timeList.length === 0) return <div className="text-pink-600">No time set for this production category.</div>;

    return (
      <div className="flex items-center space-x-4">
        {productionCategory.timeList.map((i, index) => (
          <div key={index}>
            {i.qty}: {i.days} days
          </div>
        ))}
      </div>
    );
  }
  return <div>{productionCategory.qtyPerDay} / day</div>;
};

export default SupplierDetail;
