import { gql, useMutation, useQuery } from '@apollo/client';
import { Button } from 'components/base';
import Errors from 'components/Errors';
import Page from 'components/Page';
import Spinner from 'components/Spinner';
import { Alert } from 'components/Toast';
import moment from 'moment';
import { formatDate } from 'react-day-picker/moment';
import { useNavigate } from 'react-router-dom';

const FETCH_ALL_PURCHASES = gql`
   query FETCH_ALL_PURCHASES {
      allPurchases {
         id
         createdBy {
            id
            email
         }
         state
         createdAt
         startDate
         readyDate
         totalCbm
         productCost
         shippingCost
         totalCost
      }
   }
`;

const CREATE_PURCHASE_ORDER = gql`
   mutation CREATE_PURCHASE_ORDER {
      savePurchase {
         purchase {
            id
         }
      }
   }
`;

const Purchases = () => {
   const { loading, error, data } = useQuery(FETCH_ALL_PURCHASES);
   const navigate = useNavigate();
   const [createPurchaseOrder, createPurchaseOrderRes] = useMutation(
      CREATE_PURCHASE_ORDER,
      {
         onCompleted(res) {
            navigate(`/purchase/${res.savePurchase.purchase.id}`);
         },
         onError(error) {
            Alert('error', error.message);
         },
         refetchQueries: [FETCH_ALL_PURCHASES],
      }
   );

   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;

   return (
      <Page title="Purchases">
         <div className="p-6">
            <div>
               <Button
                  title="+ Create Purchase Order"
                  size="base"
                  disabled={createPurchaseOrderRes.loading}
                  loading={createPurchaseOrderRes.loading}
                  onClick={createPurchaseOrder}
               />
            </div>

            <table className="with-line mt-4">
               <thead>
                  <tr>
                     <th>ID</th>
                     <th>Start Date</th>
                     <th>Ready Date</th>
                     <th className="text-right">Total CBM(m³)</th>
                     <th className="text-right">Product Cost</th>
                     <th className="text-right">Shipping Cost</th>
                     <th className="text-right">Total Cost</th>
                     <th className="text-center">State</th>
                     <th className="text-right">Created</th>
                  </tr>
               </thead>
               <tbody>
                  {data.allPurchases.map((purchase) => (
                     <tr key={purchase.id}>
                        <td>
                           <Button
                              title={`#${purchase.id}`}
                              link={`/purchase/${purchase.id}`}
                           />
                        </td>
                        <td>
                           {purchase.startDate
                              ? formatDate(purchase.startDate, 'YYYY-MM-DD')
                              : ' - '}
                        </td>
                        <td>
                           {purchase.readyDate
                              ? formatDate(purchase.readyDate, 'YYYY-MM-DD')
                              : '- '}
                        </td>
                        <td className="text-right">
                           {purchase.totalCbm.toFixed(3)}
                        </td>
                        <td className="text-right">
                           {purchase.productCost.toFixed(2)}
                        </td>
                        <td className="text-right">
                           {purchase.shippingCost.toFixed(2)}
                        </td>

                        <td className="text-right">
                           {purchase.totalCost.toFixed(2)}
                        </td>
                        <td className="text-center">{purchase.state}</td>
                        <td className="text-right opacity-60">
                           {purchase.createdBy
                              ? purchase.createdBy.email
                              : null}{' '}
                           {moment(purchase.createdAt).format(
                              'YYYY-MM-DD HH:mm:ss'
                           )}
                        </td>
                     </tr>
                  ))}
               </tbody>
            </table>
         </div>
      </Page>
   );
};

export default Purchases;
