const { useQuery, gql } = require("@apollo/client");

export const FETCH_AMAZON_LIVE_PURCHASE_ORDERS = gql`
  query FETCH_AMAZON_LIVE_PURCHASE_ORDERS($regionCode: String!, $purchaseOrderState: String) {
    amzLivePurchaseOrders(regionCode: $regionCode, purchaseOrderState: $purchaseOrderState) {
      orders {
        id
        data
      }
      nextToken
    }
  }
`;

export const useAmazonPurchaseOrders = (regionCode, purchaseOrderState) => {
  const { loading, error, data } = useQuery(FETCH_AMAZON_LIVE_PURCHASE_ORDERS, {
    variables: { regionCode, purchaseOrderState },
  });
  const purchaseOrders = data ? data.amzLivePurchaseOrders.orders.map((i) => JSON.parse(i.data)) : [];
  const nextToken = data ? data.amzLivePurchaseOrders.nextToken : null;
  return { loading, error, purchaseOrders, nextToken };
};

const FETCH_AMAZON_LIVE_PURCHASE_ORDERS_STATUS = gql`
  query FETCH_AMAZON_LIVE_PURCHASE_ORDERS_STATUS($regionCode: String!, $purchaseOrderNumber: String) {
    amzLivePurchaseOrderStatus(regionCode: $regionCode, purchaseOrderNumber: $purchaseOrderNumber)
  }
`;

export const useAmazonPurchaseOrdersStatus = (regionCode, purchaseOrderNumber) => {
  const { loading, error, data } = useQuery(FETCH_AMAZON_LIVE_PURCHASE_ORDERS_STATUS, {
    variables: { regionCode, purchaseOrderNumber },
  });
  const purchaseOrdersStatus = data ? JSON.parse(data.amzLivePurchaseOrderStatus) : null;
  return { loading, error, purchaseOrdersStatus };
};

const FETCH_IMPORTED_AMAZON_PURCHASE_ORDERS = gql`
  query FETCH_IMPORTED_AMAZON_PURCHASE_ORDERS($numbers: [String]) {
    amzVcPurchaseOrders(numbers: $numbers) {
      id
      number
      odooSalesOrderId
      odooSalesOrderName
      createdAt
    }
  }
`;

export const useImportedAmazonPurchaseOrders = (numbers) => {
  const { loading, error, data } = useQuery(FETCH_IMPORTED_AMAZON_PURCHASE_ORDERS, { variables: { numbers }, skip: numbers.length === 0 });

  return { loading, error, data };
};

const FETCH_AMAZON_PARTY = gql`
  query FETCH_AMAZON_PARTY($fcCode: String!) {
    amzParty(fcCode: $fcCode) {
      id
      address
      fcCode
      odooCustomerId
      odooInvoiceAddressId
      odooDeliveryAddressId
    }
  }
`;

export const useAmazonParty = (fcCode) => {
  const { loading, error, data } = useQuery(FETCH_AMAZON_PARTY, {
    variables: { fcCode },
  });

  return { loading, error, data };
};

export const FETCH_AMAZON_STOCK_CATEGORIES = gql`
  query FETCH_AMAZON_STOCK_CATEGORIES {
    listAmazonStockCategories {
      id
      name
      regions
    }
  }
`;

export const useAmazonStockCategories = () => useQuery(FETCH_AMAZON_STOCK_CATEGORIES);

const FETCH_AMAZON_VC_PURCHASE_ORDER = gql`
  query FETCH_AMAZON_VC_PURCHASE_ORDER($id: ID, $number: String) {
    amzVcPurchaseOrder(id: $id, number: $number) {
      id
      number
      stockCategory {
        id
        name
        regions
      }
      odooSalesOrderId
      odooSalesOrderName
      lines {
        id
        barcode
        asin
        qtyRequested
        netCostAmount
        netCostCurrency
        actionType
        actionQty
        stock {
          id
          name
          number
          odooId
        }
      }
    }
  }
`;

export const useAmazonVCPurchaseOrder = ({ id, number }) => {
  return useQuery(FETCH_AMAZON_VC_PURCHASE_ORDER, {
    variables: { id, number },
  });
};
