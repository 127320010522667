import { InlineSpinner } from "components/Spinner";
import { FiChevronRight } from "react-icons/fi";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";

export const CharlesButton = ({ className = "", children, loading, next, prev, icon, primary, danger, ...rest }) => {
  return (
    <button
      className={`font-semibold opacity-90 hover:opacity-100 rounded-full whitespace-nowrap disabled:cursor-not-allowed disabled:opacity-50 flex items-center space-x-2
          ${
            primary
              ? "bg-sky-600 hover:bg-sky-700 active:bg-sky-800 text-gray-100 px-6 py-2 text-xs"
              : danger
              ? "text-red-600 hover:text-red-700 active:text-red-800 dark:text-red-700 dark:hover:text-red-800 dark:active:text-red-900"
              : "text-sky-600 hover:text-sky-700 active:text-sky-800"
          }
          ${className}
        `}
      {...rest}
      disabled={loading || rest.disabled}
    >
      {loading ? (
        <div>
          <InlineSpinner color={primary ? "#eee" : "#00bbd6"} size={14} text={null} />
        </div>
      ) : prev ? (
        <IoChevronBack size={21} />
      ) : icon ? (
        icon
      ) : null}
      {children ? <div>{children}</div> : null}
      {next ? <IoChevronForward size={21} /> : null}
    </button>
  );
};

export const CharlesButtonWithArrow = ({ show, children, ...rest }) => {
  return (
    <CharlesButton {...rest}>
      <div className="flex items-center">
        {children ? <span className="pr-1">{children}</span> : null}
        <FiChevronRight
          className={`transform-gpu duration-200 inline-block align-middle
            ${show ? "rotate-90" : "rotate-0"}`}
          size={21}
        />
      </div>
    </CharlesButton>
  );
};

export default CharlesButton;
