import { useMutation } from "@apollo/client";
import { AppContext } from "App";
import { Alert } from "components/Toast";
import CharlesButton from "components/charles/base";
import { LOGOUT } from "graphql/auth";
import { useContext } from "react";
import { BsFillExclamationCircleFill } from "react-icons/bs";

const PageNotFound = () => {
  const { user } = useContext(AppContext);

  const [logout, logoutRes] = useMutation(LOGOUT, {
    onCompleted: () => {
      window.location.href = process.env.REACT_APP_AUTH_SERVER + "?redirectTo=" + window.location.origin;
    },
    onError(err) {
      Alert("error", err.message);
    },
  });

  if (!user)
    return (
      <div className="min-h-screen flex items-center justify-center w-full h-32">
        <div className="space-y-8 -mt-20 px-6">
          <div className="flex justify-center text-red-600">
            <BsFillExclamationCircleFill size={72} />
          </div>
          <h3 className="text-center">Waboba Internal System</h3>
          <div className="text-center leading-loose max-w-3xl">This page is login required.</div>
        </div>
      </div>
    );

  return (
    <div className="min-h-screen flex items-center justify-center w-full h-32">
      <div className="space-y-8 -mt-20 px-6">
        <div className="flex justify-center text-red-600">
          <BsFillExclamationCircleFill size={72} />
        </div>

        <h3 className="text-center">Waboba Internal System</h3>

        <div className="text-center leading-loose max-w-2xl">
          This account({user.email}) is not allowed to access this page. If you want to access this page, please try another account. If you don't have another
          account, please contact the administrator.
        </div>

        <div className="flex justify-center">
          <CharlesButton danger onClick={logout} loading={logoutRes.loading}>
            Logout and Try Another Account
          </CharlesButton>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
