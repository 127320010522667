import { useMutation } from "@apollo/client";
import { UPDATE_ASSESSMENT_STATUS } from "./graphql";
import { Alert } from "components/Toast";
import { parseError } from "apollo";
import CharlesButton from "components/charles/base";

const CompleteAssessmentView = ({ id, dismiss }) => {
  const [updateAssessmentStatus, updateAssessmentStatusRes] = useMutation(UPDATE_ASSESSMENT_STATUS, {
    variables: {
      id,
      status: "completed",
    },
    onCompleted() {
      Alert("success", "Assessment Completed.");
      dismiss();
    },
    onError(error) {
      Alert("error", parseError(error));
    },
  });

  return (
    <div className="space-y-6">
      <div className="text-sm">
        Are you sure to complete this assessment? Once you complete the assessment, the supplier will be notified. Make sure we have completed all our work
        before completing the assessment. You can select the questions to follow up in next assessment.
      </div>

      <hr />

      <div>
        <CharlesButton primary onClick={updateAssessmentStatus} loading={updateAssessmentStatusRes.loading}>
          Complete Assessment
        </CharlesButton>
      </div>
    </div>
  );
};

export default CompleteAssessmentView;
