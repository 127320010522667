import React from "react";
import { useQuery, gql } from "@apollo/client";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import Image from "components/Image";

const FETCH_ALL_WARNINGS = gql`
  query FETCH_ALL_WARNINGS {
    allProductWarnings {
      id
      name
      content
      productlineSet {
        id
        activeForWis
        name
        mainImage(size: "300x300")
      }
    }
  }
`;

const ProductWarnings = () => {
  const { loading, error, data } = useQuery(FETCH_ALL_WARNINGS);
  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  return (
    <div className="p-4 lg:p-6 flex overflow-auto">
      {data.allProductWarnings.map((warning, index) => {
        const productLines = warning.productlineSet.filter((i) => i.activeForWis);
        return (
          <div key={index} className="bg-white dark:bg-gray-800 rounded-xl p-4 lg:p-6 flex-1 flex flex-col mr-4">
            <div className="font-bold text-base whitespace-nowrap w-72">{warning.name}</div>
            <div className="whitespace-pre-wrap mt-2">{warning.content}</div>
            <div className="mt-2 pb-2 border-b border-gray-200 dark:border-gray-700">
              <div className="text-xs text-gray-500">{warning.productlineSet.length} Product Lines</div>
            </div>
            <div className="overflow-auto">
              {productLines.map((line) => (
                <div className="border-b border-gray-200 dark:border-gray-700 py-2 text-xs flex items-center justify-start">
                  <Image src={line.mainImage} size="w-8 h-8" />
                  <div className="ml-4 whitespace-nowrap">{line.name}</div>
                </div>
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ProductWarnings;
