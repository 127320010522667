import React from "react";
import { Input, Select, Text } from "./Form";
import SizeTips from "../assets/size-tips.png";

export const CubeSizeInput = ({ x, y, z, onChange, unit = "mm" }) => {
  return (
    <div className="flex items-center">
      <Input
        className="w-20 text-center appearance-none"
        validated={!isNaN(x)}
        placeholder="X"
        value={x || 0}
        onChange={(e) => {
          onChange({ x: e.target.value, y, z });
        }}
      />
      <span className="px-4">&times;</span>
      <Input
        className="w-20 text-center appearance-none"
        validated={!isNaN(z)}
        placeholder="Z"
        value={z || 0}
        onChange={(e) => {
          onChange({ x, y, z: e.target.value });
        }}
      />
      <span className="px-4">&times;</span>
      <Input
        className="w-20 text-center appearance-none"
        validated={!isNaN(y)}
        placeholder="Y"
        value={y || 0}
        onChange={(e) => {
          onChange({ x, y: e.target.value, z });
        }}
      />
      <span className="ml-2">{unit}</span>
      <div className="ml-4">
        <img src={SizeTips} className="h-8" alt="cub size tips" />
      </div>
    </div>
  );
};

const ItemSizeInput = ({ value, onChange, unit = "mm" }) => {
  return (
    <div className={value.shape === "multi" ? "flex-1" : "flex flex-1 items-center"}>
      <Select className="mr-4" value={value.shape} onChange={(e) => onChange({ ...value, shape: e.target.value })}>
        <option value="cube">Cube</option>
        <option value="ball">Ball</option>
        <option value="cylindrical">Cylindrical</option>
        <option value="flat">Flat</option>
        <option value="multi">Multi Sizes</option>
      </Select>

      {value.shape === "multi" ? (
        <div className="w-full mt-2">
          <Text
            placeholder="Each line should be a size, like: Component 1: 20mm x 30mm x 40mm."
            value={value.multiSizes}
            onChange={(e) => onChange({ ...value, multiSizes: e.target.value })}
          />
        </div>
      ) : (
        <div className="flex items-center">
          {value.shape === "ball" ? (
            <>
              <Input
                validated={!isNaN(value.ballDiameter)}
                className="w-20 text-center"
                placeholder="Radius"
                value={value.ballDiameter || 0}
                onChange={(e) => {
                  onChange({
                    ...value,
                    ballDiameter: e.target.value,
                  });
                }}
              />
              <span className="ml-2">{unit}</span>
            </>
          ) : value.shape === "cube" ? (
            <>
              <Input
                validated={!isNaN(value.cubeX)}
                className="w-20 text-center"
                placeholder="X"
                value={value.cubeX || 0}
                onChange={(e) => {
                  onChange({ ...value, cubeX: e.target.value });
                }}
              />

              <span className="px-4">&times;</span>

              <Input
                validated={!isNaN(value.cubeZ)}
                className="w-20 text-center"
                placeholder="Z"
                value={value.cubeZ || 0}
                onChange={(e) => {
                  onChange({ ...value, cubeZ: e.target.value });
                }}
              />

              <span className="px-4">&times;</span>

              <Input
                validated={!isNaN(value.cubeY)}
                className="w-20 text-center"
                placeholder="Y"
                value={value.cubeY}
                onChange={(e) => {
                  onChange({ ...value, cubeY: e.target.value });
                }}
              />
              <span className="ml-2">{unit}</span>
              <div className="ml-4 flex items-center">
                <img src={SizeTips} className="h-8" alt="cub size tips" />
                <span className="ml-2 text-xs text-gray-500">长(x) x 宽(z) x 高(y)</span>
              </div>
            </>
          ) : value.shape === "flat" ? (
            <>
              <Input
                validated={!isNaN(value.flatX)}
                className="w-20 text-center"
                placeholder="X"
                value={value.flatX || 0}
                onChange={(e) => {
                  onChange({ ...value, flatX: e.target.value });
                }}
              />
              <span className="px-4">&times;</span>
              <Input
                validated={!isNaN(value.flatY)}
                className="w-20 text-center"
                placeholder="Y"
                value={value.flatY || 0}
                onChange={(e) => {
                  onChange({ ...value, flatY: e.target.value });
                }}
              />
              <span className="ml-2">{unit}</span>
            </>
          ) : value.shape === "cylindrical" ? (
            <>
              <Input
                validated={!isNaN(value.cylindricalDiameter)}
                className="w-24 text-center"
                placeholder="Diameter"
                value={value.cylindricalDiameter}
                onChange={(e) => {
                  onChange({
                    ...value,
                    cylindricalDiameter: e.target.value,
                  });
                }}
              />
              <span className="px-4">&times;</span>
              <Input
                validated={!isNaN(value.cylindricalHeight)}
                className="w-20 text-center"
                placeholder="Height"
                value={value.cylindricalHeight}
                onChange={(e) => {
                  onChange({
                    ...value,
                    cylindricalHeight: e.target.value,
                  });
                }}
              />
              <span className="ml-2">{unit}</span>
            </>
          ) : (
            <div>Unknown shape</div>
          )}
        </div>
      )}
    </div>
  );
};

export default ItemSizeInput;
