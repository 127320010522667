import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import Page from "components/Page";
import { useModals } from "ModalProvider";
import PatentTaskForm from "./PatentTaskForm";
import { FETCH_ALL_PATENT_TASKS } from "./graphql";
import Status from "components/Status";
import CharlesButton from "components/charles/base";

const PatentTask = () => {
  const { loading, error, data } = useQuery(FETCH_ALL_PATENT_TASKS);
  const { present, hide } = useModals();

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  return (
    <Page
      title="Patent AI"
      leftButtons={
        <CharlesButton
          onClick={() =>
            present({
              title: "Create Patent Search Task",
              center: true,
              children: <PatentTaskForm hide={hide} />,
            })
          }
        >
          + Create Task
        </CharlesButton>
      }
    >
      <div className="p-6 space-y-6 text-sm">
        <div className="opacity-70 text-xs">
          <p>Patent AI is a tool that can help you quickly find the most relevant patents by AI.</p>
          <p>
            You start by uploading a downloaded file from{" "}
            <a href="https://patents.google.com/" target="_blank" rel="noreferrer">
              Google Patent
            </a>
            . This will create a task that will be executed in the background. Once the task is completed, you can view the results.
          </p>
        </div>

        {data.allPatentTasksOrmq.length > 0 && (
          <div className="card p-4">
            <h5 className="mb-2 pl-2">Runing Task</h5>
            <table>
              <thead>
                <tr>
                  <th className="w-1/4">Name</th>
                  <th>Status</th>
                  <th className="text-right">Started</th>
                </tr>
              </thead>
              <tbody>
                {data.allPatentTasksOrmq.map((task, index) => (
                  <tr key={index} className="border-t border-gray-100 dark:border-gray-700">
                    <td>{task.cpc}</td>
                    <td>
                      <Status status={"PROCESSING"} />
                    </td>
                    <td className="text-right text-xs">{task.startedAt}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {data.patentTasksByGroup.length > 0 && (
          <div className="card p-4">
            <h5 className="mb-2 pl-2">Completed Tasks</h5>
            <table>
              <thead>
                <tr>
                  <th className="w-1/4">Name</th>
                  <th>Status</th>
                  <th className="text-right">Start</th>
                  <th className="text-right">Completed</th>
                </tr>
              </thead>
              <tbody>
                {data.patentTasksByGroup.map((task, index) => (
                  <tr key={index} className="border-t border-gray-100 dark:border-gray-700">
                    <td>{task.success ? <Link to={`/tools/patentai/${task.cpc}`}>{task.cpc}</Link> : task.cpc}</td>
                    <td>{task.success ? <Status status={"SUCCESS"} /> : <Status status={"FAILED"} />}</td>
                    <td className="text-right text-xs">{task.startedAt}</td>
                    <td className="text-right text-xs">{task.stoppedAt}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </Page>
  );
};

export default PatentTask;
