import { gql, useQuery } from '@apollo/client';
import { Button } from 'components/base';
import Errors from 'components/Errors';
import Spinner from 'components/Spinner';
import {
   ResponsiveContainer,
   LineChart,
   Line,
   XAxis,
   YAxis,
   Tooltip,
   Legend,
} from 'recharts';
import { randomColors } from 'utils/colors';

const FETCH_SINGLE_MATERIAL = gql`
   query FETCH_SINGLE_MATERIAL($id: ID!) {
      material(id: $id) {
         id
         prices {
            id
            date
            value
         }
      }
   }
`;

const SingleMaterial = ({ id, name }) => {
   const { loading, error, data } = useQuery(FETCH_SINGLE_MATERIAL, {
      variables: { id },
   });

   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;

   const chartData = data.material.prices.map((i) => {
      return { ...i, 'Price in RMB': i.value };
   });

   const colors = randomColors(2);

   return (
      <div>
         <div className="flex justify-between items-center">
            <h5>{name}</h5>
            <Button title="Products" link={`/price/materials/${id}`} />
         </div>
         <div className="mt-4">
            <ResponsiveContainer width="100%" aspect={5 / 2}>
               <LineChart data={chartData}>
                  <XAxis dataKey="date" />
                  <YAxis width={36} angle={-45} />
                  <Tooltip content={CustomTooltip} />
                  <Line
                     type="monotone"
                     dataKey="Price in RMB"
                     stroke={colors[0]}
                     dot={false}
                  />
                  <Legend />
               </LineChart>
            </ResponsiveContainer>
         </div>
      </div>
   );
};

const CustomTooltip = ({ active, payload, label }) => {
   if (active && payload && payload.length) {
      return (
         <div className="px-6 py-3 rounded-2xl bg-opacity-80 bg-white dark:bg-gray-800 dark:bg-opacity-80">
            {payload.map((i, index) => (
               <div key={index}>{`${label}: ${i.value.toFixed(0)}`}</div>
            ))}
         </div>
      );
   }

   return null;
};

export default SingleMaterial;
