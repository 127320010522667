import { useQuery, gql, useMutation, useLazyQuery } from '@apollo/client';
import { Button } from 'components/base';
import Errors from 'components/Errors';
import Spinner from 'components/Spinner';
import { GENERATE_DOCUMENT } from 'graphql/mutations';
import { useState } from 'react';
import http from 'utils/http';
import { formatDate } from 'react-day-picker/moment';
import { DatePicker, Input, Text } from 'components/Form';
import { Alert } from 'components/Toast';
import { sayTotal } from 'utils/string';
import { useModals } from 'ModalProvider';

const FETCH_PRODUCTS_BY_ODOO_IDS = gql`
   query FETCH_PRODUCTS_BY_ODOO_IDS($odooIds: [Int]) {
      allProducts(odooIds: $odooIds) {
         id
         odooId
         productLine {
            id
            hsCodeForEu
         }
         quantityPerCarton
         ctnNetWeight
         ctnGrossWeight
      }
   }
`;

const FETCH_GENERATED_DOCUMENT = gql`
   query FETCH_GENERATED_DOCUMENT($id: ID!) {
      generatedDocument(id: $id) {
         id
         name
         data
      }
   }
`;

const CommercialInvoiceForm = ({ odooStockPickingDetail }) => {
   const odooIds = odooStockPickingDetail.moves_without_package.map(
      (line) => line.product_id[0]
   );
   const { loading, error, data } = useQuery(FETCH_PRODUCTS_BY_ODOO_IDS, {
      variables: { odooIds },
   });

   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;

   const transfer = {
      ...odooStockPickingDetail,
      deliveryAddress: `Wabona AB
C/O Charles Kendall Freight
Unit 2, Alpha Park, School Close, Chandlers Ford,
Southampton SO53 4BX
United Kingdom`,
      lines: odooStockPickingDetail.moves_without_package.map((line) => {
         const wisProduct = data.allProducts.find(
            (i) => i.odooId === line.product_id[0]
         );
         const cartons =
            wisProduct &&
            line.product_qty > 0 &&
            wisProduct.quantityPerCarton > 0
               ? line.product_qty / wisProduct.quantityPerCarton
               : 0;
         const netWeight = cartons
            ? (cartons * wisProduct.ctnNetWeight).toFixed(2)
            : 0;
         const grossWeight = cartons
            ? (cartons * wisProduct.ctnGrossWeight).toFixed(2)
            : 0;
         return {
            id: line.id,
            name: line.product_id[1],
            hsCode:
               wisProduct && wisProduct.productLine
                  ? wisProduct.productLine.hsCodeForEu
                  : null,
            qty: line.product_qty,
            cartons,
            netWeight,
            grossWeight,
            unitCost: 0,
            wisProduct,
         };
      }),
   };

   return <Content transfer={transfer} />;
};

const Content = ({ transfer }) => {
   const [name, setName] = useState(transfer.name);
   const [consignee, setConsignee] = useState(`WABOBA AB
VAT: GB324122352
EORI: GB324122352000`);
   const [deliveryAddress, setDeliveryAddress] = useState(
      transfer.deliveryAddress
   );
   const [invoiceDate, setInvoiceDate] = useState(new Date());
   const [shippingTerm, setShippingTerm] = useState('DELIVERED AT PLACE');
   const [lines, setLines] = useState(transfer.lines);
   const [notes, setNotes] = useState(`Made in China
In terms of VAT: 0% Export`);

   const prevInvoicesModal = useModals();

   const [fetchGeneratedDocument] = useLazyQuery(FETCH_GENERATED_DOCUMENT);
   const [generateDocument] = useMutation(GENERATE_DOCUMENT, {
      onError: (error) => Alert('error', error.message),
   });
   const [exportingCommercialInvoice, setExportingCommercialInvoice] =
      useState(false);

   const totalQty = lines.reduce((res, line) => res + parseInt(line.qty), 0);
   const totalCost = lines
      .reduce((res, line) => res + line.qty * line.unitCost, 0)
      .toFixed(2);
   const sayTotalString = sayTotal(totalCost);

   const totalNetWeight = lines
      .reduce((res, line) => (res += parseFloat(line.netWeight)), 0)
      .toFixed(2);
   const totalGrossWeight = lines
      .reduce((res, line) => (res += parseFloat(line.grossWeight)), 0)
      .toFixed(2);

   function onSelectPrevCI(id) {
      prevInvoicesModal.hide();
      fetchGeneratedDocument({ variables: { id } })
         .then((res) => {
            const prevData = JSON.parse(res.data.generatedDocument.data);
            setLines((lines) =>
               lines.map((line) => {
                  const found = prevData.lines.find((i) => i.id === line.id);
                  if (found)
                     return {
                        ...line,
                        unitCost: found.unitCost,
                        hsCode: found.hsCode,
                     };
                  return line;
               })
            );
         })
         .catch((error) => Alert('error', error.message));
   }

   function ciHandler() {
      setExportingCommercialInvoice(true);
      generateDocument({
         variables: {
            docType: 'commercial_invoice',
            name: transfer.name,
            data: JSON.stringify(
               {
                  name,
                  consignee,
                  deliveryAddress,
                  invoiceDate: formatDate(invoiceDate, 'YYYY-MM-DD'),
                  shippingTerm,
                  lines: lines.map((line) => ({
                     ...line,
                     totalCost: (line.unitCost * line.qty).toFixed(2),
                  })),
                  totalQty,
                  totalNetWeight,
                  totalGrossWeight,
                  totalCost,
                  sayTotalString,
                  notes,
               },
               '',
               4
            ),
         },
      })
         .then((res) => {
            return http.get(
               `${process.env.REACT_APP_SERVER_BASE_URL}generated-document/${res.data.generateDocument.document.id}/?pdf=1`,
               { responseType: 'blob', withCredentials: true }
            );
         })
         .then((res) => {
            console.log('res', res);
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', transfer.name + '.pdf');
            document.body.appendChild(link);
            link.click();
            setExportingCommercialInvoice(false);
         })
         .catch((e) => {
            Alert('error', e.message);
            setExportingCommercialInvoice(false);
         });
   }

   function onChangeLine(index, key, value) {
      setLines((prev) =>
         prev.map((i, prevIndex) => {
            if (index !== prevIndex) return i;
            return { ...i, [key]: value };
         })
      );
   }

   return (
      <div>
         <div className="flex space-x-8">
            <div className="flex-1 space-y-3">
               <div className="flex items-center space-x-3">
                  <label htmlFor="">Inv No.:</label>
                  <Input
                     value={name}
                     onChange={(e) => setName(e.target.value)}
                  />
               </div>

               <div>
                  <label htmlFor="">Consignee:</label>
                  <Text
                     className="mt-2"
                     value={consignee}
                     onChange={(e) => setConsignee(e.target.value)}
                  />
               </div>

               <div>
                  <label htmlFor="">Delivery Address:</label>
                  <Text
                     className="mt-2"
                     value={deliveryAddress}
                     onChange={(e) => setDeliveryAddress(e.target.value)}
                  />
               </div>
            </div>

            <div className="flex-1 space-y-3">
               <div className="flex items-center space-x-3">
                  <label htmlFor="">Inv Date:</label>
                  <DatePicker
                     value={invoiceDate}
                     onDayChange={setInvoiceDate}
                  />
               </div>

               <div className="flex items-center space-x-3">
                  <label htmlFor="">Shipping Term:</label>
                  <Input
                     value={shippingTerm}
                     onChange={(e) => setShippingTerm(e.target.value)}
                  />
               </div>
            </div>
         </div>

         <div className="mt-6 px-2">
            <Button
               title="Load Data from Previous Generated Commercial Invoice"
               onClick={() =>
                  prevInvoicesModal.present({
                     title: 'Commercial Invoices',
                     children: (
                        <GeneratedDocuments
                           docType="commercial_invoice"
                           onSelect={onSelectPrevCI}
                        />
                     ),
                  })
               }
            />
         </div>

         <div className="relative overflow-auto">
            <table className="mt-6">
               <thead className="text-left whitespace-nowrap">
                  <tr>
                     <th>Product</th>
                     <th className="text-right">HS Code</th>
                     <th className="text-right">Qty</th>
                     <th className="text-right">N.W.(KG)</th>
                     <th className="text-right">G.W.(KG)</th>
                     <th className="text-right">Cost / Unit</th>
                     <th className="text-right">Total Cost</th>
                  </tr>
               </thead>
               <tbody>
                  {lines.map((line, index) => (
                     <tr
                        key={line.id}
                        className="border-b dark:border-gray-700 whitespace-nowrap"
                     >
                        <td>{line.name}</td>
                        <td className="text-right">
                           <Input
                              className="text-right"
                              value={line.hsCode}
                              onChange={(e) =>
                                 onChangeLine(index, 'hsCode', e.target.value)
                              }
                           />
                        </td>
                        <td className="text-right">
                           <Input
                              className="text-right w-20"
                              value={line.qty}
                              onChange={(e) =>
                                 onChangeLine(index, 'qty', e.target.value)
                              }
                           />
                        </td>
                        <td className="text-right">
                           <Input
                              className="text-right w-20"
                              value={line.netWeight}
                              onChange={(e) =>
                                 onChangeLine(
                                    index,
                                    'netWeight',
                                    e.target.value
                                 )
                              }
                           />
                        </td>
                        <td className="text-right">
                           <Input
                              className="text-right w-20"
                              value={line.grossWeight}
                              onChange={(e) =>
                                 onChangeLine(
                                    index,
                                    'grossWeight',
                                    e.target.value
                                 )
                              }
                           />
                        </td>
                        <td className="text-right">
                           <Input
                              className="text-right w-20"
                              value={line.unitCost}
                              onChange={(e) =>
                                 onChangeLine(index, 'unitCost', e.target.value)
                              }
                           />
                        </td>
                        <td className="text-right">
                           {(line.qty * line.unitCost).toFixed(2)}
                        </td>
                     </tr>
                  ))}
               </tbody>
               <tfoot>
                  <tr className="text-right font-semibold text-xl">
                     <td className="text-left p-2 py-4" colSpan={2}>
                        Total
                     </td>
                     <td className="p-2 py-4">{totalQty}</td>
                     <td className="p-2 py-4">{totalNetWeight}</td>
                     <td className="p-2 py-4">{totalGrossWeight}</td>
                     <td className="p-2 py-4"></td>
                     <td className="p-2 py-4">${totalCost}</td>
                  </tr>
                  <tr>
                     <td colSpan={5} className="px-2 text-xl">
                        <label htmlFor="">Say Total US Dollars: </label>{' '}
                        <span className="pl-3 capitalize">
                           {sayTotalString}
                        </span>
                     </td>
                  </tr>
               </tfoot>
            </table>
         </div>

         <div className="mt-8">
            <Text value={notes} onChange={(e) => setNotes(e.target.value)} />
         </div>

         <hr />

         <div className="my-8 pb-16 flex space-x-8">
            <Button
               size="xl"
               title="Download"
               loading={exportingCommercialInvoice}
               disabled={exportingCommercialInvoice}
               onClick={ciHandler}
            />
         </div>
      </div>
   );
};

const FETCH_GENERATED_DOCUMENTS = gql`
   query FETCH_GENERATED_DOCUMENTS($docType: String) {
      allGeneratedDocuments(docType: $docType) {
         id
         name
         docType
         createdAt
         createdBy {
            id
            email
         }
      }
   }
`;

const GeneratedDocuments = ({ docType, onSelect }) => {
   const { loading, data, error } = useQuery(FETCH_GENERATED_DOCUMENTS, {
      variables: { docType },
      fetchPolicy: 'cache-and-network',
   });

   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;

   return (
      <table>
         <tbody>
            {data.allGeneratedDocuments.map((i) => (
               <tr
                  key={i.id}
                  className=" cursor-pointer hover:text-blue-600 hover:dark:text-blue-700"
                  onClick={() => onSelect(i.id)}
               >
                  <td className="border-b dark:border-gray-700">{i.name}</td>
                  <td className="border-b dark:border-gray-700 text-right">
                     {i.createdBy.email}
                  </td>
                  <td className="border-b dark:border-gray-700 text-right">
                     {new Date(i.createdAt).toLocaleString()}
                  </td>
               </tr>
            ))}
         </tbody>
      </table>
   );
};

export default CommercialInvoiceForm;
