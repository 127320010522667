import React from 'react';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import Spinner from 'components/Spinner';
import Errors from 'components/Errors';
import ShipmentErrorComponent from 'components/ShipmentError';

const FETCH_ERROR_CODES = gql`
   query FETCH_ERROR_CODES {
      errorCodes: allErrors {
         id
         code: fullCode
         description
      }
   }
`;

function ErrorCodes() {
   const { loading, error, data } = useQuery(FETCH_ERROR_CODES);
   if (error) return <Errors error={error} />;
   if (loading) return <Spinner />;
   return (
      <div className="m-4 lg:m-6 rounded-lg border border-gray-200 overflow-auto">
         <table className="text-sm bg-white">
            <thead className="bg-gray-200">
               <tr>
                  <th align="left" className="p-4 px-6">
                     Code
                  </th>
                  <th align="left" className="px-6 py-2">
                     Description
                  </th>
               </tr>
            </thead>
            <tbody>
               {data.errorCodes.map((i, index) => (
                  <tr key={index}>
                     <td className="border-b border-gray-100 py-2 px-6 text-pink-500 font-bold">
                        {i.code}
                     </td>
                     <td className="flex items-center border-b border-gray-100 py-2 px-6 text-gray-600">
                        <ShipmentErrorComponent
                           code={i.code}
                           description={i.description}
                        />
                     </td>
                  </tr>
               ))}
            </tbody>
         </table>
      </div>
   );
}

export default ErrorCodes;
