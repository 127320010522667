import React, { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { Button } from '../../../components/base';
import { Alert } from '../../../components/Toast';

const SETUP_MFA = gql`
   mutation SETUP_MFA($email: String!) {
      setupMfa(email: $email) {
         uri
      }
   }
`;

const SetupMfaByCharles = ({ email }) => {
   const [uri, setUri] = useState(null);
   const [setupMfa, { loading }] = useMutation(SETUP_MFA, {
      variables: { email },
      onCompleted: (data) => {
         Alert('success', 'Created Link.');
         setUri(data.setupMfa.uri);
      },
   });
   return (
      <div className="mt-4 bg-red">
         <Button
            title="Setup MFA"
            disabled={loading}
            loading={loading}
            onClick={setupMfa}
         />
         {uri ? <div className="mt-3">{uri}</div> : null}
      </div>
   );
};

export default SetupMfaByCharles;
