import { useContext, useState } from "react";
import { ProductListContext } from "./Form";
import { DatePicker, FileSelector, Select } from "components/Form";
import CharlesButton from "components/charles/base";
import { BsTrash, BsUpload } from "react-icons/bs";
import useFileUploader from "hooks/useFileUploader";
import { Link } from "react-router-dom";

const LabTestStandards = () => {
  const { data, setData } = useContext(ProductListContext);

  function onChangeTestReport(index, key, value) {
    setData((prev) => {
      const updatedTestReports = [...prev.updatedTestReports];
      updatedTestReports[index][key] = value;
      return { ...prev, updatedTestReports };
    });
  }

  function addTestReport() {
    setData((prev) => ({
      ...prev,
      updatedTestReports: [...prev.updatedTestReports, { standardId: null, createdAt: new Date(), fileList: [] }],
    }));
  }

  function deleteHandler(index) {
    setData((prev) => {
      const updatedTestReports = [...prev.updatedTestReports];
      updatedTestReports.splice(index, 1);
      return { ...prev, updatedTestReports };
    });
  }

  return (
    <div className="card">
      <div className="flex justify-between items-center">
        <h5>Lab Test</h5>
        <div className="space-x-4">
          <Link to="doc">DOC Report</Link>
          <Link to="cpc">CPC Report</Link>
        </div>
      </div>
      <div>
        {data.updatedTestReports.map((i, index) => (
          <TestReportView
            key={index}
            testReport={i}
            deleteHandler={() => deleteHandler(index)}
            onChangeTestReport={(key, value) => onChangeTestReport(index, key, value)}
          />
        ))}
      </div>

      <div className="mt-4">
        <CharlesButton onClick={addTestReport}>+ Add Test Report</CharlesButton>
      </div>
    </div>
  );
};

const TestReportView = ({ testReport, deleteHandler, onChangeTestReport }) => {
  const { testStandards } = useContext(ProductListContext);
  const { isUploading, handleFileUpload } = useFileUploader((urls) => {
    onChangeTestReport("fileList", [...testReport.fileList, ...urls]);
  });

  return (
    <div className="relative space-x-6 border-b border-gray-100 py-6 dark:border-gray-700">
      <div className="flex items-center space-x-3">
        <div className="flex items-center space-x-3">
          <CharlesButton danger onClick={deleteHandler}>
            <BsTrash />
          </CharlesButton>
          <label htmlFor="">Standard: </label>
          <Select value={testReport.standardId || "0"} onChange={(e) => onChangeTestReport("standardId", e.target.value)}>
            <option value="0" disabled>
              Select a standard
            </option>
            {testStandards.map((j) => (
              <option key={j.id} value={j.id}>
                {j.name}
              </option>
            ))}
          </Select>
        </div>

        <div className="flex space-x-3 items-center">
          <label htmlFor="">Created at: </label>
          <DatePicker value={testReport.createdAt} onDayChange={(d) => onChangeTestReport("createdAt", d)} />
        </div>

        <FileSelector
          multiple
          className="text-xs"
          uploading={isUploading}
          title={
            <div className="flex items-center space-x-2">
              <BsUpload />
              <span>Upload Files</span>
            </div>
          }
          onChange={(e) => {
            const files = [...e.target.files];
            handleFileUpload(files);
            e.target.value = null;
          }}
        />
      </div>

      {testReport.fileList.length > 0 ? (
        <div className="mt-2 space-y-2">
          {testReport.fileList.map((url, index) => (
            <div key={index} className="flex items-center space-x-2">
              <a href={url} target="_blank" rel="noreferrer">
                {url}
              </a>
              <CharlesButton
                danger
                onClick={() => {
                  onChangeTestReport(
                    "fileList",
                    testReport.fileList.filter((i, prevIndex) => prevIndex !== index)
                  );
                }}
              >
                <BsTrash />
              </CharlesButton>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default LabTestStandards;
