import { useModals } from "ModalProvider";
import ChooseImagesFromOneDriveView from "components/ChooseImagesFromOneDriveView";
import CharlesButton from "components/charles/base";

const NewProductImagesView = ({ images, setImages }) => {
  const modal = useModals();

  function showChooseOneDriveFolderView() {
    modal.present({
      title: "Choose Images",
      subtitle: "From One Drive",
      children: <ChooseImagesFromOneDriveView onSelect={didSelectImages} />,
    });
  }

  function didSelectImages(selectedImages) {
    modal.hide();
    setImages(selectedImages);
  }

  return (
    <div className="space-y-2">
      {images.length > 0 ? (
        <div className="flex">
          {images.map((i) => (
            <img className="mr-2 mb-2 w-20" key={i.id} src={i.url} />
          ))}
        </div>
      ) : null}

      <div className="opacity-70">
        By default, the product line images are used for the product. If you want to use different images for this product, you can choose images from one
        drive.
      </div>

      <div>
        <CharlesButton onClick={showChooseOneDriveFolderView}>Choose Images Fron One Drive</CharlesButton>
      </div>
    </div>
  );
};

export default NewProductImagesView;
