import { useQuery, gql } from '@apollo/client';
import randomColor from 'randomcolor';
import { ArcherContainer, ArcherElement } from 'react-archer';
import Errors from './Errors';
import Spinner from './Spinner';

const FETCH_PRODUCTION = gql`
   query FETCH_PRODUCTION($id: ID!) {
      productionNode(id: $id) {
         id
         name
         category {
            id
            name
            factory {
               id
               name
            }
         }
         descendants {
            id
            name
            category {
               id
               name
               factory {
                  id
                  name
               }
            }
            parent {
               id
               name
            }
         }
      }
   }
`;

const Production = ({ id }) => {
   const { loading, error, data } = useQuery(FETCH_PRODUCTION, {
      variables: { id },
   });

   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;

   const node = data.productionNode;
   const descendants = data.productionNode.descendants;

   const productionCategores = descendants.reduce((prev, node) => {
      const found = prev.find((i) => i.categoryId === node.category.id);
      if (found) return prev;
      return [...prev, { categoryId: node.category.id }];
   }, []);

   const colors = randomColor({
      count: productionCategores.length,
      luminosity: 'dark',
   });

   const categoryColors = productionCategores.map((i, index) => ({
      ...i,
      color: colors[index],
   }));

   const getColor = (categoryId) => {
      let foundCategory = categoryColors.find(
         (i) => i.categoryId === categoryId
      );
      if (foundCategory) return foundCategory.color;
      return '#eee';
   };

   return (
      <ArcherContainer strokeColor="#aaa" strokeWidth={1}>
         <ProductionNode
            node={node}
            descendants={descendants}
            getColor={getColor}
         />
      </ArcherContainer>
   );
};

export const ProductionNode = ({ node, descendants, getColor }) => {
   const children = descendants.filter(
      (i) => i.parent && i.parent.id === node.id
   );
   return (
      <div className="flex items-center space-x-44">
         {children.length > 0 ? (
            <div className="dark:border-gray-700 space-y-6 flex flex-col items-start">
               {children.map((i) => (
                  <ProductionNode
                     key={i.id}
                     node={i}
                     getColor={getColor}
                     descendants={descendants}
                  />
               ))}
            </div>
         ) : null}

         <ArcherElement
            id={node.id}
            relations={
               node.parent
                  ? [
                       {
                          targetId: node.parent ? node.parent.id : null,
                          targetAnchor: 'left',
                          sourceAnchor: 'right',
                       },
                    ]
                  : []
            }
         >
            <div className="bg-gray-100 dark:bg-gray-800 px-6 py-3 rounded-xl">
               <div
                  className="flex items-center space-x-2
               "
               >
                  <div
                     className="w-4 h-4 rounded-full align-middle mr-2 flex-shrink-0"
                     style={{ backgroundColor: getColor(node.category.id) }}
                  ></div>
                  <div>
                     <div className="font-bold">
                        {node.category.factory.name}
                     </div>
                     <div className="mt-1">{node.category.name}</div>
                  </div>
               </div>
            </div>
         </ArcherElement>
      </div>
   );
};

export default Production;
