import Status from "components/Status";
import moment from "moment";
import { Link } from "react-router-dom";
import PlanLinesTable from "./PlanLinesTable";

const AdditionPlanView = ({ plan }) => {
  return (
    <div>
      <div>
        <div className="flex items-center space-x-4">
          <h5>
            <Link to={`/order-scheduler/plans/${plan.id}`}>
              #{plan.id} {plan.name}
            </Link>
          </h5>

          <Status status={plan.state} />
        </div>

        <div className="flex space-x-8 items-center mt-2">
          <div className="flex space-x-4 items-center">
            <label>Start Date: </label>
            <span>{moment(plan.startDate).format("YYYY-MM-DD")}</span>
          </div>
        </div>

        <div className="-mx-2 mt-2">
          <PlanLinesTable plan={plan} />
        </div>
      </div>
    </div>
  );
};

export default AdditionPlanView;
