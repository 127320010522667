import { gql, useQuery } from '@apollo/client';
import { Button, NavigationLink } from 'components/base';
import Errors from 'components/Errors';
import { Input, Select } from 'components/Form';
import Page from 'components/Page';
import Spinner from 'components/Spinner';
import moment from 'moment';
import { BsArrowRight } from 'react-icons/bs';
import { IoIosCheckmarkCircle, IoIosCloseCircle } from 'react-icons/io';
import { NavLink } from 'react-router-dom';

const FETCH_SUPPLIER_PRODUCTION_CATEGORIES = gql`
   query FETCH_SUPPLIER_PRODUCTION_CATEGORIES {
      me {
         supplier {
            id
            productionCategories {
               id
               name
               type
               timeList {
                  qty
                  days
               }
               qtyPerDay

               workOnMonday
               workOnTuesday
               workOnWednesday
               workOnThursday
               workOnFriday
               workOnSaturday
               workOnSunday
               usages {
                  id
                  startDate
                  endDate
                  notes
                  productionPlan {
                     id
                     name
                  }
               }
            }
         }
      }
   }
`;

const Productions = () => {
   const { loading, error, data } = useQuery(
      FETCH_SUPPLIER_PRODUCTION_CATEGORIES
   );

   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;

   const categories = [...data.me.supplier.productionCategories].sort((a, b) =>
      a.type.toLowerCase() > b.type.toLowerCase() ? 1 : -1
   );

   return (
      <Page
         title="生产"
         leftButtons={
            <div className=" space-x-6">
               <NavigationLink to="orders" title="Orders" />
               <NavigationLink to="time" title="Production Time" />
            </div>
         }
      >
         <div className="p-6 grid grid-cols-3 gap-6">
            {categories.map((i) => (
               <ProductionCategory category={i} key={i.id} />
            ))}
         </div>
      </Page>
   );
};

const ProductionCategory = ({ category }) => {
   const computeMethod = { BY_QTY: '按数量', BY_DAY: '按天' };

   return (
      <div className="bg-gray-50 dark:bg-gray-800 p-6 px-8 shadow rounded-2xl space-y-4">
         <h4>{category.name}</h4>

         <div className="flex justify-between space-x-8">
            <div>
               <label>计算方法: </label> {computeMethod[category.type]}
            </div>
            <Button title="修改" />
         </div>

         {category.type === 'BY_QTY' ? (
            <div className="-mx-2">
               <table className="with-line">
                  <thead>
                     <tr>
                        <th>
                           <label>生产数量</label>
                        </th>
                        <th className="text-right">
                           <label>所需天数</label>
                        </th>
                     </tr>
                  </thead>
                  <tbody>
                     {category.timeList.map((i, index) => (
                        <tr key={index}>
                           <td>{i.qty}</td>
                           <td className="text-right">{i.days}</td>
                        </tr>
                     ))}
                  </tbody>
               </table>
            </div>
         ) : (
            <div>产能: {category.qtyPerDay} / 天</div>
         )}

         <div className="-mx-2 border-t border-gray-100 dark:border-gray-700 pt-4">
            <table className="w-auto">
               <thead>
                  <tr className="text-xs">
                     <th>周一</th>
                     <th>周二</th>
                     <th>周三</th>
                     <th>周四</th>
                     <th>周五</th>
                     <th>周六</th>
                     <th>周日</th>
                  </tr>
               </thead>
               <tbody>
                  <tr>
                     <td>
                        {category.workOnMonday ? (
                           <IoIosCheckmarkCircle className="text-green-600" />
                        ) : (
                           <IoIosCloseCircle className="text-red-600" />
                        )}
                     </td>
                     <td>
                        {category.workOnTuesday ? (
                           <IoIosCheckmarkCircle className="text-green-600" />
                        ) : (
                           <IoIosCloseCircle className="text-red-600" />
                        )}
                     </td>
                     <td>
                        {category.workOnWednesday ? (
                           <IoIosCheckmarkCircle className="text-green-600" />
                        ) : (
                           <IoIosCloseCircle className="text-red-600" />
                        )}
                     </td>
                     <td>
                        {category.workOnThursday ? (
                           <IoIosCheckmarkCircle className="text-green-600" />
                        ) : (
                           <IoIosCloseCircle className="text-red-600" />
                        )}
                     </td>
                     <td>
                        {category.workOnFriday ? (
                           <IoIosCheckmarkCircle className="text-green-600" />
                        ) : (
                           <IoIosCloseCircle className="text-red-600" />
                        )}
                     </td>
                     <td>
                        {category.workOnSaturday ? (
                           <IoIosCheckmarkCircle className="text-green-600" />
                        ) : (
                           <IoIosCloseCircle className="text-red-600" />
                        )}
                     </td>
                     <td>
                        {category.workOnSunday ? (
                           <IoIosCheckmarkCircle className="text-green-600" />
                        ) : (
                           <IoIosCloseCircle className="text-red-600" />
                        )}
                     </td>
                  </tr>
               </tbody>
            </table>
         </div>

         {category.usages.length > 0 ? (
            <div className="border-t border-gray-100 dark:border-gray-700 pt-4 -mx-2 text-xs">
               <table className="w-auto with-line">
                  <tbody>
                     {category.usages.map((i) => (
                        <tr key={i.id}>
                           <td>{moment(i.startDate).format('YYYY-MM-DD')}</td>
                           <td>
                              <BsArrowRight />{' '}
                           </td>
                           <td>{moment(i.endDate).format('YYYY-MM-DD')}</td>
                           <td>
                              {i.productionPlan ? i.productionPlan.name : ''}
                           </td>
                           <td>{i.notes}</td>
                        </tr>
                     ))}
                  </tbody>
               </table>
            </div>
         ) : null}
      </div>
   );
};

export default Productions;
