import moment from 'moment/moment';
import PriceWithCurrency from '../PriceWithCurrency';
import { calcSuitablePrice } from '../utils';

const ProductWithoutBOM = ({ product, qty }) => {
   const suitablePrice = calcSuitablePrice(product, qty);

   const prices = product.prices.filter((i) => i.archivedAt === null);

   return (
      <div className="mt-4">
         <table>
            <thead>
               <tr className="bg-gray-100 dark:bg-gray-800 uppercase">
                  <th className=" text-gray-600 border border-gray-200 dark:border-gray-600 px-4">
                     Supplier
                  </th>
                  <th className="text-right text-gray-600 border border-gray-200 dark:border-gray-600 px-4">
                     Qty
                  </th>
                  <th className="text-right text-gray-600 border border-gray-200 dark:border-gray-600 px-4">
                     Price
                  </th>
                  <th className="text-right text-gray-600 border border-gray-200 dark:border-gray-600 px-4 whitespace-nowrap">
                     Supplier Updated
                  </th>
               </tr>
            </thead>
            <tbody>
               {prices.map((price) => {
                  const using = suitablePrice && suitablePrice.id === price.id;
                  return (
                     <tr
                        key={price.id}
                        className={`${using ? '' : 'opacity-30'}`}
                     >
                        <td
                           className={`border border-gray-200 dark:border-gray-600 px-4 text-xs`}
                        >
                           {price.factory ? price.factory.name : '-'}
                        </td>
                        <td className="border border-gray-200 dark:border-gray-600 px-4 text-right">
                           {price.minQty}
                        </td>
                        <td className="border border-gray-200 dark:border-gray-600 px-4 text-right whitespace-nowrap">
                           <PriceWithCurrency price={price} />
                        </td>
                        <td className="border border-gray-200 dark:border-gray-600 px-4 text-right">
                           {moment(price.supplierUpdatedAt).format(
                              'YYYY-MM-DD'
                           )}
                        </td>
                     </tr>
                  );
               })}
            </tbody>
         </table>
      </div>
   );
};

export default ProductWithoutBOM;
