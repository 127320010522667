import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { useParams } from 'react-router-dom';
import Spinner from '../../components/Spinner';
import Errors from '../../components/Errors';
import { Button } from '../../components/base';
import PageContainer from '../../components/PageContainer.js';

const FETCH_PROJECT_DETAIL = gql`
   query FETCH_PROJECT_DETAIL($id: ID!) {
      project(id: $id) {
         id
         name
         versions {
            id
            version
            releasedAt
         }
      }
   }
`;

const ProjectDetail = () => {
   const { id } = useParams();
   const { loading, error, data } = useQuery(FETCH_PROJECT_DETAIL, {
      variables: { id },
   });
   if (loading) return <Spinner text={null} />;
   if (error) return <Errors error={error} />;

   const project = data.project;

   return (
      <PageContainer title={project.name} backTo="/projects">
         <div className="mt-4 px-6 py-3">
            <div>
               <label htmlFor="">Trademark: </label>
               <span>{project.name}</span>
            </div>
            <div className="mt-4">
               <label htmlFor="">
                  Selling Points, features, target market:{' '}
               </label>
               <span>Some content will be displayed here.</span>
            </div>
         </div>
         <div className="p-4 lg:p-6">
            {project.versions.map((version) => (
               <ProjectVersion
                  project={project}
                  version={version}
                  key={version.id}
               />
            ))}
         </div>
      </PageContainer>
   );
};

const Step = ({ title, children }) => (
   <div className="mb-8 border-b border-gray-100 ">
      <div className="py-2 font-bold">{title}</div>
      <div className="py-4">{children}</div>
   </div>
);

const ProjectVersion = ({ version }) => {
   function createProduct() {}

   return (
      <div className="p-6 bg-white rounded-xl border border-gray-200">
         <h4 className="mb-6">Version {version.version}</h4>

         <Step title="Product Design"></Step>

         <Step title="Packaging Design"></Step>

         <Step title="Lab Testing"></Step>

         <Step title="Trial Production">
            <Button title="+ Create Product" onClick={createProduct}></Button>
         </Step>

         <Step title="Soft Launch"></Step>

         <Step title="Big Launch"></Step>

         <Step title="Online Launch"></Step>
      </div>
   );
};

export default ProjectDetail;
