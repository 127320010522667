import { gql, useQuery } from "@apollo/client";
import Errors from "components/Errors";
import Spinner from "components/Spinner";
import React from "react";
import { Link, Route, Routes } from "react-router-dom";
import WarehouseDetail from "./Detail";
import LocationStockHistory from "./LocationStockHistory";

const WarehousesIndex = () => (
  <Routes>
    <Route>
      <Route index element={<Warehouses />} />
      <Route path=":id" element={<WarehouseDetail />} />
      <Route path=":id/history" element={<LocationStockHistory />} />
    </Route>
  </Routes>
);

const FETCH_ODOO_STOCK_LOCATIONS = gql`
  {
    odooStockLocations
  }
`;

function Warehouses() {
  const { loading, error, data } = useQuery(FETCH_ODOO_STOCK_LOCATIONS);

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const locations = JSON.parse(data.odooStockLocations).filter((i) => i.warehouse_id || i.display_name.includes("Physical Locations/Transit"));

  return (
    <div className="p-6">
      <div className="px-1 opacity-80">
        This page is an externtion of Odoo. Because Odoo does not support any report for inventory valuation for a pecific location. The list below shows all
        the locations we have. For each location, we fetch all the inventory items, and read all their moves. From those moves, we can compute the inventory for
        every item everyday. We fetch the item cost from Odoo inventory report for a pecific date.
      </div>

      <div className="card px-6 py-4 mt-4">
        <table>
          <tbody>
            {locations.map((i) => (
              <tr key={i.id} className="border-b border-gray-100">
                <td>
                  <Link to={`/stock/warehouses/${i.id}?name=${i.display_name}`}>
                    {i.warehouse_id ? `[${i.warehouse_id[1]}] ` : null}
                    {i.display_name}
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default WarehousesIndex;
