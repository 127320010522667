import { gql, useQuery } from '@apollo/client';
import Errors from 'components/Errors';
import Spinner from 'components/Spinner';
import ProductionEditor from './ProductionEditor';

const FETCH_DATA_FOR_PRODUCTION_FORM = gql`
   query FETCH_DATA_FOR_PRODUCTION_FORM($id: ID!) {
      productionNode(id: $id) {
         id
         name
         category {
            id
            name
            factory {
               id
               name
            }
         }
         daysToTransfer
         descendants(includeSelf: false) {
            id
            name
            category {
               id
               name
               factory {
                  id
                  name
               }
            }
            parent {
               id
            }
            daysToTransfer
         }
      }
   }
`;

const EditProduction = ({ id, onSave }) => {
   const { loading, error, data } = useQuery(FETCH_DATA_FOR_PRODUCTION_FORM, {
      variables: { id },
   });

   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;

   const initialData = {
      id,
      name: data.productionNode.name,
      category: data.productionNode.category,
      daysToTransfer: data.productionNode.daysToTransfer,
      descendants: data.productionNode.descendants.map((i) => ({
         id: i.id,
         category: i.category,
         parentId: i.parent.id,
         daysToTransfer: i.daysToTransfer,
      })),
   };

   return <ProductionEditor initialData={initialData} onSave={onSave} />;
};

export default EditProduction;
