import writtenNumber from "written-number";

export const snakeCase = (s) =>
  s
    .replace(/\.?([A-Z]+)/g, function (x, y) {
      return "_" + y.toLowerCase();
    })
    .replace(/^_/, "");

export function validateEmail(email) {
  var re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function getNameFromUrl(url) {
  if (url) {
    const decoded = decodeURI(url);
    const arr = decoded.split("/");
    const count = arr.length;
    if (count > 0) {
      return arr[arr.length - 1];
    }
  }
  return null;
}

export const sayTotal = (value) => {
  let s = "";
  const [a, b] = (value + "").split(".");
  if (parseInt(a, 10) > 0) {
    s = writtenNumber(a);
  }
  if (parseInt(b, 10) > 0) {
    s += " and cents " + writtenNumber(b);
  }
  if (s === "") return " - ";
  return s;
};

export function showCurrencySymbol(currency) {
  if (currency === "USD") {
    return "$";
  }
  if (currency === "EUR") {
    return "€";
  }
  if (currency === "GBP") {
    return "£";
  }
  if (currency === "RMB" || currency === "CNY") {
    return "¥";
  }
  return "";
}
