import { useMemo } from 'react';
import { AreaChart, Area } from 'recharts';

const CostChart = ({
   data,
   up,
   width = 80,
   height = 30,
   isAnimationActive = false,
}) => {
   if (data.length === 0) return null;

   return (
      <AreaChart width={width} height={height} data={data}>
         <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
               <stop
                  offset="5%"
                  stopColor={up ? '#EF4444' : '#10B981'}
                  stopOpacity={0.8}
               />
               <stop
                  offset="95%"
                  stopColor={up ? '#EF4444' : '#10B981'}
                  stopOpacity={0}
               />
            </linearGradient>
            <linearGradient id="colorDv" x1="0" y1="0" x2="0" y2="1">
               <stop
                  offset="5%"
                  stopColor={up ? '#10B981' : '#10B981'}
                  stopOpacity={0.8}
               />
               <stop
                  offset="95%"
                  stopColor={up ? '#10B981' : '#10B981'}
                  stopOpacity={0}
               />
            </linearGradient>
         </defs>
         <Area
            isAnimationActive={isAnimationActive}
            dataKey="value"
            fill={up ? 'url(#colorUv)' : 'url(#colorDv)'}
            stroke={up ? '#EF4444' : '#10B981'}
            strokeWidth={2}
            dot={false}
            type="monotone"
         />
      </AreaChart>
   );
};

export default CostChart;
