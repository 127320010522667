import http from 'utils/http';
import { Alert } from 'components/Toast';
import { Input, Select } from 'components/Form';
import { useState } from 'react';
import { Button } from 'components/base';

const ProductBarcode = ({ product }) => {
   const [code, setCode] = useState(product.barCode);
   const [barcodeType, setBarcodeType] = useState('ean13');
   const [loading, setLoading] = useState(false);

   function downloadBarcode() {
      setLoading(true);
      http
         .get(
            `${process.env.REACT_APP_SERVER_BASE_URL}download/barcode/${barcodeType}/${code}`,
            { responseType: 'blob', withCredentials: true }
         )
         .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', code + '.pdf');
            document.body.appendChild(link);
            link.click();
            setLoading(false);
         })
         .catch((e) => {
            Alert('error', e.message);
            setLoading(false);
         });
   }

   return (
      <div className="space-y-4">
         <div className="flex text-lg space-x-3">
            <label htmlFor="">Barcode: </label>
            <Input value={code} onChange={(e) => setCode(e.target.value)} />
         </div>
         <div className="space-x-3">
            <label htmlFor="">Barcode Type: </label>
            <Select
               value={barcodeType}
               onChange={(e) => setBarcodeType(e.target.value)}
            >
               <option value="ean13">EAN13</option>
               <option value="upc">UPC</option>
            </Select>
         </div>

         <hr />

         <div className="mt-8">
            <Button
               loading={loading}
               disabled={loading}
               bold
               size="lg"
               title="Download"
               onClick={downloadBarcode}
            />
         </div>
      </div>
   );
};

export default ProductBarcode;
