import { gql, useQuery } from "@apollo/client";
import XLSX from "xlsx";
import { parseError } from "apollo";
import Errors from "components/Errors";
import SearchBar from "components/SearchBar";
import Spinner from "components/Spinner";
import { useState } from "react";
import { Link, Route, Routes, useSearchParams } from "react-router-dom";
import { searchByProp } from "utils/search";
import ProductStock from "./ProductStock";
import CharlesButton from "components/charles/base";
import { useModals } from "ModalProvider";
import CreateSupplierStockRecord from "./CreateSupplierStockRecord";
import moment from "moment";
import { Select } from "components/Form";
import SupplierUpdateRequestsView from "./SupplierUpdateRequestsView";
import SupplierStockUpdateRequests from "./SupplierStockUpdateRequests";
import { byName } from "utils/sort";

const GET_SUPPLIER_STOCKS = gql`
  query GET_SUPPLIER_STOCKS {
    products(hasSupplierStocks: true) {
      results {
        id
        name
        number
        latestSupplierStock {
          active
          supplier {
            id
            name
          }
          id
          latestQty
          readyDate
          remark
          planingPlanConsumedTotal
        }
      }
    }
  }
`;

const SupplierStocksIndex = () => {
  return (
    <Routes>
      <Route index element={<SupplierStocks />} />
      <Route path=":id" element={<ProductStock />} />
      <Route path="update-requests" element={<SupplierStockUpdateRequests />} />
    </Routes>
  );
};

const SupplierStocks = () => {
  const { loading, error, data } = useQuery(GET_SUPPLIER_STOCKS, {
    fetchPolicy: "network-only",
  });
  const [searchParams, setSearchParams] = useSearchParams({ filterSupplierId: "ALL" });
  const [searchText, setSearchText] = useState("");
  // const [filterSupplierId, setFilterSupplierId] = useState(searchParams.get("filterSupplierId") || "ALL");
  const modal = useModals();

  if (loading) return <Spinner />;
  if (error) return <Errors error={parseError(error)} />;

  const filterSupplierId = searchParams.get("filterSupplierId") || "ALL";
  const products = data.products.results
    .filter((i) => i.latestSupplierStock.active)
    .filter((i) => searchByProp(i, ["name", "number"], searchText) && (filterSupplierId === "ALL" || i.latestSupplierStock.supplier.id === filterSupplierId))
    .sort((a, b) => {
      if (a.latestSupplierStock.supplier.name < b.latestSupplierStock.supplier.name) return -1;
      if (a.latestSupplierStock.supplier.name > b.latestSupplierStock.supplier.name) return 1;
      if (a.number < b.number) return -1;
      if (a.number > b.number) return 1;
      return 0;
    });

  const supplierOptions = data.products.results
    .map((i) => i.latestSupplierStock.supplier)
    .sort(byName)
    .filter((i, index, self) => self.findIndex((s) => s.id === i.id) === index);

  const productsHasNoStock = products.filter((i) => i.latestSupplierStock.latestQty === 0);

  function addHandler() {
    modal.present({
      title: "Create Product Stock",
      children: <CreateSupplierStockRecord complete={modal.hide} />,
    });
  }

  function exportStocks() {
    const supplierName = filterSupplierId === "ALL" ? "All Suppliers" : supplierOptions.find((i) => i.id === filterSupplierId).name;
    const data = [
      ...products.map((i) => ({
        Product: `[${i.number}] ${i.name}`,
        Supplier: i.latestSupplierStock.supplier.name,
        "Planing QTY": i.latestSupplierStock.planingPlanConsumedTotal,
        "Latest QTY": i.latestSupplierStock.latestQty,
        "Total QTY": i.latestSupplierStock.planingPlanConsumedTotal + i.latestSupplierStock.latestQty,
        "Ready Date": moment(i.latestSupplierStock.readyDate).format("YYYY-MM-DD"),
      })),
    ];
    const sheet = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, sheet, "stock");
    const today = moment().format("YYYY-MM-DD");
    XLSX.writeFile(wb, `${supplierName} Stock until ${today}.xlsx`);
  }

  return (
    <div className="flex-1 overflow-auto flex flex-col p-6">
      <div className="p-6 card flex-1 overflow-auto flex flex-col">
        <div className="flex space-x-6 justify-between items-center">
          <Select value={filterSupplierId} onChange={(e) => setSearchParams({ filterSupplierId: e.target.value })}>
            <option value="ALL">All Suppliers</option>
            {supplierOptions.map((i) => (
              <option key={i.id} value={i.id}>
                {i.name}
              </option>
            ))}
          </Select>

          <div className="flex-1">
            <SearchBar className="bg-gray-100" placeholder="Search by product name, number or supplier name." value={searchText} onChange={setSearchText} />
          </div>

          <CharlesButton onClick={exportStocks}>Export</CharlesButton>
          <CharlesButton onClick={addHandler}>+ Product Stock</CharlesButton>
          <SupplierUpdateRequestsView />
        </div>

        <div className="opacity-70 mt-4 leading-relaxed">
          <p>
            This page shows our current supplier stocks. These data does not exist in Odoo. It is a 2nd level of stock we build by ourselves. This stock data
            will be included to compute a production plan in Order Scheduler.
          </p>
          <p>This page only shows the products if their stock is active. You can hide a product by setting the latest stock to inactive in admin.</p>
          <p>The export function will export the filter results, and there will be an extra `Total QTY` column.</p>
        </div>

        <div className="flex-1 overflow-auto relative my-2 -mx-2">
          <table>
            <thead>
              <tr className=" sticky top-0 z-10 bg-white">
                <th>Product</th>
                <th>Supplier</th>
                <th className="text-right">Planing QTY</th>
                <th className="text-right">Latest QTY</th>
                <th className="text-right">Ready Date</th>
                <th className="text-right">Remark</th>
              </tr>
            </thead>
            <tbody>
              {products.map((i) => (
                <Link
                  to={`${i.id}?filterSupplierId=${filterSupplierId}`}
                  key={i.id}
                  className={`table-row font-normal border-b border-gray-100 dark:border-gray-700 hover:bg-blue-50 hover:dark:bg-blue-900 dark:hover:bg-opacity-20
                    ${
                      i.latestSupplierStock.latestQty === 0
                        ? "text-red-600 bg-red-50 dark:text-red-700 dark:bg-red-900 dark:hover:bg-red-800 dark:bg-opacity-20"
                        : ""
                    }
                  `}
                >
                  <td className="font-semibold">
                    [{i.number}] {i.name}
                  </td>
                  <td className="text-gray-800 dark:text-gray-100">{i.latestSupplierStock.supplier.name}</td>
                  <td className="text-right text-gray-800 dark:text-gray-100">{i.latestSupplierStock.planingPlanConsumedTotal}</td>
                  <td className="text-right text-gray-800 dark:text-gray-100">{i.latestSupplierStock.latestQty}</td>
                  <td className="text-right text-gray-800 dark:text-gray-100">{moment(i.latestSupplierStock.readyDate).format("YYYY-MM-DD")}</td>
                  <td className="text-right text-gray-800 dark:text-gray-100">{i.latestSupplierStock.remark}</td>
                </Link>
              ))}
            </tbody>
          </table>
        </div>

        <div className="italic mt-4">
          <span>Total {products.length} products. </span>
          {productsHasNoStock.length > 0 ? <span className="text-red-600">{productsHasNoStock.length} products have no stock.</span> : null}
        </div>
      </div>
    </div>
  );
};

export default SupplierStocksIndex;
