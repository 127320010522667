const { gql } = require("@apollo/client");

// * package & package address

export const FETCH_ALL_PACKAGES = gql`
  query FETCH_ALL_PACKAGES {
    allPackages {
      id
      destination {
        id
        shortName
        addressDetail
      }
      status
      trackingNumber
      method
      createdAt
      images {
        id
        name
        url
      }
      items {
        cc {
          id
          username
          email
        }
      }
      checkItems {
        senders {
          id
          username
          email
        }
        receivers {
          id
          username
          email
        }
      }
    }
  }
`;

export const CREATE_PACKAGE = gql`
  mutation CREATE_PACKAGE($destinationId: Int!, $trackingNumber: String!, $method: String!) {
    createPackage(destinationId: $destinationId, trackingNumber: $trackingNumber, method: $method) {
      package {
        id
        destination {
          id
          shortName
          addressDetail
        }
        status
        trackingNumber
        method
        createdAt
        items {
          id
        }
      }
    }
  }
`;

export const UPDATE_PACKAGE = gql`
  mutation UPDATE_PACKAGE($id: Int!, $destinationId: Int!, $trackingNumber: String!, $method: String!) {
    updatePackage(id: $id, destinationId: $destinationId, trackingNumber: $trackingNumber, method: $method) {
      package {
        id
        destination {
          id
          shortName
          addressDetail
        }
        status
        trackingNumber
        createdAt
      }
    }
  }
`;

export const UPDATE_PACKAGE_CC = gql`
  mutation UPDATE_PACKAGE_CC($id: Int!, $ccs: [Int!]) {
    updatePackageCc(id: $id, ccs: $ccs) {
      package {
        id
        cc {
          id
          username
          email
        }
      }
    }
  }
`;

export const DELETE_PACKAGE = gql`
  mutation DELETE_PACKAGE($id: Int!) {
    deletePackage(id: $id) {
      success
    }
  }
`;

export const FETCH_ALL_PACKAGE_ADDRESSES = gql`
  query FETCH_ALL_PACKAGE_ADDRESSES {
    allPackageAddresses {
      id
      shortName
      addressDetail
    }
  }
`;

export const FETCH_PACKAGE_INFO = gql`
  query FETCH_PACKAGE_INFO($id: Int!) {
    packageInfo(id: $id) {
      id
      status
      destination {
        id
        shortName
        addressDetail
      }
      trackingNumber
      method
      cc {
        id
        username
        email
      }
      createdAt
    }
    allSamples(packageId: $id) {
      id
      name
      quantity
      cc {
        id
        username
        email
      }
      unit
      checkItems {
        id
        content
        sample {
          id
        }
        package {
          id
        }
        senders {
          id
          username
          email
        }
        receivers {
          id
          username
          email
        }
      }
    }
    allPackageImagesByPkgId(packageId: $id) {
      id
      name
      url
    }
    allInternalUsers {
      id
      username
      email
    }
  }
`;

export const FETCH_INTERNAL_USERS = gql`
  query FETCH_INTERNAL_USERS {
    allInternalUsers {
      id
      username
      email
    }
  }
`;

export const FETCH_COURIER_MATCH_INFO = gql`
  query FETCH_COURIER_MATCH_INFO($trackingNumber: String!, $method: String!) {
    courierMatchInfo(trackingNumber: $trackingNumber, method: $method) {
      isMatched
      isEnough
    }
  }
`;

// * sample

export const CREATE_SAMPLE = gql`
  mutation CREATE_SAMPLE($name: String!, $quantity: Int!, $packageId: Int!, $unit: String, $messages:[SampleMessageInputType]) {
    createSample(name: $name, quantity: $quantity, packageId: $packageId, unit: $unit, messages: $messages) {
      sample {
        id
        name
        quantity
        unit
      }
    }
  }
`;

export const DELETE_SAMPLE = gql`
  mutation DELETE_SAMPLE($sampleId: Int!) {
    deleteSample(sampleId: $sampleId) {
      success
    }
  }
`;

export const UPDATE_SAMPLE = gql`
  mutation UPDATE_SAMPLE($sampleId: Int!, $name: String!, $quantity: Int!, $unit: String,  $messages:[SampleMessageInputType]) {
    updateSample(sampleId: $sampleId, name: $name, quantity: $quantity, unit: $unit, messages: $messages) {
      sample {
        id
        name
        quantity
        unit
      }
    }
  }
`;

// * package image

export const CREATE_PACKAGE_IMAGE = gql`
  mutation CREATE_PACKAGE_IMAGE($url: String!, $packageId: Int!, $name: String) {
  createPackageImage(url: $url, packageId: $packageId, name: $name) {
      packageImage {
      id
      name
      url
    }
  }
}
`;

export const DELETE_PACKAGE_IMAGE = gql`
  mutation DELETE_PACKAGE_IMAGE($imageId: Int!) {
  deletePackageImage(imageId: $imageId) {
    success
  }
}
`;

// * check item

export const FETCH_ALL_CHECK_ITEMS_BY_SAMPLE_ID = gql`
  query FETCH_ALL_CHECK_ITEMS_BY_SAMPLE_ID($sampleId: Int!) {
    allCheckItemsBySampleId(sampleId: $sampleId) {
      id
      content
      sample {
        id
      }
      package {
        id
      }
      senders {
        id
        username
        email
      }
      receivers {
        id
        username
        email
      }
    }
  }
`;


export const CREATE_CHECK_ITEM = gql`
  mutation CREATE_CHECK_ITEM($content: String, $sampleId: Int!, $packageId: Int!, $isChecked: Boolean, $senders: [Int!], $receivers: [Int!]) {
    createCheckItem(content: $content, sampleId: $sampleId, packageId: $packageId, isChecked: $isChecked, senders: $senders, receivers: $receivers) {
      checkItem {
        id
        content
        sample {
          id
        }
        package {
          id
        }
        senders {
          id
          username
          email
        }
        receivers {
          id
          username
          email
        }
      }
    }
  }
`;

export const DELETE_CHECK_ITEM = gql`
  mutation DELETE_CHECK_ITEM($itemId: Int!) {
    deleteCheckItem(itemId: $itemId) {
      success
    }
  }
`;

export const UPDATE_CHECK_ITEM = gql`
  mutation UPDATE_CHECK_ITEM($itemId: Int!, $content: String!, $isChecked: Boolean) {
    updateCheckItem(itemId: $itemId, content: $content, isChecked: $isChecked) {
      checkItem {
        id
        content
        sample {
          id
        }
        package {
          id
        }
      }
    }
  }
`;
