import { gql, useQuery } from "@apollo/client";
import Errors from "components/Errors";
import Spinner from "components/Spinner";
import { useModals } from "ModalProvider";
import { useContext } from "react";
import { QCAnalyticsContext } from ".";
import FactoryDetail from "./FactoryDetail";
import ProductDetail from "./ProductDetail";

const FETCH_QC_ANALYTICS_TOP = gql`
  query FETCH_QC_ANALYTICS_TOP($start: DateTime!, $end: DateTime!) {
    analyticsQcTopFailures(startDate: $start, endDate: $end)
  }
`;

const Overview = () => {
  const { start, end } = useContext(QCAnalyticsContext);
  const { loading, error, data } = useQuery(FETCH_QC_ANALYTICS_TOP, {
    variables: { start, end },
  });

  const { present } = useModals();

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const { products, factories } = JSON.parse(data.analyticsQcTopFailures);

  return (
    <div className="flex-1 px-6 pb-6 overflow-auto">
      <div className="flex space-x-8 h-full overflow-auto">
        <div className="h-full overflow-auto bg-white dark:bg-gray-800 rounded-xl p-6 w-96">
          <h3>Products</h3>
          <table className="mt-4">
            <tbody>
              {products.map((product) => (
                <tr
                  key={product.id}
                  className=" hover:text-blue-600 cursor-pointer"
                  onClick={() =>
                    present({
                      title: `Issues ${(product.failRate * 100).toFixed(2)}% from ${start.toLocaleDateString()} to ${end.toLocaleDateString()}`,
                      maxWidth: "max-w-7xl",
                      children: <ProductDetail id={product.id} start={start} end={end} />,
                    })
                  }
                >
                  <td className="px-0">{product.name}</td>
                  <td className="pl-8 pr-0 text-right">{(product.failRate * 100).toFixed(2)} %</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="h-full overflow-auto bg-white dark:bg-gray-800 rounded-xl p-6 w-96">
          <h3>Factories</h3>
          <table className="mt-4">
            <tbody>
              {factories.map((factory) => (
                <tr
                  key={factory.id}
                  className=" hover:text-blue-600 cursor-pointer"
                  onClick={() =>
                    present({
                      title: `Issues ${(factory.failRate * 100).toFixed(2)}% from ${start.toLocaleDateString()} to ${end.toLocaleDateString()}`,
                      maxWidth: "max-w-7xl",
                      children: <FactoryDetail id={factory.id} start={start} end={end} />,
                    })
                  }
                >
                  <td className="px-0">{factory.name}</td>
                  <td className="pl-8 pr-0 text-right">{(factory.failRate * 100).toFixed(2)} %</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Overview;
