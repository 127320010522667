import React from 'react';
import { useQuery } from '@apollo/client';
import { Select } from '../../../../components/Form';
import { InlineSpinner } from '../../../../components/Spinner.js';
import Errors from '../../../../components/Errors';
import { FETCH_ALL_ERROR_CATEGORIES } from '../../graphql';

function ErrorSelector({ value, onSelect }) {
   const { loading, error, data } = useQuery(FETCH_ALL_ERROR_CATEGORIES);
   if (error) return <Errors error={error} />;
   if (loading)
      return (
         <div>
            <InlineSpinner text={null} size={18} />
         </div>
      );
   return (
      <div className="flex-1 relative">
         <Select
            className="w-full"
            textAlignLast="left"
            name="error"
            value={value}
            onChange={(event) =>
               onSelect(
                  data.allErrorCategories
                     .flatMap((i) => i.errors)
                     .find((e) => e.id === event.target.value)
               )
            }
         >
            <option value={0} disabled>
               -----
            </option>
            {data.allErrorCategories.map((category) => (
               <optgroup label={category.name} key={category.id}>
                  {category.errors.map((e) => (
                     <option key={e.id} value={e.id}>
                        [ {e.code} ] - {e.description}
                        {e.tags.length > 0
                           ? ` ${e.tags.map((t) => `<${t.name}>`).join(' ')}`
                           : ''}
                     </option>
                  ))}
               </optgroup>
            ))}
         </Select>
      </div>
   );
}

export default ErrorSelector;
