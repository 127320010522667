import { RiExchangeCnyLine } from "react-icons/ri";

const ComponentTitle = ({ product, onClick }) => {
  const hasPriceInRMB = product.prices.filter((i) => i.currency === "RMB").length > 0;

  return (
    <div onClick={onClick ? onClick : null} className="flex items-center cursor-pointer hover:text-blue-600">
      {hasPriceInRMB ? <RiExchangeCnyLine className="mr-2 text-teal-600 flex-shrink-0" size={18} /> : null}

      <div>
        [{product.number}] {product.name}
      </div>
    </div>
  );
};

export default ComponentTitle;
