import gql from 'graphql-tag';

export const FETCH_ME = gql`
   query FETCH_ME {
      me {
         id
         email
         firstName
         lastName
         isSuperuser
         isStaff
         groups {
            id
            name
         }
      }
   }
`;
