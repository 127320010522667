import { AppContext } from "App";
import ProductImages from "components/ProductImages";
import { useModals } from "ModalProvider";
import React, { useContext } from "react";
import productProps from "./productProps";
import PreviewProductImages from "../PreviewProductImages";
import { gql, useQuery } from "@apollo/client";
import Errors from "components/Errors";
import Spinner from "components/Spinner";
import { Button } from "components/base";
import { RiBarcodeFill } from "react-icons/ri";

const FETCH_PRODUCTS = gql`
  query FETCH_PRODUCTS($productAreas: [ID!], $productLines: [ID!]) {
    products(productLines: $productLines, productAreas: $productAreas, isActive: true, prefetchForProductsPage: true) {
      results {
        id
        name
        productLine {
          id
          name
          age
          hsCode
          hsCodeForEu
          hsCodeForUs
          materials {
            id
            material {
              id
              name
            }
          }
          children {
            id
            name
            age
            materials {
              id
              material {
                id
                name
              }
            }
          }
        }
        number
        shape
        hsCode
        ballDiameter
        cubeX
        cubeY
        cubeZ
        cylindricalDiameter
        cylindricalHeight
        flatX
        flatY
        multiSizes
        itemWeight
        grossWeight
        moq
        packing
        innerBoxX
        innerBoxY
        innerBoxZ
        barCode
        innerBoxBarcodeEan
        innerBoxBarcodeUpc
        innerCartonBarcodeEan
        innerCartonBarcodeUpc
        outerCartonBarcodeEan
        outerCartonBarcodeUpc
        quantityPerDisplayBox
        displayBoxX
        displayBoxY
        displayBoxZ
        innerCartonX
        innerCartonY
        innerCartonZ
        outerCartonX
        outerCartonY
        outerCartonZ
        ctnNetWeight
        ctnGrossWeight
        outerCartonCbm
        quantityPerCarton
        pantone: finalPantone
        warnings: finalWarnings {
          id
          name
        }
        labTestStandards: finalLabTestStandards {
          id
          name
        }
      }
    }
  }
`;

function ProductTable({ selectedFields = [], selectedProducts = [], selectProducts, selectField, productAreas, productLines, previewBarcode }) {
  const { loading, error, data } = useQuery(FETCH_PRODUCTS, {
    variables: { productAreas, productLines },
  });
  const imageModal = useModals();

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const products = data.products.results;

  return (
    <table className="text-xs md:text-sm h-full">
      <thead>
        <tr>
          <th
            className={`p-4 text-left font-bold border border-t-0 sticky left-0 top-0 z-20 whitespace-nowrap bg-gray-100 border-gray-200 dark:bg-gray-700 dark:border-gray-800 dark:text-gray-300
                        ${selectProducts ? "cursor-pointer hover:bg-gray-300" : ""}
                        `}
            onClick={() => selectProducts(products)}
          >
            Products
          </th>
          {products.map((product) => (
            <th
              className={`${
                selectedProducts.filter((p) => p.id === product.id).length > 0 ? "bg-gray-300" : ""
              } sticky top-0 p-4 whitespace-nowrap border border-t-0 bg-gray-100 border-gray-200  dark:bg-gray-700 dark:border-gray-800 dark:text-gray-300 z-10
                        ${selectProducts ? "cursor-pointer hover:bg-gray-300 dark:hover:bg-gray-800" : ""}
                        `}
              key={product.id}
              onClick={(_) => {
                if (selectProducts) selectProducts([product]);
              }}
            >
              {product.name}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {productProps.map((field, index) => (
          <tr key={index}>
            <td
              className={`px-4 py-2 border border-l-0 bg-gray-100 border-gray-200  dark:bg-gray-700 dark:border-gray-700 dark:text-gray-300 font-bold md:whitespace-nowrap
                           ${selectField ? "cursor-pointer hover:bg-gray-300 dark:hover:bg-gray-800" : ""}
                           ${selectedFields.filter((f) => f.field === field.field).length > 0 ? "bg-gray-300 dark:hover:bg-gray-800" : ""}
                           sticky left-0 md:whitespace-nowrap z-10 `}
              onClick={() => {
                if (selectField) selectField(field);
              }}
            >
              {field.displayName}
            </td>
            {products.map((product, productIndex) => (
              <td
                className={`px-4 py-2 border border-gray-200 dark:border-gray-700
                        ${field.field === "hsCode" ? "md:whitespace-nowrap" : "md:whitespace-pre-wrap"}
                        ${
                          selectedFields.filter((f) => f.field === field.field).length > 0 && selectedProducts.filter((p) => p.id === product.id).length > 0
                            ? "bg-blue-300 dark:bg-blue-700"
                            : ""
                        }
                        ${selectedFields.filter((f) => f.field === field.field).length > 0 ? "bg-blue-200 dark:bg-blue-700" : ""}
                        ${
                          selectedProducts.filter((p) => p.id === product.id).length > 0 ? "bg-blue-200 dark:bg-blue-700" : ""
                        } bg-white dark:bg-gray-800 dark:text-gray-300 z-0`}
                key={productIndex + "-" + product.id}
              >
                <ProductCell
                  previewBarcode={() => previewBarcode(product)}
                  product={product}
                  property={field}
                  showImages={() =>
                    imageModal.present({
                      title: product.name + " Images",
                      fullscreen: true,
                      children: <PreviewProductImages id={product.id} />,
                    })
                  }
                />
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

const ProductCell = ({ product, property, showImages, previewBarcode }) => {
  const {
    settings: { metric },
  } = useContext(AppContext);

  switch (property.field) {
    case "name":
      return product.name;
    case "images":
      return <ProductImages id={product.id} showImages={showImages} />;
    case "barCode":
      return (
        <div className="flex items-center space-x-2">
          <span>{product.barCode}</span>
          <Button leftIcon={<RiBarcodeFill size={21} />} onClick={previewBarcode} />
        </div>
      );
    default:
      return property.value ? property.value(product, metric) : product[property.field];
  }
};

export default ProductTable;
