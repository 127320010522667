import React, { useContext } from "react";
import { formatDate } from "react-day-picker/moment";
import { useQuery, gql } from "@apollo/client";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import { DatePicker, Input, Select } from "components/Form";
import InputSelect from "components/InputSelect";
import ShipmentContext from "../ShipmentContext";
import expeditorsIcon from "assets/expeditors-icon.png";
import { containers } from "components/ContainerizationView";
import ExpeditorInfo from "./ExpeditorInfo";

const transportationOptions = ["SEA", "SHIP", "AIR", "TRAIN", "COURIER", "SEA & AIR"];

const FETCH_ALL_FORWARDERS = gql`
  query FETCH_ALL_FORWARDERS {
    allForwarders {
      id
      name
    }
    allPorts {
      id
      name
      nameEn
    }
  }
`;

const LogisticsPlan = ({ shippingTerms }) => {
  const {
    state: {
      forwarder,
      forwarderName,
      shippingTerm,
      requestCargoReadyDate,
      shipContainer,
      countryOfOrigin,
      etd,
      eta,
      apiEta,
      newEtd,
      newEta,
      loadingPort,
      portOfDischarge,
      portOfDestination,
      shipBy,
      blNumber,
      containerNumber,
      vv,
    },
    dispatch,
  } = useContext(ShipmentContext);
  const { loading, error, data } = useQuery(FETCH_ALL_FORWARDERS);

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  return (
    <div className="p-4 lg:p-6 rounded-lg bg-white border dark:bg-gray-900 dark:border-gray-700 dark:text-gray-300 space-y-4">
      <div className="flex items-center">
        <label className="mr-3">Country of Origin: </label>
        <Input value={countryOfOrigin} onChange={(e) => dispatch({ type: "changeShipment", payload: { countryOfOrigin: e.target.value } })} />
      </div>

      <div className="flex items-center">
        <label className="mr-3">Request Cargo Ready Date: </label>
        <DatePicker
          format="YYYY-MM-DD"
          value={requestCargoReadyDate ? new Date(requestCargoReadyDate * 1000) : ""}
          onDayChange={(selectedDay) =>
            dispatch({
              type: "changeShipment",
              payload: {
                requestCargoReadyDate: selectedDay ? selectedDay.getTime() / 1000 : null,
              },
            })
          }
        />
      </div>

      <div className="flex items-baseline">
        <label className="mr-3">Shipping Term: </label>
        <Select
          value={shippingTerm}
          onChange={(e) =>
            dispatch({
              type: "changeShipment",
              payload: { shippingTerm: e.target.value },
            })
          }
        >
          <option value="">--- Select ---</option>
          {shippingTerms.map((term, index) => (
            <option key={index} value={term}>
              {term}
            </option>
          ))}
        </Select>
      </div>

      <div className="flex items-center">
        <label className="mr-3">Forwarder: </label>
        <InputSelect
          className="w-full"
          value={forwarderName}
          options={data.allForwarders.map((i) => ({ name: i.name }))}
          onChange={(forwarderName) => dispatch({ type: "changeShipment", payload: { forwarderName } })}
          onSelect={(option) => dispatch({ type: "changeShipment", payload: { forwarderName: option.name } })}
        />
      </div>

      <div className="flex space-x-8">
        <div className="flex items-center">
          <label className="mr-3">Ship by: </label>
          <Select
            value={shipBy}
            onChange={(e) =>
              dispatch({
                type: "changeShipment",
                payload: { shipBy: e.target.value },
              })
            }
          >
            <option value="">--- Select ---</option>
            {transportationOptions.map((i, index) => (
              <option key={index} value={i}>
                {i}
              </option>
            ))}
          </Select>
        </div>

        {(shipBy === "SEA" || shipBy === "TRAIN") && (
          <div>
            <label className="mr-3">Container: </label>
            <Select
              value={shipContainer}
              onChange={(e) =>
                dispatch({
                  type: "changeShipment",
                  payload: { shipContainer: e.target.value },
                })
              }
            >
              <option value="">--- Select a Container ---</option>
              <option value="LCL">LCL</option>
              {containers.map((container, index) => (
                <option key={index} value={container.type}>
                  {container.type}
                </option>
              ))}
            </Select>
          </div>
        )}
      </div>

      <div className="flex items-center space-x-8">
        <div className="flex items-center">
          <label className="mr-3">Port of Loading: </label>
          <InputSelect
            inputClasses="px-4"
            value={loadingPort}
            options={data.allPorts.map((i) => ({ name: i.nameEn }))}
            onChange={(loadingPort) =>
              dispatch({
                type: "changeShipment",
                payload: { loadingPort },
              })
            }
            onSelect={(option) =>
              dispatch({
                type: "changeShipment",
                payload: { loadingPort: option.name },
              })
            }
          />
        </div>

        <div className="flex items-center">
          <label className="mr-3">Port of Discharge: </label>
          <InputSelect
            inputClasses="px-4"
            value={portOfDischarge}
            options={data.allPorts.map((i) => ({ name: i.nameEn }))}
            onChange={(portOfDischarge) =>
              dispatch({
                type: "changeShipment",
                payload: { portOfDischarge },
              })
            }
            onSelect={(option) =>
              dispatch({
                type: "changeShipment",
                payload: { portOfDischarge: option.name },
              })
            }
          />
        </div>

        <div className="flex items-center">
          <label className="mr-3">Port of Destination: </label>
          <InputSelect
            inputClasses="px-4"
            value={portOfDestination}
            options={data.allPorts.map((i) => ({ name: i.nameEn }))}
            onChange={(portOfDestination) =>
              dispatch({
                type: "changeShipment",
                payload: { portOfDestination },
              })
            }
            onSelect={(option) =>
              dispatch({
                type: "changeShipment",
                payload: { portOfDestination: option.name },
              })
            }
          />
        </div>
      </div>

      <div className="flex space-x-8">
        <div className="flex items-center">
          <label className="mr-3">ETD: </label>
          {newEtd ? (
            <div className="flex items-center">
              <span className="mr-2 line-through">{formatDate(new Date(etd * 1000), "YYYY-MM-DD")}</span>
              <span className="mr-2">→</span>
              <span className="mr-2">{formatDate(new Date(newEtd * 1000), "YYYY-MM-DD")}</span>
              <span className="text-xs text-gray-600">new ETD was set in errors, you can not change the original ETA now.</span>
            </div>
          ) : (
            <DatePicker
              format="YYYY-MM-DD"
              value={etd ? new Date(etd * 1000) : ""}
              disabled={newEtd ? true : false}
              onDayChange={(selectedDay) => {
                const etd = selectedDay ? selectedDay.getTime() / 1000 : null;
                dispatch({
                  type: "changeShipment",
                  payload: { etd },
                });
              }}
            />
          )}
        </div>

        <div className="flex items-center">
          <label className="mr-3">ETA: </label>
          <div>
            {newEta ? (
              <div className="flex items-center">
                <span className="mr-2 line-through">{formatDate(new Date(eta * 1000), "YYYY-MM-DD")}</span>
                <span className="mr-2">→</span>
                <span className="mr-2"> {formatDate(new Date(newEta * 1000), "YYYY-MM-DD")}</span>
                <span className="text-xs text-gray-600">new ETA was set in errors, you can not change the original ETA now.</span>
              </div>
            ) : (
              <DatePicker
                format="YYYY-MM-DD"
                value={eta ? new Date(eta * 1000) : ""}
                onDayChange={(selectedDay) => {
                  const eta = selectedDay ? selectedDay.getTime() / 1000 : null;
                  dispatch({
                    type: "changeShipment",
                    payload: { eta },
                  });
                }}
              />
            )}
          </div>
        </div>
      </div>

      {apiEta ? (
        <div className="flex items-center">
          <label className="mr-3">Expeditors ETA: </label>
          <div className="flex items-center space-x-2">
            <img className="w-4" src={expeditorsIcon} />
            <span>{apiEta}</span>
          </div>
        </div>
      ) : null}

      <div className="flex space-x-8">
        <div className="flex space-x-3 items-center">
          <label>BL Number: </label>
          <Input
            className="text-center"
            type="text"
            value={blNumber}
            onChange={(e) =>
              dispatch({
                type: "changeShipment",
                payload: { blNumber: e.target.value },
              })
            }
          />
          {forwarder && forwarder.name === "Expeditors" && blNumber ? <ExpeditorInfo blNumber={blNumber} /> : null}
        </div>

        <div className="flex space-x-3 items-center">
          <label>Container Number: </label>
          <Input
            type="text"
            value={containerNumber}
            onChange={(e) =>
              dispatch({
                type: "changeShipment",
                payload: { containerNumber: e.target.value },
              })
            }
          />
        </div>
      </div>

      <div className="flex items-center space-x-3">
        <label>V/V: </label>
        <Input
          className="w-full"
          type="text"
          value={vv}
          onChange={(e) =>
            dispatch({
              type: "changeShipment",
              payload: { vv: e.target.value },
            })
          }
        />
      </div>
    </div>
  );
};

export default LogisticsPlan;
