import { gql, useQuery } from "@apollo/client";
import { Select } from "./Form";

const FETCH_ODOO_WAREHOUSES = gql`
  query FETCH_ODOO_WAREHOUSES {
    odooWarehouses
  }
`;

const OdooWarehouseSelector = ({ value, onSelect, filterFunction, ...rest }) => {
  const { loading, error, data } = useQuery(FETCH_ODOO_WAREHOUSES);

  const odooWarehouses = data ? JSON.parse(data.odooWarehouses).filter((i) => (filterFunction ? filterFunction(i) : i)) : [];

  return (
    <Select value={value || "0"} onChange={(e) => onSelect(e.target.value)} {...rest}>
      {loading ? (
        <option value="0" disabled>
          loading...
        </option>
      ) : error ? (
        <option value="0" disabled>
          {error.message}
        </option>
      ) : (
        <>
          <option value="0" disabled>
            --- Select ---
          </option>
          {odooWarehouses.map((i, index) => (
            <option key={index} value={i.id}>
              {i.name}
            </option>
          ))}
        </>
      )}
    </Select>
  );
};

export default OdooWarehouseSelector;
