import { gql, useQuery } from "@apollo/client";
import { parseError } from "apollo";
import CustomTooltip from "components/CustomTooltip";
import Errors from "components/Errors";
import Spinner from "components/Spinner";
import moment from "moment";
import { Link } from "react-router-dom";
import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { randomColors } from "utils/colors";

const FETCH_PRODUCT_PRODUCTION_HISTORY = gql`
  query FETCH_PRODUCT_PRODUCTION_HISTORY($productId: ID!, $startDate: DateTime!, $endDate: DateTime!) {
    productProductionTimeHistory(productId: $productId, startDate: $startDate, endDate: $endDate) {
      date
      days
      plans {
        id
        name
        startDate
        readyDate
        wisCustomer {
          id
          name
        }
      }
    }
  }
`;

const ProductProductionHistoryView = ({ product, startDate, endDate }) => {
  const { loading, error, data } = useQuery(FETCH_PRODUCT_PRODUCTION_HISTORY, {
    variables: { productId: product.id, startDate, endDate },
  });

  const color = randomColors(1)[0];

  if (loading) return <Spinner />;
  if (error) return <Errors error={parseError(error)} />;

  const plans = data.productProductionTimeHistory.flatMap((i) => i.plans);

  return (
    <div className="pb-6">
      <div>
        <ResponsiveContainer width="100%" aspect={5 / 2}>
          <LineChart data={data.productProductionTimeHistory} margin={{ top: 0, left: -30, right: 0, bottom: 0 }}>
            <Line type="monotone" dataKey="days" stroke={color} dot={null} />
            <Tooltip content={CustomTooltip} />
            <XAxis dataKey="date" />
            <YAxis dataKey="days" />
          </LineChart>
        </ResponsiveContainer>
      </div>

      <div className="opacity-70 text-xs">
        This chart shows the production days in each handled plan for the selected product over the selected date range. The first date in X axis is the first
        plan start date while the last date is the latest plan. The Y axis shows the max production days for plans in that date. Even not each date has a plan,
        the chart shows the days in the date range. If the date has no plan, the days is 0, but in the chart is use the prev date's days to show the line.
      </div>

      <hr />

      <div>
        <h5>Plans</h5>

        <div className="mt-4 -mx-2">
          <table>
            <tbody>
              {plans.map((plan) => (
                <tr key={plan.id} className="py-2 border-b">
                  <td>
                    <Link to={`/order-scheduler/plans/${plan.id}`} target="_blank" className="space-x-2">
                      <span>
                        {plan.name} {plan.wisCustomer ? ` - ${plan.wisCustomer.name}` : null}
                      </span>
                    </Link>
                  </td>
                  <td className="text-right">
                    {moment(plan.startDate).format("YYYY-MM-DD")} ~ {moment(plan.readyDate).format("YYYY-MM-DD")}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ProductProductionHistoryView;
