const { useQuery, gql } = require('@apollo/client');

const FETCH_ODOO_PRODUCT_PRICE_LIST = gql`
   query FETCH_ODOO_PRODUCT_PRICE_LIST {
      odooPriceLists
   }
`;

const useOdooProductPriceList = () => {
   const { loading, error, data } = useQuery(FETCH_ODOO_PRODUCT_PRICE_LIST);

   const priceLists = data ? JSON.parse(data.odooPriceLists) : null;

   return { loading, error, priceLists };
};

export default useOdooProductPriceList;
