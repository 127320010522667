import { gql } from "@apollo/client";

export const CONFIRM_PRODUCTION_FORM = gql`
  mutation CONFIRM_PRODUCTION_FORM($id: ID!, $confirmType: String!) {
    confirmProductionForm(id: $id, confirmType: $confirmType) {
      productionForm {
        id
        acceptedAt
        rejectedAt
      }
    }
  }
`;
