import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { Field } from "components/Form";
import { Alert } from "components/Toast";
import { FRAGMENT_SPECIAL_ITEM } from "../../graphql";
import { CubeSizeInput } from "components/ItemSizeInput";
import CharlesButton from "components/charles/base";
import { parseError } from "apollo";

const SAVE_SPECIAL_ITEM = gql`
  mutation SAVE_SPECIAL_ITEM($id: ID, $simpleFields: ProductSimpleFields) {
    saveProduct(id: $id, simpleFields: $simpleFields) {
      product {
        ...specialItem
      }
    }
  }
  ${FRAGMENT_SPECIAL_ITEM}
`;

const emptyProduct = {
  id: null,
  name: "",
  number: "",
  hsCode: "",
  outerCartonX: 0,
  outerCartonY: 0,
  outerCartonZ: 0,
  quantityPerCarton: 0,
  ctnNetWeight: 0,
  ctnGrossWeight: 0,
  outerCartonCbm: 0,
  images: [],
  newImages: [],
  customsName: "",
  customsBrand: "",
  customsUsage: "",
  customsValue: 0,
  customsSize: "",
  customsMaterials: "",
};

function SpecialItemForm({ initialProduct, onSaveProduct, id }) {
  const [product, setProduct] = useState(emptyProduct);
  const [saveProduct, saveProductRes] = useMutation(SAVE_SPECIAL_ITEM, {
    onCompleted: (data) => {
      Alert("success", "Product saved");
      onSaveProduct(data.saveProduct.product);
    },
    onError: (error) => Alert("error", parseError(error)),
  });

  useEffect(() => {
    if (initialProduct) {
      setProduct({
        name: initialProduct.name,
        number: initialProduct.number,
        hsCode: initialProduct.hsCode,
        outerCartonX: initialProduct.outerCartonX,
        outerCartonY: initialProduct.outerCartonY,
        outerCartonZ: initialProduct.outerCartonZ,
        quantityPerCarton: initialProduct.quantityPerCarton,
        ctnNetWeight: initialProduct.ctnNetWeight,
        ctnGrossWeight: initialProduct.ctnGrossWeight,
        customsName: initialProduct.customsName,
        customsBrand: initialProduct.customsBrand,
        customsUsage: initialProduct.customsUsage,
        customsValue: initialProduct.customsValue,
        customsSize: initialProduct.customsSize,
        customsMaterials: initialProduct.customsMaterials,
      });
    }
  }, [initialProduct]);

  const outerCartonCbm = (product) =>
    ((parseFloat(product.outerCartonX) + 0.5) * (parseFloat(product.outerCartonY) + 0.5) * (parseFloat(product.outerCartonZ) + 0.5)) / (100 * 100 * 100);

  const variables = {
    id,
    simpleFields: {
      productType: "special",
      name: product.name,
      number: product.number,
      hsCode: product.hsCode,
      outerCartonX: product.outerCartonX ? product.outerCartonX : null,
      outerCartonY: product.outerCartonY ? product.outerCartonY : null,
      outerCartonZ: product.outerCartonZ ? product.outerCartonZ : null,
      quantityPerCarton: product.quantityPerCarton || 0,
      ctnNetWeight: product.ctnNetWeight || 0,
      ctnGrossWeight: product.ctnGrossWeight || 0,
      customsName: product.customsName,
      customsBrand: product.customsBrand,
      customsUsage: product.customsUsage,
      customsValue: product.customsValue || 0,
      customsSize: product.customsSize,
      customsMaterials: product.customsMaterials,
    },
  };

  return (
    <div className="space-y-8">
      <section className="space-y-4">
        <div className="flex space-x-8">
          <Field label="Name(英文)" value={product.name} onChange={(name) => setProduct({ ...product, name })} />
          <Field label="Number" value={product.number} onChange={(number) => setProduct({ ...product, number })} />
          <Field label="HS Code" value={product.hsCode} onChange={(hsCode) => setProduct({ ...product, hsCode })} />
        </div>

        <div className="flex space-x-8">
          <Field label="Qty / CTN" value={product.quantityPerCarton} onChange={(quantityPerCarton) => setProduct({ ...product, quantityPerCarton })} />

          <Field label="CTN N.W." value={product.ctnNetWeight} onChange={(ctnNetWeight) => setProduct({ ...product, ctnNetWeight })} suffix="kg" />

          <Field label="CTN G.W." value={product.ctnGrossWeight} onChange={(ctnGrossWeight) => setProduct({ ...product, ctnGrossWeight })} suffix="kg" />
        </div>

        <div className="flex items-center space-x-4">
          <div className="w-44">
            <label>Outer Carton Size: </label>
            <div className="text-xs text-gray-600">Size 长(x) x 宽(z) x 高(y):</div>
          </div>
          <CubeSizeInput
            x={product.outerCartonX}
            y={product.outerCartonY}
            z={product.outerCartonZ}
            unit="cm"
            onChange={({ x, y, z }) => setProduct((prev) => ({ ...prev, outerCartonX: x, outerCartonY: y, outerCartonZ: z }))}
          />

          <div>
            <div>
              <label>CBM: </label> {outerCartonCbm(product) ? outerCartonCbm(product).toFixed(4) : "0"} m³
            </div>
            <div className="text-xs text-gray-600">((x + 0.5) * (y + 0.5) * (z + 0.5)) / (100 * 100 * 100)</div>
          </div>
        </div>
      </section>

      <hr />

      <section className="space-y-4">
        <h3>Customs Info</h3>

        <div className="flex space-x-4">
          <Field label="Name" value={product.customsName} onChange={(customsName) => setProduct({ ...product, customsName })} />
          <Field label="Brand" value={product.customsBrand} onChange={(customsBrand) => setProduct({ ...product, customsBrand })} />

          <Field label="Usage" value={product.customsUsage} onChange={(customsUsage) => setProduct({ ...product, customsUsage })} />
          <Field label="Value" value={product.customsValue} onChange={(customsValue) => setProduct({ ...product, customsValue })} />
        </div>

        <Field label="Size" value={product.customsSize} onChange={(customsSize) => setProduct({ ...product, customsSize })} />

        <Field label="Material" value={product.customsMaterials} onChange={(customsMaterials) => setProduct({ ...product, customsMaterials })} />
      </section>

      <hr />

      <div className="flex mt-4 lg:mt-8">
        <CharlesButton loading={saveProductRes.loading} onClick={() => saveProduct({ variables })}>
          Save
        </CharlesButton>
      </div>
    </div>
  );
}

export default SpecialItemForm;
