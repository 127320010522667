import React from 'react';
import ActionAnalytics from './ActionAnalytics';
import OhsOverview from './OhsOverview';
import PageViewAnalytics from './PageViewAnalytics';
import ActionsOverview from './ActionsOverview';

function BizAnalyticsOverview() {
   return (
      <div className="py-6">
         <ActionAnalytics />
         <div className="mt-8">
            <OhsOverview />
         </div>
         <div className="mt-8">
            <PageViewAnalytics />
         </div>
         <div className="mt-8">
            <ActionsOverview />
         </div>
      </div>
   );
}

export default BizAnalyticsOverview;
