import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/client';
import Spinner from '../../../../components/Spinner';
import Errors from '../../../../components/Errors';
import { Button } from '../../../../components/base';
import BackButton from '../../../../components/BackButton';
import { NavigationBar } from '../../../../components/PageContainer';
import { Alert } from '../../../../components/Toast';
import track, { actions } from '../../../../utils/track';

const FETCH_PRODUCTLINE = gql`
   query FETCH_PRODUCTLINE($slug: String!) {
      allVideos {
         id
         name
         poster
         previewUri
      }
      productLine(slug: $slug) {
         id
         slug
         videos {
            id
            name
            poster
            previewUri
            files {
               id
               uri
            }
         }
      }
   }
`;

const UPDATE_PRODUCT_LINE_PROMO_MATERIALS = gql`
   mutation UPDATE_PRODUCT_LINE_PROMO_MATERIALS($id: ID!, $videos: [ID]) {
      updateProductLinePromoMaterials(id: $id, videos: $videos) {
         productLine {
            id
            name
            videos {
               id
               name
               poster
               previewUri
               files {
                  id
                  uri
               }
            }
         }
      }
   }
`;

function ProductLineAsscociateVideos() {
   const { slug } = useParams();
   const { loading, error, data } = useQuery(FETCH_PRODUCTLINE, {
      variables: { slug },
   });

   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;
   const { productLine, allVideos } = data;

   return (
      <ProductLineAsscociateVideosForm
         productLine={productLine}
         allVideos={allVideos}
      />
   );
}

function ProductLineAsscociateVideosForm({ productLine, allVideos }) {
   const [videos, setVideos] = useState(productLine.videos);

   const [
      updateProductLinePromoMaterials,
      updateProductLinePromoMaterialsRes,
   ] = useMutation(UPDATE_PRODUCT_LINE_PROMO_MATERIALS, {
      onCompleted: (res) => {
         Alert('success', 'Updated.');
      },
      onError: (err) => {
         Alert('error', err.message);
      },
   });

   function clickProductVideo(video) {
      setVideos((prev) => prev.filter((i) => i.id !== video.id));
   }

   function clickLibVideo(video) {
      setVideos((prev) => [video, ...prev]);
   }

   return (
      <div className="flex-1 flex flex-col h-full">
         <NavigationBar
            barStyle="light"
            title="Associate Promo Videos"
            backButton={
               <BackButton
                  color="dark"
                  to={`/products/collection-view/product-line/${productLine.slug}`}
               />
            }
            rightButtons={
               <Button
                  loading={updateProductLinePromoMaterialsRes.LazyLoad}
                  disabled={updateProductLinePromoMaterialsRes.loading}
                  title={
                     updateProductLinePromoMaterialsRes.loading
                        ? 'Saving...'
                        : 'Save'
                  }
                  onClick={() => {
                     updateProductLinePromoMaterials({
                        variables: {
                           id: productLine.id,
                           videos: videos.map((i) => i.id),
                        },
                     });
                     track(
                        actions.product.associateVideos.name,
                        productLine.name
                     );
                  }}
               ></Button>
            }
         />
         <div className="flex-1 flex overflow-auto">
            <div className="flex-1 flex content-start flex-wrap h-full overflow-auto">
               {videos.map((video, index) => (
                  <div
                     key={index}
                     onClick={() => clickProductVideo(video)}
                     className="cursor-pointer w-64 h-48 bg-gray-200 bg-center bg-cover"
                     style={{ backgroundImage: `url(${video.poster})` }}
                  ></div>
               ))}
            </div>
            <div className="flex-1 flex flex-wrap h-full overflow-auto">
               {allVideos
                  .filter((i) => videos.findIndex((j) => j.id === i.id) === -1)
                  .map((video, index) => (
                     <div
                        key={index}
                        onClick={() => clickLibVideo(video)}
                        className="cursor-pointer w-64 h-48 bg-gray-200 bg-center bg-cover"
                        style={{ backgroundImage: `url(${video.poster})` }}
                     ></div>
                  ))}
            </div>
         </div>
      </div>
   );
}

export default ProductLineAsscociateVideos;
