import { Link } from "react-router-dom";

const InboundShipmentsView = ({ shipments }) => {
  return (
    <div>
      <div className="-mx-2">
        <table>
          <thead>
            <tr>
              <th>Shipment</th>
              <th>Order Reference</th>
              <th className="text-right">ETA + 7 days</th>
              <th className="text-right">QTY</th>
            </tr>
          </thead>
          <tbody>
            {shipments.map((shipment, index) => (
              <tr key={index} className="border-y">
                <td>
                  <Link to={`/shipment/shipments/${shipment.id}`} target="_blank">
                    {shipment.invoiceNumber}
                  </Link>
                </td>
                <td>{shipment.po}</td>
                <td className="text-right">{shipment.eta}</td>
                <td className="text-right">{shipment.qty}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default InboundShipmentsView;
