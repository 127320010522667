const accountsToCompute = ["3", "4051", "4054"];

const InvoiceMarginDetailView = ({ invoice }) => {
  console.log("invoice", invoice);

  const linesToCompute = invoice.lines.filter((line) => accountsToCompute.some((account) => line.account_name.startsWith(account)));

  const totalDebit = linesToCompute.reduce((acc, line) => acc + line.debit, 0);
  const totalCredit = linesToCompute.reduce((acc, line) => acc + line.credit, 0);

  return (
    <div className="-mx-2 text-sm">
      <table>
        <thead>
          <tr>
            <th>Account</th>
            <th className="text-right">Debit</th>
            <th className="text-right">Credit</th>
          </tr>
        </thead>
        <tbody>
          {invoice.lines.map((line, index) => {
            const isAccountToCompute = accountsToCompute.some((account) => line.account_name.startsWith(account));
            return (
              <tr key={index} className={`border-y dark:border-gray-700 ${isAccountToCompute ? "" : "opacity-30"}`}>
                <td>{line.account_name}</td>
                <td className="text-right">{line.debit}</td>
                <td className="text-right">{line.credit}</td>
              </tr>
            );
          })}
        </tbody>
        <tfoot className="font-semibold">
          <tr className="border-y dark:border-gray-700">
            <td>Total</td>
            <td className="text-right">{totalDebit.toFixed(2)}</td>
            <td className="text-right">{totalCredit.toFixed(2)}</td>
          </tr>
          <tr className="border-y dark:border-gray-700">
            <td>Margin</td>
            <td colSpan="2" className="text-right">
              {invoice.margin.toFixed(2)}%
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default InvoiceMarginDetailView;
