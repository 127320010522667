import { gql, useQuery } from "@apollo/client";
import Errors from "components/Errors";
import { Select, Switcher } from "components/Form";
import { isCustomerRelated } from "components/ShipmentError";
import Spinner from "components/Spinner";
import React, { useState } from "react";
import { BiUserCircle } from "react-icons/bi";
import { FaExternalLinkAlt } from "react-icons/fa";
import { Link } from "react-router-dom";

const FETCH_SHIPMENT_ERRORS = gql`
  query FETCH_SHIPMENT_ERRORS($byPeriod: String!) {
    shipmentErrors2023(byPeriod: $byPeriod)
  }
`;

const ShipmentErrorsView = () => {
  const [period, setPeriod] = useState("quarter");
  const [showDetails, setShowDetails] = useState(true);
  const { loading, error, data } = useQuery(FETCH_SHIPMENT_ERRORS, {
    variables: {
      byPeriod: period,
    },
  });

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const shipmentErrors = JSON.parse(data.shipmentErrors2023);

  return (
    <div className="card">
      <div className="flex justify-between items-baseline">
        <h4>Shipment Errors</h4>
        <div className="flex items-center space-x-6">
          <Select value={period} onChange={(e) => setPeriod(e.target.value)}>
            <option value="quarter">by Quarter</option>
            <option value="year">by Year</option>
          </Select>
          <div className="flex items-center space-x-2">
            <label htmlFor="">Show detail: </label>
            <Switcher isOn={showDetails} onChange={() => setShowDetails(!showDetails)} />
          </div>
        </div>
      </div>
      <div className="-mx-2 mt-2">
        <table className="text-sm">
          <tbody>
            {shipmentErrors.map((i, index) => (
              <React.Fragment key={index}>
                <tr>
                  <td colSpan={4}>
                    <h5>
                      {i.period} - {i.error_rates}%
                    </h5>
                    <div className="opacity-70">
                      {i.shipments_with_errors_count} shipments with a total of {i.errors_count} errors out of {i.period_shipments_count} shipments delivered to port in this period.
                    </div>
                  </td>
                </tr>

                {i.errors.map((error, index) => (
                  <tr key={index} className="border-y dark:border-gray-700">
                    <td className="font-bold text-pink-500 align-baseline">{error.code}</td>
                    <td className="align-baseline w-full">
                      <div className="flex items-center space-x-1">
                        {isCustomerRelated(error.code) && <BiUserCircle className=" text-orange-600" size={18} />}
                        <div className="opacity-70">{error.description}</div>
                      </div>
                      {showDetails && (
                        <div className="divide-y border-t mt-4 text-xs">
                          {error.shipment_errors.map(({ shipment, products, factories, delay_days, notes }, index) => (
                            <div key={index} className="py-2 space-y-1">
                              <div className="flex space-x-4 items-center">
                                <Link to={`/shipment/shipments/${shipment.id}`} target="_blank" className="flex items-center space-x-1">
                                  <span>
                                    #{shipment.id} {shipment.invoice_number}
                                  </span>
                                  <span>
                                    <FaExternalLinkAlt size={9} />
                                  </span>
                                </Link>
                                {delay_days > 0 && <div>{delay_days} days delayed</div>}
                              </div>
                              {products.length > 0 && (
                                <div>
                                  <span>Products: </span>
                                  {products.map((x) => x.number).join(", ")}
                                </div>
                              )}

                              {factories.length > 0 && (
                                <div>
                                  <span>Factories: </span>
                                  {factories.map((x) => x.name).join(", ")}
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      )}
                    </td>
                    <td className="whitespace-nowrap text-lg align-baseline text-right">&times; {error.error_count}</td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ShipmentErrorsView;
