import { gql, useMutation } from "@apollo/client";
import { Button } from "components/base";
import CharlesButton from "components/charles/base";
import { DatePicker, FileSelector, Input, Text } from "components/Form";
import OdooPurchaseOrderPicker from "components/OdooPurchaseOrderPicker";
import Page from "components/Page";
import { Alert } from "components/Toast";
import { GENERATE_DOCUMENT } from "graphql/mutations";
import { useModals } from "ModalProvider";
import moment from "moment";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import InvoiceForm from "../invoices/Form";
import DeleteOrderView from "./DeleteOrderView";
import OdooPurchaseOrderView from "./OdooPurchaseOrderView";
import SupplierSelector from "./SupplierSelector";
import http from "utils/http";
import OdooPurchaseOrderDetailView from "./OdooPurchaseOrderDetailView";
import { MdOutlineCancel } from "react-icons/md";
import QiniuUploader from "components/QiniuUploader";

const SAVE_SUB_SUPPLIER_ORDER = gql`
  mutation SAVE_SUB_SUPPLIER_ORDER(
    $id: ID!
    $productionSupplierId: ID
    $orderDate: DateTime
    $estimateDeliveryDate: DateTime
    $paymentTerms: String
    $deliveryAddress: String
    $bankName: String
    $bankAccountNumber: String
    $bankAccountUsername: String
    $remark: String
    $imgUrl: String
  ) {
    saveSubSupplierOrder(
      id: $id
      productionSupplierId: $productionSupplierId
      orderDate: $orderDate
      estimateDeliveryDate: $estimateDeliveryDate
      paymentTerms: $paymentTerms
      bankName: $bankName
      bankAccountNumber: $bankAccountNumber
      bankAccountUsername: $bankAccountUsername
      deliveryAddress: $deliveryAddress
      remark: $remark
      imgUrl: $imgUrl
    ) {
      order {
        id
        odooPoId
        odooPoName
        odooPoOrigin
        orderDate
        paymentTerms
        estimateDeliveryDate
        deliveryAddress
        bankName
        bankAccountNumber
        bankAccountUsername
        remark
        imgUrl
        productionSupplier {
          id
          name
        }
      }
    }
  }
`;

const UPDATE_ODOO_PO = gql`
  mutation UPDATE_ODOO_PO($id: ID!, $odooPoId: Int, $odooPoName: String, $odooPoOrigin: String, $lines: [SubSupplierOrderLineInputType!]) {
    saveSubSupplierOrder(id: $id, odooPoId: $odooPoId, odooPoName: $odooPoName, odooPoOrigin: $odooPoOrigin, lines: $lines) {
      order {
        id
        odooPoId
        odooPoName
        odooPoOrigin
        odooPurchaseOrder
        lines {
          id
          name
          qty
          unitPrice
          totalPrice
        }
      }
    }
  }
`;

const CREATE_INVOICE_FOR_ORDER = gql`
  mutation($orderId: ID) {
    createSubSupplierInvoice(orderId: $orderId) {
      invoice {
        id
        number
        invoiceDate
        dueDate
        currency
        total
        order {
          id
          productionSupplier {
            id
            name
          }
          odooPoName
        }
        lines {
          id
          name
          unitPrice
          qty
        }
        paymentLines {
          id
          payment {
            id
            status
          }
        }
        remark
      }
    }
  }
`;

const RemarkImage = ({ image, setImage }) => (
  <div>
    {image.url ? (
      <div className="relative">
        <a href={image.url} target="_blank" title="Open origin image" rel="noopener noreferrer">
          <img src={image.url} />
        </a>
        <MdOutlineCancel
          className="text-3xl absolute top-2 right-2 cursor-pointer text-red-600 hover:text-red-700 active:text-red-800"
          onClick={() => {
            console.log('img', image);
            setImage(null);
          }} />
      </div>
    ) : (
      <QiniuUploader file={image.file} filename={image.name} onUploaded={(uploadedUrl) => setImage({
        url: uploadedUrl,
        name: image.name,
        file: image.file,
      })} />
    )}
  </div>
);

const SubSupplierOrderForm = ({ originalOrder, refetch }) => {
  const modal = useModals();
  const odooPoDetailModal = useModals();
  const navigate = useNavigate();
  const [order, setOrder] = useState({
    ...originalOrder,
    estimateDeliveryDate: originalOrder.estimateDeliveryDate ? new Date(originalOrder.estimateDeliveryDate) : null,
    orderDate: originalOrder.orderDate ? new Date(originalOrder.orderDate) : null,
  });
  const [saveOrder, saveOrderRes] = useMutation(SAVE_SUB_SUPPLIER_ORDER, {
    onCompleted() {
      Alert("success", "Order Saved.");
    },
    onError(error) {
      Alert("error", error.message);
    },
    refetchQueries: ["FETCH_SUB_SUPPLIER_ORDERS"],
  });
  const [generateDocument] = useMutation(GENERATE_DOCUMENT);
  const [downloadingPdf, setDownloadingPdf] = useState(false);
  const [remarkImage, setRemarkImage] = useState({
    url: originalOrder.imgUrl,
    name: originalOrder.imgUrl,
    file: null,
  } || null);

  async function downloadPdf() {
    setDownloadingPdf(true);
    try {
      const documentRes = await generateDocument({
        variables: {
          name: originalOrder.odooPoName + ".pdf",
          docType: "sub_supplier_order",
          data: JSON.stringify({ orderId: originalOrder.id }, "", 2),
        },
      });
      const res = await http.get(`${process.env.REACT_APP_SERVER_BASE_URL}generated-document/${documentRes.data.generateDocument.document.id}/?pdf=1`, {
        responseType: "blob",
        withCredentials: true,
      });
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", originalOrder.odooPoName + ".pdf");
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      Alert("error", e.message);
    }

    setDownloadingPdf(false);
  }

  const [createInvoice, createInvoiceRes] = useMutation(CREATE_INVOICE_FOR_ORDER, {
    variables: { orderId: originalOrder.id },
    onCompleted(res) {
      const invoice = res.createSubSupplierInvoice.invoice;
      modal.present({
        title: "Create Invoice",
        children: (
          <div>
            <InvoiceForm
              originalInvoice={invoice}
              onSaved={() => {
                modal.hide();
                refetch();
              }}
            />
          </div>
        ),
      });
    },
    onError(error) {
      Alert("error", error.message);
    },
  });

  function doSaveOrder() {
    saveOrder({
      variables: {
        id: order.id,
        productionSupplierId: order.productionSupplierId,
        orderDate: order.orderDate,
        estimateDeliveryDate: order.estimateDeliveryDate,
        paymentTerms: order.paymentTerms,
        bankName: order.bankName,
        bankAccountNumber: order.bankAccountNumber,
        bankAccountUsername: order.bankAccountUsername,
        deliveryAddress: order.deliveryAddress,
        remark: order.remark,
        imgUrl: remarkImage?.url || "",
      },
    });
  }

  const [updateOdooPO, updateOdooPORes] = useMutation(UPDATE_ODOO_PO, {
    onCompleted() {
      Alert("success", "Order Saved.");
    },
    onError(error) {
      Alert("error", error.message);
    },
  });

  function chooseOdooPurchaseOrder() {
    modal.present({
      title: "Choose Odoo Purchase Order",
      maxWidth: "max-w-5xl",
      center: false,
      children: <OdooPurchaseOrderPicker onSelect={onSelectPurchaseOrder} />,
    });
  }

  function onSelectPurchaseOrder(po) {
    odooPoDetailModal.present({
      title: "Choose Products",
      subtitle: po.name,
      maxWidth: "max-w-6xl",
      children: <OdooPurchaseOrderDetailView supplierId={order.productionSupplierId} po={po} onSelect={(lines) => onSelectPurchaseOrderAndLines(po, lines)} />,
    });
  }

  function onSelectPurchaseOrderAndLines(po, lines) {
    odooPoDetailModal.hide();
    modal.hide();
    const cleanLines = lines.map((i) => {
      let newLine = { ...i };
      delete newLine.odooQty;
      return newLine;
    });
    updateOdooPO({
      variables: {
        id: originalOrder.id,
        odooPoId: po.id,
        odooPoName: po.name,
        odooPoOrigin: po.origin,
        lines: cleanLines,
      },
    });
  }

  function tryDeleteOrder() {
    modal.present({
      title: "Delete Order",
      center: true,
      maxWidth: "max-w-xl",
      children: <DeleteOrderView order={order} onDeleted={() => navigate("/sub-supplier/orders/")} hide={modal.hide} />,
    });
  }

  return (
    <Page
      backTo={"/sub-supplier/orders"}
      title={originalOrder.odooPoName ? `PO ${originalOrder.odooPoName}` : "Order"}
      rightButtons={
        <div>
          <CharlesButton loading={downloadingPdf} onClick={downloadPdf}>
            Download PDF
          </CharlesButton>
        </div>
      }
    >
      <div className="p-6 grid grid-cols-12 gap-6">
        <div className="col-span-6 space-y-6">
          <div className="card px-8 py-6 space-y-4">
            <h5>Production</h5>

            <div>
              <label className="pr-4">Partner: </label>
              <SupplierSelector
                value={order.productionSupplierId}
                onChange={(supplier) => {
                  setOrder((prev) => ({
                    ...prev,
                    productionSupplierId: supplier ? supplier.id : null,
                    paymentTerms: supplier ? supplier.preferPaymentTerms : "",
                    bankName: supplier ? supplier.bankName : "",
                    bankAccountNumber: supplier ? supplier.bankAccountNumber : "",
                    bankAccountUsername: supplier ? supplier.bankAccountUsername : "",
                    deliveryAddress: supplier ? supplier.fullAddressCn : "",
                  }));
                }}
              />
            </div>

            <div className="flex space-x-8">
              <div className="flex items-center">
                <label className="pr-2">Order Date:</label>
                <DatePicker value={order.orderDate} onDayChange={(orderDate) => setOrder((prev) => ({ ...prev, orderDate }))} />
              </div>

              <div className="flex items-center">
                <label className="pr-2">Estimate Devliery Date:</label>
                <DatePicker
                  value={order.estimateDeliveryDate}
                  onDayChange={(estimateDeliveryDate) =>
                    setOrder((prev) => ({
                      ...prev,
                      estimateDeliveryDate,
                    }))
                  }
                />
              </div>
            </div>

            <div className="flex items-center">
              <label className="pr-2">Payment Terms:</label>
              <Input
                className="flex-1"
                value={order.paymentTerms}
                onChange={(e) =>
                  setOrder((prev) => ({
                    ...prev,
                    paymentTerms: e.target.value,
                  }))
                }
              />
            </div>

            <div className="flex items-center">
              <label className="pr-2">Bank Name:</label>
              <Input
                className="flex-1"
                value={order.bankName}
                onChange={(e) =>
                  setOrder((prev) => ({
                    ...prev,
                    bankName: e.target.value,
                  }))
                }
              />
            </div>
            <div className="flex items-center">
              <label className="pr-2">Bank Account Number:</label>
              <Input
                className="flex-1"
                value={order.bankAccountNumber}
                onChange={(e) =>
                  setOrder((prev) => ({
                    ...prev,
                    bankAccountNumber: e.target.value,
                  }))
                }
              />
            </div>
            <div className="flex items-center">
              <label className="pr-2">Bank Account Username:</label>
              <Input
                className="flex-1"
                value={order.bankAccountUsername}
                onChange={(e) =>
                  setOrder((prev) => ({
                    ...prev,
                    bankAccountUsername: e.target.value,
                  }))
                }
              />
            </div>

            <div>
              <label htmlFor="">Delivery Address</label>
              <Text
                className="mt-2"
                value={order.deliveryAddress || ""}
                onChange={(e) =>
                  setOrder((prev) => ({
                    ...prev,
                    deliveryAddress: e.target.value,
                  }))
                }
              />
            </div>

            <div>
              <label htmlFor="">Remark</label>
              <Text
                className="mt-2"
                value={order.remark || ""}
                onChange={(e) =>
                  setOrder((prev) => ({
                    ...prev,
                    remark: e.target.value,
                  }))
                }
              />
            </div>

            <div>
              <label htmlFor="">Picture</label>
              <FileSelector
                className="text-xs my-2"
                accept="image/*"
                title="+ Add Image"
                onChange={(e) => {
                  const files = [...e.target.files];
                  // console.log(files);
                  if (files.length > 0) {
                    const file = files[0];  // only the first file
                    setRemarkImage({
                      url: null,
                      name: `sub-supplier/${file.name}`,
                      file,
                    });
                  }
                  e.target.value = null;  // for re-selecting the same file
                }}
              />
              {remarkImage && <RemarkImage image={remarkImage} setImage={setRemarkImage} />}
            </div>

            <hr />

            <div>
              <Button title="Save" bold size="sm" onClick={doSaveOrder} loading={saveOrderRes.loading} />
            </div>
          </div>

          <div className="card space-y-4">
            <h5>Invoices</h5>
            {originalOrder.invoices.length > 0 ? (
              <div className="mt-2 -mx-2 text-xs">
                <table>
                  <thead>
                    <tr className="">
                      <th>Number</th>
                      <th>Invoice Date</th>
                      <th>Due Date</th>
                      <th className="text-right">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {originalOrder.invoices.map((i) => (
                      <tr key={i.id} className="border-y border-gray-100">
                        <td>
                          <Link to={`/sub-supplier/invoices/${i.id}`}>INV{i.number}</Link>
                        </td>
                        <td className="">{moment(i.invoiceDate).format("YYYY-MM-DD")}</td>
                        <td>{moment(i.dueDate).format("YYYY-MM-DD")}</td>
                        <td className="text-right">
                          {i.currency} {i.total.toFixed(2)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : null}
            <div className="mt-2">
              <Button title="+ Add Invoice" onClick={createInvoice} loading={createInvoiceRes.loading} />
            </div>
          </div>
        </div>

        <div className="col-span-6 space-y-6 flex flex-col">
          <div className="card">
            {originalOrder.productionSupplier ? (
              <div className="space-y-6">
                <div className="flex items-center space-x-3 text-sm">
                  <label htmlFor="">Odoo Purchase Order:</label>
                  <span>{order.odooPoName}</span>
                  <Button size="xs" bold title="Choose Odoo PO" onClick={chooseOdooPurchaseOrder} loading={updateOdooPORes.loading} />
                </div>

                {originalOrder.odooPoId && !updateOdooPORes.loading ? (
                  <>
                    <hr />

                    <div>
                      <OdooPurchaseOrderView
                        order={originalOrder.odooPurchaseOrder ? JSON.parse(originalOrder.odooPurchaseOrder) : null}
                        lines={originalOrder.lines}
                      />
                    </div>
                  </>
                ) : null}
              </div>
            ) : (
              <div>Choose a production partner and save before choosing a Odoo PO.</div>
            )}
          </div>

          <div className="flex-1"></div>

          <div className="flex justify-end">
            <CharlesButton danger onClick={tryDeleteOrder}>
              Delete Order
            </CharlesButton>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default SubSupplierOrderForm;
