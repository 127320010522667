import React from 'react';
import odooIcon from '../assets/odoo-icon.svg';

const OdooProduct = ({ name, productId, code, className = '' }) => (
   <div className={`flex items-center ${className}`}>
      <img
         className="mr-2"
         style={{ height: 16 }}
         src={odooIcon}
         alt="odoo product"
      />
      <div>
         {productId ? `#${productId} ` : null}
         {code ? `[ ${code} ] ` : null} {name}
      </div>
   </div>
);

export default OdooProduct;
