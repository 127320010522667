import { gql, useQuery } from '@apollo/client';
import Errors from 'components/Errors';
import Spinner from 'components/Spinner';
import moment from 'moment';
import { useParams } from 'react-router-dom';

const FETCH_EXPEDITOR_SHIPMENT_DETAIL = gql`
   query FETCH_EXPEDITOR_SHIPMENT_DETAIL($id: String!) {
      expeditorShipment(id: $id)
      expeditorShipmentEvents(id: $id)
   }
`;

const ShipmentDetail = () => {
   const { id } = useParams();
   const { loading, error, data } = useQuery(FETCH_EXPEDITOR_SHIPMENT_DETAIL, {
      variables: { id },
   });

   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;

   const shipment = JSON.parse(data.expeditorShipment);
   const events = JSON.parse(data.expeditorShipmentEvents);

   return (
      <div className="p-6 bg-white flex-1 flex space-x-8">
         <div className="flex-1">
            <table>
               <tbody>
                  <tr>
                     <td>
                        <label htmlFor="">Carrier: </label>
                     </td>
                     <td className="w-full">{shipment.carrier.name}</td>
                  </tr>
                  <tr>
                     <td>
                        <label htmlFor="">Clearance: </label>
                     </td>
                     <td>{shipment.clearance.name}</td>
                  </tr>
                  <tr>
                     <td>
                        <label htmlFor="">Consignee: </label>
                     </td>
                     <td>{shipment.consignee}</td>
                  </tr>
                  <tr>
                     <td>
                        <label htmlFor="">Delivery: </label>
                     </td>
                     <td>{shipment.delivery.name}</td>
                  </tr>
                  <tr>
                     <td>
                        <label htmlFor="">Destination: </label>
                     </td>
                     <td>{shipment.destination.name}</td>
                  </tr>
                  <tr>
                     <td>
                        <label htmlFor="">Freight Terms: </label>
                     </td>
                     <td>{shipment.freightTerms}</td>
                  </tr>
                  <tr>
                     <td>
                        <label htmlFor="">Goods Desc: </label>
                     </td>
                     <td>{shipment.goodsDesc}</td>
                  </tr>
                  <tr>
                     <td>
                        <label htmlFor="">Loading: </label>
                     </td>
                     <td>{shipment.loading.name}</td>
                  </tr>
                  <tr>
                     <td>
                        <label htmlFor="">Origin: </label>
                     </td>
                     <td>{shipment.origin.name}</td>
                  </tr>
                  <tr>
                     <td>
                        <label htmlFor="">Mode: </label>
                     </td>
                     <td>{shipment.mode}</td>
                  </tr>
                  <tr>
                     <td>
                        <label htmlFor="">Quantity Units: </label>
                     </td>
                     <td>{shipment.quantity.units}</td>
                  </tr>
                  <tr>
                     <td>
                        <label htmlFor="">Consignee: </label>
                     </td>
                     <td>{shipment.consignee}</td>
                  </tr>
                  <tr>
                     <td>
                        <label htmlFor="">Security Filing Party: </label>
                     </td>
                     <td>{shipment.securityFilingParty}</td>
                  </tr>
                  <tr>
                     <td>
                        <label htmlFor="">Service Type: </label>
                     </td>
                     <td>{shipment.serviceType}</td>
                  </tr>
                  <tr>
                     <td>
                        <label htmlFor="">Shipper: </label>
                     </td>
                     <td>{shipment.shipper}</td>
                  </tr>
                  <tr>
                     <td>
                        <label htmlFor="">Status: </label>
                     </td>
                     <td>{shipment.status}</td>
                  </tr>
                  <tr>
                     <td>
                        <label htmlFor="">Unlanding: </label>
                     </td>
                     <td>{shipment.unlading.name}</td>
                  </tr>
                  <tr>
                     <td>
                        <label htmlFor="">Vessel: </label>
                     </td>
                     <td>{shipment.vessel}</td>
                  </tr>
                  <tr>
                     <td>
                        <label htmlFor="">Volume: </label>
                     </td>
                     <td>
                        {shipment.volume.value} {shipment.volume.units}
                     </td>
                  </tr>
                  <tr>
                     <td>
                        <label htmlFor="">Weight: </label>
                     </td>
                     <td>
                        {shipment.weight.value} {shipment.weight.units}
                     </td>
                  </tr>
               </tbody>
            </table>
         </div>
         <div className="space-y-6">
            <div className="bg-gray-100 p-6 rounded-2xl">
               <h4>Events</h4>
               <ShipmentEvents events={events} />
            </div>
         </div>
      </div>
   );
};

const ShipmentEvents = ({ events }) => {
   return (
      <div>
         <table className="with-line">
            <tbody>
               {events.map((e, index) => (
                  <tr key={index}>
                     <td>{moment(e.time).format('YYYY-MM-DD hh:mm:ss')}</td>
                     <td>{e.description}</td>
                     <td>{e.remarks}</td>
                  </tr>
               ))}
            </tbody>
         </table>
      </div>
   );
};

export default ShipmentDetail;
