// API 拿到价格？monthly manually update? 每个货代不一样。。

export const containers = [
  { type: "20GP", cbm: 29 },
  { type: "40GP", cbm: 58 },
  { type: "40HQ", cbm: 69 },
  { type: "45HQ", cbm: 78 },
];

function containerize(totalCbm) {
  const biggestContainer = containers.reduce((acc, cur) => (acc.cbm > cur.cbm ? acc : cur));

  let remainingCbm = totalCbm;
  let containersUsed = [];

  while (remainingCbm > 0) {
    const container = containers.find((i) => i.cbm >= remainingCbm);
    if (container) {
      containersUsed.push({ ...container, emptySpace: container.cbm - remainingCbm });
      remainingCbm = 0;
    } else {
      containersUsed.push({ ...biggestContainer, emptySpace: 0 });
      remainingCbm -= biggestContainer.cbm;
    }
  }

  return containersUsed;
}

const ContainerizationView = ({ totalCbm }) => {
  const containers = containerize(totalCbm);

  let summaryText = `Total CBM: ${totalCbm.toFixed(2)}. `;
  if (containers.length === 0) {
    summaryText += "No container needed";
  } else if (containers.length === 1) {
    summaryText += "1 container needed";
  } else {
    summaryText += `${containers.length} containers needed`;
  }

  const emptySpace = containers.reduce((acc, cur) => acc + cur.emptySpace, 0);

  if (emptySpace > 0) summaryText += `, ${emptySpace.toFixed(2)} CBM empty space left, you can add more products to fill up the container with empty space.`;

  return (
    <div>
      <div className="flex flex-wrap">
        {containers.map((i, index) => (
          <ContainerView container={i} key={index} />
        ))}
      </div>
      {emptySpace > 0 ? <div className=" font-semibold">{summaryText}</div> : null}
      <div className="mt-1 opacity-70">This algriothm use as few and small containers as possible.</div>
    </div>
  );
};

const ContainerView = ({ container }) => {
  const fillPercentage = ((container.cbm - container.emptySpace) / container.cbm) * 100;
  const baseWidth = 50;
  const width = baseWidth * (container.cbm / 28);
  return (
    <div className="mr-4 mb-4">
      <div className="w-16 h-24 relative bg-gray-100 border-2  border-gray-500" style={{ width }}>
        <div
          className="w-full bg-green-500 absolute bottom-0 transition-all duration-500 ease-in-out"
          style={{
            height: `${fillPercentage}%`,
          }}
        ></div>
      </div>
      <div className="text-center mt-1">{container.type}</div>
    </div>
  );
};

export default ContainerizationView;
