import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import Zoom from 'react-medium-image-zoom';
import Spinner from '../../components/Spinner';
import Errors from '../../components/Errors';
import BackButton from '../../components/BackButton.js';
import Page from '../../components/PageContainer.js';
import moment from 'moment';
import { NavigationBar } from 'components/base';

const FETCH_TRIP = gql`
   query FETCH_TRIP($id: ID!) {
      trip(id: $id) {
         id
         when
         tips
         report
         files {
            id
            src
         }
         factoryReports {
            id
            factory {
               id
               name
            }
            summary
            files {
               id
               src
            }
         }
      }
   }
`;

function Trip() {
   const { id } = useParams();
   const { loading, error, data } = useQuery(FETCH_TRIP, { variables: { id } });
   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;
   const trip = data.trip;

   return (
      <div>
         <NavigationBar
            title={moment(trip.when).format('YYYY-MM-DD')}
            backTo="/csr/trips"
         />
         <div className="whitespace-pre-wrap leading-normal m-6 text-base px-6 md:px-24 container mx-auto">
            <div className="mt-6">{trip.tips}</div>
            <div className="mt-6">{trip.report}</div>
            <hr />
            {trip.factoryReports.map((r) => (
               <div key={r.id}>
                  <h3>{r.factory.name}</h3>
                  <div className="mt-4">{r.summary}</div>
                  {r.files.length > 0 && (
                     <div className="mt-4 flex flex-wrap">
                        {r.files.map((f, index) => (
                           <div className="mr-4 shadow" key={index}>
                              <Zoom>
                                 <img src={f.src} alt={f.src} width={100} />
                              </Zoom>
                           </div>
                        ))}
                     </div>
                  )}
                  <hr />
               </div>
            ))}
         </div>
      </div>
   );
}

export default Trip;
