import React from "react";
import { useQuery } from "@apollo/client";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import Page from "components/Page";
import CharlesButton from "components/charles/base";
import { FETCH_PROMO_IMAGE_COLLECTIONS } from "./graphql";
import { useModals } from "ModalProvider";
import SyncLogosView from "./SyncLogosView";
import { Select } from "components/Form";
import { useSearchParams } from "react-router-dom";
import LogosGridView from "./LogosGridView";

function LogosPage() {
  const { loading, error, data } = useQuery(FETCH_PROMO_IMAGE_COLLECTIONS);
  const [searchParams, setSearchParams] = useSearchParams();

  let collectionName = searchParams.get("collectionName");

  const modal = useModals();

  function showSync(collectionName) {
    modal.present({
      title: "Sync Logos from One Drive",
      children: <SyncLogosView hide={modal.hide} collectionName={collectionName} />,
      center: true,
      maxWidth: "max-w-3xl",
    });
  }

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  if (!collectionName) collectionName = data.promoImageCollections[data.promoImageCollections.length - 1].name;

  return (
    <Page title="Logos" rightButtons={<CharlesButton onClick={() => showSync(collectionName)}>Sync from OneDrive</CharlesButton>}>
      <div>
        <div className="m-6">
          <div>
            <div>
              <label htmlFor="">Collection: </label>
              <Select
                value={collectionName}
                onChange={(e) =>
                  setSearchParams({
                    collectionName: e.target.value,
                  })
                }
              >
                <option value="0" disabled>
                  Select a collection
                </option>
                {data.promoImageCollections.map((i, index) => (
                  <option key={index} value={i.name}>
                    {i.name}
                  </option>
                ))}
              </Select>
            </div>
          </div>

          <div className="mt-2 opacity-70">
            <p>
              This page list all the logos we shows in biz. These logos are synced from OneDrive(
              <a
                href="https://wabobaadmin.sharepoint.com/sites/Waboba/Shared%20Documents/Forms/AllItems.aspx?FolderCTID=0x012000ED1A44060EECF045B3DF12F1E8DEE39F&id=%2Fsites%2FWaboba%2FShared%20Documents%2Fwis%2Flogos&viewid=2ef7e44d%2D79b9%2D437a%2Dbc6f%2D674c8d62a938"
                target="_blank"
                rel="noreferrer"
              >
                Waboba / Documents / wis / logos
              </a>
              ). You can download the zip file by clicking the download button.
            </p>
            <p>Each folder in logos folder should contains folders for "JPG", "PDF", "PNG". WIS will only sync the files from these 3 folders.</p>
            <p>
              To sync the logos, click the sync button on the right. When wis syncs the logos from OneDrive, it checks the updated datetime and only syncs the
              new logos.
            </p>
            <p>
              We manage the logos by collections in wis. You can select the collection from the dropdown to view the logos. In Biz we will display logos for one
              collection in the logos page. For example, in 2024, we show the logos from the collection "2024 Logos". To change the collection for biz, please
              contact Chärlie.
            </p>
          </div>
        </div>

        {collectionName !== "0" ? <LogosGridView collectionName={collectionName} /> : <div>Please select a collection to view the logos.</div>}
      </div>
    </Page>
  );
}

export default LogosPage;
