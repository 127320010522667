import { useQuery, useLazyQuery } from '@apollo/client';
import { FETCH_ME } from '../query.js';

export function useFetchMe(options = {}) {
  return useQuery(FETCH_ME, { ...options });
}

export function useLazyFetchMe() {
  return useLazyQuery(FETCH_ME, { fetchPolicy: 'network-only' });
}
