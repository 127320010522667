import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ProductList from "./ProductList";
import OdooStock from "./OdooStock";

function OdooIndex() {
  return (
    <Routes>
      <Route path="/">
        <Route index element={<OdooStock />} />
        <Route path="*" element={<OdooStock />} />
        <Route path="search" element={<ProductList />} />
      </Route>
    </Routes>
  );
}

export default OdooIndex;
