import { gql } from "@apollo/client";

export const PRODUCT_BASE_INFO_FRAGMENT = gql`
  fragment productBaseInfo on ProductType {
    id
    name
    number
    shape
    hsCode
    ballDiameter
    cubeX
    cubeY
    cubeZ
    cylindricalDiameter
    cylindricalHeight
    flatX
    flatY
    multiSizes
    itemWeight
    grossWeight
    moq
    packing
    innerBoxX
    innerBoxY
    innerBoxZ
    barCode
    quantityPerDisplayBox
    displayBoxX
    displayBoxY
    displayBoxZ
    innerCartonX
    innerCartonY
    innerCartonZ
    outerCartonX
    outerCartonY
    outerCartonZ
    ctnNetWeight
    ctnGrossWeight
    outerCartonCbm
    quantityPerCarton
  }
`;

export const FETCH_DB = gql`
  query FETCH_DB {
    areas: allProductAreas {
      id
      name
    }
    lines: allProductlines(activeForWis: true) {
      id
      name
      age
      hsCode
      hsCodeForEu
      hsCodeForUs
      pantone
      mainImage(size: "300x300")
      materials {
        id
        material {
          id
          name
        }
      }
      warnings {
        id
        name
        content
      }
      labTestStandards {
        id
        name
      }
      children {
        id
        name
        age
        shape
        hsCode
        hsCodeForEu
        hsCodeForUs
        pantone
        materials {
          id
          material {
            id
            name
          }
        }
        warnings {
          id
          name
          content
        }
        labTestStandards {
          id
          name
        }
      }
    }
    products: productTableViewProducts {
      ...productBaseInfo
      areas {
        id
      }
      productLine {
        id
      }
    }
  }
  ${PRODUCT_BASE_INFO_FRAGMENT}
`;

export const FETCH_PRODUCTS_UPDATED_AT = gql`
  query FETCH_PRODUCTS_UPDATED_AT {
    productsUpdatedAt
  }
`;
