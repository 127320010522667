import { gql, useQuery } from "@apollo/client";
import { Button } from "components/base";
import Errors from "components/Errors";
import Spinner from "components/Spinner";
import { useState } from "react";
import { BsArrowDownCircleFill, BsZoomIn, BsZoomOut } from "react-icons/bs";
import { useDebounce } from "use-debounce";

const FETCH_SHIPPING_MARK_PREVIEW = gql`
  query FETCH_SHIPPING_MARK_PREVIEW(
    $item: String!
    $x: Float!
    $y: Float!
    $z: Float!
    $barcode: String!
    $barcodeType: String!
    $newLogo: Boolean!
    $itemNumber: String
    $values: ShippingMarkValuesInputType!
    $notes: String
    $mainFontScale: Float
    $sideFontScale: Float
    $rotate: Boolean
    $template: String
  ) {
    previewShippingMark(
      item: $item
      x: $x
      y: $y
      z: $z
      barcode: $barcode
      barcodeType: $barcodeType
      newLogo: $newLogo
      itemNumber: $itemNumber
      values: $values
      notes: $notes
      mainFontScale: $mainFontScale
      sideFontScale: $sideFontScale
      rotate: $rotate
      template: $template
    )
  }
`;

const ShippingMarkPreview = ({ variables }) => {
  const [debouncedValues] = useDebounce(variables, 500);
  const { data, error, loading } = useQuery(FETCH_SHIPPING_MARK_PREVIEW, {
    variables: debouncedValues,
    fetchPolicy: "network-only",
  });
  const [zoom, setZoom] = useState(20);
  function zoomIn() {
    setZoom(zoom + 10);
  }

  function zoomOut() {
    if (zoom > 10) {
      setZoom(zoom - 10);
    }
  }

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  function download() {
    const url = `data:application/pdf;base64,${data.previewShippingMark}`;
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `Shipping mark - ${variables.item}.pdf`);
    document.body.appendChild(link);
    link.click();
  }

  if (!data) return null;

  return (
    <div className="flex-1 flex flex-col relative">
      <iframe key={zoom} className="w-full h-full flex-1 border-0" src={`data:application/pdf;base64,${data.previewShippingMark}#toolbar=0&zoom=${zoom}`} />
      <div className="flex space-x-6 absolute top-4 left-4 ">
        <Button title="Zoom In" size="lg" bold onClick={zoomIn} leftIcon={<BsZoomIn className="mr-2" />} />
        <Button title="Zoom Out" size="lg" bold onClick={zoomOut} leftIcon={<BsZoomOut className="mr-2" />} />
        <Button title="Download" size="lg" bold onClick={download} leftIcon={<BsArrowDownCircleFill className="mr-2" />} />
      </div>
    </div>
  );
};

export default ShippingMarkPreview;
