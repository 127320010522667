import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import Spinner from 'components/Spinner';
import Errors from 'components/Errors';
import { Button } from 'components/base';
import { FETCH_ALL_DOCUMENTS, PAGE_SIZE } from './graphql';

function DocList() {
   const navigate = useNavigate();
   const { docType } = useParams();
   const [hasNext, setHasNext] = useState(true);
   const [page, setPage] = useState(1);
   const [isFetchingMore, setIsFetchingMore] = useState(false);
   const [hasReachedEnd, setHasReachedEnd] = useState(false);

   const { loading, error, data, fetchMore } = useQuery(FETCH_ALL_DOCUMENTS, {
      variables: { docType },
   });

   useEffect(() => {
      function scrollHandler() {
         if (
            hasReachedEnd === false &&
            window.scrollY + window.innerHeight >
               document.body.scrollHeight - 200
         ) {
            setHasReachedEnd(true);
         }
      }
      window.addEventListener('scroll', scrollHandler);
      return () => {
         window.removeEventListener('scroll', scrollHandler);
      };
   }, [hasReachedEnd]);

   useEffect(() => {
      if (hasReachedEnd && hasNext && !isFetchingMore) {
         const offset = page * PAGE_SIZE;
         const limit = PAGE_SIZE;
         setIsFetchingMore(true);
         fetchMore({
            variables: { docType, offset, limit },
            updateQuery: (prev, { fetchMoreResult }) => {
               setHasReachedEnd(false);
               setPage(page + 1);
               setHasNext(fetchMoreResult.allDocuments.length === PAGE_SIZE);
               setIsFetchingMore(false);
               return {
                  ...prev,
                  allDocuments: [
                     ...prev.allDocuments,
                     ...fetchMoreResult.allDocuments,
                  ],
               };
            },
         });
      }
   }, [hasReachedEnd, hasNext, isFetchingMore, fetchMore, page, docType]);

   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;

   return (
      <div className="m-4 lg:m-6 rounded-lg border border-gray-200 dark:border-gray-700 overflow-auto">
         <table className="bg-white dark:bg-gray-800 dark:text-gray-300">
            <thead className="bg-gray-200 dark:bg-gray-900 dark:text-gray-300">
               <tr>
                  <th className="py-4 px-6 capitalize" align="left">
                     {docType}
                  </th>
                  <th className="py-4 px-6" align="right">
                     Created
                  </th>
               </tr>
            </thead>
            <tbody>
               {data.allDocuments.map((doc) => (
                  <tr
                     className="hover:bg-blue-200 dark:hover:bg-blue-800 animate-ease-2 cursor-pointer"
                     key={doc.id}
                     onClick={() =>
                        navigate(`/shipment/doc/${docType}/${doc.id}`)
                     }
                  >
                     <td className="px-6 py-3 border-b border-gray-100 dark:border-gray-700">
                        <div>
                           <div>#{doc.shipment.invoiceNumber}</div>
                           <div className="text-sm text-gray-500">
                              {doc.shipment.customer.name}
                           </div>
                        </div>
                     </td>
                     <td className="px-6 py-3 border-b border-gray-100 dark:border-gray-700 text-right text-gray-500 text-xs">
                        <div>{new Date(doc.created).toLocaleString()}</div>
                     </td>
                  </tr>
               ))}
               {hasNext && (
                  <tr>
                     <td colSpan="3" align="center" style={{ border: 0 }}>
                        <Button
                           disabled={loading}
                           className="btn m-4"
                           // onClick={(_) => _onFetchMore(fetchMore)}
                           title={isFetchingMore ? 'Fetching...' : 'Fetch More'}
                        />
                     </td>
                  </tr>
               )}
            </tbody>
         </table>
      </div>
   );
}

export default DocList;
