export function setTheme(theme) {
   if (theme === 'dark') {
      document.documentElement.classList.add('dark');
   } else if (theme === 'light') {
      document.documentElement.classList.remove('dark');
   } else {
      if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
         document.documentElement.classList.add('dark');
      } else {
         document.documentElement.classList.remove('dark');
      }
      localStorage.setItem('theme', 'auto');
   }
}
