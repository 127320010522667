import { gql, useQuery } from '@apollo/client';
import { Button } from 'components/base';
import Errors from 'components/Errors';
import ImageLoader from 'components/Image';
import Spinner from 'components/Spinner';
import { useNavigate } from 'react-router-dom';

const FETCH_QC_INLINE_REPORTS = gql`
   query FETCH_QC_INLINE_REPORTS {
      allQcInlineReports {
         id
         productLine {
            id
            name
            mainImage(size: "80x80")
         }
         factory {
            id
            name
         }
         createdAt
         createdBy {
            id
            email
         }
         date
         batchQty
         finishedQty
         checkQty
      }
   }
`;

const InlineReportList = () => {
   const { loading, error, data } = useQuery(FETCH_QC_INLINE_REPORTS);
   const navigate = useNavigate();

   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;

   const reports = data.allQcInlineReports;

   return (
      <div className="py-4 md:py-6">
         {reports.map((report) => (
            <div
               key={report.id}
               className="border-b dark:border-gray-700 py-2 px-4 md:px-6"
            >
               <div className="sm:flex justify-between items-center space-y-2 sm:space-y-0">
                  <div
                     className="flex items-center space-x-2 flex-1 cursor-pointer hover:text-blue-600"
                     onClick={() => navigate(`/qc/inline/${report.id}`)}
                  >
                     <ImageLoader
                        size="w-12 h-12"
                        src={report.productLine.mainImage}
                     />
                     <div>
                        <div>
                           {report.productLine.name} ({report.checkQty} /{' '}
                           {report.finishedQty} / {report.batchQty})
                        </div>
                        <div className="text-xs opacity-70">
                           {report.createdBy.email}{' '}
                           {new Date(report.createdAt).toLocaleString()}
                        </div>
                     </div>
                  </div>

                  <div className="flex items-center space-x-4">
                     <Button
                        title="Edit 编辑"
                        link={`/qc/inline/${report.id}`}
                     />
                     <Button
                        title="Preview 预览"
                        link={`/qc/inline/${report.id}/preview`}
                     />
                  </div>
               </div>
            </div>
         ))}
      </div>
   );
};

export default InlineReportList;
