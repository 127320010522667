import { useModals } from "ModalProvider";
import { Text } from "components/Form";
import CharlesButton from "components/charles/base";
import { useState } from "react";
import NotifyCustomerTemplateList from "./NotifyCustomerTemplateList";
import { IoIosSend } from "react-icons/io";
import { useMutation } from "@apollo/client";
import { NOTIFY_CUSTOMER } from "../graphql";
import { Alert } from "components/Toast";

const NotifyCustomerView = ({ shipmentId, initialNote = "", emails = [], invoiceNumber, customerName, hide }) => {
  const modal = useModals();
  const [content, setContent] = useState(initialNote);
  const [notifyCustomer, notifyCustomerRes] = useMutation(NOTIFY_CUSTOMER, {
    onCompleted() {
      Alert("success", "Email sent successfully.");
      hide();
    },
    onError(error) {
      Alert("error", error.message);
    },
  });

  function sendNotification() {
    notifyCustomer({
      variables: { shipmentId, confirmedEmails: emails, content },
    });
  }

  function showTemplates() {
    modal.present({
      title: "Select a template",
      children: (
        <NotifyCustomerTemplateList
          shipmentId={shipmentId}
          onSelect={(template) => {
            const notes = template.replace("{customername}", customerName).replace("{invoicenumber}", invoiceNumber);
            setContent(notes);
            modal.hide();
          }}
        />
      ),
    });
  }

  return (
    <div>
      <div>
        <div className="text-sm flex justify-between items-baseline">
          <div className="text-sm opacity-70">You are going to send a notification to the customer. Please write the content below.</div>
          <CharlesButton onClick={showTemplates}>Choose a template</CharlesButton>
        </div>
        <Text className="mt-2" rows={20} value={content} onChange={(e) => setContent(e.target.value)} />
        <div className="mt-2 ml-1 text-sm">Email will be sent to: {emails.join(", ")}</div>
      </div>

      <div className="mt-4 flex justify-end">
        <CharlesButton primary icon={<IoIosSend />} onClick={sendNotification} loading={notifyCustomerRes.loading}>
          Send Notification
        </CharlesButton>
      </div>
    </div>
  );
};

export default NotifyCustomerView;
