import { Route, Routes, Navigate } from 'react-router-dom';
import Purchases from './Purchases';
import PurchasesDetail from './PurchasesDetail';

const Purchase = () => {
   return (
      <Routes>
         <Route index element={<Purchases />} />
         <Route path=":id" element={<PurchasesDetail />} />
         <Route path="*" element={<Navigate to="/purchase" />} />
      </Routes>
   );
};

export default Purchase;
