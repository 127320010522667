import { gql } from '@apollo/client';

export const FETCH_ODOO_CATEGORIES_MARGIN = gql`
  query FETCH_ODOO_CATEGORIES_MARGIN(
    $startDate: String!
    $endDate: String!
    $orderType: String!
  ) {
    odooProductCategoriesMargin(
      startDate: $startDate
      endDate: $endDate
      orderType: $orderType
    )
  }
`;

export const FETCH_ODOO_PRODUCTS_MARGIN = gql`
  query FETCH_ODOO_PRODUCTS_MARGIN(
    $productIds: [Int]
    $startDate: String!
    $endDate: String!
    $orderType: String!
  ) {
    odooProductsMargin(
      productOdooIds: $productIds
      startDate: $startDate
      endDate: $endDate
      orderType: $orderType
    )
  }
`;
