import { useQuery } from "@apollo/client";
import { FETCH_ODOO_STOCK_PRODUCTS } from "./graphql";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import { useParams } from "react-router-dom";
import StocksViewNew from "./StocksViewNew";

const OdooStockProductsPage = () => {
  const { stockId } = useParams();
  console.log("stockId", stockId);
  const { loading, error, data } = useQuery(FETCH_ODOO_STOCK_PRODUCTS, {
    variables: { stockId },
  });

  if (loading) return <Spinner text="Loading data from Odoo..." />;
  if (error) return <Errors error={error} />;

  const productIds = data.odooStock.products.map((product) => product.odooId);

  return <StocksViewNew productOdooIds={productIds} />;
};

export default OdooStockProductsPage;
