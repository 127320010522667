import { useQuery } from "@apollo/client";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import { FETCH_COMPARE_SALES_FORECAST } from "../graphql";
import CategoryView from "./CategoryView";

const SalesForecastView = ({ groupBy }) => {
  const { loading, error, data } = useQuery(FETCH_COMPARE_SALES_FORECAST, {
    variables: { groupBy, forecast: true },
  });

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const res = JSON.parse(data.incCompareSalesAndForecast);

  return (
    <div className="mt-6 grid grid-cols-2 gap-6">
      {Object.entries(res).map(([name, data]) => (
        <div key={name} className="card">
          <CategoryView name={name} data={data} />
        </div>
      ))}
    </div>
  );
};

export default SalesForecastView;
