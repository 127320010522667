import { gql, useMutation } from "@apollo/client";
import { parseError } from "apollo";
import { FileSelector, Text } from "components/Form";
import { Alert } from "components/Toast";
import CharlesButton from "components/charles/base";
import { CREATE_CONTENT_BLOCK } from "graphql/mutations";
import useFileUploader from "hooks/useFileUploader";
import { useState } from "react";
import { BsTrash, BsUpload } from "react-icons/bs";

const AddpackingInstructionView = ({ productListId, hide }) => {
  const [content, setContent] = useState("");
  const [images, setImages] = useState([]);
  const { isUploading, handleFileUpload } = useFileUploader((urls) => {
    setImages([...images, ...urls]);
  });
  const [createPackingInstruction, createPackingInstructionRes] = useMutation(CREATE_CONTENT_BLOCK, {
    variables: {
      modelId: productListId,
      modelType: "ProductList",
      contentType: "packing_instruction",
      content,
      images,
    },
    onCompleted() {
      Alert("success", "Packing instruction added successfully.");
      hide();
    },
    onError(error) {
      Alert("error", parseError(error));
    },
    refetchQueries: ["FETCH_PRODUCT_LIST_PACKING_INSTRUCTIONS"],
    awaitRefetchQueries: true,
  });

  return (
    <div>
      <div>
        <div>
          <Text value={content} rows={8} placeholder="Input description here." onChange={(e) => setContent(e.target.value)} />
        </div>

        <div className="mt-2">
          {images.length > 0 ? (
            <>
              <div className="text-xs space-y-2 my-2">
                {images.map((url, index) => (
                  <div key={index} className="flex items-center space-x-2">
                    <CharlesButton danger onClick={() => setImages(images.filter((i) => i !== url))}>
                      <BsTrash />
                    </CharlesButton>
                    <a href={url} target="_blank" rel="noreferrer">
                      {url}
                    </a>
                  </div>
                ))}
              </div>
            </>
          ) : null}

          <FileSelector
            accept="image/*"
            multiple
            className="text-sm mt-2"
            uploading={isUploading}
            title={
              <div className="flex items-center space-x-2">
                <BsUpload />
                <span>Upload image</span>
              </div>
            }
            onChange={(e) => {
              const files = [...e.target.files];
              handleFileUpload(files);
              e.target.value = null;
            }}
          />
        </div>
      </div>

      <div className="mt-8">
        <CharlesButton primary onClick={createPackingInstruction} loading={createPackingInstructionRes.loading}>
          Save
        </CharlesButton>
      </div>
    </div>
  );
};

export default AddpackingInstructionView;
