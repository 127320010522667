import { formatDate } from "react-day-picker/moment";
import Errors from "components/Errors";
import Spinner from "components/Spinner";
import { useAmazonParty, useAmazonVCPurchaseOrder } from "hooks/useAmazon";
import CreateOdooOrderForm from "./CreateOdooOrderForm";
import useOdooCustomers from "hooks/useOdooCustomers";

const AcknowledgedOrder = ({ regionCode, order }) => {
  const shipToPartyQuery = useAmazonParty(order.orderDetails.shipToParty.partyId);
  const amazonVcPurchaseOrderQuery = useAmazonVCPurchaseOrder({
    number: order.purchaseOrderNumber,
  });
  const odooCustomersQuery = useOdooCustomers();

  if (amazonVcPurchaseOrderQuery.loading || shipToPartyQuery.loading || odooCustomersQuery.loading) return <Spinner />;
  if (amazonVcPurchaseOrderQuery.error) return <Errors error={amazonVcPurchaseOrderQuery.error} />;
  if (shipToPartyQuery.error) return <Errors error={shipToPartyQuery.error} />;
  if (odooCustomersQuery.error) return <Errors error={odooCustomersQuery.error} />;

  const odooDeliveryAddress = odooCustomersQuery.odooCustomers.find((i) => i.comment && i.comment.includes(order.orderDetails.shipToParty.partyId));

  const odooCustomer =
    odooDeliveryAddress && odooDeliveryAddress.parent_id ? odooCustomersQuery.odooCustomers.find((i) => i.id === odooDeliveryAddress.parent_id[0]) : null;

  const wisOrder = amazonVcPurchaseOrderQuery.data.amzVcPurchaseOrder;

  return (
    <div className="text-sm">
      <div className="space-y-4">
        <div className="flex space-x-4">
          <label>Status: </label>
          <div>{order.purchaseOrderState}</div>
        </div>

        <div className="flex space-x-4">
          <label>Window Start: </label>
          <div>{formatDate(order.windowStart, "YYYY-MM-DD")}</div>

          <label>Window End: </label>
          <div>{formatDate(order.windowEnd, "YYYY-MM-DD")}</div>
        </div>

        <div className="flex space-x-8">
          <div className="flex space-x-4">
            <label>Selling Party: </label>
            <div>
              <div className="font-body">{order.orderDetails.sellingParty.partyId}</div>
            </div>
          </div>

          <div className="flex space-x-4">
            <label>Buying Party: </label>
            <div>
              <div className="font-body">{order.orderDetails.buyingParty.partyId}</div>
            </div>
          </div>

          <div className="flex space-x-4">
            <label>Ship to Party: </label>
            <div>
              <div className="font-body">{order.orderDetails.shipToParty.partyId}</div>
            </div>
          </div>

          <div className="flex space-x-4">
            <label>Bill to Party: </label>
            <div>
              <div className="font-body">{order.orderDetails.billToParty.partyId}</div>
            </div>
          </div>
        </div>

        <div className="flex">
          <label className="mr-4">Delivery Address: </label>

          {odooDeliveryAddress ? (
            <div>{odooDeliveryAddress.contact_address}</div>
          ) : (
            <div className="text-red-600 font-semibold">
              Could not find this customer in Odoo by FC Code: {order.orderDetails.shipToParty.partyId}. Make sure you fill this FC Code into Odoo for this
              customer address.{" "}
              <a
                className="text-blue-600 hover:text-blue-700"
                href="https://waboba.odoo.com/web#action=468&model=res.partner&view_type=kanban&menu_id=332"
                target="_blank"
              >
                Open Odoo
              </a>
            </div>
          )}
        </div>

        <div className="flex items-center space-x-4">
          <label>Expect Date: </label>
          <div>{formatDate(order.windowEnd, "YYYY-MM-DD")}</div>
        </div>

        {wisOrder ? (
          <div className="font-semibold text-green-600">
            <label htmlFor="">Using Stock: </label>
            <span>{wisOrder.stockCategory.name}</span>
          </div>
        ) : null}
      </div>

      {wisOrder ? (
        <div className="mt-6 space-y-4">
          <table className="bg-white dark:bg-gray-900">
            <thead>
              <tr>
                <th className="border dark:border-gray-700 p-4 text-left">Product</th>
                <th className="border dark:border-gray-700 p-4 text-right">Qty Requested</th>

                <th className="border dark:border-gray-700 p-4 text-right">Unit Price</th>

                <th className="border dark:border-gray-700 p-4 text-right">Acknowledgement</th>
              </tr>
            </thead>
            <tbody>
              {wisOrder.lines.map((line, lineIndex) => (
                <tr
                  key={lineIndex}
                  className={
                    line.actionType === "accept"
                      ? "bg-green-200 bg-opacity-30 dark:bg-green-900 dark:bg-opacity-30"
                      : "bg-pink-100 bg-opacity-60 dark:bg-pink-900 dark:bg-opacity-30"
                  }
                >
                  <td className="border dark:border-gray-700 px-4">
                    <div className="space-y-1">
                      {line.stock ? (
                        <div className="font-bold">
                          [{line.stock.number}] {line.stock.name} {line.stock.barcode}
                        </div>
                      ) : (
                        <div>
                          <b>{line.asin}</b> - No stock for this asin.
                        </div>
                      )}
                    </div>
                  </td>

                  <td className="border dark:border-gray-700 px-4 text-right">{line.qtyRequested}</td>

                  <td className="border dark:border-gray-700 px-4 text-right">
                    <div>
                      {line.netCostCurrency} {line.netCostAmount}
                    </div>
                  </td>

                  <td className="border dark:border-gray-700 px-4 text-right text-xs">
                    <div
                      className={`font-bold capitalize
                                 ${line.actionType === "accept" ? "text-green-500" : "text-pink-500"}
                                 `}
                    >
                      {line && line.actionType === "accept" ? `ACCEPTED ${line.actionQty}` : null}
                      {line && line.actionType === "reject" ? `REJECTED ${line.actionQty}` : null}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="relative">
            <CreateOdooOrderForm
              regionCode={regionCode}
              order={order}
              wisOrder={wisOrder}
              fcCode={order.orderDetails.shipToParty.partyId}
              odooCustomer={odooCustomer}
              odooDeliveryAddress={odooDeliveryAddress}
            />
          </div>
        </div>
      ) : (
        <div className="mt-6 text-pink-400 font-bold">This order has not been handled throught WIS. So the order item acknowledgement status is unknown.</div>
      )}
    </div>
  );
};

export default AcknowledgedOrder;
