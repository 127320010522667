import { gql, useMutation } from "@apollo/client";
import { useModals } from "ModalProvider";
import { parseError } from "apollo";
import { Alert } from "components/Toast";
import CharlesButton from "components/charles/base";
import StockStrategyView from "./StockUseStrategyView";

const CHANGE_USE_STRATEGY = gql`
  mutation CHANGE_USE_STRATEGY($id: ID!, $useStrategy: String!) {
    updateSupplierStockRecord(id: $id, useStrategy: $useStrategy) {
      supplierStockRecord {
        id
        useStrategy
      }
    }
  }
`;

const ProductStockSettingsView = ({ stock, disabled }) => {
  const modal = useModals();
  const [changeUseStrategy, changeUseStrategyRes] = useMutation(CHANGE_USE_STRATEGY, {
    onCompleted: () => {
      Alert("success", "Use strategy changed successfully.");
      modal.hide();
    },
    onError(error) {
      Alert("error", parseError(error));
    },
  });

  function changeHandler(useStrategy) {
    modal.present({
      title: "Change use Strategy",
      center: true,
      maxWidth: "max-w-3xl",
      children: (
        <div className="text-base">
          <div className="space-y-2">
            <p>Are you sure you want to change the use strategy?</p>
            <p>
              Please note: Modifying a stock strategy affects future plans only and doesn't auto-recompute existing ones. To update current plans based on the
              new strategy, manually initiate re-computation.
            </p>
          </div>
          <hr />
          <div className="flex space-x-6">
            <CharlesButton
              primary
              loading={changeUseStrategyRes.loading}
              onClick={() =>
                changeUseStrategy({
                  variables: { id: stock.id, useStrategy },
                })
              }
            >
              Confirm
            </CharlesButton>
            <CharlesButton onClick={modal.hide}>Cancel</CharlesButton>
          </div>
        </div>
      ),
    });
  }

  return <StockStrategyView value={stock.useStrategy} onChange={changeHandler} disabled={disabled} />;
};

export default ProductStockSettingsView;
