import { gql, useQuery } from '@apollo/client';
import AppSettings from 'AppSettings';
import Errors from 'components/Errors';
import Spinner from 'components/Spinner';
import { BiLinkExternal } from 'react-icons/bi';
import { IoCheckmarkCircle, IoCloseCircle } from 'react-icons/io5';

const FETCH_PRODUCTS_ON_SALE_DATA = gql`
   query FETCH_PRODUCTS_ON_SALE_DATA {
      compareWisAndOdooProducts
   }
`;

const ProductsOnSale = () => {
   const { loading, error, data } = useQuery(FETCH_PRODUCTS_ON_SALE_DATA);

   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;

   const products = JSON.parse(data.compareWisAndOdooProducts);

   console.log('products', products);

   return (
      <div className="p-4 text-sm flex-1 overflow-auto flex flex-col">
         <div className="card p-0 flex-1 overflow-auto relative text-xs">
            <table>
               <thead>
                  <tr className="whitespace-nowrap sticky top-0 bg-gray-50 bg-opacity-90 backdrop-blur outline outline-gray-100">
                     <th className="px-6 py-3">Odoo Product</th>
                     <th className="px-6">WIS Product</th>
                     <th className="px-6">Odoo Stock</th>
                     <th className="px-6">Odoo Active</th>
                     <th className="px-6">Wis Active</th>
                     <th className="px-6">Number Matches</th>
                     <th className="px-6">Salable Matches</th>
                     <th className="px-6">Active Matches</th>
                  </tr>
               </thead>
               <tbody>
                  {products.map((i, index) => (
                     <tr
                        key={index}
                        className="border-t border-gray-100 dark:border-gray-700"
                     >
                        <td className="px-6">
                           <div className="flex space-x-2 items-center">
                              <div>
                                 {i.odoo_id
                                    ? `[${i.default_code}] ${i.odoo_name}`
                                    : '-'}
                              </div>
                              {i.odoo_id ? (
                                 <a
                                    className="text-xs"
                                    target="_blank"
                                    rel="noreferer"
                                    href={
                                       AppSettings.odooBaseUrl +
                                       `/web#id=${i.odoo_id}&cids=1&menu_id=222&action=563&model=product.product&view_type=form`
                                    }
                                 >
                                    <BiLinkExternal />
                                 </a>
                              ) : null}
                           </div>
                        </td>
                        <td className="px-6">
                           {i.id ? `[${i.number}] ${i.name}` : '-'}
                        </td>
                        <td className="px-6 text-right">{i.qty_available}</td>
                        <td className="px-6 text-center">
                           {i.odoo_active ? 'Yes' : 'No'}
                        </td>
                        <td className="px-6 text-center">
                           {i.is_active ? 'Yes' : 'No'}
                        </td>
                        <td className="px-6 text-center">
                           <MatchesCheck match={i.number_matches} />
                        </td>
                        <td className="px-6 text-center">
                           <MatchesCheck match={i.sale_ok_matches} />
                        </td>
                        <td className="px-6 text-center">
                           <MatchesCheck match={i.active_matches} />
                        </td>
                     </tr>
                  ))}
               </tbody>
            </table>

            <div className="sticky bottom-0 bg-gray-50 px-6 py-2 bg-opacity-90 backdrop-blur">
               <span className="text-xs opacity-70">
                  {products.length} rows
               </span>
            </div>
         </div>
      </div>
   );
};

const MatchesCheck = ({ match }) => {
   if (match)
      return (
         <IoCheckmarkCircle className="inline-block text-green-500" size={18} />
      );
   return <IoCloseCircle className="inline-block text-red-500" size={18} />;
};

export default ProductsOnSale;
