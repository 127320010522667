import { createContext } from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import { FETCH_PRODUCT_LINE_DETAIL } from "../graphql";
import { useModals } from "ModalProvider";
import AppConfig from "AppSettings";
import ProductWithBomCostHistoryView from "./ProductWithBomCostHistoryView";
import OneProductView from "./OneProductView";
import ProductPricesView from "../ProductPricesView";

export const ProductCostContext = createContext({});

const ProductDetail = () => {
  const { id } = useParams();
  const { loading, error, data } = useQuery(FETCH_PRODUCT_LINE_DETAIL, {
    variables: { id },
  });

  const productPricesModal = useModals();
  const costHistoryModal = useModals();

  function showProductWithBomCostHistory(id) {
    costHistoryModal.present({
      title: "Product Cost History",
      children: <ProductWithBomCostHistoryView id={id} />,
    });
  }

  function showProductCosts(product) {
    productPricesModal.present({
      title: product.name,
      maxWidth: "max-w-5xl",
      children: <ProductPricesView id={product.id} />,
    });
  }

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const products = data.productLine.products.filter((product) => product.isActive);

  return (
    <ProductCostContext.Provider value={{ showProductWithBomCostHistory, showProductCosts }}>
      <div className="p-6 h-full overflow-auto">
        <div className="flex justify-between items-center">
          <h1 className="dark:text-gray-200">{data.productLine.name}</h1>
        </div>
        <div>
          {products.length > 0 ? (
            <div>
              <div className="text-right">{AppConfig.exchangeRateNote}</div>
              <div className="mt-4">
                {products.map((product) => (
                  <div className="bg-white dark:bg-gray-800 dark:text-gray-300 rounded-xl p-6 mt-6 mb-10 shadow" key={product.id}>
                    <OneProductView product={product} showPriceSyncer={() => setShowPriceSyncer(product)} />
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="flex-1 items-center justify-center py-6">
              <label>This Product does not have any variants.</label>
            </div>
          )}
        </div>
      </div>
    </ProductCostContext.Provider>
  );
};

export default ProductDetail;
