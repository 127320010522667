import { gql, useMutation, useQuery } from "@apollo/client";
import { AppContext } from "App";
import { Button, ButtonX } from "components/base";
import Errors from "components/Errors";
import { FileSelector, Input, Select, Text } from "components/Form";
import Page from "components/Page";
import QiniuUploader from "components/QiniuUploader";
import Spinner, { InlineSpinner } from "components/Spinner";
import { Alert } from "components/Toast";
import { useModals } from "ModalProvider";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { formatDate } from "react-day-picker/moment";
import { useParams } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { FETCH_DEFECT_CODES } from "../graphql";
import { INTERNAL } from "utils/permissions";

export const FETCH_QC_INLINE_REPORT = gql`
  query FETCH_QC_INLINE_REPORT($id: ID!) {
    qcInlineReport(id: $id) {
      id
      productLine {
        id
        name
        mainImage(size: "80x80")
      }
      factory {
        id
        name
      }
      date
      createdBy {
        id
        email
      }
      finishedQty
      batchQty
      checkQty
      images {
        id
        type
        src
      }
      issues {
        id
        defectCode {
          id
          code
          description
        }
        images {
          id
          src
        }
        remark
        qty
      }
      remark
    }
  }
`;

const InlineReportForm = () => {
  const { id } = useParams();
  const { user, hasPermission } = useContext(AppContext);

  const { loading, error, data } = useQuery(FETCH_QC_INLINE_REPORT, {
    variables: { id },
  });

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const report = data.qcInlineReport;

  console.log("user", user);

  return (
    <Page
      title={report.productLine.name}
      backTo="/qc/inline"
      rightButtons={
        <div className="flex space-x-4">
          {hasPermission(INTERNAL) ? (
            <Button title="Admin" link={`${process.env.REACT_APP_SERVER_ADMIN_URL}/qc/qcinlinereport/${report.id}/change/`} target="_blank" />
          ) : null}
          <Button title="Preview" link={`/qc/inline/${id}/preview`} target="_blank" />
        </div>
      }
    >
      <div className="p-6">
        <FormContent report={report} />
      </div>
    </Page>
  );
};

export const metaTypes = {
  product: {
    name: "Product / 产品图片",
  },
  "product line": {
    name: "Product Line / 生产线图片",
  },
  process: {
    name: "Process / 工序图片",
  },
  other: {
    name: "Other / 其他图片",
  },
};

const SAVE_QC_INLINE_REPORT = gql`
  mutation SAVE_QC_INLINE_REPORT($id: ID!, $remark: String) {
    saveQcInlineReport(id: $id, remark: $remark) {
      report {
        id
        remark
      }
    }
  }
`;

const FormContent = ({ report }) => {
  const { present, hide } = useModals({});
  const [remark, setRemark] = useState(report.remark);
  const [saveReport, saveReportRes] = useMutation(SAVE_QC_INLINE_REPORT, {
    variables: { id: report.id, remark },
    onCompleted() {
      Alert("success", "保存成功.");
    },
    onError(error) {
      Alert("error", error.message);
    },
  });

  const debouncedSave = useDebouncedCallback(saveReport, 500);

  return (
    <div className=" space-y-4">
      <div>
        <label htmlFor="">Factory 工厂: </label>
        <span>{report.factory.name}</span>
      </div>

      <div className="flex space-x-8">
        <div>
          <label htmlFor="">Batch Qty:</label> <span>{report.batchQty}</span>
        </div>
        <div>
          <label htmlFor="">Finish Qty:</label> <span>{report.finishedQty}</span>
        </div>
        <div>
          <label htmlFor="">Inspection Qty:</label> <span>{report.checkQty}</span>
        </div>
      </div>

      <div>
        <label htmlFor="">Date: </label> <span>{formatDate(report.date, "YYYY-MM-DD")}</span>
      </div>

      <div>
        <label htmlFor="">Created By: </label> <span>{report.createdBy.email}</span>
      </div>

      <div className="pt-8 space-y-6">
        {Object.entries(metaTypes).map(([type, meta]) => (
          <div key={type} className="bg-white dark:bg-gray-800 p-6 rounded-2xl">
            <MetaImages type={type} meta={meta} report={report} />
          </div>
        ))}
      </div>

      {report.issues.length > 0 ? (
        <div className="pt-8">
          <h2>Issues</h2>
          <div className="mt-4 space-y-6">
            {report.issues.map((i) => (
              <Issue issue={i} checkQty={report.checkQty} key={i.id} />
            ))}
          </div>
        </div>
      ) : null}

      <hr />

      <div>
        <Button
          size="lg"
          title="+ Add Issue 添加缺陷"
          onClick={() =>
            present({
              title: "Add an Issue",
              maxWidth: "max-w-5xl",
              center: false,
              children: <IssueForm reportId={report.id} hide={hide} maxQty={report.finishedQty} />,
            })
          }
        />
      </div>

      <hr />
      <div className="pt-8">
        <div className="flex space-x-3">
          <h4>Remark 备注:</h4>
          {saveReportRes.loading ? <InlineSpinner size={16} text={null} /> : null}
        </div>
        <Text
          className="mt-3"
          value={remark}
          onChange={(e) => {
            setRemark(e.target.value);
            debouncedSave();
          }}
        />
      </div>
    </div>
  );
};

const CREATE_QC_INLINE_REPORT_IMAGE = gql`
  mutation CREATE_QC_INLINE_REPORT_IMAGE($reportId: ID!, $images: [QcInlineReportImagesInputType!]) {
    createQcInlineReportImages(reportId: $reportId, images: $images) {
      report {
        id
        images {
          id
          type
          src
        }
      }
    }
  }
`;

const MetaImages = ({ type, meta, report }) => {
  const [images, setImages] = useState([]);

  const [createImages, createImagesRes] = useMutation(CREATE_QC_INLINE_REPORT_IMAGE, {
    onCompleted() {
      Alert("success", "上传成功.");
    },
    onError(error) {
      Alert("error", error.message);
    },
  });

  function onCreated(index, url) {
    setImages((prev) =>
      prev.map((i, prevIndex) => {
        if (prevIndex !== index) return i;
        return { ...i, url };
      })
    );
  }

  useEffect(() => {
    if (images.length === 0) return;

    if (images.every((i) => i.url)) {
      createImages({
        variables: {
          reportId: report.id,
          images: images.map((i) => ({ type, src: i.url })),
        },
      }).then(() => {
        setImages([]);
      });
    }
  }, [images]);

  return (
    <div>
      <h4 className="capitalize">{meta.name}</h4>
      <div className="mt-4 grid grid-cols-1 items-end gap-6 md:grid-cols-2 lg:grid-cols-4 2xl:grid-cols-5">
        {report.images
          .filter((i) => i.type === type)
          .map(({ id, src }) => (
            <MetaImage key={id} id={id} src={src} />
          ))}

        {images.map((i, index) => (
          <div className="relative w-full" key={index}>
            <QiniuUploader
              preview={i.preview}
              category="qcInlineReportImages"
              file={i.file}
              filename={i.filename}
              onUploaded={(url) => onCreated(index, url)}
            />
            <div className="absolute top-2 right-2 z-10">
              <ButtonX onClick={() => setImages((prev) => prev.filter((_, prevIndex) => prevIndex !== index))} />
            </div>
          </div>
        ))}
      </div>

      <FileSelector
        accept="image/*"
        className="mt-4"
        title="+ Add Images 添加图片"
        disabled={createImagesRes.loading}
        uploading={createImagesRes.loading}
        onChange={(e) => {
          const files = [...e.target.files];
          setImages((prev) => [
            ...prev,
            ...files.map((file) => ({
              src: null,
              file,
              filename: `qcInlineReportImages/${file.name}`,
              preview: URL.createObjectURL(file),
            })),
          ]);
          e.target.value = null;
        }}
      />
    </div>
  );
};

const DELETE_QC_INLINE_REPORT_IMAEG = gql`
  mutation DELETE_QC_INLINE_REPORT_IMAEG($id: ID!) {
    deleteQcInlineReportImage(id: $id) {
      report {
        id
        images {
          id
          type
          src
        }
      }
    }
  }
`;

const MetaImage = ({ id, src }) => {
  const [deleteImage, deleteImageRes] = useMutation(DELETE_QC_INLINE_REPORT_IMAEG, {
    variables: { id },
    onCompleted() {
      Alert("success", "Deleted.");
    },
    onError(error) {
      Alert("error", error.message);
    },
  });

  return (
    <div className="relative" key={id}>
      <a className="w-full block" href={src} target="_blank" title="Open origin image" rel="noopener noreferrer">
        <img className="w-full bg-gray-700" src={`${src}?imageView2/2/w/600`} alt="meta" />
      </a>

      <div className="absolute top-2 right-2 z-10">
        <ButtonX
          disabled={deleteImageRes.loading}
          onClick={() => {
            if (window.confirm("Are you sure to delete this image? ")) deleteImage();
          }}
        />
      </div>
    </div>
  );
};

const REMOVE_ISSUE = gql`
  mutation REMOVE_ISSUE($id: ID!) {
    deleteQcInlineReportIssue(id: $id) {
      report {
        id
        issues {
          id
          defectCode {
            id
            code
            description
          }
          images {
            id
            src
          }
          qty
        }
      }
    }
  }
`;

const Issue = ({ issue, checkQty }) => {
  const [removeIssue, removeIssueRes] = useMutation(REMOVE_ISSUE, {
    variables: { id: issue.id },
    onCompleted() {
      Alert("success", "Deleted.");
    },
    onError(error) {
      Alert("error", error.message);
    },
  });

  return (
    <div className="bg-white dark:bg-gray-800 rounded-2xl p-4 sm:p-6">
      <div className="flex justify-between">
        <div>
          <div className="text-lg mb-2">
            <label htmlFor="">Qty ( {((issue.qty * 100) / checkQty).toFixed(2)}%) :</label> &times; {issue.qty}
          </div>
          <div>
            {issue.defectCode.code}: {issue.defectCode.description}
          </div>
        </div>

        <div>
          <Button
            title="Delete"
            color="red"
            size="xs"
            onClick={() => {
              if (window.confirm("Are you sure to delete this issue?")) removeIssue();
            }}
            disabled={removeIssueRes.loading}
            loading={removeIssueRes.loading}
          />
        </div>
      </div>
      {issue.images.length > 0 ? (
        <div className="mt-4 grid grid-cols-1 items-end gap-6 md:grid-cols-2 lg:grid-cols-4 2xl:grid-cols-5">
          {issue.images.map((i) => (
            <IssueImage src={i.src} id={i.id} key={i.id} />
          ))}
        </div>
      ) : (
        <div>
          <label htmlFor="">No images for this issue.</label>
        </div>
      )}

      {issue.remark ? (
        <div className="mt-4">
          <label htmlFor="">Remark: </label>
          <div className="whitespace-pre-wrap">{issue.remark}</div>
        </div>
      ) : null}
    </div>
  );
};

const DELETE_QC_INLINE_REPORT_ISSUE_IMAGE = gql`
  mutation DELETE_QC_INLINE_REPORT_ISSUE_IMAGE($id: ID!) {
    deleteQcInlineReportIssueImage(id: $id) {
      issue {
        id
        images {
          id
          src
        }
      }
    }
  }
`;

const IssueImage = ({ id, src }) => {
  const [deleteImage, deleteImageRes] = useMutation(DELETE_QC_INLINE_REPORT_ISSUE_IMAGE, {
    variables: { id },
    onCompleted() {
      Alert("success", "Deleted.");
    },
    onError(error) {
      Alert("error", error.message);
    },
  });
  return (
    <div className="relative">
      <a className="w-full block" href={src} target="_blank" title="Open origin image" rel="noopener noreferrer">
        <img className="w-full bg-gray-700" src={`${src}?imageView2/2/w/600`} alt="meta" />
      </a>

      <div className="absolute top-2 right-2 z-10">
        <ButtonX
          disabled={deleteImageRes.loading}
          onClick={() => {
            if (window.confirm("Are you sure to delete this image? ")) deleteImage();
          }}
        />
      </div>
    </div>
  );
};

const SAVE_QC_INLINE_REPORT_ISSUE = gql`
  mutation SAVE_QC_INLINE_REPORT_ISSUE($reportId: ID!, $defectCodeId: ID!, $qty: Int!, $images: [String], $remark: String) {
    saveQcInlineReportIssue(reportId: $reportId, defectCodeId: $defectCodeId, qty: $qty, images: $images, remark: $remark) {
      report {
        id
        issues {
          id
          defectCode {
            id
            code
            description
          }
          images {
            id
            src
          }
          qty
        }
      }
    }
  }
`;

const IssueForm = ({ reportId, hide, maxQty }) => {
  const { loading, error, data } = useQuery(FETCH_DEFECT_CODES);

  const [defectCodeId, setDefectCodeId] = useState("0");
  const [qty, setQty] = useState(1);
  const [images, setImages] = useState([]);
  const [remark, setRemark] = useState("");

  const [save, saveRes] = useMutation(SAVE_QC_INLINE_REPORT_ISSUE, {
    onCompleted() {
      Alert("success", "Saved.");
      setDefectCodeId("0");
      setQty(1);
      setImages([]);
      hide();
    },
    onError(error) {
      Alert("error", error.message);
    },
  });

  function onCreated(index, url) {
    setImages((prev) =>
      prev.map((i, prevIndex) => {
        if (prevIndex !== index) return i;
        return { ...i, url };
      })
    );
  }

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const allImagesReady = images.every((i) => i.url);

  return (
    <div className="space-y-4">
      <div className="space-y-3">
        <div>
          <label>Defect Code: </label>
        </div>
        <Select onChange={(e) => setDefectCodeId(e.target.value)} value={defectCodeId}>
          <option value="0" disabled>
            -- Choose Error Code 请选择缺陷类型 --
          </option>
          {data.allDefectCodes.map((i, index) => (
            <option key={index} value={i.id}>
              {i.code} - {i.description}
            </option>
          ))}
        </Select>
      </div>

      <div className="space-y-3">
        <div>
          <label>数量: </label>
        </div>
        <Input
          className="text-center"
          value={qty}
          onChange={(e) => {
            const v = parseInt(e.target.value);
            if (isNaN(v)) {
              setQty(0);
            } else {
              setQty(Math.min(v, maxQty));
            }
          }}
        />
      </div>

      <div>
        <div className="mt-4 grid grid-cols-1 items-end gap-6 md:grid-cols-2 lg:grid-cols-4 2xl:grid-cols-5">
          {images.map((i, index) => (
            <div className="relative w-full" key={index}>
              <QiniuUploader
                preview={i.preview}
                category="qcInlineReportIssueImages"
                file={i.file}
                filename={i.filename}
                onUploaded={(url) => onCreated(index, url)}
              />
              <div className="absolute top-2 right-2 z-10">
                <ButtonX onClick={() => setImages((prev) => prev.filter((_, prevIndex) => prevIndex !== index))} />
              </div>
            </div>
          ))}
        </div>

        <FileSelector
          accept="image/*"
          className="mt-4"
          title="+ Add Images 添加图片"
          onChange={(e) => {
            const files = [...e.target.files];
            setImages((prev) => [
              ...prev,
              ...files.map((file) => ({
                src: null,
                file,
                filename: `qcInlineReportIssueImages/${file.name}`,
                preview: URL.createObjectURL(file),
              })),
            ]);
            e.target.value = null;
          }}
        />
      </div>

      <div>
        <label htmlFor="">Remark 备注:</label>
        <div className="mt-3">
          <Text value={remark} onChange={(e) => setRemark(e.target.value)} />
        </div>
      </div>

      <hr />

      <div className="my-8">
        <Button
          title="Save"
          size="2xl"
          loading={saveRes.loading}
          disabled={saveRes.loading || !allImagesReady || defectCodeId === "0"}
          onClick={() =>
            save({
              variables: {
                reportId,
                defectCodeId,
                qty,
                remark,
                images: images.map((i) => i.url),
              },
            })
          }
        />
      </div>
    </div>
  );
};

export default InlineReportForm;
