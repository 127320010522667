import { gql, useQuery } from "@apollo/client";
import { Select } from "./Form";

const FETCH_ODOO_INCOTERMS = gql`
  query FETCH_ODOO_INCOTERMS {
    odooIncoterms
  }
`;

const OdooIncotermsSelector = ({ value, onSelect, ...rest }) => {
  const { loading, error, data } = useQuery(FETCH_ODOO_INCOTERMS);

  const odooIncoterms = data ? JSON.parse(data.odooIncoterms) : [];

  return (
    <Select value={value || "0"} onChange={(e) => onSelect(e.target.value)} {...rest}>
      {loading ? (
        <option value="0" disabled>
          loading...
        </option>
      ) : error ? (
        <option value="0" disabled>
          {error.message}
        </option>
      ) : (
        <>
          <option value="0" disabled>
            --- Select ---
          </option>
          {odooIncoterms.map((i, index) => (
            <option key={index} value={i.id}>
              {i.name}
            </option>
          ))}
        </>
      )}
    </Select>
  );
};

export default OdooIncotermsSelector;
