import React from 'react';
import Card from '../../../components/Card.js';
import ShipmentError from '../../../components/ShipmentError.js';

function ErrorsCauseDelay({ shipments }) {
   const shipmentErrorsWithDelayDays = shipments
      .map((shipment) =>
         shipment.shipmentStatus.map((shipmentStatus) =>
            shipmentStatus.errors.filter(
               (shipmentError) =>
                  shipmentError.delayDays && shipmentError.delayDays > 0
            )
         )
      )
      .flat(2);
   let data = [];
   shipmentErrorsWithDelayDays.forEach((shipmentError) => {
      let error = data.find((d) => d.id === shipmentError.error.id);
      if (!error) {
         error = { ...shipmentError.error, totalDelayDays: 0 };
         data.push(error);
      }
      error.totalDelayDays += shipmentError.delayDays;
   });
   data = data.sort((a, b) => b.totalDelayDays - a.totalDelayDays);
   return (
      <Card title="Errors cause delay">
         {data.map((i, index) => (
            <div
               key={index}
               className="flex border-t border-gray-100 dark:border-gray-700 items-center py-2"
            >
               <div className="w-48">
                  Total{' '}
                  <span className="text-pink-500">{i.totalDelayDays}</span>{' '}
                  delay days
               </div>
               <ShipmentError
                  className="flex-1"
                  code={i.code}
                  description={i.description}
               />
            </div>
         ))}
      </Card>
   );
}

export default ErrorsCauseDelay;
