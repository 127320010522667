import { gql, useMutation } from "@apollo/client";
import CharlesButton from "components/charles/base";
import { Alert } from "components/Toast";
import { FETCH_PRICE_LISTS } from ".";

const DELETE_PRICE_LIST = gql`
  mutation DELETE_PRICE_LIST($id: ID!) {
    deletePriceList(id: $id) {
      result
    }
  }
`;

const DeletePriceListView = ({ priceListId, complete, cancel }) => {
  const [deletePriceList, deletePriceListRes] = useMutation(DELETE_PRICE_LIST, {
    variables: { id: priceListId },
    onCompleted() {
      Alert("success", "Price List Deleted.");
      complete();
    },
    onError(error) {
      Alert("error", error.message);
    },
    refetchQueries: [{ query: FETCH_PRICE_LISTS }],
  });

  return (
    <div className="text-base">
      <div>Are you sure to delete this Price List?</div>

      <hr />

      <div className="flex space-x-8">
        <CharlesButton danger onClick={deletePriceList} loading={deletePriceListRes.loading}>
          Yes, Delete it.
        </CharlesButton>
        <CharlesButton onClick={cancel}>No, Keep it.</CharlesButton>
      </div>
    </div>
  );
};

export default DeletePriceListView;
