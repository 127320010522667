import React, { createContext } from "react";
import { useMutation } from "@apollo/client";
import Issue from "./Issue";
import IssueForm from "./IssueForm";
import MetaForm from "./MetaForm";
import Printing from "./Printing";
import Summary from "./Summary";
import { SAVE_SIMPLE_REPORT } from "../graphql";
import BarcodesView from "./BarcodesView";
import { useModals } from "ModalProvider";
import CharlesButton from "components/charles/base";

export const QCReportContext = createContext({});

function FinalQcReportEditForm({ report, metaCategories }) {
  const [saveSimpleQcReport, { loading }] = useMutation(SAVE_SIMPLE_REPORT);

  const modal = useModals();

  function showIssueForm() {
    modal.present({
      title: "Add Issue 添加缺陷",
      children: <IssueForm reportId={report.id} hide={modal.hide} />,
    });
  }

  return (
    <QCReportContext.Provider value={{ report, metaCategories }}>
      {/* Meta Categories */}
      <div className="my-6">
        <div className="px-4 lg:px-6 text-gray-500">上传图片</div>
        <div>
          {metaCategories.map((cat) => (
            <div className="border bg-white dark:bg-gray-800 border-gray-200 dark:border-gray-700 p-4 lg:px-6 mt-2" key={cat.id}>
              <div>{cat.name}</div>
              {cat.metas.map((catMeta, index) => (
                <MetaForm key={index} meta={catMeta} />
              ))}
            </div>
          ))}
        </div>
      </div>

      <BarcodesView />

      {/* Issues */}
      <div className="my-6">
        <div className="px-4 lg:px-6 mb-2 text-gray-500">Quality Check 质量检验 (检验数: {report.checkQty})</div>
        <div className="px-4 md:p-6 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 space-y-4">
          {report.qualityIssues.length > 0 && (
            <div>
              {report.qualityIssues.map((issue) => (
                <Issue key={issue.id} issue={issue} />
              ))}
            </div>
          )}

          <CharlesButton onClick={showIssueForm}>+ Add Issue 添加缺陷</CharlesButton>
        </div>
      </div>

      <Printing reportId={report.id} printings={report.printings} />

      <Summary
        reportId={report.id}
        packageCheck={report.packageCheck}
        qualityCheck={report.qualityCheck}
        logoCheck={report.logoCheck}
        status={report.status}
        saveSimpleQcReport={saveSimpleQcReport}
        loading={loading}
      />
    </QCReportContext.Provider>
  );
}

export default FinalQcReportEditForm;
