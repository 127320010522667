import React from 'react';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import Card from '../../../../components/Card';
import Spinner from '../../../../components/Spinner';
import Errors from '../../../../components/Errors';

const FETCH_COMPANY_OHS_USAGE = gql`
   query($start: DateTime!, $end: DateTime!) {
      companyOhsUsage(start: $start, end: $end) {
         company
         times
      }
   }
`;

function OhsUsage({ start, end }) {
   const { loading, error, data } = useQuery(FETCH_COMPANY_OHS_USAGE, {
      variables: { start, end },
   });
   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;

   const sortedCompanyUsage = [...data.companyOhsUsage].sort(
      (a, b) => b.times - a.times
   );

   return (
      <Card title="OHS Company Usage">
         <div className="overflow-auto scrolling-touch">
            <table>
               <thead className="bg-gray-200">
                  <tr>
                     <th className="p-2 text-left">Company</th>
                     <th className="p-2 text-right">Times</th>
                  </tr>
               </thead>
               <tbody>
                  {sortedCompanyUsage.map((node, index) => (
                     <tr className="" key={index}>
                        <td className="text-left p-2 border-b border-gray-100">
                           {node.company}
                        </td>
                        <td className="text-right p-2 border-b border-gray-100">
                           {node.times}
                        </td>
                     </tr>
                  ))}
               </tbody>
            </table>
         </div>
      </Card>
   );
}

export default OhsUsage;
