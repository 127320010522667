import { gql, useQuery } from '@apollo/client';
import { Tag } from './base';
import Errors from './Errors';
import { InlineSpinner } from './Spinner';

const FETCH_DATA = gql`
   query FETCH_ALL_PRODUCT_AREAS {
      allProductAreas {
         id
         name
      }
   }
`;

const ProductAreasSelector = ({ value, onChange }) => {
   const { loading, error, data } = useQuery(FETCH_DATA);
   if (loading) return <InlineSpinner />;
   if (error) return <Errors error={error} />;

   return (
      <div className="flex flex-wrap">
         {data.allProductAreas.map(({ id, name }) => (
            <Tag
               selected={value.includes(id)}
               className="mr-2 mb-2"
               title={name}
               key={id}
               onClick={() =>
                  onChange(() =>
                     value.includes(id)
                        ? value.filter((i) => i !== id)
                        : [...value, id]
                  )
               }
            />
         ))}
      </div>
   );
};

export default ProductAreasSelector;
