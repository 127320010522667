import { FileSelector } from "components/Form";
import ImageLoader from "components/Image";
import CharlesButton from "components/charles/base";
import { IoIosCloseCircle } from "react-icons/io";

const Images = ({ title, data, type, buttonText, newType, removeImage, removeNewImage, onAddNewImages }) => (
  <div>
    {title ? <h5 className="text-gray-700 dark:text-gray-200 mb-6">{title}</h5> : null}
    <div className="flex flex-1 items-start justify-start">
      <div className="flex flex-1 flex-wrap items-start">
        {data[type].map((i, index) => (
          <div className="relative mr-2 mb-2" key={index}>
            <ImageLoader size="w-40 h-32" bgStyle="bg-center bg-contain bg-no-repeat" src={i.thumb} originalSrc={i.url} />
            <div className="absolute top-0 left-0 p-2 ">
              <CharlesButton className="text-2xl" danger onClick={(_) => removeImage(i.id, type)}>
                <IoIosCloseCircle />
              </CharlesButton>
            </div>
          </div>
        ))}
        {data[newType].map((i, index) => (
          <div className="relative mr-2 mb-2" key={index}>
            <ImageLoader size="w-40 h-32" bgStyle="bg-center bg-contain bg-no-repeat" src={i.url} />
            <div className="absolute top-0 left-0 p-2">
              <CharlesButton className="text-2xl" danger onClick={(_) => removeNewImage(index, newType)}>
                <IoIosCloseCircle />
              </CharlesButton>
            </div>
          </div>
        ))}
      </div>
    </div>
    <div className="flex">
      <FileSelector accept="images/*" title={buttonText} onChange={(e) => onAddNewImages(e, newType)} />
    </div>
  </div>
);

export default Images;
