import React, { useState } from "react";
import { useParams } from "react-router-dom";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/client";
import Spinner from "../../../../components/Spinner";
import Errors from "../../../../components/Errors";
import { Button } from "../../../../components/base";
import BackButton from "../../../../components/BackButton";
import { NavigationBar } from "../../../../components/PageContainer";
import { Alert } from "../../../../components/Toast";
import track, { actions } from "../../../../utils/track";

const FETCH_PRODUCTLINE = gql`
  query FETCH_PRODUCTLINE($slug: String!) {
    productLine(slug: $slug) {
      id
      slug
      promoImages {
        id
        name
        thumb: url(size: "200x200")
      }
    }
    allPromoImages(collectionName: "2024 Lifestyle Images") {
      id
      name
      thumb: url(size: "200x200")
      url
    }
  }
`;

const UPDATE_PRODUCT_LINE_PROMO_MATERIALS = gql`
  mutation UPDATE_PRODUCT_LINE_PROMO_MATERIALS($id: ID!, $lifestyleImages: [ID]) {
    updateProductLinePromoMaterials(id: $id, lifestyleImages: $lifestyleImages) {
      productLine {
        id
        name
        promoImages {
          id
          name
          thumb: url(size: "200x200")
        }
      }
    }
  }
`;

function ProductLineAsscociateLifeStyle() {
  const { slug } = useParams();
  const { loading, error, data } = useQuery(FETCH_PRODUCTLINE, {
    variables: { slug },
  });

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;
  const { productLine, allPromoImages } = data;

  return <ProductLineAsscociateLifeStyleForm productLine={productLine} allPromoImages={allPromoImages} />;
}

function ProductLineAsscociateLifeStyleForm({ productLine, allPromoImages }) {
  const [lifestyleImages, setLifestyleImages] = useState(productLine.promoImages);

  const [updateProductLinePromoMaterials, updateProductLinePromoMaterialsRes] = useMutation(UPDATE_PRODUCT_LINE_PROMO_MATERIALS, {
    onCompleted: (res) => {
      Alert("success", "Updated.");
    },
    onError: (err) => {
      Alert("error", err.message);
    },
  });

  function clickProductImage(image) {
    setLifestyleImages((prev) => prev.filter((i) => i.id !== image.id));
  }

  function clickLibImage(image) {
    setLifestyleImages((prev) => [image, ...prev]);
  }

  return (
    <div className="flex-1 flex flex-col h-full">
      <NavigationBar
        barStyle="light"
        title="Associate Images"
        backButton={<BackButton color="dark" to={`/products/collection-view/${productLine.slug}`} />}
        rightButtons={
          <Button
            loading={updateProductLinePromoMaterialsRes.LazyLoad}
            disabled={updateProductLinePromoMaterialsRes.loading}
            title={updateProductLinePromoMaterialsRes.loading ? "Saving..." : "Save"}
            onClick={() => {
              updateProductLinePromoMaterials({
                variables: {
                  id: productLine.id,
                  lifestyleImages: lifestyleImages.map((i) => i.id),
                },
              });
              track(actions.product.associateLifestyleImages.name, productLine.name);
            }}
          ></Button>
        }
      />
      <div className="flex-1 flex overflow-auto">
        <div className="flex flex-1 flex-col h-full border-r border-gray-200">
          <div className="p-4 py-2 bg-gray-200 text-xs font-bold text-gray-600">Associated Images</div>
          <div className="flex-1 flex content-start flex-wrap h-full overflow-auto">
            {lifestyleImages.map((promoImage, index) => (
              <div key={index} onClick={() => clickProductImage(promoImage)} className="cursor-pointer w-1/2 lg:w-1/3 xl:w-1/4 relative">
                <div
                  className="w-full bg-center bg-cover bg-no-repeat bg-gray-200 border"
                  style={{
                    paddingBottom: "100%",
                    backgroundImage: `url(${promoImage.thumb})`,
                  }}
                ></div>
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-1 flex-col h-full">
          <div className="p-4 py-2 bg-gray-200 text-xs font-bold text-gray-600">Lifestyle Images Libray</div>
          <div className="flex-1 flex content-start flex-wrap h-full overflow-auto">
            {allPromoImages
              .filter((i) => lifestyleImages.findIndex((j) => j.id === i.id) === -1)
              .map((promoImage, index) => (
                <div key={index} onClick={() => clickLibImage(promoImage)} className="cursor-pointer w-1/2 lg:w-1/3 xl:w-1/4 relative">
                  <div
                    className="w-full bg-center bg-cover bg-no-repeat bg-gray-200 border"
                    style={{
                      paddingBottom: "100%",
                      backgroundImage: `url(${promoImage.thumb})`,
                    }}
                  ></div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductLineAsscociateLifeStyle;
