import CharlesButton from "components/charles/base";
import { FileSelector } from "../../components/Form";
import { download } from "../../utils/http";
import { getNameFromUrl } from "../../utils/string";
import { BsTrash } from "react-icons/bs";

const FileUploader = ({ data, setData }) => {
  function removeAttachment(indexToRemove, field) {
    setData({
      ...data,
      [field]: data[field].filter((_, index) => index !== indexToRemove),
    });
  }

  return (
    <div className="mt-6">
      <h5>Price Documents</h5>
      <div className="flex flex-1 items-start justify-start mt-2">
        <div className="flex-1">
          {data.attachments.map((i, index) => (
            <AttachmentRow url={i.url} previewUrl={i.previewUrl} index={index} key={index} removeAttachment={(_) => removeAttachment(index, "attachments")} />
          ))}
          {data.newAttachments.map((i, index) => (
            <AttachmentRow url={i.url} name={i.file.name} index={index} key={index} removeAttachment={() => removeAttachment(index, "newAttachments")} />
          ))}
        </div>
      </div>
      <FileSelector
        className="mt-4"
        title="Upload Files"
        onChange={(e) => {
          let files = [];
          const newAttachments = [];
          for (const file of e.target.files) {
            files.push(file);
          }
          e.target.value = null;
          for (const file of files) {
            newAttachments.push({
              file,
              type: "price",
            });
          }
          setData({
            ...data,
            newAttachments: [...data.newAttachments, ...newAttachments],
          });
        }}
      />
    </div>
  );
};

const AttachmentRow = ({ name, url, index, removeAttachment }) => {
  const filename = url ? getNameFromUrl(url) : name;

  return (
    <div className="flex items-center py-3 space-x-2 border-b border-gray-100 dark:border-gray-800">
      <CharlesButton danger onClick={() => removeAttachment(index)}>
        <BsTrash />
      </CharlesButton>
      <CharlesButton onClick={() => download(url, filename)}>{filename}</CharlesButton>
    </div>
  );
};

export default FileUploader;
