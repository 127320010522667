import { useMutation } from '@apollo/client';
import { Button } from 'components/base';
import { Input } from 'components/Form';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DUPLICATE_AMAZON_STOCK_CATEGORY } from '../graphql';
import { formatDate } from 'react-day-picker/moment';
import { Alert } from 'components/Toast';

const StockCategoryForm = ({ fromStockCategory, hide }) => {
   const [name, setName] = useState(formatDate(new Date(), 'YYYY-MM'));
   const [regions, setRegions] = useState(fromStockCategory.regions);

   const navigate = useNavigate();
   const [duplicate, { loading }] = useMutation(
      DUPLICATE_AMAZON_STOCK_CATEGORY,
      {
         onCompleted(res) {
            navigate(
               `/amazon/stocks/${res.amzDuplicateStockCategory.amazonStockCategory.id}`
            );
            hide();
         },
         onError(error) {
            Alert('error', error.message);
         },
         refetchQueries: ['FETCH_AMAZON_STOCK_CATEGORIES'],
      }
   );

   return (
      <div className="space-y-4">
         <div className="flex space-x-4 items-center">
            <label className="w-24">Name: </label>
            <Input
               className="flex-1"
               value={name}
               onChange={(e) => setName(e.target.value)}
            />
         </div>

         <div>
            <div className="flex space-x-4 items-baseline">
               <label className="w-24">regions: </label>
               <div className="flex-1">
                  <Input
                     className="w-full"
                     value={regions}
                     onChange={(e) => setRegions(e.target.value)}
                  />
                  <div className="mt-1 ml-2 text-xs opacity-50">
                     Valid values are GB, DE, IT, ES and FR. Separate by comma.
                  </div>
               </div>
            </div>
         </div>

         <div className="pt-4">
            <Button
               title="Save"
               bold
               size="xl"
               loading={loading}
               disabled={loading}
               onClick={() =>
                  duplicate({
                     variables: {
                        name,
                        fromCategoryId: fromStockCategory.id,
                        regions,
                     },
                  })
               }
            />
         </div>
      </div>
   );
};

export default StockCategoryForm;
