import React, { useState } from "react";
import { useMutation, gql } from "@apollo/client";
import { FileSelector } from "components/Form";
import { Button } from "components/base";
import Icon from "components/Icon";
import QiniuUploader from "components/QiniuUploader";
import { QC_REPORT_FRAGMENT } from "../graphql";
import { Alert } from "components/Toast";

const SAVE_QC_PRINTING = gql`
  mutation SAVE_QC_PRINTING($reportId: ID, $printingId: ID, $isValid: Boolean!, $newImages: [String], $deleteImages: [ID]) {
    saveQcPrinting(reportId: $reportId, printingId: $printingId, isValid: $isValid, newImages: $newImages, deleteImages: $deleteImages) {
      qcReport {
        ...qcReport
      }
    }
  }
  ${QC_REPORT_FRAGMENT}
`;

const DELETE_QC_PRINTING = gql`
  mutation DELETE_QC_PRINTING($id: ID!) {
    deleteQcPrinting(id: $id) {
      qcReport {
        ...qcReport
      }
    }
  }
  ${QC_REPORT_FRAGMENT}
`;

function PrintingImage({ index, src, file, filename, preview, onCreated, onDeleted }) {
  if (src)
    return (
      <div className="mt-4 mr-4 relative">
        <a href={src} target="_blank" title="Open origin image" rel="noopener noreferrer">
          <img className="w-full lg:w-auto lg:h-32 bg-gray-700" src={`${src}?imageView2/2/w/600`} alt="meta" style={{ minWidth: 100 }} />
        </a>
        <div
          className="absolute z-10 cursor-pointer top-0 p-2 text-red-600 hover:text-red-700 active:text-red-800"
          onClick={(_) => {
            if (onDeleted && window.confirm("确认删除图片?")) onDeleted(index);
          }}
        >
          <Icon icon="close-solid" />
        </div>
      </div>
    );
  return (
    <div className="flex items-center justify-center mt-4 mr-4 w-32 h-32 bg-gray-200">
      <QiniuUploader preview={preview} file={file} filename={filename} onUploaded={(url) => onCreated(index, url)} />
    </div>
  );
}

function PrintingForm({ initialPrinting, reportId, onCancel }) {
  const [isValid, setIsValid] = useState(initialPrinting ? initialPrinting.isValid : true);
  const [images, setImages] = useState(initialPrinting ? initialPrinting.images : []);

  const [savePrinting, savePrintingRes] = useMutation(SAVE_QC_PRINTING, {
    onCompleted: () => {
      Alert("success", "Saved.");
      onCancel();
    },
    onError: (error) => Alert("error", error.message),
  });

  function onCreated(index, url) {
    setImages((prev) =>
      prev.map((image, imageIndex) => {
        if (imageIndex !== index) return image;
        return {
          ...image,
          src: url,
          thumb: image.preview,
        };
      })
    );
  }

  function onDeleted(index) {
    setImages((prev) => prev.filter((_, imageIndex) => imageIndex !== index));
  }

  const title = initialPrinting ? "Edit Sample 编辑样板" : "Create Sample 添加样板";
  const isUploadingImages = images.filter((i) => i.src === null).length > 0;

  console.log(
    "new images",
    images.filter((i) => i.file)
  );

  return (
    <div
      className="p-4 border rounded-lg border-gray-200 dark:border-gray-700
       bg-gray-100 dark:bg-gray-900"
    >
      <h4>{title}</h4>
      <div className="mt-4 lg:mt-6">
        <div className="mt-4 lg:flex items-center">
          <label htmlFor="">是否符合要求: </label>
          <div className="flex items-center mt-2 lg:mt-0 lg:ml-4">
            <Button title="Valid 符合" border size="sm" color={isValid === true ? "blue" : ""} className="px-4 mr-4" onClick={(_) => setIsValid(true)} />
            <Button title="Invalid 不符合" border size="sm" color={isValid === false ? "blue" : ""} className="px-4 mr-4" onClick={(_) => setIsValid(false)} />
          </div>
        </div>

        <div className="lg:flex lg:items-center lg:flex-wrap">
          {images.map((i, index) => (
            <PrintingImage
              key={index}
              index={index}
              src={i.src}
              preview={i.preview}
              file={i.file}
              filename={i.filename}
              onCreated={onCreated}
              onDeleted={onDeleted}
            />
          ))}
        </div>
        <FileSelector
          accept="image/*"
          className="mt-4"
          title="add images 添加图片"
          onChange={(e) => {
            const files = [...e.target.files];
            setImages((prev) => [
              ...prev,
              ...files.map((file) => ({
                src: null,
                file,
                filename: `printingImages/${file.name}`,
                preview: URL.createObjectURL(file),
              })),
            ]);
            e.target.value = null;
          }}
        />

        <div className="mt-4 flex">
          <Button
            title={isUploadingImages ? "uploading images..." : savePrintingRes.loading ? "saving..." : "save"}
            border
            color="blue"
            disabled={isUploadingImages || savePrintingRes.loading}
            loading={isUploadingImages || savePrintingRes.loading}
            onClick={(_) => {
              const updatedImageIds = images.filter((i) => i.id !== null).map((i) => i.id);
              const deleteImages = initialPrinting ? initialPrinting.images.filter((i) => updatedImageIds.indexOf(i.id) === -1).map((i) => i.id) : [];
              savePrinting({
                variables: {
                  reportId,
                  printingId: initialPrinting ? initialPrinting.id : null,
                  isValid,
                  newImages: images.filter((i) => i.file).map((i) => i.src),
                  deleteImages,
                },
              });
            }}
          />
          <Button title="cancel" border onClick={onCancel} className="ml-4" />
        </div>
      </div>
    </div>
  );
}

function PrintingItem({ printing, index }) {
  const [editing, setEditing] = useState(false);
  const [deleteQcPrinting] = useMutation(DELETE_QC_PRINTING, {
    variables: { id: printing.id },
  });
  return (
    <div>
      {editing ? (
        <div className="mt-4">
          <PrintingForm initialPrinting={printing} onCancel={(_) => setEditing(false)} />
        </div>
      ) : (
        <div className="lg:flex justify-between items-start border-b border-gray-200 dark:border-gray-700 py-2">
          <div>
            <div>Sample 样板 {index + 1}</div>
            <div className={`mt-2 font-bold ${printing.isValid ? "text-green-600" : "text-red-600"}`}>{printing.isValid ? "符合" : "不符合"}</div>
            <div className="lg:flex lg:flex-wrap">
              {printing.images.map((image) => (
                <div className="mt-4 lg:mr-4" key={image.id}>
                  <a href={image.src} target="_blank" title="Open origin image" rel="noopener noreferrer">
                    <img className="w-full lg:w-auto lg:h-32 bg-gray-700" src={`${image.src}?imageView2/2/w/600`} alt="printing" style={{ minWidth: 100 }} />
                  </a>
                </div>
              ))}
            </div>
          </div>
          <div className="flex mt-2">
            <Button size="sm" title="edit" onClick={(_) => setEditing(true)} />
            <Button
              className="ml-4"
              size="sm"
              color="red"
              title="delete"
              onClick={(_) => {
                if (window.confirm("确认删除缺陷？")) deleteQcPrinting();
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

function Printing({ reportId, printings }) {
  const [addingSample, setAddingSample] = useState(false);

  return (
    <div className="py-6">
      <div className="px-4 lg:px-6 mb-2 text-base text-gray-500">移印Logo牢度 Pad Printing Fastness 样板</div>
      <div className="bg-white dark:bg-gray-800 px-4 lg:p-6 lg:py-2 border border-gray-200 dark:border-gray-700">
        {printings.map((i, index) => (
          <PrintingItem key={i.id} printing={i} index={index} />
        ))}
        {addingSample ? (
          <div className="py-4">
            <PrintingForm reportId={reportId} onCancel={(_) => setAddingSample(false)} />
          </div>
        ) : (
          <Button size="sm" className="my-4" title="add sample 添加样板" onClick={(_) => setAddingSample(true)} />
        )}
      </div>
    </div>
  );
}

export default Printing;
