import { Switcher } from "components/Form";
import { useContext } from "react";
import ShipmentContext from "./ShipmentContext";
import { useMutation, gql } from "@apollo/client";
import { Alert } from "components/Toast";
import { InlineSpinner } from "components/Spinner";

const UPDATE_SHIPMENT_TRACK_LEADTIME = gql`
  mutation UPDATE_SHIPMENT_TRACK_LEADTIME($shipmentInput: ShipmentInput!) {
    updateShipment(shipmentInput: $shipmentInput) {
      shipment {
        id
        trackLeadtime
      }
    }
  }
`;

const ShipmentLeadtimeTracker = () => {
  const {
    state: { id, trackLeadtime },
    dispatch,
  } = useContext(ShipmentContext);

  const [update, { loading }] = useMutation(UPDATE_SHIPMENT_TRACK_LEADTIME, {
    onCompleted: () => {
      Alert("success", "Updated.");
      dispatch({
        type: "changeShipment",
        payload: { trackLeadtime: !trackLeadtime },
      });
    },
    onError: (error) => Alert("error", error.message),
  });

  return (
    <div className="flex items-center space-x-4">
      <label htmlFor="">Track Leadtime: </label>
      <Switcher
        isOn={trackLeadtime}
        onChange={() =>
          update({
            variables: {
              shipmentInput: { id, trackLeadtime: !trackLeadtime },
            },
          })
        }
      />
      {loading ? <InlineSpinner size={16} text={null} /> : null}
    </div>
  );
};

export default ShipmentLeadtimeTracker;
