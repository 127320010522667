import React, { useState } from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import ProductDetail from './Detail';
import { useQuery } from '@apollo/client';
import { FETCH_ACTIVE_PRODUCT_LINES } from 'graphql/query';
import Spinner from 'components/Spinner';
import Errors from 'components/Errors';
import { NavigationLink } from 'components/base';
import { byName } from 'utils/sort';
import SearchBar from 'components/SearchBar';
import { searchByProp } from 'utils/search';
import ImageLoader from 'components/Image';

const Products = () => (
   <Routes>
      <Route element={<LayoutWithProductLines />}>
         <Route
            index
            element={
               <div className="flex items-center justify-center h-full">
                  <label className="text-xl">
                     Select a Product to view the cost.
                  </label>
               </div>
            }
         />
         <Route path=":id" element={<ProductDetail />} />
      </Route>
   </Routes>
);

export function LayoutWithProductLines() {
   const { loading, error, data } = useQuery(FETCH_ACTIVE_PRODUCT_LINES);
   const [searchText, setSearchText] = useState('');

   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;

   const lines = data.productLines.results
      .filter((i) =>
         searchByProp(i, ['name', 'productsSearchString'], searchText)
      )
      .sort(byName);

   return (
      <div className="flex flex-1 h-full">
         <div className="h-full flex-shrink-0 overflow-auto border-r dark:border-gray-700 w-80 relative">
            <div className="px-6 py-3 border-b dark:border-gray-700 sticky top-0 bg-gray-200 dark:bg-gray-900 bg-opacity-70 dark:bg-opacity-60 backdrop-filter backdrop-blur-xl z-10">
               <SearchBar
                  placeholder="Search by name or item number.."
                  value={searchText}
                  onChange={setSearchText}
               />
            </div>
            <div>
               {lines.map((line) => (
                  <NavigationLink
                     key={line.id}
                     className="flex items-center space-x-2 px-6 py-2 border-b dark:border-gray-700"
                     to={line.id}
                  >
                     <ImageLoader size="w-6 h-6" src={line.mainImage} />
                     <span>{line.name}</span>
                  </NavigationLink>
               ))}
            </div>
         </div>
         <div className="relative flex-1 overflow-auto">
            <Outlet />
         </div>
      </div>
   );
}

export default Products;
