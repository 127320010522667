import { gql, useQuery } from '@apollo/client';
import { InlineSpinner } from 'components/Spinner';
import Errors from 'components/Errors';
import { FixedSizeList } from 'react-window';
import { searchByProp } from 'utils/search';
import { Input } from 'components/Form';
import odooIcon from 'assets/odoo-icon.svg';
import { useState } from 'react';

export const FETCH_ALL_PRODUCTS = gql`
   query FETCH_ALL_PRODUCTS($isSalable: Boolean) {
      products(
         productType: "normal"
         isActive: true
         isSalable: $isSalable
         useCache: false
      ) {
         results {
            id
            odooId
            name
            number
         }
      }
   }
`;

const ProductSelector = ({ onSelect, className = '', variables = {} }) => {
   const { loading, error, data } = useQuery(FETCH_ALL_PRODUCTS, { variables });
   const [searchText, setSearchText] = useState('');
   const [showOptions, setShowOptions] = useState(false);

   if (loading)
      return (
         <div>
            <InlineSpinner text={null} size={16} />
         </div>
      );
   if (error) return <Errors error={error} />;

   const optionsToShow = data.products.results.filter((i) =>
      searchByProp(i, ['name', 'number'], searchText)
   );

   function render({ data, index, style }) {
      const option = data[index];

      return (
         <div
            className="cursor-pointer border-b dark:border-gray-700 px-4 hover:bg-gray-200 dark:hover:bg-gray-700 w-full h-full flex items-center"
            key={index}
            onClick={() => onSelect(option)}
            style={style}
         >
            <div className="flex items-center">
               {option.odooId ? (
                  <img
                     className="mr-2"
                     style={{ height: 14 }}
                     src={odooIcon}
                     alt="odoo product"
                  />
               ) : null}
               [ {option.number} ] {option.name}
            </div>
         </div>
      );
   }

   return (
      <div
         className={`
            ${showOptions ? 'z-10' : 'z-0'} w-full relative ${className}`}
      >
         <div
            className={`fixed inset-0
                ${showOptions ? ' visible' : ' invisible'}
                `}
            onClick={() => setShowOptions(false)}
         ></div>
         <Input
            className="w-full relative z-10"
            onChange={(e) => setSearchText(e.target.value)}
            value={searchText}
            onFocus={() => setShowOptions(true)}
         />

         <div
            className={`absolute w-full rounded overflow-auto z-20 shadow-lg bg-white dark:bg-gray-800
                ${showOptions ? ' visible' : ' invisible'}
            `}
         >
            <FixedSizeList
               height={300}
               itemSize={36}
               width="100%"
               itemData={optionsToShow}
               itemCount={optionsToShow.length}
            >
               {render}
            </FixedSizeList>
         </div>
      </div>
   );
};

export default ProductSelector;
