export const CURRENCIES = [
  { code: "USD", symbol: "$", saying: "US DOLLARS", centsName: "CENTS" },
  { code: "EUR", symbol: "€", saying: "EUROS", centsName: "CENTS" },
  { code: "SEK", symbol: "kr", saying: "SWEDISH KRONA", centsName: "CENTS" },
  { code: "RMB", symbol: "¥", saying: "CHINESE YUAN", centsName: "CENTS" },
  { code: "CNY", symbol: "¥", saying: "CHINESE YUAN", centsName: "CENTS" },
];

export const CURRENCY_DICT = {
  USD: {
    code: "USD",
    symbol: "$",
    saying: "US DOLLARS",
    centsName: "CENTS",
  },
  EUR: {
    code: "EUR",
    symbol: "€",
    saying: "EUROS",
    centsName: "CENTS",
  },
  SEK: {
    code: "SEK",
    symbol: "kr",
    saying: "SWEDISH KRONA",
    centsName: "CENTS",
  },
  RMB: {
    code: "RMB",
    symbol: "¥",
    saying: "CHINESE YUAN",
    centsName: "CENTS",
  },
  CNY: {
    code: "CNY",
    symbol: "¥",
    saying: "CHINESE YUAN",
    centsName: "CENTS",
  },
};
