import { useMutation } from "@apollo/client";
import { InlineSpinner } from "components/Spinner";
import Errors from "components/Errors";
import { Alert } from "components/Toast";
import odooIcon from "assets/odoo-icon.svg";
import { FETCH_PRODUCT_ODOO_PRICE, useOdooSupplierInfo } from "hooks/useOdooSuppliers";
import { SYNC_PRODUCT_PRICES_TO_ODOO } from "pages/manage/products/odoo/ComputedPriceSyncer";
import CharlesButton from "components/charles/base";

const ProductOdooPrices = ({ productId, productOdooId, computed }) => {
  const { loading, error, data } = useOdooSupplierInfo(productOdooId);

  const [syncProductPricesToOdoo, syncProductPricesToOdooRes] = useMutation(SYNC_PRODUCT_PRICES_TO_ODOO, {
    variables: { id: productId },
    onCompleted: () => Alert("success", "Cost Synced."),
    onError: (error) => Alert("error", error.message),
    refetchQueries: [
      {
        query: FETCH_PRODUCT_ODOO_PRICE,
        variables: { productId: productOdooId },
      },
      "FETCH_FACTORY_DETAIL",
    ],
    awaitRefetchQueries: true,
  });

  const [syncComputedPrice, syncComputedPriceRes] = useMutation(SYNC_PRODUCT_PRICES_TO_ODOO, {
    variables: { id: productId, computed: true },
    onCompleted: () => Alert("success", "Cost Synced."),
    onError: (error) => Alert("error", error.message),
    refetchQueries: [
      {
        query: FETCH_PRODUCT_ODOO_PRICE,
        variables: { productId: productOdooId },
      },
      "FETCH_FACTORY_DETAIL",
    ],
    awaitRefetchQueries: true,
  });

  if (loading)
    return (
      <div className="px-4 flex items-center relative space-x-4">
        <InlineSpinner size={16} text={null} />
        <span className=" opacity-70">Loading cost from Odoo...</span>
      </div>
    );
  if (error) return <Errors error={error} />;

  function syncHandler() {
    if (computed) {
      syncComputedPrice();
    } else {
      syncProductPricesToOdoo();
    }
  }

  return (
    <div className="bg-white dark:bg-gray-900 dark:bg-opacity-50 p-6 rounded-xl">
      <div className="flex items-center">
        <img className="mr-2" style={{ height: 16 }} src={odooIcon} alt="odoo product" />
        <h4>Price in Odoo</h4>
      </div>

      {data.odooSupplierInfo.length > 0 ? (
        <table className="mt-4">
          <thead>
            <tr>
              <th className="border dark:border-gray-700 px-4 text-left">Partner</th>
              <th className="border dark:border-gray-700 px-4 text-right">Qty</th>
              <th className="border dark:border-gray-700 px-4 text-right">Price</th>
            </tr>
          </thead>
          <tbody>
            {data.odooSupplierInfo.map((i, index) => (
              <tr key={index}>
                <td className="border dark:border-gray-700 px-4">{i.partnerName}</td>
                <td className="border dark:border-gray-700 px-4 text-right">{i.minQty}</td>
                <td className="border dark:border-gray-700 px-4 text-right">
                  {i.currency} {i.price}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="mt-2">
          <label className="text-pink-600">This product has not prices in Odoo.</label>
        </div>
      )}

      <div className="mt-6">
        <CharlesButton
          onClick={syncHandler}
          loading={syncProductPricesToOdooRes.loading || syncComputedPriceRes.loading}
          disabled={syncProductPricesToOdooRes.loading || syncComputedPriceRes.loading}
        >
          Sync WIS Cost to Odoo
        </CharlesButton>
      </div>
    </div>
  );
};

export default ProductOdooPrices;
