import { gql, useQuery } from "@apollo/client";
import Errors from "components/Errors";
import Spinner from "components/Spinner";
import { createContext } from "react";

export const CurrencyContext = createContext({});

const FETCH_CURRENCY = gql`
  query FETCH_CURRENCY {
    latestExchangeRate {
      id
      rate
      supplierUsdToRmbRate
    }
  }
`;

const CurrencyProvider = ({ children }) => {
  const { loading, error, data } = useQuery(FETCH_CURRENCY);

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  return (
    <CurrencyContext.Provider
      value={{
        rmbToUsdRate: 1.0 / data.latestExchangeRate.rate,
        latestExchangeRate: data.latestExchangeRate,
      }}
    >
      {children}
    </CurrencyContext.Provider>
  );
};

export default CurrencyProvider;
