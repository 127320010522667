import { gql, useQuery } from '@apollo/client';
import Errors from 'components/Errors';
import Spinner from 'components/Spinner';
import { useParams } from 'react-router-dom';
import SubSupplierPaymentForm from './Form';

const FETCH_SUB_SUPPLIER_PAYMENT_DETAIL = gql`
   query FETCH_SUB_SUPPLIER_PAYMENT_DETAIL($id: ID!) {
      subSupplierPayment(id: $id) {
         id
         supplier {
            id
         }
         lines {
            id
            invoice {
               id
               total
            }
            amount
         }
         statements {
            id
            name
            url
         }
         remark
      }
   }
`;

const SubSupplierPaymentDetail = () => {
   const { id } = useParams();
   const { loading, error, data } = useQuery(
      FETCH_SUB_SUPPLIER_PAYMENT_DETAIL,
      { variables: { id } }
   );

   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;

   return <SubSupplierPaymentForm originalPayment={data.subSupplierPayment} />;
};

export default SubSupplierPaymentDetail;
