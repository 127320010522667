import React from "react";
import { NavigationLink } from "components/base";

export const LeftButtons = () => {
  return (
    <div className="flex space-x-4">
      <NavigationLink to="/stock/odoo" title="Odoo" />
      <NavigationLink to="/stock/forecasted" title="Forecasted" />
      <NavigationLink to="/stock/warehouses" title="Warehouses" />
      <NavigationLink to="/stock/supplier" title="Suppliers" />
      <NavigationLink to="/stock/odoo/search" title="Import from Odoo" />
      <NavigationLink to="/stock/alc" title="ALC" />
      <NavigationLink to="/stock/charles-kendall" title="Charles Kendall" />
    </div>
  );
};

export const RightButtons = () => (
  <div className="flex">
    <NavigationLink to="/stock/odoo/search" title="Import from Odoo" />
  </div>
);
