import React, { useState } from 'react';
import Card from '../../../components/Card';
import ShipmentTitle from '../../../components/ShipmentTitle';
import { Button } from '../../../components/base';
import ShipmentStatus from '../../../components/ShipmentStatus.js';

function OrderStatus({ selectedStatus = [], shipments }) {
   const [showOrders, setShowOrders] = useState(false);
   return (
      <Card title="Latest Status">
         <Button
            className="absolute right-0 top-0 p-6"
            title={showOrders ? 'Hide Orders' : 'Show Orders'}
            size="sm"
            onClick={(_) => setShowOrders((prev) => !prev)}
         ></Button>
         {selectedStatus.map((i, index) => {
            const shipmentsForCurrenntStatus = shipments.filter(
               (s) => s.latestStatus === i
            );
            return (
               <div
                  key={index}
                  className={`
                     animate-ease-1 mt-4
                     ${showOrders ? 'p-4 rounded-xl bg-gray-100' : ''}
                  `}
               >
                  <div className="flex items-center">
                     <div className="w-24">
                        <span className="text-gray-700 font-bold">
                           {shipmentsForCurrenntStatus.length}
                        </span>{' '}
                        orders
                     </div>
                     <ShipmentStatus className="ml-4 text-xs" status={i} />
                  </div>
                  {showOrders && shipmentsForCurrenntStatus.length > 0 && (
                     <div className="mt-4">
                        {shipmentsForCurrenntStatus.map((shipment, index) => (
                           <div key={index} className="py-2 border-t">
                              <ShipmentTitle
                                 link={`/shipment/shipments/${shipment.id}`}
                                 target="_blank"
                                 customerName={shipment.customer.name}
                                 number={shipment.invoiceNumber}
                                 po={shipment.po}
                              />
                           </div>
                        ))}
                     </div>
                  )}
               </div>
            );
         })}
         <div className="font-bold mt-6">Total {shipments.length} Orders</div>
      </Card>
   );
}

export default OrderStatus;
