import { CurrencyContext } from 'CurrencyProvider';
import { useContext } from 'react';
import { BsArrowRight } from 'react-icons/bs';
import { SiGooglecalendar } from 'react-icons/si';
import { CURRENCIES } from 'utils/invoice';

export const currencySymbol = (currency) =>
   CURRENCIES.find((i) => i.code === currency).symbol;

function calcPrice(price, rate) {
   const value = parseFloat(price.price);
   if (price.currency !== 'USD') return value / rate;
   return value;
}

const PriceWithCurrency = ({ price }) => {
   const { latestExchangeRate } = useContext(CurrencyContext);

   const isFullYearPrice =
      price.avaliableFromMonth === 1 && price.avaliableToMonth === 12;

   if (price.currency !== 'USD') {
      return (
         <div className="flex items-center space-x-1 justify-end">
            {isFullYearPrice ? null : (
               <SiGooglecalendar className="mr-2 text-yellow-500" />
            )}
            <div>
               {currencySymbol(price.currency)}{' '}
               {parseFloat(price.price).toFixed(3)}
            </div>
            <BsArrowRight />
            <div>
               <div>
                  ${' '}
                  {calcPrice(
                     price,
                     latestExchangeRate.supplierUsdToRmbRate
                  ).toFixed(3)}
               </div>
            </div>
         </div>
      );
   }

   return (
      <div className="flex items-center justify-end">
         {isFullYearPrice ? null : (
            <SiGooglecalendar className="mr-2 text-yellow-500" />
         )}
         <div>$ {parseFloat(price.price).toFixed(3)}</div>
      </div>
   );
};

export default PriceWithCurrency;
