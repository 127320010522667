import { useMutation } from "@apollo/client";
import { useModals } from "ModalProvider";
import CharlesButton from "components/charles/base";
import { useState } from "react";
import { UPDATE_PACKAGE_CC } from "./graphql";
import { Alert } from "components/Toast";
import InputSelect from "components/InputSelect";
import { ImCross } from "react-icons/im";

const CcForm = ({ hide, ccs, packageId, internalUsers, setCcs }) => {
  const [newCcs, setNewCcs] = useState(ccs);
  const [inputCc, setInputCc] = useState('');

  const [updatePackageCc] = useMutation(UPDATE_PACKAGE_CC, {
    onCompleted: () => {
      Alert("success", "Cc updated successfully");
      hide();
    }
  });
  return (
    <div className=" space-y-4">
      <div className="flex">
        <label className="w-24">Cc:</label>
        {newCcs && newCcs.length > 0 && (
          <div>
            {newCcs.map((cc, index) => (
              <div key={index} className="flex items-center space-x-2">
                <div>{cc.email}</div>
                <CharlesButton
                  icon={<ImCross className="text-xxs" />}
                  danger={true}
                  onClick={() => setNewCcs(newCcs.filter((_, i) => i !== index))}
                />
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="flex items-center">
        <label className="w-24"></label>

        <InputSelect
          options={internalUsers.map(user => ({ name: user.email, cc: user }))}
          onSelect={selectedOption => {
            if (!newCcs.some(cc => cc.id === selectedOption.cc.id)) {
              setNewCcs([...newCcs, selectedOption.cc]);
            }
          }}
          onChange={setInputCc}
          value={inputCc}
          placeholder="Search a user"
          className="w-60"
        />
      </div>

      <div className="flex space-x-4">
        <CharlesButton
          onClick={() => {
            setCcs(newCcs);
            updatePackageCc({
              variables: {
                id: packageId,
                ccs: newCcs.map(cc => cc.id)
              }
            });
          }}
        >
          Save
        </CharlesButton>
        <CharlesButton onClick={hide} className=" text-gray-500 hover:text-gray-800">Cancel</CharlesButton>
      </div>
    </div>
  );
};

const CcList = ({ internalUsers, samples, parcel, isEditable }) => {
  let allCcs = [...parcel.cc];
  // Note: compatible with the legacy cc (from each sample)
  samples.forEach(sample => {
    sample.cc.forEach(cc => {
      if (!allCcs.some(c => c.id === cc.id)) {
        allCcs.push(cc);
      }
    });
  });

  const [ccs, setCcs] = useState(allCcs);
  const { present, hide } = useModals();

  return (
    <div
      className="flex items-center space-x-4"
    >
      {ccs?.length > 0 &&
        <div className="flex items-center space-x-2 ">
          {ccs.map((cc, index) => <span key={index} className="font-bold">{cc.email}</span>)}
        </div>
      }

      {isEditable &&
        <CharlesButton
          className="h-6 border border-sky-300 text-sky-500 hover:text-sky-800 hover:bg-sky-500 hover:border-sky-500 active:border-sky-700 text-xs px-2 py-1 rounded-md"
          onClick={() => {
            present({
              title: "Edit Cc",
              children: <CcForm hide={hide} ccs={ccs} packageId={parcel.id} internalUsers={internalUsers} setCcs={setCcs} />,
              center: true,
              isBeingPresented: true,
            });
          }}
        >
          Edit
        </CharlesButton>
      }
    </div>
  );
};


export default CcList;