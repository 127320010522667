const { useQuery, gql } = require('@apollo/client');

const FETCH_ODOO_PRODUCT = gql`
   query FETCH_ODOO_PRODUCT($id: Int!) {
      odooProduct(id: $id) {
         id
         name
         displayName
         defaultCode
      }
   }
`;

const useOdooProduct = (id) => {
   const { data } = useQuery(FETCH_ODOO_PRODUCT, {
      variables: { id },
      skip: !id,
   });

   return data ? data.odooProduct : null;
};

const FETCH_ODOO_PRODUCTS = gql`
   query FETCH_ODOO_PRODUCTS($ids: [Int], $barcodes: [String]) {
      odooProducts(ids: $ids, barcodes: $barcodes) {
         id
         displayName
         name
         defaultCode
         barcode
      }
   }
`;

export const useOdooProducts = ({ ids, barcodes }) => {
   const { loading, error, data } = useQuery(FETCH_ODOO_PRODUCTS, {
      variables: { ids, barcodes },
   });

   const odooProducts = data ? data.odooProducts : null;

   return { loading, error, odooProducts };
};

export default useOdooProduct;
