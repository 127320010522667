import moment from "moment";
import { ARCHIVE_CODES } from "./ArchivePriceForm";
import { BsArrowRight } from "react-icons/bs";
import { UNARCHIVE_COMPONENT_PRICE } from "./graphql";
import { useMutation } from "@apollo/client";
import CharlesButton from "components/charles/base";
import { Alert } from "components/Toast";

const ArchivedPricesView = ({ prices }) => {
  const [unarchive, unarchiveRes] = useMutation(UNARCHIVE_COMPONENT_PRICE, {
    onCompleted: () => {
      Alert("success", "Price is unarchived.");
    },
    onError: (error) => Alert("error", error.message),
  });

  function getArchiveCodeDescription(code) {
    const foundCode = ARCHIVE_CODES.find((i) => i.code === code);
    if (foundCode) return foundCode.description;
    return "-";
  }

  return (
    <div className="mt-8 pt-6 border-t dark:border-gray-700">
      <h3 className="px-2">Archived Prices</h3>
      <table className="mt-4">
        <thead className="text-left border-b dark:border-gray-700">
          <tr>
            <th>Supplier</th>
            <th>Min Qty</th>
            <th>Price</th>
            <th className="text-right">Available Months</th>
            <th className="text-right">Archived At</th>
          </tr>
        </thead>
        <tbody>
          {prices.map((price, priceIndex) => (
            <tr key={priceIndex} className="border-b dark:border-gray-700 py-2">
              <td>{price.factory ? price.factory.nickName : null}</td>
              <td>{price.minQty}</td>
              <td>{price.price}</td>
              <td className="text-right">
                <div className="flex space-x-1 items-center justify-end">
                  <div>{price.avaliableFromMonth}</div> <BsArrowRight />
                  <div>{price.avaliableToMonth}</div>
                </div>
              </td>
              <td className="text-right">
                {moment(price.archivedAt).format("YYYY-MM-DD hh:mm:ss")}
                <div>
                  <div className="font-bold">{getArchiveCodeDescription(price.archivedCode)}</div>
                  <div>{price.archivedNotes}</div>
                </div>
                <div className="flex justify-end">
                  <CharlesButton
                    loading={unarchiveRes.loading}
                    onClick={() => {
                      if (window.confirm("Are you sure to unarchive this price?"))
                        unarchive({
                          variables: { id: price.id },
                        });
                    }}
                  >
                    Unarchive
                  </CharlesButton>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ArchivedPricesView;
