import { gql, useQuery } from "@apollo/client";
import Errors from "components/Errors";
import Spinner from "components/Spinner";
import OdooSyncerForm from "./OdooSyncerForm";

const FETCH_PRODUCT_FOR_ODOO = gql`
  query FETCH_PRODUCT_FOR_ODOO($id: ID!) {
    product(id: $id) {
      id
      odooId
      name
      number
      itemWeight
      isLaborCost
      isSalable
      isPurchasable
      barCode: innerBoxBarcodeEan
      odooCategoryId
      productLine {
        id
        name
        hsCodeForEu
      }
      itemsPerSet
      defaultQty
      defaultSupplier {
        id
        odooId
        name
      }
      children {
        id
        product {
          id
          name
          number
          odooId
          prices {
            id
            minQty
            price
            factory {
              id
              name
              odooId
            }
            archivedAt
          }
        }
        qty
      }
      prices {
        id
        minQty
        currency
        price
        factory {
          id
          name
          odooId
        }
        archivedAt
      }
    }
  }
`;

const Syncer = ({ id, onSave }) => {
  const { loading, error, data } = useQuery(FETCH_PRODUCT_FOR_ODOO, {
    variables: { id },
  });

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  return <OdooSyncerForm product={data.product} onSave={onSave} />;
};

export default Syncer;
