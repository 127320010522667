import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Button } from '../../../components/base.js';
import Spinner from '../../../components/Spinner';
import Errors from '../../../components/Errors';
import { Alert } from '../../../components/Toast.js';
import { FETCH_DOC } from './graphql';
import http from '../../../utils/http';
import track, { actions } from '../../../utils/track';
import Page from 'components/Page.js';

function CustomFormPreview() {
   const { id } = useParams();
   const [downloadingPdf, setDownloadingPdf] = useState(false);
   const [downloadingExcel, setDownloadingExcel] = useState(false);
   const { loading, error, data } = useQuery(FETCH_DOC, {
      variables: { id },
   });

   function setLoading(filetype, loading) {
      if (filetype === 'Pdf') setDownloadingPdf(loading);
      if (filetype === 'Excel') setDownloadingExcel(loading);
   }

   const exportFile = (id, filename, filetype) => {
      setLoading(filetype, true);
      http
         .get(`/doc/${id}/?${filetype.toLowerCase()}=1`, {
            baseURL: process.env.REACT_APP_SERVER_BASE_URL,
            timeout: 60000,
            responseType: 'blob',
         })
         .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            setLoading(filetype, false);
         })
         .catch((err) => {
            Alert('error', String(err));
            setLoading(filetype, false);
         });
      track(actions.shipment.exportCustomForm.name, {
         format: filetype.toLowerCase(),
      });
   };

   if (error) return <Errors error={error} />;
   if (loading) return <Spinner />;
   const { shipment, html } = data.document;
   return (
      <Page
         title={`#${shipment.invoiceNumber} - ${shipment.customer.name}`}
         backTo="/shipment/doc/customform"
         rightButtons={
            <div className="flex">
               <Button
                  title="Edit Shipment"
                  link={`/shipment/shipments/${shipment.id}`}
               />
               <Button
                  title={downloadingPdf ? 'Exporting...' : 'Export PDF'}
                  disabled={downloadingPdf}
                  loading={downloadingPdf}
                  className="btn-plain ml-4"
                  onClick={(_) =>
                     exportFile(
                        id,
                        `Customform #${shipment.invoiceNumber}.pdf`,
                        'Pdf'
                     )
                  }
               />
               <Button
                  title={downloadingExcel ? 'Exporting...' : 'Export Excel'}
                  disabled={downloadingExcel}
                  loading={downloadingExcel}
                  className="btn-plain ml-4"
                  onClick={(_) =>
                     exportFile(
                        id,
                        `Custom Form #${shipment.invoiceNumber}.xlsx`,
                        'Excel'
                     )
                  }
               />
            </div>
         }
      >
         <iframe
            title="Customform Preview"
            className="flex-1 w-full bg-white"
            srcDoc={html}
            frameBorder="0"
         />
      </Page>
   );
}

export default CustomFormPreview;
