import React from "react";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useParams } from "react-router-dom";
import Page from "components/Page";
import Errors from "components/Errors";
import Spinner from "components/Spinner";

const FETCH_CUSTOMER_PRODUCTS = gql`
  query FETCH_CUSTOMER_PRODUCTS($id: ID!) {
    customer(id: $id) {
      id
      name
    }
    products(isActive: true, isSalable: true, customerId: $id) {
      results {
        id
        number
        name
        productLine {
          id
          name
        }
      }
      total
    }
  }
`;

function Products() {
  const { id } = useParams();
  const { loading, error, data } = useQuery(FETCH_CUSTOMER_PRODUCTS, { variables: { id } });
  if (loading)
    return (
      <div className="w-full h-32">
        <Spinner text="Loading Products.." />
      </div>
    );
  if (error) return <Errors error={error} />;

  const lines = data.products.results.reduce((acc, i) => {
    if (i.productLine) {
      if (acc[i.productLine.id]) {
        acc[i.productLine.id].products.push(i);
      } else {
        acc[i.productLine.id] = {
          name: i.productLine.name,
          products: [i],
        };
      }
    } else {
      if (acc["no-line"]) {
        acc["no-line"].products.push(i);
      } else {
        acc["no-line"] = {
          name: "No Product Line",
          products: [i],
        };
      }
    }
    return acc;
  }, {});

  return (
    <Page title={`${data.customer.name} - Products`} backTo="./..">
      <div className="p-6 text-sm">
        <div className="text-xs opacity-70">
          <p>This page shows all the products that are related to this customer. Only active and salable products are included.</p>
          <p>
            The relation between the product and the customer is defined by the product groups. Except those products which are set explicitly excluded to this
            customer.
          </p>
        </div>
        <div className="mt-2">{data.products.total} products total.</div>
        <div className="mt-4 card grid grid-cols-2 gap-6">
          {Object.entries(lines).map(([key, value]) => (
            <div key={key}>
              <h5 className="pb-2 border-b border-gray-100 dark:border-gray-700">{value.name}</h5>
              <div className="mt-2">
                {value.products.map((i) => (
                  <div key={i.id} className="py-2 text-xs">
                    <div>
                      [{i.number}] {i.name}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </Page>
  );
}

export default Products;
