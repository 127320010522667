import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import Card from "../../components/Card.js";
import { Button, Tag } from "../../components/base.js";
import Spinner from "../../components/Spinner";
import Errors from "../../components/Errors";
import { UPDATE_CUSTOMER_PRODUCT_GROUPS, FETCH_PRODUCT_AREAS } from "./graphql.js";
import { Link } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";

function CustomerProducts({ customer }) {
  const [areas, setAreas] = useState(customer.areas.map((i) => i.id));
  const [editing, setEditing] = useState(false);
  const { loading, error, data } = useQuery(FETCH_PRODUCT_AREAS);
  const [customerUpdateProductGroups, customerUpdateProductGroupsRes] = useMutation(UPDATE_CUSTOMER_PRODUCT_GROUPS);

  function saveHandler() {
    customerUpdateProductGroups({
      variables: {
        customerId: customer.id,
        productGroupsId: areas,
      },
      update: (_) => {
        setEditing(false);
      },
    });
  }

  return (
    <Card
      rightButtons={
        <div className="flex">
          {editing ? (
            <div className="flex">
              <Button
                disabled={customerUpdateProductGroupsRes.loading}
                loading={customerUpdateProductGroupsRes.loading}
                title={customerUpdateProductGroupsRes.loading ? "saving..." : "save"}
                onClick={saveHandler}
              />
              <Button className="ml-4" title="cancel" color="gray" onClick={(_) => setEditing(false)} />
            </div>
          ) : (
            <Button title="edit" onClick={(_) => setEditing(true)} />
          )}
        </div>
      }
      className="mt-4 lg:mt-6"
      title="Product Groups"
      subtitle="This indicates what products this customer is related to. Customer is able to see
                  these product data(item number, item size, item weight, etc.) in our product
                  pages in biz.waboba.com."
    >
      {editing ? (
        <div className="flex flex-wrap mt-2">
          {loading ? (
            <Spinner />
          ) : error ? (
            <Errors error={error} />
          ) : (
            data.allProductAreas.map((a, index) => {
              const selected = areas.includes(a.id);
              return (
                <Tag
                  className="mb-2 mr-4 text-xs"
                  key={index}
                  title={a.name}
                  selected={selected}
                  onClick={(_) => {
                    if (selected) {
                      setAreas((prev) => prev.filter((i) => i !== a.id));
                    } else {
                      setAreas((prev) => [...prev, a.id]);
                    }
                  }}
                />
              );
            })
          )}
        </div>
      ) : areas.length > 0 ? (
        <div>
          <div className="flex flex-wrap">
            {customer.areas.map((a, index) => (
              <Tag className="mb-2 mr-4 text-xs" key={index} title={a.name} />
            ))}
          </div>
          <div className="mt-4">
            <Link to="./products" className=" align-middle">
              View Products{" "}
              <span className="inline-block align-middle">
                <FaChevronRight />
              </span>
            </Link>
          </div>
        </div>
      ) : (
        <div className="text-gray-500 text-xs mt-2">Customer is not belonged to any product groups.</div>
      )}
    </Card>
  );
}

export default CustomerProducts;
