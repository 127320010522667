import { gql, useMutation, useQuery } from "@apollo/client";
import CharlesButton from "components/charles/base";
import Errors from "components/Errors";
import Spinner from "components/Spinner";
import { Alert } from "components/Toast";
import { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const REORDER_PRODUCTION_PLANS = gql`
  mutation($planIds: [ID]!) {
    reorderProductionPlans(planIds: $planIds) {
      plans {
        id
        name
        startDate
        readyDate
      }
    }
  }
`;

const FETCH_PLANS_FOR_REORDERING = gql`
  query FETCH_PLANS_FOR_REORDERING {
    allProductionPlans(statesIn: ["WAITING_TO_CONFIRM", "PLANNING"]) {
      total
      results {
        id
        name
      }
    }
  }
`;

const ReorderView = ({ onCompleted }) => {
  const { loading, error, data } = useQuery(FETCH_PLANS_FOR_REORDERING, { fetchPolicy: "network-only" });
  const [plans, setPlans] = useState([]);
  const [reorderPlans, reorderPlansRes] = useMutation(REORDER_PRODUCTION_PLANS, {
    variables: { planIds: plans.map((i) => i.id) },
    onCompleted() {
      Alert("success", "Plans updated.");
      onCompleted();
    },
    onError(error) {
      Alert("error", error.message);
    },
    refetchQueries: ["FETCH_PREV_PLANS"],
  });

  useEffect(() => {
    if (data) {
      setPlans(
        data.allProductionPlans.results.map((i, index) => ({
          ...i,
          orderingNumber: index + 1,
        }))
      );
    }
  }, [data]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  function onDragEnd(result) {
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;
    const newPlans = reorder(plans, result.source.index, result.destination.index);
    setPlans(newPlans);
  }

  function confirm() {
    reorderPlans();
  }

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  return (
    <div className="text-sm">
      <div className=" text-orange-600 font-semibold">Note that when you change the order of the plans, the dates will be change accordingly.</div>
      <div className="text-xs opacity-70 mt-2">Drag and Drop to set the ordering for the production plans.</div>
      <div className="mt-4">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable-plans">
            {(provided) => {
              return (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {plans.map((i, index) => (
                    <Draggable key={i.id} draggableId={i.id} index={index}>
                      {(provided, snapshot) => {
                        if (snapshot.isDragging) {
                          provided.draggableProps.style = {
                            ...provided.draggableProps.style,
                            left: provided.draggableProps.style.offsetLeft,
                            top: provided.draggableProps.style.offsetTop,
                          };
                        }
                        return (
                          <div className="py-2" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                            <div className="card-in-modal">
                              <div className="flex space-x-4 items-center">
                                <div className="w-4 h-4 bg-teal-600 text-white rounded-full flex items-center justify-center">{i.orderingNumber}</div>
                                <div>
                                  Plan#{i.id} {i.name}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              );
            }}
          </Droppable>
        </DragDropContext>
      </div>

      <hr />

      <div>
        <CharlesButton primary onClick={confirm} loading={reorderPlansRes.loading}>
          Confirm
        </CharlesButton>
      </div>
    </div>
  );
};

export default ReorderView;
