import { useContext } from "react";
import { ProductContext } from "./ProductForm";
import { ButtonX } from "components/base";
import { Field, Input } from "components/Form";
import CharlesButton, { CharlesButtonWithArrow } from "components/charles/base";
import CostPreview from "./CostPreview";
import ProductSelector from "./ProductSelector";
import odooIcon from "assets/odoo-icon.svg";
import ComputedPriceSyncer from "../odoo/ComputedPriceSyncer";
import { BsTrash } from "react-icons/bs";

const BOMView = () => {
  const { showBom, setShowBom, simpleFields, onChangeSimpleFields, children, setChildren, odooProduct, syncPriceToOdooRes, odooSyncerModal, data } = useContext(
    ProductContext
  );

  return (
    <section>
      <CharlesButtonWithArrow className="text-xl" show={showBom} onClick={() => setShowBom((prev) => !prev)}>
        BOM
      </CharlesButtonWithArrow>

      {showBom ? (
        <div className="mt-4">
          <div className="mt-4 flex">
            <Field
              className="text-center"
              label="Qty / Set"
              value={simpleFields.itemsPerSet}
              onChange={(value) => onChangeSimpleFields("itemsPerSet", value)}
            />
          </div>

          <div className="mt-4">
            {children.map(({ product, qty }, index) => (
              <div key={index} className="py-1 flex justify-between items-center space-x-6">
                <div className="flex flex-1 space-x-2 items-center">
                  <CharlesButton
                    danger
                    onClick={() => {
                      setChildren((prev) => prev.filter((_, prevIndex) => prevIndex !== index));
                    }}
                  >
                    <BsTrash />
                  </CharlesButton>
                  {product ? (
                    <div className="flex items-center">
                      [{product.number}] {product.name}{" "}
                      {product.odooId ? <img className="ml-2" style={{ height: 14 }} src={odooIcon} alt="odoo product" /> : null}
                    </div>
                  ) : (
                    <ProductSelector
                      onSelect={(product) =>
                        setChildren((prev) =>
                          prev.map((i, prevIndex) => {
                            if (prevIndex !== index) return i;
                            return { ...i, product };
                          })
                        )
                      }
                    />
                  )}
                </div>
                <div className="flex items-center space-x-2">
                  <span className="text-xl">&times; </span>
                  <Input
                    className="text-center w-24"
                    value={qty}
                    onChange={(e) =>
                      setChildren((prev) =>
                        prev.map((i, prevIndex) =>
                          prevIndex !== index
                            ? i
                            : {
                                ...i,
                                qty: e.target.value,
                              }
                        )
                      )
                    }
                  />
                </div>
              </div>
            ))}
          </div>

          <CharlesButton className="mt-2" onClick={() => setChildren((prev) => [...prev, { product: null, qty: 1 }])}>
            + Product
          </CharlesButton>

          {children.filter((i) => i.product).length > 0 > 0 ? (
            <div className="">
              <hr />
              <div className="relative flex space-x-4 items-center">
                <div>Computed Cost:</div>
                <div className="font-bold relative">
                  <CostPreview itemsPerSet={simpleFields.itemsPerSet} bom={children.filter((i) => i.product)} qty={simpleFields.defaultQty} />
                </div>

                {odooProduct ? (
                  <CharlesButton
                    loading={syncPriceToOdooRes.loading}
                    onClick={() =>
                      odooSyncerModal.present({
                        title: "Sync Computed Price to Odoo",
                        hideChildren: true,
                        center: true,
                        children: <ComputedPriceSyncer product={data.product} hide={odooSyncerModal.hide} />,
                      })
                    }
                  >
                    Sync Computed Price to Odoo
                  </CharlesButton>
                ) : null}
              </div>
            </div>
          ) : null}
        </div>
      ) : null}
    </section>
  );
};

export default BOMView;
