import React from "react";
import { useParams } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import CommercialInvoiceForm from "./Form";
import Page from "components/Page";

const FETCH_CREATE_COMMERCIAL_INVOICE_DATA = gql`
  query FETCH_CREATE_COMMERCIAL_INVOICE_DATA($id: ID!) {
    shipment(id: $id) {
      id
      invoiceNumber
      po
      odooCurrency
      specialNotes
      customer {
        id
        name
        user {
          id
          email
        }
        notes
        lastUsedCurrency
      }
      latestStatus
      paymentTerms
      countryOfOrigin
      shipmentConsigneeName
      addr
      deliveryAddr
      office
      hbl
      shipmentNotifyPartyName
      notifyParty
      shippingMark
      stamp
      extraEmails
      forwarder {
        id
        name
      }
      blNumber
      vv
      containerNumber
      shippingTerm
      loadingPort
      requestCargoReadyDate
      actualCargoReadyDate
      dateOfDeliveryToPort
      etd
      eta
      newEtd
      newEta
      pickupLocation
      shipBy
      portOfDischarge
      portOfDestination
      notes
      notesForCustomform
      stampForPackingList
      hasRowOrderNo
      shipmentInvoices {
        id
        number
        pos {
          id
          number
        }
        invoice {
          id
          number
          odooModelType
        }
        shipmentInvoiceRows {
          id
          orderNo
          product {
            id
            odooId
            productType
            packingName
            hsCode
            hsCodeForDestination
            name
            packingName
            number
            quantityPerCarton
            ctnNetWeight
            ctnGrossWeight
            outerCartonCbm
            cost
          }
          purchaseOrder {
            id
            number
          }
          packingName
          newProductItemNumber
          hsCode
          hsCodeForDestination
          newProductQtyPerCarton
          newProductNetWeight
          newProductGrossWeight
          newProductCbm
          newProductValue
          unitPrice
          qty
          totalCarton
          totalNetWeight
          totalGrossWeight
          totalCbm
          totalValue
          qcReports {
            id
            status
          }
        }
      }
      totalQty
      totalCarton
      totalNetWeight
      totalGrossWeight
      totalCbm
      totalValue
      attachments {
        id
        name
        uri
      }
      deletedAt
    }
  }
`;

function GenerateCommercialInvoice() {
  const { id } = useParams();
  const { loading, error, data } = useQuery(FETCH_CREATE_COMMERCIAL_INVOICE_DATA, { variables: { id } });
  if (error) return <Errors error={error} />;
  if (loading) return <Spinner />;

  const { shipment } = data;

  const initialShipment = {
    ...shipment,
    shipmentInvoices: shipment.shipmentInvoices.map((i) => {
      if (i.invoice.odooModelType === "transfer") {
        return {
          ...i,
          shipmentInvoiceRows: i.shipmentInvoiceRows.map((row) => ({
            ...row,
            unitPrice: row.product.cost || 0,
          })),
        };
      }
      return i;
    }),
  };

  return (
    <Page className="lg:h-screen" title="Generate Commercial Invoice" subtitle={`#${shipment.invoiceNumber}`} backTo={`/shipment/shipments/${shipment.id}`}>
      <CommercialInvoiceForm initialShipment={initialShipment} />
    </Page>
  );
}

export default GenerateCommercialInvoice;
