import React from "react";
import Icon from "./Icon.js";

function Card({ className = "", title, subtitle = null, rightButtons = null, children, onClick }) {
  return (
    <div
      className={`rounded-2xl bg-white dark:bg-gray-800 border dark:border-gray-800 px-6 py-4 relative
            ${onClick ? "cursor-pointer hover:bg-gray-200" : ""}
            ${className}
            `}
      onClick={onClick}
    >
      <div className="flex justify-between items-start">
        <div className="mb-4">
          {title ? (
            <div>
              <div className="font-bold md:text-base lg:text-lg">
                {title}
                {onClick ? <Icon className="inline-block" size="16" icon="link" /> : null}
              </div>
              {subtitle ? <div className="text-xs text-gray-600">{subtitle}</div> : null}
            </div>
          ) : null}
        </div>
        {rightButtons ? rightButtons : null}
      </div>
      {children}
    </div>
  );
}

export default Card;
