import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatDate } from 'react-day-picker/moment';
import { Button, Tag } from 'components/base';
import Spinner from 'components/Spinner';
import Errors from 'components/Errors';
import SearchBar from 'components/SearchBar';
import ShipmentTitle from 'components/ShipmentTitle';
import { searchByProp } from 'utils/search';
import Page from 'components/Page';
import odooIcon from 'assets/odoo-icon.svg';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import useOdooSalesOrdersAndInternalTransfoers from 'hooks/useOdooSalesOrdersAndInternalTransfoers';
import { gql, useQuery } from '@apollo/client';

const CreateShipment = () => {
   const [searchText, setSearchText] = useState('');
   const { loading, error, data } = useOdooSalesOrdersAndInternalTransfoers();

   const [selectedItems, setSelectedItems] = useState([]);
   const [selectedAddressId, setSelectedAddressId] = useState(null);

   if (loading) return <Spinner text="Loading Odoo Orders" />;
   if (error) return <Errors error={error} />;

   let list = [
      ...data.odooSoList.map((i) => ({
         ...i,
         type: 'so',
         timestamp: +new Date(i.datetime),
      })),
      ...data.odooInternalTransferList.map((i) => ({
         ...i,
         type: 'transfer',
         timestamp: +new Date(i.datetime),
      })),
   ];

   const selectedType = selectedItems.length > 0 ? selectedItems[0].type : null;

   list = list.sort((a, b) => b.timestamp - a.timestamp);
   list = list
      .filter((i) => (selectedType ? i.type === selectedType : true))
      .filter((i) => searchByProp(i, ['name', 'customer'], searchText))
      .filter((i) => {
         if (selectedItems.length > 0) {
            const selectedCustomer = selectedItems[0].customer;
            return i.customer === selectedCustomer;
         }
         return true;
      })
      .map((i) => ({
         ...i,
         selected: selectedItems
            ? selectedItems.find((j) => j.id === i.id)
            : false,
      }));

   console.log('list', list);

   return (
      <Page
         title="Create Shipment"
         subtitle="From Odoo Sales Orders and Internal Transfers"
         backTo="/shipment/shipments"
      >
         <div className="flex space-x-8 items-start p-6">
            <div className="w-1/2">
               <div>
                  <SearchBar
                     autoFocus
                     placeholder="Search SO by number or customer name."
                     className="py-2"
                     text={searchText}
                     onChange={setSearchText}
                  />
               </div>

               <div className="mt-2">
                  {list.map((item, index) => (
                     <div
                        key={index}
                        className="flex justify-between items-center sm:cursor-pointer hover:bg-white hover:rounded-2xl hover:border-b-0 dark:hover:bg-gray-800 border-b  dark:border-gray-800 px-4 py-2 transform-gpu duration-100 hover:scale-105"
                        onClick={() => {
                           if (item.selected) {
                              setSelectedItems((prev) =>
                                 prev.filter((i) => i.id !== item.id)
                              );
                           } else {
                              setSelectedItems((prev) => [...prev, item]);
                           }
                        }}
                     >
                        <div className="flex items-center space-x-3">
                           <ShipmentTitle
                              customerName={item.customer}
                              number={item.name}
                           />
                           {item.selected ? (
                              <IoIosCheckmarkCircle
                                 className="text-green-500"
                                 size={21}
                              />
                           ) : null}
                        </div>

                        <div className="flex space-x-8">
                           <div>{item.state}</div>
                           <div>{formatDate(item.datetime, 'YYYY-MM-DD')}</div>
                        </div>
                     </div>
                  ))}
               </div>
            </div>

            <div className="w-1/2 bg-white dark:bg-gray-800 rounded-2xl p-6">
               {selectedItems.length === 0 ? (
                  <label>Select Odoo Orders on the left.</label>
               ) : (
                  <NextForm
                     selectedType={selectedType}
                     selectedItems={selectedItems}
                     setSelectedItems={setSelectedItems}
                     selectedAddressId={selectedAddressId}
                     setSelectedAddressId={setSelectedAddressId}
                     odooPartnerId={selectedItems[0].partnerId}
                  />
               )}
            </div>
         </div>
      </Page>
   );
};

const FETCH_CUSTOMER = gql`
   query FETCH_CUSTOMER($odooPartnerId: Int) {
      customer(odooPartnerId: $odooPartnerId) {
         id
         addresses {
            id
            address
            category
            isDefault
         }
      }
   }
`;

const NextForm = ({
   selectedType,
   selectedItems,
   setSelectedItems,
   selectedAddressId,
   setSelectedAddressId,
   odooPartnerId,
}) => {
   const { loading, error, data } = useQuery(FETCH_CUSTOMER, {
      variables: { odooPartnerId },
   });
   const navigate = useNavigate();

   if (loading) return <Spinner text="Loading Odoo Orders" />;
   if (error) return <Errors error={error} />;

   const deliveryAddresses = data.customer.addresses.filter(
      (i) => i.category === 'DELIVERY'
   );

   let nextUrl = `/shipment/create/preview?ids=${selectedItems
      .map((i) => i.id)
      .join(',')}&type=${selectedType}`;

   if (selectedAddressId) nextUrl += `&deliveryAddressId=${selectedAddressId}`;

   return (
      <div>
         <div className="flex justify-between items-center">
            <div className="flex items-center">
               <img
                  className="mr-2"
                  style={{ height: 16 }}
                  src={odooIcon}
                  alt="odoo product"
               />
               <label htmlFor="">Odoo Customer: </label>
               <span className="ml-4">{selectedItems[0].customer}</span>
            </div>

            <Button
               title="Clear"
               color="#666"
               onClick={() => setSelectedItems([])}
            />
         </div>

         <div className="text-xs text-gray-600 mt-2">
            You could select multiple Sales Orders or Internal Transfers to
            create a shipment.
         </div>
         <div className="flex flex-wrap my-4">
            {selectedItems.map((item, index) => (
               <Tag
                  className="my-2 mr-4"
                  key={index}
                  title={item.name}
                  selected={!!selectedItems.find((i) => i.id === item.id)}
                  onClick={() =>
                     setSelectedItems((prev) =>
                        prev.filter((i) => i.id !== item.id)
                     )
                  }
               />
            ))}
         </div>

         {deliveryAddresses.length > 0 ? (
            <div className="mt-6">
               <h3>Select Devliery Address</h3>
               <div className="mt-4 space-y-4">
                  {deliveryAddresses.map((i) => (
                     <div
                        key={i.id}
                        className={`p-4 rounded-2xl whitespace-pre-line cursor-pointer
                        ${
                           i.id === selectedAddressId
                              ? 'bg-blue-600 dark:bg-blue-800 text-white'
                              : 'bg-gray-100 hover:bg-blue-200 dark:bg-gray-900 dark:bg-opacity-70'
                        }
                     `}
                        onClick={() =>
                           i.id === selectedAddressId
                              ? setSelectedAddressId(null)
                              : setSelectedAddressId(i.id)
                        }
                     >
                        {i.address}
                     </div>
                  ))}
               </div>
            </div>
         ) : null}

         <div className="flex mx-2 mt-6">
            <Button
               size="xl"
               bold
               title="Next"
               next
               iconSize={21}
               onClick={() => navigate(nextUrl)}
            />
         </div>
      </div>
   );
};

export default CreateShipment;
