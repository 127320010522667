import { useMutation } from "@apollo/client";
import { Input } from "components/Form";
import { Alert } from "components/Toast";
import { useEffect, useRef, useState } from "react";
import { CREATE_PRODUCTION_PLAN } from "./graphql";
import CharlesButton from "components/charles/base";

const CreatePlan = ({ onCreatedPlan }) => {
  const [name, setName] = useState("");
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 200);
    }
  }, []);

  const [createPlan, createPlanRes] = useMutation(CREATE_PRODUCTION_PLAN, {
    variables: {
      name,
      planType: "PRODUCT",
      orderType: "FOB_ORDER",
      startDate: new Date().addDays(7),
    },
    onCompleted(res) {
      Alert("success", "Plan Created.");
      onCreatedPlan(res.createProductionPlan.productionPlan);
    },
    onError(error) {
      Alert("error", error.message);
    },
    refetchQueries: ["FETCH_PREV_PLANS"],
  });

  function tryCreatePlan() {
    if (!name) {
      Alert("error", "Plan Name is required.");
      return;
    }
    createPlan();
  }

  return (
    <form onSubmit={tryCreatePlan}>
      <div className="flex items-center space-x-3">
        <label>Plan Name: </label>
        <Input autoFocus ref={inputRef} className="flex-1" value={name} onChange={(e) => setName(e.target.value)} />
      </div>

      <div className=" opacity-70 text-sm mt-2">Give a name to your plan. This will help you identify the plan later.</div>

      <hr />

      <div>
        <CharlesButton primary loading={createPlanRes.loading} onClick={tryCreatePlan}>
          Create
        </CharlesButton>
      </div>
    </form>
  );
};

export default CreatePlan;
