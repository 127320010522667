import { gql, useMutation, useQuery } from '@apollo/client';
import CharlesButton from 'components/charles/base';
import { Alert } from 'components/Toast';

const DELETE_SUB_SUPPLIER_ORDER = gql`
   mutation ($id: ID!) {
      deleteSubSupplierOrder(id: $id) {
         result
      }
   }
`;

const DeleteOrderView = ({ order, onDeleted, hide }) => {
   const [deleteOrder, deleteOrderRes] = useMutation(
      DELETE_SUB_SUPPLIER_ORDER,
      {
         variables: { id: order.id },
         onCompleted() {
            onDeleted();
            Alert('success', 'Order Deleted.');
         },
         onError(error) {
            Alert('error', error.message);
         },
      }
   );

   return (
      <div>
         <div className="text-sm">
            {order.invoices.length > 0 ? (
               <div>
                  <p>
                     There are invoices for this order, you can not delete this
                     order unless you delete all the invoices for this order.
                  </p>
                  <hr />
                  <div className="flex space-x-6">
                     <CharlesButton primary onClick={hide}>
                        OK
                     </CharlesButton>
                  </div>
               </div>
            ) : (
               <div>
                  <p>Are you sure to delete this order?</p>
                  <hr />
                  <div className="flex space-x-6">
                     <CharlesButton
                        danger
                        onClick={() => deleteOrder()}
                        loading={deleteOrderRes.loading}
                     >
                        Yes, Delete Order
                     </CharlesButton>
                     <CharlesButton onClick={hide}>
                        No, Take me back
                     </CharlesButton>
                  </div>
               </div>
            )}
         </div>
      </div>
   );
};

export default DeleteOrderView;
