import { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { useDebouncedCallback } from 'use-debounce';
import { InlineSpinner } from 'components/Spinner';
import { Input } from 'components/Form';
import { Alert } from 'components/Toast';
import { SAVE_PRODUCT_SALES_PRICE } from '../graphql';

const ProductVariant = ({ variant, editMode, found }) => {
   const [salesPrice, setSalesPrice] = useState(variant.salesPrice);

   const [save, { loading }] = useMutation(SAVE_PRODUCT_SALES_PRICE, {
      onCompleted: () => Alert('success', 'Updated.'),
      onError: (error) => Alert('error', error.message),
      awaitRefetchQueries: true,
      refetchQueries: ['FETCH_PRODUCTS_FOR_SALES_INC'],
   });
   const debouncedSave = useDebouncedCallback(save, 600);

   return (
      <div
         className={`border-t border-gray-100 dark:border-gray-700 py-3 px-4 ${
            found ? '' : 'opacity-50'
         }`}
      >
         <div className="flex space-x-8 items-center">
            <div className="w-1/2 flex-grow">
               <a
                  href={`${process.env.REACT_APP_SERVER_ADMIN_URL}/product/product/${variant.id}`}
                  target="_blank"
                  rel="noreff"
               >
                  #{variant.id}
               </a>{' '}
               [{variant.number}] {variant.name}
            </div>
            <div className="w-32 text-right">{variant.defaultQty}</div>
            <div className="text-right w-24">
               <div>$ {variant.unitCost}</div>
               <div
                  className="text-xs text-gray-500"
                  title="AB EX-Work updated at"
               >
                  {variant.costUpdatedAt}
               </div>
            </div>
            <div className="text-right w-24">
               {editMode ? (
                  <div className="flex items-center space-x-4">
                     <Input
                        className="text-center w-24"
                        value={salesPrice || ''}
                        onChange={(e) => {
                           const salesPrice = e.target.value;
                           setSalesPrice(e.target.value);
                           if (isNaN(salesPrice)) return;
                           debouncedSave({
                              variables: {
                                 productId: variant.id,
                                 priceListId: 1,
                                 price: salesPrice,
                              },
                           });
                        }}
                        disabled={loading}
                     />
                     {loading ? <InlineSpinner text={null} size={16} /> : null}
                  </div>
               ) : variant.salesPrice ? (
                  <div>
                     <div>$ {variant.salesPrice}</div>
                     <div
                        className="text-xs text-gray-500"
                        title="INC price updated at"
                     >
                        {variant.salesPriceUpdatedAt}
                     </div>
                  </div>
               ) : (
                  '-'
               )}
            </div>
            <div className="text-right w-24">
               {(variant.margin * 100).toFixed(3)} %
            </div>
         </div>
      </div>
   );
};

export default ProductVariant;
