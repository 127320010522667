import { gql, useQuery } from "@apollo/client";
import Errors from "components/Errors";
import Spinner from "components/Spinner";
import { HiOutlineExternalLink } from "react-icons/hi";

const FETCH_PLAN_LINES = gql`
  query FETCH_PLAN_LINES($ids: [ID!]!) {
    productionPlanLines(ids: $ids) {
      id
      plan {
        id
        name
        state
      }
    }
  }
`;

const ProductDetailView = ({ product }) => {
  const { loading, error, data } = useQuery(FETCH_PLAN_LINES, {
    variables: { ids: product.planLineIds },
  });

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  return (
    <div>
      <div>
        {data.productionPlanLines.map((i) => (
          <a className="flex items-center space-x-2 border-b dark:border-gray-700 py-2" target="_blank" href={`/order-scheduler/plans/${i.plan.id}`}>
            <span>{i.plan.name}</span>
            <span>
              <HiOutlineExternalLink />
            </span>
          </a>
        ))}
      </div>
    </div>
  );
};

export default ProductDetailView;
