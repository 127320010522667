import { gql, useQuery } from "@apollo/client";
import CharlesButton from "components/charles/base";
import Errors from "components/Errors";
import { InlineSpinner } from "components/Spinner";
import useDateRange from "hooks/useDateRange";
import XLSX from "xlsx";
import DateRangePicker from "../DateRangePicker";
import { useModals } from "ModalProvider";
import ProductDetailView from "./ProductDetailView";
import ProductProductionHistoryView from "./ProductProductionHistoryView";
import { FaChartLine } from "react-icons/fa";

const FETCH_PRODUCTION_TIME_IN_PLANS = gql`
  query FETCH_PRODUCTION_TIME_IN_PLANS($startDate: DateTime!, $endDate: DateTime!) {
    productionTimeInPlans(startDate: $startDate, endDate: $endDate) {
      id
      name
      inPlans
      avgAddDays
      totalAddDays
      planLineIds
    }
  }
`;

const Products = () => {
  const [{ start, end }, { setStart, setEnd }] = useDateRange(6);
  const { loading, error, data } = useQuery(FETCH_PRODUCTION_TIME_IN_PLANS, {
    variables: { startDate: start, endDate: end },
  });
  const modal = useModals();
  const largeModal = useModals();

  function exportXlxs() {
    const sheet = XLSX.utils.json_to_sheet(
      data.productionTimeInPlans.map((i) => {
        let x = { ...i };
        delete x.__typename;
        return x;
      })
    );
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, sheet, "Production Days");
    XLSX.writeFile(wb, "Production Days.xlsx");
  }

  function showDetail(product) {
    modal.present({
      title: "Plans",
      subtitle: product.name,
      children: <ProductDetailView product={product} />,
    });
  }

  function showHistory(product) {
    largeModal.present({
      title: "Production History",
      maxWidth: "max-w-7xl",
      subtitle: product.name,
      children: <ProductProductionHistoryView product={product} startDate={start} endDate={end} />,
    });
  }

  return (
    <div className="p-6 bg-white dark:bg-gray-800 text-sm">
      <div className="flex justify-between">
        <DateRangePicker start={start} end={end} setStart={setStart} setEnd={setEnd} />

        <div>
          <CharlesButton onClick={exportXlxs}>Export</CharlesButton>
        </div>
      </div>

      {loading ? (
        <div className="p-10">
          <InlineSpinner />
        </div>
      ) : error ? (
        <Errors error={error} />
      ) : (
        <div className="-mx-2">
          <table className="with-line mt-4">
            <thead>
              <tr>
                <th>Product</th>
                <th className="text-right">In Plans</th>
                <th className="text-right">Ave Add Days</th>
                <th className="text-right">Total Add Days</th>
              </tr>
            </thead>
            <tbody>
              {data.productionTimeInPlans.map((product, index) => (
                <ProductRow index={index} product={product} key={index} showDetail={showDetail} showHistory={showHistory} />
              ))}
            </tbody>
          </table>
        </div>
      )}

      <div className="mt-6 opacity-70 text-xs leading-relaxed">
        <p>Products production days between the plan start date and the product ready date from Order Scheduler Handled Plans.</p>
        <p>This is a method to judge which product is necessary to build stock to save production lead time.</p>
        <p>1. Calculate the standard deviation of the production days for each product in a plan.</p>
        <p>2. Select the plans with standard deviation value is more than 7 days.</p>
        <p>3. Find the products from these plans which production days are larger than average production days.</p>
        <p>4. Calculate the added days to average production days for these products for every plan they are included.</p>
        <p>5. Calculate the average added days for these products and rank them by total added days</p>
      </div>
    </div>
  );
};

const ProductRow = ({ product, showDetail, showHistory }) => {
  return (
    <tr>
      <td>
        <div className="flex items-center space-x-3">
          <div>{product.name}</div>
          <CharlesButton onClick={() => showDetail(product)}>view plans</CharlesButton>
          <CharlesButton onClick={() => showHistory(product)}>
            <FaChartLine />
          </CharlesButton>
        </div>
      </td>
      <td className="text-right">{product.inPlans}</td>
      <td className="text-right">{product.avgAddDays}</td>
      <td className="text-right">{product.totalAddDays}</td>
    </tr>
  );
};

export default Products;
