import { gql, useQuery } from "@apollo/client";
import Errors from "components/Errors";
import Spinner from "components/Spinner";
import { useModals } from "ModalProvider";
import { formatDate } from "react-day-picker/moment";
import { IoMdCheckmarkCircle, IoMdClose } from "react-icons/io";
import PreviewProductionForm from "./PreviewProductionForm";

const FETCH_PRODUCTION_FORMS = gql`
  query FETCH_PRODUCTION_FORMS {
    productionForms {
      id
      createdAt
      data
      factory {
        id
        name
      }
      acceptedAt
      rejectedAt
    }
  }
`;

const ReceivedForms = () => {
  const { data, error, loading } = useQuery(FETCH_PRODUCTION_FORMS);

  const modal = useModals();

  if (loading)
    return (
      <div className="relative  p-24">
        <Spinner />
        <Spinner />
      </div>
    );
  if (error) return <Errors error={error} />;

  return (
    <div>
      <h5 className="whitespace-nowrap sticky top-0 p-6 py-4 bg-white bg-opacity-80 dark:bg-gray-800 dark:bg-opacity-70 backdrop-blur shadow-sm z-10">
        Received Production Forms
      </h5>
      <div className="mt-2 p-6 pt-0">
        {data.productionForms.length > 0 ? (
          data.productionForms.map((i) => (
            <div
              key={i.id}
              className="border-b dark:border-gray-700 py-2 flex justify-between items-baseline cursor-pointer hover:text-blue-600"
              onClick={() =>
                modal.present({
                  title: "Production Submission",
                  subtitle: i.factory.name,
                  children: <PreviewProductionForm previewForm={i} hide={modal.hide} />,
                })
              }
            >
              <div className="flex space-x-2 items-center">
                {i.acceptedAt ? <IoMdCheckmarkCircle className="text-green-600" /> : null}
                {i.rejectedAt ? <IoMdClose className="text-red-600" /> : null}
                <div>{i.factory.name}</div>
              </div>
              <div className="opacity-70 text-xs whitespace-nowrap ml-8">{formatDate(i.createdAt, "YYYY-MM-DD HH:mm:ss")}</div>
            </div>
          ))
        ) : (
          <div className="opacity-50 font-bold">No Supplier submit production forms yet.</div>
        )}
      </div>
    </div>
  );
};

export default ReceivedForms;
