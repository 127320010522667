const { useQuery, gql } = require('@apollo/client');

const FETCH_ODOO_DATA = gql`
   query FETCH_ODOO_SALES_ORDERS_AND_INTERNAL_TRANSFERS {
      odooSoList(warehouseId: 1) {
         id
         name
         state
         datetime: dateOrder
         customer: partnerName
         partnerId
      }
      odooInternalTransferList {
         id
         name
         customer: partnerName
         origin
         datetime: scheduledDate
         partnerId
      }
   }
`;

const useOdooSalesOrdersAndInternalTransfers = () => {
   const { loading, error, data } = useQuery(FETCH_ODOO_DATA);
   return { loading, error, data };
};

export default useOdooSalesOrdersAndInternalTransfers;
