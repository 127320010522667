import { gql, useQuery } from '@apollo/client';
import { Button, NavigationLink } from 'components/base';
import Errors from 'components/Errors';
import Page from 'components/Page';
import Spinner from 'components/Spinner';

const FETCH_SUPPLIER_COST = gql`
   query FETCH_SUPPLIER_COST {
      me {
         supplier {
            id
            products {
               id
               name
               number
               prices {
                  id
                  currency
                  minQty
                  price
                  archivedAt
                  supplierUpdatedAt
                  archivedCode
                  archivedNotes
               }
            }
         }
      }
   }
`;

const CostPage = () => {
   const { loading, error, data } = useQuery(FETCH_SUPPLIER_COST);

   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;

   const products = data.me.supplier.products;

   return (
      <Page
         title="报价"
         rightButtons={
            <div className="flex items-center space-x-6 text-sm">
               <NavigationLink title="报价记录" to="/supplier/cost/history" />
               <Button title="发送新报价" />
            </div>
         }
      >
         <div className="p-6 text-sm">
            <table className="with-line">
               <thead>
                  <tr>
                     <th>产品</th>
                     <th></th>
                     <th className="text-right">价格</th>
                     <th className="text-right">报价时间</th>
                  </tr>
               </thead>
               <tbody>
                  {products.map((product) => (
                     <ProductRows key={product.id} product={product} />
                  ))}
               </tbody>
            </table>
         </div>
      </Page>
   );
};

const ProductRows = ({ product }) => {
   const activePrices = product.prices.filter((i) => i.archivedAt === null);
   const hasPriceForDifferentQty = activePrices.length > 1;

   return (
      <>
         <tr>
            <td rowSpan={activePrices.length + 1}>{product.name}</td>
         </tr>
         {activePrices.map((i) => (
            <tr key={i.id}>
               <td className=" text-right">
                  {hasPriceForDifferentQty ? (
                     <label htmlFor="">qty: {i.minQty}</label>
                  ) : null}
               </td>
               <td className="text-right">{i.price}</td>
               <td className="text-right">{i.supplierUpdatedAt}</td>
            </tr>
         ))}
      </>
   );
};

export default CostPage;
