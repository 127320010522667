import { useEffect, useState } from "react";
import { RiDraggable } from "react-icons/ri";

const DraggableItem = ({
  item,
  index,
  onDragStart,
  onDragOver,
  onDragEnd,
  onDragDrop,
}) => {
  const [isDraggable, setIsDraggable] = useState(false);

  return (
    <div
      draggable={isDraggable}
      onDragStart={(event) => onDragStart(event, index)}
      onDragOver={(event) => onDragOver(event, index)}
      onDragEnd={(event) => onDragEnd(event, index)}
      onDrop={(event) => onDragDrop(event, index)}
      className="relative"
    >
      <div
        className="cursor-grab absolute top-2 right-1 text-base "
        onMouseDown={() => setIsDraggable(true)}
        onMouseUp={() => setIsDraggable(false)}
      >
        <RiDraggable />
      </div>

      <div>{item.content}</div>
    </div>
  );
};

const DraggableList = ({ componentList }) => {
  const [draggingIndex, setDraggingIndex] = useState(null); // record the dragging item index
  const [list, setList] = useState(
    componentList.map((content, index) => ({ id: index, content })),
  );

  useEffect(() => {
    const savedOrder = JSON.parse(localStorage.getItem("draggableListOrder"));
    if (savedOrder && savedOrder.length === list.length) {
      const orderedComponentList = savedOrder.map((id) =>
        list.find((item) => item.id === id),
      );
      setList(orderedComponentList);
    }
  }, [componentList]);

  const handleDragStart = (event, index) => {
    setDraggingIndex(index);
  };

  const handleDragOver = (event, index) => {
    event.preventDefault(); // prevent browser default behavior to allow drop in target area

    if (draggingIndex === index) return;

    // Update the modules' order when dragging an item hovering over another item
    const newList = [...list];
    const draggedItem = newList[draggingIndex];
    newList.splice(draggingIndex, 1);
    newList.splice(index, 0, draggedItem);

    setDraggingIndex(index); // update the dragging index beacuse the item has been moved
    setList(newList);
  };

  const handleDragEnd = (event, index) => {
    // OnDragEnd will be triggered whatever the item is dropped successfully or not when dragging is finished
    setDraggingIndex(null);
  };

  const handleDragDrop = (event, index) => {
    // OnDragDrop will be triggered only when the item is dropped successfully
    event.preventDefault(); // to allow drop in target area

    localStorage.setItem(
      "draggableListOrder",
      JSON.stringify(list.map((item) => item.id)),
    );
  };

  return (
    <div>
      {list.map((item, index) => (
        <DraggableItem
          key={item.id}
          item={item}
          index={index}
          onDragStart={handleDragStart}
          onDragOver={handleDragOver}
          onDragEnd={handleDragEnd}
          onDragDrop={handleDragDrop}
        />
      ))}
    </div>
  );
};

export default DraggableList;
