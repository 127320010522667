import React from 'react';
import DevLogs from './DevLogs';
import Page from 'components/Page';

export default () => {
   return (
      <Page title="Recent Update">
         <DevLogs />
      </Page>
   );
};
