import useOdooProductPriceList from 'hooks/useOdooProductPriceList';
import { Select } from './Form';

const OdooPriceListSelector = ({ value, onSelect, ...rest }) => {
   const { loading, error, priceLists } = useOdooProductPriceList();

   return (
      <Select
         value={value || '0'}
         onChange={(e) => {
            onSelect(
               e.target.value === '0'
                  ? null
                  : priceLists.find((i) => i.id === parseInt(e.target.value))
            );
         }}
         {...rest}
      >
         {loading ? (
            <option value="0" disabled>
               loading...
            </option>
         ) : error ? (
            <option value="0" disabled>
               {error.message}
            </option>
         ) : (
            <>
               <option value="0">--- Select ---</option>
               {priceLists.map((i, index) => (
                  <option key={index} value={i.id}>
                     {i.name} ({i.currency_id[1]})
                  </option>
               ))}
            </>
         )}
      </Select>
   );
};

export default OdooPriceListSelector;
