import gql from 'graphql-tag';

export const PAGE_SIZE = 50;

export const LIST_DOC_FRAGMENT = gql`
   fragment listDoc on DocumentType {
      id
      docType
      shipment {
         id
         invoiceNumber
         customer {
            id
            name
         }
      }
      created
   }
`;

export const DETAIL_DOC_FRAGMENT = gql`
   fragment detailDoc on DocumentType {
      id
      shipment {
         id
         invoiceNumber
         customer {
            id
            name
         }
      }
      html
      created
   }
`;

export const FETCH_ALL_DOCUMENTS = gql`
   query FETCH_ALL_DOCUMENTS($docType: String, $offset: Int) {
      allDocuments(docType: $docType, limit: ${PAGE_SIZE}, offset: $offset) {
         ...listDoc
      }
   }${LIST_DOC_FRAGMENT}
`;

export const FETCH_DOC = gql`
   query FETCH_DOC($id: ID!) {
      document(id: $id) {
         ...detailDoc
      }
   }
   ${DETAIL_DOC_FRAGMENT}
`;
