import { gql, useQuery } from "@apollo/client";
import Errors from "components/Errors";
import Spinner from "components/Spinner";
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, Tooltip } from "recharts";
import { randomColors } from "utils/colors";
import DefectCodesChart from "./DefectCodesChart";
import IssueImages from "./IssueImages";
import ReportList from "./ReportList";

const FETCH_FACTORY_QC_ANALYTICS = gql`
  query FETCH_FACTORY_QC_ANALYTICS($id: ID!, $start: DateTime!, $end: DateTime!) {
    factoryQcAnalytics(id: $id, startDate: $start, endDate: $end)
  }
`;

const FactoryDetail = ({ id, start, end }) => {
  const { loading, error, data } = useQuery(FETCH_FACTORY_QC_ANALYTICS, {
    variables: { id, start, end },
    fetchPolicy: "network-only",
  });

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const res = JSON.parse(data.factoryQcAnalytics);

  return (
    <div>
      <div className="space-y-12">
        {res.products.map((product, index) => (
          <div key={product.id} className={index > 0 ? "border-t-4 pt-12" : ""}>
            <h2>{product.name}</h2>
            <ProductChart product={product} />
            <IssueImages issues={product.issues} />
            <ReportList reports={product.origin_reports} />
          </div>
        ))}
      </div>
    </div>
  );
};

const ProductChart = ({ product }) => {
  const chartData = product.reports.map((report) => ({
    month: report.month,
    value: Math.round(report.issue_percentage * 100) / 100,
  }));

  const defectCodes = Object.entries(product.defectCodes)
    .map(([_, value]) => value)
    .sort((a, b) => b.qty - a.qty);
  const pieData = defectCodes.map((i) => ({
    name: i.description,
    value: i.qty,
  }));

  return (
    <div className="flex items-center space-x-8">
      <div className="py-6 w-2/3">
        <ResponsiveContainer width="100%" height={250}>
          <LineChart data={chartData}>
            <XAxis dataKey="month" />
            <YAxis domain={[0, 10]} label="%" />
            <Tooltip content={CustomTooltip} />
            <Line type="monotone" dataKey="value" stroke={randomColors(1)[0]} />
          </LineChart>
        </ResponsiveContainer>
      </div>

      <div className="py-6 w-1/3">
        <DefectCodesChart data={pieData} />
      </div>
    </div>
  );
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const data = payload[0];

    return (
      <div className="px-6 py-3 rounded-2xl bg-opacity-70 bg-white dark:bg-gray-800 dark:bg-opacity-80">
        <div>{`${label}: ${data.value}%`}</div>
      </div>
    );
  }

  return null;
};

export default FactoryDetail;
