import * as calculator from "../calculator";

const reducer = (state, action) => {
  const payload = action.payload;
  let updatedShipment = {};
  let updatedShipmentStatus = [];
  switch (action.type) {
    case "changeShipment":
      return { ...state, ...payload };
    case "didCreateShipmentStatus":
      updatedShipmentStatus = [payload, ...state.shipmentStatus];
      updatedShipmentStatus.sort((a, b) => b.created - a.created);
      return { ...state, shipmentStatus: updatedShipmentStatus };
    case "updateShipmentStatus":
      updatedShipmentStatus = state.shipmentStatus.map((s) => {
        if (s.id === payload.id) return { ...s, ...payload };
        return s;
      });
      updatedShipmentStatus.sort((a, b) => b.created - a.created);
      return { ...state, shipmentStatus: updatedShipmentStatus };
    case "deleteShipmentStatus":
      return {
        ...state,
        shipmentStatus: state.shipmentStatus.filter((i) => i.id !== payload),
      };
    case "createShipmentStatusError":
      return {
        ...state,
        newEtd: payload.shipment.newEtd,
        newEta: payload.shipment.newEta,
        shipmentStatus: state.shipmentStatus.map((s) => {
          if (s.id !== payload.shipmentStatusId) return s;
          return {
            ...s,
            errors: [payload.shipmentStatusError, ...s.errors],
          };
        }),
      };
    case "updateShipmentStatusError":
      return {
        ...state,
        newEtd: payload.shipment.newEtd,
        newEta: payload.shipment.newEta,
        shipmentStatus: state.shipmentStatus.map((s) => {
          if (s.id !== payload.shipmentStatusId) return s;
          return {
            ...s,
            errors: s.errors.map((e) => {
              if (e.id !== payload.shipmentStatusError.id) return e;
              return payload.shipmentStatusError;
            }),
          };
        }),
      };
    case "deleteShipmentStatusError":
      return {
        ...state,
        newEtd: payload.shipment.newEtd,
        newEta: payload.shipment.newEta,
        shipmentStatus: state.shipmentStatus.map((s) => {
          if (s.id !== payload.shipmentStatusId) return s;
          return {
            ...s,
            errors: s.errors.filter((e) => e.id !== payload.shipmentStatusErrorId),
          };
        }),
      };
    case "sendShipmentErrorCustomerMessage":
      return {
        ...state,
        shipmentStatus: state.shipmentStatus.map((s) => {
          if (s.id !== payload.shipmentStatusId) return s;
          return {
            ...s,
            errors: s.errors.map((e) => {
              if (e.id !== payload.shipmentStatusErrorId) return e;
              return {
                ...e,
                shipmentErrorCustomerMessages: [...e.shipmentErrorCustomerMessages, payload.newShipmentStatusErrorCustomerMessage],
              };
            }),
          };
        }),
      };

    case "createShipmentInvoiceFromOdoo":
      updatedShipment = {
        ...state,
        shipmentInvoices: state.shipmentInvoices.find((x) => x.id === payload.id)
          ? state.shipmentInvoices.map((shipmentInvoice) => {
              if (shipmentInvoice.id !== payload.id) return shipmentInvoice;
              return payload;
            })
          : [...state.shipmentInvoices, payload],
      };
      return {
        ...updatedShipment,
        ...calculator.reCalcShipemntTotal(updatedShipment),
      };

    case "createShipmentInvoice":
      return {
        ...state,
        shipmentInvoices: [...state.shipmentInvoices, payload],
      };

    case "createShipmentInvoiceRow":
      return {
        ...state,
        shipmentInvoices: state.shipmentInvoices.map((shipmentInvoice) => {
          if (shipmentInvoice.id !== payload.shipmentInvoiceId) return shipmentInvoice;
          return {
            ...shipmentInvoice,
            shipmentInvoiceRows: [...shipmentInvoice.shipmentInvoiceRows, payload.shipmentInvoiceRow],
          };
        }),
      };

    case "changeShipmentInvoiceRowProduct":
      console.log("changeShipmentInvoiceRowProduct", payload, state);
      return {
        ...state,
        shipmentInvoices: state.shipmentInvoices.map((shipmentInvoice) => {
          if (shipmentInvoice.id !== payload.shipmentInvoiceRow.shipmentInvoice.id) return shipmentInvoice;
          return {
            ...shipmentInvoice,
            shipmentInvoiceRows: shipmentInvoice.shipmentInvoiceRows.map((shipmentInvoiceRow) => {
              if (shipmentInvoiceRow.id !== payload.shipmentInvoiceRow.id) return shipmentInvoiceRow;
              return { ...shipmentInvoiceRow, ...payload.shipmentInvoiceRow };
            }),
          };
        }),
      };

    case "createShipmentInvoiceRows":
      return {
        ...state,
        shipmentInvoices: payload.shipmentInvoices,
      };

    case "deleteShipmentInvoice":
      updatedShipment = {
        ...state,
        shipmentInvoices: state.shipmentInvoices.filter((i) => i.id !== payload),
      };
      return {
        ...updatedShipment,
        ...calculator.reCalcShipemntTotal(updatedShipment),
      };

    case "deleteShipmentInvoiceRow":
      updatedShipment = {
        ...state,
        shipmentInvoices: state.shipmentInvoices.map((shipmentInvoice) => {
          if (shipmentInvoice.id !== payload.shipmentInvoiceId) return shipmentInvoice;
          return {
            ...shipmentInvoice,
            shipmentInvoiceRows: shipmentInvoice.shipmentInvoiceRows.filter((row) => row.id !== payload.shipmentInvoiceRowId),
          };
        }),
      };
      return {
        ...updatedShipment,
        ...calculator.reCalcShipemntTotal(updatedShipment),
      };

    case "deleteShipmentInvoicePo":
      updatedShipment = {
        ...state,
        shipmentInvoices: state.shipmentInvoices.map((shipmentInvoice) => {
          if (shipmentInvoice.id !== payload.shipmentInvoiceId) return shipmentInvoice;
          return {
            ...shipmentInvoice,
            pos: shipmentInvoice.pos.filter((i) => i.id !== payload.poId),
            shipmentInvoiceRows: shipmentInvoice.shipmentInvoiceRows.filter((row) => row.purchaseOrder.id !== payload.poId),
          };
        }),
      };
      return {
        ...updatedShipment,
        ...calculator.reCalcShipemntTotal(updatedShipment),
      };

    case "onChangeShipmentInvoiceRow":
      updatedShipment = {
        ...state,
        shipmentInvoices: state.shipmentInvoices.map((shipmentInvoice) => {
          if (shipmentInvoice.id !== payload.shipmentInvoiceId) return shipmentInvoice;
          return {
            ...shipmentInvoice,
            shipmentInvoiceRows: shipmentInvoice.shipmentInvoiceRows.map((shipmentInvoiceRow) => {
              if (shipmentInvoiceRow.id !== payload.shipmentInvoiceRowId) return shipmentInvoiceRow;
              const newRow = {
                ...shipmentInvoiceRow,
                [payload.key]: payload.value,
              };
              return {
                ...newRow,
                ...calculator.calcRowTotal(newRow),
              };
            }),
          };
        }),
      };
      return {
        ...updatedShipment,
        ...calculator.reCalcShipemntTotal(updatedShipment),
      };
    case "updateShipmentInvoiceRowProduct":
      updatedShipment = {
        ...state,
        shipmentInvoices: state.shipmentInvoices.map((shipmentInvoice) => {
          if (shipmentInvoice.id !== payload.shipmentInvoiceRow.shipmentInvoice.id) return shipmentInvoice;
          return {
            ...shipmentInvoice,
            shipmentInvoiceRows: shipmentInvoice.shipmentInvoiceRows.map((shipmentInvoiceRow) => {
              if (shipmentInvoiceRow.id !== payload.shipmentInvoiceRow.id) return shipmentInvoiceRow;
              return payload.shipmentInvoiceRow;
            }),
          };
        }),
      };
      return {
        ...updatedShipment,
        ...calculator.reCalcShipemntTotal(updatedShipment),
      };
    case "updateShipmentInvoiceRowProductData":
      updatedShipment = {
        ...state,
        shipmentInvoices: state.shipmentInvoices.map((shipmentInvoice) => {
          if (shipmentInvoice.id !== payload.shipmentInvoiceId) return shipmentInvoice;
          return {
            ...shipmentInvoice,
            shipmentInvoiceRows: shipmentInvoice.shipmentInvoiceRows.map((shipmentInvoiceRow) => {
              if (shipmentInvoiceRow.id !== payload.shipmentInvoiceRowId) return shipmentInvoiceRow;
              const newRow = {
                ...shipmentInvoiceRow,
                product: payload.product,
                packingName: payload.product.name,
                newProductItemNumber: payload.product.number,
                newProductQtyPerCarton: payload.product.quantityPerCarton,
                newProductNetWeight: payload.product.ctnNetWeight,
                newProductGrossWeight: payload.product.ctnGrossWeight,
                newProductCbm: payload.product.outerCartonCbm,
                newProductValue: payload.product.customsValue,
                hsCode: payload.product.hsCode,
              };
              return {
                ...newRow,
                ...calculator.calcRowTotal(newRow),
              };
            }),
          };
        }),
      };
      return {
        ...updatedShipment,
        ...calculator.reCalcShipemntTotal(updatedShipment),
      };
    case "addedShipmentAttachment":
      return {
        ...state,
        attachments: state.attachments.map((a, aIndex) => {
          if (aIndex !== payload.index) return a;
          return payload.attachment;
        }),
      };
    case "deleteShipmentAttachments":
      return {
        ...state,
        attachments: state.attachments.filter((_, index) => index !== payload),
      };
    case "onSelectAttachments":
      return {
        ...state,
        attachments: [...state.attachments, ...payload],
      };
    case "didUploadFileToAws":
      return {
        ...state,
        attachments: state.attachments.map((a, aIndex) => {
          if (aIndex !== payload.index) return a;
          return { ...a, uri: payload.url };
        }),
      };
    case "createdInternalTransfer":
      const current = state.odooData.internalTransfers || [];
      const newInternalTransfers = [...current, payload];
      return {
        ...state,
        odooData: {
          ...state.odooData,
          internalTransfers: newInternalTransfers,
        },
      };
    default:
      return state;
  }
};

export default reducer;
