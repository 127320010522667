import React from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import Page from "components/Page";
import ABStockProducts from "./ABStockProducts";
import { NavigationLink } from "components/base";
import CostWithOdoo from "./CostWithOdoo";
import OdooConnection from "./OdooConnection";
import WisProductImages from "./WisProductImages";
import ShippingMark from "./ShippingMark";
import OdooProductsNotCreatedByStella from "./OdooProductsNotCreatedByStella";
import ProductsOnSale from "./ProductsOnSale";
import ProductTestReports from "./ProductTestReports";
import ProductListTestReports from "./ProductListTestReports";
import ProductLineTestReports from "./ProductLineTestReports";
import IncProducts from "./incProducts";

function Validator() {
  return (
    <Routes>
      <Route path="*" element={<Layout />}>
        <Route path="odoo-connections" element={<OdooConnection />} />
        <Route path="cost-with-odoo" element={<CostWithOdoo />} />
        <Route path="ab-products" element={<ABStockProducts />} />
        <Route path="wis-product-images" element={<WisProductImages />} />
        <Route path="shipping-mark" element={<ShippingMark />} />
        <Route path="product-test-reports" element={<ProductTestReports />} />
        <Route path="product-list-test-reports" element={<ProductListTestReports />} />
        <Route path="product-line-test-reports" element={<ProductLineTestReports />} />
        <Route path="odoo-products-not-created-by-stella" element={<OdooProductsNotCreatedByStella />} />
        <Route path="products-on-sale" element={<ProductsOnSale />} />
        <Route path="inc-products" element={<IncProducts />} />
      </Route>
    </Routes>
  );
}

const LeftButtons = () => (
  <div className="flex space-x-4">
    <NavigationLink to="/validate/odoo-products-not-created-by-stella" title="Odoo Products Creation" />
    <NavigationLink to="/validate/products-on-sale" title="Products On Sale" />
    <NavigationLink to="/validate/odoo-connections" title="Odoo Connections" />
    <NavigationLink to="/validate/cost-with-odoo" title="Cost with Odoo" />
    <NavigationLink to="/validate/ab-products" title="AB Products" />
    <NavigationLink to="/validate/wis-product-images" title="Product Images" />
    <NavigationLink to="/validate/shipping-mark" title="Shipping Mark" />
    <NavigationLink to="/validate/product-list-test-reports" title="Product List Test Reports" />
    <NavigationLink to="/validate/product-test-reports" title="Product Test Reports" />
    <NavigationLink to="/validate/product-line-test-reports" title="Product Line Test Reports" />
    <NavigationLink to="/validate/inc-products" title="INC Products" />
  </div>
);

const Layout = () => (
  <Page leftButtons={<LeftButtons />} className="lg:h-screen">
    <Outlet />
  </Page>
);

export default Validator;
