import { useState } from 'react';

function useDateRange(months = 6) {
   let monthsAgo = new Date();
   monthsAgo.setMonth(monthsAgo.getMonth() - months);

   const [start, setStart] = useState(monthsAgo);
   const [end, setEnd] = useState(new Date());

   return [
      { start, end },
      { setStart, setEnd },
   ];
}

export function useDateRangeDaysAgo(days = 7) {
   let daysAgo = new Date();
   daysAgo.setDate(daysAgo.getDate() - days);

   const [start, setStart] = useState(daysAgo);
   const [end, setEnd] = useState(new Date());

   console.log('start, end', start, end);

   return [
      { start, end },
      { setStart, setEnd },
   ];
}

export function useDateRangeFrom(startDate, endDate = new Date()) {
   const [start, setStart] = useState(startDate);
   const [end, setEnd] = useState(endDate);

   return [
      { start, end },
      { setStart, setEnd },
   ];
}

export default useDateRange;
