import { DatePicker, Input, Text } from "components/Form";

const PLInput = ({ className = "", label, value, onChange, trailingLabel, inputType = "text", ...rest }) => (
  <div className={`flex items-center mb-4 ${className}`}>
    <label htmlFor="" className="w-40 inline-block whitespace-normal">
      {label}:
    </label>
    {inputType === "textArea" ? (
      <Text className="ml-4 flex-1" value={value} onChange={onChange} />
    ) : inputType === "datePicker" ? (
      <DatePicker className="ml-4" value={value ? new Date(value) : ""} onDayChange={onChange} />
    ) : (
      <Input className="ml-4 flex-1" value={value} onChange={onChange} {...rest} />
    )}
    {trailingLabel ? <span className="ml-2">{trailingLabel}</span> : null}
  </div>
);

export default PLInput;
