import React, { useContext } from 'react';
import OhsAnalytics from './OhsAnalytics';
import OhsUsage from './OhsUsage';
import OhsCompanyUsage from './OhsCompanyUsage';
import { BizAnalyticsContext } from '..';

function BizAnalyticsIndex() {
   const { start, end } = useContext(BizAnalyticsContext);
   return (
      <div className="my-6">
         <OhsAnalytics start={start} end={end} />
         <div className="lg:flex mt-6">
            <div className="flex-1 lg:mr-6">
               <OhsUsage start={start} end={end} />
            </div>
            <div className="mt-4 lg:mt-0 flex-1">
               <OhsCompanyUsage start={start} end={end} />
            </div>
         </div>
      </div>
   );
}

export default BizAnalyticsIndex;
