import { NavigationLink } from 'components/base';
import Page from 'components/Page';
import React from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import SubSupplierInvoices from './invoices';
import SubSupplierInvoiceDetail from './invoices/Detail';
import SubSupplierOrders from './orders';
import SubSupplierOrderDetail from './orders/Detail';
import SubSupplierPayments from './payments';
import SubSupplierPaymentDetail from './payments/Detail';
import SubSupplierProducts from './products';

function SubSupplierPage() {
   return (
      <Routes>
         <Route element={<Layout />}>
            <Route path="orders" element={<SubSupplierOrders />} />
            <Route path="invoices" element={<SubSupplierInvoices />} />
            <Route path="payments" element={<SubSupplierPayments />} />
            <Route path="products" element={<SubSupplierProducts />} />
         </Route>

         <Route path="orders/:id" element={<SubSupplierOrderDetail />} />
         <Route path="invoices/:id" element={<SubSupplierInvoiceDetail />} />
         <Route path="payments/:id" element={<SubSupplierPaymentDetail />} />
         <Route path="*" element={<Navigate to="orders" />} />
      </Routes>
   );
}

const Layout = () => (
   <Page
      leftButtons={
         <div className="space-x-4">
            <NavigationLink to="/sub-supplier/orders">Orders</NavigationLink>
            <NavigationLink to="/sub-supplier/invoices">
               Invoices
            </NavigationLink>
            <NavigationLink to="/sub-supplier/payments">
               Payments
            </NavigationLink>
            <NavigationLink to="/sub-supplier/products">
               Products
            </NavigationLink>
         </div>
      }
   >
      <Outlet />
   </Page>
);

export default SubSupplierPage;
