import { gql, useMutation } from '@apollo/client';
import { Button } from 'components/base';
import Errors from 'components/Errors';
import Spinner from 'components/Spinner';
import { Alert } from 'components/Toast';
import useProduction, { FETCH_ALL_PRODUCTION_NODES } from 'hooks/useProduction';
import { useModals } from 'ModalProvider';
import { ArcherContainer, ArcherElement } from 'react-archer';
import { byName } from 'utils/sort';
import EditProduction from './EditProduction';
import ProductionEditor from './ProductionEditor';

const ProductionNodes = () => {
   const { loading, error, allProductionNodes } = useProduction();
   const modal = useModals();

   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;

   const nodes = allProductionNodes
      .filter((i) => i.name && i.parent === null)
      .sort(byName);

   return (
      <div className="p-6 space-y-6">
         <div>
            <Button
               title="+ Create New Production"
               size="base"
               onClick={() =>
                  modal.present({
                     title: 'Create New Production',
                     maxWidth: 'max-w-7xl',
                     children: <ProductionEditor />,
                  })
               }
            />
         </div>
         <div className="space-y-8">
            {nodes.map((i) => (
               <Row
                  key={i.id}
                  node={i}
                  allProductionNodes={allProductionNodes}
                  edit={() =>
                     modal.present({
                        title: 'Edit',
                        maxWidth: 'max-w-7xl',
                        children: <EditProduction id={i.id} />,
                     })
                  }
               />
            ))}
         </div>
      </div>
   );
};

const DELETE_PRODUCTION = gql`
   mutation DELETE_PRODUCTION($id: ID!) {
      deleteProduction(id: $id) {
         message
      }
   }
`;

const Row = ({ node, allProductionNodes, edit }) => {
   const [deleteProduction, { loading }] = useMutation(DELETE_PRODUCTION, {
      variables: { id: node.id },
      onCompleted: () => Alert('success', 'Deleted.'),
      onError: (error) => Alert('error', error.message),
      update: (cache) => {
         const prev = cache.readQuery({ query: FETCH_ALL_PRODUCTION_NODES });
         const data = {
            ...prev,
            allProductionNodes: prev.allProductionNodes.filter(
               (i) => i.id !== node.id
            ),
         };
         cache.writeQuery({ query: FETCH_ALL_PRODUCTION_NODES, data });
      },
   });

   function deleteHandler() {
      if (window.confirm('Are you sure to delete this production?')) {
         deleteProduction();
      }
   }

   return (
      <div className="bg-white dark:bg-gray-800 p-8 rounded-3xl">
         <div className="flex justify-between items-center">
            <h4>{node.name}</h4>

            <div className="flex space-x-4">
               <Button title="Edit" onClick={edit} />
               <Button
                  color="red"
                  title="Delete"
                  onClick={deleteHandler}
                  loading={loading}
                  disabled={loading}
               />
            </div>
         </div>
         <div className="mt-8">
            <ArcherContainer strokeColor="#aaa" strokeWidth={1}>
               <ProductionNode
                  node={node}
                  allProductionNodes={allProductionNodes}
               />
            </ArcherContainer>
         </div>
      </div>
   );
};

const ProductionNode = ({ node, allProductionNodes }) => {
   const children = allProductionNodes.filter(
      (i) => i.parent && i.parent.id === node.id
   );
   return (
      <div className="flex items-center space-x-44">
         {children.length > 0 ? (
            <div className="dark:border-gray-700 space-y-6 flex flex-col items-start">
               {children.map((i, index) => (
                  <ProductionNode
                     key={index}
                     node={i}
                     allProductionNodes={allProductionNodes}
                  />
               ))}
            </div>
         ) : null}

         <ArcherElement
            id={node.id}
            relations={
               node.parent
                  ? [
                       {
                          targetId: node.parent ? node.parent.id : null,
                          targetAnchor: 'left',
                          sourceAnchor: 'right',
                       },
                    ]
                  : []
            }
         >
            <div className="bg-gray-100 dark:bg-gray-900 px-6 py-3 rounded-xl">
               <div className="flex items-center space-x-2">
                  <div>
                     <div className="font-bold">
                        {node.category.factory.name}
                     </div>
                     <div className="mt-1">{node.category.name}</div>
                     <div className="text-xs mt-1">
                        Transfer: {node.daysToTransfer} days.
                     </div>
                  </div>
               </div>
            </div>
         </ArcherElement>
      </div>
   );
};

export default ProductionNodes;
