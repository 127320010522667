import { gql, useQuery } from "@apollo/client";
import { InlineSpinner } from "components/Spinner";
import Errors from "components/Errors";
import { Button } from "components/base";
import { useModals } from "ModalProvider";
import { IoIosInformationCircleOutline } from "react-icons/io";
import moment from "moment";
import CharlesButton from "components/charles/base";

const FETCH_EXPEDITOR_SHIPMENT = gql`
  query FETCH_EXPEDITOR_SHIPMENT($id: String!) {
    expeditorShipment(id: $id)
    expeditorShipmentEvents(id: $id)
  }
`;

const ExpeditorInfo = ({ blNumber }) => {
  const modal = useModals();
  return (
    <CharlesButton
      icon={<IoIosInformationCircleOutline size={18} />}
      onClick={() => {
        modal.present({
          title: "Expeditor Shipment BL No. " + blNumber,
          maxWidth: "max-w-7xl",
          children: <ExpeditorShipment blNumber={blNumber} />,
        });
      }}
    >
      Expeditors Info
    </CharlesButton>
  );
};

const ExpeditorShipment = ({ blNumber }) => {
  const { loading, error, data } = useQuery(FETCH_EXPEDITOR_SHIPMENT, {
    variables: { id: blNumber },
  });

  if (loading) return <InlineSpinner size={21} />;
  if (error) return <Errors error={error} />;

  const shipment = JSON.parse(data.expeditorShipment);
  const events = JSON.parse(data.expeditorShipmentEvents).sort((a, b) => new Date(a.time) - new Date(b.time));

  return (
    <div className="flex space-x-6 items-start text-sm">
      <div className="bg-white dark:bg-gray-900 p-6 rounded-2xl shadow">
        <table className="with-line -mx-2">
          <tbody>
            <tr>
              <td>
                <label htmlFor="">Carrier: </label>
              </td>
              <td className="w-full">{shipment.carrier.name}</td>
            </tr>
            <tr>
              <td>
                <label htmlFor="">Clearance: </label>
              </td>
              <td>{shipment.clearance.name}</td>
            </tr>
            <tr>
              <td>
                <label htmlFor="">Consignee: </label>
              </td>
              <td>{shipment.consignee}</td>
            </tr>
            <tr>
              <td>
                <label htmlFor="">Delivery: </label>
              </td>
              <td>{shipment.delivery.name}</td>
            </tr>
            <tr>
              <td>
                <label htmlFor="">Destination: </label>
              </td>
              <td>{shipment.destination.name}</td>
            </tr>
            <tr>
              <td>
                <label htmlFor="">Freight Terms: </label>
              </td>
              <td>{shipment.freightTerms}</td>
            </tr>
            <tr>
              <td>
                <label htmlFor="">Goods Desc: </label>
              </td>
              <td>{shipment.goodsDesc}</td>
            </tr>
            <tr>
              <td>
                <label htmlFor="">Loading: </label>
              </td>
              <td>{shipment.loading.name}</td>
            </tr>
            <tr>
              <td>
                <label htmlFor="">Origin: </label>
              </td>
              <td>{shipment.origin.name}</td>
            </tr>
            <tr>
              <td>
                <label htmlFor="">Mode: </label>
              </td>
              <td>{shipment.mode}</td>
            </tr>
            <tr>
              <td>
                <label htmlFor="">Quantity Units: </label>
              </td>
              <td>{shipment.quantity.units}</td>
            </tr>
            <tr>
              <td>
                <label htmlFor="">Consignee: </label>
              </td>
              <td>{shipment.consignee}</td>
            </tr>
            <tr>
              <td>
                <label htmlFor="">Security Filing Party: </label>
              </td>
              <td>{shipment.securityFilingParty}</td>
            </tr>
            <tr>
              <td>
                <label htmlFor="">Service Type: </label>
              </td>
              <td>{shipment.serviceType}</td>
            </tr>
            <tr>
              <td>
                <label htmlFor="">Shipper: </label>
              </td>
              <td>{shipment.shipper}</td>
            </tr>
            <tr>
              <td>
                <label htmlFor="">Status: </label>
              </td>
              <td>{shipment.status}</td>
            </tr>
            <tr>
              <td>
                <label htmlFor="">Unlanding: </label>
              </td>
              <td>{shipment.unlading.name}</td>
            </tr>
            <tr>
              <td>
                <label htmlFor="">Vessel: </label>
              </td>
              <td>{shipment.vessel}</td>
            </tr>
            <tr>
              <td>
                <label htmlFor="">Volume: </label>
              </td>
              <td>
                {shipment.volume.value} {shipment.volume.units}
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="">Weight: </label>
              </td>
              <td>
                {shipment.weight.value} {shipment.weight.units}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="bg-white dark:bg-gray-900 p-6 rounded-2xl shadow flex-1">
        <h4>Events</h4>
        <table className="with-line mt-4 -mx-2">
          <tbody>
            {events.map((e, index) => (
              <tr key={index}>
                <td className="whitespace-nowrap">{moment(e.time).format("YYYY-MM-DD hh:mm:ss")}</td>
                <td>{e.description}</td>
                <td>{e.remarks}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ExpeditorInfo;
