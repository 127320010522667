import { gql, useLazyQuery } from "@apollo/client";
import { Alert } from "components/Toast";
import { useState } from "react";

const UPLOAD_URL = "https://up-z2.qiniup.com";

const FETCH_PRESIGNED_URL = gql`
  query FETCH_PRESIGNED_URL($filename: String!) {
    qiniuPresignedUrl(filename: $filename) {
      token
      key
      url
    }
  }
`;

function uploadFile(qiniuPresignedUrl, file) {
  console.log("uploadFile", qiniuPresignedUrl, file);
  return new Promise((resolve, reject) => {
    let xhr = new XMLHttpRequest();
    xhr.open("POST", UPLOAD_URL, true);
    let formData = new FormData();
    formData.append("key", qiniuPresignedUrl.key);
    formData.append("token", qiniuPresignedUrl.token);
    formData.append("file", file);
    xhr.upload.addEventListener(
      "progress",
      function (evt) {
        if (evt.lengthComputable) {
          console.log("process", Math.round((evt.loaded * 100) / evt.total));
        }
      },
      false
    );
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200 && xhr.responseText !== "") {
        resolve(qiniuPresignedUrl.url);
      } else if (xhr.readyState === 4) {
        reject(new Error("Upload failed with status " + xhr.status));
      }
    };
    xhr.send(formData);
  });
}

function useFileUploader(complete) {
  const [fetchPresignedUrl] = useLazyQuery(FETCH_PRESIGNED_URL);
  const [isUploading, setIsUploading] = useState(false);

  function handleFileUpload(files) {
    setIsUploading(true);
    let promises = [];
    files.forEach((file) => {
      promises.push(
        new Promise((onCompleted, onError) => {
          // remove comma from filename
          const filename = file.name.replace(/,/g, "");
          fetchPresignedUrl({ variables: { filename } })
            .then((res) => uploadFile(res.data.qiniuPresignedUrl, file))
            .then(onCompleted)
            .catch(onError);
        })
      );
    });

    Promise.all(promises)
      .then((urls) => {
        console.log("urls", urls);
        complete(urls);
      })
      .catch((err) => {
        Alert("error", err.message);
      })
      .finally(() => {
        setIsUploading(false);
      });
  }

  return { isUploading, handleFileUpload };
}

export default useFileUploader;
