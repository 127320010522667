import { Input } from "components/Form";
import { InlineSpinner } from "components/Spinner";
import { useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { SAVE_ASSESSMENT } from "./graphql";
import { useMutation } from "@apollo/client";
import { parseError } from "apollo";
import { Alert } from "components/Toast";

const AssessmentExtraInfoView = ({ assessment }) => {
  console.log("assessment", assessment);
  const [extraInfo, setExtraInfo] = useState({
    lowestWagesPerHour: assessment.lowestWagesPerHour,
    lowestWagesPerMonth: assessment.lowestWagesPerMonth,
  });

  const [saveAssessment, saveAssessmentRes] = useMutation(SAVE_ASSESSMENT, {
    onCompleted() {
      Alert("success", "Assessment Updated.");
    },
    onError(error) {
      Alert("error", parseError(error));
    },
  });

  const debouncedSave = useDebouncedCallback(saveAssessment, 1000);

  return (
    <div className="card px-6 py-4">
      {saveAssessmentRes.loading && (
        <div className=" absolute top-4 right-4">
          <InlineSpinner size={16} text={null} />
        </div>
      )}

      <h5>Extra Info</h5>
      <div className="mt-2 space-y-2">
        <div className="space-x-3">
          <label>Lowest Wages / Month: </label>
          <Input
            className="text-center w-20"
            value={extraInfo.lowestWagesPerHour}
            onChange={(e) => {
              setExtraInfo({ ...extraInfo, lowestWagesPerHour: e.target.value });
              const updatedExtraInfo = { ...extraInfo, lowestWagesPerHour: e.target.value };
              debouncedSave({
                variables: { id: assessment.id, extraInfo: updatedExtraInfo },
              });
            }}
          />
        </div>

        <div className="space-x-3">
          <label>Lowest Wages / Hour: </label>
          <Input
            className="text-center w-20"
            value={extraInfo.lowestWagesPerMonth}
            onChange={(e) => {
              setExtraInfo({ ...extraInfo, lowestWagesPerMonth: e.target.value });
              const updatedExtraInfo = { ...extraInfo, lowestWagesPerMonth: e.target.value };
              debouncedSave({
                variables: { id: assessment.id, extraInfo: updatedExtraInfo },
              });
            }}
          />
        </div>
      </div>
      <div className="mt-4 opacity-70 text-xs">This info will be used for score computing.</div>
    </div>
  );
};

export default AssessmentExtraInfoView;
