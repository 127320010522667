import { gql, useMutation } from '@apollo/client';
import { Button } from 'components/base';
import { Field } from 'components/Form';
import OdooProductSelector from 'components/OdooProductSelector';
import { Alert } from 'components/Toast';
import { useModals } from 'ModalProvider';
import { useState } from 'react';
import OdooProduct from './OdooProduct';

const CREATE_AMZ_STOCK = gql`
   mutation CREATE_AMZ_STOCK(
      $name: String
      $number: String
      $asin: String
      $barcode: String
      $qtyPerMaster: Int
      $unitCost: Float
      $initialQty: Int
      $categoryId: ID
      $odooId: Int
   ) {
      amzCreateStock(
         name: $name
         number: $number
         asin: $asin
         barcode: $barcode
         qtyPerMaster: $qtyPerMaster
         unitCost: $unitCost
         initialQty: $initialQty
         categoryId: $categoryId
         odooId: $odooId
      ) {
         stock {
            id
         }
      }
   }
`;

const NewStockForm = ({ categoryId, hide }) => {
   const [odooId, setOdooId] = useState(null);
   const [name, setName] = useState('');
   const [number, setNumber] = useState('');
   const [asin, setAsin] = useState('');
   const [barcode, setBarcode] = useState('');
   const [qtyPerMaster, setQtyPerMaster] = useState('');
   const [unitCost, setUnitCost] = useState('');
   const [initialQty, setInitialQty] = useState('');

   const modal = useModals();

   const [create, createRes] = useMutation(CREATE_AMZ_STOCK, {
      variables: {
         name,
         number,
         asin,
         barcode,
         qtyPerMaster,
         unitCost,
         initialQty,
         categoryId,
         odooId,
      },
      refetchQueries: ['FETCH_AMAZON_STOCK_CATEGORY'],
      awaitRefetchQueries: true,
      onCompleted: () => {
         Alert('success', 'New Stock Created.');
         hide();
      },
      onError(error) {
         Alert('error', error.message);
      },
   });

   function showOdooProductSelector() {
      modal.present({
         title: 'Select Odoo Product',
         maxWidth: 'max-w-5xl',
         children: (
            <OdooProductSelector
               onSelect={(id) => {
                  setOdooId(id);
                  modal.hide();
               }}
            />
         ),
      });
   }

   return (
      <div>
         <div className="space-y-4">
            <Field label="Name" value={name} onChange={setName} />

            <div className="flex items-baseline space-y-2">
               <label className="pr-4">Odoo Product: </label>
               <div>
                  {odooId ? (
                     <div>
                        <OdooProduct id={odooId} />
                        <div className="mt-2 flex space-x-6">
                           <Button
                              title="Change Odoo Product"
                              onClick={showOdooProductSelector}
                           />
                           <Button
                              color="red"
                              title="Remove Connection"
                              onClick={() => setOdooId(null)}
                           />
                        </div>
                     </div>
                  ) : (
                     <Button
                        title="Connect Odoo Product"
                        onClick={showOdooProductSelector}
                     />
                  )}
               </div>
            </div>

            <div className="flex space-x-8">
               <div className="flex-1">
                  <Field label="ASIN" value={asin} onChange={setAsin} />
               </div>
               <div className="flex-1">
                  <Field label="Number" value={number} onChange={setNumber} />
               </div>
            </div>
            <div className="flex space-x-8">
               <div className="flex-1">
                  <Field
                     label="Barcode"
                     value={barcode}
                     onChange={setBarcode}
                  />
               </div>
               <div className="flex-1">
                  <Field
                     label="Qty / Master"
                     value={qtyPerMaster}
                     onChange={setQtyPerMaster}
                  />
               </div>
            </div>
            <div className="flex space-x-8">
               <div className="flex-1">
                  <Field
                     label="Unit Cost"
                     value={unitCost}
                     onChange={setUnitCost}
                  />
               </div>
               <div className="flex-1">
                  <Field
                     label="Initial Qty"
                     value={initialQty}
                     onChange={setInitialQty}
                  />
               </div>
            </div>
         </div>

         <div className="mt-8">
            <Button
               title="Create"
               bold
               size="xl"
               onClick={() => create()}
               loading={createRes.loading}
               disabled={createRes.loading}
            />
         </div>
      </div>
   );
};

export default NewStockForm;
