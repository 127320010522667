import React from "react";
import convertUnits from "utils/converter";
import Image from "components/Image";

export default [
  {
    field: "images",
    displayName: "Images",
    value: (product) => {
      if (!product.images) return product.id;
      return (
        <div className="flex items-center justify-center relative">
          {product.images.map((image, index) => (
            <a key={product.id + "-" + index} className="block mx-3" href={image.url} target="_blank" rel="noopener noreferrer">
              <Image size="w-12 h-12 z-0" src={image.thumb} />
            </a>
          ))}
        </div>
      );
    },
  },
  { field: "number", displayName: "Item Number" },
  { field: "quantityPerDisplayBox", displayName: "Qty/Display Box" },
  { field: "quantityPerCarton", displayName: "Qty/Carton" },
  {
    field: "itemWeight",
    displayName: "Item Weight",
    value: (product, metric) => {
      return convertUnits([product.itemWeight], "g", "lb", metric);
    },
  },
  {
    field: "grossWeight",
    displayName: "Gross Weight",
    value: (product, metric) => convertUnits([product.grossWeight], "g", "lb", metric),
  },
  {
    field: "itemSize",
    displayName: "Item Size",
    value: (product, metric) => {
      if (product.shape === "ball") {
        return convertUnits([product.ballDiameter], "mm", "in", metric);
      } else if (product.shape === "cube") {
        return convertUnits([product.cubeX, product.cubeZ, product.cubeY], "mm", "in", metric);
      } else if (product.shape === "flat") {
        return convertUnits([product.flatX, product.flatY], "mm", "in", metric);
      } else if (product.shape === "multi") {
        return product.multiSizes;
      }
      return convertUnits([product.cubeX, product.cubeZ, product.cubeY], "mm", "in", metric);
    },
  },
  {
    field: "hsCode",
    displayName: "HS Code",
    value: (product) => {
      if (!product.productLine) return "";
      return `${product.productLine.hsCode} ${product.productLine.hsCodeForEu ? `EU: ${product.productLine.hsCodeForEu}` : ""} ${
        product.productLine.hsCodeForUs ? `US: ${product.productLine.hsCodeForUs}` : ""
      }`;
    },
  },
  {
    field: "productMaterial",
    displayName: "Product Material",
    value: (product) => {
      if (product && product.productLine && product.productLine.children) {
        if (product.productLine.children.length > 0) {
          return product.productLine.children.map((i) => `- ${i.name}: ${i.materials.map((j) => j.material.name).join(", ")}`).join("\n");
        }
        return product.productLine.materials.map((i) => i.material.name).join(", ");
      }
      return " - ";
    },
  },
  { field: "pantone", displayName: "Pantone" },
  {
    field: "age",
    displayName: "Age Grading",
    value: (product) => {
      if (!product.productLine) return "";
      if (product.productLine.children.length > 0) {
        return product.productLine.children.map((i) => `- ${i.name}: ${i.age}`).join("\n");
      }
      return product.productLine.age;
    },
  },
  {
    field: "warnings",
    displayName: "Warnings",
    value: (product) => {
      return product.warnings.map((i) => i.name).join(", ");
    },
  },
  {
    field: "labTestStandards",
    displayName: "Test Reports",
    value: (product) => {
      return product.labTestStandards.map((i) => i.name).join(", ");
    },
  },
  { field: "moq", displayName: "MOQ" },
  { field: "packing", displayName: "Packing" },
  {
    field: "innerBoxSize",
    displayName: "Inner Box/Clam Shell Size",
    value: (product, metric) => convertUnits([product.innerBoxX, product.innerBoxZ, product.innerBoxY], "mm", "in", metric),
  },
  {
    field: "barcodes",
    displayName: "Barcodes",
    value: (product) => {
      let res = "";
      if (product.innerBoxBarcodeEan) {
        res += `Inner Box(EAN): ${product.innerBoxBarcodeEan}\n`;
      }
      if (product.innerBoxBarcodeUpc) {
        res += `Inner Box(UPC): ${product.innerBoxBarcodeUpc}\n`;
      }
      if (product.innerCartonBarcodeEan) {
        res += `Inner Carton(EAN/GTIN14): ${product.innerCartonBarcodeEan}\n`;
      }
      if (product.innerCartonBarcodeUpc) {
        res += `Inner Carton(UPC/GTIN14): ${product.innerCartonBarcodeUpc}\n`;
      }
      if (product.outerCartonBarcodeEan) {
        res += `Outer Carton(EAN/GTIN14): ${product.outerCartonBarcodeEan}\n`;
      }
      if (product.outerCartonBarcodeUpc) {
        res += `Outer Carton(UPC/GTIN14): ${product.outerCartonBarcodeUpc}\n`;
      }
      return res;
    },
  },
  {
    field: "displayBoxSize",
    displayName: "Display Box Size",
    value: (product, metric) => convertUnits([product.displayBoxX, product.displayBoxZ, product.displayBoxY], "mm", "in", metric),
  },
  {
    field: "innerCartonSize",
    displayName: "Inner Carton Size",
    value: (product, metric) => convertUnits([product.innerCartonX, product.innerCartonZ, product.innerCartonY], "mm", "in", metric),
  },
  {
    field: "outerCartonSize",
    displayName: "Outer Carton Size",
    value: (product, metric) => convertUnits([product.outerCartonX, product.outerCartonZ, product.outerCartonY], "cm", "in", metric),
  },
  {
    field: "ctnNetWeight",
    displayName: "CTN Net Weight",
    value: (product, metric) => convertUnits([product.ctnNetWeight], "kg", "lb", metric),
  },
  {
    field: "ctnGrossWeight",
    displayName: "CTN Gross Weight",
    value: (product, metric) => convertUnits([product.ctnGrossWeight], "kg", "lb", metric),
  },
  {
    field: "outerCartonCbm",
    displayName: "Outer Carton CBM",
    value: (product, metric) => convertUnits([product.outerCartonCbm], "m3", "ft3", metric),
  },
];
