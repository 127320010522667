const NOTES_TEMPLATES = [
  `Dear {customername},

Your shipment for #{invoicenumber} was entered into our system biz.waboba.com today, you can go to this website to check the order status and download initial packing list now. Please confirm the forwarder contact details for this shipment ASAP.

Final packing lit & commercial invoice for custom clearance will be ready for download within 2 days after ETD.

Just follow the 4 steps below to download the documents.
1. Login biz.waboba.com with your account.
2. Go to "Orders" page.
3. Click the invoice number line.
4. Scroll down to the bottom and click "Download packing list" and "Download commercial invoice".

Any question please let us know freely.

Waboba Logistics Team
`,
  `Dear {customername},

The final packing list & commercial invoice for customs clearance were ready for download now.

You can go to this website(https://biz.waboba.com) to check the order status and download the shipment documents.

Any question please let us know freely.

Waboba Logistics Team
`,
];  // No spaces are required at the beginning of the text content

const NotifyCustomerTemplateList = ({ onSelect }) => {
  return (
    <div className="space-y-6 whitespace-pre-wrap">
      {NOTES_TEMPLATES.map((i, index) => (
        <div
          className="card cursor-pointer hover:bg-blue-100 "
          onClick={() => {
            onSelect(i);
          }}
        >
          <h4>Template {index + 1}</h4>
          <div className="mt-4">{i}</div>
        </div>
      ))}
    </div>
  );
};

export default NotifyCustomerTemplateList;
