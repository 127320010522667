import { gql, useQuery } from '@apollo/client';
import Errors from 'components/Errors';
import Spinner from 'components/Spinner';
import { BsArrowDownCircleFill, BsArrowUpCircleFill } from 'react-icons/bs';
import { randomColors } from 'utils/colors';
import {
   LineChart,
   Line,
   ResponsiveContainer,
   XAxis,
   YAxis,
   Tooltip,
   Legend,
} from 'recharts';
import { FRAGMENT_PRODUCT_FOR_COST } from 'graphql/fragments';
import { getDates } from 'utils/date';
import moment from 'moment';
import { useContext } from 'react';
import { CurrencyContext } from 'CurrencyProvider';

const FETCH_PRODUCT_COST = gql`
   query FETCH_PRODUCT_COST($id: ID!) {
      product(id: $id) {
         ...productForCost
      }
   }
   ${FRAGMENT_PRODUCT_FOR_COST}
`;

function buildChartDate(dates, groups) {
   if (dates.length < 2) return null;
   const dateRange = getDates(
      new Date(dates[0]),
      new Date(dates[dates.length - 1])
   );
   let res = [];

   dateRange.forEach((date) => {
      let dateValue = { date: moment(date).format('YYYY-MM-DD') };
      Object.keys(groups).forEach((qty) => {
         let price = 0;
         const foundPrices = groups[qty].filter((i) => {
            const update = new Date(i.supplierUpdatedAt);
            update.setHours(0, 0, 0, 0);
            return update <= date;
         });
         if (foundPrices.length > 0)
            price = foundPrices[foundPrices.length - 1].price;
         dateValue[qty] = price;
      });
      res.push(dateValue);
   });

   return res;
}

const ProductCostHistory = ({ product }) => {
   const { latestExchangeRate } = useContext(CurrencyContext);
   const { loading, error, data } = useQuery(FETCH_PRODUCT_COST, {
      variables: { id: product.id },
   });

   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;

   const prices = data.product.prices.map((i) =>
      i.currency === 'RMB'
         ? {
              ...i,
              sourcePrice: i.price,
              price:
                 parseInt((i.price / latestExchangeRate.rate) * 1000) / 1000,
           }
         : i
   );

   let groups = {};
   prices.forEach((i) => {
      if (groups[i.minQty]) {
         groups[i.minQty].push(i);
      } else {
         groups[i.minQty] = [i];
      }
   });

   console.log('data.product.prices', data.product.prices);

   const dates = data.product.prices.map((i) => i.supplierUpdatedAt);
   const chartData = buildChartDate(dates, groups);
   const colors = randomColors(Object.keys(groups).length);

   return (
      <div>
         {chartData ? (
            <div className="p-6 bg-white dark:bg-gray-900 dark:bg-opacity-60 rounded-2xl shadow fill-current">
               <ResponsiveContainer height={300}>
                  <LineChart
                     data={chartData}
                     margin={{
                        top: 30,
                        right: 30,
                        left: 0,
                        bottom: 0,
                     }}
                  >
                     <XAxis dataKey="date" />
                     <YAxis />
                     <Tooltip content={CustomTooltip} />
                     <Legend />
                     {Object.keys(groups).map((qty, index) => (
                        <Line
                           stroke={colors[index]}
                           key={qty}
                           dataKey={qty}
                           dot={false}
                           type="monotone"
                        />
                     ))}
                  </LineChart>
               </ResponsiveContainer>
            </div>
         ) : null}

         <table className="with-border mt-6 bg-white dark:bg-gray-800">
            <thead>
               <tr>
                  <th>QTY</th>
                  <th>Supplier</th>
                  <th className="text-right">Updated at</th>
                  <th className="text-right">Cost</th>
               </tr>
            </thead>
            <tbody>
               {Object.entries(groups).map(([qty, prices]) => (
                  <PriceGroup key={qty} qty={qty} prices={prices} />
               ))}
            </tbody>
         </table>
      </div>
   );
};

const PriceGroup = ({ qty, prices }) => {
   const computedPrices = prices.reduce((res, item, index) => {
      let diff = 0;
      if (index > 0) diff = item.price - res[index - 1].price;
      return [...res, { ...item, diff }];
   }, []);

   return (
      <>
         <tr>
            <td rowSpan={computedPrices.length + 1}>{qty}</td>
         </tr>
         {computedPrices.map((i) => (
            <tr key={i.id}>
               <td>{i.factory.name}</td>
               <td className="text-right">{i.supplierUpdatedAt}</td>
               <td className="text-right">
                  <div
                     className={`flex space-x-2 justify-end items-center
                        ${i.diff > 0 ? 'text-red-600' : ''}
                        ${i.diff < 0 ? 'text-green-600' : ''}
                    `}
                  >
                     <div>
                        {i.diff === 0 ? null : i.diff > 0 ? (
                           <BsArrowUpCircleFill />
                        ) : (
                           <BsArrowDownCircleFill />
                        )}{' '}
                     </div>
                     <div>
                        USD {i.price.toFixed(3)}
                        {i.sourcePrice
                           ? ` (${i.currency} ${i.sourcePrice})`
                           : null}
                     </div>
                  </div>
               </td>
            </tr>
         ))}
      </>
   );
};

const CustomTooltip = ({ active, payload }) => {
   if (active && payload && payload.length) {
      const data = payload[0];

      let date = data.payload.data;

      return (
         <div className="px-6 py-3 rounded-2xl bg-opacity-80 bg-white dark:bg-gray-800 dark:bg-opacity-80">
            <label htmlFor="">{date}</label>
            {Object.entries(data.payload).map(([k, v]) => (
               <div key={k}>
                  {k === 'date' ? null : (
                     <div>
                        {k}: {v}
                     </div>
                  )}
               </div>
            ))}
         </div>
      );
   }

   return null;
};

export default ProductCostHistory;
