import React, { useRef } from 'react';
import QRCode from 'qrcode.react';
import { Button } from '../../../components/base';
import { downloadSvg, downloadImageFromCanvas } from '../utils';

const ShorternLinkQRCode = ({ name, shorternLink }) => {
   const qrcodeContainerRef = useRef();

   return (
      <div>
         <div ref={qrcodeContainerRef} className="flex justify-center w-32">
            <QRCode
               className="h-12"
               renderAs="svg"
               value={shorternLink}
               size={300}
            />
            <QRCode
               renderAs="canvas"
               value={shorternLink}
               size={300}
               className="hidden"
            />
         </div>
         <div className="mt-4 flex justify-center">
            <Button
               size="sm"
               title="SVG"
               onClick={() =>
                  downloadSvg(
                     qrcodeContainerRef.current.children[0],
                     `${name}-shortern-link`
                  )
               }
            />
            <Button
               size="sm"
               className="ml-4"
               title="PNG"
               onClick={() =>
                  downloadImageFromCanvas(
                     qrcodeContainerRef.current.children[1],
                     name + '-shortern-link',
                     'png'
                  )
               }
            />
            <Button
               size="sm"
               className="ml-4"
               title="JPG"
               onClick={() =>
                  downloadImageFromCanvas(
                     qrcodeContainerRef.current.children[1],
                     name + '-shortern-link',
                     'jpg'
                  )
               }
            />
         </div>
      </div>
   );
};

export default ShorternLinkQRCode;
