import { gql, useMutation } from '@apollo/client';
import CharlesButton from 'components/charles/base';
import { DatePicker } from 'components/Form';
import { Alert } from 'components/Toast';
import moment from 'moment';
import { useState } from 'react';
import { BsArrowRight } from 'react-icons/bs';

const CHANGE_PLAN_START_DATE = gql`
   mutation ($planId: ID!, $startDate: DateTime!) {
      changeProductionPlanStartDate(planId: $planId, startDate: $startDate) {
         plan {
            id
            startDate
         }
      }
   }
`;

const ChangeStartDateView = ({ plan, onCompleted }) => {
   const [startDate, setStartDate] = useState(new Date(plan.startDate));
   const [changeStartDate, changeStartDateRes] = useMutation(
      CHANGE_PLAN_START_DATE,
      {
         variables: { planId: plan.id, startDate },
         onCompleted(res) {
            Alert('success', 'Updated.');
            if (onCompleted)
               onCompleted(res.changeProductionPlanStartDate.plan);
         },
         onError(error) {
            Alert('error', error.message);
         },
         refetchQueries: ['FETCH_PREV_PLANS'],
      }
   );

   return (
      <div className="text-sm">
         <div>
            Note that changing the plan start date will effect the later plans
            too.
         </div>

         <div className="flex items-center space-x-2 mt-6">
            <label htmlFor="">Start Date: </label>
            <span>{moment(plan.startDate).format('YYYY-MM-DD')}</span>
            <BsArrowRight />
            <DatePicker value={startDate} onDayChange={setStartDate} />
         </div>

         <hr />

         <div>
            <CharlesButton
               onClick={changeStartDate}
               loading={changeStartDateRes.loading}
            >
               Confirm
            </CharlesButton>
         </div>
      </div>
   );
};

export default ChangeStartDateView;
