import React from "react";
import { useQuery } from "@apollo/client";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import { FETCH_PRODUCT_LISTS } from "./graphql";
import Page from "components/Page";
import { useModals } from "ModalProvider";
import CreateProductListView from "./CreateProductListView";
import SearchBar from "components/SearchBar";
import CharlesButton from "components/charles/base";
import { searchByProp } from "utils/search";
import moment from "moment";
import Status from "components/Status";

function List() {
  const [searchParams, setSearchParams] = useSearchParams({ q: "" });
  const q = searchParams.get("q");
  const navigate = useNavigate();
  const { loading, error, data } = useQuery(FETCH_PRODUCT_LISTS);
  const modal = useModals({ center: true, maxWidth: "max-w-lg" });

  function tryCreate() {
    modal.present({
      title: "Create Product List",
      children: (
        <CreateProductListView
          onCreated={(productList) => {
            modal.hide();
            navigate(`/product-list/${productList.id}`);
          }}
        />
      ),
    });
  }

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const productLists = data.allProductLists
    .filter((i) => i.parent === null)
    .filter((i) => searchByProp(i, ["name"], q))
    .sort((a, b) => new Date(b.created) - new Date(a.created));

  console.log("productLists", productLists);

  return (
    <Page title="Product Lists">
      <div className="flex justify-between space-x-4 m-6">
        <div className="flex-1">
          <SearchBar autoFocus value={q} initialQuery={q} onChange={(value) => setSearchParams({ q: value }, { replace: true })} placeholder="Search by name" />
        </div>
        <CharlesButton onClick={tryCreate}>+ Create Product List</CharlesButton>
      </div>

      <div className="card p-4 m-6 mt-0">
        <table>
          <thead>
            <tr>
              <th>Product List</th>
              <th className="text-right">Updated</th>
              <th className="text-right">Created</th>
            </tr>
          </thead>
          <tbody>
            {productLists.map((productList) => (
              <tr key={productList.id} className="border-b border-gray-100 dark:border-gray-700">
                <td>
                  <div className="text-sm">
                    <Link to={productList.latestVersion.id} state={{ q }}>
                      {productList.name}
                    </Link>
                    <span className="mx-2 opacity-70">v{productList.latestVersion.version}</span>
                    {productList.latestVersion.state !== "CONFIRMED" ? <Status status={productList.latestVersion.state} /> : null}
                  </div>
                </td>
                <td className="text-right">
                  {moment(productList.latestVersion.updatedAt).local().format("YYYY-MM-DD HH:mm:ss")}
                  {productList.latestVersion.updatedBy ? (
                    <span>
                      {" "}
                      by <span className="font-semibold">{productList.latestVersion.updatedBy.email}</span>
                    </span>
                  ) : null}
                </td>
                <td className="text-right">{moment(productList.created).local().format("YYYY-MM-DD")} </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Page>
  );
}

export default List;
