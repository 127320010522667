import { gql, useMutation, useQuery } from "@apollo/client";
import { InlineSpinner } from "components/Spinner";
import Errors from "components/Errors";
import CharlesButton from "components/charles/base";
import { useModals } from "ModalProvider";
import ChooseImagesFromOneDriveView from "components/ChooseImagesFromOneDriveView";
import { Alert } from "components/Toast";

const FETCH_PRODUCT_IMAGES = gql`
  query FETCH_PRODUCT_IMAGES($id: ID!) {
    product(id: $id) {
      id
      images: computedImages {
        id
        url(size: "300x300")
      }
    }
  }
`;

const SYNC_PRODUCT_WIS_IMAGES = gql`
  mutation SYNC_PRODUCT_WIS_IMAGES($productId: ID!, $ids: [String]!) {
    syncWisProductImagesFromOneDrive(productId: $productId, ids: $ids) {
      product {
        id
        images: computedImages {
          id
          url(size: "300x300")
        }
      }
    }
  }
`;

const ProductImagesView = ({ id }) => {
  const { loading, error, data } = useQuery(FETCH_PRODUCT_IMAGES, { variables: { id } });
  const [syncProductImagesFronOneDrive, syncProductImagesFronOneDriveRes] = useMutation(SYNC_PRODUCT_WIS_IMAGES, {
    onCompleted() {
      Alert("success", "Images synced.");
    },
    onError(error) {
      Alert("error", error.message);
    },
  });
  const modal = useModals();

  function chooseImages() {
    modal.present({
      title: "Choose Images",
      subtitle: "From One Drive",
      children: <ChooseImagesFromOneDriveView onSelect={didSelectImages} />,
    });
  }

  function didSelectImages(selectedImages) {
    modal.hide();
    syncProductImagesFronOneDrive({
      variables: {
        productId: id,
        ids: selectedImages.map((i) => i.id),
      },
    });
  }

  if (loading) return <InlineSpinner text={null} size={21} />;
  if (error) return <Errors error={error} />;

  return (
    <div>
      {data.product.images.length === 0 ? (
        <div className="mb-2 opacity-70">This product does not have any images.</div>
      ) : (
        <div className="flex flex-wrap">
          {data.product.images.map((i) => (
            <img className="mr-2 mb-2 w-20" key={i.id} src={i.url} />
          ))}
        </div>
      )}

      <div>
        <div className="opacity-70">
          By default, the product line images are used for the product. If you want to use different images for this product, you can choose images from one
          drive.
        </div>
        <CharlesButton className="mt-2" onClick={chooseImages} loading={syncProductImagesFronOneDriveRes.loading}>
          Choose Images from One Drive
        </CharlesButton>
      </div>
    </div>
  );
};

export default ProductImagesView;
