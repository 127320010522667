import React from 'react';
import { InlineSpinner } from 'components/Spinner';
import Errors from 'components/Errors';
import { Select } from 'components/Form';
import { gql, useQuery } from '@apollo/client';

const FETCH_INVOICE = gql`
   query FETCH_INVOICE($supplierId: ID) {
      subSupplierInvoices(supplierId: $supplierId) {
         results {
            id
            number
            currency
            total
            balance
            paymentLines {
               id
            }
         }
      }
   }
`;

function SubSupplierInvoiceSelector({ supplierId, value, onChange }) {
   const { loading, error, data } = useQuery(FETCH_INVOICE, {
      variables: { supplierId },
      fetchPolicy: 'network-only',
   });

   if (loading)
      return (
         <div className="flex relative">
            <InlineSpinner size={18} text={null} />
         </div>
      );
   if (error) return <Errors error={error} />;

   const options = data.subSupplierInvoices.results;

   return (
      <Select
         value={value ? value : '0'}
         onChange={(e) =>
            onChange(
               e.target.value,
               data.subSupplierInvoices.results.find(
                  (x) => x.id === e.target.value
               )
            )
         }
      >
         <option value="0" disabled>
            -- Select an Invoice --
         </option>
         {options.map((i) => (
            <option key={i.id} value={i.id}>
               INV{i.number} (Balance: {i.currency} {i.balance})
            </option>
         ))}
      </Select>
   );
}

export default SubSupplierInvoiceSelector;
