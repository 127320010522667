import React from "react";

export const Addresses = () => (
  <div className="leading-normal flex justify-center text-center">
    <div className="mx-2 w-1/4">
      <p>
        <i>
          <b>Sweden</b>
        </i>
      </p>
      <p>Hornsgatan 110</p>
      <p>117 26 Stockholm</p>
      <p>Sweden</p>
    </div>
    <div className="mx-2 w-1/4">
      <p>
        <i>
          <b>Netherlands Warehouse</b>
        </i>
      </p>
      <p>ALC Warehousing b.v.</p>
      <p>Ondernemingsweg 68</p>
      <p>2404 HN Alphen aan den Rijn</p>
      <p>The Netherlands</p>
    </div>
  </div>
);
