import React from "react";
import Icon from "./Icon.js";

export const CUSTOMER_RELATED_ERROR_CODES = ["013", "014"];

export const isCustomerRelated = (code) => code.includes("C");

function ShipmentError({ className = "", prefix = "", code, description }) {
  return (
    <div className={`${className} flex items-center`}>
      <div className="font-bold text-pink-500 whitespace-nowrap">
        {prefix}
        {code}
      </div>
      <div className="ml-2">-</div>
      {isCustomerRelated(code) ? (
        <div className="ml-2 text-yellow-500">
          <Icon icon="user" />
        </div>
      ) : null}
      <div className="ml-2 text-gray-700 dark:text-gray-400">{description}</div>
    </div>
  );
}

export default ShipmentError;
