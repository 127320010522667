import React, { useState } from 'react';
import Card from '../../../components/Card.js';
import { Button } from '../../../components/base.js';

function finishProduction(shipment) {
   const shipmentStatusList = shipment.shipmentStatus
      .map((shipmentStatus) => shipmentStatus.status)
      .flat();
   return (
      shipmentStatusList.includes('in production') &&
      shipmentStatusList.includes('delivered to port')
   );
}

function ProductionDays({ shipments }) {
   const [showMore, setShowMore] = useState(false);
   let data = shipments.filter(finishProduction).map((shipment) => {
      const productionStart = new Date(
         shipment.shipmentStatus.find(
            (shipmentStatus) => shipmentStatus.status === 'in production'
         ).created * 1000
      );
      const productionEnd = new Date(
         shipment.shipmentStatus.find(
            (shipmentStatus) => shipmentStatus.status === 'delivered to port'
         ).created * 1000
      );
      const productionTime = Math.round(
         Math.abs((productionEnd - productionStart) / (24 * 60 * 60 * 1000))
      );
      return {
         ...shipment,
         productionTime,
         productionStart,
         productionEnd,
      };
   });
   data = data.sort((a, b) => b.productionTime - a.productionTime);
   if (!showMore && data.length > 5) data = data.slice(0, 5);
   return (
      <Card title="Production Days">
         {data.map((shipment, index) => (
            <div
               key={index}
               className="lg:flex border-t border-gray-100 dark:border-gray-700 items-center py-2"
            >
               <div className="lg:w-1/3">
                  <div>
                     <Button
                        size="sm"
                        link={`/shipment/shipments/${shipment.id}`}
                        title={shipment.invoiceNumber}
                     />
                  </div>
                  <div className="text-gray-500">{shipment.customer.name}</div>
               </div>
               <div className="flex-1 lg:text-right ">
                  <span className="text-gray-700 font-bold">
                     {shipment.productionTime}
                  </span>{' '}
                  days
               </div>
               <div className="flex-1 lg:text-right ">
                  {shipment.productionStart.toLocaleDateString()} ~{' '}
                  {shipment.productionEnd.toLocaleDateString()}
               </div>
            </div>
         ))}
         {!showMore ? (
            <div className="mt-4">
               <Button
                  size="sm"
                  title="show more"
                  onClick={(_) => setShowMore(true)}
               />
            </div>
         ) : null}
      </Card>
   );
}

export default ProductionDays;
