import React, { useState } from 'react';
import Image from 'components/Image';
import { Button } from 'components/base';
import track, { actions } from 'utils/track';

function Images({ images }) {
   return (
      <div className="mt-6">
         <h3>Images</h3>
         <div className="flex flex-wrap -mx-4">
            {images.map((i) => (
               <div
                  key={i.id}
                  className="flex flex-col justify-between w-32 px-4"
               >
                  <div>
                     <Image size="w-full h-32" key={i.id} src={i.thumb} />
                     <div className="text-xs text-gray-600 text-center break-all">
                        {i.name}
                     </div>
                  </div>
                  <div className="flex justify-center">
                     <Button
                        link={i.thumb}
                        size="sm"
                        title="small"
                        target="_blank"
                        onClick={() => {
                           track(actions.product.download.name, {
                              type: 'small',
                              file: i.name,
                           });
                        }}
                     ></Button>
                     <Button
                        link={i.url}
                        className="ml-2"
                        size="sm"
                        title="large"
                        target="_blank"
                        onClick={() => {
                           track(actions.product.download.name, {
                              type: 'large',
                              file: i.name,
                           });
                        }}
                     ></Button>
                  </div>
               </div>
            ))}
         </div>
      </div>
   );
}

export default Images;
