import Calendar from "components/Calendar";
import { useState } from "react";

const Holidays = ({ offDates }) => {
  const [year, setYear] = useState(new Date().getFullYear());

  return <Calendar year={year} setYear={setYear} selectedDates={offDates.map((i) => i.date)} />;
};

export default Holidays;
