import { gql } from "@apollo/client";

export const FETCH_SHIPMENT_FORM_STATUS = gql`
  {
    shipmentForm @client {
      isSaving
    }
  }
`;

export const FRAGMENT_SHIPMENT_ERROR_CUSTOMER_MESSAGE = gql`
  fragment shipmentErrorCustomerMessage on ShipmentErrorCustomerMessageType {
    id
    message
    created
  }
`;

export const FRAGMENT_SHIPMENT_ERROR_PRODUCT = gql`
  fragment shipmentErrorProduct on ShipmentErrorProductType {
    id
    shipmentInvoiceRow {
      id
      product {
        id
        name
        images
      }
      qty
    }
    notes
  }
`;

export const FRAGMENT_SHIPMENT_STATUS_ERROR_PRODUCT_ROW = gql`
  fragment shipmentStatusErrorProductRow on ShipmentStatusErrorProductRowType {
    id
    shipmentInvoiceRow {
      id
      product {
        id
        name
        images: computedImages {
          id
          url(size: "300x300")
        }
      }
      qty
    }
  }
`;

export const FRAGMENT_SHIPMENT_ERROR = gql`
  fragment shipmentError on ShipmentErrorType {
    id
    notes
    error {
      id
      code: fullCode
      description
      tags {
        id
        name
      }
    }
    shipmentStatusErrorProductRows {
      ...shipmentStatusErrorProductRow
    }
    shipmentStatusErrorFactories {
      id
      factory {
        id
        name
        nickName
      }
    }
    created
    hasDelay
    newEtd
    newEta
    delayDays
    shipmentErrorCustomerMessages {
      ...shipmentErrorCustomerMessage
    }
  }
  ${FRAGMENT_SHIPMENT_STATUS_ERROR_PRODUCT_ROW}
  ${FRAGMENT_SHIPMENT_ERROR_CUSTOMER_MESSAGE}
`;

export const FRAGMENT_PRODUCT = gql`
  fragment product on ProductType {
    id
    productType
    packingName
    odooId
    hsCode
    hsCodeForDestination
    name
    packingName
    number
    quantityPerCarton
    ctnNetWeight
    ctnGrossWeight
    outerCartonCbm
    itemsPerSet
    outerCartonX
    outerCartonY
    outerCartonZ
    customsName
    customsBrand
    customsUsage
    customsValue
    customsSize
    customsMaterials
    images: computedImages {
      id
      url(size: "300x300")
    }
    finalCustomsName
    finalCustomsBrand
    finalCustomsUsage
    finalCustomsValue
    finalCustomsSize
    finalCustomsMaterials

    endFactories {
      id
      name
    }
  }
`;

export const FRAGMENT_SHIPMENT_STATUS = gql`
  fragment shipmentStatus on ShipmentStatusType {
    id
    status
    created
    notes
    errors {
      ...shipmentError
    }
  }
  ${FRAGMENT_SHIPMENT_ERROR}
`;

export const FRAGMENT_SHIPMENT_INVOICE_ROW = gql`
  fragment shipmentInvoiceRow on ShipmentInvoiceRowType {
    id
    product {
      ...product
    }
    purchaseOrder {
      id
      number
    }
    orderNo
    packingName
    newProductItemNumber
    hsCode
    hsCodeForDestination
    newProductQtyPerCarton
    newProductNetWeight
    newProductGrossWeight
    newProductCbm
    newProductValue
    unitPrice
    qty
    totalCarton
    totalNetWeight
    totalGrossWeight
    totalCbm
    totalValue
  }
  ${FRAGMENT_PRODUCT}
`;

export const FRAGMENT_SHIPMENT_INVOICE = gql`
  fragment shipmentInvoice on ShipmentInvoiceType {
    id
    number
    pos {
      id
      number
    }
    invoice {
      id
      number
      odooModelId
      odooModelType
      qcReports {
        id
        name
        status
        qty
        product {
          id
          name
        }
        deletedAt
      }
    }
    shipmentInvoiceRows {
      ...shipmentInvoiceRow
    }
  }
  ${FRAGMENT_SHIPMENT_INVOICE_ROW}
`;

export const FETCH_ALL_PRODUCTS = gql`
  {
    allProducts(productType: "normal") {
      ...product
    }
  }
  ${FRAGMENT_PRODUCT}
`;

export const SAVE_PRODUCT = gql`
  mutation SAVE_PRODUCT(
    $id: ID
    $productType: String!
    $name: String!
    $number: String!
    $quantityPerCarton: Int!
    $ctnNetWeight: Float!
    $ctnGrossWeight: Float!
    $outerCartonCbm: Float!
    $value: Float!
    $itemCubeSizeX: Float!
    $itemCubeSizeY: Float!
    $itemCubeSizeZ: Float!
    $outerCartonX: Float
    $outerCartonY: Float
    $outerCartonZ: Float
    $images: [Upload!]
  ) {
    saveProduct(
      id: $id
      productType: $productType
      name: $name
      number: $number
      quantityPerCarton: $quantityPerCarton
      ctnNetWeight: $ctnNetWeight
      ctnGrossWeight: $ctnGrossWeight
      outerCartonCbm: $outerCartonCbm
      value: $value
      itemCubeSizeX: $itemCubeSizeX
      itemCubeSizeY: $itemCubeSizeY
      itemCubeSizeZ: $itemCubeSizeZ
      outerCartonX: $outerCartonX
      outerCartonY: $outerCartonY
      outerCartonZ: $outerCartonZ
      images: $images
    ) {
      product {
        ...product
      }
    }
  }
  ${FRAGMENT_PRODUCT}
`;

export const FETCH_SHIPMENT_LIST = gql`
  query FETCH_SHIPMENT_LIST($latestStatusIn: [String], $options: CommonOptionsInput) {
    shipments(latestStatusIn: $latestStatusIn, options: $options) {
      results {
        id
        specialNotes
        status
        shippingTerm
        latestStatus
        latestEtd
        latestEta
        loadingPort
        apiEta
        requestCargoReadyDate
        created
        invoiceNumber
        shipContainer
        po
        creator {
          id
          email
        }
        customer {
          id
          name
        }
        shipmentInvoices {
          id
          number
        }
        deletedAt
      }
      total
    }
  }
`;

export const FRAGMENT_SHIPMENT = gql`
  fragment shipment on ShipmentType {
    id
    invoiceNumber
    po
    specialNotes
    odooData
    customer {
      id
      name
      odooPartnerId
      user {
        id
        email
      }
      notes
      subAccounts {
        id
        user {
          id
          email
        }
      }
      lastUsedCurrency
    }
    shipmentStatus {
      ...shipmentStatus
    }
    latestStatus
    paymentTerms
    consigneeName
    deliveryName
    addr
    deliveryAddr
    office
    hbl
    notifyPartyName
    notifyParty
    shippingMark
    stamp
    extraEmails
    forwarder {
      id
      name
    }
    blNumber
    shipContainer
    countryOfOrigin
    vv
    containerNumber
    shippingTerm
    loadingPort
    requestCargoReadyDate
    actualCargoReadyDate
    dateOfDeliveryToPort
    etd
    eta
    apiEta
    newEtd
    newEta
    pickupLocation
    shipBy
    portOfDischarge
    portOfDestination
    notes
    notesForCustomform
    stampForPackingList
    shipmentInvoices {
      ...shipmentInvoice
    }
    totalQty
    totalCarton
    totalNetWeight
    totalGrossWeight
    totalCbm
    totalValue
    attachments {
      id
      name
      uri
    }
    qcReports {
      id
      name
      status
      factory {
        id
        name
      }
      sharedWithCustomerAt
      productLine {
        id
        name
        mainImage
      }
      qty
      deletedAt
    }
    deletedAt
    trackLeadtime
  }
  ${FRAGMENT_SHIPMENT_STATUS}
  ${FRAGMENT_SHIPMENT_INVOICE}
`;

export const FETCH_SHIPMENT = gql`
  query FETCH_SHIPMENT($id: ID!) {
    shipment(id: $id) {
      ...shipment
    }
    shipmentMeta {
      forwarders {
        id
        name
      }
      shipFrom
      shipBy
      shippingTerms
      loadingPorts
      paymentTerms
      shipmentStatusOptions
      errors {
        id
        code
        description
      }
    }
  }
  ${FRAGMENT_SHIPMENT}
`;

export const UPDATE_SHIPMENT = gql`
  mutation UPDATE_SHIPMENT($shipmentInput: ShipmentInput!, $shipmentInvoiceRows: [ShipmentInvoiceRowInput]) {
    updateShipment(shipmentInput: $shipmentInput, shipmentInvoiceRows: $shipmentInvoiceRows) {
      shipment {
        ...shipment
      }
    }
  }
  ${FRAGMENT_SHIPMENT}
`;

export const DUPLICATE_SHIPMENT = gql`
  mutation DUPLICATE_SHIPMENT($id: ID!) {
    duplicateShipment(id: $id) {
      shipment {
        id
      }
    }
  }
`;

export const DELETE_SHIPMENT = gql`
  mutation DELETE_SHIPMENT($id: ID!) {
    deleteShipment(id: $id) {
      shipment {
        id
      }
    }
  }
`;

export const UPDATE_SHIPMENT_NOTES = gql`
  mutation UPDATE_SHIPMENT_NOTES($shipmentInput: ShipmentInput!) {
    updateShipment(shipmentInput: $shipmentInput) {
      shipment {
        id
        specialNotes
      }
    }
  }
`;

export const CREATE_SHIPMENT_STATUS = gql`
  mutation CREATE_SHIPMENT_STATUS($shipmentId: ID!, $status: String, $notes: String, $created: TimeStamp) {
    createShipmentStatus(shipmentId: $shipmentId, status: $status, notes: $notes, created: $created) {
      shipmentStatus {
        id
        status
        created
        notes
        errors {
          ...shipmentError
        }
      }
      shipment {
        id
        latestStatus
      }
    }
  }
  ${FRAGMENT_SHIPMENT_ERROR}
`;

export const createShipmentStatus = gql`
  mutation createShipmentStatus($shipmentId: Int, $status: String, $notes: String, $rows: [Int], $delayReasonId: Int) {
    createShipmentStatus(status: $status, shipmentId: $shipmentId, notes: $notes, rows: $rows, delayReasonId: $delayReasonId) {
      id
      status
      created
      delayReason {
        id
        description
      }
    }
  }
`;

export const UPDATE_SHIPMENT_STATUS = gql`
  mutation UPDATE_SHIPMENT_STATUS($id: ID!, $notes: String, $created: TimeStamp) {
    updateShipmentStatus(id: $id, notes: $notes, created: $created) {
      shipmentStatus {
        id
        notes
        created
      }
    }
  }
`;

export const DELETE_SHIPMENT_STATUS = gql`
  mutation DELETE_SHIPMENT_STATUS($id: ID!) {
    deleteShipmentStatus(id: $id) {
      id
    }
  }
`;

export const CREATE_SHIPMENT_ERROR = gql`
  mutation CREATE_SHIPMENT_ERROR(
    $shipmentStatusId: Int
    $errorId: Int
    $shipmentInvoiceRowIds: [Int]
    $factoryIds: [Int]
    $notes: String
    $created: TimeStamp
    $hasDelay: Boolean
    $newEtd: TimeStamp
    $newEta: TimeStamp
    $delayDays: Int
  ) {
    createShipmentError(
      shipmentStatusId: $shipmentStatusId
      errorId: $errorId
      shipmentInvoiceRowIds: $shipmentInvoiceRowIds
      factoryIds: $factoryIds
      notes: $notes
      created: $created
      hasDelay: $hasDelay
      newEtd: $newEtd
      newEta: $newEta
      delayDays: $delayDays
    ) {
      shipmentError {
        ...shipmentError
      }
      shipment {
        id
        newEtd
        newEta
      }
    }
  }
  ${FRAGMENT_SHIPMENT_ERROR}
`;

export const UPDATE_SHIPMENT_ERROR = gql`
  mutation UPDATE_SHIPMENT_ERROR(
    $id: ID!
    $errorId: ID
    $notes: String
    $created: TimeStamp
    $hasDelay: Boolean
    $newEtd: TimeStamp
    $newEta: TimeStamp
    $delayDays: Int
    $shipmentInvoiceRowIds: [ID]
    $factoryIds: [ID]
  ) {
    updateShipmentError(
      id: $id
      errorId: $errorId
      notes: $notes
      created: $created
      hasDelay: $hasDelay
      newEtd: $newEtd
      newEta: $newEta
      delayDays: $delayDays
      shipmentInvoiceRowIds: $shipmentInvoiceRowIds
      factoryIds: $factoryIds
    ) {
      shipmentError {
        ...shipmentError
      }
      shipment {
        id
        newEtd
        newEta
      }
    }
  }
  ${FRAGMENT_SHIPMENT_ERROR}
`;

export const DELETE_SHIPMENT_ERROR = gql`
  mutation DELETE_SHIPMENT_ERROR($id: ID!) {
    deleteShipmentError(id: $id) {
      id
      shipment {
        id
        newEtd
        newEta
      }
    }
  }
`;

export const SEND_SHIPMENT_ERROR_CUSTOMER_MESSAGE = gql`
  mutation SEND_SHIPMENT_ERROR_CUSTOMER_MESSAGE($shipmentErrorId: ID!, $message: String!) {
    sendShipmentErrorCustomerMessage(shipmentErrorId: $shipmentErrorId, message: $message) {
      shipmentErrorCustomerMessage {
        ...shipmentErrorCustomerMessage
      }
    }
  }
  ${FRAGMENT_SHIPMENT_ERROR_CUSTOMER_MESSAGE}
`;

export const DELETE_SHIPMENT_INVOICE = gql`
  mutation DELETE_SHIPMENT_INVOICE($id: ID!) {
    deleteShipmentInvoice(id: $id) {
      id
      shipment {
        id
        invoiceNumber
      }
    }
  }
`;

export const DELETE_SHIPMENT_INVOICE_PO = gql`
  mutation DELETE_SHIPMENT_INVOICE_PO($shipmentInvoiceId: ID!, $poId: ID!) {
    deleteShipmentInvoicePo(shipmentInvoiceId: $shipmentInvoiceId, poId: $poId) {
      shipmentInvoice {
        id
      }
    }
  }
`;

export const DELETE_SHIPMENT_INVOICE_ROW = gql`
  mutation DELETE_SHIPMENT_INVOICE_ROW($id: ID!) {
    deleteShipmentInvoiceRow(id: $id) {
      id
      shipment {
        id
        totalQty
        totalCarton
        totalNetWeight
        totalGrossWeight
        totalCbm
      }
    }
  }
`;

export const FETCH_CUSTOMER_INVOICES = gql`
  query FETCH_CUSTOMER_INVOICES($id: ID!) {
    customer(id: $id) {
      id
      invoices {
        id
        number
        po
      }
    }
  }
`;

export const CREATE_SHIPMENT = gql`
  mutation CREATE_SHIPMENT($customerId: ID!, $invoiceInput: ShipmentInvoiceFromOdooInputType) {
    createShipment(customerId: $customerId, invoiceInput: $invoiceInput) {
      shipment {
        id
      }
    }
  }
`;

export const CREATE_SHIPMENT_INVOICE = gql`
  mutation CREATE_SHIPMENT_INVOICE($shipmentId: ID!, $invoiceId: ID!, $pos: [ID]) {
    createShipmentInvoice(shipmentId: $shipmentId, invoiceId: $invoiceId, pos: $pos) {
      shipment {
        id
        invoiceNumber
      }
      shipmentInvoice {
        ...shipmentInvoice
      }
    }
  }
  ${FRAGMENT_SHIPMENT_INVOICE}
`;

export const CREATE_INVOICE = gql`
  mutation CREATE_INVOICE($number: String!, $po: String, $customerId: ID!) {
    createInvoice(number: $number, po: $po, customerId: $customerId) {
      invoice {
        id
        number
        po
      }
    }
  }
`;

export const addInvoices = gql`
  mutation ADD_INVOICES($shipmentId: Int, $invoices: [Int]) {
    addInvoices(shipmentId: $shipmentId, invoices: $invoices) {
      shipmentInvoices {
        id
        number
        po
        shipmentInvoiceRows {
          ...shipmentInvoiceRow
        }
      }
    }
  }
  ${FRAGMENT_SHIPMENT_INVOICE_ROW}
`;

export const deleteAttachment = gql`
  mutation DELETE_ATTACHMENT($id: ID!) {
    deleteAttachment(id: $id) {
      id
    }
  }
`;

export const CREATE_DOCUMENT = gql`
  mutation CREATE_DOCUMENT($shipmentId: ID!, $docType: String!) {
    createDocument(shipmentId: $shipmentId, docType: $docType) {
      document {
        id
        docType
        shipment {
          id
          invoiceNumber
          customer {
            id
            name
          }
        }
        created
      }
    }
  }
`;

export const CREATE_SHIPMENT_BOM = gql`
  mutation CREATE_SHIPMENT_BOM($shipmentId: ID!, $shipmentInvoiceRowIds: [ID]) {
    createShipmentBom(shipmentId: $shipmentId, shipmentInvoiceRowIds: $shipmentInvoiceRowIds) {
      document {
        id
        docType
      }
    }
  }
`;

export const CREATE_SHIPMENT_INVOICE_ROW = gql`
  mutation CREATE_SHIPMENT_INVOICE_ROW($shipmentInvoiceId: ID!, $productId: ID!, $poId: ID) {
    createShipmentInvoiceRow(shipmentInvoiceId: $shipmentInvoiceId, productId: $productId, poId: $poId) {
      shipmentInvoiceRow {
        ...shipmentInvoiceRow
      }
    }
  }
  ${FRAGMENT_SHIPMENT_INVOICE_ROW}
`;

export const CREATE_SHIPMENT_INVOICE_ROWS = gql`
  mutation CREATE_SHIPMENT_INVOICE_ROWS($shipmentInvoiceId: ID!, $shipmentInvoiceProducts: [ShipmentInvoiceProductsInput]) {
    createShipmentInvoiceRows(shipmentInvoiceId: $shipmentInvoiceId, shipmentInvoiceProducts: $shipmentInvoiceProducts) {
      shipment {
        ...shipment
      }
    }
  }
  ${FRAGMENT_SHIPMENT}
`;

export const UPDATE_SHIPMENT_INVOICE_ROW = gql`
  mutation UPDATE_SHIPMENT_INVOICE_ROW(
    $shipmentInvoiceRowId: ID!
    $productId: ID
    $packingName: String
    $qty: Int
    $hsCode: String
    $newProductGrossWeight: Float
    $totalCarton: Int
    $totalNetWeight: Float
    $totalGrossWeight: Float
    $totalCbm: Float
  ) {
    updateShipmentInvoiceRow(
      shipmentInvoiceRowId: $shipmentInvoiceRowId
      productId: $productId
      packingName: $packingName
      qty: $qty
      hsCode: $hsCode
      newProductGrossWeight: $newProductGrossWeight
      totalCarton: $totalCarton
      totalNetWeight: $totalNetWeight
      totalGrossWeight: $totalGrossWeight
      totalCbm: $totalCbm
    ) {
      shipmentInvoiceRow {
        ...shipmentInvoiceRow
      }
      shipment {
        id
        totalQty
        totalCarton
        totalNetWeight
        totalGrossWeight
        totalCbm
      }
    }
  }
  ${FRAGMENT_SHIPMENT_INVOICE_ROW}
`;

export const UPDATE_SHIPMENT_INVOICE_ROW_PRODUCT = gql`
  mutation UPDATE_SHIPMENT_INVOICE_ROW_PRODUCT($shipmentInvoiceRowId: ID!, $productId: ID) {
    updateShipmentInvoiceRowProduct(shipmentInvoiceRowId: $shipmentInvoiceRowId, productId: $productId) {
      shipmentInvoiceRow {
        ...shipmentInvoiceRow
        shipmentInvoice {
          id
        }
      }
    }
  }
  ${FRAGMENT_SHIPMENT_INVOICE_ROW}
`;

export const ADD_SHIPMENT_ATTACHMENT = gql`
  mutation ADD_SHIPMENT_ATTACHMENT($shipmentId: ID!, $name: String!, $url: String!) {
    addShipmentAttachment(shipmentId: $shipmentId, name: $name, url: $url) {
      attachment {
        id
        name
        uri
      }
    }
  }
`;

export const REMOVE_SHIPMENT_ATTACHMENT = gql`
  mutation REMOVE_SHIPMENT_ATTACHMENT($id: ID!) {
    removeShipmentAttachment(id: $id) {
      id
    }
  }
`;

export const NOTIFY_CUSTOMER = gql`
  mutation NOTIFY_CUSTOMER($shipmentId: ID!, $confirmedEmails: [String!], $content: String!) {
    notifyCustomer(shipmentId: $shipmentId, confirmedEmails: $confirmedEmails, content: $content) {
      message
    }
  }
`;

export const FRAGMENT_SPECIAL_ITEM = gql`
  fragment specialItem on ProductType {
    id
    productType
    name
    number
    hsCode
    outerCartonX
    outerCartonY
    outerCartonZ
    quantityPerCarton
    ctnNetWeight
    ctnGrossWeight
    outerCartonCbm
    images: computedImages {
      id
      url(size: "300x300")
    }
    customsName
    customsBrand
    customsUsage
    customsValue
    customsSize
    customsMaterials
  }
`;

export const FETCH_ALL_ERROR_CATEGORIES = gql`
  query FETCH_ALL_ERROR_CATEGORIES {
    allErrorCategories {
      id
      name
      code
      errors {
        id
        code: fullCode
        description
        tags {
          id
          name
        }
      }
    }
  }
`;

export const UPDATE_PRODUCT_PRICE_WITH_ODOO = gql`
  mutation UPDATE_PRODUCT_PRICE_WITH_ODOO($shipmentId: ID!) {
    updateProductPriceWithOdoo(shipmentId: $shipmentId) {
      shipment {
        id
      }
    }
  }
`;

export const UPDATE_PRODUCT_PRICE = gql`
  mutation UPDATE_PRODUCT_PRICE($shipmentId: ID!) {
    updateProductPrice(shipmentId: $shipmentId) {
      shipment {
        id
      }
    }
  }
`;

export const CREATE_SHIPMENT_INVOICE_FROM_ODOO = gql`
  mutation CREATE_SHIPMENT_INVOICE_FROM_ODOO($shipmentId: ID!, $invoiceInput: ShipmentInvoiceFromOdooInputType!) {
    createShipmentInvoiceFromOdoo(shipmentId: $shipmentId, invoiceInput: $invoiceInput) {
      shipmentInvoice {
        ...shipmentInvoice
      }
    }
  }
  ${FRAGMENT_SHIPMENT_INVOICE}
`;
