import { Select } from 'components/Form';

const ProductSelector = ({
   productLines,
   disabled = false,
   onSelect,
   selectedProductIds = [],
   value = '0',
}) => {
   const products = productLines.flatMap((i) => i.products);

   function select(e) {
      const product = products.find((i) => i.id === e.target.value);
      onSelect(product);
   }

   return (
      <div>
         <Select onChange={select} value={value} disabled={disabled}>
            <option value="0"> -- Select Product -- </option>
            {productLines.map((line) => (
               <optgroup key={line.id} label={line.name}>
                  {line.products.map((product) => (
                     <option
                        key={product.id}
                        value={product.id}
                        disabled={selectedProductIds.includes(product.id)}
                     >
                        [{product.number}] {product.name}
                     </option>
                  ))}
               </optgroup>
            ))}
         </Select>
      </div>
   );
};

export default ProductSelector;
