import React, { useContext } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import Page from "components/Page";
import ShipmentForm from "./ShipmentForm";
import { FETCH_SHIPMENT, DUPLICATE_SHIPMENT, DELETE_SHIPMENT, UPDATE_SHIPMENT_NOTES } from "../graphql";
import { Alert } from "components/Toast";
import { AppContext } from "App";
import CharlesButton from "components/charles/base";
import { parseError } from "apollo";

function ShipmentDetail() {
  const { id } = useParams();
  const { hasPermission } = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { loading, error, data } = useQuery(FETCH_SHIPMENT, { variables: { id } });
  const [updateShipmentNotes, updateShipmentNotesStatus] = useMutation(UPDATE_SHIPMENT_NOTES, {
    onCompleted: () => Alert("success", "Notes are added."),
    onError(error) {
      Alert("error", parseError(error));
    },
  });
  const [duplicate, duplicateRes] = useMutation(DUPLICATE_SHIPMENT, {
    variables: { id },
    onCompleted: (data) => {
      Alert("success", "Shipment is duplicated.");
      navigate(`/shipment/shipments/${data.duplicateShipment.shipment.id}`);
    },
    onError(error) {
      Alert("error", parseError(error));
    },
  });
  const [deleteShipment, deleteShipmentStatus] = useMutation(DELETE_SHIPMENT, {
    variables: { id },
    onCompleted: () => {
      Alert("success", "Shipment is deleted.");
      navigate("/shipment/shipments/");
      window.location.reload();
    },
    onError(error) {
      Alert("error", parseError(error));
    },
  });

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const { shipment, shipmentMeta } = data;

  function tryAddNotes() {
    const specialNotes = window.prompt(
      "Simple notes for this shipment, typically to clarify duplicated shipment. Will show in the shipment list.",
      shipment.specialNotes ? shipment.specialNotes : ""
    );
    if (specialNotes !== null) {
      updateShipmentNotes({
        variables: {
          shipmentInput: { id: shipment.id, specialNotes },
        },
      });
    }
  }

  return (
    <Page
      className="lg:h-screen"
      backTo={`/shipment/shipments${location.search}`}
      rightButtons={
        <div className="flex items-center space-x-4">
          {hasPermission() ? (
            <a href={`${process.env.REACT_APP_SERVER_ADMIN_URL}shipment/shipment/${id}/change/`} target="_blank">
              Admin
            </a>
          ) : null}
          <CharlesButton onClick={tryAddNotes} loading={updateShipmentNotesStatus.loading}>
            + Notes
          </CharlesButton>
          <CharlesButton
            loading={duplicateRes.loading}
            onClick={(_) => {
              if (window.confirm("You are sure to duplicate this shipment?")) duplicate();
            }}
          >
            Duplicate
          </CharlesButton>

          <CharlesButton
            danger
            loading={deleteShipmentStatus.loading}
            onClick={(_) => {
              if (window.confirm("You are sure to delete this shipment??!")) deleteShipment();
            }}
          >
            Delete
          </CharlesButton>
        </div>
      }
      title={`#${shipment.invoiceNumber}`}
      subtitle={shipment.specialNotes}
    >
      <ShipmentForm shipment={shipment} shipmentMeta={shipmentMeta} />
    </Page>
  );
}

export default ShipmentDetail;
