import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { Button } from '../../../../components/base';
import BackButton from '../../../../components/BackButton';
import Spinner from '../../../../components/Spinner';
import Errors from '../../../../components/Errors';
import Image from '../../../../components/Image';
import PageContainer from '../../../../components/PageContainer';
import Images from './Images';
import ProductDetail from './ProductDetail';
import LifestyleImages from './LifestyleImages';
import PromoVideos from './PromoVideos';

const FETCH_PRODUCTLINE = gql`
   query FETCH_PRODUCTLINE($slug: String!) {
      productLine(slug: $slug) {
         id
         slug
         name
         number
         description
         mainImage
         age
         newImages {
            id
            name
            thumb: url(size: "300x300")
            url
         }
         promoImages {
            id
            name
            thumb: url(size: "300x300")
            url
         }
         videos {
            id
            name
            poster
            previewUri
            files {
               id
               uri
            }
         }
         images {
            id
            name
            useFor
            active
            url
            thumb: url(size: "400x400")
            tiff
         }
      }
   }
`;

function ProductLineDetail({ area = null }) {
   const { slug } = useParams();
   const { loading, error, data } = useQuery(FETCH_PRODUCTLINE, {
      variables: { slug },
   });
   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;
   const { productLine } = data;

   return (
      <PageContainer
         navigationBarClassName="lg:hidden"
         backButton={<BackButton to="/products/collection-view" />}
         title={productLine.name}
         content={
            <div className="flex-1 flex flex-col p-4 lg:p-6 bg-white">
               <h1 className="hidden lg:block lg:mb-2">{productLine.name}</h1>
               <div className="mb-4 text-base text-gray-600 font-bold">
                  #{productLine.number}
               </div>
               <div className="flex-col-reverse md:flex-row flex">
                  <div className="text-gray-700 whitespace-pre-wrap flex-1">
                     {productLine.description}
                     <br />
                     <br />
                     Age: {productLine.age}+
                  </div>
                  <div className="flex justify-center ml-0 mb-4 md:mb-0 md:ml-4">
                     <Image
                        size="w-full md:w-64 h-64"
                        bgStyle="bg-center bg-contain bg-no-repeat bg-white"
                        src={productLine.mainImage}
                     />
                  </div>
               </div>

               <Images images={productLine.newImages} />

               <div className="mt-6">
                  <LifestyleImages productLine={productLine} />
               </div>

               <div className="mt-6">
                  <PromoVideos productLine={productLine} />
               </div>
            </div>
         }
      />
   );
}

export default ProductLineDetail;
