const ConvertCodeView = () => (
  <div>
    <div className="mt-6">以下两个为我们扫码设备的转换码，可以直接使用扫码枪对着屏幕扫描使用。</div>
    <div className="mt-6 space-y-6 lg:grid lg:grid-cols-2 lg:space-y-0 lg:gap-6">
      <img
        className="border-2 w-full"
        src="https://qiniu-waboba.cors.tech/a7e661b5b2b242e3935ab6abf3260aa7-productFrontSide/a8d26f70-1f18-4e0b-adf5-d1f7b6ebf864-upc-to-ean13.png"
      />

      <img
        className="border-2 w-full"
        src="https://qiniu-waboba.cors.tech/ec7833729b3c47b686bc37395e37833b-productFrontSide/785ab76f-aef3-4323-87e0-da869b1cca02-upc-not-to-ean13.png"
      />
    </div>
  </div>
);

export default ConvertCodeView;
