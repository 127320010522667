import { Button } from 'components/base';
import { formatDate } from 'react-day-picker/moment';
import { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { InlineSpinner } from 'components/Spinner';
import Errors from 'components/Errors';
import DateRangePicker from 'pages/analytics/DateRangePicker';
import useDateRange from 'hooks/useDateRange';
import XLSX from 'xlsx';
import { Select } from 'components/Form';

const FIELDS = {
   po: 'PO Numbers',
   paymentTerms: 'Payment Terms',
   latestStatus: 'Latest Status',
   created: 'Created Date',
   requestCargoReadyDate: 'Request Cargo Ready Date',
   actualCargoReadyDate: 'Actual Cargo Ready Date',
   deliveryToPortDate: 'Delivery to Port Date',
   latestEtd: 'Latest ETD',
   latestEta: 'Latest ETA',
   portOfDischarge: 'Port of Discharge',
   portOfDestination: 'Port of Destination',
   totalQty: 'Total Qty',
   totalCbm: 'Total CBM',
   totalNetWeight: 'Total Net Weight',
   totalGrossWeight: 'Total Gross Weight',
};

const ExportShipments = () => {
   const [{ start, end }, { setStart, setEnd }] = useDateRange(12);
   const [selectedFields, setSelectedFields] = useState([]);
   const [filterBy, setFilterBy] = useState('created');

   const { loading, error, data } = useQuery(SHIPMENT_LIST_QUERY, {
      variables: { start, end, filterBy },
   });

   const shipments = data
      ? data.allShipments
         .filter((i) => i.deletedAt === null)
         .map((i) => {
            return {
               ...i,
               created: formatDate(i.created, 'YYYY-MM-DD'),
               requestCargoReadyDate: i.requestCargoReadyDate ? formatDate(i.requestCargoReadyDate * 1000, 'YYYY-MM-DD') : null,
               deliveryToPortDate: i.deliveryToPortDate ? formatDate(i.deliveryToPortDate * 1000, 'YYYY-MM-DD') : null,
               actualCargoReadyDate: i.actualCargoReadyDate ? formatDate(i.actualCargoReadyDate * 1000, 'YYYY-MM-DD') : null,
            };
         })
      : null;

   function exportXlxs() {
      const data = shipments.map((i) => {
         let res = { 'Order Number': i.invoiceNumber };
         selectedFields.forEach((f) => {
            res[FIELDS[f]] = i[f];
         });
         return res;
      });
      const sheet = XLSX.utils.json_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, sheet, 'shipments');
      XLSX.writeFile(wb, 'shipments.xlsx');
   }

   return (
      <div className="p-6 flex-1 flex flex-col overflow-auto">
         <div className="flex items-center space-x-3">
            <DateRangePicker
               start={start}
               end={end}
               setStart={setStart}
               setEnd={setEnd}
            />

            <label htmlFor="filterBy">Filter by:</label>
            <Select
               value={filterBy}
               onChange={(e) => setFilterBy(e.target.value)}
            >
               <option value="created">Shipment Created</option>
               <option value="eta">ETA</option>
               <option value="shipping_date">Shipping Date</option>
               <option value="delivery_to_port_date">Delivered To Port Date</option>
               <option value="request_cargo_ready_date">Request Cargo Ready Date</option>
            </Select>
         </div>

         <div className="flex overflow-auto flex-1 space-x-6">
            <div>
               <div className="mt-4">
                  <label htmlFor="">Select the data you want</label>
               </div>
               <div className="space-y-2 mt-4">
                  {Object.entries(FIELDS).map(([key, value]) => (
                     <div key={key}>
                        <label
                           htmlFor={key}
                           className="cursor-pointer hover:text-blue-500 block"
                        >
                           <input
                              type="checkbox"
                              id={key}
                              checked={selectedFields.includes(key)}
                              onChange={() =>
                                 setSelectedFields((prev) =>
                                    prev.includes(key)
                                       ? prev.filter((i) => i !== key)
                                       : [...prev, key]
                                 )
                              }
                           />
                           <span className="pl-2">{value}</span>
                        </label>
                     </div>
                  ))}
               </div>
               <div className="mt-4 flex space-x-6">
                  <Button title="Clear" onClick={() => setSelectedFields([])} />
                  <Button bold title="Export" onClick={exportXlxs} />
               </div>
            </div>

            <div className="overflow-auto flex-1 my-4 border dark:border-gray-700">
               {loading ? (
                  <div className="p-24">
                     <InlineSpinner />
                  </div>
               ) : error ? (
                  <Errors error={error} />
               ) : (
                  <ShipmentList shipments={shipments} fields={selectedFields} />
               )}
            </div>
         </div>
      </div>
   );
};

const SHIPMENT_LIST_QUERY = gql`
   query SHIPMENT_LIST_QUERY($start: DateTime, $end: DateTime, $filterBy: String) {
      allShipments(start: $start, end: $end, filterBy: $filterBy) {
         id
         deletedAt
         invoiceNumber
         po
         deliveryAddr
         paymentTerms
         latestStatus
         shipper
         shipBy
         shipTo
         shipFrom
         created
         requestCargoReadyDate
         actualCargoReadyDate
         deliveryToPortDate
         latestEtd
         latestEta
         portOfDischarge
         portOfDestination
         totalQty
         totalCbm
         totalNetWeight
         totalGrossWeight
      }
   }
`;

const ShipmentList = ({ shipments, fields }) => {
   return (
      <table className="whitespace-nowrap">
         <thead>
            <tr>
               <th className="border dark:border-gray-700">Order Number</th>
               {fields.map((field) => (
                  <th key={field} className="border dark:border-gray-700">
                     {FIELDS[field]}
                  </th>
               ))}
            </tr>
         </thead>
         <tbody>
            {shipments.map((shipment) => (
               <tr key={shipment.id}>
                  <td className="border dark:border-gray-700">
                     {shipment.invoiceNumber}
                  </td>
                  {fields.map((field) => (
                     <td key={field} className="border dark:border-gray-700">
                        {shipment[field]}
                     </td>
                  ))}
               </tr>
            ))}
         </tbody>
      </table>
   );
};

export default ExportShipments;
