import { ApolloClient, InMemoryCache, makeVar, from } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { createUploadLink } from "apollo-upload-client";

export const showRootMenu = makeVar(false);
export const shipmentVar = makeVar(null);

export const cache = new InMemoryCache();

const httpLinkWithUpload = createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL_URL,
  credentials: "include",
});

const errorLink = onError((error) => {
  const { graphQLErrors, networkError } = error;
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message }) => {
      console.log(`[GraphQL error]: ${message}`);
    });
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

const link = from([errorLink, httpLinkWithUpload]);

export const parseError = (error) => {
  if (!error) return "";
  if (error.graphQLErrors && error.graphQLErrors.length > 0) {
    console.log("error.graphQLErrors", error.graphQLErrors);
    return error.graphQLErrors.map((e) => e.message).join("\n");
  } else if (error.networkError && error.networkError.result && error.networkError.result.errors && error.networkError.result.errors.length > 0) {
    console.log("error.networkError.result.errors", error.networkError.result.errors);
    return error.networkError.result.errors.map((e) => e.message).join("\n");
  }
  return error.message;
};

export const client = new ApolloClient({ link, cache });
