const { useQuery, gql } = require('@apollo/client');

const FETCH_QC_IMAGE_META_CATEGORIES = gql`
   query FETCH_QC_IMAGE_META_CATEGORIES {
      metaCategories: allQcImageMetaCategories {
         id
         name
         metas {
            id
            name
            key
         }
      }
   }
`;

const useQcImageMetaCategories = () => {
   const { loading, error, data } = useQuery(FETCH_QC_IMAGE_META_CATEGORIES);
   const metaCategories = data ? data.metaCategories : null;
   return { loading, error, metaCategories };
};

export default useQcImageMetaCategories;
