import React, { useState } from "react";
import { CharlesButtonWithArrow } from "components/charles/base.js";
import CustomerAccount from "components/Customer";

function CustomerInfo({ customer }) {
  const [showNotes, setShowNotes] = useState(false);

  return (
    <div>
      <div>
        <h2>{customer.name}</h2>
        <div className="opacity-70 mt-1">{customer.user.email}</div>
      </div>

      {customer.subAccounts.length > 0 && (
        <div className="text-gray-600">
          <div className="md:flex flex-wrap">
            {customer.subAccounts.map((c) => (
              <CustomerAccount key={c.id} className="mt-2 mr-4" email={c.user.email} />
            ))}
          </div>
          <div className="pt-2 text-xs text-grey">
            These accounts will be able to see this shipment in <b>biz.waboba.com</b>
          </div>
        </div>
      )}

      {customer.notes && (
        <div className="mt-3">
          <CharlesButtonWithArrow show={showNotes} onClick={() => setShowNotes((prev) => !prev)}>
            Customer Notes
          </CharlesButtonWithArrow>
        </div>
      )}

      {showNotes && <div className="rounded-xl shadow-lg p-6 my-4 leading-loose bg-white dark:bg-gray-800 whitespace-pre-wrap">{customer.notes}</div>}
    </div>
  );
}

export default CustomerInfo;
