import React, { useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import Spinner from 'components/Spinner';
import Errors from 'components/Errors';
import { Button } from 'components/base';
import Page from 'components/Page';
import LinkForm from './LinkForm';
import ShorternLinkQRCode from './ShorternLinkQRCode';
import { useModals } from 'ModalProvider';

export const FETCH_ALL_LINKS = gql`
   query FETCH_ALL_LINKS {
      allLinks {
         id
         name
         slug
         shorternLink
         originLink
         description
         updatedAt
         createdAt
         visitedCount
      }
   }
`;

const Shortlinks = () => {
   const { loading, error, data } = useQuery(FETCH_ALL_LINKS);

   const { present, hide } = useModals();

   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;

   return (
      <Page
         title="Short Links"
         rightButtons={
            <div>
               <Button
                  title="+ New Link"
                  onClick={() =>
                     present({
                        title: 'Create Link',
                        center: true,
                        children: <LinkForm hide={hide} />,
                     })
                  }
               />
            </div>
         }
      >
         <div className="p-4 lg:p-6 flex items-start space-x-6">
            <div className="bg-white dark:bg-gray-800 dark:text-gray-300 p-6 rounded-xl">
               {data.allLinks.map((link, index) => (
                  <div
                     key={link.id}
                     className={`
                        ${
                           index + 1 === data.allLinks.length
                              ? ''
                              : 'border-b dark:border-gray-700'
                        } py-4 flex justify-between items-center`}
                  >
                     <div>
                        <Button
                           title={link.name}
                           size="lg"
                           bold
                           onClick={() =>
                              present({
                                 title: 'Edit Link',
                                 center: true,
                                 children: <LinkForm link={link} hide={hide} />,
                              })
                           }
                        />
                        <div className="flex items-baseline mt-2">
                           <label className="w-32 mr-4  flex-shrink-0">
                              Shorten Link:
                           </label>
                           <a
                              href={link.shorternLink}
                              className="text-blue-600"
                              target="_blank"
                              rel="noreferer"
                           >
                              {link.shorternLink}
                           </a>
                        </div>
                        <div className="flex items-baseline mt-2">
                           <label className="w-32 mr-4 flex-shrink-0">
                              Origin Link:
                           </label>
                           <a
                              href={link.originLink}
                              className="text-blue-600"
                              target="_blank"
                              rel="noreferer"
                           >
                              {link.originLink}
                           </a>
                        </div>
                        {link.description ? (
                           <div className="mt-4 text-xs text-gray-600">
                              {link.description}
                           </div>
                        ) : null}
                     </div>
                     <div className="flex flex-1 justify-end px-8">
                        <ShorternLinkQRCode
                           name={link.name}
                           shorternLink={link.shorternLink}
                        />
                     </div>
                     <div className="text-gray-600">
                        {new Date(link.updatedAt).toLocaleDateString()}
                     </div>
                  </div>
               ))}
            </div>

            <div className="bg-white dark:bg-gray-800 dark:text-gray-300 rounded-xl">
               <h3 className="p-4">Visits</h3>
               <Ranking links={data.allLinks} />
            </div>
         </div>
      </Page>
   );
};

const Ranking = ({ links }) => {
   const sortedLinks = [...links].sort(
      (a, b) => b.visitedCount - a.visitedCount
   );

   return (
      <div>
         {sortedLinks.map((link) => (
            <div
               key={link.id}
               className="border-b dark:border-gray-700 px-4 py-3 space-x-8 justify-between flex items-center whitespace-nowrap"
            >
               <div>{link.name}</div>
               <div>{link.visitedCount}</div>
            </div>
         ))}
      </div>
   );
};

export default Shortlinks;
