import { gql, useMutation } from "@apollo/client";
import SearchBar from "components/SearchBar";
import Spinner from "components/Spinner";
import { Alert } from "components/Toast";
import { useState } from "react";
import { searchByProp } from "utils/search";

const SAVE_INC_PRODUCT = gql`
  mutation SAVE_INC_PRODUCT($id: ID!, $wisProductId: ID) {
    saveIncProduct(id: $id, wisProductId: $wisProductId) {
      product {
        id
        wisProduct {
          id
          number
          name
        }
      }
    }
  }
`;

const ChooseWisProductView = ({ incProduct, products, hide }) => {
  const [searchText, setSearchText] = useState(incProduct.sku);
  const searchedProducts = products.filter((product) => searchByProp(product, ["number", "name"], searchText));
  const [saveIncProduct, saveIncProductRes] = useMutation(SAVE_INC_PRODUCT, {
    onCompleted: () => {
      Alert("success", "Product saved.");
      hide();
    },
    onError: (error) => {
      Alert("error", error.message);
    },
  });

  return (
    <div>
      <SearchBar value={searchText} onChange={setSearchText} />
      <div className="mt-4">
        {searchedProducts.map((product) => (
          <div
            key={product.id}
            className=" border-b py-2 cursor-pointer hover:text-blue-600 active:text-blue-700"
            onClick={() => saveIncProduct({ variables: { id: incProduct.id, wisProductId: product.id } })}
          >
            [{product.number}] {product.name}
          </div>
        ))}
      </div>

      {saveIncProductRes.loading && <Spinner />}
    </div>
  );
};

export default ChooseWisProductView;
