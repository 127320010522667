import React, { useContext } from "react";
import { useMutation } from "@apollo/client";
import { Alert } from "components/Toast";
import { DELETE_SHIPMENT_INVOICE } from "../../graphql";
import ShipmentContext from "../ShipmentContext";
import CharlesButton from "components/charles/base";
import { parseError } from "apollo";

const InvoiceRow = ({ shipmentInvoice }) => {
  const { dispatch } = useContext(ShipmentContext);
  const [deleteShipmentInvoice] = useMutation(DELETE_SHIPMENT_INVOICE, {
    onCompleted: (data) => {
      Alert("success", "Shipment Invoice deleted");
      dispatch({ type: "deleteShipmentInvoice", payload: data.deleteShipmentInvoice.id });
    },
    onError: (error) => {
      Alert("error", parseError(error));
    },
  });

  return (
    <div className="flex justify-between items-start lg:items-center px-4 lg:px-6 py-3 bg-gray-200 dark:bg-gray-800">
      <div className="break-all flex space-x-2 items-baseline">
        <div className="text-base font-bold">SO {shipmentInvoice.invoice.number}</div>
        {shipmentInvoice.pos.length > 0 ? <div className=" opacity-70">PO{shipmentInvoice.pos.map((i) => i.number).join(",")}</div> : null}
      </div>

      <CharlesButton
        danger
        onClick={() => {
          if (window.confirm(`Are you sure you want to delete this invoice !?`)) {
            deleteShipmentInvoice({ variables: { id: shipmentInvoice.id } });
          }
        }}
      >
        delete invoice
      </CharlesButton>
    </div>
  );
};

export default InvoiceRow;
