import { useContext } from "react";
import { ProductContext } from "./ProductForm";
import { Field, Switcher } from "components/Form";
import NewProductImagesView from "./images/NewProductImagesView";
import ProductImagesView from "./images/ProductImagesView";
import ItemSizeInput from "components/ItemSizeInput";
import FactorySelector from "components/FactorySelector";
import ProductLineSelector from "components/ProductLineSelector";

const BaseInfoView = () => {
  const {
    simpleFields,
    onChangeSimpleFields,
    productLineId,
    setProductLineId,
    images,
    setImages,
    id,
    size,
    setSize,
    defaultSupplierId,
    setDefaultSupplierId,
    producerId,
    setProduceerId,
    deliverToSupplierId,
    setDeliverToSupplierId,
  } = useContext(ProductContext);

  return (
    <div className="space-y-8">
      <h4>Base Info</h4>
      <section className="space-y-4">
        <Field
          label="Name"
          labelWidth="w-24"
          value={simpleFields.name}
          onChange={(value) => onChangeSimpleFields("name", value)}
          tips="For example: EU Surf ball in 2 tier box assorted color."
        />

        <Field
          label="Item Number"
          labelWidth="w-24"
          value={simpleFields.number}
          onChange={(value) => onChangeSimpleFields("number", value)}
          tips="Same value as Internal Reference / default code in Odoo."
        />

        <div className="flex items-baseline space-x-4 mb-4">
          <label className="w-24">Product Line:</label>
          <div>
            <ProductLineSelector value={productLineId} onChange={setProductLineId} />
            <div className="text-xs opacity-60 mt-1 px-2">
              You should choose product line when you create a product for sale. You probably don't need to if you create a component. Product lines shares some
              info: hs code, age grading, warnings, pantone, lab test reports, printing method and materils with their products.
            </div>
          </div>
        </div>

        <div className="flex space-x-4">
          <label className="w-24">Images: </label>
          <div>
            {id === null ? <NewProductImagesView images={images} setImages={setImages} /> : <ProductImagesView id={id} />}

            <label htmlFor="useProductLineImagesIfNoImages" className="flex mt-2 space-x-1 items-center">
              <input
                id="useProductLineImagesIfNoImages"
                type="checkbox"
                checked={simpleFields.useProductLineImagesIfNoImages}
                onChange={() => {
                  onChangeSimpleFields("useProductLineImagesIfNoImages", !simpleFields.useProductLineImagesIfNoImages);
                }}
              />
              <span>Use Product Line Images if No Images</span>
            </label>
          </div>
        </div>

        <div className="mt-4">
          <div className="flex items-center space-x-4">
            <label className="w-24">Is Labor Cost: </label>
            <Switcher isOn={simpleFields.isLaborCost} onChange={() => onChangeSimpleFields("isLaborCost", !simpleFields.isLaborCost)} />
            <div className="text-xs opacity-60 mt-1">
              <p>Labor cost will set this product consumable in Odoo.</p>
            </div>
          </div>
        </div>

        {simpleFields.isLaborCost ? null : (
          <>
            <div className="flex items-center space-x-4">
              <label className="w-24">Size: </label>
              <ItemSizeInput value={size} onChange={setSize} />
            </div>

            <div className="flex space-x-8">
              <Field
                label="Item Weight"
                labelWidth="w-24"
                value={simpleFields.itemWeight}
                onChange={(value) => onChangeSimpleFields("itemWeight", value)}
                suffix="g"
              />

              <Field
                label="Gross Weight"
                labelWidth="w-24"
                value={simpleFields.grossWeight}
                onChange={(value) => onChangeSimpleFields("grossWeight", value)}
                suffix="g"
              />
            </div>
          </>
        )}

        <div className="flex space-x-8">
          <Field
            label="Default Qty"
            labelWidth="w-24"
            value={simpleFields.defaultQty}
            onChange={(value) => onChangeSimpleFields("defaultQty", value)}
            tips="Default Order Qty for this variant, used to calculate the default unit cost."
          />

          <Field label="MOQ" value={simpleFields.moq} onChange={(value) => onChangeSimpleFields("moq", value)} />
        </div>

        <div className="flex space-x-8">
          <div className="flex items-baseline space-x-4">
            <label>Default supplier: </label>
            <div>
              <FactorySelector value={defaultSupplierId} onChange={setDefaultSupplierId} />
            </div>
          </div>
          <div className="flex items-baseline space-x-4">
            <label>Producer: </label>
            <div>
              <FactorySelector value={producerId} onChange={setProduceerId} />
              <div className="opacity-70 mt-1">Choose a producer if it is different from the default supplier.</div>
            </div>
          </div>

          <div className="flex items-baseline space-x-4">
            <label>Deliver to: </label>
            <div>
              <FactorySelector value={deliverToSupplierId} onChange={setDeliverToSupplierId} />
            </div>
          </div>
        </div>
      </section>

      <section className="space-x-8 flex">
        <div className="flex items-center space-x-4">
          <label>Is Active: </label>
          <Switcher isOn={simpleFields.isActive} onChange={() => onChangeSimpleFields("isActive", !simpleFields.isActive)} />
        </div>

        <div className="flex items-center space-x-4">
          <label>Is Active for BIZ: </label>
          <Switcher isOn={simpleFields.isActiveForBiz} onChange={() => onChangeSimpleFields("isActiveForBiz", !simpleFields.isActiveForBiz)} />
        </div>

        <div className="flex items-center space-x-4">
          <label>Is Salable: </label>
          <Switcher isOn={simpleFields.isSalable} onChange={() => onChangeSimpleFields("isSalable", !simpleFields.isSalable)} />
        </div>

        <div className="flex items-center space-x-4">
          <label>Is Purchasable: </label>
          <Switcher isOn={simpleFields.isPurchasable} onChange={() => onChangeSimpleFields("isPurchasable", !simpleFields.isPurchasable)} />
        </div>
      </section>

      <div>
        <label htmlFor="">Notes: </label> About Cost and BOM, for example Acrowork Products, we purchase directly in Odoo so we don't need to have BOM in Odoo.{" "}
        <br />
        But we want to keep BOM in WIS so that we could track the component prices. <br />
        In this case, you create BOM in WIS, and only <b>sync the computed price to Odoo</b> in the BOM section below.
        <br />
        So we will have some products with BOM in WIS but no BOM in Odoo, that is supposed to be like that.
      </div>
    </div>
  );
};

export default BaseInfoView;
