import { RiErrorWarningFill } from "react-icons/ri";
import { Button } from "components/base";

const SentryFallbackComponent = () => {
  return (
    <div className="flex items-center justify-center h-screen">
      <div className=" flex flex-col items-center justify-between">
        <RiErrorWarningFill className="text-8xl text-red-500" />
        <div className="mt-6 text-lg space-y-2 text-center">
          <div>We&apos;re really sorry, it looks like the app crashed.</div>
          <div>We are notified and will fix it asap.</div>
        </div>

        <div className="flex space-x-8">
          <Button className="mt-6 text-xl font-bold" onClick={() => window.location.reload()} title="Try Reload" />
        </div>
      </div>
    </div>
  );
};

export default SentryFallbackComponent;
