import { gql, useQuery } from '@apollo/client';
import Errors from 'components/Errors';
import Spinner from 'components/Spinner';
import { useState } from 'react';
import { searchByProp } from 'utils/search';
import SearchBar from './SearchBar';

const FETCH_ODOO_PRODUCTS = gql`
   query FETCH_ODOO_PRODUCTS {
      odooProducts {
         id
         name
         defaultCode
         barcode
      }
   }
`;

const OdooProductSelector = ({ onSelect }) => {
   const [searchText, setSearchText] = useState('');
   const { loading, error, data } = useQuery(FETCH_ODOO_PRODUCTS);

   if (loading) return <Spinner text="Loading from Odoo.." />;
   if (error) return <Errors error={error} />;

   return (
      <div className="flex flex-col flex-1">
         <SearchBar
            className="py-2 dark:bg-gray-900"
            autoFocus
            type="text"
            placeholder="Search by name or number"
            value={searchText}
            onChange={setSearchText}
         />
         <div className="flex-1 mt-4">
            {data.odooProducts
               ? data.odooProducts
                    .filter((i) =>
                       searchByProp(i, ['name', 'defaultCode'], searchText)
                    )
                    .map((product) => (
                       <div
                          className="px-2 cursor-pointer border-b dark:border-gray-700 py-2 hover:bg-gray-100 active:bg-gray-200 flex justify-between"
                          key={product.id}
                          onClick={() => onSelect(product.id)}
                       >
                          <div>
                             #{product.id} [{product.defaultCode}]{' '}
                             {product.name}
                          </div>
                          <div>
                             {product.barcode !== 'false'
                                ? product.barcode
                                : null}
                          </div>
                       </div>
                    ))
               : null}
         </div>
      </div>
   );
};

export default OdooProductSelector;
