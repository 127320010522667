import { gql } from "@apollo/client";

export const CREATE_ODOO_ORDER = gql`
  mutation CREATE_ODOO_ORDER($region: String!, $number: String!, $orderDate: Date!, $data: String!) {
    amzCreateSalesOrderInOdoo(region: $region, number: $number, orderDate: $orderDate, data: $data) {
      purchaseOrder {
        id
        number
        odooSalesOrderId
        odooSalesOrderName
        createdAt
      }
    }
  }
`;

export const AMAZON_SUBMIT_ACKNOWLEDGEMENT = gql`
  mutation AMAZON_SUBMIT_ACKNOWLEDGEMENT($region: String!, $number: String!, $detail: String!, $orderDate: Date!, $stockCategoryId: ID) {
    amzSubmitAcknowledgement(region: $region, number: $number, detail: $detail, orderDate: $orderDate, stockCategoryId: $stockCategoryId) {
      amazonPurchaseOrder {
        id
        data
      }
    }
  }
`;

export const FETCH_AMAZON_STOCK_CATEGORY = gql`
  query FETCH_AMAZON_STOCK_CATEGORY($id: ID!) {
    amazonStockCategory(id: $id) {
      id
      name
      regions
      stocks {
        id
        name
        odooId
        barcode
        number
        asin

        qtyPerMaster
        unitCost
        initialQty
        latestQty
        editRecords {
          id
          qty
          date
          notes
        }

        purchaseOrderLines {
          id
          qtyRequested
          actionQty
          actionType
          purchaseOrder {
            id
            orderDate
            number
          }
        }

        orderLines {
          id
          qty
          order {
            createdAt
            state
            odooSalesOrderName
          }
        }
      }
    }
  }
`;

export const AMAZON_UPDATE_STOCK = gql`
  mutation AMAZON_UPDATE_STOCK(
    $id: ID!
    $name: String
    $number: String
    $asin: String
    $barcode: String
    $odooId: Int
    $qtyPerMaster: Int
    $unitCost: Float
    $initialQty: Int
    $editRecords: [AmazonVendorCetnerEditRecordInput]
  ) {
    amzUpdateStock(
      id: $id
      name: $name
      number: $number
      asin: $asin
      barcode: $barcode
      odooId: $odooId
      qtyPerMaster: $qtyPerMaster
      unitCost: $unitCost
      initialQty: $initialQty
      editRecords: $editRecords
    ) {
      stock {
        id
        name
        number
        asin
        barcode
        odooId
        qtyPerMaster
        initialQty
        latestQty
        unitCost
        editRecords {
          id
          qty
          notes
          date
        }
        latestQty
      }
    }
  }
`;

export const AMAZON_DELETE_STOCK = gql`
  mutation AMAZON_DELETE_STOCK($id: ID!) {
    amzDeleteStock(id: $id) {
      deleted
    }
  }
`;

export const ODOO_GENERATE_SO_PDF = gql`
  mutation ODOO_GENERATE_SO_PDF($id: Int!, $name: String!) {
    odooGenerateSoPdf(id: $id, name: $name) {
      document {
        id
      }
    }
  }
`;

export const DUPLICATE_AMAZON_STOCK_CATEGORY = gql`
  mutation amzDuplicateStockCategory($name: String!, $regions: String!, $fromCategoryId: ID!) {
    amzDuplicateStockCategory(name: $name, regions: $regions, fromCategoryId: $fromCategoryId) {
      amazonStockCategory {
        id
        name
      }
    }
  }
`;
