import { gql, useQuery } from '@apollo/client';
import Errors from 'components/Errors';
import Spinner from 'components/Spinner';

const FETCH_PRODUCTS_WITH_DIFFERENT_PRODUCERS = gql`
   query FETCH_PRODUCTS_WITH_DIFFERENT_PRODUCERS {
      products(hasDifferentProducer: true) {
         total
         results {
            id
            name
            number
            defaultSupplier {
               id
               name
               preferPaymentTerms
            }
            producer {
               id
               name
               preferPaymentTerms
            }
            deliverToSupplier {
               id
               name
            }
         }
      }
   }
`;

const SubSupplierProducts = () => {
   const { loading, error, data } = useQuery(
      FETCH_PRODUCTS_WITH_DIFFERENT_PRODUCERS,
      { fetchPolicy: 'network-only' }
   );

   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;

   return (
      <div className="p-6">
         <div className="opacity-70">
            This page is for listing products with different producers.
         </div>
         <div className="card px-6 py-4 mt-4">
            <div className="-mx-2">
               <table>
                  <thead>
                     <tr>
                        <th>Products</th>
                        <th>Default Supplier</th>
                        <th>Producer Payment Terms</th>
                        <th>Producer</th>
                        <th>Delivery to Supplier</th>
                     </tr>
                  </thead>
                  <tbody>
                     {data.products.results.map((i) => (
                        <tr
                           key={i.id}
                           className="border-y border-gray-100 dark:border-gray-700"
                        >
                           <td>
                              #{i.id} [{i.number}] {i.name}
                           </td>
                           <td>
                              {i.defaultSupplier ? i.defaultSupplier.name : '-'}
                           </td>
                           <td>
                              {i.producer ? i.producer.preferPaymentTerms : '-'}
                           </td>
                           <td>{i.producer ? i.producer.name : '-'}</td>
                           <td>
                              {i.deliverToSupplier
                                 ? i.deliverToSupplier.name
                                 : '-'}
                           </td>
                        </tr>
                     ))}
                  </tbody>
               </table>
            </div>
         </div>
      </div>
   );
};

export default SubSupplierProducts;
