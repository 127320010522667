import React from 'react';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { Select } from '../../../components/Form.js';

const FETCH_SHIPMENT_STATUS_INFO = gql`
   query FETCH_SHIPMENT_STATUS_INFO {
      shipmentStatusInfo
   }
`;

const ShipmentStatusOptions = ({ initialValue, onSelected, ...rest }) => {
   const { data } = useQuery(FETCH_SHIPMENT_STATUS_INFO);

   if (!data) return <div className="text-gray-500">Loading...</div>;

   const options = JSON.parse(data.shipmentStatusInfo);

   return (
      <Select
         value={initialValue}
         className="border-0 h-6 capitalize"
         onChange={(e) => onSelected(e.target.value)}
         {...rest}
      >
         <option value="0">All Status</option>
         {Object.entries(options).map(([key, value]) => (
            <option value={key} key={key}>
               {key} ({value})
            </option>
         ))}
      </Select>
   );
};

export default ShipmentStatusOptions;
