import { gql, useQuery } from "@apollo/client";
import Errors from "components/Errors";
import Spinner from "components/Spinner";
import { useState } from "react";
import { ButtonWithArrow } from "components/base";
import { formatDate } from "react-day-picker/moment";
import { BsArrowDownCircleFill, BsArrowUpCircleFill } from "react-icons/bs";
import { randomColors } from "utils/colors";
import { LineChart, Line, ResponsiveContainer, XAxis, YAxis, Tooltip } from "recharts";
import { FRAGMENT_PRODUCT_FOR_COST } from "graphql/fragments";

const FETCH_PRODUCT_COST = gql`
  query FETCH_PRODUCT_COST($id: ID!) {
    product(id: $id) {
      ...productForCost
    }
  }
  ${FRAGMENT_PRODUCT_FOR_COST}
`;

const ProductDetail = ({ startDate, product }) => {
  const { loading, error, data } = useQuery(FETCH_PRODUCT_COST, {
    variables: { id: product.id },
  });

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const computedProduct = {
    ...data.product,
    children: data.product.children.map((child) => {
      const prices = child.product.prices
        .filter((i) => (child.product.defaultSupplier ? child.product.defaultSupplier.id === i.factory.id : true))
        .sort((a, b) => a.supplierUpdatedAt - b.supplierUpdatedAt);
      return { ...child, prices };
    }),
    sets: data.product.defaultQty / data.product.itemsPerSet,
  };

  const color = randomColors(1)[0];

  return (
    <div>
      <div className="p-6 bg-white dark:bg-gray-900 dark:bg-opacity-60 rounded-2xl shadow fill-current">
        <ResponsiveContainer height={300}>
          <LineChart
            data={Object.values(product.chartData)}
            margin={{
              top: 30,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip content={CustomTooltip} />
            <Line dataKey="value" stroke={color} dot={false} />
          </LineChart>
        </ResponsiveContainer>
      </div>

      <table className="with-border mt-6 bg-white dark:bg-gray-800">
        <tbody>
          {computedProduct.children.map((child) => (
            <Child sets={computedProduct.sets} child={child} startDate={startDate} key={child.id} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

const Child = ({ sets, child, startDate }) => {
  const [showHistory, setShowHistory] = useState(true);

  const allPrices = child.prices
    .map((i) => ({
      ...i,
      supplierUpdatedAt: new Date(i.supplierUpdatedAt),
    }))
    .sort((a, b) => a.supplierUpdatedAt - b.supplierUpdatedAt);
  const olderHistory = allPrices.filter((i) => i.supplierUpdatedAt < startDate);

  let groups = {};
  allPrices.forEach((i) => {
    if (groups[i.minQty]) {
      groups[i.minQty].push(i);
    } else {
      groups[i.minQty] = [i];
    }
  });
  const qtyLevels = Object.keys(groups)
    .map((i) => parseInt(i))
    .sort((a, b) => b - a)
    .filter((i) => i <= sets * child.qty);

  const usingQty = `${qtyLevels[0]}`;
  const usingGroup = groups[`${usingQty}`] || [];

  console.log("usingGroup", usingGroup);

  const prices = usingGroup
    .filter((i) => (showHistory ? true : i.supplierUpdatedAt >= startDate))
    .reduce((res, item, index) => {
      let diff = 0;
      if (index > 0) diff = item.price - res[index - 1].price;
      return [...res, { ...item, diff }];
    }, []);

  return (
    <>
      <tr>
        <td className="font-bold bg-gray-100 dark:bg-gray-700" colSpan={4}>
          <div className="flex items-center space-x-3">
            <div>
              [{child.product.number}] {child.product.name}
            </div>
            {olderHistory.length > 0 ? (
              <ButtonWithArrow className="text-blue-600" show={showHistory} onClick={() => setShowHistory((prev) => !prev)}>
                {showHistory ? "show latest update" : "show all"}
              </ButtonWithArrow>
            ) : null}
          </div>
        </td>
      </tr>
      {prices.map((i) => (
        <tr key={i.id} className={i.supplierUpdatedAt >= startDate ? "" : " opacity-50"}>
          <td>{formatDate(i.supplierUpdatedAt, "YYYY-MM-DD")}</td>
          <td>{i.factory.name}</td>
          <td className="text-right">{i.minQty}</td>
          <td className="text-right">
            <div
              className={`flex space-x-2 justify-end items-center
                        ${i.diff > 0 ? "text-red-600" : ""}
                        ${i.diff < 0 ? "text-green-600" : ""}
                    `}
            >
              <div>{i.diff === 0 ? null : i.diff > 0 ? <BsArrowUpCircleFill /> : <BsArrowDownCircleFill />} </div>
              <div>
                {i.currency} {i.price}
              </div>
            </div>
          </td>
        </tr>
      ))}
    </>
  );
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const data = payload[0];

    return (
      <div className="px-6 py-3 rounded-2xl bg-opacity-80 bg-white dark:bg-gray-800 dark:bg-opacity-80">
        <div>{`${label}: ${data.value}`}</div>
      </div>
    );
  }

  return null;
};

export default ProductDetail;
