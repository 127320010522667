import { useMutation } from "@apollo/client";
import CharlesButton from "components/charles/base";
import { Field } from "components/Form";
import OdooProductSelector from "components/OdooProductSelector";
import { Alert } from "components/Toast";
import { useModals } from "ModalProvider";
import { useState } from "react";
import { formatDate } from "react-day-picker/moment";
import { AMAZON_UPDATE_STOCK, AMAZON_DELETE_STOCK } from "../graphql";
import OdooProduct from "../OdooProduct";
import ConsumedRecords from "./ConsumedRecords";
import moment from "moment";

const StockDetail = ({ stock, hide }) => {
  const [name, setName] = useState(stock.name);
  const [odooId, setOdooId] = useState(stock.odooId);
  const [asin, setAsin] = useState(stock.asin);
  const [number, setNumber] = useState(stock.number);
  const [barcode, setBarcode] = useState(stock.barcode);
  const [qtyPerMaster, setQtyPerMaster] = useState(stock.qtyPerMaster);
  const [unitCost, setUnitCost] = useState(stock.unitCost);
  const [initialQty, setInitialQty] = useState(stock.initialQty);
  const [editRecords, setEditRecords] = useState(
    stock.editRecords.map((i) => ({
      date: formatDate(i.date, "YYYY-MM-DD"),
      qty: i.qty,
      notes: i.notes,
    }))
  );
  const [saveStock, saveStockRes] = useMutation(AMAZON_UPDATE_STOCK, {
    onCompleted() {
      Alert("success", "Stock saved.");
      hide();
    },
    onError(error) {
      Alert("error", error.message);
    },
  });

  const [deleteStock, deleteStockRes] = useMutation(AMAZON_DELETE_STOCK, {
    variables: { id: stock.id },
    onCompleted() {
      Alert("success", "Stock deleted.");
      hide();
    },
    onError(error) {
      Alert("error", error.message);
    },
    awaitRefetchQueries: true,
    refetchQueries: ["FETCH_AMAZON_STOCKS"],
  });
  const modal = useModals();

  console.log("stock.purchaseOrderLines", stock.purchaseOrderLines);

  const purchaseOrderLines = stock.purchaseOrderLines
    .filter((i) => i.actionType === "accept")
    .map((i) => ({
      ...i,
      qty: i.actionQty,
      date: i.purchaseOrder.orderDate,
      notes: "Amazon PO - " + i.purchaseOrder.number,
    }));
  const salesOrderLines = stock.orderLines
    .filter((i) => ["PLANNING", "HANDLED"].includes(i.order.state))
    .map((i) => ({ ...i, date: moment(i.order.createdAt).format("YYYY-MM-DD"), notes: "WIS Order - " + i.order.odooSalesOrderName }));

  const orderLines = [...purchaseOrderLines, ...salesOrderLines];

  const latestQty =
    initialQty - orderLines.reduce((res, i) => res + i.qty, 0) - editRecords.reduce((res, i) => res + (isNaN(parseInt(i.qty)) ? 0 : parseInt(i.qty)), 0);

  function saveHandler() {
    saveStock({
      variables: {
        id: stock.id,
        name,
        number,
        asin,
        barcode,
        odooId,
        qtyPerMaster,
        unitCost: unitCost ? parseFloat(unitCost) : 0,
        initialQty,
        editRecords,
      },
    });
  }

  function deleteHandler() {
    if (window.confirm(`Are you sure to delete this stock? This can not be undone. The related stock record will be delete too.`)) {
      deleteStock();
    }
  }

  function showOdooProductSelector() {
    modal.present({
      title: "Select Odoo Product",
      maxWidth: "max-w-5xl",
      children: (
        <OdooProductSelector
          onSelect={(id) => {
            setOdooId(id);
            modal.hide();
          }}
        />
      ),
    });
  }

  return (
    <div>
      <div className="space-y-4">
        <Field label="Name" value={name} onChange={setName} />

        <div className="flex items-baseline space-y-2">
          <label className="pr-4">Odoo Product: </label>
          <div>
            {odooId ? (
              <div>
                <OdooProduct id={odooId} />
                <div className="mt-2 flex space-x-6 text-xs">
                  <CharlesButton onClick={showOdooProductSelector}>Change Odoo Product</CharlesButton>
                  <CharlesButton danger onClick={() => setOdooId(null)}>
                    Remove Connection
                  </CharlesButton>
                </div>
              </div>
            ) : (
              <CharlesButton onClick={showOdooProductSelector}>Connect Odoo Product</CharlesButton>
            )}
          </div>
        </div>

        <div className="flex space-x-8">
          <div className="flex-1">
            <Field label="ASIN" value={asin} onChange={setAsin} />
          </div>
          <div className="flex-1">
            <Field label="Number" value={number} onChange={setNumber} />
          </div>
        </div>
        <div className="flex space-x-8">
          <div className="flex-1">
            <Field label="Barcode" value={barcode} onChange={setBarcode} />
          </div>
          <div className="flex-1">
            <Field label="Qty / Master" value={qtyPerMaster} onChange={setQtyPerMaster} />
          </div>
        </div>
        <div className="flex space-x-8">
          <div className="flex-1">
            <Field label="Unit Cost" value={unitCost} onChange={setUnitCost} />
          </div>
          <div className="flex-1">
            <Field label="Initial Qty" value={initialQty} onChange={setInitialQty} />
          </div>
        </div>

        <div className="opacity-70 text-xs">
          When checking stock avaliable for new orders, we match items by ASIN. ASIN could have multiple values seperated by comma(,).
        </div>
      </div>

      <div className="my-8 space-x-6 text-2xl flex items-center justify-end font-bold">
        <div>Latest Qty: </div>
        <span>{latestQty}</span>
      </div>

      <div className="card px-6 py-4">
        <h5>Consumed Records</h5>
        <ConsumedRecords stock={stock} orderLines={orderLines} editRecords={editRecords} setEditRecords={setEditRecords} />
      </div>

      <div className="mt-8 flex justify-between text-base">
        <CharlesButton onClick={saveHandler} loading={saveStockRes.loading}>
          Save
        </CharlesButton>
        <CharlesButton danger onClick={deleteHandler} loading={deleteStockRes.loading}>
          Delete Stock
        </CharlesButton>
      </div>
    </div>
  );
};

export default StockDetail;
