import CharlesButton from "components/charles/base";

const ConfirmChangeWarehouseView = ({ warehouseName, confirmHandler }) => {
  return (
    <div>
      <div>
        <p>Are you sure you want to change the warehouse to {warehouseName}?</p>
        <p>Every warehouse has different items, the products you select will be clear.</p>
      </div>

      <hr />

      <div>
        <CharlesButton onClick={confirmHandler}>Confirm</CharlesButton>
      </div>
    </div>
  );
};

export default ConfirmChangeWarehouseView;
