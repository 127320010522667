import { gql } from "@apollo/client";

export const FETCH_PRODUCTS_NEW_PAGE_DATA = gql`
  query FETCH_PRODUCTS_NEW_PAGE_DATA {
    productLines(activeForWis: true) {
      total
      results {
        id
        name
        number
      }
    }
    groups: allProductAreas {
      id
      name
    }
  }
`;
