import React from 'react';
import Card from '../../../components/Card.js';
import ShipmentError from '../../../components/ShipmentError.js';

function ErrorsOverview({ shipments }) {
   const errors = [
      ...new Set(
         shipments
            .map((shipment) =>
               shipment.shipmentStatus.map((shipmentStatus) =>
                  shipmentStatus.errors.map(
                     (shipmentError) => shipmentError.error
                  )
               )
            )
            .flat(3)
            .sort((a, b) => a - b)
      ),
   ];
   let data = [];
   errors.forEach((e) => {
      let error = {
         ...e,
         orders: shipments.reduce(
            (count, shipment) =>
               (count +=
                  shipment.shipmentStatus.filter(
                     (shipmentStatus) =>
                        shipmentStatus.errors.filter(
                           (shipmentError) =>
                              shipmentError.error.code === e.code
                        ).length > 0
                  ).length > 0
                     ? 1
                     : 0),
            0
         ),
      };
      data.push(error);
   });
   data = data.sort((a, b) => b.orders - a.orders);
   return (
      <Card title="Errors Ranking">
         {data.map((i, index) => (
            <div
               key={index}
               className="lg:flex border-t border-gray-100 dark:border-gray-700 items-center py-2"
            >
               <div className="w-32">
                  <span className="text-pink-500">{i.orders}</span> orders
               </div>
               <ShipmentError
                  className="flex-1"
                  code={i.code}
                  description={i.description}
               />
            </div>
         ))}
      </Card>
   );
}

export default ErrorsOverview;
