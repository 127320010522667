import { useState } from "react";
import { useQuery } from "@apollo/client";

import DateRangePicker from "../DateRangePicker";
import ScoreRadarChart from "./ScoreRadarChart";
import { FETCH_SUPPLIER_SCORES } from './graphql';
import useDateRange from "hooks/useDateRange";
import { formatDate } from 'react-day-picker/moment';
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import CharlesButton from "components/charles/base";
import { IoIosArrowForward } from 'react-icons/io';
import { FaExternalLinkAlt } from "react-icons/fa";
import { Link } from "react-router-dom";

const SupplierList = ({ supplierScores, setSelectedSupplier }) => {
   const calculateAverageScore = (scores) => {
      const { __typename, ...validScores } = scores;  // remove __typename, it's not a score indicator
      const scoreValues = Object.values(validScores);
      const averageScore = scoreValues.reduce((sum, score) => sum + score, 0) / scoreValues.length;
      return averageScore.toFixed(0);
   };

   return (
      <div >
         {supplierScores.map((info, index) => (
            <div key={index}>
               <div className="pt-4 border-b flex justify-between items-center border-gray-100 dark:border-gray-700 hover:bg-gray-200 dark:hover:bg-gray-700">
                  <CharlesButton className="flex-1"
                     onClick={() => setSelectedSupplier(info)}
                  >
                     {info.name}
                  </CharlesButton>

                  <div className="flex-none mr-4">{calculateAverageScore(info.scores) + " / 100"}</div>

                  <IoIosArrowForward className="text-gray-400 flex-none" />
               </div>
            </div>
         ))}
      </div>
   );
};

const ScoreInfo = ({ info }) => {
   const scoreItems = [
      { label: 'Quality', score: info?.scores.quality, path: 'quality' },
      { label: 'Accuracy(shipment errors)', score: info?.scores.shipmentErrors, path: 'shipment-error' },
      { label: 'Accuracy(lead time)', score: info?.scores.leadTime, path: 'lead-time' },
      { label: 'ESG', score: info?.scores.esg, path: 'esg' },
      { label: 'Communications', score: info?.scores.communications, path: 'communication' },
   ];

   return (
      <div data-testid="supplier-score-info">
         {info ? (
            <div>
               {scoreItems.map((item, index) => (
                  <div key={index} className="mt-3">
                     <div className="flex text-base items-center">
                        <label className="pr-3" >{item.label}: </label>
                        <Link to={`/analytics/grading/${item.path}`} className="flex items-center justify-center cursor-pointer text-sky-600 hover:text-sky-700 active:text-sky-800 ">
                           {item.score !== -1 ? item.score : "/"}
                           <FaExternalLinkAlt className="ml-2 text-xs" />
                        </Link>
                     </div>
                  </div>
               ))}
            </div>
         ) : null}
      </div>
   );
};

const SpecificSupplierOverview = ({ supplierInfo }) => {
   return (
      <div className="grid grid-cols-2">
         <div className="col-span-2">
            <h3>{supplierInfo.name}</h3>
         </div>

         <div className="flex items-center">
            <div>
               <ScoreInfo info={supplierInfo} />
            </div>
         </div>

         <ScoreRadarChart supplierScores={[supplierInfo]} cx="55%" showLegend={false} tickShowLess={true} />
      </div>
   );
};


const SupplierGradingDashboard = () => {
   const [{ start, end }, { setStart, setEnd }] = useDateRange(6);
   const [selectedSupplier, setSelectedSupplier] = useState(null);

   // Timestmp always change, so we need to format it to YYYY-MM-DD
   const { loading, error, data } = useQuery(FETCH_SUPPLIER_SCORES, {
      variables: {
         startDate: formatDate(start, 'YYYY-MM-DD'),
         endDate: formatDate(end, 'YYYY-MM-DD'),
      },
      skip: !start || !end,
   });

   if (loading) return <Spinner />;
   if (error) return <Errors error={error} />;


   return (
      <div data-testid="supplier-grading-dashboard" className="p-6 space-y-6 text-sm grid grid-cols-3 gap-x-6">
         <div className="col-span-3">
            <DateRangePicker start={start} end={end} setStart={setStart} setEnd={setEnd} />
         </div>

         <div className="card col-span-1">
            <SupplierList supplierScores={data.supplierScores} setSelectedSupplier={setSelectedSupplier} />
         </div>

         <div className="card col-span-2" data-testid="score-radar-chart">
            <ScoreRadarChart supplierScores={data.supplierScores} title="Overview" cx="40%" />
         </div>

         {selectedSupplier ? (
            <div className="card col-span-2 col-end-4" data-testid="score-radar-chart">
               <SpecificSupplierOverview supplierInfo={selectedSupplier} />
            </div>
         ) : null}
      </div>
   );
};

export default SupplierGradingDashboard;