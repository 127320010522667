const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const data = payload[0];

    return (
      <div className="px-6 py-3 rounded-2xl bg-opacity-80 bg-white dark:bg-gray-800 dark:bg-opacity-80">
        <div>{`${label}: ${data.value}`}</div>
      </div>
    );
  }

  return null;
};

export default CustomTooltip;
