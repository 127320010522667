import React from 'react';
import Video from '../../../../components/Video';
import { Button } from '../../../../components/base';

function PromoVideos({ productLine }) {
   return (
      <div>
         <div className="flex justify-between">
            <h3>Promo Videos</h3>
            <Button
               title="Associate"
               size="sm"
               link={`/products/collection-view/product-line/${productLine.slug}/associate/videos`}
            ></Button>
         </div>
         <div className="mt-4 flex overflow-auto scrolling-touch">
            {productLine.videos.length > 0 ? (
               productLine.videos.map((video) => (
                  <div key={video.id}>
                     <Video poster={video.poster} src={video.previewUri} />
                  </div>
               ))
            ) : (
               <div className="flex-1 flex items-center justify-center p-8 text-gray-500 font-bold bg-gray-100">
                  Associated videos will be list here.
               </div>
            )}
         </div>
      </div>
   );
}

export default PromoVideos;
