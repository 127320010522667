const Field = ({ className = "", label, value, suffix, notes }) => (
  <div className={className}>
    <div className="flex space-x-2">
      <label className="pr-2 font-semibold opacity-60">{label}: </label>
      <div>{value}</div>
      {suffix ? <div>{suffix}</div> : null}
    </div>
    {notes ? <div className="opacity-70 text-xs">{notes}</div> : null}
  </div>
);

export default Field;
