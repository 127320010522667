import { useQuery } from "@apollo/client";
import { BiLinkExternal } from "react-icons/bi";
import Errors from "components/Errors";
import Spinner, { InlineSpinner } from "components/Spinner";
import { useModals } from "ModalProvider";
import ProductFormLoader from "pages/manage/products/All/ProductForm";
import { FETCH_PRODUCTS_VALIDATION } from "./graphql";
import { useContext, useState } from "react";
import { useAmazonStockCategories } from "hooks/useAmazon";
import { Select } from "components/Form";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { OdooContext } from "OdooProvider";

const ABStockProducts = () => {
  const [stockCategoryId, setStockCategoryId] = useState(null);
  const { loading, error, data } = useAmazonStockCategories();

  if (loading) return <Spinner text="Validating..." />;
  if (error) return <Errors error={error} />;

  return (
    <div>
      <div className="px-6 py-3">
        <label className="mr-3">Stock Category: </label>
        <Select value={stockCategoryId || "0"} onChange={(e) => setStockCategoryId(e.target.value)}>
          <option value="0">-- Choose Category --</option>
          {data.listAmazonStockCategories.map((i) => (
            <option value={i.id}>{i.name}</option>
          ))}
        </Select>

        <span className="opacity-70 italic ml-6">This page is used to validate product cost, production and connections among amazon, odoo and wis.</span>
      </div>
      {stockCategoryId ? <ABStockProductsContent stockCategoryId={stockCategoryId} /> : null}
    </div>
  );
};

const ABStockProductsContent = ({ stockCategoryId }) => {
  const { odooUrl } = useContext(OdooContext);
  const { loading, error, data } = useQuery(FETCH_PRODUCTS_VALIDATION, {
    variables: { productType: "AB_STOCK_PRODUCTS", stockCategoryId },
  });
  const editModal = useModals();

  if (loading)
    return (
      <div className="p-20">
        <InlineSpinner text="Validating..." />
      </div>
    );
  if (error) return <Errors error={error} />;

  const res = JSON.parse(data.validateProducts);

  console.log("res", res);

  return (
    <table className="bg-white">
      <thead className="whitespace-nowrap">
        <tr>
          <th className="px-6  sticky top-0 backdrop-blur bg-gray-200 dark:bg-gray-800 bg-opacity-80">Item Product</th>
          <th className="px-6  sticky top-0 backdrop-blur bg-gray-200 dark:bg-gray-800 bg-opacity-80">Odoo Product</th>
          <th className="px-6  sticky top-0 backdrop-blur bg-gray-200 dark:bg-gray-800 bg-opacity-80">Wis Product</th>
          <th className="px-6  sticky top-0 backdrop-blur bg-gray-200 dark:bg-gray-800 bg-opacity-80">Cost</th>
          <th className="px-6  sticky top-0 backdrop-blur bg-gray-200 dark:bg-gray-800 bg-opacity-80">Areas</th>
          <th className="px-6  sticky top-0 backdrop-blur bg-gray-200 dark:bg-gray-800 bg-opacity-80">Production</th>
        </tr>
      </thead>
      <tbody>
        {Object.entries(res).map(([id, i]) => (
          <tr key={id}>
            <td className="px-6 border-b dark:border-gray-700 whitespace-nowrap">{i.name}</td>
            <td className={`px-6 border-b dark:border-gray-700 whitespace-nowrap ${i.odoo_product ? "" : "bg-pink-300 dark:bg-pink-900"}`}>
              <div className="flex space-x-2 items-center">
                <span>{i.odoo_product}</span>
                {i.odoo_product_id ? (
                  <a
                    href={`${odooUrl}/web#id=${i.odoo_product_id}&action=563&model=product.product&view_type=form&menu_id=222`}
                    target="_blank"
                    rel="noreferer"
                    className="text-blue-600 hover:text-blue-700 active:text-blue-800"
                  >
                    <BiLinkExternal />
                  </a>
                ) : null}
              </div>
            </td>
            <td
              className={`px-6 border-b dark:border-gray-700
                            ${
                              !i.wis_product || i.wis_product === "Not Connected" || i.wis_product.includes("Not unique connection")
                                ? "bg-pink-300 dark:bg-pink-900 text-pink-600 font-semibold"
                                : "text-blue-600 hover:text-blue-700 active:text-blue-800 cursor-pointer"
                            }`}
              onClick={() => {
                if (i.wis_product_id)
                  editModal.present({
                    title: "Edit Product",
                    fullscreen: true,
                    children: <ProductFormLoader id={i.wis_product_id} hide={editModal.hide} />,
                  });
              }}
            >
              {i.wis_product}
            </td>
            <td
              className={`px-6 border-b dark:border-gray-700
                        ${i.cost ? "" : "bg-pink-300 dark:bg-pink-900"}`}
            >
              {i.cost}
            </td>
            <td
              className={`px-6 border-b dark:border-gray-700
                        ${i.areas && i.areas.length > 0 ? "" : "bg-pink-300 dark:bg-pink-900"}`}
            >
              {i.areas ? i.areas.join(", ") : " - "}
            </td>
            <td
              className={`px-6 border-b dark:border-gray-700 whitespace-nowrap ${
                i.production !== "Has Production" ? "bg-pink-300 dark:bg-pink-900" : " text-green-600"
              }`}
            >
              {i.production === "Has Production" ? <IoIosCheckmarkCircle size={18} /> : i.production}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ABStockProducts;
