import { gql, useMutation } from "@apollo/client";
import { parseError } from "apollo";
import { Alert } from "components/Toast";
import CharlesButton from "components/charles/base";

const SALES_PRICE_BATCH_UPDATE = gql`
  mutation SALES_PRICE_BATCH_UPDATE($priceListId: ID!, $salesPrices: [SalesPriceInputType]!) {
    salesPriceBatchUpdate(priceListId: $priceListId, salesPrices: $salesPrices) {
      priceList {
        id
      }
    }
  }
`;

const BatchUpdatePriceView = ({ priceListId, rows, hide }) => {
  console.log("BatchUpdatePriceView.rows", rows);
  const [updatePrices, updatePricesRes] = useMutation(SALES_PRICE_BATCH_UPDATE, {
    variables: {
      priceListId,
      salesPrices: rows.map((row) => ({
        productId: row.id,
        price: row.price,
        discountPercentage: row.discount,
        useFixedMargin: row.useFixedMargin,
        fixedMargin: row.fixedMargin,
      })),
    },
    onCompleted() {
      Alert("success", "Prices updated successfully");
      hide();
    },
    onError(error) {
      Alert("error", parseError(error));
    },
    refetchQueries: ["FETCH_PRICE_LIST"],
    awaitRefetchQueries: true,
  });

  return (
    <div className="text-sm">
      <div className="-mx-2 overflow-auto max-h-96">
        <table>
          <thead>
            <tr className=" sticky top-0 z-10 bg-gray-100 whitespace-nowrap">
              <th>Product</th>
              <th className="text-right">Price</th>
              <th className="text-right">Discount</th>
              <th className="text-right">Use Fixed Margin</th>
              <th className="text-right">Fixed Margin</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row, index) => (
              <tr key={index} className="border-y dark:border-gray-700 text-right">
                <td className="w-full text-left">
                  [{row.number}] {row.name}
                </td>
                <td>{row.price}</td>
                <td>{row.discount}</td>
                <td>{row.useFixedMargin ? "Y" : "N"}</td>
                <td>{row.fixedMargin}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="mt-6">You are about to update {rows.length} prices. Are you sure?</div>

      <div className="flex items-center mt-4 space-x-6">
        <CharlesButton primary onClick={updatePrices} loading={updatePricesRes.loading}>
          Yes, Update Now
        </CharlesButton>
        <CharlesButton onClick={hide}>No, Take me Back</CharlesButton>
      </div>
    </div>
  );
};

export default BatchUpdatePriceView;
